import {
  DELETE_MANAGER,
  DELETE_MANAGER_FAIL,
  DispatchActionTypes,
  GET_TOTAL_MANAGERS,
  GET_TOTAL_MANAGERS_FAIL,
  GET_TOTAL_MANAGERS_SUCCESS,
  IManagersState,
  MANAGER_DUMP_DB,
  MANAGER_DUMP_DB_FAIL,
  MANAGER_DUMP_DB_SUCCESS,
  SEARCH_MANAGERS,
  SEARCH_MANAGERS_FAIL,
  SEARCH_MANAGERS_SUCCESS,
  UPSERT_MANAGER,
  UPSERT_MANAGER_FAIL,
} from '../../../models/admin/icons-managers.model';

const initialState: IManagersState = {
  loading: false,
  searchedManagers: [],
  totalManagers: 0,
  totalManagersLoading: false,
};

const managersReducer = (
  state: IManagersState = initialState,
  action: DispatchActionTypes
): IManagersState => {
  /* Reducer logic */
  switch (action.type) {
    /***************************************
     *                 Dump                *
     ***************************************/
    case MANAGER_DUMP_DB:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MANAGER_DUMP_DB_SUCCESS:
      return {
        ...state,
        loading: false,
        managersDump: action.payload,
      };
    case MANAGER_DUMP_DB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *                Search               *
     ***************************************/
    case SEARCH_MANAGERS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SEARCH_MANAGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedManagers: action.payload,
      };
    case SEARCH_MANAGERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *          Get Total Managers         *
     ***************************************/
    case GET_TOTAL_MANAGERS:
      return {
        ...state,
        totalManagersLoading: true,
        error: undefined,
      };
    case GET_TOTAL_MANAGERS_SUCCESS:
      return {
        ...state,
        totalManagersLoading: false,
        totalManagers: action.payload,
      };
    case GET_TOTAL_MANAGERS_FAIL:
      return {
        ...state,
        totalManagersLoading: false,
        error: action.payload,
      };

    /***************************************
     *                Upsert               *
     ***************************************/
    case UPSERT_MANAGER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UPSERT_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *                Delete               *
     ***************************************/
    case DELETE_MANAGER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case DELETE_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default managersReducer;
