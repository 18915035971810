import { ArrowRight } from '@mui/icons-material';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Palette,
  SvgIcon,
  listItemTextClasses,
  useTheme,
} from '@mui/material';
import { IStructureSection } from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TooltipIconButton from '../components/tooltip-icon-button.component';
import { openNavMenu } from '../store/actions/modals.action';
import { RootState } from '../store/reducers/root.reducer';
import { iconStyles } from '../styles/icons.styles';
import { PREMIUM_ROUTES, RouteEnum } from './routes.constants';

export const getStyles = (palette: Palette) => ({
  container: {
    width: '100%',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiListItemButton-root': {
      backgroundColor: 'transparent !important',
    },
    '&:hover': {
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
    },
    '&:hover svg': {
      color: palette.primary.main,
    },
  },
  containerSelected: {
    backgroundColor: palette.background.paper,
    color: palette.primary.main,
    '& svg': {
      color: palette.primary.main,
    },
  },
  nested: {
    paddingLeft: '3rem',
    '&:hover': {
      backgroundColor: palette.background.paper,
    },
  },
  macroSectionFontSize: {
    fontSize: '1.2rem',
  },
  macroSectionColourHidden: {
    color: palette.background.default,
  },
  sectionFontSize: {
    fontSize: '1.1rem',
  },
  macroSectionItem: {
    padding: '1.3rem',
    paddingLeft: '1.6rem',
    borderLeftColor: palette.background.default,
  },
  macroSectionItemClosed: {
    color: 'transparent !important',
  },
  macroSectionItemSelected: {
    borderLeftStyle: 'solid',
    paddingLeft: '1.3rem !important',
    borderLeftWidth: '0.3rem',
    borderLeftColor: palette.primary.main,
  },
  listItemIconRoot: {
    minWidth: 0,
    marginRight: '1rem',
    color:
      palette.mode === 'dark' ? palette.text.primary : palette.primary.main,
    // marginLeft: '0.3rem',
    '& svg': {
      fontSize: '1.8rem',
    },
  },
  listRoot: {
    whiteSpace: 'normal',
  },
  arrowIconRoot: {
    padding: '0.5rem !important',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  divider: {
    backgroundColor: palette.divider,
  },
});

type Props = {
  displayName: string;
  icon: string;
  path: string;
  onlyIcon?: boolean;
  current: boolean;
  sections: IStructureSection[];
  topDivider?: boolean;
};

const NavigationMenuItemComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const styles = getStyles(theme.palette);

  const { onlyIcon } = props;

  return (
    <>
      {!!props.topDivider && <Divider sx={{ ...styles.divider }} />}
      <Box
        sx={{
          ...styles.container,
          ...(props.current ? styles.containerSelected : {}),
        }}
        key={props.displayName}
      >
        <Link to={props.path} style={{ all: 'unset' }}>
          <ListItemButton
            sx={{
              ...styles.macroSectionItem,
              ...(props.current ? styles.macroSectionItemSelected : {}),
              ...(!onlyIcon ? styles.macroSectionItemClosed : {}),
            }}
          >
            <ListItemIcon sx={{ ...styles.listItemIconRoot }}>
              <SvgIcon>
                <path fill="currentColor" d={props.icon} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={props.displayName}
              sx={{
                [`& .${listItemTextClasses.primary}`]:
                  styles.macroSectionFontSize,
              }}
              style={{
                marginBottom: 0,
              }}
            />
          </ListItemButton>
        </Link>
        {onlyIcon && props.sections?.length > 1 && (
          <TooltipIconButton
            icon={<ArrowRight />}
            aria-label="theme"
            iconButtonStyle={{
              ...iconStyles.iconButtonRoot,
              ...iconStyles.listItemIconRoot,
              ...styles.arrowIconRoot,
            }}
            onClick={($event: any) => {
              $event.stopPropagation();
              dispatch(
                openNavMenu(
                  props.sections.map(
                    (e: IStructureSection, optionIndex: number) => ({
                      label: e.displayName || '',
                      path: `..${props.path}/${e.path}`,
                      disabled:
                        !hasActiveLicence &&
                        !!props.path &&
                        PREMIUM_ROUTES.get(props.path as RouteEnum)?.includes(
                          e.path as string
                        ),
                    })
                  ),
                  {
                    mouseX: $event.clientX,
                    mouseY: $event.clientY,
                  }
                )
              );
            }}
            tooltip=""
          ></TooltipIconButton>
        )}
      </Box>
    </>
  );
};

export default NavigationMenuItemComponent;
