import { Dispatch } from 'redux';
import { actionErrorInterceptor } from './error-interceptor';

export const actionTryCatchWrapper = (
  dispatch: Dispatch,
  action: string,
  callback: (...args: any[]) => any
) => {
  try {
    callback();
  } catch (err) {
    console.debug('ERROR', err);
    actionErrorInterceptor(dispatch, action, err);
  }
};
