import { Close } from '@mui/icons-material';
import { Box, Divider, Palette, Typography, useTheme } from '@mui/material';
import { IDescription } from '@prometeus/common';
import { useEffect, useState } from 'react';
import { ChartJsComponent } from 'react-chartjs3-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { CROSSHAIR_OPTIONS } from '../../constants/charts/options.constants';
import { COMMON_STYLES } from '../../constants/general.constants';
import { SCALE_LOADER_PROPS } from '../../constants/loading';
import { IOpenLargeChart } from '../../models/visuals.model';
import { getDescription } from '../../store/actions/descriptions.action';
import { RootState } from '../../store/reducers/root.reducer';
import { pxToRem } from '../../ui/functions';
import CustomDialogComponent from '../dialog.component';
import TooltipIconButton from '../tooltip-icon-button.component';

type Props = {
  title: string;
  open: boolean;
  onCloseHandler: () => void;
  data: IOpenLargeChart;
};

const getStyles = (palette: Palette) => ({
  dialogContent: {
    overflow: 'auto',
    padding: `${pxToRem(8)} ${pxToRem(24)}`,
  },
  listItemIconRoot: {
    '& svg': {
      fontSize: '1.8rem',
    },
  },
  chartContainerInfo: {
    height: '30rem',
  },
  chartContainerLarge: {
    height: '95%',
  },
  descriptionContainer: {
    padding: '1.2rem 1rem',
  },
  infoTitle: {
    color: palette.text.primary,
    textAlign: 'left',
  },
  infoText: {
    color: palette.text.primary,
    fontSize: '1.1rem',
  },
  chartTypeDescription: {
    flex: '1 1 0',
  },
  chartDescription: {
    flex: '2 1 0',
  },
  verticalDivider: {
    margin: '1.8rem 0.8rem',
    backgroundColor: palette.secondary.main,
    width: '0.2rem',
  },
});

const ChartDialogComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const dispatch = useDispatch();

  const { data } = props;
  const loading = useSelector((state: RootState) => state.descriptions.loading);
  const descriptionMap = useSelector(
    (state: RootState) => state.descriptions.descriptionMap
  );
  const [description, setDescription] = useState<IDescription>();

  useEffect(() => {
    if (!!data.info) {
      if (descriptionMap.has(data.titleLabel)) {
        const _description = descriptionMap.get(data.titleLabel);
        if (_description) {
          _description.chart.content = _description.chart.content.replaceAll(
            '|ratio|',
            _description?.ratio || data.titleLabel
          );
        }
        setDescription(_description);
      } else {
        dispatch(getDescription(data.titleLabel));
      }
    }
  }, [dispatch, data, descriptionMap]);

  return (
    <CustomDialogComponent
      open={props.open}
      onClose={props.onCloseHandler}
      maxWidth="lg"
      paperScrollPaperSx={!data.info ? styles.chartContainerLarge : {}}
      dialogTitle={
        <>
          <div>{props.title}</div>
          <TooltipIconButton
            icon={<Close />}
            tooltip="Close"
            onClick={props.onCloseHandler}
          />
        </>
      }
      dialogContent={
        <>
          {loading || !data.data ? (
            <div style={COMMON_STYLES.centered}>
              <ScaleLoader {...SCALE_LOADER_PROPS} />
            </div>
          ) : (
            <Box
              sx={{
                ...(!!data.info ? styles.chartContainerInfo : {}),
                ...(!data.info ? styles.chartContainerLarge : {}),
              }}
            >
              <ChartJsComponent
                type={data.type}
                data={data.data}
                options={data.options}
                enableVerticalCrosshair={data.type === 'line'}
                enableHorizontalCrosshair={data.type === 'line'}
                crosshairHorizontalOptions={CROSSHAIR_OPTIONS}
                crosshairVerticalOptions={CROSSHAIR_OPTIONS}
                transferChartImage={() => {}}
              />
            </Box>
          )}
          {!!data.info && (
            <div style={{ display: 'flex' }}>
              <Box
                sx={{
                  ...styles.descriptionContainer,
                  ...styles.chartTypeDescription,
                }}
              >
                <Typography gutterBottom variant="h6" sx={styles.infoTitle}>
                  Chart Type
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  align="justify"
                  sx={styles.infoText}
                >
                  {!!description?.chart?.content
                    ? description?.chart?.content
                        ?.split('\n')
                        ?.map((d: string) => <div key={d}>{d}</div>)
                    : 'No data available.'}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={styles.verticalDivider}
              />
              <Box
                sx={{
                  ...styles.descriptionContainer,
                  ...styles.chartDescription,
                }}
              >
                <Typography gutterBottom variant="h6" sx={styles.infoTitle}>
                  Description
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  align="justify"
                  sx={styles.infoText}
                >
                  {!!description?.content
                    ? description?.content
                        ?.split('\n')
                        ?.map((d: string) => <div key={d}>{d}</div>)
                    : 'No data available.'}
                </Typography>
              </Box>
            </div>
          )}
        </>
      }
      dialogContentSx={{
        ...styles.dialogContent,
        ...(!data.info ? styles.chartContainerLarge : {}),
      }}
    />
  );
};

export default ChartDialogComponent;
