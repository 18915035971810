import { Box, useTheme } from '@mui/material';
import {
  ICompanyPeerData,
  ITableColumnData,
  ScreenerLabelEnum,
} from '@prometeus/common';
import { throttle } from 'lodash';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFocus } from '../../../hooks/use-focus.hook';
import { ISecIndCty } from '../../../models/screener/structure.model';
import { getIcons } from '../../../store/actions/icons.action';
import {
  checkAllCompanies,
  initializeSectorData,
  resetSearchResult,
  searchPeerCompanies,
} from '../../../store/actions/peer-group.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { formBorder } from '../../../ui/functions';
import CardComponent, { getCardStyles } from '../../card.component';
import { SearchInputComponent } from '../../filters/filter-components/autocomplete-render-input.component';
import LoadingComponent from '../../loading.component';
import * as tableUtils from '../../table/table.constants';
import PeerGroupPeersTableComponent from './peer-group-peers-table.component';
import PeerGroupSearchTableComponent from './peer-group-search-table.component';

type Props = {
  onCloseHandler: () => void;
};

const SEARCH_TABLE_COLUMNS_SET = new Set([
  ScreenerLabelEnum.TICKER_ID,
  ScreenerLabelEnum.COMPANY_NAME,
  ScreenerLabelEnum.SECTOR,
  ScreenerLabelEnum.INDUSTRY,
  ScreenerLabelEnum.COUNTRY,
  ScreenerLabelEnum.MARKET_CAP,
  ScreenerLabelEnum.REVENUE,
]);

const SELECTED_TICKERS_TABLE_COLUMNS_SET = new Set([
  ScreenerLabelEnum.TICKER_ID,
  ScreenerLabelEnum.COMPANY_NAME,
]);

const PeerGroupDialogComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const cardStyles = getCardStyles(theme.palette);

  const peers = useSelector((state: RootState) => state.peerGroup.peers);
  const sectors = useSelector((state: RootState) => state.peerGroup.sectors);
  const selectedCountries = useSelector(
    (state: RootState) => state.peerGroup.selectedCountries
  );
  const selectedIndustries = useSelector(
    (state: RootState) => state.peerGroup.selectedIndustries
  );
  const peerGroupSearchResult = useSelector(
    (state: RootState) => state.peerGroup.peerGroupSearchResult
  );
  const peerGroupSearchResultTotal = useSelector(
    (state: RootState) => state.peerGroup.peerGroupSearchResultTotal
  );
  const loading = useSelector(
    (state: RootState) => state.peerGroup.checkAllLoading
  );
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  useEffect(() => {
    if (!!peerGroupSearchResult?.length) {
      dispatch(
        getIcons(peerGroupSearchResult.map((e: ICompanyPeerData) => e.tickerId))
      );
    }
  }, [dispatch, peerGroupSearchResult]);

  const screenerColumnSetup = useSelector(
    (state: RootState) => state.screener.structure.screenerColumnSetup
  );

  useEffect(() => {
    dispatch(initializeSectorData());
  }, [dispatch]);

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleRequest = useCallback(
    throttle(
      (
        nextValue: string,
        countries: string[],
        industries: string[],
        order: tableUtils.Order,
        orderBy: keyof ICompanyPeerData,
        page: number,
        rowsPerPage: number
      ) =>
        dispatch(
          searchPeerCompanies(
            nextValue,
            countries,
            industries,
            order,
            orderBy,
            page,
            rowsPerPage
          )
        ),
      1000
    ),
    [] // will be created only once initially
  );

  const checkAllPeersHandler = () => {
    const searchValue = searchInputValue.trim().toLowerCase();
    const selectedAllCountries = selectedCountries[0]?.selected;
    if (searchValue.length || !selectedAllCountries || !sectors[0]?.selected) {
      dispatch(
        checkAllCompanies(
          currentCompanyTickerId as string,
          searchValue,
          selectedCountries
            .slice(1)
            .filter((e: ISecIndCty) => e.selected)
            .map((e: ISecIndCty) => e.label.split(' -')[0]),
          selectedIndustries
        )
      );
    }
  };

  /* Search shortcut */
  const [autocompleteRef, setAutocompleteFocus] = useFocus();
  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.ctrlKey && e.code === 'KeyF') {
        e.preventDefault();
        (setAutocompleteFocus as () => void)();
      }
    });
  }, [setAutocompleteFocus]);

  // Pagination
  /* Orderings */
  const [order, setOrder] = useState<tableUtils.Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ICompanyPeerData>('tickerId');

  /* Pages */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const searchValue = searchInputValue.trim().toLowerCase();

    // Check if selectedAllCountries is selected --> Meaning both selected (all) or indeterminate (some)
    const selectedAllCountries = selectedCountries[0]?.selected;

    if (searchValue.length || !selectedAllCountries || !sectors[0]?.selected) {
      throttleRequest(
        searchValue,
        selectedCountries
          .slice(1)
          .filter((e: ISecIndCty) => e.selected)
          .map((e: ISecIndCty) => e.label.split(' -')[0]),
        selectedIndustries,
        order,
        orderBy,
        page,
        rowsPerPage
      );
    } else {
      dispatch(resetSearchResult());
    }
  }, [
    dispatch,
    throttleRequest,
    searchInputValue,
    selectedCountries,
    selectedIndustries,
    sectors,
    order,
    orderBy,
    page,
    rowsPerPage,
  ]);

  return (
    <>
      {loading && <LoadingComponent transparent={true} />}
      <SearchInputComponent
        label=""
        placeholder="Type the ticker or the company name"
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        notArrowAdornment={true}
        inputRef={autocompleteRef as MutableRefObject<any>}
        hasFilters={true}
        hasFiltersType="peerGroup"
        textFieldStyle={{}}
        onChange={(event) => {
          setSearchInputValue(event.target.value);
        }}
        sx={{
          border: formBorder(theme.palette),
          '& input': {
            padding: '1.3rem 0rem !important',
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '1rem',
        }}
      >
        <Box
          sx={cardStyles.nullifyCard}
          style={{ flex: 3 /*  width: '70%' */ }}
        >
          <CardComponent titleLabel="Search Result">
            <PeerGroupSearchTableComponent
              data={peerGroupSearchResult}
              searchValue={searchInputValue}
              tableColumns={screenerColumnSetup.filter((e: ITableColumnData) =>
                SEARCH_TABLE_COLUMNS_SET.has(e.label as ScreenerLabelEnum)
              )}
              peers={peers}
              currentTicker={currentCompanyTickerId || ''}
              checkAllPeers={checkAllPeersHandler}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={peerGroupSearchResultTotal}
            />
          </CardComponent>
        </Box>
        <Box sx={{ ...cardStyles.nullifyCard, flex: 2 }}>
          <CardComponent titleLabel="Selected Peers">
            <PeerGroupPeersTableComponent
              data={Array.from(peers.values())}
              tableColumns={screenerColumnSetup.filter((e: ITableColumnData) =>
                SELECTED_TICKERS_TABLE_COLUMNS_SET.has(
                  e.label as ScreenerLabelEnum
                )
              )}
            ></PeerGroupPeersTableComponent>
          </CardComponent>
        </Box>
      </Box>
    </>
  );
};

export default PeerGroupDialogComponent;
