import { Close, Tune } from '@mui/icons-material';
import {
  Box,
  Fab,
  Grow,
  Stack,
  Tab,
  Tabs,
  Typography,
  tabsClasses,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { PREMIUM_FEATURE_TOOLTIP } from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { useHandleQueryParams } from '../hooks/use-handle-query-params.hook';
import { AuthenticatedRoute } from '../navigation/route-wrappers';
import {
  AdminRouteEnum,
  PREMIUM_ROUTES,
  RouteEnum,
} from '../navigation/routes.constants';
import { openActiveFreeTrialDialog } from '../store/actions/modals.action';
import { RootState } from '../store/reducers/root.reducer';
import { a11yTabProps, getTabStyles } from '../styles/tab.styles';
import BrandFlame from './brand-flame.component';
import CompanyHeaderComponent from './company-header.component';
import CustomDialogComponent from './dialog.component';
import EnhancedTooltip from './enhanced-tooltip.component';
import TooltipIconButton from './tooltip-icon-button.component';

type GenericT = { [field: string]: any };

export interface ITab<T = GenericT> {
  label: string;
  route: string;
  component: React.FunctionComponent<T>;
  extra?: T;
}

type Props<T = GenericT> = {
  pageRoute: RouteEnum | AdminRouteEnum;
  tabs: ITab<T>[];
  filtersComponent?: React.FunctionComponent<any>;
  routeComponent?: React.FunctionComponent<any>;
  hideCompanyHeader?: boolean;
  history?: RouteComponentProps['history'];
  isDisabled?: (tab: ITab<T>, index: number) => boolean;
};

function PageSkeletonComponent<T = GenericT>(props: Props<T>) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getTabStyles(theme.palette);
  const { path, url } = useRouteMatch();

  const {
    tabs,
    pageRoute,
    filtersComponent: FiltersComponent,
    routeComponent,
  } = props;
  const RouteComponent = routeComponent || AuthenticatedRoute;

  const [tabValue, setTabValue] = useState<string>('');

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const defaultIsDisabled = (tab: ITab<T>) =>
    !!hasActiveLicence || !pageRoute
      ? false
      : PREMIUM_ROUTES.get(pageRoute as RouteEnum)?.includes(tab.route);
  const isDisabled = props.isDisabled || defaultIsDisabled;

  const location = useLocation();
  useEffect(() => {
    const lastPath = location.pathname.split('/')[2];
    setTabValue(lastPath);
  }, [location]);

  /*  */
  useHandleQueryParams();

  /* FiltersComponent */
  const filtersComponent = !!FiltersComponent ? (
    <FiltersComponent
      tab={tabValue || tabs[0].route}
      parentHistory={props.history}
    />
  ) : (
    <></>
  );

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const [openFiltersDialog, setOpenFiltersDialog] = useState<boolean>(false);

  return (
    <Router>
      <Box sx={styles.root}>
        <Box
          sx={{
            ...styles.skeletonContainer,
            ...(!props.hideCompanyHeader ? styles.containerCompanyHeader : {}),
          }}
        >
          <Box
            sx={{
              flex: '3 1 0',
            }}
          >
            {!props.hideCompanyHeader && <CompanyHeaderComponent />}
            <Grow in={true}>
              {/* Used to avoid scrollTop error */}
              <Tabs
                action={(ref) => ref?.updateIndicator()}
                sx={{
                  ...styles.tabsRoot,
                  [`& .${tabsClasses.indicator}`]: styles.indicator,
                }}
                value={tabValue || tabs[0].route}
                indicatorColor="primary"
                textColor="secondary"
                aria-label={`${pageRoute} tabs`}
              >
                {tabs.map((e: ITab<T>, i: number) => {
                  const key = `${e.label}-${i}`;
                  const disabled = !!isDisabled ? isDisabled(e, i) : false;

                  const innerTabComponent = (
                    <Tab
                      key={key}
                      label={
                        disabled ? (
                          <Stack direction="row" alignItems="center">
                            <span className="tab-label">{e.label}</span>
                            <BrandFlame
                              sx={{
                                marginLeft: '0.4rem',
                                transform: 'scale(1.2)',
                              }}
                            />
                          </Stack>
                        ) : (
                          <span className="tab-label">{e.label}</span>
                        )
                      }
                      {...a11yTabProps(pageRoute, i)}
                      component={Link as any}
                      to={`${url}/${e.route}`}
                      tabIndex={i}
                      value={e.route}
                      onClick={() => {
                        setTabValue(e.route);
                      }}
                      disabled={disabled}
                      sx={{
                        ...(isPortraitMobile
                          ? {
                              '& span': {
                                fontSize: '1.4rem',
                              },
                            }
                          : {}),
                        ...(disabled
                          ? {
                              opacity: '1 !important',
                              '& span.tab-label': {
                                opacity: '0.38 !important',
                              },
                              '&:hover': {
                                color: `${theme.palette.text.secondary} !important`,
                              },
                            }
                          : {}),
                      }}
                    />
                  );

                  return disabled ? (
                    <EnhancedTooltip
                      key={key}
                      title={PREMIUM_FEATURE_TOOLTIP}
                      onInternalClick={() => {
                        dispatch(openActiveFreeTrialDialog());
                      }}
                    >
                      {innerTabComponent}
                    </EnhancedTooltip>
                  ) : (
                    innerTabComponent
                  );
                })}
              </Tabs>
            </Grow>
          </Box>
        </Box>
        <Box
          sx={{
            ...styles.displayFlex,
            ...styles.tabView,
            ...styles.overflowY,
            ...(!props.hideCompanyHeader ? styles.tabViewCompanyHeader : {}),
            ...(!!props.hideCompanyHeader ? styles.tabViewNoCompanyHeader : {}),
            ...(isPortraitMobile ? { marginTop: '0.8rem' } : {}),
          }}
        >
          <Box sx={{ ...styles.tabsPageMacrocolumn, ...styles.macroColumn }}>
            <Switch>
              {tabs
                .filter((e: ITab<T>, i: number) =>
                  !!isDisabled ? !isDisabled(e, i) : true
                )
                .map((e: ITab<T>) => {
                  const TabComponent = e.component;
                  return (
                    <RouteComponent
                      path={`${path}/${e.route}`}
                      key={`${path}/${e.route}`}
                    >
                      <TabComponent {...(e?.extra as any)} />
                    </RouteComponent>
                  );
                })}
              <RouteComponent path={path}>
                <Redirect
                  to={{
                    pathname: `${url}/${tabs[0].route}`,
                  }}
                />
              </RouteComponent>
              <Route path="*">
                <Redirect
                  to={{
                    pathname: `${path}`,
                  }}
                />
              </Route>
            </Switch>
          </Box>
          {/* Filters column */}
          {!!FiltersComponent && (
            <>
              {isPortraitMobile ? (
                <>
                  <Fab
                    color="primary"
                    size="small"
                    sx={{
                      position: 'absolute',
                      bottom: '3rem',
                      right: '3rem',
                      '& svg': {
                        height: '3rem',
                        width: '3rem',
                      },
                    }}
                    onClick={() => {
                      // Trigger filters drawer
                      setOpenFiltersDialog(true);
                    }}
                  >
                    <Tune />
                  </Fab>
                  <CustomDialogComponent
                    maxWidth="lg"
                    dialogTitle={
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: '100%' }}
                      >
                        <Typography variant="h3">Filters</Typography>
                        <TooltipIconButton
                          icon={<Close />}
                          tooltip="Close"
                          onClick={() => {
                            setOpenFiltersDialog(false);
                          }}
                          iconButtonStyle={{
                            transform: 'scale(1.8)',
                          }}
                        />
                      </Stack>
                    }
                    dialogContent={filtersComponent}
                    open={openFiltersDialog}
                    onClose={() => setOpenFiltersDialog(false)}
                    paperSx={{
                      height: '85%',
                      padding: '2rem',
                      borderRadius: '2rem',
                    }}
                  />
                </>
              ) : (
                <Box sx={{ ...styles.column, ...styles.filterColumn }}>
                  {filtersComponent}
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Router>
  );
}

export default PageSkeletonComponent;
