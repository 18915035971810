import { PYTHON_ANYWHERE_URL } from './constants';

export const API_URL = '/api/';
export const ADMIN_API_URL = `${API_URL}admin/`;

/* Authentication */
export const AUTH_URL = `${API_URL}auth/`;
export const DUMMY_AUTH_URL = `${AUTH_URL}dummy`;
export const RENEW_TOKEN_AUTH_URL = `${AUTH_URL}renew-token`;
export const DEV_SIGNIN_AUTH_URL = `${AUTH_URL}dev-signin`;
export const GOOGLE_AUTH_URL = `${AUTH_URL}google`;
export const MICROSOFT_AUTH_URL = `${AUTH_URL}microsoft`;
export const VERIFY_OAUTH_URL = `${API_URL}verify-oauth`;
export const GET_LOGGED_USER_DATA_URL = `${API_URL}get-logged-user-data`;
/* ReCAPTCHA */
export const VERIFY_RECAPTCHA_URL = `${API_URL}verify-recaptcha/`;

/* Companies */
export const COMPANIES_URL = `${API_URL}companies`;
export const COMPANY_URL = `${API_URL}company`;
export const COMPANY_COORDINATES_URL = `${API_URL}coordinates`;
export const SECTORS_URL = `${API_URL}sectors`;
export const FETCH_PEER_LIST_DATA_URL = `${COMPANIES_URL}/peer-list-data`;
export const MAP_OVERVIEW_DATA_API_URL = `${COMPANIES_URL}/market-coverage`;
export const GET_MOST_VIEWED_COMPANIES_URL = `${COMPANIES_URL}/most-viewed`;
export const ADMIN_COMPANIES_URL = `${ADMIN_API_URL}companies/`;
export const GET_COMPANIES_COUNT_URL = `${ADMIN_COMPANIES_URL}count`;
export const GET_COMPANIES_DUMP_URL = `${ADMIN_COMPANIES_URL}get-dump`;
export const LOAD_COMPANIES_DUMP_URL = `${ADMIN_COMPANIES_URL}load-dump`;
export const COMPANIES_DELTA_URL = `${ADMIN_COMPANIES_URL}delta`;
export const GET_COMPANY_COUNTERS_URL = `${ADMIN_COMPANIES_URL}dump-counters`;
export const LOAD_COMPANY_COUNTERS_URL = `${ADMIN_COMPANIES_URL}load-counters`;

/* Peer Group */
export const PEER_SEARCH_URL = `${COMPANIES_URL}/peer-search`;
export const PEER_SEARCH_GET_ALL_URL = `${PEER_SEARCH_URL}/get-all`;
const PEER_COMPANIES_URL = `${API_URL}peer-companies/`;
export const REGIONAL_COMPANIES_URL = `${PEER_COMPANIES_URL}regional`;
export const INTERNATIONAL_COMPANIES_URL = `${PEER_COMPANIES_URL}international`;

/* Others */
export const FEEDBACK_URL = `${API_URL}feedback`;
export const SEND_RATING_URL = `${API_URL}rating`;
export const GET_NUMBER_OF_ONLINE_USERS_URL = `${API_URL}online-users`;

/* Descriptions */
export const DESCRIPTION_URL = `${API_URL}description`;

/* Icons */
export const GET_ICONS_URL = `${API_URL}icon`;
export const ADMIN_ICON_URL = `${ADMIN_API_URL}/icon/`;
export const FETCH_REMOTE_ICON_URL = `${ADMIN_ICON_URL}fetch`;
export const SEARCH_ICONS_URL = `${ADMIN_ICON_URL}search`;
export const GET_TOTAL_ICONS_URL = `${ADMIN_ICON_URL}total`;

/* Managers */
export const MANAGERS_URL = `${API_URL}manager`;
export const MANAGER_URL = `${ADMIN_API_URL}manager`;
export const FETCH_REMOTE_MANAGER_URL = `${MANAGER_URL}/fetch`;
export const SEARCH_MANAGERS_URL = `${MANAGER_URL}/search`;
export const GET_TOTAL_MANAGERS_URL = `${MANAGER_URL}/total`;
export const UPDATE_MANAGERS_URL = `${MANAGERS_URL}/update`;
export const FETCH_MANAGERS_URL = `${MANAGER_URL}/fetch-managers`;

/* User */
export const USER_URL = `${API_URL}user`;
export const SUBMIT_USER_REGISTRATION_INFORMATION_URL = `${USER_URL}/registration`;
export const UPDATE_USER_REGISTRATION_INFORMATION_URL = `${USER_URL}/update-information`;
export const FETCH_LICENCES_HISTORY_URL = `${USER_URL}/licences-history`;
export const ACTIVATE_FREE_TRIAL_URL = `${USER_URL}/activate-free-trial`;
export const USER_ADMIN_URL = `${ADMIN_API_URL}user`;
export const USERS_ADMIN_URL = `${USER_ADMIN_URL}s`;
export const REVOKE_USER_LICENCE_URL = `${USER_ADMIN_URL}/revoke-licence`;

/* PayPal */
export const PAYPAL_URL = `${API_URL}paypal`;
export const PAYPAL_CANCEL_SUBSCRIPTION_URL = `${PAYPAL_URL}/cancel-subscription`;
export const PAYPAL_API_URL = 'https://api-m.paypal.com/';
export const PAYPAL_SANDBOX_API_URL = 'https://api-m.sandbox.paypal.com/';

/* Plans */
export const GET_PLAN_DETAILS = `${API_URL}plan`;
export const PLAN_URL = `${ADMIN_API_URL}plan`;

/***************************************
 *                 ADMIN               *
 ***************************************/

/* Dump */
export const USER_DUMP_URL = `${USER_URL}/dump`;
export const USER_LOAD_DUMP_URL = `${USER_URL}/load-dump`;

export const ICON_DUMP_URL = `${ADMIN_ICON_URL}dump`;
export const ICON_LOAD_DUMP_URL = `${ADMIN_ICON_URL}load-dump`;

export const MANAGER_DUMP_URL = `${MANAGER_URL}/dump`;
export const MANAGER_LOAD_DUMP_URL = `${MANAGER_URL}/load-dump`;

export const PLAN_DUMP_URL = `${PLAN_URL}/dump`;
export const PLAN_LOAD_DUMP_URL = `${PLAN_URL}/load-dump`;

/* Logs */
export const GET_BACKEND_LOGS_URL = `${ADMIN_API_URL}logs`;

/* Standard Icon */
export const RESIZE_IMAGE_URL = `${PYTHON_ANYWHERE_URL}api/icon`;
