import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyCtySecIndFilters,
  selectIndustryCountries,
  selectIndustryIndustries,
  selectIndustrySectors,
} from '../../../pages/sector-analysis/utils/visuals.actions';
import { A_PAGE_LIMIT } from '../../../pages/sector-analysis/utils/visuals.constants';
import { RootState } from '../../../store/reducers/root.reducer';
import { AFiltersStyles } from '../aggregated-filters/a-filters.component';
import CountrySelectorComponent from '../filter-components/country-selector.component';
import SectorIndustrySelectorComponent from '../filter-components/sector-industry-selector.component';

const IndustryTabFiltersComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  /* Get data from state manager */
  const industryCountries = useSelector(
    (state: RootState) => state.visuals.sector.industryCountries
  );
  const industrySectors = useSelector(
    (state: RootState) => state.visuals.sector.industrySectors
  );
  const industrySelectedIndustries = useSelector(
    (state: RootState) => state.visuals.sector.industrySelectedIndustries
  );

  const countryIdsClickHandler = useCallback(
    (optionIndex: number) => {
      dispatch(selectIndustryCountries(optionIndex));
    },
    [dispatch]
  );

  const onCloseHandlerCallback = useCallback(() => {
    dispatch(
      applyCtySecIndFilters(
        industryCountries,
        industrySectors,
        industrySelectedIndustries
      )
    );
  }, [
    dispatch,
    industryCountries,
    industrySectors,
    industrySelectedIndustries,
  ]);

  return (
    <>
      <CountrySelectorComponent
        label="Countries"
        placeholder={'Select countries'}
        dataList={industryCountries}
        onClickCheckHandler={countryIdsClickHandler}
        onCloseHandler={onCloseHandlerCallback}
        sx={AFiltersStyles.customSelect}
      />
      <SectorIndustrySelectorComponent
        label="Sectors & Industries"
        placeholder={`Select up to ${A_PAGE_LIMIT} industries`}
        dataList={industrySectors.slice(1)}
        limit={A_PAGE_LIMIT}
        sectorAction={selectIndustrySectors}
        industryAction={selectIndustryIndustries}
        selectedIndustries={industrySelectedIndustries?.length || 0}
        onCloseHandler={onCloseHandlerCallback}
        filterTabSecInd="industry"
        sx={AFiltersStyles.customSelect}
        textFieldSx={
          !industrySelectedIndustries?.length
            ? {
                '& fieldset': {
                  border: `solid 0.05rem ${theme.palette.divider} !important`,
                  borderRadius: '2rem',
                  ...AFiltersStyles.missingHighlightBorder,
                },
              }
            : {}
        }
      />
    </>
  );
};

export default IndustryTabFiltersComponent;
