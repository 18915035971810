import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerFinancialStrengthFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import {
  CommonBlockComponent,
  COMMON_SCREENER_ROW_HEIGHT,
} from '../utils/constants';

const FinancialStrengthTab = () => {
  const dispatch = useDispatch();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.financialStrength
      : null
  );

  const financialStrengthSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(
        ScreenerTabNameEnum.FINANCIAL_STRENGTH,
        block,
        filter,
        value
      )
    );
  };

  return (
    <>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* Current Ratio */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
            cardBlock={ScreenerFinancialStrengthFilterCardEnum.CURRENT_RATIO}
            data={tabData?.currentRatio}
            setFilterValueHandler={financialStrengthSetFilterValue}
            isLarge={true}
          />
        </div>
        {/* Debt To Equity */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
            cardBlock={ScreenerFinancialStrengthFilterCardEnum.DEBT_TO_EQUITY}
            data={tabData?.debtToEquity}
            setFilterValueHandler={financialStrengthSetFilterValue}
            isLarge={true}
          />
        </div>
      </div>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* Quick Ratio */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
            cardBlock={ScreenerFinancialStrengthFilterCardEnum.QUICK_RATIO}
            data={tabData?.quickRatio}
            setFilterValueHandler={financialStrengthSetFilterValue}
            isLarge={true}
          />
        </div>
        {/* Debt Payback */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
            cardBlock={ScreenerFinancialStrengthFilterCardEnum.DEBT_PAYBACK}
            data={tabData?.debtPayback}
            setFilterValueHandler={financialStrengthSetFilterValue}
            isLarge={true}
          />
        </div>
      </div>
    </>
  );
};

export default FinancialStrengthTab;
