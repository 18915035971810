import {
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Palette,
  SxProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useGlobalStyles } from '../hooks/use-global-styles.hook';
import { formBorder, pxToRem } from '../ui/functions';

export const getDialogStyles = (palette: Palette) => ({
  paperFullWidth: {
    width: `calc(100% - ${pxToRem(64)})`,
  },
  paperWidthXl: {
    maxWidth: pxToRem(1920),
  },
  paperWidthLg: {
    maxWidth: pxToRem(1600),
  },
  paperWidthSm: {
    maxWidth: pxToRem(600),
  },
  paperScrollPaper: {
    maxHeight: `calc(100% - ${pxToRem(64)})`,
    backgroundColor: palette.background.paper,
    // borderTopStyle: 'solid',
    // borderTopColor: palette.primary.main,
    border: formBorder(palette),
    borderWidth: '0.05rem !important',
  },
  paper: {
    margin: pxToRem(32),
    borderWidth: '0.3rem',
  },
  paperRounded: {
    borderRadius: pxToRem(4),
  },
  dialogTitle: {
    padding: '0.5rem 1.75rem 0rem',
    color: palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    overflow: 'hidden',
    padding: `${pxToRem(8)} ${pxToRem(24)}`,
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  maxWidth: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  paperSx?: SxProps;
  paperScrollPaperSx?: SxProps;
  sx?: SxProps;
  dialogTitleSx?: SxProps;
  dialogContentSx?: SxProps;
};

const CustomDialogComponent = (props: Props) => {
  /* Styles */
  const theme = useTheme();
  const styles = getDialogStyles(theme.palette);
  const globalStyles = useGlobalStyles();

  const { open } = props;

  const onCloseDialogHandler = () => {
    props.onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialogHandler}
      fullWidth
      maxWidth={props.maxWidth}
      scroll={'paper'}
      sx={{
        ...globalStyles.body,
        [`& .${dialogClasses.paperScrollPaper}`]: {
          ...styles.paperScrollPaper,
          ...props.paperScrollPaperSx,
        },
        [`& .${dialogClasses.paperWidthSm}`]: styles.paperWidthSm,
        [`& .${dialogClasses.paperWidthLg}`]: styles.paperWidthLg,
        [`& .${dialogClasses.paperWidthXl}`]: styles.paperWidthXl,
        [`& .${dialogClasses.paperFullWidth}`]: styles.paperFullWidth,
        ...props.sx,
      }}
      PaperProps={{
        sx: {
          ...styles.paper,
          ...styles.paperRounded,
          ...props.paperSx,
          backgroundImage: 'none',
        },
      }}
    >
      <DialogTitle
        sx={{
          ...styles.dialogTitle,
          ...props.dialogTitleSx,
        }}
      >
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent
        sx={{
          ...styles.dialogContent,
          ...props.dialogContentSx,
        }}
      >
        {props.dialogContent}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialogComponent;
