import { ICompanyMinimal, IconShapeEnum } from '@prometeus/common';
import { DumpType } from '../general.model';

export interface IIconCompany extends ICompanyMinimal {
  icon: string;
  url?: string;
  manualIcon?: boolean;
  shape?: IconShapeEnum;
}

export interface IIconsState {
  totalIcons?: number;
  totalIconsLoading?: boolean;
  loading: boolean;
  error?: string;
  iconDump?: DumpType;
  searchedCompanies: IIconCompany[];
}

/***************************************
 *                 Dump                *
 ***************************************/
/* ICON_DUMP_DB */
export const ICON_DUMP_DB = 'ICON_DUMP_DB';
export interface IIconDumpDB {
  type: typeof ICON_DUMP_DB;
}

/* ICON_DUMP_DB_SUCCESS */
export const ICON_DUMP_DB_SUCCESS = 'ICON_DUMP_DB_SUCCESS';
export interface IIconDumpDBSuccess {
  type: typeof ICON_DUMP_DB_SUCCESS;
  payload: DumpType;
}

/* ICON_DUMP_DB_FAIL */
export const ICON_DUMP_DB_FAIL = 'ICON_DUMP_DB_FAIL';
export interface IIconDumpDBFail {
  type: typeof ICON_DUMP_DB_FAIL;
  payload: string;
}

export type IconDumpDBDispatchTypes =
  | IIconDumpDB
  | IIconDumpDBSuccess
  | IIconDumpDBFail;

/***************************************
 *             Search Icons            *
 ***************************************/
/* SEARCH_ICONS */
export const SEARCH_ICONS = 'SEARCH_ICONS';
export interface ISearchIcon {
  type: typeof SEARCH_ICONS;
}

/* SEARCH_ICONS_SUCCESS */
export const SEARCH_ICONS_SUCCESS = 'SEARCH_ICONS_SUCCESS';
export interface ISearchIconSuccess {
  type: typeof SEARCH_ICONS_SUCCESS;
  payload: IIconCompany[];
}

/* SEARCH_ICONS_FAIL */
export const SEARCH_ICONS_FAIL = 'SEARCH_ICONS_FAIL';
export interface ISearchIconFail {
  type: typeof SEARCH_ICONS_FAIL;
  payload: string;
}

export type SearchIconDispatchTypes =
  | ISearchIcon
  | ISearchIconSuccess
  | ISearchIconFail;

/***************************************
 *                Delete               *
 ***************************************/
/* DELETE_ICON */
export const DELETE_ICON = 'DELETE_ICON';
export interface IDeleteIcon {
  type: typeof DELETE_ICON;
}

/* DELETE_ICON_FAIL */
export const DELETE_ICON_FAIL = 'DELETE_ICON_FAIL';
export interface IDeleteIconFail {
  type: typeof DELETE_ICON_FAIL;
  payload: string;
}

export type DeleteIconDispatchTypes =
  | IDeleteIcon
  | SearchIconDispatchTypes
  | IDeleteIconFail;

/***************************************
 *                Upsert               *
 ***************************************/
/* UPSERT_ICON */
export const UPSERT_ICON = 'UPSERT_ICON';
export interface IUpsertIcon {
  type: typeof UPSERT_ICON;
}

/* UPSERT_ICON_FAIL */
export const UPSERT_ICON_FAIL = 'UPSERT_ICON_FAIL';
export interface IUpsertIconFail {
  type: typeof UPSERT_ICON_FAIL;
  payload: string;
}

export type UpsertIconDispatchTypes =
  | IUpsertIcon
  | SearchIconDispatchTypes
  | IUpsertIconFail;

/***************************************
 *             Total Icons          *
 ***************************************/
/* GET_TOTAL_ICONS */
export const GET_TOTAL_ICONS = 'GET_TOTAL_ICONS';
export interface IGetTotalIcons {
  type: typeof GET_TOTAL_ICONS;
}

/* GET_TOTAL_ICONS_SUCCESS */
export const GET_TOTAL_ICONS_SUCCESS = 'GET_TOTAL_ICONS_SUCCESS';
export interface IGetTotalIconsSuccess {
  type: typeof GET_TOTAL_ICONS_SUCCESS;
  payload: number;
}

/* GET_TOTAL_ICONS_FAIL */
export const GET_TOTAL_ICONS_FAIL = 'GET_TOTAL_ICONS_FAIL';
export interface IGetTotalIconsFail {
  type: typeof GET_TOTAL_ICONS_FAIL;
  payload: string;
}

export type GetTotalIconsDispatchTypes =
  | IGetTotalIcons
  | IGetTotalIconsSuccess
  | IGetTotalIconsFail;

/***************************************
 *         Dispatch Action Types       *
 ***************************************/
export type DispatchActionTypes =
  | IconDumpDBDispatchTypes
  | DeleteIconDispatchTypes
  | UpsertIconDispatchTypes
  | SearchIconDispatchTypes
  | GetTotalIconsDispatchTypes;
