import { MenuProps, SxProps } from '@mui/material';
import CustomSelectComponent from './select.component';
import TextField from './text-field.component';

// TODO change disabled textfield style

type Props = {
  editable?: boolean;
  label: string;
  value?: string;
  setValue: (value: string) => void;
  options: string[];
  textFieldSx?: SxProps;
  selectSx?: SxProps;
  selectBoxSx?: SxProps;
  selectFormControlSx?: SxProps;
  MenuProps?: Partial<MenuProps>;
};

const SelectTextField = (props: Props) => {
  const { editable, label, value, setValue, options } = props;

  return (
    <>
      {editable ? (
        <CustomSelectComponent
          boxLabel={label}
          option={value}
          options={options}
          setOption={setValue}
          selectSx={{ ...props.selectSx }}
          boxSx={{ ...props.selectBoxSx }}
          formControlSx={props.selectFormControlSx}
          MenuProps={props.MenuProps}
        />
      ) : (
        <TextField
          variant="outlined"
          label={label}
          value={value}
          sx={{ ...(props.textFieldSx as any) }}
          disabled
        />
      )}
    </>
  );
};

export default SelectTextField;
