import { Box, Snackbar } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAxiosError,
  setGlobalError,
} from '../../store/actions/error-handling.action';
import { setStructureError } from '../../store/actions/structure.action';
import { RootState } from '../../store/reducers/root.reducer';

const MyAlert = (props: AlertProps) => {
  return <Alert elevation={6} variant="filled" {...props} />;
};

const ErrorSnackbarComponent = () => {
  const dispatch = useDispatch();

  /* Snackbar */
  const apiError = useSelector((state: RootState) => state.structure.error);
  const globalError = useSelector(
    (state: RootState) => state.errorHandling.error
  );
  const axiosError = useSelector(
    (state: RootState) => state.errorHandling.axiosError
  );

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const openSnackBar = () => {
    setSnackBarOpen(true);
  };
  const closeSnackBar = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    resetErrors();
  };
  const [snackBarError, setSnackBarError] = useState<string>();

  const resetErrors = () => {
    dispatch(setGlobalError(undefined));
    dispatch(setAxiosError(undefined));
    dispatch(setStructureError(undefined));
  };

  useEffect(() => {
    if (!!apiError) {
      setSnackBarError(apiError);
      openSnackBar();
    } else if (!!axiosError) {
      setSnackBarError(axiosError.message);
      openSnackBar();
    } else if (
      !!globalError?.message &&
      !globalError.message?.includes('ResizeObserver') // Ignore this message
    ) {
      setSnackBarError(globalError.message);
      console.debug('[GLOBAL ERROR]', globalError?.message);
      openSnackBar();
    }
  }, [dispatch, apiError, globalError, axiosError]);

  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={2000}
      onClose={closeSnackBar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Box
        sx={{
          '& .MuiAlert-message': {
            padding: '0.5rem 0rem',
            marginTop: '0.5rem',
          },
        }}
      >
        {/* Used to avoid scrollTop error */}
        <MyAlert onClose={closeSnackBar} severity="error">
          {snackBarError}
        </MyAlert>
      </Box>
    </Snackbar>
  );
};

export default ErrorSnackbarComponent;
