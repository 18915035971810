import {
  Button,
  ButtonGroup,
  Palette,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material';
import CSS from 'csstype';
import { COMMON_COLORS } from '../../../ui/colors';
import { maskBorderShadow } from '../../../ui/functions';

type Props = {
  values: boolean[];
  labels: string[] | number[];
  title?: string;
  handleClick: (value: boolean[]) => void;
  typographyVariant?: TypographyVariant;
  containerStyle?: CSS.Properties;
};

const getStyles = (palette: Palette) => ({
  maskBorderShadow: {
    ...maskBorderShadow(palette),
  },
  buttonGroupRoot: {
    display: 'flex',
    borderRadius: '2rem !important',
    padding: '0.2rem',
  },
  buttonGroupRootMarginBottom: {
    marginBottom: '1rem',
  },
  buttonRoot: {
    color: palette.text.primary,
    fontSize: '0.7rem',
    borderRadius: '2rem !important',
    border: 'none !important',
    '&:hover': {
      color:
        palette.mode === 'dark' ? COMMON_COLORS.contrastPrimaryText : 'black',
      backgroundColor: 'none',
    },
    width: '50% !important',
    minWidth: '2rem !important',
  },
  buttonRootSelected: {
    borderColor: palette.background.default,
    borderRadius: '0 !important',
    color: COMMON_COLORS.contrastPrimaryText,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  buttonRootLeft: {
    borderTopLeftRadius: '2rem !important',
    borderBottomLeftRadius: '2rem !important',
  },
  buttonRootRight: {
    borderTopRightRadius: '2rem !important',
    borderBottomRightRadius: '2rem !important',
  },
  typography: {
    marginBottom: '0.5rem',
    marginTop: '0.1rem',
  },
});

const ButtonGroupScreenerComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const handleClick = (index: number) => {
    const tmp: boolean[] = props.values;
    tmp[index] = !tmp[index];
    props.handleClick([...tmp]);
  };

  return (
    <div>
      <Typography variant="subtitle1" align="left" sx={styles.typography}>
        {props.title}
      </Typography>
      <ButtonGroup
        sx={{ ...styles.buttonGroupRoot, ...styles.maskBorderShadow }}
      >
        {props.values.map((e: boolean, index: number) => (
          <Button
            key={index}
            sx={{
              ...styles.buttonRoot,
              ...(e ? styles.buttonRootSelected : {}),
              ...(e && (!index || !props.values[index - 1])
                ? styles.buttonRootLeft
                : {}),
              ...(e &&
              (index === props.values.length - 1 || !props.values[index + 1])
                ? styles.buttonRootRight
                : {}),
            }}
            onClick={() => handleClick(index)}
          >
            {props.labels[index]}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default ButtonGroupScreenerComponent;
