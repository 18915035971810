import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/root.reducer';
import IconLogo from './IconLogo';

type Props = {
  src?: string;
  rest?: any;
};

// Double component --> work around, otherwise it did not re-render
const IconComponent = (props: Props) => {
  const iconsMap = useSelector((state: RootState) => state.icons.iconsMap);

  const iconStyles = {
    ...props.rest.style,
  };

  return iconsMap.has(props.src || '') ? (
    <IconLogo
      src={props.src}
      rest={{
        ...props.rest,
        style: iconStyles,
      }}
      map={iconsMap as any}
    />
  ) : (
    <IconLogo
      src={''}
      rest={{
        ...props.rest,
        style: iconStyles,
      }}
      map={iconsMap as any}
    />
  );
};

export default IconComponent;
