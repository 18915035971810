import { Drawer, DrawerProps, SxProps } from '@mui/material';
import React from 'react';
import { APP_BAR_HEIGHT_REM } from '../../constants/general.constants';

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (flag: boolean) => void;
  drawerProps?: DrawerProps;
  sx?: SxProps;
};

const MobileDrawer = ({ children, open, setOpen, drawerProps, sx }: Props) => {
  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '100%',
          marginTop: `${APP_BAR_HEIGHT_REM}rem !important`,
          backgroundColor: (theme) => theme.palette.background.default,
          backgroundImage: 'none',
          overflowX: 'hidden !important',
          ...(sx as any),
        },
      }}
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};

export default MobileDrawer;
