export const PathDisplayNameMap = new Map<string, string>([
  ['sector-analysis', 'Sector Analysis'],
  ['stock-analysis', 'Stock Analysis'],
  ['income-statement-analysis', 'Income Statement Analysis'],
  ['balance-sheet-analysis', 'Balance Sheet Analysis'],
  ['cash-flow-analysis', 'Cash Flow Analysis'],
  ['management-effectiveness', 'Management Effectiveness'],
  ['financial-strength', 'Financial Strength'],
  ['screener', 'Screener'],
  ['user', 'User'],
  ['market-coverage', 'Market Coverage'],
  ['company-summary', 'Company Summary'],
]);
