import { ITableColumnData, ScreenerItemEnum } from '@prometeus/common';
import { SCREENER_COLUMNS } from '../../../constants/config/screener/screener-columns';
import {
  DispatchActionTypes,
  FETCH_PRELIMINARY_RESULTS,
  IScreenerResultsState,
  SET_CHECKSUM,
  SET_SCREENER_RESULTS_PAGE,
  SET_SCREENER_RESULTS_PER_PAGE,
  SET_SCREENER_SORT_BY,
  SET_SCREENER_SORT_ORDER,
  SET_SCREENER_TABLE_COLUMNS,
  SHOW_SCREENER_RESULTS,
  SHOW_SCREENER_RESULTS_FAIL,
  SHOW_SCREENER_RESULTS_SUCCESS,
} from '../../../models/screener/results.model';
import { showScreenerResults } from '../../actions/screener/results.action';

const initialState: IScreenerResultsState = {
  results: [],
  page: 0,
  resultsPerPage: 10,
  sortBy: ScreenerItemEnum.TICKER_ID,
  sortOrder: 'asc',
  tableColumns: SCREENER_COLUMNS?.filter(
    (e: ITableColumnData) => !!e.isDefault
  ),
  loading: false,
};

const screenerResultsReducer = (
  state: IScreenerResultsState = initialState,
  action: DispatchActionTypes
): IScreenerResultsState => {
  switch (action.type) {
    case SET_SCREENER_RESULTS_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_SCREENER_RESULTS_PER_PAGE:
      return {
        ...state,
        resultsPerPage: action.payload,
      };

    case SET_SCREENER_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };

    case SET_SCREENER_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      };

    case SET_SCREENER_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: action.payload,
      };

    case SET_CHECKSUM:
      return {
        ...state,
        checksum: action.payload,
      };

    case FETCH_PRELIMINARY_RESULTS:
      action.asyncDispatch(
        showScreenerResults(
          action.payload,
          0,
          state.resultsPerPage,
          state.tableColumns.map((e: ITableColumnData) => e.item),
          state.sortBy,
          state.sortOrder,
          state.checksum
        )
      );

      return {
        ...state,
      };
    /***************************************
     *                Results              *
     ***************************************/
    case SHOW_SCREENER_RESULTS:
      return {
        ...state,
        loading: true,
      };

    case SHOW_SCREENER_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.data,
        checksum: action.payload.checksum,
      };

    case SHOW_SCREENER_RESULTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default screenerResultsReducer;
