export interface ICompanyDataCounts {
  companiesCount: number;
  marketCapRevenueCount: number;
}

export interface ICompaniesState extends ICompanyDataCounts {
  loading: boolean;
  error?: string;
}

/* GET_COMPANIES_COUNT */
export const GET_COMPANIES_COUNT = 'GET_COMPANIES_COUNT';
export interface IGetCompaniesCount {
  type: typeof GET_COMPANIES_COUNT;
}

/* GET_COMPANIES_COUNT_SUCCESS */
export const GET_COMPANIES_COUNT_SUCCESS = 'GET_COMPANIES_COUNT_SUCCESS';
export interface IGetCompaniesCountSuccess {
  type: typeof GET_COMPANIES_COUNT_SUCCESS;
  payload: ICompanyDataCounts;
}

/* GET_COMPANIES_COUNT_FAIL */
export const GET_COMPANIES_COUNT_FAIL = 'GET_COMPANIES_COUNT_FAIL';
export interface IGetCompaniesCountFail {
  type: typeof GET_COMPANIES_COUNT_FAIL;
  payload: string;
}

export type GetCompaniesCountDispatchTypes =
  | IGetCompaniesCount
  | IGetCompaniesCountSuccess
  | IGetCompaniesCountFail;

export type DispatchActionTypes = GetCompaniesCountDispatchTypes;
