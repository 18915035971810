import { Menu } from '@mui/material';
import { isNullUndefined } from '@prometeus/common';
import React from 'react';
import { useSelectStyles } from '../../../styles/select.styles';

type Props = {
  id: string;
  open: boolean;
  /* Anchors */
  anchorPosition?: {
    mouseX: null | number;
    mouseY: null | number;
  };
  children: React.ReactNode;
  onMenuCloseHandler: () => void;
};

const MenuWrapperComponent = (props: Props) => {
  const selectStyles = useSelectStyles();

  const { anchorPosition, open, onMenuCloseHandler } = props;

  return (
    <Menu
      id={props.id}
      anchorReference="anchorPosition"
      anchorPosition={
        !isNullUndefined(anchorPosition?.mouseY) &&
        !isNullUndefined(anchorPosition?.mouseX)
          ? {
              top: anchorPosition?.mouseY as number,
              left: anchorPosition?.mouseX as number,
            }
          : undefined
      }
      keepMounted
      open={open}
      onClose={onMenuCloseHandler}
      PaperProps={{ sx: selectStyles.menuPaper }}
    >
      {props.children}
    </Menu>
  );
};

export default MenuWrapperComponent;
