import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemTextClasses,
  Theme,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const styles = {
  container: {
    width: '100%',
    fontSize: '1.3rem',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: (theme: Theme) =>
        `${theme.palette.background.paper} !important`,
      color: 'primary.main',
    },
  },
  containerSelected: {
    backgroundColor: 'background.paper',
    color: 'primary.main',
  },
  macroSectionFontSize: {
    fontSize: '1.3rem',
  },
  macroSectionItem: {
    padding: '1.3rem',
    paddingLeft: '1.6rem',
    borderLeftColor: 'background.default',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: (theme: Theme) =>
        `${theme.palette.background.paper} !important`,
      color: 'primary.main',
    },
  },
  macroSectionItemSelected: {
    borderLeftStyle: 'solid',
    paddingLeft: '1.3rem !important',
    borderLeftWidth: '0.3rem',
    borderLeftColor: 'primary.main',
  },
  listItemIconRoot: {
    minWidth: 0,
    marginRight: '1.5rem',
    marginLeft: '0.6rem',
    color: 'text.primary',
    // marginLeft: '0.3rem',
    '& svg': {
      fontSize: '1.8rem',
    },
  },
};

type Props = {
  displayName: string;
  icon: React.ReactElement;
  path: string;
  current: boolean;
};

const AdminNavigationMenuItemComponent = (props: Props) => {
  return (
    <Link to={props.path} style={{ all: 'unset' }}>
      <Box
        sx={{
          ...styles.container,
          ...(props.current ? styles.containerSelected : {}),
        }}
        key={props.displayName}
      >
        <ListItemButton
          sx={{
            ...styles.macroSectionItem,
            ...(props.current ? styles.macroSectionItemSelected : {}),
          }}
        >
          <ListItemIcon sx={{ ...styles.listItemIconRoot }}>
            {props.icon}
          </ListItemIcon>
          <ListItemText
            primary={props.displayName}
            sx={{
              marginBottom: 0,
              [`& .${listItemTextClasses['primary']}`]: {
                ...styles.macroSectionFontSize,
              },
            }}
          />
        </ListItemButton>
      </Box>
    </Link>
  );
};

export default AdminNavigationMenuItemComponent;
