import { Box, Palette, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { COMPANY_HEADER_HEIGHT_REM } from '../constants/general.constants';
import { RootState } from '../store/reducers/root.reducer';
import IconComponent from './icon.component';
import SectorIndustryCountryHeaderComponent from './sector-industry-country-header.component';

const getStyles = (palette: Palette) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1rem 0rem 0rem',
    height: `${COMPANY_HEADER_HEIGHT_REM}rem`,
    margin: '0.5rem 1.5rem 0.5rem',
  },
  titleRoot: {
    color: palette.text.primary,
    // marginTop: '0.3rem',
    marginLeft: '1rem',
  },
});

const CompanyHeaderComponent = () => {
  const currentCompanyCompanyId = useSelector(
    (state: RootState) => state.companies.currentCompany?.companyId
  );
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );
  const currentCompanyCompanyName = useSelector(
    (state: RootState) => state.companies.currentCompany?.companyName
  );

  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <Box sx={styles.container}>
      {!!currentCompanyCompanyId && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Stack direction="row" alignItems="center">
            <IconComponent
              src={currentCompanyCompanyId}
              rest={{
                style: {
                  width: '4rem',
                  height: '4rem',
                },
              }}
            />
            <div>
              <Typography variant="h5" sx={{ ...styles.titleRoot }}>
                {currentCompanyTickerId}
              </Typography>
              <Typography variant="h6" sx={{ ...styles.titleRoot }}>
                {currentCompanyCompanyName}
              </Typography>
            </div>
          </Stack>
          <SectorIndustryCountryHeaderComponent />
        </Stack>
      )}
    </Box>
  );
};

export default CompanyHeaderComponent;
