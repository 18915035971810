import React from 'react';
import ChartLoadingComponent from './charts/chart-loading.component';

const CardLoadingComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <ChartLoadingComponent
        scaleLoaderCardProps={{
          height: '3.5rem',
          width: '0.35rem',
          margin: '0.12rem',
        }}
      />
    </div>
  );
};

export default CardLoadingComponent;
