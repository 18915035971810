import {
  ICompanyFirms,
  ICompanyMinimal,
  ICompanyMinimalIcon,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { setLocalStorage } from '../../constants/utils.constants';
import { LocalStorageEnum } from '../../models/authentication.model';
import {
  COMPANIES_SEARCH_SELECT_COUNTRY_IDS,
  COMPANIES_SEARCH_SELECT_INDUSTRIES,
  COMPANIES_SEARCH_SELECT_SECTORS,
  FETCH_COMPANY,
  FETCH_COMPANY_FAIL,
  FETCH_COMPANY_SUCCESS,
  FETCH_PEER_LIST_DATA,
  FETCH_PEER_LIST_DATA_FAIL,
  FETCH_PEER_LIST_DATA_SUCCESS,
  FetchCompaniesDispachTypes,
  FetchPeerListDataDispatchTypes,
  GET_MOST_VIEWED_COMPANIES,
  GET_MOST_VIEWED_COMPANIES_FAIL,
  GET_MOST_VIEWED_COMPANIES_SUCCESS,
  ITickerIdRevenueCap,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_FAIL,
  SEARCH_COMPANIES_SUCCESS,
  SET_COMPANIES_DATA,
} from '../../models/companies.model';
import {
  GET_ICONS_SUCCESS,
  GetIconsSuccessI,
  IIconObj,
} from '../../models/icons.model';
import { ISecIndCty } from '../../models/screener/structure.model';
import {
  fetchCompanyDataService,
  fetchPeerListDataService,
  getMostViewedCompaniesService,
  searchCompaniesService,
} from '../../services/companies.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';
import { getIcons } from './icons.action';

export const searchCompanies =
  (keyword: string, countries: ISecIndCty[], industries: string[]) =>
    async (dispatch: Dispatch<FetchCompaniesDispachTypes | GetIconsSuccessI>) => {
      actionTryCatchWrapper(dispatch, SEARCH_COMPANIES_FAIL, async () => {
        dispatch({
          type: SEARCH_COMPANIES,
          payload: keyword,
        });

        const response: AxiosResponse<ICompanyMinimalIcon[]> =
          await searchCompaniesService(
            keyword,
            countries
              .slice(1) // Remove 'Select all companies'
              .filter((e: ISecIndCty) => e.selected)
              .map((e: ISecIndCty) => e.label.split(' -')[0]),
            industries
          );

        const data = response?.data || [];

        const companies: ICompanyMinimal[] = data?.map(
          (e: ICompanyMinimalIcon) => ({
            tickerId: e.tickerId,
            companyName: e.companyName,
            companyId: e.companyId,
          })
        );
        const icons: IIconObj[] = data?.map((e: ICompanyMinimalIcon) => ({
          companyId: e.companyId as string,
          image: e.icon,
          shape: e.shape,
        }));

        dispatch({
          type: SEARCH_COMPANIES_SUCCESS,
          payload: { companies, keyword },
        });

        dispatch({
          type: GET_ICONS_SUCCESS,
          payload: icons || [],
        });
      });
    };

export const fetchCompanyData =
  (tickerId: string) => (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(dispatch, FETCH_COMPANY_FAIL, async () => {
      dispatch({
        type: FETCH_COMPANY,
      });

      const response: AxiosResponse<ICompanyFirms> =
        await fetchCompanyDataService(tickerId);

      if (response?.data?.companyId) {
        dispatch(getIcons([response?.data?.companyId]));
      }

      setLocalStorage(tickerId, LocalStorageEnum.TICKER_ID);
      dispatch({
        type: FETCH_COMPANY_SUCCESS,
        payload: response?.data,
      });
    });
  };

export const resetCompanyData = () => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: null,
});

export const setCompaniesData = (companies: ICompanyMinimal[]) => ({
  type: SET_COMPANIES_DATA,
  payload: companies,
});

// Country, Sector, Industry
export const companiesSearchSelectSectors = (optionIndex: number) => ({
  type: COMPANIES_SEARCH_SELECT_SECTORS,
  payload: optionIndex,
});

export const companiesSearchSelectIndustries = (
  sectorIndex: number,
  industryIndex: number
) => ({
  type: COMPANIES_SEARCH_SELECT_INDUSTRIES,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const companiesSearchSelectCountries = (optionIndex: number) => ({
  type: COMPANIES_SEARCH_SELECT_COUNTRY_IDS,
  payload: optionIndex,
});

export const fetchPeerListData =
  (tickerIds: string[]) =>
    (dispatch: Dispatch<FetchPeerListDataDispatchTypes>) => {
      actionTryCatchWrapper(dispatch, FETCH_PEER_LIST_DATA_FAIL, async () => {
        dispatch({
          type: FETCH_PEER_LIST_DATA,
        });

        const response: AxiosResponse<ITickerIdRevenueCap[]> =
          await fetchPeerListDataService(tickerIds);

        dispatch({
          type: FETCH_PEER_LIST_DATA_SUCCESS,
          payload: response?.data,
        });
      });
    };

export const getMostViewedCompanies = () => (dispatch: Dispatch<any>) => {
  actionTryCatchWrapper(dispatch, GET_MOST_VIEWED_COMPANIES_FAIL, async () => {
    dispatch({
      type: GET_MOST_VIEWED_COMPANIES,
    });

    const response: AxiosResponse<ICompanyMinimal[]> =
      await getMostViewedCompaniesService();

    dispatch(getIcons(response?.data?.map((e: ICompanyMinimal) => e.tickerId)));

    dispatch({
      type: GET_MOST_VIEWED_COMPANIES_SUCCESS,
      payload: response?.data,
    });
  });
};
