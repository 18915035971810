import { LocalStorageEnum } from '../models/authentication.model';

export const authHeader = () => {
  try {
    const tokens = JSON.parse(
      localStorage.getItem(LocalStorageEnum.TOKEN) as string
    );
    if (!!tokens?.accessToken?.token) {
      return {
        'x-access-token': tokens.accessToken.token,
      };
    } else {
      throw new Error('authHeader Token error');
    }
  } catch (err) {
    console.error(err);
  }
};
