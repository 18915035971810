import { AxiosResponse } from 'axios';
import {
  CHART_DATA_URL,
  SECTOR_CHART_DATA_URL,
} from '../constants/api-urls.constants';
import {
  IFetchChartDataBody,
  IFetchSectorChartDataBody,
} from '../models/visuals.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const fetchChartDataService = (
  body: IFetchChartDataBody
): Promise<AxiosResponse<{ data: string[] }>> =>
  axiosInstance.post(CHART_DATA_URL, body);

export const fetchSectorChartDataService = (
  body: IFetchSectorChartDataBody
): Promise<AxiosResponse<{ data: string[] }>> =>
  axiosInstance.post(SECTOR_CHART_DATA_URL, body);
