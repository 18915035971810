import { SxProps, Theme } from '@mui/material';
import LoginButton from './LoginButton';
import { GoogleLogo } from './logos';

export type GoogleLoginButtonProps = {
  signIn: () => void;
  label?: string;
  sx: SxProps<Theme>;
};

const GoogleLoginButton = (props: GoogleLoginButtonProps) => (
  <LoginButton
    text={props.label || 'Sign in with Google'}
    logo={GoogleLogo}
    onClick={props.signIn}
    sx={props.sx}
  />
);

export default GoogleLoginButton;
