import {
  ExitToApp,
  Person,
  Settings,
  Star,
  WorkspacePremium,
} from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  listItemTextClasses,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RouteEnum } from '../../../navigation/routes.constants';
import {
  closeUserSettingsMenu,
  openRatingDialog,
} from '../../../store/actions/modals.action';
import { useSelectStyles } from '../../../styles/select.styles';
import { pxToRem } from '../../../ui/functions';
import { signOutBundler } from '../../../utils/common';
import MenuWrapperComponent from './menu-wrapper.component';

const MENU_ITEMS = [
  {
    label: 'User Information',
    path: 'user-information',
    icon: <Person />,
  },
  {
    label: 'Licences',
    path: 'licences',
    icon: <WorkspacePremium />,
  },
  {
    label: 'Preferences',
    path: 'preferences',
    icon: <Settings />,
  },
];

const UserMenuItem = (props: {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
}) => {
  const selectStyles = useSelectStyles();

  return (
    <MenuItem
      sx={{
        ...selectStyles.menuItemRoot,
        paddingRight: pxToRem(32),
        paddingLeft: pxToRem(32),
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
      onClick={props.onClick}
    >
      <ListItemIcon sx={{ transform: 'scale(1.2)' }}>{props.icon}</ListItemIcon>
      <ListItemText
        primary={props.label}
        sx={{
          marginTop: '1.8rem',
          [`& .${listItemTextClasses.primary}`]: { fontSize: '1.2rem' },
        }}
      />
    </MenuItem>
  );
};

type Props = {
  menuOpen: boolean;
  /* Anchors */
  anchorPosition?: {
    mouseX: null | number;
    mouseY: null | number;
  };
};

const UserSettingsMenu = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { menuOpen, anchorPosition } = props;

  const onMenuCloseHandler = () => {
    dispatch(closeUserSettingsMenu());
  };

  return (
    <MenuWrapperComponent
      id="user-serrings-menu"
      anchorPosition={anchorPosition}
      open={menuOpen}
      onMenuCloseHandler={onMenuCloseHandler}
    >
      {MENU_ITEMS?.map((e: (typeof MENU_ITEMS)[0]) => (
        <Link
          to={`${RouteEnum.USER}/${e.path}`}
          style={{ all: 'unset' }}
          key={e.label}
        >
          <UserMenuItem
            onClick={() => {
              onMenuCloseHandler();
            }}
            label={e.label}
            icon={e.icon}
          />
        </Link>
      ))}
      <Divider />
      <UserMenuItem
        key="Leave a Rating"
        onClick={() => {
          dispatch(openRatingDialog());
          onMenuCloseHandler();
        }}
        label="Leave a Rating"
        icon={<Star />}
      />
      <UserMenuItem
        key="Sign Out"
        onClick={() => {
          onMenuCloseHandler();
          signOutBundler(dispatch, history);
        }}
        label="Sign Out"
        icon={<ExitToApp />}
      />
    </MenuWrapperComponent>
  );
};

export default UserSettingsMenu;
