import {
  IScreenerConfig,
  ISectorData,
  ITableColumnData,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
} from '@prometeus/common';
import { Set } from 'immutable';
import { Dispatch } from 'redux';
import { ISectorsPayload } from '../../constants/companies.constants';
import { PeerGroupEnum } from '../peer-group.model';

export const US_COUNTRY_LABEL = 'US - USA';
export const FREEPREMIUM_SCREENER_COUNTRIES = ['US - USA'];

export enum ScreenerTabNameEnum {
  STOCK = 'stock',
  INCOME_STATEMENT = 'incomeStatement',
  FINANCIAL_STRENGTH = 'financialStrength',
  MANAGEMENT_EFFECTIVENESS = 'managementEffectiveness',
}
export interface ISecIndCty {
  label: string;
  index: number;
  shown: boolean;
  selected: boolean;
  group?: string;
  disabled?: boolean;
}

export interface ISectorIndustry extends ISecIndCty {
  industries: ISecIndCty[];
  indeterminate?: boolean;
}

export interface IScreenerStructureState {
  /* Strucure & Filters */
  screenerStructure?: IScreenerConfig;
  screenerStructureChecksum?: string;

  /* Column Setup */
  screenerColumnSetup: ITableColumnData[];
  activeFilters: Set<string>;

  /* Results */
  filteredTickers: string[];

  /* Country, Sector & Industry */
  sectorData?: ISectorData[];
  fetchSectorsDataLoading: boolean;

  sectors: ISectorIndustry[];
  selectedIndustries: string[];
  selectedCountries: ISecIndCty[];
  industryCompaniesNumber?: number;
  industryCompaniesLoading: boolean;
  screenerCompaniesNumber?: number;
  peerGroup: PeerGroupEnum;

  sendScreenerFilterLoading: boolean;
  showScreenerResultLoading: boolean;
  error?: string;
}

/***************************************
 *          Actions - Reducers         *
 ***************************************/

/* SET_SCREENER_FILTER_VALUE */
export const SET_SCREENER_FILTER_VALUE = 'SET_SCREENER_FILTER_VALUE';
export interface ISetScreenerFilterValue {
  type: typeof SET_SCREENER_FILTER_VALUE;
  payload: {
    tab: ScreenerTabNameEnum;
    cardBlock: ScreenerFilterCardType;
    filter: ScreenerFilterNameEnum;
    value: any;
  };
}

/* RESET_SCREENER_FILTER_BLOCK_VALUE */
export const RESET_SCREENER_FILTER_BLOCK_VALUE =
  'RESET_SCREENER_FILTER_BLOCK_VALUE';

export interface ResetScreenerFilterBlockValueI {
  type: typeof RESET_SCREENER_FILTER_BLOCK_VALUE;
  payload: {
    tab: ScreenerTabNameEnum;
    cardBlock: ScreenerFilterCardType;
  };
}

/* RESET_SCREENER_TAB_FILTERS */
export const RESET_SCREENER_TAB_FILTERS = 'RESET_SCREENER_TAB_FILTERS';

export interface ResetScreenerTabFiltersI {
  type: typeof RESET_SCREENER_TAB_FILTERS;
  payload: number;
}

/* APPLY_FILTERS */
export const APPLY_FILTERS = 'APPLY_FILTERS';
export interface IApplyFilters {
  type: typeof APPLY_FILTERS;
  asyncDispatch: Dispatch<any>;
}

/* SEND_SCREENER_FILTERS */
export const SEND_SCREENER_FILTERS = 'SEND_SCREENER_FILTERS';
export interface SendScreenerFiltersI {
  type: typeof SEND_SCREENER_FILTERS;
}

export const SEND_SCREENER_FILTERS_SUCCESS = 'SEND_SCREENER_FILTERS_SUCCESS';
export interface SendScreenerFiltersSuccessI {
  type: typeof SEND_SCREENER_FILTERS_SUCCESS;
  payload: string[];
}

export const SEND_SCREENER_FILTERS_FAIL = 'SEND_SCREENER_FILTERS_FAIL';
export interface SendScreenerFiltersFailI {
  // SUCCESS and FAIL?
  type: typeof SEND_SCREENER_FILTERS_FAIL;
  payload: string; // error
}

export type SendScreenerFiltersDispatchType =
  | SendScreenerFiltersI
  | SendScreenerFiltersSuccessI
  | SendScreenerFiltersFailI;

/* FETCH_SECTORS_DATA */
export const FETCH_SECTORS_DATA = 'FETCH_SECTORS_DATA';
export const FETCH_SECTORS_DATA_SUCCESS = 'FETCH_SECTORS_DATA_SUCCESS';
export const FETCH_SECTORS_DATA_FAIL = 'FETCH_SECTORS_DATA_FAIL';

export interface FetchSectorsDataI {
  type: typeof FETCH_SECTORS_DATA;
}

export interface FetchSectorsDataFailI {
  type: typeof FETCH_SECTORS_DATA_FAIL;
  payload: string;
}

export interface FetchSectorsDataSuccessI {
  type: typeof FETCH_SECTORS_DATA_SUCCESS;
  payload: ISectorsPayload;
}

export type FetchSectorsDataDispachTypes =
  | FetchSectorsDataI
  | FetchSectorsDataFailI
  | FetchSectorsDataSuccessI;

/* SELECT_SECTORS */
export const SELECT_SECTORS = 'SELECT_SECTORS';
export interface SelectSectorsI {
  type: typeof SELECT_SECTORS;
  payload: number;
}

/* SELECT_INDUSTRIES */
export const SELECT_INDUSTRIES = 'SELECT_INDUSTRIES';
export interface SelectIndustriesI {
  type: typeof SELECT_INDUSTRIES;
  payload: {
    sector: number;
    industry: number;
  };
}

/* SELECT_COUNTRY_IDS */
export const SELECT_COUNTRY_IDS = 'SELECT_COUNTRY_IDS';
export interface SelectCountryIdsI {
  type: typeof SELECT_COUNTRY_IDS;
  payload: number;
}

/* FETCH_INDUSTRY_COMPANIES */
export const FETCH_INDUSTRY_COMPANIES = 'FETCH_INDUSTRY_COMPANIES';
export const FETCH_INDUSTRY_COMPANIES_SUCCESS =
  'FETCH_INDUSTRY_COMPANIES_SUCCESS';
export const FETCH_INDUSTRY_COMPANIES_FAIL = 'FETCH_INDUSTRY_COMPANIES_FAIL';

export interface FetchIndustryCompaniesI {
  type: typeof FETCH_INDUSTRY_COMPANIES;
}

export interface FetchIndustryCompaniesFailI {
  type: typeof FETCH_INDUSTRY_COMPANIES_FAIL;
  payload: string;
}

export interface FetchIndustryCompaniesSuccessI {
  type: typeof FETCH_INDUSTRY_COMPANIES_SUCCESS;
  payload: number;
}

export type FetchIndustryCompaniesDispachTypes =
  | FetchIndustryCompaniesI
  | FetchIndustryCompaniesFailI
  | FetchIndustryCompaniesSuccessI;

/* SET_SCREENER_COMPANIES_NUMBER */
export const SET_SCREENER_COMPANIES_NUMBER = 'SET_SCREENER_COMPANIES_NUMBER';

export interface SetScreenerCompaniesNumberI {
  type: typeof SET_SCREENER_COMPANIES_NUMBER;
  payload?: string;
}

/* SET_SCREENER_PEER_GROUP */
export const SET_SCREENER_PEER_GROUP = 'SET_SCREENER_PEER_GROUP';

export interface ISetScreenerPeerGroup {
  type: typeof SET_SCREENER_PEER_GROUP;
  payload: PeerGroupEnum;
}

/* SETUP_NOT_ACTIVE_LICENCE_COUNTRIES */
export const SETUP_NOT_ACTIVE_LICENCE_COUNTRIES =
  'SETUP_NOT_ACTIVE_LICENCE_COUNTRIES';
export interface ISetupNotActiveLicenceCountries {
  type: typeof SETUP_NOT_ACTIVE_LICENCE_COUNTRIES;
}

/***************************************
 *         SHOW SCREENER RESULTS       *
 ***************************************/

/* DispatchActionTypes */
export type DispatchActionTypes =
  | ISetScreenerFilterValue
  | ResetScreenerFilterBlockValueI
  | ResetScreenerTabFiltersI
  | IApplyFilters
  | SendScreenerFiltersDispatchType
  | FetchSectorsDataDispachTypes
  | SelectSectorsI
  | SelectIndustriesI
  | SelectCountryIdsI
  | FetchIndustryCompaniesDispachTypes
  | SetScreenerCompaniesNumberI
  | ISetScreenerPeerGroup
  | ISetupNotActiveLicenceCountries;
