import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingComponent from '../components/loading.component';
import {
  ADMIN_DRAWER_WIDTH_REM,
  APP_BAR_HEIGHT_REM,
  CLOSED_DRAWER_WIDTH_REM,
} from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { RootStore } from '../store/store';
import { AdminRoute, AuthenticatedRoute, UseredRoute } from './route-wrappers';
import { AdminRouteEnum, RouteEnum } from './routes.constants';

/***************************************
 *             Lazy Loading            *
 ***************************************/
const BalanceSheetAnalysisPage = lazy(
  () => import('../pages/balance-sheet-analysis/balance-sheet-analysis.page')
);
const CashFlowAnalysisPage = lazy(
  () => import('../pages/cash-flow-analysis/cash-flow-analysis.page')
);
const FinancialStrengthPage = lazy(
  () => import('../pages/financial-strength/financial-strength.page')
);
const IncomeStatementAnalysisPage = lazy(
  () =>
    import('../pages/income-statement-analysis/income-statement-analysis.page')
);
const ManagementEffectivenessPage = lazy(
  () =>
    import('../pages/management-effectiveness/management-effectiveness.page')
);
const ScreenerPage = lazy(() => import('../pages/screener/screener.page'));
const SectorAnalysisPage = lazy(
  () => import('../pages/sector-analysis/sector-analysis.page')
);
const UserPage = lazy(() => import('../pages/user/user.page'));
const StockAnalysisPage = lazy(
  () => import('../pages/stock-analysis/stock-analysis.page')
);
const CompanySummaryPage = lazy(
  () => import('../pages/company-summary/company-summary.page')
);
const MarketCoveragePage = lazy(
  () => import('../pages/market-coverage/market-coverage.page')
);
const PaymentPage = lazy(() => import('../pages/payment'));

/* Admin */
const AdminHomePage = lazy(() => import('../pages/admin/home.page'));
const CompanyPage = lazy(() => import('../pages/admin/company.page'));
const UsersPage = lazy(() => import('../pages/admin/users/users.page'));
const PlansPage = lazy(() => import('../pages/admin/plans/plans.page'));
const AdminUserPage = lazy(() => import('../pages/admin/users/user.page'));
const AdminPlanPage = lazy(() => import('../pages/admin/plans/plan.page'));
const LogsPage = lazy(() => import('../pages/admin/logs/logs.page'));
const IconsPage = lazy(() => import('../pages/admin/icons.page'));
const IconsManagersPage = lazy(
  () => import('../pages/admin/icons-managers.page')
);

const styles = {
  switch: {
    marginTop: `${APP_BAR_HEIGHT_REM}rem`,
    height: '100%',
    overflow: 'auto',
  },
};

const NavigationSwitchComponent = (props: { adminMenu?: boolean }) => {
  const loading = useSelector(
    (state: RootStore) => state.authentication.loading
  );

  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Suspense fallback={<LoadingComponent />}>
          <Box
            sx={{
              ...styles.switch,
              marginLeft: `${
                isPortraitMobile
                  ? 0
                  : !!props.adminMenu
                  ? ADMIN_DRAWER_WIDTH_REM
                  : CLOSED_DRAWER_WIDTH_REM
              }rem`,
            }}
          >
            <Switch>
              {/* Test */}
              <AuthenticatedRoute path={`${RouteEnum.PAYMENT}/:planId`}>
                <PaymentPage />
              </AuthenticatedRoute>

              {/* App routes */}
              <AuthenticatedRoute path={RouteEnum.MARKET_COVERAGE}>
                <MarketCoveragePage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.COMPANY_SUMMARY}>
                <CompanySummaryPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.SECTOR_ANALYSIS}>
                <SectorAnalysisPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.STOCK_ANALYSIS}>
                <StockAnalysisPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.INCOME_STATEMENT_ANALYSIS}>
                <IncomeStatementAnalysisPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.BALANCE_SHEET_ANALYSIS}>
                <BalanceSheetAnalysisPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.CASH_FLOW_ANALYSIS}>
                <CashFlowAnalysisPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.MANAGEMENT_EFFECTIVENESS}>
                <ManagementEffectivenessPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.FINANCIAL_STRENGTH}>
                <FinancialStrengthPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={RouteEnum.SCREENER}>
                <ScreenerPage />
              </AuthenticatedRoute>

              {/* User information */}
              <UseredRoute path={RouteEnum.USER}>
                <UserPage />
              </UseredRoute>

              {/* Admin */}
              <AdminRoute path={`${AdminRouteEnum.USERS}/:userId`}>
                <AdminUserPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.USERS}>
                <UsersPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.ICONS}>
                <IconsPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.MANAGERS}>
                <IconsManagersPage />
              </AdminRoute>
              <AdminRoute path={`${AdminRouteEnum.PLANS}/:planId`}>
                <AdminPlanPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.PLANS}>
                <PlansPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.LOGS}>
                <LogsPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.COMPANY}>
                <CompanyPage />
              </AdminRoute>
              <AdminRoute path={AdminRouteEnum.HOME}>
                <AdminHomePage />
              </AdminRoute>

              {/* Home Page */}
              <AuthenticatedRoute path="/">
                <Redirect
                  to={{
                    pathname: RouteEnum.COMPANY_SUMMARY,
                  }}
                />
              </AuthenticatedRoute>

              {/* Everything else */}
              <Route path="*">
                <Redirect
                  to={{
                    pathname: '/',
                  }}
                />
              </Route>
            </Switch>
          </Box>
        </Suspense>
      )}
    </>
  );
};

export default NavigationSwitchComponent;
