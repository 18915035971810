import { PYTHON_ANYWHERE_URL } from '@prometeus/common';

/* API Data */
const _PYTHON_ANYWHERE_URL = `${PYTHON_ANYWHERE_URL}api/`;
export const CHART_DATA_URL = `${_PYTHON_ANYWHERE_URL}general`;
export const SECTOR_CHART_DATA_URL = `${_PYTHON_ANYWHERE_URL}macro`;
export const SCREENER_URL = `${_PYTHON_ANYWHERE_URL}screener`;
export const SCREENER_RESULTS_URL = `${SCREENER_URL}/results`;

/* Logs */
export const GET_API_LOGS_URL = `${_PYTHON_ANYWHERE_URL}logs/`;
export const GET_API_LOGS_TREE_URL = `${GET_API_LOGS_URL}tree`;
export const GET_API_LOGS_FILE_URL = `${GET_API_LOGS_URL}file`;
