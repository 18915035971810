import { Box, List, ListItem } from '@mui/material';
import React, { useState } from 'react';
import { CustomListItem } from '../../custom-list-item.component';

const styles = {
  listRoot: {
    padding: '0rem 2rem !important',
    '& .MuiCheckbox-root': {
      padding: '0rem !important',
    },
    margin: '0rem 1rem',
  },
  lineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

type Props = {
  labels: string[];
  checked: boolean[];
  onClick: (value: boolean[]) => void;
  active: boolean;
};

const CheckboxListScreenerComponent = (props: Props) => {
  const [indeterminateAll, setIndeterminateAll] = useState<boolean>(false);
  const handleItemClick = (index: number) => {
    let newChecked: boolean[] = [];
    if (index === 0) {
      newChecked = Array(props.checked.length).fill(!props.checked[0]);
    } else {
      const tmp: boolean[] = props.checked;
      tmp[index] = !tmp[index];

      // All
      const notEvery = tmp.slice(1).every((e: boolean) => !e);
      tmp[0] = !notEvery;

      newChecked = [...tmp];
    }

    props.onClick(newChecked);

    // Indeterminate All
    const allStatus = newChecked[0];
    let indeterminate = false;
    if (allStatus) {
      const otherStatus = newChecked.slice(1).every((e: boolean) => e);
      indeterminate = !otherStatus;
    }
    setIndeterminateAll(indeterminate);
  };

  return (
    <List component="div" role="list" sx={styles.listRoot}>
      <Box sx={styles.lineContainer}>
        <CustomListItem
          label="Select All"
          checked={props.checked[0]}
          indeterminate={indeterminateAll && props.active}
          onClick={() => {
            handleItemClick(0);
          }}
        />
        <div style={{ flex: '2 1 0' }}></div>
      </Box>
      {Array(props.labels.length / 3)
        .fill(0)
        .map((_, _index: number) => {
          const checkedIndex = _index * 3 + 1;
          const listIndex = checkedIndex - 1;
          return (
            <Box
              sx={styles.lineContainer}
              key={`list-item-couple-${listIndex}`}
            >
              <CustomListItem
                label={props.labels[listIndex]}
                checked={props.checked[checkedIndex]}
                onClick={() => {
                  handleItemClick(checkedIndex);
                }}
              />
              <CustomListItem
                label={props.labels[listIndex + 1]}
                checked={props.checked[checkedIndex + 1]}
                onClick={() => {
                  handleItemClick(checkedIndex + 1);
                }}
              />
              <CustomListItem
                label={props.labels[listIndex + 2]}
                checked={props.checked[checkedIndex + 2]}
                onClick={() => {
                  handleItemClick(checkedIndex + 2);
                }}
              />
            </Box>
          );
        })}
      <ListItem />
    </List>
  );
};

export default CheckboxListScreenerComponent;
