import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { MAX_ICON_TICKERS_CHUNK_SIZE } from '../../constants/general.constants';
import { chunkArray } from '../../constants/utils.constants';
import {
  GET_ICONS,
  GET_ICONS_FAIL,
  GET_ICONS_SUCCESS,
  IIconObj,
} from '../../models/icons.model';
import { getIconsService } from '../../services/icons.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';
import { RootState } from '../reducers/root.reducer';

export const getIcons =
  (_tickers: string[]) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    actionTryCatchWrapper(dispatch, GET_ICONS_FAIL, async () => {
      const { requestedTickers } = getState().icons;
      dispatch({
        type: GET_ICONS,
        payload: _tickers,
      });

      const tickers = _tickers.filter((e: string) => !requestedTickers.has(e));

      if (!tickers.length) {
        dispatch({
          type: GET_ICONS_SUCCESS,
          payload: [],
        });
      } else {
        const chunkedTickers = chunkArray(tickers, MAX_ICON_TICKERS_CHUNK_SIZE);

        chunkedTickers.forEach((chunk: string[]) => {
          getIconsService(chunk).then((response: AxiosResponse<IIconObj[]>) => {
            dispatch({
              type: GET_ICONS_SUCCESS,
              payload: response?.data,
            });
          });
        });
      }
    });
  };
