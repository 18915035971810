import { Settings, Star, SupervisorAccount } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Stack,
  SvgIcon,
  Theme,
  Toolbar,
  Typography,
  buttonClasses,
  useTheme,
} from '@mui/material';
import Hamburger from 'hamburger-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { APP_BAR_HEIGHT_REM } from '../constants/general.constants';
import { PathDisplayNameMap } from '../constants/path-maps.constants';
import { FIRE_ICON_D } from '../constants/svg-icons';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { AdminRouteEnum, RouteEnum } from '../navigation/routes.constants';
import {
  openActiveFreeTrialDialog,
  openRatingDialog,
  openUserSettingsMenu,
} from '../store/actions/modals.action';
import { RootState } from '../store/reducers/root.reducer';
import { COMMON_COLORS } from '../ui/colors';
import { pxToRem } from '../ui/functions';
import BrandFlame from './brand-flame.component';
import AutocompleteTickerIdComponent from './filters/filter-components/autocomplete-tickerid.component';
import HeaderLogoComponent from './header-logo.component';
import {
  LogInButtonComponent,
  SignUpButtonComponent,
} from './sign-in-dialog.component';
import TooltipIconButton from './tooltip-icon-button.component';

type Props = {
  currentPath: string;
  isPaymentRoute?: boolean;
  setOpenMobileMenu: (flag: boolean) => void;
  openMobileMenu: boolean;
};

const getStyles = (theme: Theme) => ({
  /* Appbar */
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: `${APP_BAR_HEIGHT_REM}rem`,
    paddingTop: '0.5rem',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: COMMON_COLORS.contrastPrimaryText,
    backgroundImage: 'none !important',
  },
  typographyTitle: {
    marginTop: '0.5rem',
    fontSize: '1.8rem',
  },

  /* Toolbar icons */
  listItemIconRoot: {
    '& svg': {
      fontSize: '1.8rem',
    },
  },
  iconButtonRoot: {
    margin: '0rem 0.05rem',
  },
  verticalDivider: {
    margin: '0.6rem 0.8rem',
    backgroundColor: COMMON_COLORS.contrastPrimaryText,
    width: '0.2rem',
    borderColor: COMMON_COLORS.contrastPrimaryText,
  },
  unlockFullPotential: {
    cursor: 'pointer',
  },
  button: {
    fontSize: '1.05rem',
    borderRadius: '3rem !important',
    border: `0.1rem solid transparent`,
    fontWeight: 600,
    backgroundColor: COMMON_COLORS.contrastPrimaryText,
    '&:hover': {
      color: COMMON_COLORS.contrastPrimaryText,
      backgroundColor: theme.palette.primary.main,
      borderColor: COMMON_COLORS.contrastPrimaryText,
    },
  },
  ratingButton: {
    borderRadius: '2rem !important',
    border: `0.1rem solid ${COMMON_COLORS.contrastPrimaryText} !important`,
    color: COMMON_COLORS.contrastPrimaryText,
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      borderColor: `${theme.palette.secondary.main} !important`,
    },
    [`& .${buttonClasses.startIcon}`]: {
      marginRight: pxToRem(8),
      marginLeft: pxToRem(-4),
      '& svg': { fontSize: pxToRem(20) },
    },
  },
});

const AppbarComponent = ({
  currentPath,
  isPaymentRoute,
  openMobileMenu,
  setOpenMobileMenu,
}: Props) => {
  const theme = useTheme();
  const hasRated = useSelector(
    (state: RootState) => state.authentication.hasRated
  );
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const isAdmin = useSelector(
    (state: RootState) => state.authentication.isAdmin
  );
  const userInformation = useSelector(
    (state: RootState) => state.user.userInformation,
    shallowEqual
  );
  const token = useSelector(
    (state: RootState) => state.authentication.accessToken
  );

  const styles = getStyles(theme);

  const dispatch = useDispatch();

  const location = useLocation();
  const isAdminPage =
    location.pathname.split('/').filter((e: string) => !!e)[0] === 'admin';

  // Loading
  const loadingAuthentication = useSelector(
    (state: RootState) => state.authentication.loading
  );
  const loadingSectorsData = useSelector(
    (state: RootState) => state.companies.loadingSectorsData
  );
  const loadingMostViewedCompanies = useSelector(
    (state: RootState) => state.companies.loading
  );
  const loading =
    loadingAuthentication || loadingMostViewedCompanies || loadingSectorsData;

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <AppBar position="fixed" sx={styles.appBar}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '0.8rem !important',
          alignItems: 'center',
        }}
      >
        <Stack alignItems="center" direction="row" flex="1 1 0">
          <HeaderLogoComponent hideLabel={isPortraitMobile} />
          {!isPortraitMobile && PathDisplayNameMap.has(currentPath) && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ ...styles.verticalDivider }}
            />
          )}
          <Typography variant="h5" noWrap sx={{ ...styles.typographyTitle }}>
            {PathDisplayNameMap.has(currentPath)
              ? PathDisplayNameMap.get(currentPath)
              : ''}
          </Typography>
        </Stack>
        {!isPaymentRoute && (
          <AutocompleteTickerIdComponent
            style={{
              flex: `${isPortraitMobile ? 3 : 1} 1 0`,
              padding: '0.35rem 3rem 0rem',
            }}
            currentPath={currentPath}
          />
        )}
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          flex="1 1 0"
          justifyContent="flex-end"
          sx={
            isPortraitMobile && !isPaymentRoute
              ? { height: '2rem', marginRight: '-3%' }
              : {}
          }
        >
          {isPortraitMobile && !isPaymentRoute ? (
            <>
              <Box
                onClick={() => setOpenMobileMenu(!openMobileMenu)}
                sx={{
                  transform: 'scale(0.5)',
                  '& .hamburger-react': {
                    height: '2rem',
                    width: '2rem',
                  },
                }}
              >
                <Hamburger
                  toggled={openMobileMenu}
                  toggle={setOpenMobileMenu as any}
                />
              </Box>
            </>
          ) : (
            <>
              {!loading && !userInformation && !isAdmin && !isPaymentRoute && (
                <>
                  <LogInButtonComponent />
                  <SignUpButtonComponent />
                </>
              )}
              {!!token && !isPaymentRoute && (
                <>
                  {!!isAdmin && (
                    <>
                      <Link
                        to={
                          isAdminPage
                            ? RouteEnum.COMPANY_SUMMARY
                            : AdminRouteEnum.HOME
                        }
                        style={{ all: 'unset' }}
                      >
                        <TooltipIconButton
                          icon={
                            isAdminPage ? (
                              <SvgIcon>
                                <path d={FIRE_ICON_D} />
                              </SvgIcon>
                            ) : (
                              <SupervisorAccount />
                            )
                          }
                          tooltip={isAdminPage ? 'App' : 'Admin Dashboard'}
                          iconButtonStyle={styles.iconButtonRoot}
                        />
                      </Link>
                    </>
                  )}
                </>
              )}
              {(!!userInformation || isAdmin) && (
                <>
                  {!isPaymentRoute && (
                    <>
                      {!userInformation?.usedFreeTrial && !hasActiveLicence ? (
                        <>
                          <Button
                            sx={styles.button}
                            onClick={() => {
                              dispatch(openActiveFreeTrialDialog());
                            }}
                          >
                            Activate Free Trial
                            <BrandFlame
                              sx={{ marginLeft: '0.3rem' }}
                              gradient
                            />
                          </Button>
                        </>
                      ) : (
                        <>
                          {!hasRated &&
                            !isAdmin && ( // show only if user has never left a rating (or a long time passed since last rating)
                              <Button
                                variant="outlined"
                                sx={styles.ratingButton}
                                startIcon={<Star />}
                                onClick={() => dispatch(openRatingDialog())}
                              >
                                Leave a rating
                              </Button>
                            )}
                        </>
                      )}
                    </>
                  )}

                  <TooltipIconButton
                    icon={<Settings />}
                    tooltip=""
                    onClick={($event: any) => {
                      dispatch(
                        openUserSettingsMenu({
                          mouseX: $event.clientX,
                          mouseY: $event.clientY,
                        })
                      );
                    }}
                    iconButtonStyle={styles.iconButtonRoot}
                  />
                </>
              )}
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppbarComponent;
