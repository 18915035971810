/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
import { FilterEnum, IStructureMacrosection } from '@prometeus/common';
import { RouteEnum } from '../../navigation/routes.constants';
import { BalanceSheetAnalysisRouteEnum } from '../../pages/balance-sheet-analysis/balance-sheet-analysis.page';
import { CashFlowAnalysisRouteEnum } from '../../pages/cash-flow-analysis/cash-flow-analysis.page';
import { FinancialStrengthRouteEnum } from '../../pages/financial-strength/financial-strength.page';
import { IncomeStatementAnalysisRouteEnum } from '../../pages/income-statement-analysis/income-statement-analysis.page';
import { ManagementEffectivenessRouteEnum } from '../../pages/management-effectiveness/management-effectiveness.page';
import { ScreenerRouteEnum } from '../../pages/screener/screener.page';
import {
  SectorAnalysisRouteEnum,
} from '../../pages/sector-analysis/sector-analysis.page';
import { StockAnalysisRouteEnum } from '../../pages/stock-analysis/stock-analysis.page';

const SCREENER_FILTERS = [FilterEnum.country, FilterEnum.industry];

export const STRUCTURE_CONFIG: IStructureMacrosection[] = [
  {
    id: 'W',
    icon: 'M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
    displayName: 'Market Coverage',
    path: RouteEnum.MARKET_COVERAGE,
    sections: [],
  },
  {
    id: 'Z',
    displayName: 'Company Summary',
    icon: 'M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17',
    path: RouteEnum.COMPANY_SUMMARY,
    sections: [
      {
        id: '1',
        displayName: 'Company Summary',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: true,
        changeCurrency: true,
        isFirstPage: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: '',
      },
    ],
    topDivider: true,
  },
  {
    id: 'B',
    displayName: 'Stock Analysis',
    icon: 'M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64',
    path: RouteEnum.STOCK_ANALYSIS,
    sections: [
      {
        id: '1',
        displayName: 'Company Stocks',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: true,
        changeCurrency: true,
        isFirstPage: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: StockAnalysisRouteEnum.COMPANY_STOCKS,
      },
      {
        id: '2',
        displayName: 'Company Dividends',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: true,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: StockAnalysisRouteEnum.COMPANY_DIVIDENDS,
      },
    ],
  },
  {
    id: 'C',
    displayName: 'Income Statement Analysis',
    icon: 'M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z',
    path: RouteEnum.INCOME_STATEMENT_ANALYSIS,
    sections: [
      {
        id: '1',
        displayName: 'Revenue & Profit',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: IncomeStatementAnalysisRouteEnum.REVENUE_AND_PROFIT,
      },
      {
        id: '2',
        displayName: 'Profit Margin & Gross Margin',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: IncomeStatementAnalysisRouteEnum.PROFIT_MARGIN_AND_GROSS_MARGIN,
      },
      {
        id: '3',
        displayName: 'Operating Expenses',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: IncomeStatementAnalysisRouteEnum.OPERATING_EXPENSES,
      },
      {
        id: '4',
        displayName: 'Income Statement',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: IncomeStatementAnalysisRouteEnum.INCOME_STATEMENT,
      },
    ],
  },
  {
    id: 'D',
    displayName: 'Balance Sheet Analysis',
    icon: 'M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z',
    path: RouteEnum.BALANCE_SHEET_ANALYSIS,
    sections: [
      {
        id: '1',
        displayName: 'Overview',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: BalanceSheetAnalysisRouteEnum.OVERVIEW,
      },
      {
        id: '2',
        displayName: 'Current Asset & Liabilities',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: BalanceSheetAnalysisRouteEnum.CURRENT_ASSET_AND_LIABILITIES,
      },
      {
        id: '3',
        displayName: 'Noncurrent Asset & Liabilities',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: BalanceSheetAnalysisRouteEnum.NON_CURRENT_ASSET_AND_LIABILITIES,
      },
      {
        id: '4',
        displayName: 'Equity',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: BalanceSheetAnalysisRouteEnum.EQUITY,
      },
      {
        id: '5',
        displayName: 'Balance Sheet',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: BalanceSheetAnalysisRouteEnum.BALANCE_SHEET,
      },
    ],
  },
  {
    id: 'E',
    displayName: 'Cash Flow Analysis',
    icon: 'M22,6.92L20.59,5.5L17.74,8.72C15.68,6.4 12.83,5 9.61,5C6.72,5 4.07,6.16 2,8L3.42,9.42C5.12,7.93 7.27,7 9.61,7C12.35,7 14.7,8.26 16.38,10.24L13.5,13.5L9.5,9.5L2,17L3.5,18.5L9.5,12.5L13.5,16.5L17.55,11.93C18.3,13.28 18.8,14.83 19,16.5H21C20.78,14.18 20.05,12.09 18.96,10.34L22,6.92Z',
    path: RouteEnum.CASH_FLOW_ANALYSIS,
    sections: [
      {
        id: '1',
        displayName: 'Cash Flow Analysis',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: CashFlowAnalysisRouteEnum.CASH_FLOW,
      },
      {
        id: '2',
        displayName: 'Cash Flow Statement',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        changeCurrency: true,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: CashFlowAnalysisRouteEnum.CASH_FLOW_STATEMENT,
      },
    ],
  },
  {
    id: 'F',
    displayName: 'Management Effectiveness',
    icon: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z',
    path: RouteEnum.MANAGEMENT_EFFECTIVENESS,
    sections: [
      {
        id: '1',
        displayName: 'ROS & ROE',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: ManagementEffectivenessRouteEnum.ROS_AND_ROE,
      },

      {
        id: '2',
        displayName: 'ROA & ROIC',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: ManagementEffectivenessRouteEnum.ROA_AND_ROIC,
      },
    ],
  },
  {
    id: 'G',
    displayName: 'Financial Strength',
    icon: 'M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z',
    path: RouteEnum.FINANCIAL_STRENGTH,
    sections: [
      {
        id: '1',
        displayName: 'Current & Quick Ratio, CapEx Impact',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: FinancialStrengthRouteEnum.CURRENT_AND_QUICK_RATIO,
      },
      {
        id: '2',
        displayName: 'Debt To Equity & Debt Payback',
        timeframeFilterQY: true,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.ticker,
          FilterEnum.doubleYear,
          FilterEnum.mode,
          FilterEnum.peerGroup,
          FilterEnum.peers,
        ],
        path: FinancialStrengthRouteEnum.DEBT_TO_EQUITY_AND_DEBT_PAYBACK,
      },
    ],
  },
  {
    id: 'A',
    displayName: 'Sector Analysis',
    icon: 'M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z',
    path: RouteEnum.SECTOR_ANALYSIS,
    topDivider: true,
    sections: [
      {
        id: '2',
        displayName: 'Country Analysis',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.country,
          FilterEnum.industry,
          FilterEnum.timeWindow,
          FilterEnum.measureListTop,
          FilterEnum.measureListBottom,
        ],
        path: SectorAnalysisRouteEnum.COUNTRY,
      },
      {
        id: '3',
        displayName: 'Sector Analysis',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.country,
          FilterEnum.industry,
          FilterEnum.timeWindow,
          FilterEnum.measureListTop,
          FilterEnum.measureListBottom,
        ],
        path: SectorAnalysisRouteEnum.SECTOR,
      },
      {
        id: '4',
        displayName: 'Industry Analysis',
        timeframeFilterQY: false,
        timeframeFilterDWMQ: false,
        filters: [
          FilterEnum.country,
          FilterEnum.industry,
          FilterEnum.timeWindow,
          FilterEnum.measureListTop,
          FilterEnum.measureListBottom,
        ],
        path: SectorAnalysisRouteEnum.INDUSTRY,
      },
    ],
  },
  {
    id: 'S',
    icon: 'M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z',
    displayName: 'Screener',
    path: RouteEnum.SCREENER,
    sections: [
      {
        id: '1',
        displayName: 'Stock',
        filters: SCREENER_FILTERS,
        path: ScreenerRouteEnum.STOCK,
      },
      {
        id: '2',
        displayName: 'Income Statement',
        filters: SCREENER_FILTERS,
        path: ScreenerRouteEnum.INCOME_STATEMENT,
      },
      {
        id: '3',
        displayName: 'Management Effectiveness',
        filters: SCREENER_FILTERS,
        path: ScreenerRouteEnum.MANAGEMENT_EFFECTIVENESS,
      },
      {
        id: '4',
        displayName: 'Financial Strength',
        filters: SCREENER_FILTERS,
        path: ScreenerRouteEnum.FINANCIAL_STRENGTH,
      },
    ],
  },
];
