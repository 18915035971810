import {
  DEV_SIGNIN_AUTH_URL,
  DUMMY_AUTH_URL,
  GOOGLE_AUTH_URL,
  MICROSOFT_AUTH_URL,
  PYTHON_ANYWHERE_URL,
} from '@prometeus/common';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { History } from 'history';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import isDev from '../constants/is-dev.constants';
import { setAxiosError } from '../store/actions/error-handling.action';
import { authHeader } from './auth-header';
import { signOutBundler } from './common';

// process.hrtime = require('browser-process-hrtime');
interface AxiosRequestConfigMetadata extends AxiosRequestConfig {
  metadata: [number, number];
}

const API_URL = isDev()
  ? process.env.REACT_APP_BASE_URL_API
  : process.env.REACT_APP_BASE_URL_API_PROD;

/* Request */
const getReqInterceptor = (isDev: boolean) => (config: AxiosRequestConfig) => {
  if (isDev) {
    // (config as AxiosRequestConfigMetadata).metadata = process.hrtime();
  }

  const configUrl = config.url || '';

  const outputConfig = {
    ...config,
  };

  if (!configUrl.includes(PYTHON_ANYWHERE_URL)) {
    outputConfig.url = `${API_URL?.slice(0, -1)}${configUrl}`;
  }

  // Add tokenHeader if needed
  let tokenHeader = null;
  // Check if tokenHeader is needed
  if (
    !!configUrl &&
    ![
      DEV_SIGNIN_AUTH_URL,
      GOOGLE_AUTH_URL,
      MICROSOFT_AUTH_URL,
      DUMMY_AUTH_URL,
    ].includes(configUrl)
  ) {
    try {
      tokenHeader = authHeader();
    } catch (err) {
      return new axios.Cancel(err as string);
    }

    if (!!tokenHeader) {
      outputConfig.headers = tokenHeader;
    } else {
      return new axios.Cancel('No valid token');
    }
  }

  return outputConfig;
};

const reqErrInterceptor = (error: AxiosError) => {
  return Promise.reject(error);
};

/* Response */
const resInterceptor = (response: AxiosResponse<any>) => {
  if (isDev()) {
    // const duration = process.hrtime(
    //   (response.config as AxiosRequestConfigMetadata).metadata
    // );
    // console.debug(
    //   response.config.url
    //     ?.replace(API_URL as string, 'BACKEND/')
    //     ?.replace(PYTHON_ANYWHERE_URL, 'API/')
    //     ?.replace(/\/$/, ''),
    //   ' @ ',
    //   `${(duration[1] / 1000000).toFixed(2)}ms`
    // );
  }
  return response;
};
const resErrInterceptor =
  (dispatch: Dispatch, history: History<unknown>) => (error: AxiosError) => {
    const data = error.response?.data;
    console.error(data?.showMessage || data?.message || error);
    dispatch(setAxiosError(error.response?.data || error));
    if ([401, 402, 403].includes(error.response?.status || 0)) {
      signOutBundler(dispatch, history);
    }
    // return Promise.reject(error);
  };

export const axiosInstance = axios.create({ baseURL: '' });

const AxiosInterceptor = ({ children }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    /* Error Interceptor */
    const responseInterceptor = axiosInstance.interceptors.response.use(
      resInterceptor,
      resErrInterceptor(dispatch, history)
    );

    /* Timing Debug Interceptors */
    let requestInterceptor = axiosInstance.interceptors.request.use(
      getReqInterceptor(isDev()),
      reqErrInterceptor
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, history]);

  return children;
};

export default AxiosInterceptor;
