import {
  CLOSE_ACTIVE_FREE_TRIAL_DIALOG,
  CLOSE_CHART_MENU,
  CLOSE_LARGE_CHART_DIALOG,
  CLOSE_NAV_MENU,
  CLOSE_RATING_DIALOG,
  CLOSE_REPORT_ISSUE_DIALOG,
  CLOSE_SIGN_IN_DIALOG,
  CLOSE_TABLE_DIALOG,
  CLOSE_USER_SETTINGS_MENU,
  DispatchActionTypes,
  IModalsState,
  OPEN_ACTIVE_FREE_TRIAL_DIALOG,
  OPEN_CHART_MENU,
  OPEN_LARGE_CHART_DIALOG,
  OPEN_NAV_MENU,
  OPEN_RATING_DIALOG,
  OPEN_REPORT_ISSUE_DIALOG,
  OPEN_SIGN_IN_DIALOG,
  OPEN_TABLE_DIALOG,
  OPEN_USER_SETTINGS_MENU,
  TOGGLE_ALERT,
} from '../../models/modals.model';

const initialState: IModalsState = {};

const modalsReducer = (
  state: IModalsState = initialState,
  action: DispatchActionTypes
): IModalsState => {
  switch (action.type) {
    // Report issue
    case OPEN_REPORT_ISSUE_DIALOG:
      return {
        ...state,
        reportIssueDialogData: { ...action.payload },
      };
    case CLOSE_REPORT_ISSUE_DIALOG:
      return {
        ...state,
        reportIssueDialogData: undefined,
      };

    // Large chart
    case OPEN_LARGE_CHART_DIALOG:
      return {
        ...state,
        largeChartDialogData: action.payload,
      };
    case CLOSE_LARGE_CHART_DIALOG:
      return {
        ...state,
        largeChartDialogData: undefined,
      };

    // Table dialog
    case OPEN_TABLE_DIALOG:
      return {
        ...state,
        tableDialogData: action.payload,
      };
    case CLOSE_TABLE_DIALOG:
      return {
        ...state,
        tableDialogData: undefined,
      };

    // Chart menu
    case OPEN_CHART_MENU:
      return {
        ...state,
        menuData: action.payload,
      };
    case CLOSE_CHART_MENU:
      return {
        ...state,
        menuData: {
          ...state.menuData,
          open: false,
        },
      };

    // Nav menu
    case OPEN_NAV_MENU:
      return {
        ...state,
        navMenuData: {
          open: true,
          anchorPosition: action.payload.anchorPosition,
          linkList: action.payload.linkList,
          closeTrigger: undefined,
        },
      };
    case CLOSE_NAV_MENU:
      return {
        ...state,
        navMenuData: {
          ...state.navMenuData,
          closeTrigger: action.payload,
          open: false,
        },
      };

    // Free trial dialog
    case OPEN_ACTIVE_FREE_TRIAL_DIALOG:
      return {
        ...state,
        activeFreeTrialOpen: true,
      };
    case CLOSE_ACTIVE_FREE_TRIAL_DIALOG:
      return {
        ...state,
        activeFreeTrialOpen: false,
      };

    // User settings
    case OPEN_USER_SETTINGS_MENU:
      return {
        ...state,
        userSettingsMenuData: {
          open: true,
          anchorPosition: action.payload.anchorPosition,
        },
      };
    case CLOSE_USER_SETTINGS_MENU:
      return {
        ...state,
        userSettingsMenuData: {
          ...state.navMenuData,
          open: false,
        },
      };

    // Alert
    case TOGGLE_ALERT:
      return {
        ...state,
        showAlert: action.payload,
      };

    // Rating dialog
    case OPEN_RATING_DIALOG:
      return {
        ...state,
        openRatingDialog: true,
      };
    case CLOSE_RATING_DIALOG:
      return {
        ...state,
        openRatingDialog: false,
      };
    // Rating dialog
    case OPEN_SIGN_IN_DIALOG:
      return {
        ...state,
        openSignInDialog: true,
      };
    case CLOSE_SIGN_IN_DIALOG:
      return {
        ...state,
        openSignInDialog: false,
      };
    default: {
      return state;
    }
  }
};

export default modalsReducer;
