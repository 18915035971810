export interface IIconImage {
  base64: string;
  url: string;
}

export enum IconShapeEnum {
  ORIGINAL = 'original',
  SQUARED = 'squared',
  ROUNDED = 'rounded',
}
