import {
  DispatchActionTypes,
  IErrorHandlingState,
  SET_AXIOS_ERROR,
  SET_GLOBAL_ERROR,
} from '../../models/error-handling.model';

const initialState: IErrorHandlingState = {};

const errorHandlingReducer = (
  state: IErrorHandlingState = initialState,
  action: DispatchActionTypes
): IErrorHandlingState => {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_AXIOS_ERROR:
      return {
        ...state,
        axiosError: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default errorHandlingReducer;
