import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMPANY_DIVIDENDS_TITLE_LABELS } from '../utils/visuals.config';

const CompanyDividendsTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
    const dividendYieldData = useSelector((state: RootState) => state.visuals.stock.dividendYieldData);
    const dividendYieldDataUpdating = useSelector((state: RootState) => state.visuals.stock.dividendYieldDataUpdating);
    const dividendYieldDistributionData = useSelector((state: RootState) => state.visuals.stock.dividendYieldDistributionData);
    const dividendYieldDistributionDataUpdating = useSelector((state: RootState) => state.visuals.stock.dividendYieldDistributionDataUpdating);
    const payoutRatioData = useSelector((state: RootState) => state.visuals.stock.payoutRatioData);
    const payoutRatioDataUpdating = useSelector((state: RootState) => state.visuals.stock.payoutRatioDataUpdating);
    const dividendHistoryData = useSelector((state: RootState) => state.visuals.stock.dividendHistoryData);
    const dividendHistoryDataUpdating = useSelector((state: RootState) => state.visuals.stock.dividendHistoryDataUpdating);
    const dividendTTMData = useSelector((state: RootState) => state.visuals.stock.dividendTTMData);
    const dividendTTMDataUpdating = useSelector((state: RootState) => state.visuals.stock.dividendTTMDataUpdating);

  const [dividendYield, setDividendYield] = useState<ChartData>();
  const [dividendYieldDistribution, setDividendYieldDistribution] =
    useState<ChartData>();
  const [payoutRatio, setPayoutRatio] = useState<ChartData>();
  const [dividendHistory, setDividendHistory] = useState<ChartData>();
  const [dividendTTM, setDividendTTM] = useState<ChartData>();

  /* Dividend Yield Chart */
  const [dividendYieldHasData, dividendYieldHasLabels, dividendYieldError] =
    extractChartMetadata(dividendYieldData);
  useEffect(() => {
    setDividendYield({
      datasets: createChartDatasetsConfig(
        dividendYieldData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    dividendYieldData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Dividend Yield Distribution */
  const [
    dividendYieldDistributionHasData,
    dividendYieldDistributionHasLabels,
    dividendYieldDistributionError,
  ] = extractChartMetadata(dividendYieldDistributionData);
  useEffect(() => {
    setDividendYieldDistribution({
      labels: dividendYieldDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        dividendYieldDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [dividendYieldDistributionData, screenWidth, currentCompanyTickerId]);

  /* Payout Ratio Chart */
  const [payoutRatioHasData, payoutRatioHasLabels, payoutRatioError] =
    extractChartMetadata(payoutRatioData);
  useEffect(() => {
    setPayoutRatio({
      datasets: createChartDatasetsConfig(payoutRatioData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [payoutRatioData, useDollars, fiscalYear, screenWidth]);

  /* Dividend History Chart */
  const [
    dividendHistoryHasData,
    dividendHistoryHasLabels,
    dividendHistoryError,
  ] = extractChartMetadata(dividendHistoryData);
  useEffect(() => {
    setDividendHistory({
      datasets: createChartDatasetsConfig(dividendHistoryData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [dividendHistoryData, useDollars, fiscalYear, screenWidth]);

  /* Dividend TTM Chart */
  const [dividendTTMHasData, dividendTTMHasLabels, dividendTTMError] =
    extractChartMetadata(dividendTTMData);
  useEffect(() => {
    setDividendTTM({
      datasets: createChartDatasetsConfig(dividendTTMData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [dividendTTMData, useDollars, fiscalYear, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COMPANY_DIVIDENDS));
  }, [dispatch]);

  return (
    <>
      {dividendYieldDataUpdating &&
        dividendYieldDistributionDataUpdating &&
        dividendHistoryDataUpdating &&
        dividendTTMDataUpdating &&
        payoutRatioDataUpdating && <LoadingComponent transparent={true} />}

      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendYield}
          type="line"
          data={dividendYield}
          error={
            !!dividendYieldError
              ? GENERAL_ERROR_MESSAGE
              : !dividendYieldHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={dividendYieldHasData}
          exportData={dividendYieldData}
          showLegend={true}
          currencySymbol={
            !!dividendYieldData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!dividendYieldData?.isPercentage}
          updating={!!dividendYieldDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={dividendYieldDistribution}
          error={
            !!dividendYieldDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !dividendYieldDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendYieldDistr}
          hasData={dividendYieldDistributionHasData}
          exportData={dividendYieldDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!dividendYieldDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!dividendYieldDistributionData?.isPercentage}
          updating={!!dividendYieldDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={dividendHistory}
          error={
            !!dividendHistoryError
              ? GENERAL_ERROR_MESSAGE
              : !dividendHistoryHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendHistory}
          hasData={dividendHistoryHasData}
          exportData={dividendHistoryData}
          currencySymbol={
            !!dividendHistoryData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!dividendHistoryData?.isPercentage}
          updating={!!dividendHistoryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={dividendTTM}
          error={
            !!dividendTTMError
              ? GENERAL_ERROR_MESSAGE
              : !dividendTTMHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendTTM}
          hasData={dividendTTMHasData}
          exportData={dividendTTMData}
          currencySymbol={
            !!dividendTTMData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!dividendTTMData?.isPercentage}
          updating={!!dividendTTMDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={payoutRatio}
          error={
            !!payoutRatioError
              ? GENERAL_ERROR_MESSAGE
              : !payoutRatioHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.payoutRatio}
          hasData={payoutRatioHasData}
          exportData={payoutRatioData}
          currencySymbol={
            !!payoutRatioData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!payoutRatioData?.isPercentage}
          updating={!!payoutRatioDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default CompanyDividendsTab;
