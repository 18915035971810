import { TOOLTIP_FONT_SIZE } from '../constants/general.constants';
import { pxToRem } from '../ui/functions';

export const iconStyles = {
  listItemIconRoot: {
    minWidth: 0,
    '& svg': {
      fontSize: '1.6rem',
    },
  },
  iconButtonRoot: {
    fontSize: '1rem',
    margin: '0rem 0.05rem 0.5rem 0.0rem',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
    padding: pxToRem(12),
  },
  tooltip: {
    fontSize: TOOLTIP_FONT_SIZE,
    padding: `${pxToRem(4)} ${pxToRem(8)}`,
    margin: `${pxToRem(24)} ${pxToRem(0)}`,
    borderRadius: pxToRem(4),
    maxWidth: pxToRem(300),
  },
  customSelectIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0.1rem',
    '& .MuiInput-underline::before, & .MuiInput-underline::after': {
      content: 'none',
    },
    '& .MuiInputBase-root': {
      cursor: 'default',
    },

    '& .MuiSelect-select.MuiSelect-select': {
      padding: 0,
    },

    '& .MuiSelect-select': {
      minWidth: 0,
    },
  },
};
