import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { SxProps, Theme } from '@mui/material';
import isDev from '../../constants/is-dev.constants';
import { IMicrosoftLogin } from '../../models/authentication.model';
import LoginButton from './LoginButton';
import { MicrosoftLogo } from './logos';

const MSAL_CONFIG = (clientId: string): Configuration => ({
  auth: {
    clientId,
    // authority: `https://login.microsoftonline.com/${tenantId}`,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: isDev()
      ? 'http://localhost:3000'
      : 'https://app.prometeus.finance',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          // FIXME
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});

class AzureAuthenticationContext {
  private myMSALObj: PublicClientApplication;
  private account?: AccountInfo;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isAuthenticationConfigured = false;

  constructor(clientId: string) {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG(clientId));
    // @ts-ignore
    this.account = null;
    this.setRequestObjects();
    if (clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  login = (signInType: string, setUser: any) => {
    if (signInType === 'loginPopup') {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp, setUser);
        });
      // .catch((err) => {
      //   console.error(err);
      // });
    } else if (signInType === 'loginRedirect') {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  };

  handleResponse = (
    response: AuthenticationResult,
    incomingFunction: (data: IMicrosoftLogin) => void
  ) => {
    if (response !== null && response.account !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      incomingFunction({
        email: this.account.username,
        tokenId: response.idToken,
      });
    }
  };

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: [],
      prompt: 'select_account',
    };
  }

  private getAccount(): AccountInfo | undefined {
    console.log('loadAuthModule');
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      // @ts-ignore
      console.log('No accounts detected');
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // TBD: Add choose account code here
      // @ts-ignore
      console.log(
        'Multiple accounts detected, need to add choose account code.'
      );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

// const ua = window.navigator.userAgent;
// const msie = ua.indexOf('MSIE ');
// const msie11 = ua.indexOf('Trident/');
// const isIE = msie > 0 || msie11 > 0;

export type MicrosoftLoginButtonProps = {
  onSuccess: (data: IMicrosoftLogin) => void;
  clientId: string;
  sx: SxProps<Theme>;
  label?: string;
};

const MicrosoftLoginButton = (props: MicrosoftLoginButtonProps) => {
  // Azure client context
  const authenticationModule: AzureAuthenticationContext =
    new AzureAuthenticationContext(props.clientId);

  const logIn = (): any => {
    const logInType = 'loginPopup';

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };

  const returnedAccountInfo = (data: IMicrosoftLogin) => {
    props.onSuccess(data);
  };

  return (
    <LoginButton
      text={props.label || 'Sign in with Microsoft'}
      logo={MicrosoftLogo}
      onClick={() => {
        logIn();
      }}
      sx={props.sx}
    />
  );
};

export default MicrosoftLoginButton;
