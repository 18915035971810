import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { ROS_ROE_TITLE_LABELS } from '../utils/visuals.config';

const RosRoeTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *              Chars Data             *
   ***************************************/
  const rosData = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.rosData
  );
  const rosDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.rosDataUpdating
  );
  const rosDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.rosDistributionData
  );
  const rosDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.rosDistributionDataUpdating
  );
  const roeData = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roeData
  );
  const roeDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roeDataUpdating
  );
  const roeDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roeDistributionData
  );
  const roeDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roeDistributionDataUpdating
  );

  const [ros, setRos] = useState<ChartData>();
  const [rosDistribution, setRosDistribution] = useState<ChartData>();
  const [roe, setRoe] = useState<ChartData>();
  const [roeDistribution, setRoeDistribution] = useState<ChartData>();

  /* ROS */
  const [rosHasData, rosHasLabels, rosError] = extractChartMetadata(rosData);
  useEffect(() => {
    setRos({
      datasets: createChartDatasetsConfig(
        rosData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [rosData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* ROE */
  const [roeHasData, roeHasLabels, roeError] = extractChartMetadata(roeData);
  useEffect(() => {
    setRoe({
      datasets: createChartDatasetsConfig(
        roeData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [roeData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* ROS Distribution */
  const [
    rosDistributionHasData,
    rosDistributionHasLabels,
    rosDistributionError,
  ] = extractChartMetadata(rosDistributionData);
  useEffect(() => {
    setRosDistribution({
      labels: rosDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        rosDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [rosDistributionData, screenWidth, currentCompanyTickerId]);

  /* ROE Distribution */
  const [
    roeDistributionHasData,
    roeDistributionHasLabels,
    roeDistributionError,
  ] = extractChartMetadata(roeDistributionData);
  useEffect(() => {
    setRoeDistribution({
      labels: roeDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        roeDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [roeDistributionData, screenWidth, currentCompanyTickerId]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.ROS_ROE));
  }, [dispatch]);

  return (
    <>
      {rosDataUpdating &&
        rosDistributionDataUpdating &&
        roeDataUpdating &&
        roeDistributionDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={ros}
          error={
            !!rosError
              ? GENERAL_ERROR_MESSAGE
              : !rosHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROS_ROE_TITLE_LABELS.ros}
          hasData={rosHasData}
          exportData={rosData}
          showLegend={true}
          currencySymbol={
            !!rosData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!rosData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!rosDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={rosDistribution}
          error={
            !!rosDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !rosDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROS_ROE_TITLE_LABELS.rosDistribution}
          hasData={rosDistributionHasData}
          exportData={rosDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!rosDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!rosDistributionData?.isPercentage}
          updating={!!rosDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={roe}
          error={
            !!roeError
              ? GENERAL_ERROR_MESSAGE
              : !roeHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROS_ROE_TITLE_LABELS.roe}
          hasData={roeHasData}
          exportData={roeData}
          showLegend={true}
          currencySymbol={
            !!roeData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roeData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!roeDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={roeDistribution}
          error={
            !!roeDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !roeDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROS_ROE_TITLE_LABELS.roeDistribution}
          hasData={roeDistributionHasData}
          exportData={roeDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!roeDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roeDistributionData?.isPercentage}
          updating={!!roeDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default RosRoeTab;
