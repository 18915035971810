import { ITokenIsAdmin } from '@prometeus/common';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { DEFAULT_COMPANY } from '../constants/general.constants';
import {
  IAuthenticationState,
  LocalStorageEnum,
} from '../models/authentication.model';
import RootReducer from './reducers/root.reducer';
import { initialStructureState } from './reducers/structure.reducer';

export type RootStore = ReturnType<typeof RootReducer>;

const readLocalStorage = () => {
  const tokens: ITokenIsAdmin = JSON.parse(
    localStorage.getItem(LocalStorageEnum.TOKEN) as string
  );

  const tickerId: string =
    (localStorage.getItem(LocalStorageEnum.TICKER_ID) as string) ||
    DEFAULT_COMPANY.tickerId;

  return {
    accessToken: tokens.accessToken,
    tickerId,
  };
};

// Get authentication and user stored data on store creation
const preloadState = (): any => {
  let authentication: IAuthenticationState = {
    isAdmin: false,
  };

  try {
    const {
      accessToken,
      tickerId,
    } = readLocalStorage();

    return {
      authentication: {
        accessToken,
        isAdmin: true // In order to avoid admin pages to be re-rendered on loading (even if the user is admin)
      },
      structure: {
        ...initialStructureState,
        tickerId,
        // peerGroup: PeerGroupEnum.REGIONAL,
      },
    };
  } catch (err: any) {
    return {
      authentication,
      structure: initialStructureState,
    };
  }
};

// This middleware will just add the property "async dispatch" to all actions
const asyncDispatchMiddleware =
  (reduxStore: any) => (next: any) => (action: any) => {
    let syncActivityFinished = false;
    let actionQueue: any[] = [];

    const flushQueue = () => {
      actionQueue.forEach((a) => reduxStore.dispatch(a)); // flush queue
      actionQueue = [];
    };

    const asyncDispatch = (asyncAction: any) => {
      actionQueue = actionQueue.concat([asyncAction]);

      if (syncActivityFinished) {
        flushQueue();
      }
    };

    const actionWithAsyncDispatch = Object.assign({}, action, {
      asyncDispatch,
    });

    const res = next(actionWithAsyncDispatch);

    syncActivityFinished = true;
    flushQueue();

    return res;
  };

// Create store
const store = createStore(
  RootReducer,
  { ...preloadState() },
  applyMiddleware(ReduxThunk, asyncDispatchMiddleware)
);

export default store;
