import { uniqueStringArray } from '@prometeus/common';
import { ChartDataset } from 'chart.js';
import moment from 'moment';
import {
  buildDistrDatasetOptions,
  createChartDatasetConfig,
} from '../../../constants/charts/chart.constants';
import { makeDate } from '../../../constants/charts/visuals.constants';
import { isInRange } from '../../../constants/utils.constants';
import {
  CreateChartDatasetOptionsType,
  IChartRawData,
  IVisualConfigData,
} from '../../../models/visuals.model';
import { COMMON_COLORS } from '../../../ui/colors';
import { IAVisualChart } from './visuals.model';

export const A_PAGE_LIMIT = 3;

export const DEFAULT_EMPTY_OPTION_CHART_MESSAGE = 'Select a chart type';

export const DEFAULT_EMPTY_CSI_OPTION_CHART_MESSAGE = (
  type: 'sectors' | 'industries' | 'countries'
) => `Select a chart type and select up to ${A_PAGE_LIMIT} ${type}`;

export const DEFAULT_EMPTY_CSI_CHART_MESSAGE = (
  type: 'sectors' | 'industries' | 'countries'
) => `Select up to ${A_PAGE_LIMIT} ${type}`;

export const getEmptyOptionMessage = (
  type: 'sectors' | 'industries' | 'countries',
  hasElements: boolean,
  hasOption: boolean
): string =>
  !hasElements && !hasOption
    ? DEFAULT_EMPTY_CSI_OPTION_CHART_MESSAGE(type)
    : hasElements
    ? DEFAULT_EMPTY_OPTION_CHART_MESSAGE
    : DEFAULT_EMPTY_CSI_CHART_MESSAGE(type);

/* Columns should be in the following order:
 * label
 * group
 * values
 */
export const processAVisualData = (
  data: IChartRawData,
  configData: IVisualConfigData,
  dateLabel?: boolean
): IAVisualChart => {
  const columns: string[] = configData.columns;
  const isPercentage = !!configData.isPercentage;

  /* Initialisations */
  let labels: string[] = data[columns[0]] as string[];
  const groups: string[] = data[columns[1]] as string[];
  let values: number[] = data[columns[2]] as number[];

  if (!!dateLabel) {
    labels = labels?.map((e: string) => `${makeDate(e)}`);
  }

  if (isPercentage) {
    values = values?.map((e: number) => e * 100);
  }

  /* Process data, separating by group */
  const datasetLabels: string[] = (uniqueStringArray(groups) as string[]).sort(
    (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())
  );
  const datasetLabelsMap = new Map<string, number>(
    datasetLabels?.map((e: string, i: number) => [e, i])
  ); // Used to get in O(1)

  const dataArrMap = new Map<string, number[]>(); // label - values
  const EMPTY_GROUP_VALUE_PAIRS: number[] = Array(datasetLabels.length).fill(
    null
  );
  groups.forEach((group: string, index: number) => {
    const groupIndex = datasetLabelsMap.get(group) as number;

    const label = !!labels?.length ? labels[index] : '';
    const value = !!values?.length ? values[index] : 0;
    let currentPairs: number[] = [];
    if (!dataArrMap.has(label)) {
      currentPairs = [...EMPTY_GROUP_VALUE_PAIRS];
    } else {
      currentPairs = [...(dataArrMap.get(label) as number[])];
    }
    currentPairs[groupIndex] = value;
    dataArrMap.set(label, currentPairs);
  });

  const mappedLabels = Array.from(dataArrMap.keys());
  const dataArr: number[][] = Array(datasetLabels.length).fill([]);
  mappedLabels.forEach((label: string) => {
    const mappedValues: number[] = dataArrMap.get(label) as number[];
    mappedValues.forEach((value: number, groupIndex: number) => {
      dataArr[groupIndex] = [...dataArr[groupIndex], value];
    });
  });

  return {
    labels: mappedLabels,
    dataArr: dataArr,
    hasData: true,
    dateLabel: !!dateLabel,
    columns,
    datasetLabels: datasetLabels?.map((e: string) => ({ label: e })),
    isPercentage: !!configData.isPercentage,
  };
};

export const createAChartDatasetsConfig = <T extends IAVisualChart>(
  data: T | undefined,
  screenWidth: number,
  options?: {
    isTime?: boolean;
    isDistr?: boolean;
    timeWindow?: [number, number];
  }
): ChartDataset[] => {
  const isTime = !!options?.isTime;
  const isDistr = !!options?.isDistr;
  const timeWindow = options?.timeWindow;

  /* Consider Time Window */
  let twKeepIndexes: Set<number> = new Set([]);
  if (!!data?.labels) {
    twKeepIndexes = new Set([...data.labels.keys()]);
    if (!!timeWindow) {
      twKeepIndexes = new Set(
        [...data.labels.keys()].filter((index: number) =>
          isInRange(
            moment(+data.labels[index]).year(),
            timeWindow[1],
            timeWindow[0]
          )
        )
      );
    }
  }

  return data && data?.datasetLabels
    ? data.datasetLabels?.map(
        (datasetOption: CreateChartDatasetOptionsType, i: number) =>
          createChartDatasetConfig(
            screenWidth,
            (data?.labels || []).filter((_, index: number) =>
              twKeepIndexes.has(index)
            ),
            ((data?.dataArr[i] as number[]) || []).filter((_, index: number) =>
              twKeepIndexes.has(index)
            ),
            COMMON_COLORS[`chartColor${i + 1}` as keyof typeof COMMON_COLORS],
            isDistr
              ? buildDistrDatasetOptions(datasetOption, 0, screenWidth)
              : datasetOption,
            false,
            undefined,
            isTime,
            false
          )
      )
    : [];
};

export const makeDistributionTitle = (title: string): string =>
  title + ' Distribution';

export const extractChartMetadata = <T extends IAVisualChart>(
  data?: T
): [boolean, boolean, string | undefined] => [
  !!data?.hasData,
  !!data?.labels?.length,
  data?.error,
];
