import { Box, Checkbox, Palette, useTheme } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';
import { ITableColumnData } from '@prometeus/common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openActiveFreeTrialDialog } from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import { pxToRem } from '../../ui/functions';
import * as tableUtils from './table.constants';

const HeadCheckbox = (props: {
  checked: boolean;
  unchecked: boolean;
  disabledCheckbox: boolean;
  checkHandler?: () => void;
}) => {
  const theme = useTheme();
  const headStyles = getTableHeadStyles(theme.palette);
  const { checked, unchecked, disabledCheckbox, checkHandler } = props;
  return (
    <TableCell
      padding="checkbox"
      sx={{
        ...headStyles.tableHeadCell,
        [`& .${tableCellClasses.stickyHeader}`]: headStyles.stickyTable,
        [`& .${tableCellClasses.paddingCheckbox}`]: headStyles.checkbox,
      }}
    >
      <Checkbox
        sx={headStyles.checkboxRoot}
        indeterminate={!checked && !unchecked}
        checked={!disabledCheckbox && checked}
        onChange={checkHandler}
        disabled={disabledCheckbox}
        inputProps={{ 'aria-label': 'select all companies' }}
      />
    </TableCell>
  );
};

type Props = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  sortOrder: tableUtils.Order;
  sortBy: string;
  rowCount: number;
  headCells: ITableColumnData[];
  hasCheckboxes?: 'right' | 'left';
  disabledCheckbox?: boolean;
  uncheckedCheckbox?: boolean;
  checkedCheckbox?: boolean;
  onCheckAll?: () => void;
  rightCell?: React.ReactNode;
  headFilterProperty?: keyof ITableColumnData;
};

export const getTableHeadStyles = (palette: Palette) => ({
  tableHeadCell: {
    borderBottomColor: palette.primary.main,
    borderBottomWidth: pxToRem(1),
    padding: pxToRem(16),
    backgroundColor: palette.primary.main,
  },
  checkbox: {
    '&:last-child': {
      paddingRight: pxToRem(4),
    },
    width: pxToRem(48),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: pxToRem(1),
    margin: pxToRem(-1),
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: pxToRem(20),
    width: pxToRem(1),
  },
  tableRow: {
    height: `${tableUtils.HEAD_ROW_HEIGHT_REM}rem !important`,
  },
  stickyTable: {
    backgroundColor: palette.primary.main,
  },
  tableSortLabelRoot: {
    width: 'max-content',
    fontSize: '1rem',
    color: 'white !important',
    '&:hover': {
      opacity: 0.8,
    },
    '& .MuiSvgIcon-root': {
      color: 'white !important',
    },
  },
  tableSortLabelActive: {
    '& .MuiTableSortLabel-icon': {
      color: `${COMMON_COLORS.contrastPrimaryText} !important`,
      marginBottom: '0.23rem',
    },
  },
  tableSortLabelIcon: {
    fontSize: pxToRem(18),
    marginLeft: pxToRem(4),
    marginRight: pxToRem(4),
  },
  checkboxRoot: {
    marginBottom: '0.2rem',
    color: COMMON_COLORS.contrastPrimaryText,
    paddingTop: '0.5rem !important',
    paddingBottom: '0.5rem !important',
  },

  /* Statements */
  statementMinWidth: {
    minWidth: '10rem',
  },
});

const TableHeadComponent = (props: Props) => {
  const { sortOrder, sortBy, onRequestSort } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const headStyles = getTableHeadStyles(theme.palette);

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const createSortHandler =
    (headCell: ITableColumnData) => (event: React.MouseEvent<unknown>) => {
      const property: keyof ITableColumnData = props.headFilterProperty || 'id';
      onRequestSort(event, headCell[property] as string);
    };

  return (
    <TableHead>
      <TableRow sx={headStyles.tableRow}>
        {props.hasCheckboxes === 'left' && (
          <HeadCheckbox
            checked={!!props.checkedCheckbox}
            unchecked={!!props.uncheckedCheckbox}
            disabledCheckbox={!!props.disabledCheckbox}
            checkHandler={props.onCheckAll}
          />
        )}
        {props.headCells?.map((headCell) => (
          <TableCell
            sx={{
              ...headStyles.tableHeadCell,
              [`& .${tableCellClasses.stickyHeader}`]: headStyles.stickyTable,
            }}
            key={headCell.id}
            align={headCell.align}
            padding="normal"
            sortDirection={sortBy === headCell.item ? sortOrder : false}
            style={{
              minWidth: headCell.minWidth ? `${headCell.minWidth}rem` : 0,
            }}
          >
            <TableSortLabel
              active={sortBy === headCell.item}
              direction={sortBy === headCell.item ? sortOrder : 'asc'}
              onClick={
                !hasActiveLicence
                  ? () => dispatch(openActiveFreeTrialDialog())
                  : createSortHandler(headCell)
              }
              sx={{
                ...headStyles.tableSortLabelRoot,
                [`& .${tableSortLabelClasses.active}`]:
                  headStyles.tableSortLabelActive,
                [`& .${tableSortLabelClasses.icon}`]:
                  headStyles.tableSortLabelIcon,
              }}
            >
              {headCell.label.split(' (')[0]}
              {sortBy === headCell.item ? (
                <Box component="span" sx={headStyles.visuallyHidden}>
                  {sortOrder === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.hasCheckboxes === 'right' && (
          <HeadCheckbox
            checked={!!props.checkedCheckbox}
            unchecked={!!props.uncheckedCheckbox}
            disabledCheckbox={!!props.disabledCheckbox}
            checkHandler={props.onCheckAll}
          />
        )}
        {props.rightCell && (
          <TableCell
            padding="checkbox"
            sx={{
              ...headStyles.tableHeadCell,
              [`& .${tableCellClasses.stickyHeader}`]: headStyles.stickyTable,
              [`& .${tableCellClasses.paddingCheckbox}`]: headStyles.checkbox,
            }}
          >
            {props.rightCell}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;
