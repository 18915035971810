import { ScreenerFilterNameEnum } from '@prometeus/common';
import { BetweenValuesType } from '../../../components/filters/screener/between.component';

export type ITabDispatcher<T> = (
  block: T,
  filter: ScreenerFilterNameEnum,
  value: any
) => void;

export const onMinBetweenValueHandler = <T>(
  tabDispatcher: ITabDispatcher<T>,
  cardBlock: T,
  value: number | null,
  isTime?: boolean
): void => {
  tabDispatcher(
    cardBlock,
    !!isTime
      ? ScreenerFilterNameEnum.MIN_YEAR
      : ScreenerFilterNameEnum.THRESHOLD_GREATER,
    value
  );
};
export const onMaxBetweenValueHandler = <T>(
  tabDispatcher: ITabDispatcher<T>,
  cardBlock: T,
  value: number | null,
  isTime?: boolean
): void => {
  tabDispatcher(
    cardBlock,
    !!isTime
      ? ScreenerFilterNameEnum.MAX_YEAR
      : ScreenerFilterNameEnum.THRESHOLD_LESS,
    value
  );
};
export const onBetweenValuesHandler = <T>(
  tabDispatcher: ITabDispatcher<T>,
  cardBlock: T,
  values: BetweenValuesType,
  isTime?: boolean
): void => {
  onMinBetweenValueHandler(tabDispatcher, cardBlock, values[0], isTime);
  onMaxBetweenValueHandler(tabDispatcher, cardBlock, values[1], isTime);
};
