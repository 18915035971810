import {
  BETWEEN_VALUE,
  BETWEEN_YEARS,
  BUTTON_YEAR_LABELS,
  DEFAULT_SCREENER_FILTER_VALUE,
  GREATER_LESS,
  INCREASING_DECREASING,
  IScreenerConfig,
  MARKET_CAP_LIST,
  PEERS_COMPARISON,
  ScreenerItemEnum,
  ScreenerLabelEnum,
} from '@prometeus/common';

const SCREENER_STRUCTURE_CONFIG: IScreenerConfig = {
  stock: {
    price: {
      boxLabel: ScreenerLabelEnum.PRICE,
      item: ScreenerItemEnum.PRICE,
      components: [
        {
          label: BETWEEN_VALUE,
          min: 0,
          step: 10000,
          max: 1000000,
        },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(false, true),
    },

    priceEarningsRatio: {
      boxLabel: ScreenerLabelEnum.PE,
      item: ScreenerItemEnum.PE,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        { ...BETWEEN_YEARS, min: new Date().getFullYear() - 10 },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true, false, false, true),
    },

    marketCap: {
      boxLabel: ScreenerLabelEnum.MARKET_CAP,
      item: ScreenerItemEnum.MARKET_CAP,
      components: [
        {
          list: MARKET_CAP_LIST,
          label: 'Category',
        },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(false, false, true),
    },

    dividend: {
      boxLabel: ScreenerLabelEnum.DIVIDEND,
      item: 'Dividend TTM',
      components: [
        {
          list: [
            { label: 'Increasing', value: 'Increasing' },
            { label: 'Decreasing', value: 'Decreasing' },
            { label: 'Not decreasing', value: 'Not decreasing' },
            { label: 'Not increasing', value: 'Not increasing' },
          ],
          label: 'Dividend TTM Trend',
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },

    dividendYield: {
      boxLabel: ScreenerLabelEnum.DIV_YIELD,
      item: ScreenerItemEnum.DIV_YIELD,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 30,
          step: 1,
          isPercent: true,
        },
        { ...BETWEEN_YEARS, min: new Date().getFullYear() - 10 },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true, false, false, true),
    },

    payoutRatio: {
      boxLabel: ScreenerLabelEnum.PAYOUT,
      item: ScreenerItemEnum.PAYOUT,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 200,
          step: 10,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
  },

  incomeStatement: {
    profitMargin: {
      boxLabel: ScreenerLabelEnum.PROFIT_MARGIN,
      item: ScreenerItemEnum.PROFIT_MARGIN,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: -50,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    grossMargin: {
      boxLabel: ScreenerLabelEnum.GROSS_MARGIN,
      item: ScreenerItemEnum.GROSS_MARGIN,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: -50,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    opInc: {
      boxLabel: ScreenerLabelEnum.OP_INC,
      item: ScreenerItemEnum.OP_INC,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: -50,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    revGrowth: {
      boxLabel: ScreenerLabelEnum.REVENUE_GROWTH,
      item: ScreenerItemEnum.REVENUE_RATE,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 100,
          step: 1,
          isPercent: true,
        },
        {
          list: INCREASING_DECREASING,
          label: 'Revenue Growth Trend',
        },
        {
          label: 'Years',
          list: BUTTON_YEAR_LABELS,
        },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(),
    },
    profGrowth: {
      boxLabel: ScreenerLabelEnum.PROFIT_GROWTH,
      item: ScreenerItemEnum.NET_INCOME_RATE,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 100,
          step: 1,
          isPercent: true,
        },
        {
          list: INCREASING_DECREASING,
          label: 'Profit Growth Trend',
        },
        {
          label: 'Years',
          list: BUTTON_YEAR_LABELS,
        },
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(),
    },
  },

  managementEffectiveness: {
    ROS: {
      boxLabel: ScreenerLabelEnum.ROS,
      item: ScreenerItemEnum.ROS,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    ROE: {
      boxLabel: ScreenerLabelEnum.ROE,
      item: ScreenerItemEnum.ROE,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    ROA: {
      boxLabel: ScreenerLabelEnum.ROA,
      item: ScreenerItemEnum.ROA,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    ROIC: {
      boxLabel: ScreenerLabelEnum.ROIC,
      item: ScreenerItemEnum.ROIC,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 50,
          step: 1,
          isPercent: true,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
  },

  financialStrength: {
    currentRatio: {
      boxLabel: ScreenerLabelEnum.CURRENT_RATIO,
      item: ScreenerItemEnum.CURRENT_RATIO,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 2,
          step: 0.1,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    quickRatio: {
      boxLabel: ScreenerLabelEnum.QUICK_RATIO,
      item: ScreenerItemEnum.QUICK_RATIO,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 2,
          step: 0.1,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    debtToEquity: {
      boxLabel: ScreenerLabelEnum.DE,
      item: ScreenerItemEnum.DE,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 1,
          step: 0.01,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
    debtPayback: {
      boxLabel: ScreenerLabelEnum.DEBT_PAYBACK,
      item: ScreenerItemEnum.DEBT_PAYBACK,
      components: [
        {
          list: GREATER_LESS,
          label: PEERS_COMPARISON,
        },
        {
          label: BETWEEN_VALUE,
          min: 0,
          max: 20,
          step: 1,
        },
        BETWEEN_YEARS,
      ],
      ...DEFAULT_SCREENER_FILTER_VALUE(true),
    },
  },
};

export default SCREENER_STRUCTURE_CONFIG;
