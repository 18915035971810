import { Card, CardContent, Palette, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';

type Props = {
  children: React.ReactNode;
};

const getStyles = (palette: Palette) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '98%',
  },
  cardRoot: {
    margin: '1rem',
    color: palette.text.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    // overflow: 'auto',
    height: '100%',
  },
  mobileCardRoot: {
    margin: 0,
    padding: '3rem',
    // backgroundColor: palette.background.default,
    backgroundImage: 'none !important',
  },
  cardContentRoot: {
    padding: '0.5rem',
    paddingTop: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const FiltersCardComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <Box sx={styles.container}>
      <Card
        sx={{
          ...styles.cardRoot,
          ...(isPortraitMobile ? styles.mobileCardRoot : {}),
        }}
      >
        <CardContent sx={{ ...styles.cardContentRoot }}>
          {props.children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default FiltersCardComponent;
