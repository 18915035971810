import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processVisualData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  DispatchActionTypes,
  IVisualsState,
  MANAGEMENT_EFFECTIVENESS_UPDATE,
  ROA_DISTRIBUTION,
  ROA_QUARTERLY,
  ROA_YEARLY,
  ROE_DISTRIBUTION,
  ROE_QUARTERLY,
  ROE_YEARLY,
  ROIC_DISTRIBUTION,
  ROIC_QUARTERLY,
  ROIC_YEARLY,
  ROS_DISTRIBUTION,
  ROS_QUARTERLY,
  ROS_YEARLY,
} from './visuals.model';

const CHART_ACTIONS_SET = new Set([MANAGEMENT_EFFECTIVENESS_UPDATE]);

const initialState: IVisualsState = {};

const managementEffectivenessVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case MANAGEMENT_EFFECTIVENESS_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;

    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      case ROS_YEARLY:
        if (hashedData === state.rosData?.checksum) {
          return { ...state, rosDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          rosData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          rosDataUpdating: false,
        };
      case ROS_QUARTERLY:
        if (hashedData === state.rosData?.checksum) {
          return { ...state, rosDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          rosData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          rosDataUpdating: false,
        };

      case ROE_YEARLY:
        if (hashedData === state.roeData?.checksum) {
          return { ...state, roeDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roeData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roeDataUpdating: false,
        };

      case ROE_QUARTERLY:
        if (hashedData === state.roeData?.checksum) {
          return { ...state, roeDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roeData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roeDataUpdating: false,
        };

      case ROS_DISTRIBUTION:
        if (hashedData === state.rosDistributionData?.checksum) {
          return { ...state, rosDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          rosDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          rosDistributionDataUpdating: false,
        };

      case ROE_DISTRIBUTION:
        if (hashedData === state.roeDistributionData?.checksum) {
          return { ...state, roeDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roeDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          roeDistributionDataUpdating: false,
        };

      case ROA_YEARLY:
        if (hashedData === state.roaData?.checksum) {
          return { ...state, roaDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roaData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roaDataUpdating: false,
        };

      case ROA_QUARTERLY:
        if (hashedData === state.roaData?.checksum) {
          return { ...state, roaDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roaData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roaDataUpdating: false,
        };

      case ROIC_YEARLY:
        if (hashedData === state.roicData?.checksum) {
          return { ...state, roicDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roicData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roicDataUpdating: false,
        };

      case ROIC_QUARTERLY:
        if (hashedData === state.roicData?.checksum) {
          return { ...state, roicDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roicData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          roicDataUpdating: false,
        };

      case ROA_DISTRIBUTION:
        if (hashedData === state.roaDistributionData?.checksum) {
          return { ...state, roaDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roaDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          roaDistributionDataUpdating: false,
        };

      case ROIC_DISTRIBUTION:
        if (hashedData === state.roicDistributionData?.checksum) {
          return { ...state, roicDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          roicDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          roicDistributionDataUpdating: false,
        };

      default:
        return state;
    }
  }
};

export default managementEffectivenessVisualsReducer;
