import { Dispatch } from 'redux';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import {
  GroupBySectorEnum,
  IFetchChartDataVisual,
  IFetchSectorChartDataBody,
  IVisualConfig,
} from '../../../models/visuals.model';
import { fetchSectorChartDataService } from '../../../services/filters.service';
import { actionTryCatchWrapper } from '../../../utils/action-try-catch-wrapper';
import {
  APPLY_CTY_SEC_IND_FILTERS,
  FETCH_SECTOR_CHART_DATA,
  FETCH_SECTOR_CHART_DATA_FAIL,
  FETCH_SECTOR_CHART_DATA_SUCCESS,
  SELECT_COUNTRY_COUNTRY_IDS,
  SELECT_COUNTRY_INDUSTRIES,
  SELECT_COUNTRY_SECTORS,
  SELECT_INDUSTRY_COUNTRY_IDS,
  SELECT_INDUSTRY_INDUSTRIES,
  SELECT_INDUSTRY_SECTORS,
  SELECT_SECTOR_COUNTRY_IDS,
  SELECT_SECTOR_INDUSTRIES,
  SELECT_SECTOR_SECTORS,
  SET_BOTTOM_CHART_OPTION,
  SET_SECTOR_GROUP_BY,
  SET_TIME_WINDOW,
  SET_TOP_CHART_OPTION,
} from './visuals.model';

/***************************************
 *              Set Option             *
 ***************************************/
export const setTopChartOption = (option: string) => ({
  type: SET_TOP_CHART_OPTION,
  payload: option,
});

export const setBottomChartOption = (option: string) => ({
  type: SET_BOTTOM_CHART_OPTION,
  payload: option,
});

/***************************************
 *                Select               *
 ***************************************/
/* COUNTRY */
export const selectCountrySectors = (optionIndex: number) => ({
  type: SELECT_COUNTRY_SECTORS,
  payload: optionIndex,
});

export const selectCountryIndustries = (
  sectorIndex: number,
  industryIndex: number
) => ({
  type: SELECT_COUNTRY_INDUSTRIES,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const selectCountryCountries = (optionIndex: number) => ({
  type: SELECT_COUNTRY_COUNTRY_IDS,
  payload: optionIndex,
});

/* SECTOR */
export const selectSectorSectors = (optionIndex: number) => ({
  type: SELECT_SECTOR_SECTORS,
  payload: optionIndex,
});

export const selectSectorIndustries = (
  sectorIndex: number,
  industryIndex: number
) => ({
  type: SELECT_SECTOR_INDUSTRIES,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const selectSectorCountries = (optionIndex: number) => ({
  type: SELECT_SECTOR_COUNTRY_IDS,
  payload: optionIndex,
});

/* INDUSTRY */
export const selectIndustrySectors = (optionIndex: number) => ({
  type: SELECT_INDUSTRY_SECTORS,
  payload: optionIndex,
});

export const selectIndustryIndustries = (
  sectorIndex: number,
  industryIndex: number
) => ({
  type: SELECT_INDUSTRY_INDUSTRIES,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const selectIndustryCountries = (optionIndex: number) => ({
  type: SELECT_INDUSTRY_COUNTRY_IDS,
  payload: optionIndex,
});

/***************************************
 *                 FETCH               *
 ***************************************/
export const setSectorGroupBy = (groupBy: GroupBySectorEnum) => ({
  type: SET_SECTOR_GROUP_BY,
  payload: groupBy,
});

export const setTimeWindow = (timeWindow: [number, number]) => ({
  type: SET_TIME_WINDOW,
  payload: timeWindow,
});

export const applyCtySecIndFilters = (
  country: ISecIndCty[],
  sector: ISectorIndustry[],
  industry: string[]
) => ({
  type: APPLY_CTY_SEC_IND_FILTERS,
  payload: {
    country,
    sector,
    industry,
  },
});

const makeFetchSectorChartDataBody = (
  config: IVisualConfig[],
  groupBy: GroupBySectorEnum,
  minYear: number,
  maxYear: number,
  countries: string[],
  sectors: string[],
  industries: string[]
): IFetchSectorChartDataBody => ({
  groupBy,
  minYear,
  maxYear,
  countries,
  sectors,
  industries,
  visuals: config.map(
    (e: IVisualConfig): IFetchChartDataVisual => ({
      item: e.item,
      type: e.type,
    })
  ),
});

export const fetchSectorChartData = (
  config: IVisualConfig[],
  groupBy: GroupBySectorEnum,
  years: [number, number],
  countries: string[],
  sectors: string[],
  industries: string[]
) => {
  return (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(dispatch, FETCH_SECTOR_CHART_DATA_FAIL, async () => {
      // Loading
      dispatch({ type: FETCH_SECTOR_CHART_DATA });

      const chartData: string[] = (
        await fetchSectorChartDataService(
          makeFetchSectorChartDataBody(
            config,
            groupBy,
            years[0],
            years[1],
            countries,
            sectors,
            industries
          )
        )
      ).data.data;

      chartData.forEach((tsv: string, index: number) => {
        const conf = config[index];

        dispatch({
          type: conf.dataAction,
          payload: {
            data: tsv,
            configData: {
              columns: conf.columns,
              datasetLabels: conf.datasetLabels,
              isCurrency: conf?.isCurrency,
              isPercentage: conf?.isPercentage,
            },
          },
        });
      });
      dispatch({ type: FETCH_SECTOR_CHART_DATA_SUCCESS });
    });
  };
};
