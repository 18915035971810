import { IDescription } from '@prometeus/common';
import { Map } from 'immutable';
import {
  DispatchActionTypes,
  GET_DESCRIPTION,
  GET_DESCRIPTION_FAIL,
  GET_DESCRIPTION_SUCCESS,
  IDescriptionsState,
} from '../../models/descriptions.model';

const initialState: IDescriptionsState = {
  descriptionMap: Map<string, IDescription>(),
  loading: false,
};

const descriptionsReducer = (
  state: IDescriptionsState = initialState,
  action: DispatchActionTypes
): IDescriptionsState => {
  switch (action.type) {
    case GET_DESCRIPTION:
      return { ...state, loading: true, error: undefined };
    case GET_DESCRIPTION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        descriptionMap: state.descriptionMap.set(
          action.payload.key,
          action.payload.description
        ),
      };

    default:
      return state;
  }
};

export default descriptionsReducer;
