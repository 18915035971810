import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISecIndCty } from '../../../models/screener/structure.model';
import {
  applyCtySecIndFilters,
  selectCountryCountries,
  selectCountryIndustries,
  selectCountrySectors,
} from '../../../pages/sector-analysis/utils/visuals.actions';
import { A_PAGE_LIMIT } from '../../../pages/sector-analysis/utils/visuals.constants';
import { RootState } from '../../../store/reducers/root.reducer';
import { AFiltersStyles } from '../aggregated-filters/a-filters.component';
import CountrySelectorComponent from '../filter-components/country-selector.component';
import SectorIndustrySelectorComponent from '../filter-components/sector-industry-selector.component';

const CountryTabFiltersComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const countryCountries = useSelector(
    (state: RootState) => state.visuals.sector.countryCountries
  );
  const countrySectors = useSelector(
    (state: RootState) => state.visuals.sector.countrySectors
  );
  const countrySelectedIndustries = useSelector(
    (state: RootState) => state.visuals.sector.countrySelectedIndustries
  );

  const countryCountryIdsClickHandler = useCallback(
    (optionIndex: number) => {
      dispatch(selectCountryCountries(optionIndex));
    },
    [dispatch]
  );

  const onCloseHandlerCallback = useCallback(() => {
    dispatch(
      applyCtySecIndFilters(
        countryCountries,
        countrySectors,
        countrySelectedIndustries
      )
    );
  }, [dispatch, countryCountries, countrySectors, countrySelectedIndustries]);

  const countryDataList = countryCountries.slice(1);

  return (
    <>
      <CountrySelectorComponent
        label="Countries"
        placeholder={`Select up to ${A_PAGE_LIMIT} countries`}
        dataList={countryDataList}
        limit={A_PAGE_LIMIT}
        onClickCheckHandler={countryCountryIdsClickHandler}
        onCloseHandler={onCloseHandlerCallback}
        textFieldSx={
          !countryDataList
            .slice(!!A_PAGE_LIMIT ? 0 : 1)
            .filter((e: ISecIndCty) => e.shown && e.selected).length
            ? {
                '& fieldset': {
                  border: `solid 0.05rem ${theme.palette.divider} !important`,
                  borderRadius: '2rem',
                  ...AFiltersStyles.missingHighlightBorder,
                },
              }
            : {}
        }
      />
      <SectorIndustrySelectorComponent
        label="Sectors & Industries"
        placeholder={'Select sectors & industries'}
        dataList={countrySectors}
        sectorAction={selectCountrySectors}
        industryAction={selectCountryIndustries}
        selectedIndustries={countrySelectedIndustries?.length || 0}
        onCloseHandler={onCloseHandlerCallback}
      />
    </>
  );
};

export default CountryTabFiltersComponent;
