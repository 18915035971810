import { ITableColumnData, ScreenerItemEnum } from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { MD5 } from 'object-hash';
import { Dispatch } from 'redux';
import {
  IScreenerResultUnmapped,
  mapScreenerResults,
  SET_CHECKSUM,
  SET_SCREENER_RESULTS_PAGE,
  SET_SCREENER_RESULTS_PER_PAGE,
  SET_SCREENER_SORT_BY,
  SET_SCREENER_SORT_ORDER,
  SET_SCREENER_TABLE_COLUMNS,
  SHOW_SCREENER_RESULTS,
  SHOW_SCREENER_RESULTS_FAIL,
  SHOW_SCREENER_RESULTS_SUCCESS,
  SortOrderType,
} from '../../../models/screener/results.model';
import { showScreenerResultsService } from '../../../services/screener.service';
import { actionTryCatchWrapper } from '../../../utils/action-try-catch-wrapper';
import { getIcons } from '../icons.action';

export const showScreenerResults =
  (
    tickers: string[],
    page: number,
    resultsPerPage: number,
    items: string[],
    sortBy: string,
    sortOrder: SortOrderType,
    checksum?: string
  ) =>
  (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(dispatch, SHOW_SCREENER_RESULTS_FAIL, async () => {
      const _checksum = MD5({
        tickers,
        page,
        resultsPerPage,
        sortBy,
        sortOrder,
        items,
      });

      if (_checksum !== checksum) {
        dispatch({
          type: SHOW_SCREENER_RESULTS,
        });

        const response: AxiosResponse<{ data: IScreenerResultUnmapped[] }> =
          await showScreenerResultsService(
            tickers,
            page,
            resultsPerPage,
            items,
            sortBy,
            sortOrder
          );

        const data = response?.data.data;

        dispatch(
          getIcons(data.map((e: IScreenerResultUnmapped) => e.CompanyId))
        );

        dispatch({
          type: SHOW_SCREENER_RESULTS_SUCCESS,
          payload: {
            data: data.map(mapScreenerResults),
            checksum: _checksum,
          },
        });
      }
    });
  };

export const setScreenerResultsPage = (page: number) => ({
  type: SET_SCREENER_RESULTS_PAGE,
  payload: page,
});

export const setScreenerResultsPerPage = (resultsPerPage: number) => ({
  type: SET_SCREENER_RESULTS_PER_PAGE,
  payload: resultsPerPage,
});

export const setScreenerSortBy = (sortBy: ScreenerItemEnum) => ({
  type: SET_SCREENER_SORT_BY,
  payload: sortBy,
});

export const setScreenerSortOrder = (sortOrder: SortOrderType) => ({
  type: SET_SCREENER_SORT_ORDER,
  payload: sortOrder,
});

export const setScreenerTableColumns = (tableColumns: ITableColumnData[]) => ({
  type: SET_SCREENER_TABLE_COLUMNS,
  payload: tableColumns,
});

export const setScreenerChecksum = (checksum: number) => ({
  type: SET_CHECKSUM,
  payload: checksum,
});
