import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';

type Props = {
  fill?: number;
};

const ScrollFillerComponent = (props: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  return (
    <div
      style={
        isPortraitMobile
          ? { height: 0 }
          : {
              height: !!props.fill ? `${props.fill}rem` : '3rem',
            }
      }
    ></div>
  );
};

export default ScrollFillerComponent;
