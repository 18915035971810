import { ChartTypeEnum, IVisualConfig } from '../../../models/visuals.model';
import {
  BOTTOM_COUNTRY_DATA,
  BOTTOM_DISTRIBUTION_COUNTRY_DATA,
  BOTTOM_DISTRIBUTION_INDUSTRY_DATA,
  BOTTOM_DISTRIBUTION_SECTOR_DATA,
  BOTTOM_INDUSTRY_DATA,
  BOTTOM_SECTOR_DATA,
  ILabelValue,
  TOP_COUNTRY_DATA,
  TOP_DISTRIBUTION_COUNTRY_DATA,
  TOP_DISTRIBUTION_INDUSTRY_DATA,
  TOP_DISTRIBUTION_SECTOR_DATA,
  TOP_INDUSTRY_DATA,
  TOP_SECTOR_DATA,
} from './visuals.model';

export const COUNTRY_ANALYSIS_TOP_CONFIG: IVisualConfig[] = [
  {
    id: 'topCountry',
    dataAction: TOP_COUNTRY_DATA,
    stateLabel: 'topCountryData',
    columns: ['Fiscal Year', 'CountryId', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'topDistributionCountry',
    dataAction: TOP_DISTRIBUTION_COUNTRY_DATA,
    stateLabel: 'topDistributionCountryData',
    columns: ['Bin', 'CountryId', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const COUNTRY_ANALYSIS_BOTTOM_CONFIG: IVisualConfig[] = [
  {
    id: 'bottomCountry',
    dataAction: BOTTOM_COUNTRY_DATA,
    stateLabel: 'bottomCountryData',
    columns: ['Fiscal Year', 'CountryId', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'bottomDistributionCountry',
    dataAction: BOTTOM_DISTRIBUTION_COUNTRY_DATA,
    stateLabel: 'bottomDistributionCountryData',
    columns: ['Bin', 'CountryId', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const COUNTRY_ANALYSIS_CONFIG: IVisualConfig[] = [
  ...COUNTRY_ANALYSIS_TOP_CONFIG,
  ...COUNTRY_ANALYSIS_TOP_CONFIG,
];

export const SECTOR_ANALYSIS_TOP_CONFIG: IVisualConfig[] = [
  {
    id: 'topSector',
    dataAction: TOP_SECTOR_DATA,
    stateLabel: 'topSectorData',
    columns: ['Fiscal Year', 'Sector', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'topDistributionSector',
    dataAction: TOP_DISTRIBUTION_SECTOR_DATA,
    stateLabel: 'topDistributionSectorData',
    columns: ['Bin', 'Sector', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const SECTOR_ANALYSIS_BOTTOM_CONFIG: IVisualConfig[] = [
  {
    id: 'bottomSector',
    dataAction: BOTTOM_SECTOR_DATA,
    stateLabel: 'bottomSectorData',
    columns: ['Fiscal Year', 'Sector', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'bottomDistributionSector',
    dataAction: BOTTOM_DISTRIBUTION_SECTOR_DATA,
    stateLabel: 'bottomDistributionSectorData',
    columns: ['Bin', 'Sector', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const SECTOR_ANALYSIS_CONFIG: IVisualConfig[] = [
  ...SECTOR_ANALYSIS_TOP_CONFIG,
  ...SECTOR_ANALYSIS_BOTTOM_CONFIG,
];

export const INDUSTRY_ANALYSIS_TOP_CONFIG: IVisualConfig[] = [
  {
    id: 'topIndustry',
    dataAction: TOP_INDUSTRY_DATA,
    stateLabel: 'topIndustryData',
    columns: ['Fiscal Year', 'Industry', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'topDistributionIndustry',
    dataAction: TOP_DISTRIBUTION_INDUSTRY_DATA,
    stateLabel: 'topDistributionIndustryData',
    columns: ['Bin', 'Industry', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const INDUSTRY_ANALYSIS_BOTTOM_CONFIG: IVisualConfig[] = [
  {
    id: 'bottomIndustry',
    dataAction: BOTTOM_INDUSTRY_DATA,
    stateLabel: 'bottomIndustryData',
    columns: ['Fiscal Year', 'Industry', 'Value'],
    item: '',
    type: ChartTypeEnum.TIME_CHART,
    datasetLabels: [],
  },
  {
    id: 'bottomDistributionIndustry',
    dataAction: BOTTOM_DISTRIBUTION_INDUSTRY_DATA,
    stateLabel: 'bottomDistributionIndustryData',
    columns: ['Bin', 'Industry', 'Distribution'],
    item: '',
    type: ChartTypeEnum.DISTR_CHART,
    datasetLabels: [],
    isPercentage: true,
  },
];

export const INDUSTRY_ANALYSIS_CONFIG: IVisualConfig[] = [
  ...INDUSTRY_ANALYSIS_TOP_CONFIG,
  ...INDUSTRY_ANALYSIS_BOTTOM_CONFIG,
];

export const CHART_OPTIONS: ILabelValue[] = [
  { label: 'CapEx To Net Income', value: 'CapEx To Net Income' },
  { label: 'Debt Payback Period', value: 'Debt Payback Period' },
  { label: 'D/E adj', value: 'D/E adj' },
  { label: 'Op. Inc. %', value: 'Op. Inc. %' },
  { label: 'R&D %', value: 'R&D %' },
  { label: 'SG&A %', value: 'SG&A %' },
  { label: 'Amort. & Depr. %', value: 'Amort. & Depr. %' },
  { label: 'Profit Margin', value: 'Profit Margin' },
  { label: 'ROE adj', value: 'ROE adj' },
  { label: 'ROA adj', value: 'ROA adj' },
  { label: 'Quick Ratio', value: 'Quick Ratio' },
  { label: 'Current Ratio', value: 'Current Ratio' },
  { label: 'Gross Margin', value: 'Gross Margin' },
  { label: 'ROS', value: 'ROS' },
  { label: 'Interest %', value: 'Interest %' },
  { label: 'ROIC', value: 'ROIC' },
  { label: 'Payout Ratio', value: 'Payout Ratio' },
  { label: 'Dividend Yield', value: 'Dividend Yield' },
  { label: 'P/E', value: 'P/E' },
];

export const CHART_OPTIONS_IS_PERCENT = [
  'Profit Margin',
  'Gross Margin',
  'Op. Inc. %',
  'R&D %',
  'SG&A %',
  'Amort. & Depr. %',
  'Interest %',
  'ROS',
  'D/E adj',
  'ROA adj',
  'ROE adj',
  'ROIC',
  'CapEx To Net Income',
  'Payout Ratio',
  'Dividend Yield',
];

export const FREE_LICENCE_SECTOR_ANALYSIS_METRICS = [
  'Current Ratio',
  'Profit Margin',
  'ROA adj',
];
