import { COMPANY_LABEL_PLACEHOLDER } from '../../../constants/charts/chart.constants';
import { EMPTY_DATASET_LABELS } from '../../../constants/charts/utils.constants';
import { ChartTypeEnum, IVisualConfig } from '../../../models/visuals.model';
import {
  DIVIDEND_HISTORY_DATA,
  DIVIDEND_TTM_DATA,
  DIVIDEND_YIELD_DATA,
  DIVIDEND_YIELD_DISTRIBUTION_DATA,
  EPS_DATA,
  MARKET_CAP_DATA,
  MARKET_CAP_RANK_DATA,
  PAYOUT_RATIO_DATA,
  PE_RATIO_DATA,
  PRICE_DATA,
  SHARES_DATA,
} from './visuals.model';

/***************************************
 *        COMPANY_STOCKS_CONFIG       *
 ***************************************/
export const COMPANY_STOCK_DWMQ_CONFIG: IVisualConfig[] = [
  /* Price */
  {
    id: 'price',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Close adj',
    dataAction: PRICE_DATA,
    stateLabel: 'priceData',
    isCurrency: true,
    columns: ['Date', 'Close adj'],
    datasetLabels: [{ label: 'Price' }],
  },
  /* PE Ratio */
  {
    id: 'peRatio',
    type: ChartTypeEnum.TIME_CHART,
    item: 'P/E',
    dataAction: PE_RATIO_DATA,
    stateLabel: 'peRatioData',
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'marketCap',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Market Cap',
    dataAction: MARKET_CAP_DATA,
    stateLabel: 'marketCapData',
    isCurrency: true,
    columns: ['Date', 'Market Cap'],
    datasetLabels: [{ label: 'Market Cap' }],
  },
];

export const COMPANY_STOCKS_CONFIG: IVisualConfig[] = [
  {
    id: 'eps',
    type: ChartTypeEnum.BASIC_CHART,
    item: ['EPS', 'EPS TTM'],
    dataAction: EPS_DATA,
    stateLabel: 'epsData',
    isCurrency: true,
    columns: ['Date', 'EPS TTM', 'EPS'],
    datasetLabels: [{ label: 'EPS TTM' }, { label: 'EPS' }],
  },
  {
    id: 'shares',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Shares',
    dataAction: SHARES_DATA,
    stateLabel: 'sharesData',
    columns: ['Date', 'Shares'],
    datasetLabels: [{ label: 'Shares', fill: true }],
  },
  {
    id: 'marketCapRank',
    type: ChartTypeEnum.RANK_CHART,
    item: 'Market Cap',
    dataAction: MARKET_CAP_RANK_DATA,
    stateLabel: 'marketCapRankData',
    isCurrency: true,
    columns: ['TickerId', 'Value', 'Rank', 'selectedTicker', 'reduced'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

/***************************************
 *       COMPANY_DIVIDENDS_CONFIG      *
 ***************************************/
export const COMPANY_DIVIDENDS_DWMQ_CONFIG: IVisualConfig[] = [
  {
    id: 'dividendYield',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Dividend Yield',
    dataAction: DIVIDEND_YIELD_DATA,
    stateLabel: 'dividendYieldData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const COMPANY_DIVIDENDS_CONFIG: IVisualConfig[] = [
  {
    id: 'payoutRatio',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Payout Ratio',
    dataAction: PAYOUT_RATIO_DATA,
    stateLabel: 'payoutRatioData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Payout Ratio'],
    datasetLabels: [{ fill: true }],
  },
  {
    id: 'dividendHistory',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Dividend',
    dataAction: DIVIDEND_HISTORY_DATA,
    stateLabel: 'dividendHistoryData',
    isCurrency: true,
    columns: ['Date', 'Dividend'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'dividendTTM',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Dividend TTM',
    dataAction: DIVIDEND_TTM_DATA,
    stateLabel: 'dividendTTMData',
    isCurrency: true,
    columns: ['Date', 'Dividend TTM'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'dividendYieldDistr',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Dividend Yield',
    dataAction: DIVIDEND_YIELD_DISTRIBUTION_DATA,
    stateLabel: 'dividendYieldDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
];

/***************************************
 *             Title Labels            *
 ***************************************/
export const COMPANY_STOCKS_TITLE_LABELS = {
  price: 'Price',
  peRatio: 'P/E Ratio',
  marketCap: 'Market Cap',
  eps: 'EPS (Earning Per Share) TTM',
  shares: 'Shares',
  marketCapRank: 'Market Cap Rank',
  marketCapRankFull: 'Market Cap Rank',
};

export const COMPANY_DIVIDENDS_TITLE_LABELS = {
  dividendYield: 'Dividend Yield',
  dividendYieldDistr: 'Dividend Yield Distribution',
  payoutRatio: 'Payout Ratio',
  dividendHistory: 'Dividend History',
  dividendTTM: 'Dividend TTM History',
};
