import {
  ADMIN_ICON_URL,
  FETCH_REMOTE_ICON_URL,
  GET_ICONS_URL,
  GET_TOTAL_ICONS_URL,
  ICON_DUMP_URL,
  ICON_LOAD_DUMP_URL,
  IIconImage,
  IconShapeEnum,
  SEARCH_ICONS_URL
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { ISearchIconsParams } from '../admin/components/search-bar.component';
import { IIconCompany } from '../models/admin/icons.model';
import { DumpType } from '../models/general.model';
import { IIconObj } from '../models/icons.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const getIconsService = (
  tickers: string[]
): Promise<AxiosResponse<IIconObj[]>> =>
  axiosInstance.post<IIconObj[]>(GET_ICONS_URL, {
    tickers,
  });

/***************************************
 *                 Admin               *
 ***************************************/

export const iconDumpDBService = (
  mandatoryFields: string[]
): Promise<AxiosResponse<DumpType>> =>
  axiosInstance.post(ICON_DUMP_URL, { mandatoryFields });

export const iconLoadDumpDBService = (
  dump: any[],
  deleteExisting?: boolean
): Promise<AxiosResponse> =>
  axiosInstance.put(ICON_LOAD_DUMP_URL, { dump, deleteExisting });

export const deleteIconService = (tickerId: string): Promise<AxiosResponse> =>
  axiosInstance.delete(`${ADMIN_ICON_URL}${tickerId}`);

export const upsertIconService = (
  companyId: string,
  image: IIconImage,
  shape: IconShapeEnum
): Promise<AxiosResponse> =>
  axiosInstance.put(`${ADMIN_ICON_URL}${companyId}`, { image, shape });

export const searchIconsService = (
  searchParams: ISearchIconsParams
): Promise<AxiosResponse<IIconCompany[]>> =>
  axiosInstance.post(SEARCH_ICONS_URL, searchParams);

export const fetchRemoteIconService = (url: string) =>
  axiosInstance.post(FETCH_REMOTE_ICON_URL, { url });

export const getTotalIconsService = (): Promise<
  AxiosResponse<{ totalIcons: number }>
> => axiosInstance.get(GET_TOTAL_ICONS_URL);
