import { Stack, SvgIcon, SxProps, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { COMMON_COLORS } from '../ui/colors';

type Props = {
  hideLabel?: boolean;
  logoSx?: SxProps;
  labelSx?: SxProps;
};

const HeaderLogoComponent = ({ hideLabel, logoSx, labelSx }: Props) => {
  return (
    <Link to="/" style={{ all: 'unset', cursor: 'pointer' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: `${COMMON_COLORS.contrastPrimaryText} !important`,
          textDecoration: 'none !important',
        }}
      >
        <SvgIcon
          sx={{
            width: '4.5rem',
            height: '4.5rem',
            marginLeft: '-0.45rem',
            ...logoSx,
          }}
        >
          <Logo />
        </SvgIcon>
        {!hideLabel && (
          <Typography
            variant="h5"
            noWrap
            sx={{
              marginTop: '0.5rem',
              fontSize: '2rem',
              letterSpacing: '0.05rem',
              ...labelSx,
            }}
          >
            PROMETEUS
          </Typography>
        )}
      </Stack>
    </Link>
  );
};

export default HeaderLogoComponent;
