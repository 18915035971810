import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import {
  createChartDatasetConfigAssets,
  createChartDatasetConfigLiabilities,
} from '../../../constants/charts/chart.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { CURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../utils/visuals.config';

const CurrentAssetAndLiabilitiesTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  /***************************************
   *              Charts Data            *
   ***************************************/
  const currentAssetsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.currentAssetsData
  );
  const currentAssetsDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.currentAssetsDataUpdating
  );
  const cashEqShortTermInvestmentsData = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.cashEqShortTermInvestmentsData
  );
  const cashEqShortTermInvestmentsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.cashEqShortTermInvestmentsDataUpdating
  );
  const accountsReceivableData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.accountsReceivableData
  );
  const accountsReceivableDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.accountsReceivableDataUpdating
  );
  const inventoriesData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.inventoriesData
  );
  const inventoriesDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.inventoriesDataUpdating
  );
  const otherShortTermAssetsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.otherShortTermAssetsData
  );
  const otherShortTermAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherShortTermAssetsDataUpdating
  );
  const currentLiabilitiesData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.currentLiabilitiesData
  );
  const currentLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.currentLiabilitiesDataUpdating
  );
  const payablesAccrualsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.payablesAccrualsData
  );
  const payablesAccrualsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.payablesAccrualsDataUpdating
  );
  const shortTermDebtData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.shortTermDebtData
  );
  const shortTermDebtDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.shortTermDebtDataUpdating
  );
  const otherShortTermLiabilitiesData = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherShortTermLiabilitiesData
  );
  const otherShortTermLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherShortTermLiabilitiesDataUpdating
  );

  const [currentAssets, setCurrentAssets] = useState<ChartData>();
  const [cashEqShortTermInvestments, setCashEqShortTermInvestments] =
    useState<ChartData>();
  const [accountsReceivable, setAccountsReceivable] = useState<ChartData>();
  const [inventories, setInventories] = useState<ChartData>();
  const [otherShortTermAssets, setOtherShortTermAssets] = useState<ChartData>();
  const [currentLiabilities, setCurrentLiabilities] = useState<ChartData>();
  const [payablesAccruals, setPayablesAccruals] = useState<ChartData>();
  const [shortTermDebt, setShortTermDebt] = useState<ChartData>();
  const [otherShortTermLiabilities, setOtherShortTermLiabilities] =
    useState<ChartData>();

  /* Current Assets */
  const [currentAssetsHasData, currentAssetsHasLabels, currentAssetsError] =
    extractChartMetadata(currentAssetsData);
  useEffect(() => {
    setCurrentAssets({
      datasets: createChartDatasetConfigAssets(
        currentAssetsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [currentAssetsData, useDollars, fiscalYear, screenWidth]);

  /* Cash Eq Short Term Investments */
  const [
    cashEqShortTermInvestmentsHasData,
    cashEqShortTermInvestmentsHasLabels,
    cashEqShortTermInvestmentsError,
  ] = extractChartMetadata(cashEqShortTermInvestmentsData);
  useEffect(() => {
    setCashEqShortTermInvestments({
      datasets: createChartDatasetConfigAssets(
        cashEqShortTermInvestmentsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [cashEqShortTermInvestmentsData, useDollars, fiscalYear, screenWidth]);

  /* Accounts Receivable */
  const [
    accountsReceivableHasData,
    accountsReceivableHasLabels,
    accountsReceivableError,
  ] = extractChartMetadata(accountsReceivableData);
  useEffect(() => {
    setAccountsReceivable({
      datasets: createChartDatasetConfigAssets(
        accountsReceivableData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [accountsReceivableData, useDollars, fiscalYear, screenWidth]);

  /* Inventories */
  const [inventoriesHasData, inventoriesHasLabels, inventoriesError] =
    extractChartMetadata(inventoriesData);
  useEffect(() => {
    setInventories({
      datasets: createChartDatasetConfigAssets(
        inventoriesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [inventoriesData, useDollars, fiscalYear, screenWidth]);

  /* Other Short Term Assets */
  const [
    otherShortTermAssetsHasData,
    otherShortTermAssetsHasLabels,
    otherShortTermAssetsError,
  ] = extractChartMetadata(otherShortTermAssetsData);
  useEffect(() => {
    setOtherShortTermAssets({
      datasets: createChartDatasetConfigAssets(
        otherShortTermAssetsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [otherShortTermAssetsData, useDollars, fiscalYear, screenWidth]);

  /* Current Liabilities */
  const [
    currentLiabilitiesHasData,
    currentLiabilitiesHasLabels,
    currentLiabilitiesError,
  ] = extractChartMetadata(currentLiabilitiesData);
  useEffect(() => {
    setCurrentLiabilities({
      datasets: createChartDatasetConfigLiabilities(
        currentLiabilitiesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [currentLiabilitiesData, fiscalYear, useDollars, screenWidth]);

  /* Payables Accruals */
  const [
    payablesAccrualsHasData,
    payablesAccrualsHasLabels,
    payablesAccrualsError,
  ] = extractChartMetadata(payablesAccrualsData);
  useEffect(() => {
    setPayablesAccruals({
      datasets: createChartDatasetConfigLiabilities(
        payablesAccrualsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [fiscalYear, payablesAccrualsData, useDollars, screenWidth]);

  /* Short Term Debt */
  const [shortTermDebtHasData, shortTermDebtHasLabels, shortTermDebtError] =
    extractChartMetadata(shortTermDebtData);
  useEffect(() => {
    setShortTermDebt({
      datasets: createChartDatasetConfigLiabilities(
        shortTermDebtData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [fiscalYear, shortTermDebtData, useDollars, screenWidth]);

  /* Other Short Term Liabilities */
  const [
    otherShortTermLiabilitiesHasData,
    otherShortTermLiabilitiesHasLabels,
    otherShortTermLiabilitiesError,
  ] = extractChartMetadata(otherShortTermLiabilitiesData);
  useEffect(() => {
    setOtherShortTermLiabilities({
      datasets: createChartDatasetConfigLiabilities(
        otherShortTermLiabilitiesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [fiscalYear, otherShortTermLiabilitiesData, useDollars, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CURRENT_ASSETS_AND_LIABILITIES));
  }, [dispatch]);

  return (
    <>
      {currentAssetsDataUpdating &&
        currentLiabilitiesDataUpdating &&
        cashEqShortTermInvestmentsDataUpdating &&
        accountsReceivableDataUpdating &&
        payablesAccrualsDataUpdating &&
        shortTermDebtDataUpdating &&
        inventoriesDataUpdating &&
        otherShortTermAssetsDataUpdating &&
        otherShortTermLiabilitiesDataUpdating && (
          <LoadingComponent transparent={true} />
        )}

      <ResponsiveRow sx={{ height: '40%' }}>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={currentAssets}
          error={
            !!currentAssetsError
              ? GENERAL_ERROR_MESSAGE
              : !currentAssetsHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.currentAssets}
          hasData={currentAssetsHasData}
          exportData={currentAssetsData}
          currencySymbol={
            !!currentAssetsData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!currentAssetsData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!currentAssetsDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={currentLiabilities}
          error={
            !!currentLiabilitiesError
              ? GENERAL_ERROR_MESSAGE
              : !currentLiabilitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.currentLiabilities
          }
          hasData={currentLiabilitiesHasData}
          exportData={currentLiabilitiesData}
          currencySymbol={
            !!currentLiabilitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!currentLiabilitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!currentLiabilitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <div style={{ height: '50%' }}>
        <ResponsiveRow>
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={cashEqShortTermInvestments}
            error={
              !!cashEqShortTermInvestmentsError
                ? GENERAL_ERROR_MESSAGE
                : !cashEqShortTermInvestmentsHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.cashEqShortTermInvestments
            }
            hasData={cashEqShortTermInvestmentsHasData}
            exportData={cashEqShortTermInvestmentsData}
            currencySymbol={
              !!cashEqShortTermInvestmentsData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!cashEqShortTermInvestmentsData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!cashEqShortTermInvestmentsDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={accountsReceivable}
            error={
              !!accountsReceivableError
                ? GENERAL_ERROR_MESSAGE
                : !accountsReceivableHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.accountsReceivable
            }
            hasData={accountsReceivableHasData}
            exportData={accountsReceivableData}
            currencySymbol={
              !!accountsReceivableData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!accountsReceivableData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!accountsReceivableDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={payablesAccruals}
            error={
              !!payablesAccrualsError
                ? GENERAL_ERROR_MESSAGE
                : !payablesAccrualsHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.payableAccruals}
            hasData={payablesAccrualsHasData}
            exportData={payablesAccrualsData}
            currencySymbol={
              !!payablesAccrualsData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!payablesAccrualsData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!payablesAccrualsDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={shortTermDebt}
            error={
              !!shortTermDebtError
                ? GENERAL_ERROR_MESSAGE
                : !shortTermDebtHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.shortTermDebt}
            hasData={shortTermDebtHasData}
            exportData={shortTermDebtData}
            currencySymbol={
              !!shortTermDebtData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!shortTermDebtData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!shortTermDebtDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
        </ResponsiveRow>
        <ResponsiveRow noMarginBottom>
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={inventories}
            error={
              !!inventoriesError
                ? GENERAL_ERROR_MESSAGE
                : !inventoriesHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.inventories}
            hasData={inventoriesHasData}
            exportData={inventoriesData}
            currencySymbol={
              !!inventoriesData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!inventoriesData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!inventoriesDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={otherShortTermAssets}
            error={
              !!otherShortTermAssetsError
                ? GENERAL_ERROR_MESSAGE
                : !otherShortTermAssetsHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.otherShortTermAssets
            }
            hasData={otherShortTermAssetsHasData}
            exportData={otherShortTermAssetsData}
            currencySymbol={
              !!otherShortTermAssetsData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!otherShortTermAssetsData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!otherShortTermAssetsDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={otherShortTermLiabilities}
            error={
              !!otherShortTermLiabilitiesError
                ? GENERAL_ERROR_MESSAGE
                : !otherShortTermLiabilitiesHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.otherShortTermLiabilities
            }
            hasData={otherShortTermLiabilitiesHasData}
            exportData={otherShortTermLiabilitiesData}
            currencySymbol={
              !!otherShortTermLiabilitiesData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!otherShortTermLiabilitiesData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!otherShortTermLiabilitiesDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <div style={{ margin: '0 1rem' }}></div>
        </ResponsiveRow>
      </div>
    </>
  );
};

export default CurrentAssetAndLiabilitiesTab;
