import { ILicence, IUserData, MOMENT_FORMAT, MOMENT_FORMAT_TIMESTAMP } from '@prometeus/common';
import moment from 'moment';
import {
  CLEAN_UP_USER_DETAIL,
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DispatchActionTypes,
  FETCH_USER,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  FILTER_USERS,
  IMappedUserData,
  IUsersState,
  RESET_USER_ERROR,
  REVOKE_USER_LICENCE,
  REVOKE_USER_LICENCE_FAIL,
  REVOKE_USER_LICENCE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  USER_DUMP_DB,
  USER_DUMP_DB_FAIL,
  USER_DUMP_DB_SUCCESS,
} from '../../../models/admin/users.model';

const initialState: IUsersState = {
  loading: false,
  users: [],
};

const usersMapper = (users: IUserData[]): IMappedUserData[] =>
  users.map((e: IUserData): IMappedUserData => {
    const licenceExpirationDate = !!e.activeLicence?.expirationDate
      ? e.activeLicence?.expirationDate
      : !!e.licencesHistory?.length
        ? e.licencesHistory?.reduce((prev: ILicence, curr: ILicence) =>
          moment(prev.expirationDate, MOMENT_FORMAT).valueOf() >
            moment(curr.expirationDate, MOMENT_FORMAT).valueOf()
            ? prev
            : curr
        ).expirationDate
        : undefined;

    return {
      ...e,
      name:
        !!e.lastName && !!e.firstName
          ? `${e.lastName} ${e.firstName}`
          : undefined,
      licenceExpirationDate: !!licenceExpirationDate
        ? moment(licenceExpirationDate, MOMENT_FORMAT).valueOf()
        : undefined,
      firstLogin: !!e?.firstLogin ? moment(e.firstLogin, MOMENT_FORMAT_TIMESTAMP).valueOf() : undefined,
      lastLogin: !!e?.lastLogin ? moment(e.lastLogin, MOMENT_FORMAT_TIMESTAMP).valueOf() : undefined,
    };
  });

const usersReducer = (
  state: IUsersState = initialState,
  action: DispatchActionTypes
): IUsersState => {
  /* Reducer logic */
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: usersMapper(action.payload),
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILTER_USERS:
      return {
        ...state,
        filterValue: action.payload,
      };
    case FETCH_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetail: action.payload,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetail: action.payload,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetail: action.payload,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: usersMapper(action.payload),
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAN_UP_USER_DETAIL:
      return {
        ...state,
        userDetail: undefined,
      };
    case RESET_USER_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case USER_DUMP_DB:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case USER_DUMP_DB_SUCCESS:
      return {
        ...state,
        loading: false,
        userDump: action.payload,
      };
    case USER_DUMP_DB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REVOKE_USER_LICENCE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case REVOKE_USER_LICENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetail: action.payload,
      };
    case REVOKE_USER_LICENCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
