import { IPlan } from '@prometeus/common';
import {
    DELETE_PLAN,
    DELETE_PLAN_FAIL,
    DispatchActionTypes,
    GET_PLANS,
    GET_PLANS_FAIL,
    GET_PLANS_SUCCESS,
    GET_PLAN_DETAILS,
    GET_PLAN_DETAILS_FAIL,
    GET_PLAN_DETAILS_SUCCESS,
    IPlansState,
    PLAN_DUMP_DB,
    PLAN_DUMP_DB_FAIL,
    PLAN_DUMP_DB_SUCCESS,
    UPSERT_PLAN,
    UPSERT_PLAN_FAIL,
} from '../../../models/admin/plans.model';

const initialState: IPlansState = {
    plans: [],
    loading: false,
};

const plansReducer = (
    state: IPlansState = initialState,
    action: DispatchActionTypes
): IPlansState => {
    switch (action.type) {
        case PLAN_DUMP_DB:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case PLAN_DUMP_DB_SUCCESS:
            return {
                ...state,
                loading: false,
                plansDump: action.payload,
            };
        case PLAN_DUMP_DB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        //
        case GET_PLANS:
            return {
                ...state,
                loading: true,
            };
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
                loading: false,
            };

        case GET_PLANS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        //
        case GET_PLAN_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case GET_PLAN_DETAILS_SUCCESS:
            const hasAlreadyPlan = state.plans.some(
                (e: IPlan) => e.planId === action.payload.planId
            );
            return {
                ...state,
                plans: hasAlreadyPlan ? state.plans : [...state.plans, action.payload],
                loading: false,
            };

        case GET_PLAN_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        //
        case UPSERT_PLAN:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case UPSERT_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //
        case DELETE_PLAN:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case DELETE_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default: {
            return state;
        }
    }
};

export default plansReducer;
