import React, { useEffect, useRef } from 'react';

type Props = {
  children: React.ReactNode;
  setOpen: (value: boolean) => void;
};

export const NO_CLICK_AWAY_KEY = 'NO-CLICK-AWAY';

const ClickAwayComponent = (props: Props) => {
  const useClickAwayListener = (ref: any) => {
    useEffect(() => {
      // Click away listener
      const handleClickOutside = (event: any) => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.id.includes(NO_CLICK_AWAY_KEY)
        ) {
          props.setOpen(false);
        }
      };

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  /* Click Away Listener */
  const clickAwayListenerRef = useRef(null);
  useClickAwayListener(clickAwayListenerRef);

  return (
    <div
      ref={clickAwayListenerRef}
      style={{
        padding: '0.5rem',
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
};

export default ClickAwayComponent;
