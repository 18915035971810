import { IManager } from '@prometeus/common';
import { DumpType } from '../general.model';

// export interface IManager {
//   name: string;
//   icon: string;
//   url?: string;
// }

export interface IManagersState {
  loading: boolean;
  error?: string;
  managersDump?: DumpType;
  searchedManagers: IManager[];
  totalManagers: number;
  totalManagersLoading: boolean;
}

/***************************************
 *                 Dump                *
 ***************************************/
/* MANAGER_DUMP_DB */
export const MANAGER_DUMP_DB = 'MANAGER_DUMP_DB';
export interface IManagerDumpDB {
  type: typeof MANAGER_DUMP_DB;
}

/* MANAGER_DUMP_DB_SUCCESS */
export const MANAGER_DUMP_DB_SUCCESS = 'MANAGER_DUMP_DB_SUCCESS';
export interface IManagerDumpDBSuccess {
  type: typeof MANAGER_DUMP_DB_SUCCESS;
  payload: DumpType;
}

/* MANAGER_DUMP_DB_FAIL */
export const MANAGER_DUMP_DB_FAIL = 'MANAGER_DUMP_DB_FAIL';
export interface IManagerDumpDBFail {
  type: typeof MANAGER_DUMP_DB_FAIL;
  payload: string;
}

export type ManagerDumpDBDispatchTypes =
  | IManagerDumpDB
  | IManagerDumpDBSuccess
  | IManagerDumpDBFail;

/***************************************
 *             Search Managers            *
 ***************************************/
/* SEARCH_MANAGERS */
export const SEARCH_MANAGERS = 'SEARCH_MANAGERS';
export interface ISearchManager {
  type: typeof SEARCH_MANAGERS;
}

/* SEARCH_MANAGERS_SUCCESS */
export const SEARCH_MANAGERS_SUCCESS = 'SEARCH_MANAGERS_SUCCESS';
export interface ISearchManagerSuccess {
  type: typeof SEARCH_MANAGERS_SUCCESS;
  payload: IManager[];
}

/* SEARCH_MANAGERS_FAIL */
export const SEARCH_MANAGERS_FAIL = 'SEARCH_MANAGERS_FAIL';
export interface ISearchManagerFail {
  type: typeof SEARCH_MANAGERS_FAIL;
  payload: string;
}

export type SearchManagerDispatchTypes =
  | ISearchManager
  | ISearchManagerSuccess
  | ISearchManagerFail;

/***************************************
 *             Total Managers          *
 ***************************************/
/* GET_TOTAL_MANAGERS */
export const GET_TOTAL_MANAGERS = 'GET_TOTAL_MANAGERS';
export interface IGetTotalManagers {
  type: typeof GET_TOTAL_MANAGERS;
}

/* GET_TOTAL_MANAGERS_SUCCESS */
export const GET_TOTAL_MANAGERS_SUCCESS = 'GET_TOTAL_MANAGERS_SUCCESS';
export interface IGetTotalManagersSuccess {
  type: typeof GET_TOTAL_MANAGERS_SUCCESS;
  payload: number;
}

/* GET_TOTAL_MANAGERS_FAIL */
export const GET_TOTAL_MANAGERS_FAIL = 'GET_TOTAL_MANAGERS_FAIL';
export interface IGetTotalManagersFail {
  type: typeof GET_TOTAL_MANAGERS_FAIL;
  payload: string;
}

export type GetTotalManagersDispatchTypes =
  | IGetTotalManagers
  | IGetTotalManagersSuccess
  | IGetTotalManagersFail;

/***************************************
 *                Delete               *
 ***************************************/
/* DELETE_MANAGER */
export const DELETE_MANAGER = 'DELETE_MANAGER';
export interface IDeleteManager {
  type: typeof DELETE_MANAGER;
}

/* DELETE_MANAGER_FAIL */
export const DELETE_MANAGER_FAIL = 'DELETE_MANAGER_FAIL';
export interface IDeleteManagerFail {
  type: typeof DELETE_MANAGER_FAIL;
  payload: string;
}

export type DeleteManagerDispatchTypes =
  | IDeleteManager
  | SearchManagerDispatchTypes
  | IDeleteManagerFail;

/***************************************
 *                Upsert               *
 ***************************************/
/* UPSERT_MANAGER */
export const UPSERT_MANAGER = 'UPSERT_MANAGER';
export interface IUpsertManager {
  type: typeof UPSERT_MANAGER;
}

/* UPSERT_MANAGER_FAIL */
export const UPSERT_MANAGER_FAIL = 'UPSERT_MANAGER_FAIL';
export interface IUpsertManagerFail {
  type: typeof UPSERT_MANAGER_FAIL;
  payload: string;
}

export type UpsertManagerDispatchTypes =
  | IUpsertManager
  | SearchManagerDispatchTypes
  | IUpsertManagerFail;

/***************************************
 *         Dispatch Action Types       *
 ***************************************/
export type DispatchActionTypes =
  | ManagerDumpDBDispatchTypes
  | DeleteManagerDispatchTypes
  | UpsertManagerDispatchTypes
  | SearchManagerDispatchTypes
  | GetTotalManagersDispatchTypes;
