import { Close } from '@mui/icons-material';
import { Palette, Stack, Typography, useTheme } from '@mui/material';
import { IUserInformation } from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFirstLogin } from '../store/actions/authentication.action';
import { submitRegistrationInformation } from '../store/actions/user.action';
import { RootState } from '../store/reducers/root.reducer';
import { COMMON_COLORS } from '../ui/colors';
import BrandFlame from './brand-flame.component';
import CustomDialogComponent from './dialog.component';
import TooltipIconButton from './tooltip-icon-button.component';
import UserInformationForm from './user-information-form.compontent';

const getStyles = (palette: Palette) => ({
  container: {
    height: '100%',
  },
});

const RegistrationDialog = () => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);
  const dispatch = useDispatch();

  const isFirstLogin = useSelector(
    (state: RootState) => state.authentication.isFirstLogin
  );

  const submitHandler = (userInformation: IUserInformation) => {
    dispatch(submitRegistrationInformation(userInformation));
    closeHandler();
  };

  const skipHandler = () => {
    closeHandler();
  };

  const closeHandler = () => {
    dispatch(setIsFirstLogin(false));
  };

  return (
    <CustomDialogComponent
      open={!!isFirstLogin}
      onClose={closeHandler}
      maxWidth="md"
      dialogTitle=""
      dialogContentSx={{ padding: '1rem 3rem 1.5rem 3rem' }}
      dialogContent={
        <Stack spacing={4} sx={styles.container}>
          {/* Header */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', marginTop: '1rem' }}
          >
            <Typography variant="h4">Congratulations!</Typography>
            <TooltipIconButton
              icon={<Close />}
              tooltip="Close"
              onClick={closeHandler}
            />
          </Stack>
          <Typography variant="h5">
            You successfully registered!{' '}
            <BrandFlame sx={{ transform: 'scale(1.4)' }} /> One last step: tell
            us something about{' '}
            <b style={{ color: COMMON_COLORS.accent }}>You</b>!
          </Typography>

          <UserInformationForm
            registration
            onSubmit={submitHandler}
            onSkip={skipHandler}
          />
        </Stack>
      }
    />
  );
};

export default RegistrationDialog;
