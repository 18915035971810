import { SvgIcon, SxProps, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { isNullUndefined } from '@prometeus/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FACTORY_ICON_D } from '../../../constants/svg-icons';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import { A_PAGE_LIMIT } from '../../../pages/sector-analysis/utils/visuals.constants';
import { getAutocompleteStyles } from '../../../styles/autocomplete.styles';
import { useSelectStyles } from '../../../styles/select.styles';
import ClickAwayComponent, {
  NO_CLICK_AWAY_KEY,
} from '../../click-away.component';
import CustomBadge from '../../custom-badge.component';
import AccordionComponent from './accordion.component';
import AutocompleteRenderInputComponent from './autocomplete-render-input.component';

const styles = {
  listbox: {
    padding: '0rem !important',
  },
  icon: {
    //  color: colors.icons,
  },
};

type Props = {
  label: string;
  placeholder: string;
  dataList: ISectorIndustry[];
  sectorAction: (optionIndex: number) => { type: string; payload: number };
  industryAction: (
    sectorIndex: number,
    industryIndex: number
  ) => { type: string; payload: { sector: number; industry: number } };
  selectedIndustries: number;

  sx?: SxProps;
  textFieldSx?: SxProps;
  onCloseHandler: () => void;
  limit?: number;
  filterTabSecInd?: 'sector' | 'industry';
};

const SectorIndustrySelectorComponent = (props: Props) => {
  const theme = useTheme();
  const autocompleteStyles = getAutocompleteStyles(theme);
  const selectStyles = useSelectStyles();

  const dispatch = useDispatch();
  const { dataList, limit, filterTabSecInd } = props;

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [expandedElements, setExpandedElements] = useState<boolean[]>(
    Array(dataList.length).fill(false)
  );
  const [upFocusEndAdornment, setUpFocusEndAdornment] =
    useState<boolean>(false);
  /* Keep track if any element has been clicked while the menu is open */
  const [clicked, setClicked] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  /* Limit */
  const [reachedLimit, setReachedLimit] = useState<boolean>(false);
  useEffect(() => {
    if (limit) {
      const selected: number =
        filterTabSecInd === 'industry'
          ? dataList
              .filter((e: ISecIndCty) => e.selected)
              .reduce(
                (prev: number, curr: ISectorIndustry) =>
                  prev +
                  curr.industries.filter((e: ISecIndCty) => e.selected).length,
                0
              )
          : dataList.filter((e: ISecIndCty) => e.selected).length;
      setReachedLimit(selected >= limit);
    }
  }, [dataList, limit, filterTabSecInd]);

  const getOptions = (): ISectorIndustry[] => {
    const filtered: ISectorIndustry[] = dataList.filter(
      (e: ISectorIndustry) => e.shown
    );
    return filtered.length <= 1
      ? []
      : searchInputValue.length > 0
      ? filtered.slice(1)
      : filtered;
  };

  const toggleExpanded = (index: number) => {
    const current = expandedElements[index];
    const tmp = [...expandedElements];
    tmp[index] = !current;
    setExpandedElements(tmp);
  };

  const sectorActionHandler = (sector: ISectorIndustry) => {
    if ((!reachedLimit || sector.selected) && filterTabSecInd !== 'industry') {
      // Works only if limit not reached yet or, in case, if the option has been already selected
      if (!clicked) {
        setClicked(true);
      }
      dispatch(props.sectorAction(sector.index));
    }
  };

  const industryActionHandler = (
    sector: ISectorIndustry,
    industry: ISecIndCty
  ) => {
    if (!reachedLimit || industry.selected) {
      // Works only if limit not reached yet or, in case, if the option has been already selected
      const sectorIndex = sector.index;
      if (sectorIndex > 0) {
        if (!clicked) {
          setClicked(true);
        }
        dispatch(props.industryAction(sectorIndex, industry.index));
      }
    }
  };

  const filterOptions = (
    options: ISectorIndustry[],
    state: FilterOptionsState<ISectorIndustry>
  ): ISectorIndustry[] => {
    return options
      .map((o: ISectorIndustry) => ({
        ...o,
        industries: o.industries.filter((ind: ISecIndCty) =>
          ind.label.toLowerCase().includes(state.inputValue.toLowerCase())
        ),
      }))
      .filter(
        (o: ISectorIndustry) =>
          o.industries.length > 0 ||
          o.label.toLowerCase().includes(state.inputValue.toLowerCase())
      );
  };

  const onOpenHandler = () => {
    setUpFocusEndAdornment(true);
    setClicked(false);
  };

  const { onCloseHandler: propsOnCloseHandler } = props;
  const onCloseHandler = useCallback(() => {
    setUpFocusEndAdornment(false);
    if (clicked) {
      setClicked(false);
      propsOnCloseHandler();
    }
  }, [clicked, propsOnCloseHandler]);

  useEffect(() => {
    if (!open) {
      onCloseHandler();
    }
  }, [onCloseHandler, open]);

  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <CustomBadge
      label={
        props.dataList
          .slice(!!A_PAGE_LIMIT ? 0 : 1)
          .filter((e: ISectorIndustry) => e.shown && e.selected).length
      }
      hiddenLabel="Sectors"
      show={isHover || open}
      BadgeProps={{
        color:
          reachedLimit && filterTabSecInd === 'sector' ? 'error' : 'primary',
      }}
    >
      <CustomBadge
        label={props.selectedIndustries}
        hiddenLabel="Industries"
        BadgeProps={{
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          color:
            reachedLimit && filterTabSecInd === 'industry'
              ? 'error'
              : 'primary',
        }}
        show={isHover || open}
      >
        <ClickAwayComponent setOpen={setOpen}>
          <Autocomplete
            multiple
            disableListWrap
            disableClearable
            forcePopupIcon={true}
            clearOnBlur={false}
            disableCloseOnSelect={true}
            id={`${NO_CLICK_AWAY_KEY}-sector-industry-selector`}
            open={open}
            // End Adornment
            onOpen={onOpenHandler}
            onClose={onCloseHandler}
            // Hover
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            // Styling1x
            componentsProps={{
              paper: {
                sx: {
                  ...autocompleteStyles.paper,
                  ...selectStyles.menuPaper,
                  backgroundColor: theme.palette.background.default,
                  '& li': {
                    padding: '0.3rem 0 !important',
                    '& *': {
                      transition: 'color 0s !important',
                    },
                    '&.MuiFocused, &[aria-selected="true"], &:hover, &.Mui-focused':
                      {
                        backgroundColor: 'transparent !important',
                      },
                    '&:hover .header-label': {
                      color: `${theme.palette.primary.main} !important`,
                    },
                  },
                  '& > ul': {
                    padding: '0.2rem 0 !important',
                  },
                },
              },
            }}
            sx={{
              width: '100%',
              margin: '1rem 0rem',
              noOptions: autocompleteStyles.noOptions,
              ...autocompleteStyles.countrySectorRoot,
              ...props.sx,
            }}
            // Listbox
            ListboxProps={{
              id: `${NO_CLICK_AWAY_KEY}-SEC-IND-ul`,
              style: {
                ...autocompleteStyles.listbox,
                ...styles.listbox,
              },
            }}
            // Search
            filterOptions={filterOptions}
            // Option
            options={getOptions()}
            getOptionLabel={(option: ISectorIndustry) => option.label}
            isOptionEqualToValue={(
              option: ISectorIndustry,
              value: ISectorIndustry
            ) => option.label === value.label}
            renderOption={(props, option: ISectorIndustry, { selected }) => (
              <li
                {...props}
                style={{
                  ...autocompleteStyles.option,
                }}
              >
                <AccordionComponent
                  isAll={isNullUndefined(limit) && option.index === 0}
                  isLast={option.index === getOptions().length - 1}
                  checked={option.selected}
                  indeterminate={
                    option.selected || option.index === 0
                      ? !!option.indeterminate
                      : false
                  }
                  label={option.label}
                  index={option.index}
                  contentList={option.industries}
                  reachedLimit={reachedLimit}
                  // Check handlers
                  sectorAction={() => sectorActionHandler(option)}
                  industryAction={(industry: ISecIndCty) => {
                    industryActionHandler(option, industry);
                  }}
                  // Expanded
                  expanded={
                    option.index === 0 ? false : expandedElements[option.index]
                  }
                  expandedHandler={() => toggleExpanded(option.index)}
                />
              </li>
            )}
            // Input
            inputValue={searchInputValue}
            onChange={(event: React.ChangeEvent<{}>) => event.stopPropagation()}
            onInputChange={(
              event: React.ChangeEvent<{}>,
              value: string,
              reason: string
            ) => {
              event?.stopPropagation();
              if (reason === 'input') {
                // If there is no more a search input, reduce all the accordions
                if (!(value.length > 0)) {
                  setExpandedElements(Array(dataList.length).fill(false));
                } else {
                  setExpandedElements(Array(dataList.length).fill(true));
                }
                setSearchInputValue(value);
              }
            }}
            renderInput={AutocompleteRenderInputComponent({
              label: props.label,
              textFieldStyle: {
                fieldset: {
                  border: `solid 0.05rem ${theme.palette.divider} !important`,
                  borderRadius: '2rem',
                },
                ...props.textFieldSx,
              },
              placeholder: props.placeholder,
              searchInputValue: searchInputValue,
              setSearchInputValue: setSearchInputValue,
              upFocusEndAdornment: upFocusEndAdornment,
              dataListLength: dataList.length,
              setExpandedElements: setExpandedElements,
              icon: (
                <SvgIcon sx={styles.icon}>
                  <path fill="currentColor" d={FACTORY_ICON_D} />
                </SvgIcon>
              ),
              onClick: () => {
                // Open the autocomplete menu
                setOpen(!open);
              },
              hasFiltersType: 'modal',
            })}
          />
        </ClickAwayComponent>
      </CustomBadge>
    </CustomBadge>
  );
};

export default SectorIndustrySelectorComponent;
