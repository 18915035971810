import {
  Checkbox,
  checkboxClasses,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Palette,
  useTheme,
} from '@mui/material';
import { pxToRem } from '../ui/functions';

const getStyles = (palette: Palette) => ({
  listItemRoot: {
    padding: '0.3rem !important',
    flex: '1 1 0',
    marginLeft: '2rem',
    borderRadius: '2rem',

    '&:hover': {
      backgroundColor: 'transparent',
      color: palette.primary.main,
      '& .MuiCheckbox-root': {
        color: `${palette.primary.main} !important`,
      },
    },
  },
  listItemText: {
    marginBottom: pxToRem(4),
    color: palette.text.primary,
    '& span': {
      whiteSpace: 'nowrap',
    },
  },
  checkbox: {
    color: palette.text.primary,
    marginBottom: '0.3rem',
    padding: '0.3rem',
    marginRight: '0.3rem',
  },
  listItemIconRoot: {
    minWidth: pxToRem(30),
    '& .MuiIconButton-root': {
      padding: '0rem !important',
    },
  },
  indeterminate: {
    color: palette.primary.main,
  },
});

type ListItemProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
  indeterminate?: boolean;
  disabled?: boolean;
};
export const CustomListItem = (props: ListItemProps) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <ListItemButton
      key={props.label}
      role="listitem"
      disableRipple
      disabled={!!props.disabled}
      sx={{ ...styles.listItemRoot }}
      onClick={($event) => {
        $event.preventDefault();
        props.onClick();
      }}
    >
      <ListItemIcon sx={{ ...styles.listItemIconRoot }}>
        <Checkbox
          color="primary"
          checked={props.checked}
          tabIndex={-1}
          disableRipple
          sx={{
            ...styles.checkbox,
            [`& .${checkboxClasses.indeterminate}`]: styles.indeterminate,
          }}
          indeterminate={!!props.indeterminate}
        />
      </ListItemIcon>
      <ListItemText primary={props.label} sx={{ ...styles.listItemText }} />
    </ListItemButton>
  );
};
