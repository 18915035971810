import {
  BUTTON_YEAR_LABELS,
  IScreenerBetween,
  IScreenerConfig,
  IScreenerFilter,
  IScreenerFilterFields,
  IScreenerTab,
  MARKET_CAP_LIST,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
} from '@prometeus/common';
import { Dispatch } from 'redux';
import { SCREENER_PRE_FETCHING_THRESHOLD } from '../../../constants/general.constants';
import { PeerGroupEnum } from '../../../models/peer-group.model';
import {
  FETCH_PRELIMINARY_RESULTS,
  IFetchPreliminaryResults,
} from '../../../models/screener/results.model';
import {
  APPLY_FILTERS,
  ISecIndCty,
  ISectorIndustry,
  RESET_SCREENER_FILTER_BLOCK_VALUE,
  RESET_SCREENER_TAB_FILTERS,
  SELECT_COUNTRY_IDS,
  SELECT_INDUSTRIES,
  SELECT_SECTORS,
  SEND_SCREENER_FILTERS,
  SEND_SCREENER_FILTERS_FAIL,
  SEND_SCREENER_FILTERS_SUCCESS,
  SETUP_NOT_ACTIVE_LICENCE_COUNTRIES,
  SET_SCREENER_FILTER_VALUE,
  SET_SCREENER_PEER_GROUP,
  ScreenerTabNameEnum,
  SelectCountryIdsI,
  SelectIndustriesI,
  SelectSectorsI,
  SendScreenerFiltersDispatchType,
} from '../../../models/screener/structure.model';
import { sendScreenerFiltersService } from '../../../services/screener.service';
import { actionTryCatchWrapper } from '../../../utils/action-try-catch-wrapper';

export const setFilterValue = (
  tab: ScreenerTabNameEnum,
  cardBlock: ScreenerFilterCardType,
  filter: ScreenerFilterNameEnum,
  value: any
) => ({
  type: SET_SCREENER_FILTER_VALUE,
  payload: {
    tab,
    cardBlock,
    filter,
    value,
  },
});

export const resetScreenerFilterBlockValue = (
  tab: ScreenerTabNameEnum,
  cardBlock: ScreenerFilterCardType
) => ({
  type: RESET_SCREENER_FILTER_BLOCK_VALUE,
  payload: {
    tab,
    cardBlock,
  },
});

export const resetScreenerTabFilters = (tabIndex: number) => ({
  type: RESET_SCREENER_TAB_FILTERS,
  payload: tabIndex,
});

export const applyFilters = () => ({
  type: APPLY_FILTERS,
});

export const sendScreenerFilters = (
  countries: ISecIndCty[],
  sectors: ISectorIndustry[],
  industries: string[],
  peerGroup: PeerGroupEnum,
  filters: IScreenerConfig
) => {
  return async (
    dispatch: Dispatch<
      SendScreenerFiltersDispatchType | IFetchPreliminaryResults
    >
  ) => {
    actionTryCatchWrapper(dispatch, SEND_SCREENER_FILTERS_FAIL, async () => {
      dispatch({ type: SEND_SCREENER_FILTERS });

      const processPercentValue = (
        value: number | null,
        isPercent: boolean
      ): null | number =>
        value === null ? null : value / (isPercent ? 100 : 1);

      const hasPercent = (filter: IScreenerFilter): boolean =>
        filter.components.some((e) => !!(e as IScreenerBetween)?.isPercent);

      const processedFilters = Object.values(filters)
        .map((tab: IScreenerTab) =>
          Object.values(tab)
            .filter((e: IScreenerFilter) => e.active)
            .map(
              (e: IScreenerFilter): IScreenerFilterFields => ({
                item: e.item,
                thresholdGreater: processPercentValue(
                  e.thresholdGreater,
                  !!hasPercent(e)
                ),
                thresholdLess: processPercentValue(
                  e.thresholdLess,
                  !!hasPercent(e)
                ),
                peerGroupOperator: e.peerGroupOperator,
                minYear: e.minYear,
                maxYear: e.maxYear,
                bins: MARKET_CAP_LIST.filter(
                  (_, index: number) => !!e.bins && !!e.bins.slice(1)[index]
                ).length
                  ? MARKET_CAP_LIST.filter(
                    (_, index: number) => !!e.bins && !!e.bins.slice(1)[index]
                  )
                  : null,
                trend: e.trend,
                years: BUTTON_YEAR_LABELS.filter(
                  (_, index: number) => !!e.years && !!e.years[index]
                ).length
                  ? BUTTON_YEAR_LABELS.filter(
                    (_, index: number) => !!e.years && !!e.years[index]
                  )
                  : null,
              })
            )
        )
        .reduce((accumulator, value) => accumulator.concat(value), []);

      const response = await sendScreenerFiltersService({
        filters: processedFilters,
        countries: countries
          .slice(1)
          .filter((e: ISecIndCty) => e.selected)
          .map((e: ISecIndCty) => e.label.split(' -')[0]),
        sectors: sectors
          .slice(1)
          .filter((e: ISectorIndustry) => e.selected)
          .map((e: ISectorIndustry) => e.label),
        industries,
        peerGroup,
      });

      const selectedTickers = response?.data.data;

      dispatch({
        type: SEND_SCREENER_FILTERS_SUCCESS,
        payload: selectedTickers,
      });

      // If there are not so many tickers, pre-fetch results
      if (
        selectedTickers?.length <= SCREENER_PRE_FETCHING_THRESHOLD &&
        selectedTickers?.length > 0
      ) {
        dispatch({
          type: FETCH_PRELIMINARY_RESULTS,
          payload: selectedTickers,
          asyncDispatch: dispatch,
        });
      }
    });
  };
};

export const selectSectors = (optionIndex: number): SelectSectorsI => ({
  type: SELECT_SECTORS,
  payload: optionIndex,
});

export const selectIndustries = (
  sectorIndex: number,
  industryIndex: number
): SelectIndustriesI => ({
  type: SELECT_INDUSTRIES,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const selectCountries = (optionIndex: number): SelectCountryIdsI => ({
  type: SELECT_COUNTRY_IDS,
  payload: optionIndex,
});

export const setScreenerPeerGroup = (peerGroup: PeerGroupEnum) => ({
  type: SET_SCREENER_PEER_GROUP,
  payload: peerGroup,
});

export const setupNotActiveLicenceCountries = () => ({
  type: SETUP_NOT_ACTIVE_LICENCE_COUNTRIES,
});
