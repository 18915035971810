import { History } from 'history';
import { Dispatch } from 'redux';
import { DEFAULT_COMPANY } from '../constants/general.constants';
import { RouteEnum } from '../navigation/routes.constants';
import { getDummyToken, signOut } from '../store/actions/authentication.action';
import { setTickerId } from '../store/actions/filters.action';
import { resetUserInformation } from '../store/actions/user.action';

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )})`
    : 'rbg(255, 255, 255)';
};

export const signOutBundler = (
  dispatch: Dispatch<any>,
  history?: History<unknown>
) => {
  dispatch(signOut());
  dispatch(setTickerId(DEFAULT_COMPANY.tickerId, true));
  dispatch(resetUserInformation());
  dispatch(getDummyToken());
  if (!!history) {
    history?.push(RouteEnum.COMPANY_SUMMARY);
    window.location.reload();
  }
};

export const getRedirectUri = (): string => {
  const [protocol, rest] = window.location.href.split('//');
  return `${protocol}//${rest.split('/')[0]}`;
};
