import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processVisualData,
  processVisualRankData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  DIVIDEND_HISTORY_DATA,
  DIVIDEND_TTM_DATA,
  DIVIDEND_YIELD_DATA,
  DIVIDEND_YIELD_DISTRIBUTION_DATA,
  DispatchActionTypes,
  EPS_DATA,
  IVisualsState,
  MARKET_CAP_DATA,
  MARKET_CAP_RANK_DATA,
  PAYOUT_RATIO_DATA,
  PE_RATIO_DATA,
  PRICE_DATA,
  SHARES_DATA,
  STOCK_ANALYSIS_UPDATE,
} from './visuals.model';

const initialState: IVisualsState = {};

const CHART_ACTIONS_SET = new Set([STOCK_ANALYSIS_UPDATE]);

const stockAnalysisVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case STOCK_ANALYSIS_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = (
      action as { type: string; payload: IVisualPayload }
    ).payload;
    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      /***************************************
       *         B-1 - Company Summary       *
       ***************************************/
      case PRICE_DATA:
        if (hashedData === state.priceData?.checksum) {
          return { ...state, priceDataUpdating: false };
        }

        return {
          ...state,
          priceData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          priceDataUpdating: false,
        };

      case PE_RATIO_DATA:
        if (hashedData === state.peRatioData?.checksum) {
          return { ...state, peRatioDataUpdating: false };
        }

        /* Update state */
        return {
          ...state,
          peRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          peRatioDataUpdating: false,
        };
      case MARKET_CAP_RANK_DATA:
        if (hashedData === state.marketCapRankData?.checksum) {
          return { ...state, marketCapRankDataUpdating: false };
        }

        const { reduced, full } = processVisualRankData(data, configData);

        /* Update state */
        return {
          ...state,
          marketCapRankData: {
            ...reduced,
            checksum: hashedData,
          },
          marketCapRankFullData: {
            ...full,
            checksum: hashedData,
          },
          marketCapRankDataUpdating: false,
        };
      case MARKET_CAP_DATA:
        if (hashedData === state.marketCapData?.checksum) {
          return { ...state, marketCapDataUpdating: false };
        }

        /* Update state */
        return {
          ...state,
          marketCapData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          marketCapDataUpdating: false,
        };
      case EPS_DATA:
        if (hashedData === state.epsData?.checksum) {
          return { ...state, epsDataUpdating: false };
        }

        /* Update state */
        return {
          ...state,
          epsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          epsDataUpdating: false,
        };
      case SHARES_DATA:
        if (hashedData === state.sharesData?.checksum) {
          return { ...state, sharesDataUpdating: false };
        }

        /* Update state */
        return {
          ...state,
          sharesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          sharesDataUpdating: false,
        };
      /***************************************
       *        B-2 - Company Dividends      *
       ***************************************/
      case DIVIDEND_YIELD_DATA:
        if (hashedData === state.dividendYieldData?.checksum) {
          return { ...state, dividendYieldDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          dividendYieldData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          dividendYieldDataUpdating: false,
        };
      case PAYOUT_RATIO_DATA:
        if (hashedData === state.payoutRatioData?.checksum) {
          return { ...state, payoutRatioDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          payoutRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          payoutRatioDataUpdating: false,
        };
      case DIVIDEND_YIELD_DISTRIBUTION_DATA:
        if (hashedData === state.dividendYieldDistributionData?.checksum) {
          return { ...state, dividendYieldDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          dividendYieldDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          dividendYieldDistributionDataUpdating: false,
        };
      case DIVIDEND_HISTORY_DATA:
        if (hashedData === state.dividendHistoryData?.checksum) {
          return { ...state, dividendHistoryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          dividendHistoryData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          dividendHistoryDataUpdating: false,
        };
      case DIVIDEND_TTM_DATA:
        if (hashedData === state.dividendTTMData?.checksum) {
          return { ...state, dividendTTMDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          dividendTTMData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          dividendTTMDataUpdating: false,
        };
      default:
        return state;
    }
  }
};

export default stockAnalysisVisualsReducer;
