import { Palette } from '@mui/material';
import { ROOT_FONT_SIZE_PIXELS } from '../constants/general.constants';
import { COMMON_COLORS } from './colors';

export const pxToRem = (px: number): string =>
  `${px / ROOT_FONT_SIZE_PIXELS}rem`;

export const perc2HexOpacity = (
  perc: number // perc format ex: '20%'
): string => {
  let hex = Math.round((perc * 255) / 100).toString(16);
  if (hex.length === 1) {
    hex = '0' + hex;
  }
  return hex;
};

export const boxShadow = (palette: Palette, important?: boolean) =>
  palette.mode === 'dark'
    ? `0px ${pxToRem(2)} ${pxToRem(4)} ${pxToRem(-1)} #000000${perc2HexOpacity(
        60
      )},\
        0px ${pxToRem(4)} ${pxToRem(5)} 0px #000000${perc2HexOpacity(40)},\
        0px ${pxToRem(1)} ${pxToRem(10)} 0px #000000${perc2HexOpacity(30)}${
        !!important ? '!important' : ''
      }`
    : `0px ${pxToRem(2)} ${pxToRem(4)} ${pxToRem(-1)} ${
        palette.text.secondary
      }${perc2HexOpacity(20)},\
        0px ${pxToRem(4)} ${pxToRem(5)} 0px ${
        palette.text.secondary
      }${perc2HexOpacity(14)},\
        0px ${pxToRem(1)} ${pxToRem(10)} 0px ${
        palette.text.secondary
      }${perc2HexOpacity(12)}${!!important ? '!important' : ''}`;

export const formBorder = (palette: Palette, important?: boolean) =>
  palette.mode === 'dark'
    ? `0.05rem solid ${palette.divider}${!!important ? '!important' : ''}`
    : 'none';

export const maskBorderShadow = (
  palette: Palette,
  hasMask?: boolean,
  important?: boolean
) => ({
  border: formBorder(palette, important),
  boxShadow: palette.mode === 'light' ? boxShadow(palette, important) : 'none',
  backgroundColor: !!hasMask
    ? palette.mode === 'dark'
      ? COMMON_COLORS.mask
      : 'transparent'
    : palette.background.default,
});

export const generateRandColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getProgressColor = (value: number) => {
  // value from 0 to 1
  const hue = ((1 - value) * 120).toString(10);
  return ['hsl(', hue, ',100%,50%)'].join('');
};
