export enum RouteEnum {
  MARKET_COVERAGE = '/market-coverage',
  COMPANY_SUMMARY = '/company-summary',
  SECTOR_ANALYSIS = '/sector-analysis',
  STOCK_ANALYSIS = '/stock-analysis',
  INCOME_STATEMENT_ANALYSIS = '/income-statement-analysis',
  BALANCE_SHEET_ANALYSIS = '/balance-sheet-analysis',
  CASH_FLOW_ANALYSIS = '/cash-flow-analysis',
  MANAGEMENT_EFFECTIVENESS = '/management-effectiveness',
  FINANCIAL_STRENGTH = '/financial-strength',
  SCREENER = '/screener',
  USER = '/user',
  PAYMENT = '/payment'
}

export enum AdminRouteEnum {
  HOME = '/admin',
  USERS = '/admin/user',
  ICONS = '/admin/icon',
  MANAGERS = '/admin/manager',
  LOGS = '/admin/logs',
  COMPANY = '/admin/company',
  PLANS = '/admin/plans'
}

export const PREMIUM_ROUTES = new Map([
  [
    RouteEnum.BALANCE_SHEET_ANALYSIS,
    ['noncurrent-asset-and-liabilities', 'equity', 'balance-sheet'],
  ],
  [RouteEnum.CASH_FLOW_ANALYSIS, ['cash-flow-statement']],
  [RouteEnum.FINANCIAL_STRENGTH, ['debt-to-equity-and-debt-payback']],
  [
    RouteEnum.INCOME_STATEMENT_ANALYSIS,
    ['operating-expenses', 'income-statement'],
  ],
  [RouteEnum.MANAGEMENT_EFFECTIVENESS, ['roa-and-roic']],
  [RouteEnum.SCREENER, []],
  [RouteEnum.SECTOR_ANALYSIS, ['sector', 'industry']],
  [RouteEnum.STOCK_ANALYSIS, ['company-dividends']],
  [RouteEnum.USER, []],
]);
