export const BROAD_LABELS = [
  'Returns',
  'Profitability',
  'Growth',
  'Dividend',
  'Debt',
];

export interface INarrowLabel {
  group: string;
  label: string;
}

export const NARROW_LABELS: INarrowLabel[] = [
  { group: 'Debt', label: 'D/E adj' },
  { group: 'Debt', label: 'Debt Payback Period' },
  { group: 'Dividend', label: 'Dividend Yield' },
  { group: 'Dividend', label: 'Payout Ratio' },
  { group: 'Growth', label: 'Revenue' },
  { group: 'Growth', label: 'Total Assets' },
  { group: 'Profitability', label: 'Gross Margin' },
  { group: 'Profitability', label: 'Op. Inc. %' },
  { group: 'Profitability', label: 'Profit Margin' },
  { group: 'Returns', label: 'ROA adj' },
  { group: 'Returns', label: 'ROE adj' },
  { group: 'Returns', label: 'ROIC' },
  { group: 'Returns', label: 'ROS' },
];
