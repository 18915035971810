import {
  COMPANIES_URL,
  COMPANY_URL,
  FETCH_PEER_LIST_DATA_URL,
  GET_COMPANIES_COUNT_URL,
  GET_COMPANIES_DUMP_URL,
  GET_COMPANY_COUNTERS_URL,
  GET_MOST_VIEWED_COMPANIES_URL,
  ICompanyFirms,
  ICompanyMinimal,
  ICompanyMinimalIcon,
  LOAD_COMPANY_COUNTERS_URL
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { ICompanyDataCounts } from '../models/admin/companies.model';
import { ITickerIdRevenueCap } from '../models/companies.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const searchCompaniesService = (
  keyword: string,
  countries: string[],
  industries: string[]
): Promise<AxiosResponse<ICompanyMinimalIcon[]>> =>
  axiosInstance.post(COMPANIES_URL, { keyword, countries, industries });

export const fetchCompanyDataService = (
  tickerId: string
): Promise<AxiosResponse<ICompanyFirms>> =>
  axiosInstance.get(`${COMPANY_URL}/${tickerId?.toUpperCase()}`);

export const fetchPeerListDataService = (
  tickerIds: string[]
): Promise<AxiosResponse<ITickerIdRevenueCap[]>> =>
  axiosInstance.post(FETCH_PEER_LIST_DATA_URL, {
    tickerIds: tickerIds?.map((e: string) => e?.toUpperCase()),
  });

export const getMostViewedCompaniesService = (): Promise<
  AxiosResponse<ICompanyMinimal[]>
> => axiosInstance.get(GET_MOST_VIEWED_COMPANIES_URL);

/* Admin */
export const getCompanyDumpService = (): Promise<AxiosResponse> =>
  axiosInstance.get(GET_COMPANIES_DUMP_URL);

export const getCompaniesCountService = (): Promise<
  AxiosResponse<ICompanyDataCounts>
> => axiosInstance.get(GET_COMPANIES_COUNT_URL);

export const getCompanyCountersService = (): Promise<AxiosResponse> =>
  axiosInstance.get(GET_COMPANY_COUNTERS_URL);

export const loadCompanyCountersService = (
  dump: { tickerId: string; counter: number; adminCounter: number }[],
  increase?: boolean
): Promise<AxiosResponse> =>
  axiosInstance.put(LOAD_COMPANY_COUNTERS_URL, { dump, increase });
