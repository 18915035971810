import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { PageIdEnum } from '../../../models/filters.model';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import {
  GroupBySectorEnum,
  IOpenLargeChart,
} from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { setSectorGroupBy } from '../utils/visuals.actions';
import { CHART_OPTIONS } from '../utils/visuals.config';
import {
  createAChartDatasetsConfig,
  extractChartMetadata,
  getEmptyOptionMessage,
  makeDistributionTitle,
} from '../utils/visuals.constants';
import { ILabelValue } from '../utils/visuals.model';

const IndustryAnalysisTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const topIndustryData = useSelector(
    (state: RootState) => state.visuals.sector.topIndustryData
  );
  const topIndustryDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.topIndustryDataUpdating
  );
  const topDistributionIndustryData = useSelector(
    (state: RootState) => state.visuals.sector.topDistributionIndustryData
  );
  const topDistributionIndustryDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.sector.topDistributionIndustryDataUpdating
  );
  const bottomIndustryData = useSelector(
    (state: RootState) => state.visuals.sector.bottomIndustryData
  );
  const bottomIndustryDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.bottomIndustryDataUpdating
  );
  const bottomDistributionIndustryData = useSelector(
    (state: RootState) => state.visuals.sector.bottomDistributionIndustryData
  );
  const bottomDistributionIndustryDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.sector.bottomDistributionIndustryDataUpdating
  );
  const topOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );
  const bottomOption = useSelector(
    (state: RootState) => state.visuals.sector.bottomOption
  );
  const industrySectors = useSelector(
    (state: RootState) => state.visuals.sector.industrySectors
  );
  const timeWindow = useSelector(
    (state: RootState) => state.visuals.sector.timeWindow
  );

  const [top, setTop] = useState<ChartData>();
  const [topDistribution, setTopDistribution] = useState<ChartData>();
  const [bottom, setBottom] = useState<ChartData>();
  const [bottomDistribution, setBottomDistribution] = useState<ChartData>();

  /* Top */
  const [topHasData, topHasLabels, topError] =
    extractChartMetadata(topIndustryData);
  useEffect(() => {
    setTop({
      datasets: createAChartDatasetsConfig(topIndustryData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [topIndustryData, timeWindow, screenWidth]);

  /* Top Distribution */
  const [
    topDistributionHasData,
    topDistributionHasLabels,
    topDistributionError,
  ] = extractChartMetadata(topDistributionIndustryData);
  useEffect(() => {
    setTopDistribution({
      labels: topDistributionIndustryData?.labels,
      datasets: createAChartDatasetsConfig(
        topDistributionIndustryData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [topDistributionIndustryData, screenWidth]);

  /* Bottom */
  const [bottomHasData, bottomHasLabels, bottomError] =
    extractChartMetadata(bottomIndustryData);
  useEffect(() => {
    setBottom({
      datasets: createAChartDatasetsConfig(bottomIndustryData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [bottomIndustryData, timeWindow, screenWidth]);

  /* Bottom Distribution */
  const [
    bottomDistributionHasData,
    bottomDistributionHasLabels,
    bottomDistributionError,
  ] = extractChartMetadata(bottomDistributionIndustryData);
  useEffect(() => {
    setBottomDistribution({
      labels: bottomDistributionIndustryData?.labels,
      datasets: createAChartDatasetsConfig(
        bottomDistributionIndustryData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [bottomDistributionIndustryData, screenWidth]);

  /***************************************
   *             Chart Titles            *
   ***************************************/
  const [topTitle, setTopTitle] = useState<string>('');
  const [bottomTitle, setBottomTitle] = useState<string>('');

  /***************************************
   *         Chart Change Listener       *
   ***************************************/
  useEffect(() => {
    if (!!topOption) {
      setTopTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === topOption)?.label ||
          ''
      );
    }
  }, [dispatch, topOption]);

  useEffect(() => {
    if (!!bottomOption) {
      setBottomTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === bottomOption)
          ?.label || ''
      );
    }
  }, [dispatch, bottomOption]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *             Error Message           *
   ***************************************/
  const hasSecInds = (): boolean =>
    !!industrySectors?.filter(
      (e: ISectorIndustry) =>
        e.selected &&
        e.industries.some((industry: ISecIndCty) => industry.selected)
    )?.length;

  const makeErrorMessage = (
    option: boolean,
    hasLabels: boolean,
    error?: string
  ): string | undefined =>
    !option || !hasSecInds()
      ? getEmptyOptionMessage('industries', hasSecInds(), !!option)
      : !!error
      ? GENERAL_ERROR_MESSAGE
      : !hasLabels
      ? GENERAL_NO_DATA_MESSAGE
      : undefined;

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.INDUSTRY_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.INDUSTRY));
  }, [dispatch]);

  return (
    <>
      {topIndustryDataUpdating &&
        topDistributionIndustryDataUpdating &&
        bottomIndustryDataUpdating &&
        bottomDistributionIndustryDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={top}
          error={makeErrorMessage(!!topOption, topHasLabels, topError)}
          warningIcon={!topOption || !hasSecInds()}
          titleLabel={topTitle}
          hasData={!!topOption && hasSecInds() ? topHasData : true}
          isPercentage={topIndustryData?.isPercentage}
          exportData={topIndustryData}
          showLegend={true}
          updating={!!topIndustryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={topDistribution}
          error={makeErrorMessage(
            !!topOption,
            topDistributionHasLabels,
            topDistributionError
          )}
          warningIcon={!topOption || !hasSecInds()}
          titleLabel={topTitle ? makeDistributionTitle(topTitle) : ''}
          hasData={!!topOption && hasSecInds() ? topDistributionHasData : true}
          exportData={topDistributionIndustryData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          isPercentage={!!topDistributionIndustryData?.isPercentage}
          updating={!!topDistributionIndustryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={bottom}
          error={makeErrorMessage(!!bottomOption, bottomHasLabels, bottomError)}
          warningIcon={!bottomOption || !hasSecInds()}
          titleLabel={bottomTitle}
          hasData={!!bottomOption && hasSecInds() ? bottomHasData : true}
          exportData={bottomIndustryData}
          showLegend={true}
          updating={!!bottomIndustryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={bottomDistribution}
          error={makeErrorMessage(
            !!bottomOption,
            bottomDistributionHasLabels,
            bottomDistributionError
          )}
          warningIcon={!bottomOption || !hasSecInds()}
          titleLabel={bottomTitle ? makeDistributionTitle(bottomTitle) : ''}
          hasData={
            !!bottomOption && hasSecInds() ? bottomDistributionHasData : true
          }
          isPercentage={bottomIndustryData?.isPercentage}
          exportData={bottomDistributionIndustryData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          updating={!!bottomDistributionIndustryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default IndustryAnalysisTab;
