import { useRef } from 'react';

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    if (!!htmlElRef?.current) {
      (htmlElRef.current as any).focus();
    }
  };

  return [htmlElRef, setFocus];
};
