import { Box } from '@mui/material';
import {
  BUTTON_YEAR_LABELS,
  IScreenerBetween,
  IScreenerButtonGroup,
  IScreenerFilter,
  IScreenerSelect,
  IScreenerSlider,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
} from '@prometeus/common';
import clsx from 'clsx';
import CardComponent from '../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../components/filters/screener/between.component';
import ButtonGroupScreenerComponent from '../../../components/filters/screener/button-group.component';
import SelectItemComponent from '../../../components/filters/screener/select-item.component';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { screenerStyles } from './styles';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from './tab-change.handler';

export const COMMON_SCREENER_ROW_HEIGHT = '45%';

interface BlockProps {
  tabName: ScreenerTabNameEnum;
  cardBlock: ScreenerFilterCardType;
  data?: IScreenerFilter;
  setFilterValueHandler: (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ) => void;
}

interface CommonBlockProps extends BlockProps {
  isLarge?: boolean;
}

export const CommonBlockComponent = (props: CommonBlockProps) => {
  const { tabName, cardBlock, data, setFilterValueHandler, isLarge } = props;

  return (
    <CardComponent
      containerHeight={true}
      tabName={tabName}
      cardBlock={cardBlock}
      activeFilters={!!data?.active}
      titleLabel={data?.boxLabel || ''}
    >
      <div
        style={{
          ...screenerStyles.centered,
          ...screenerStyles.alignItemsCenter,
          ...screenerStyles.flexHorizontal,
        }}
      >
        <Box sx={{ flex: '1 1 0', marginRight: '3.5rem' }}>
          <SelectItemComponent
            label={(data?.components[0] as IScreenerSelect).label || ''}
            value={data?.peerGroupOperator}
            list={(data?.components[0] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
                value
              );
            }}
          />
        </Box>
        <Box
          sx={{
            flex: '1 1 0',
            ...screenerStyles.flexVertical,
            ...(!!isLarge ? screenerStyles.firstChildMarginBottom : {}),
          }}
        >
          <BetweenSliderComponent
            containerSx={screenerStyles.betweenContainer}
            title={(data?.components[2] as IScreenerBetween).label}
            step={(data?.components[2] as IScreenerBetween).step}
            min={(data?.components[2] as IScreenerBetween).min || 0}
            minValue={data?.minYear as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(
                setFilterValueHandler,
                cardBlock,
                value,
                true
              );
            }}
            max={(data?.components[2] as IScreenerBetween).max || 0}
            maxValue={data?.maxYear as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(
                setFilterValueHandler,
                cardBlock,
                value,
                true
              );
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(
                setFilterValueHandler,
                cardBlock,
                values,
                true
              );
            }}
          />
          <BetweenSliderComponent
            title={(data?.components[1] as IScreenerSlider).label || ''}
            step={(data?.components[1] as IScreenerBetween).step}
            min={(data?.components[1] as IScreenerBetween).min || 0}
            minValue={data?.thresholdGreater as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            max={(data?.components[1] as IScreenerBetween).max || 0}
            maxValue={data?.thresholdLess as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(setFilterValueHandler, cardBlock, values);
            }}
            containerSx={screenerStyles.verticalSliderBoxContainer}
            isPercent={!!(data?.components[1] as IScreenerSlider).isPercent}
          />
        </Box>
      </div>
    </CardComponent>
  );
};

export const VerticalCommonBlockComponent = (props: BlockProps) => {
  const { tabName, cardBlock, data, setFilterValueHandler } = props;

  return (
    <CardComponent
      containerHeight={true}
      tabName={tabName}
      cardBlock={cardBlock}
      activeFilters={!!data?.active}
      titleLabel={data?.boxLabel || ''}
    >
      <div
        className={clsx(
          screenerStyles.centered,
          screenerStyles.flexVertical,
          screenerStyles.noPaddingTop
        )}
      >
        <SelectItemComponent
          label={(data?.components[0] as IScreenerSelect).label || ''}
          value={data?.peerGroupOperator}
          list={(data?.components[0] as IScreenerSelect).list || []}
          onSelect={(value: string) => {
            setFilterValueHandler(
              cardBlock,
              ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
              value
            );
          }}
          split={true}
          isSmall={true}
        />
        <BetweenSliderComponent
          containerSx={screenerStyles.betweenContainer}
          title={(data?.components[2] as IScreenerBetween).label}
          step={(data?.components[2] as IScreenerBetween).step}
          min={(data?.components[2] as IScreenerBetween).min || 0}
          minValue={data?.minYear as number}
          setMinValue={(value: number) => {
            onMinBetweenValueHandler(
              setFilterValueHandler,
              cardBlock,
              value,
              true
            );
          }}
          max={(data?.components[2] as IScreenerBetween).max || 0}
          maxValue={data?.maxYear as number}
          setMaxValue={(value: number) => {
            onMaxBetweenValueHandler(
              setFilterValueHandler,
              cardBlock,
              value,
              true
            );
          }}
          setValues={(values: BetweenValuesType) => {
            onBetweenValuesHandler(
              setFilterValueHandler,
              cardBlock,
              values,
              true
            );
          }}
        />
        <BetweenSliderComponent
          title={(data?.components[1] as IScreenerSlider).label || ''}
          step={(data?.components[1] as IScreenerBetween).step}
          min={(data?.components[1] as IScreenerBetween).min || 0}
          minValue={data?.thresholdGreater as number}
          setMinValue={(value: number) => {
            onMinBetweenValueHandler(setFilterValueHandler, cardBlock, value);
          }}
          max={(data?.components[1] as IScreenerBetween).max || 0}
          maxValue={data?.thresholdLess as number}
          setMaxValue={(value: number) => {
            onMaxBetweenValueHandler(setFilterValueHandler, cardBlock, value);
          }}
          setValues={(values: BetweenValuesType) => {
            onBetweenValuesHandler(setFilterValueHandler, cardBlock, values);
          }}
          containerSx={screenerStyles.verticalSliderBoxContainer}
          isPercent={!!(data?.components[1] as IScreenerSlider).isPercent}
        />
      </div>
    </CardComponent>
  );
};

export const GrowthBlockComponent = (props: BlockProps) => {
  const { tabName, cardBlock, data, setFilterValueHandler } = props;

  return (
    <CardComponent
      containerHeight={true}
      tabName={tabName}
      cardBlock={cardBlock}
      activeFilters={!!data?.active}
      titleLabel={data?.boxLabel || ''}
    >
      <div
        className={clsx(
          screenerStyles.centered,
          screenerStyles.flexVertical,
          screenerStyles.marginBottom2
        )}
      >
        <Box sx={screenerStyles.flexHorizontal}>
          <SelectItemComponent
            label={(data?.components[0] as IScreenerSelect).label || ''}
            value={data?.peerGroupOperator}
            list={(data?.components[0] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
                value
              );
            }}
            containerSx={screenerStyles.marginBottom}
          />
          <BetweenSliderComponent
            containerSx={screenerStyles.betweenContainer}
            title={(data?.components[1] as IScreenerBetween).label}
            min={(data?.components[1] as IScreenerBetween).min || 0}
            minValue={data?.minYear as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            max={(data?.components[1] as IScreenerBetween).max || 0}
            maxValue={data?.maxYear as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(setFilterValueHandler, cardBlock, values);
            }}
          />
        </Box>
        <Box sx={screenerStyles.flexHorizontal}>
          <SelectItemComponent
            split={true}
            label={(data?.components[2] as IScreenerSelect).label || ''}
            value={data?.trend}
            list={(data?.components[2] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.TREND,
                value
              );
            }}
            containerSx={{
              ...screenerStyles.longSelectItemComponent,
              ...screenerStyles.noMarginRight,
            }}
          />
          <ButtonGroupScreenerComponent
            labels={(data?.components[3] as IScreenerButtonGroup).list || []}
            values={
              (data?.years as boolean[]) ||
              Array(BUTTON_YEAR_LABELS.length).fill(false)
            }
            handleClick={(value: boolean[]) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.YEARS,
                value
              );
            }}
            title={(data?.components[3] as IScreenerButtonGroup).label || ''}
          />
        </Box>
      </div>
    </CardComponent>
  );
};
