import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processVisualData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  CAPEX_DISTRIBUTION,
  CAPEX_QUARTERLY,
  CAPEX_YEARLY,
  CURRENT_RATIO_DISTRIBUTION,
  CURRENT_RATIO_QUARTERLY,
  CURRENT_RATIO_YEARLY,
  DEBT_PAYBACK_DISTRIBUTION,
  DEBT_PAYBACK_QUARTERLY,
  DEBT_PAYBACK_YEARLY,
  DEBT_TO_EQUITY_DISTRIBUTION,
  DEBT_TO_EQUITY_QUARTERLY,
  DEBT_TO_EQUITY_YEARLY,
  DispatchActionTypes,
  FINANCIAL_STRENGTH_UPDATE,
  IVisualsState,
  QUICK_RATIO_DISTRIBUTION,
  QUICK_RATIO_QUARTERLY,
  QUICK_RATIO_YEARLY,
} from './visuals.model';

const CHART_ACTIONS_SET = new Set([FINANCIAL_STRENGTH_UPDATE]);

const initialState: IVisualsState = {};

const financialStrengthVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case FINANCIAL_STRENGTH_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;

    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      /***************************************
       *   G-1 Current & Quick Ratio, Capex  *
       ***************************************/
      case CURRENT_RATIO_YEARLY:
        if (hashedData === state.currentRatioData?.checksum) {
          return { ...state, currentRatioDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          currentRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentRatioDataUpdating: false,
        };

      case CURRENT_RATIO_QUARTERLY:
        if (hashedData === state.currentRatioData?.checksum) {
          return { ...state, currentRatioDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          currentRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentRatioDataUpdating: false,
        };

      case CURRENT_RATIO_DISTRIBUTION:
        if (hashedData === state.currentRatioDistributionData?.checksum) {
          return { ...state, currentRatioDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          currentRatioDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          currentRatioDistributionDataUpdating: false,
        };
      case QUICK_RATIO_YEARLY:
        if (hashedData === state.quickRatioData?.checksum) {
          return { ...state, quickRatioDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          quickRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          quickRatioDataUpdating: false,
        };
      case QUICK_RATIO_QUARTERLY:
        if (hashedData === state.quickRatioData?.checksum) {
          return { ...state, quickRatioDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          quickRatioData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          quickRatioDataUpdating: false,
        };
      case QUICK_RATIO_DISTRIBUTION:
        if (hashedData === state.quickRatioDistributionData?.checksum) {
          return { ...state, quickRatioDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          quickRatioDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          quickRatioDistributionDataUpdating: false,
        };
      case CAPEX_YEARLY:
        if (hashedData === state.capexData?.checksum) {
          return { ...state, capexDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          capexData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          capexDataUpdating: false,
        };
      case CAPEX_QUARTERLY:
        if (hashedData === state.capexData?.checksum) {
          return { ...state, capexDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          capexData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          capexDataUpdating: false,
        };
      case CAPEX_DISTRIBUTION:
        if (hashedData === state.capexDistributionData?.checksum) {
          return { ...state, capexDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          capexDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          capexDistributionDataUpdating: false,
        };

      /***************************************
       *   G-2 Debt to Equity & Debt Payback *
       ***************************************/
      case DEBT_TO_EQUITY_YEARLY:
        if (hashedData === state.deData?.checksum) {
          return { ...state, deDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          deData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          deDataUpdating: false,
        };
      case DEBT_TO_EQUITY_QUARTERLY:
        if (hashedData === state.deData?.checksum) {
          return { ...state, deDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          deData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          deDataUpdating: false,
        };
      case DEBT_TO_EQUITY_DISTRIBUTION:
        if (hashedData === state.deDistributionData?.checksum) {
          return { ...state, deDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          deDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          deDistributionDataUpdating: false,
        };
      case DEBT_PAYBACK_YEARLY:
        if (hashedData === state.debtPaybackData?.checksum) {
          return { ...state, debtPaybackDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          debtPaybackData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          debtPaybackDataUpdating: false,
        };
      case DEBT_PAYBACK_QUARTERLY:
        if (hashedData === state.debtPaybackData?.checksum) {
          return { ...state, debtPaybackDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          debtPaybackData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          debtPaybackDataUpdating: false,
        };
      case DEBT_PAYBACK_DISTRIBUTION:
        if (hashedData === state.debtPaybackDistributionData?.checksum) {
          return { ...state, debtPaybackDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          debtPaybackDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          debtPaybackDistributionDataUpdating: false,
        };
      default:
        return state;
    }
  }
};

export default financialStrengthVisualsReducer;
