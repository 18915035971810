import { PaletteMode, PaletteOptions } from '@mui/material';
import { TOOLTIP_FONT_SIZE } from '../constants/general.constants';
import {
  ColorsType,
  COMMON_COLORS,
  DARK_THEME_COLORS,
  LIGHT_THEME_COLORS,
} from './colors';

const COMMON: PaletteOptions = {
  primary: {
    main: COMMON_COLORS.primary,
    contrastText: 'white',
  },
  secondary: { main: COMMON_COLORS.accent },
  error: { main: COMMON_COLORS.error },
};

const getPaletteOptions = (
  themeColors: ColorsType
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): PaletteOptions => ({
  ...COMMON,
  // palette values for light mode
  divider: themeColors.divider,
  text: {
    primary: themeColors.textPrimary,
    secondary: themeColors.textSecondary,
  },
  background: {
    default: themeColors.bgPrimary,
    // secondary: themeColors.bgSecondary,
    paper: themeColors.bgSecondary,
  },
});

export const getDesignTokens = (mode: PaletteMode) => {
  const palette: any =
    mode === 'light'
      ? getPaletteOptions(LIGHT_THEME_COLORS)
      : getPaletteOptions(DARK_THEME_COLORS);

  return {
    palette: {
      mode,
      ...palette,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            '& *': {
              scrollbarWidth: 'thin !important',
              // 'scrollbar-width': 'thin !important',
              // 'scrollbar-color': `${palette.primary.main} transparent !important`,
              scrollbarColor: `${palette.primary.main} transparent !important`,
            },
            '& *::-webkit-scrollbar': {
              width: '0.4rem',
              height: '0.4rem', // overflow-x
            },
            '& *::-webkit-scrollbar-track': {
              // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              background: 'transparent',
            },
            '& *::-webkit-scrollbar-thumb': {
              background: `${palette.primary.main}`,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: TOOLTIP_FONT_SIZE,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: COMMON_COLORS.backdrop,
          },
        },
      },
    },
  };
};
