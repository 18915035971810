import { COMPANY_LABEL_PLACEHOLDER } from '../../../constants/charts/chart.constants';
import { EMPTY_DATASET_LABELS } from '../../../constants/charts/utils.constants';
import {
  ChartTypeEnum,
  IStatementsConfig,
  IVisualAggregatedConfig,
  IVisualConfig,
} from '../../../models/visuals.model';
import {
  ANNUAL_REVENUE_GROWTH_RATE_DATA,
  AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA,
  GROSS_MARGIN_DISTRIBUTION_DATA,
  GROSS_MARGIN_QUARTERLY_DATA,
  GROSS_MARGIN_YEARLY_DATA,
  IS_STATEMENTS_QUARTERLY_DATA,
  IS_STATEMENTS_YEARLY_DATA,
  OPERATING_INCOME_DISTRIBUTION_DATA,
  OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA,
  OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA,
  OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA,
  OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA,
  OPERATION_EXPENSES_RD_QUARTERLY_DATA,
  OPERATION_EXPENSES_RD_YEARLY_DATA,
  OPERATION_EXPENSES_SGA_QUARTERLY_DATA,
  OPERATION_EXPENSES_SGA_YEARLY_DATA,
  PROFITABILITY_RANK_DATA,
  PROFIT_MARGIN_DISTRIBUTION_DATA,
  PROFIT_MARGIN_QUARTERLY_DATA,
  PROFIT_MARGIN_YEARLY_DATA,
  REVENUE_GROWTH_YOY_QUARTERLY_DATA,
  REVENUE_GROWTH_YOY_YEARLY_DATA,
  REVENUE_RANK_DATA,
  REVENUE_VS_INCOME_QUARTERLY_DATA,
  REVENUE_VS_INCOME_YEARLY_DATA,
} from './visuals.model';

/***************************************
 *          REVENUE_AND_PROFIT         *
 ***************************************/
const REVENUE_AND_PROFIT_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'revenueVsIncomeQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: ['Revenue', 'Ebit', 'Net Income'],
    dataAction: REVENUE_VS_INCOME_QUARTERLY_DATA,
    stateLabel: 'revenueVsIncomeData',
    isCurrency: true,
    columns: [
      'Date',
      'Revenue',
      'Ebit',
      'Net Income',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: [
      { label: 'Revenue' },
      { label: 'Ebit' },
      { label: 'Net Income' },
    ],
  },
  {
    id: 'revenueGrowthYoyQuarterly',
    type: ChartTypeEnum.YOY_CHART,
    item: 'Revenue',
    dataAction: REVENUE_GROWTH_YOY_QUARTERLY_DATA,
    stateLabel: 'revenueGrowthYoyData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const REVENUE_AND_PROFIT_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'revenueVsIncomeYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: ['Revenue', 'Ebit', 'Net Income'],
    dataAction: REVENUE_VS_INCOME_YEARLY_DATA,
    stateLabel: 'revenueVsIncomeData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Revenue',
      'Ebit',
      'Net Income',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: [
      { label: 'Revenue' },
      { label: 'Ebit' },
      { label: 'Net Income' },
    ],
  },
  {
    id: 'revenueGrowthYoyYearly',
    type: ChartTypeEnum.YOY_CHART,
    item: 'Revenue',
    dataAction: REVENUE_GROWTH_YOY_YEARLY_DATA,
    stateLabel: 'revenueGrowthYoyData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const REVENUE_AND_PROFIT_CONFIG: IVisualConfig[] = [
  {
    id: 'revenueRank',
    type: ChartTypeEnum.RANK_CHART,
    item: 'Revenue',
    dataAction: REVENUE_RANK_DATA,
    stateLabel: 'revenueRankData',
    isCurrency: true,
    columns: ['CompanyId', 'Value', 'Rank', 'selectedTicker', 'reduced'],
    datasetLabels: [{ label: 'Revenue' }, { label: 'Peers' }],
  },
  {
    id: 'profitabilityRank',
    type: ChartTypeEnum.RANK_CHART,
    item: 'Profit Margin',
    dataAction: PROFITABILITY_RANK_DATA,
    stateLabel: 'profitabilityRankData',
    isPercentage: true,
    columns: ['CompanyId', 'Value', 'Rank', 'selectedTicker', 'reduced'],
    datasetLabels: [{ label: 'Profit Margin' }, { label: 'Peers' }],
  },
  {
    id: 'annualRevenueGrowthRate',
    type: ChartTypeEnum.RATE_CHART,
    item: 'Revenue Rate',
    dataAction: ANNUAL_REVENUE_GROWTH_RATE_DATA,
    stateLabel: 'annualRevenueGrowthRateData',
    isPercentage: true,
    columns: ['YR', 'Revenue Rate', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const REVENUE_AND_PROFIT_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  quarterly: REVENUE_AND_PROFIT_QUARTERLY_CONFIG,
  yearly: REVENUE_AND_PROFIT_YEARLY_CONFIG,
};

/***************************************
 *    PROFIT_MARGIN_AND_GROSS_MARGIN   *
 ***************************************/
const PROFIT_MARGIN_AND_GROSS_MARGIN_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'profitMarginYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Profit Margin',
    dataAction: PROFIT_MARGIN_YEARLY_DATA,
    stateLabel: 'profitMarginData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'grossMarginYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Gross Margin',
    dataAction: GROSS_MARGIN_YEARLY_DATA,
    stateLabel: 'grossMarginData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const PROFIT_MARGIN_AND_GROSS_MARGIN_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'profitMarginQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Profit Margin',
    dataAction: PROFIT_MARGIN_QUARTERLY_DATA,
    stateLabel: 'profitMarginData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'grossMarginQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Gross Margin',
    dataAction: GROSS_MARGIN_QUARTERLY_DATA,
    stateLabel: 'grossMarginData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const PROFIT_MARGIN_AND_GROSS_MARGIN_CONFIG: IVisualConfig[] = [
  {
    id: 'profitMarginDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Profit Margin',
    dataAction: PROFIT_MARGIN_DISTRIBUTION_DATA,
    stateLabel: 'profitMarginDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution', fill: true },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average`, fill: true },
      { label: 'Peers Average', fill: true },
    ],
  },
  {
    id: 'grossMarginDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Gross Margin',
    dataAction: GROSS_MARGIN_DISTRIBUTION_DATA,
    stateLabel: 'grossMarginDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution', fill: true },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average`, fill: true },
      { label: 'Peers Average', fill: true },
    ],
  },
];

export const PROFIT_MARGIN_AND_GROSS_MARGIN_CONFIG_AGGREGATED: IVisualAggregatedConfig =
  {
    quarterly: PROFIT_MARGIN_AND_GROSS_MARGIN_QUARTERLY_CONFIG,
    yearly: PROFIT_MARGIN_AND_GROSS_MARGIN_YEARLY_CONFIG,
  };

/***************************************
 *          Operating Expenses         *
 ***************************************/
const OPERATING_EXPENSES_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'operatingIncomeOnGrossProfitQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Op. Inc. %',
    dataAction: OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA,
    stateLabel: 'operatingIncomeOnGrossProfitData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesR&DQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'R&D %',
    dataAction: OPERATION_EXPENSES_RD_QUARTERLY_DATA,
    stateLabel: 'opExpensesRDData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesSG&AQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'SG&A %',
    dataAction: OPERATION_EXPENSES_SGA_QUARTERLY_DATA,
    stateLabel: 'opExpensesSGAData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesAmort&DeprQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Amort. & Depr. %',
    dataAction: OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA,
    stateLabel: 'opExpensesAmortDeprData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const OPERATING_EXPENSES_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'operatingIncomeOnGrossProfitYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Op. Inc. %',
    dataAction: OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA,
    stateLabel: 'operatingIncomeOnGrossProfitData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesR&DYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'R&D %',
    dataAction: OPERATION_EXPENSES_RD_YEARLY_DATA,
    stateLabel: 'opExpensesRDData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesSG&AYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'SG&A %',
    dataAction: OPERATION_EXPENSES_SGA_YEARLY_DATA,
    stateLabel: 'opExpensesSGAData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'opExpensesAmort&DeprYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Amort. & Depr. %',
    dataAction: OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA,
    stateLabel: 'opExpensesAmortDeprData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const OPERATING_EXPENSES_CONFIG: IVisualConfig[] = [
  {
    id: 'operatingIncomeDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Op. Inc. %',
    dataAction: OPERATING_INCOME_DISTRIBUTION_DATA,
    stateLabel: 'operatingIncomeDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution', fill: true },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average`, fill: true },
      { label: 'Peers Average', fill: true },
    ],
  },
  {
    id: 'averageOpExpensesDistribution',
    type: ChartTypeEnum.PIE_CHART,
    item: [
      'Selling, General & Administrative',
      'Research & Development',
      'Other Operating Expenses',
    ],
    dataAction: AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA,
    stateLabel: 'averageOpExpensesDistributionData',
    isPercentage: true,
    columns: [
      'Fiscal Year',
      'Selling, General & Administrative',
      'Research & Development',
      'Other Operating Expenses',
    ],
    datasetLabels: [
      { label: 'SG&A' },
      { label: 'R&D' },
      { label: 'Other Expenses' },
    ],
  },
];

export const OPERATING_EXPENSES_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  quarterly: OPERATING_EXPENSES_QUARTERLY_CONFIG,
  yearly: OPERATING_EXPENSES_YEARLY_CONFIG,
};

export const IS_STATEMENTS_CONFIG: IStatementsConfig[] = [
  {
    label: 'Revenue',
    column: 'Revenue',
    hierarchy: 1,
  },
  {
    label: 'Cost of Revenue',
    column: 'Cost of Revenue',
    hierarchy: 2,
  },
  {
    label: 'Gross Profit',
    column: 'Gross Profit',
    hierarchy: 1,
  },
  {
    label: 'Operating Expenses',
    column: 'Operating Expenses',
    hierarchy: 2,
  },
  {
    label: 'Selling, General & Administrative',
    column: 'Selling, General & Administrative',
    hierarchy: 3,
  },
  {
    label: 'Research & Development',
    column: 'Research & Development',
    hierarchy: 3,
  },
  {
    label: 'Other Operating Expenses',
    column: 'Other Operating Expenses',
    hierarchy: 3,
  },
  {
    label: 'Operating Income',
    column: 'Operating Income',
    hierarchy: 1,
  },
  {
    label: 'Non-Operating Income',
    column: 'Non-Operating Income',
    hierarchy: 2,
  },
  {
    label: 'Net Interest Income',
    column: 'Net Interest Income',
    hierarchy: 3,
  },
  {
    label: 'Interest Income',
    column: 'Interest Income',
    hierarchy: 4,
  },
  {
    label: 'Interest Expense',
    column: 'Interest Expense',
    hierarchy: 4,
  },
  {
    label: 'Net Other Non-Operating Income',
    column: 'Net Other Non-Operating Income',
    hierarchy: 3,
  },
  {
    label: 'Non-Recurring Income',
    column: 'Non-Recurring Income',
    hierarchy: 3,
  },
  {
    label: 'Income Before Tax',
    column: 'Income Before Tax',
    hierarchy: 2,
  },
  {
    label: 'Tax Expense',
    column: 'Tax Expense',
    hierarchy: 2,
  },
  {
    label: 'Net Income from Continuing Operations',
    column: 'Net Income from Continuing Operations',
    hierarchy: 2,
  },
  {
    label: 'Minority Interest',
    column: 'Minority Interest',
    hierarchy: 2,
  },
  {
    label: 'Net Income',
    column: 'Net Income',
    hierarchy: 1,
  },
  {
    label: 'Ebit',
    column: 'Ebit',
    hierarchy: 5,
  },
  {
    label: 'Ebitda',
    column: 'Ebitda',
    hierarchy: 5,
  },
  {
    label: 'Reconciled Depreciation',
    column: 'Reconciled Depreciation',
    hierarchy: 5,
  },
];

const is_statements_items = IS_STATEMENTS_CONFIG.map(
  (e: IStatementsConfig) => e.column
);

export const IS_STATEMENTS_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: [
    {
      id: 'isStatements',
      type: ChartTypeEnum.BASIC_CHART,
      item: is_statements_items,
      dataAction: IS_STATEMENTS_YEARLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
  quarterly: [
    {
      id: 'isStatementsQuarterly',
      type: ChartTypeEnum.BASIC_CHART,
      item: is_statements_items,
      dataAction: IS_STATEMENTS_QUARTERLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
};

/***************************************
 *             Title Labels            *
 ***************************************/
export const REVENUE_AND_PROFIT_TITLE_LABELS = {
  revenueVsIncome: 'Revenue VS Income',
  revenueGrowthYoy: 'Revenue Growth YoY',
  revenueRank: 'Revenue Rank',
  revenueRankFull: 'Revenue Rank',
  profitabilityRank: 'Profitability Rank',
  profitabilityRankFull: 'Profitability Rank',
  annualRevenueGrowthRate: 'Annual Revenue Growth Rate',
};

export const PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS = {
  profitMargin: 'Profit Margin',
  grossMargin: 'Gross Margin',
  profitMarginDistribution: 'Profit Margin Distribution',
  grossMarginDistribution: 'Gross Margin Distribution',
};

export const OPERATING_EXPENSES_TITLE_LABELS = {
  operatingIncomeOnGrossProfit: 'Operating Income On Gross Profit',
  operationExpensesRD: 'Op Expenses R&D',
  operationExpensesSGA: 'Op Expenses SG&A',
  operationExpensesAmortDepr: 'Op Expenses Amort & Depr',
  operatingIncomeDistribution: 'Operating Income Distribution',
  averageOperationExpensesDistribution: 'Last Year Op Expenses Distribution',
};
