import {
  Box,
  FormControl,
  InputLabel,
  menuClasses,
  MenuItem,
  menuItemClasses,
  MenuProps,
  Select,
  selectClasses,
  SxProps,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { PREMIUM_FEATURE_TOOLTIP } from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { openActiveFreeTrialDialog } from '../store/actions/modals.action';
import { useSelectStyles } from '../styles/select.styles';
import { COMMON_COLORS } from '../ui/colors';
import { maskBorderShadow } from '../ui/functions';
import BrandFlame from './brand-flame.component';
import EnhancedTooltip from './enhanced-tooltip.component';

interface ILabelValue {
  label: string;
  value: string;
}

type Props = {
  options: (ILabelValue | string)[];
  option: string | ILabelValue | undefined;
  setOption: (option: string) => void;
  boxLabel: string;
  hasNoneOption?: boolean;
  title?: string;
  boxSx?: SxProps;
  selectSx?: SxProps;
  formControlSx?: SxProps;
  inputLabelSx?: SxProps;
  typographyVariant?: TypographyVariant;
  isLabelValue?: boolean;
  isDisabled?: (value: string) => boolean;
  disabled?: boolean;
  MenuProps?: Partial<MenuProps>;
};

const useStyles = () => {
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const palette = theme.palette;

  return {
    formControl: {
      minWidth: '15rem',
      width: '100%',
      color: palette.text.primary,
      ...maskBorderShadow(palette),
      border: `solid 0.1rem ${COMMON_COLORS.chartGrid} !important`,
      borderRadius: '2rem',
    },
    labelRoot: {
      color: palette.text.primary,
      padding: '0rem 0.5rem',
      fontSize: `${isPortraitMobile ? 2 : 1.2}rem !important`,
      fontWeight: '700 !important',
      transform: `translate(1rem, -${
        isPortraitMobile ? 0.95 : 0.65
      }rem) scale(0.75) !important`,
    },
    container: {
      margin: '1rem',
    },
    typographyAlign: {
      textAlign: 'left' as 'left',
    },
  };
};

const CustomSelectComponent = (props: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const selectStyles = useSelectStyles();

  const handleChange = (event: any) => {
    props.setOption(event.target.value as string);
  };

  return (
    <Box
      sx={{
        ...props.boxSx,
      }}
    >
      {!!props.title && (
        <Typography
          gutterBottom
          variant={
            props.typographyVariant ? props.typographyVariant : 'subtitle1'
          }
          sx={{
            ...styles.typographyAlign,
          }}
        >
          {props.title}
        </Typography>
      )}
      <FormControl
        variant="outlined"
        sx={{ ...styles.formControl, ...props.formControlSx }}
        disabled={!!props.disabled}
      >
        <InputLabel
          id="select-outlined-label"
          sx={{ ...styles.labelRoot, ...props.inputLabelSx }}
        >
          {props.boxLabel}
        </InputLabel>
        <Select
          labelId="select-outlined-label"
          id="select-outlined"
          value={
            !!props.isLabelValue
              ? (props.option as ILabelValue)?.value
              : props.option
          }
          onChange={handleChange}
          label={props.boxLabel}
          MenuProps={{
            ...props.MenuProps,
            sx: {
              [`& .${menuClasses.paper}`]: selectStyles.menuPaper,
              ...(props.MenuProps?.sx || {}),
            },
          }}
          sx={{
            ...selectStyles.selectRoot,
            [`&. ${selectClasses.outlined}`]: selectStyles.outlined,
            [`&. ${selectClasses.icon}`]: selectStyles.iconText,
            [`&. ${selectClasses.select}`]: selectStyles.select,
            ...props.selectSx,
          }}
        >
          {props.hasNoneOption && (
            <MenuItem
              value=""
              sx={{
                ...selectStyles.menuItemRoot,
                [`&. ${menuItemClasses.selected}`]:
                  selectStyles.menuItemSelected,
              }}
              key="None"
            >
              <em>None</em>
            </MenuItem>
          )}
          {props.options.map((e: ILabelValue | string) => {
            const key = !!props.isLabelValue
              ? (e as ILabelValue).value
              : (e as string);
            const disabled = !!props.isDisabled
              ? props.isDisabled(
                  !!props.isLabelValue
                    ? (e as ILabelValue).value
                    : (e as string)
                )
              : false;

            const inner = (
              <MenuItem
                key={key}
                value={
                  !!props.isLabelValue
                    ? (e as ILabelValue).value
                    : (e as string)
                }
                sx={{
                  ...selectStyles.menuItemRoot,
                  [`&. ${menuItemClasses.selected}`]:
                    selectStyles.menuItemSelected,
                  ...(disabled ? selectStyles.disabledMenuItem : {}),
                }}
                disabled={disabled}
              >
                <span className="menu-item-label">
                  {!!props.isLabelValue ? (e as ILabelValue).value : e}
                </span>
                {disabled && <BrandFlame sx={selectStyles.brandFlame} />}
              </MenuItem>
            );

            return disabled ? (
              <EnhancedTooltip
                key={key}
                title={PREMIUM_FEATURE_TOOLTIP}
                onInternalClick={() => {
                  dispatch(openActiveFreeTrialDialog());
                }}
              >
                {inner}
              </EnhancedTooltip>
            ) : (
              inner
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelectComponent;
