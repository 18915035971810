import { SxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';

type Props = {
  sx?: SxProps;
  title: React.ReactNode;
  children: React.ReactElement;
  onInternalClick?: () => void;
};

const EnhancedTooltip = (props: Props) => {
  return !!props.title ? (
    <Tooltip sx={props.sx} title={<Typography>{props.title}</Typography>}>
      <div onClick={props.onInternalClick}>{props.children}</div>
    </Tooltip>
  ) : (
    <>{props.children}</>
  );
};

export default EnhancedTooltip;
