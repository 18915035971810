import { ForwardedRef, forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ColorModeContext } from '../ui/ColorModeContext';

type Props = {
  onLoad: () => void;
};

const RecaptchaComponent = forwardRef<ReCAPTCHA, Props>(
  (props: Props, ref: ForwardedRef<ReCAPTCHA>) => {
    const { colorTheme } = useContext(ColorModeContext);

    return (
      <ReCAPTCHA
        ref={ref}
        size="invisible"
        theme={(colorTheme as any) || 'light'}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        // badge="inline"
        asyncScriptOnLoad={props.onLoad}
      />
    );
  }
);

export default RecaptchaComponent;
