/* eslint-disable no-shadow */
export enum ScreenerLabelEnum {
  TICKER_ID = 'Ticker ID',
  PRICE = 'Price',
  PE = 'P/E Ratio (Price To Earnings)',
  MARKET_CAP = 'Market Capitalization',
  DIVIDEND = 'Dividend',
  DIV_YIELD = 'Dividend Yield',
  PAYOUT = 'Payout Ratio',
  PROFIT_MARGIN = 'Profit Margin',
  GROSS_MARGIN = 'Gross Margin',
  OP_INC = 'Operation Income %',
  REVENUE_GROWTH = 'Revenue Growth',
  PROFIT_GROWTH = 'Profit Growth',
  ROS = 'ROS (Return On Sales)',
  ROE = 'ROE (Return On Equity)',
  ROA = 'ROA (Return On Assets)',
  ROIC = 'ROIC (Return On Invested Capital)',
  CURRENT_RATIO = 'Current Ratio',
  QUICK_RATIO = 'Quick Ratio',
  DE = 'D/E Ratio (Debt To Equity)',
  DEBT_PAYBACK = 'Debt Payback Period',
  COMPANY_NAME = 'Company Name',
  SECTOR = 'Sector',
  INDUSTRY = 'Industry',
  DIVIDEND_TTM = 'Dividend TTM',
  REVENUE = 'Revenue',
  NET_INCOME = 'Net Income',
  ASSETS = 'Total Assets',
  LIABILITIES = 'Total Liabilities',
  EQUITY = 'Total Equity',
  YEAR = 'Last Year',
  COUNTRY = 'Country',
  CAPEX = 'CapEx Impact',
}

export enum ScreenerItemEnum {
  TICKER_ID = 'TickerId',
  COMPANY_NAME = 'Company Name',
  COUNTRY = 'Country Name',
  SECTOR = 'Sector',
  INDUSTRY = 'Industry',
  YEAR = 'Last Year',
  PRICE = 'Close adj',
  PE = 'P/E',
  MARKET_CAP = 'Market Cap',
  DIVIDEND = 'Dividend',
  DIV_YIELD = 'Dividend Yield',
  PAYOUT = 'Payout Ratio',
  PROFIT_MARGIN = 'Profit Margin',
  GROSS_MARGIN = 'Gross Margin',
  OP_INC = 'Op. Inc. %',
  ROS = 'ROS',
  ROE = 'ROE adj',
  ROA = 'ROA adj',
  ROIC = 'ROIC',
  CURRENT_RATIO = 'Current Ratio',
  QUICK_RATIO = 'Quick Ratio',
  DE = 'D/E adj',
  DEBT_PAYBACK = 'Debt Payback Period',
  DIVIDEND_TTM = 'Dividend TTM',
  REVENUE = 'Revenue',
  REVENUE_RATE = 'Revenue Rate',
  NET_INCOME = 'Net Income',
  NET_INCOME_RATE = 'Net Income Rate',
  ASSETS = 'Total Assets',
  LIABILITIES = 'Total Liabilities',
  EQUITY = 'Total Equity',
  CAPEX = 'CapEx To Net Income',
}

export enum ScreenerStockFilterCardEnum {
  PRICE = 'price',
  PRICE_EARNINGS = 'priceEarningsRatio',
  MARKET_CAP = 'marketCap',
  DIVIDEND = 'dividend',
  DIVIDEND_YIELD = 'dividendYield',
  PAYOUT_RATIO = 'payoutRatio',
}

export enum ScreenerIncomeStatementFilterCardEnum {
  PROFIT_MARGIN = 'profitMargin',
  GROSS_MARGIN = 'grossMargin',
  OP_INC = 'opInc',
  REV_GROWTH = 'revGrowth',
  PROF_GROWTH = 'profGrowth',
}

export enum ScreenerManagementEffectivenessFilterCardEnum {
  ROS = 'ROS',
  ROE = 'ROE',
  ROA = 'ROA',
  ROIC = 'ROIC',
}

export enum ScreenerFinancialStrengthFilterCardEnum {
  CURRENT_RATIO = 'currentRatio',
  QUICK_RATIO = 'quickRatio',
  DEBT_TO_EQUITY = 'debtToEquity',
  DEBT_PAYBACK = 'debtPayback',
}

export const ScreenerFilterCardEnum = {
  ...ScreenerStockFilterCardEnum,
  ...ScreenerIncomeStatementFilterCardEnum,
  ...ScreenerManagementEffectivenessFilterCardEnum,
  ...ScreenerFinancialStrengthFilterCardEnum,
};
export type ScreenerFilterCardType =
  | ScreenerStockFilterCardEnum
  | ScreenerIncomeStatementFilterCardEnum
  | ScreenerManagementEffectivenessFilterCardEnum
  | ScreenerFinancialStrengthFilterCardEnum;

/* Constants */
export const GREATER_THAN_LABEL = 'Greater than';
export const LESS_THAN_LABEL = 'Less than';
export const BETWEEN_VALUE = 'Value';

export const GREATER_LESS = [
  { label: 'Less than peers average', value: '<=' },
  { label: 'Greater than peers average', value: '>=' },
];
export const INCREASING_DECREASING = [
  { label: 'Increasing', value: 'Increasing' },
  { label: 'Decreasing', value: 'Decreasing' },
];
export const BETWEEN_YEARS = {
  label: 'Time Range',
  min: 2000,
  max: new Date().getFullYear(),
  step: 1,
};
export const BUTTON_YEAR_LABELS = [1, 3, 5, 7, 9];
export const MARKET_CAP_LIST = [
  'Mega',
  'Large',
  'Mid',
  'Small',
  'Micro',
  'Nano',
];
export const PEERS_COMPARISON = 'Peers Comparison';

export interface IScreenerTab {
  [label: string]: IScreenerFilter;
}

/* Models */
export interface IScreenerConfig {
  stock: IScreenerTab;
  incomeStatement: IScreenerTab;
  managementEffectiveness: IScreenerTab;
  financialStrength: IScreenerTab;
}

export enum ScreenerFilterNameEnum {
  THRESHOLD_GREATER = 'thresholdGreater',
  THRESHOLD_LESS = 'thresholdLess',
  PEER_GROUP_OPERATOR = 'peerGroupOperator',
  MIN_YEAR = 'minYear',
  MAX_YEAR = 'maxYear',
  BINS = 'bins',
  TREND = 'trend',
  YEARS = 'years',
}

export interface IScreenerFilterFields {
  item: string;
  thresholdGreater: number | null;
  thresholdLess: number | null;
  peerGroupOperator: FilterOperator | null;
  // peerGroup: string;
  minYear: number | null;
  maxYear: number | null;
  bins: boolean[] | string[] | null;
  trend:
  | 'Increasing'
  | 'Decreasing'
  | 'Not increasing'
  | 'Not decreasing'
  | null;
  years: boolean[] | number[] | null;
}

export interface IScreenerFilter extends IScreenerFilterFields {
  // Meta
  active?: boolean;
  boxLabel: string;
  components: (
    | IScreenerBetween
    | IScreenerSelect
    | ScreenerCheckboxListI
    | IScreenerSlider
    | IScreenerButtonGroup
  )[];
}
export interface IScreenerFilterLabel {
  label: string;
}

export interface ScreenerMinMaxI {
  min: number;
  max: number;
}
export interface IScreenerBetween
  extends IScreenerFilterLabel,
  ScreenerMinMaxI {
  step: number;
  isPercent?: boolean;
}

export interface IScreenerSelect extends IScreenerFilterLabel {
  list: { label: string; value: string }[];
}

export interface IScreenerSlider extends IScreenerFilterLabel, ScreenerMinMaxI {
  step: number;
  default: number;
  isPercent?: boolean;
}

export interface ScreenerCheckboxListI extends IScreenerFilterLabel {
  list: string[];
}

export interface IScreenerButtonGroup extends IScreenerFilterLabel {
  list: number[];
}

export type FilterOperator = '>=' | '<=';

export const DEFAULT_SCREENER_FILTER_VALUE = (
  isTimeRange?: boolean,
  isPrice?: boolean,
  isMarketCap?: boolean,
  hasNotMinYear?: boolean
): {
  minYear: number | null;
  maxYear: number | null;
  thresholdGreater: number | null;
  thresholdLess: null;
  peerGroupOperator: null;
  bins: boolean[] | null;
  trend: null;
  years: null;
} => ({
  thresholdGreater: !!isPrice ? 0 : null,
  thresholdLess: null,
  peerGroupOperator: null,
  minYear: !!isTimeRange
    ? hasNotMinYear
      ? new Date().getFullYear() - 10
      : BETWEEN_YEARS.min
    : null,
  maxYear: !!isTimeRange ? BETWEEN_YEARS.max : null,
  bins: !!isMarketCap ? Array(7).fill(false) : null,
  trend: null,
  years: null,
});
