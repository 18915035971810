import { MarketCoverageType } from '@prometeus/common';

export interface IVisualsState {
  loading: boolean;
  mapData?: MarketCoverageType;
  error?: string;
}

export enum WorldZoneEnum {
  ALL = 'All',
  AFRICA = 'Africa',
  ASIA = 'Asia',
  EUROPE = 'Europe',
  MIDDLE_EAST = 'Middle East',
  NORTH_AMERICA = 'North America',
  OCEANIA = 'Oceania',
  SOUTH_AMERICA = 'South America',
  SOUTHEAST_ASIA = 'Southeast Asia',
}

// https://www.tutorialrepublic.com/html-reference/html-color-picker.php
export enum PrimaryShadeEnum {
  PRIMARY_900 = '#0f4786',
  PRIMARY_800 = '#11519a',
  PRIMARY_700 = '#135bad',
  PRIMARY_600 = '#1565c0',
  PRIMARY_500 = '#2c74c6',
  PRIMARY_400 = '#4484cd',
  PRIMARY_300 = '#5b93d3',
  PRIMARY_200 = '#73a3d9',
  PRIMARY_100 = '#b9d1ec',
  PRIMARY_50 = '#d0e0f2',
}

export enum TopCompaniesMetricEnum {
  REVENUE = 'Revenue',
  MARKET_CAP = 'Market Cap',
}

export interface ITooltip {
  top: number;
  left: number;
  value: string;
}

/***************************************
 *            Redux constants          *
 ***************************************/

/* GET_MAP_OVERVIEW_DATA */
export const GET_MAP_OVERVIEW_DATA = 'GET_MAP_OVERVIEW_DATA';
export interface IGetMapOverviewData {
  type: typeof GET_MAP_OVERVIEW_DATA;
}

/* GET_MAP_OVERVIEW_DATA_SUCCESS */
export const GET_MAP_OVERVIEW_DATA_SUCCESS = 'GET_MAP_OVERVIEW_DATA_SUCCESS';
export interface IGetMapOverviewDataSuccess {
  type: typeof GET_MAP_OVERVIEW_DATA_SUCCESS;
  payload: any;
}

/* GET_MAP_OVERVIEW_DATA_FAIL */
export const GET_MAP_OVERVIEW_DATA_FAIL = 'GET_MAP_OVERVIEW_DATA_FAIL';
export interface IGetMapOverviewDataFail {
  type: typeof GET_MAP_OVERVIEW_DATA_FAIL;
  payload: string;
}

export type GetMapOverviewDataTypes =
  | IGetMapOverviewData
  | IGetMapOverviewDataFail
  | IGetMapOverviewDataSuccess;

export type DispatchActionTypes = GetMapOverviewDataTypes;
