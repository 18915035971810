import {
  ICompanyMinimal,
  ICompanyPeerData,
  INTERNATIONAL_COMPANIES_URL,
  PEER_SEARCH_GET_ALL_URL,
  PEER_SEARCH_URL,
  REGIONAL_COMPANIES_URL,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import * as tableUtils from '../components/table/table.constants';
import { ISearchPeerCompaniesResult } from '../models/peer-group.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const searchPeerCompaniesService = (
  keyword: string,
  countries: string[],
  industries: string[],
  order: tableUtils.Order,
  orderBy: keyof ICompanyPeerData,
  page: number,
  rowsPerPage: number
): Promise<AxiosResponse<ISearchPeerCompaniesResult>> =>
  axiosInstance.post(PEER_SEARCH_URL, {
    keyword,
    countries,
    industries,
    order,
    orderBy,
    page,
    rowsPerPage,
  });

export const getAllPeersService = (
  keyword: string,
  countries: string[],
  industries: string[]
): Promise<AxiosResponse<ICompanyMinimal[]>> =>
  axiosInstance.post(PEER_SEARCH_GET_ALL_URL, {
    keyword,
    countries,
    industries,
  });

export const getCompaniesRegionalService = (
  countryId: string,
  industry: string
): Promise<AxiosResponse<ICompanyMinimal[]>> =>
  axiosInstance.get(
    `${REGIONAL_COMPANIES_URL}?countryId=${encodeURIComponent(
      countryId
    )}&industry=${encodeURIComponent(industry)}`
  );

export const getCompaniesInternationalService = (
  industry: string
): Promise<AxiosResponse<ICompanyMinimal[]>> =>
  axiosInstance.get(
    `${INTERNATIONAL_COMPANIES_URL}?industry=${encodeURIComponent(industry)}`
  );
