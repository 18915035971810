import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import CustomDialogComponent from '../dialog.component';

const AlertComponent = () => {
  const dispatch = useDispatch();
  const closeHandler = () => {
    dispatch(toggleAlert());
  };

  const showAlert = useSelector((state: RootState) => state.modals.showAlert);

  return (
    <CustomDialogComponent
      dialogTitle=""
      dialogContent={
        <Stack alignItems="center" sx={{ padding: '1rem 0rem' }} spacing={2}>
          {!!showAlert?.error ? (
            <ErrorOutline
              sx={{ transform: 'scale(2)', color: COMMON_COLORS.error }}
            />
          ) : (
            <CheckCircle
              sx={{ transform: 'scale(2)', color: COMMON_COLORS.chartColor1 }}
            />
          )}
          <Typography variant="h6">{showAlert?.message}</Typography>
          <Button
            variant="contained"
            sx={{ borderRadius: '2rem' }}
            onClick={closeHandler}
          >
            Dismiss
          </Button>
        </Stack>
      }
      open={!!showAlert}
      onClose={closeHandler}
      maxWidth="xs"
    />
  );
};

export default AlertComponent;
