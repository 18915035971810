import { ICompanyMinimal } from '@prometeus/common';
import { Map } from 'immutable';
import {
  handleGetSectorIndustryCountryData,
  handleSelectedCountry,
  handleSelectedIndustry,
  handleSelectedSector,
} from '../../constants/companies.constants';
import {
  CHECK_ALL_COMPANIES,
  CHECK_COMPANY,
  DispatchActionTypes,
  FETCH_PEER_GROUP_SECTORS_DATA,
  FETCH_PEER_GROUP_SECTORS_DATA_FAIL,
  FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS,
  GET_COMPANIES_INTERNATIONAL,
  GET_COMPANIES_INTERNATIONAL_FAIL,
  GET_COMPANIES_REGIONAL,
  GET_COMPANIES_REGIONAL_FAIL,
  GET_TOTAL_PEERS,
  GET_TOTAL_PEERS_FAIL,
  INITIALIZE_SECTOR_DATA,
  IPeerGroupState,
  KEEP_PEERS,
  PeerGroupEnum,
  REMOVE_ALL_COMPANIES,
  REMOVE_COMPANY,
  RESET_PEERS_CHANGES,
  RESET_PEER_GROUP_SEARCH_RESULT,
  SEARCH_PEER_COMPANIES,
  SEARCH_PEER_COMPANIES_FAIL,
  SEARCH_PEER_COMPANIES_SUCCESS,
  SELECT_COUNTRY_IDS_PEER_GROUP,
  SELECT_INDUSTRIES_PEER_GROUP,
  SELECT_SECTORS_PEER_GROUP,
  SET_PEERS_PEER_GROUP,
  SET_PEER_GROUP_TYPE,
} from '../../models/peer-group.model';

const checkCompany = (
  map: Map<string, ICompanyMinimal>,
  company: ICompanyMinimal
): Map<string, ICompanyMinimal> =>
  map.has(company.tickerId)
    ? map.remove(company.tickerId)
    : map.set(company.tickerId, company);

const initialState: IPeerGroupState = {
  peerGroupType: PeerGroupEnum.REGIONAL,
  peerGroupSearchResult: [],
  peers: Map<string, ICompanyMinimal>(),
  keepPeers: Map(),
  sectors: [],
  selectedCountries: [],
  selectedIndustries: [],
  loading: false,
  checkAllLoading: false,
};

const peerGroupReducer = (
  state: IPeerGroupState = initialState,
  action: DispatchActionTypes
): IPeerGroupState => {
  switch (action.type) {
    case SET_PEER_GROUP_TYPE:
      return {
        ...state,
        peerGroupType: action.payload,
      };
    case CHECK_COMPANY:
      return {
        ...state,
        peers: checkCompany(state.peers, action.payload),
      };
    case GET_TOTAL_PEERS:
      return {
        ...state,
        checkAllLoading: true,
        error: undefined,
      };
    case GET_TOTAL_PEERS_FAIL:
      return {
        ...state,
        checkAllLoading: false,
        error: action.payload,
      };
    case CHECK_ALL_COMPANIES:
      const peerSearchResult = action.payload.peers;
      const currentPeers = state.peers;
      const everyChecked = peerSearchResult.every((e: ICompanyMinimal) =>
        currentPeers.has(e.tickerId)
      );
      let newPeers = Map(currentPeers);

      peerSearchResult.forEach((e: ICompanyMinimal) => {
        if (e.tickerId !== action.payload.currentTickerId) {
          if (!everyChecked) {
            if (!newPeers.has(e.tickerId)) {
              newPeers = newPeers.set(e.tickerId, e);
            }
          } else {
            if (newPeers.has(e.tickerId)) {
              newPeers = newPeers.remove(e.tickerId);
            }
          }
        }
      });
      return { ...state, checkAllLoading: false, peers: newPeers };
    case REMOVE_COMPANY: {
      return {
        ...state,
        peers: state.peers.remove(action.payload),
      };
    }
    case REMOVE_ALL_COMPANIES: {
      const currentCompany = state.peers.get(action.payload);
      return {
        ...state,
        peers: !!currentCompany
          ? Map<string, ICompanyMinimal>().set(action.payload, currentCompany)
          : Map<string, ICompanyMinimal>(),
      };
    }
    case SEARCH_PEER_COMPANIES:
      return { ...state, loading: true };
    case SEARCH_PEER_COMPANIES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SEARCH_PEER_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        peerGroupSearchResult: action.payload.companies,
        peerGroupSearchResultTotal: action.payload.total,
      };

    case FETCH_PEER_GROUP_SECTORS_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case FETCH_PEER_GROUP_SECTORS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INITIALIZE_SECTOR_DATA:
      return {
        ...state,
        ...handleGetSectorIndustryCountryData(state.sectorData || []),
      };
    case SELECT_COUNTRY_IDS_PEER_GROUP:
      return {
        ...state,
        ...handleSelectedCountry(
          state.selectedCountries,
          action.payload,
          state.sectorData || [],
          state.sectors
        ),
      };
    case SELECT_SECTORS_PEER_GROUP:
      return {
        ...state,
        ...handleSelectedSector(state.sectors, action.payload),
      };
    case SELECT_INDUSTRIES_PEER_GROUP:
      return {
        ...state,
        ...handleSelectedIndustry(
          state.sectors,
          action.payload.sector,
          action.payload.industry
        ),
      };

    case RESET_PEER_GROUP_SEARCH_RESULT: {
      return {
        ...state,
        peerGroupSearchResult: [],
      };
    }
    case GET_COMPANIES_REGIONAL:
      return { ...state, loading: true };
    case GET_COMPANIES_REGIONAL_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_COMPANIES_INTERNATIONAL:
      return { ...state, loading: true };
    case GET_COMPANIES_INTERNATIONAL_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SET_PEERS_PEER_GROUP:
      return {
        ...state,
        loading: false,
        peers: Map(action.payload?.map((e: ICompanyMinimal) => [e.tickerId, e])),
      };
    // Handle Peers keep-reset
    case KEEP_PEERS:
      return {
        ...state,
        keepPeers: state.peers,
      };
    case RESET_PEERS_CHANGES:
      const keepPeers = state.keepPeers;
      return {
        ...state,
        peers: keepPeers,
        keepPeers: Map(),
      };
    default:
      return state;
  }
};

export default peerGroupReducer;
