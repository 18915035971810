import { ISectorData } from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { handleGetSectorIndustryCountryData } from '../../constants/companies.constants';
import {
  CompaniesSearchSetSectorDataDispatchTypes,
  COMPANIES_SEARCH_SET_SECTOR_DATA,
  COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL,
  COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS,
} from '../../models/companies.model';
import {
  FetchPeerGroupSectorsDataDispachTypes,
  FETCH_PEER_GROUP_SECTORS_DATA,
  FETCH_PEER_GROUP_SECTORS_DATA_FAIL,
  FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS,
} from '../../models/peer-group.model';
import {
  FetchSectorsDataDispachTypes,
  FETCH_SECTORS_DATA,
  FETCH_SECTORS_DATA_FAIL,
  FETCH_SECTORS_DATA_SUCCESS,
} from '../../models/screener/structure.model';
import {
  FetchSectorAnalysisSectorsDataDispachTypes,
  FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL,
  FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS,
} from '../../pages/sector-analysis/utils/visuals.model';
import { fetchSectorsDataService } from '../../services/screener.service';
import { actionErrorInterceptor } from '../../utils/error-interceptor';

export const fetchSectorsData =
  () =>
  async (
    dispatch: Dispatch<
      | FetchSectorsDataDispachTypes
      | FetchPeerGroupSectorsDataDispachTypes
      | FetchSectorAnalysisSectorsDataDispachTypes
      | CompaniesSearchSetSectorDataDispatchTypes
    >
  ) => {
    try {
      dispatch({
        type: FETCH_SECTORS_DATA,
      });
      dispatch({
        type: FETCH_PEER_GROUP_SECTORS_DATA,
      });
      dispatch({
        type: COMPANIES_SEARCH_SET_SECTOR_DATA,
      });

      const response: AxiosResponse<ISectorData[]> =
        await fetchSectorsDataService();

      let { data } = response;
      data = data.filter((e) => !!e.countryId);

      const { selectedCountries, sectors, selectedIndustries } =
        handleGetSectorIndustryCountryData(data);

      const payload = {
        sectorData: data,
        selectedCountries,
        sectors,
        selectedIndustries,
      };

      dispatch({
        type: FETCH_SECTORS_DATA_SUCCESS,
        payload,
      });
      dispatch({
        type: FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS,
        payload,
      });
      dispatch({
        type: FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS,
        payload,
      });
      dispatch({
        type: COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS,
        payload,
      });
    } catch (err: any) {
      actionErrorInterceptor(dispatch, FETCH_SECTORS_DATA_FAIL, err);
      actionErrorInterceptor(dispatch, FETCH_PEER_GROUP_SECTORS_DATA_FAIL, err);
      actionErrorInterceptor(
        dispatch,
        COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL,
        err
      );
      actionErrorInterceptor(
        dispatch,
        FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL,
        err
      );
    }
  };
