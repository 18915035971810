import { Box } from '@mui/material';
import {
  IScreenerBetween,
  IScreenerSelect,
  IScreenerTab,
  ScreenerCheckboxListI,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent, {
  resetScreenerCardBlockHandler,
} from '../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../components/filters/screener/between.component';
import CheckboxListScreenerComponent from '../../../components/filters/screener/checkbox-list.component';
import SelectItemComponent from '../../../components/filters/screener/select-item.component';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { CommonBlockComponent } from '../utils/constants';
import { screenerStyles } from '../utils/styles';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from '../utils/tab-change.handler';

const StockFiltersTab = () => {
  const dispatch = useDispatch();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.stock
      : null
  );

  const stockSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(setFilterValue(ScreenerTabNameEnum.STOCK, block, filter, value));
  };

  return (
    <>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: '24.5%',
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* Price Earnings Ratio */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CardComponent
            containerHeight={true}
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.PRICE_EARNINGS}
            activeFilters={!!tabData?.priceEarningsRatio?.active}
            titleLabel={tabData?.priceEarningsRatio.boxLabel || ''}
          >
            <Box
              sx={{
                ...screenerStyles.centered,
                ...screenerStyles.verticalHalfPadding,
              }}
            >
              <SelectItemComponent
                label={
                  (tabData?.priceEarningsRatio.components[0] as IScreenerSelect)
                    .label || ''
                }
                value={tabData?.priceEarningsRatio.peerGroupOperator}
                list={
                  (tabData?.priceEarningsRatio.components[0] as IScreenerSelect)
                    .list || []
                }
                onSelect={(value: string) => {
                  stockSetFilterValue(
                    ScreenerStockFilterCardEnum.PRICE_EARNINGS,
                    ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
                    value
                  );
                }}
                containerSx={screenerStyles.longSelectItemComponent}
              />
              <BetweenSliderComponent
                title={
                  (
                    tabData?.priceEarningsRatio
                      .components[1] as IScreenerBetween
                  ).label
                }
                step={
                  (
                    tabData?.priceEarningsRatio
                      .components[1] as IScreenerBetween
                  ).step
                }
                min={
                  (
                    tabData?.priceEarningsRatio
                      .components[1] as IScreenerBetween
                  ).min || 0
                }
                minValue={tabData?.priceEarningsRatio.minYear as number}
                setMinValue={(value: number) => {
                  onMinBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE_EARNINGS,
                    value,
                    true
                  );
                }}
                max={
                  (
                    tabData?.priceEarningsRatio
                      .components[1] as IScreenerBetween
                  ).max || 0
                }
                maxValue={tabData?.priceEarningsRatio.maxYear as number}
                setMaxValue={(value: number) => {
                  onMaxBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE_EARNINGS,
                    value,
                    true
                  );
                }}
                setValues={(values: BetweenValuesType) => {
                  onBetweenValuesHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE_EARNINGS,
                    values,
                    true
                  );
                }}
                containerSx={screenerStyles.paddingRight0}
              />
            </Box>
          </CardComponent>
        </div>
        {/* Price */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CardComponent
            containerHeight={true}
            titleLabel={tabData?.price.boxLabel || ''}
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.PRICE}
            activeFilters={!!tabData?.price?.active}
          >
            <Box
              sx={{
                ...screenerStyles.centered,
                ...screenerStyles.marginTopNegd5,
              }}
            >
              <BetweenSliderComponent
                title={(tabData?.price.components[0] as IScreenerBetween).label}
                step={(tabData?.price.components[0] as IScreenerBetween).step}
                min={
                  (tabData?.price.components[0] as IScreenerBetween).min || 0
                }
                minValue={tabData?.price.thresholdGreater as number}
                setMinValue={(value: number) => {
                  onMinBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE,
                    value
                  );
                }}
                max={
                  (tabData?.price.components[0] as IScreenerBetween).max || 0
                }
                maxValue={tabData?.price.thresholdLess as number}
                setMaxValue={(value: number) => {
                  onMaxBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE,
                    value
                  );
                }}
                setValues={(values: BetweenValuesType) => {
                  onBetweenValuesHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.PRICE,
                    values
                  );
                }}
                largeInputWidth={true}
              />
            </Box>
          </CardComponent>
        </div>
      </div>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: '24.5%',
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* Dividend */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CardComponent
            containerHeight={true}
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.DIVIDEND}
            activeFilters={!!tabData?.dividend?.active}
            titleLabel={tabData?.dividend.boxLabel || ''}
          >
            <Box
              sx={{
                ...screenerStyles.centered,
                ...screenerStyles.verticalHalfPadding,
              }}
            >
              <SelectItemComponent
                split={true}
                label={
                  (tabData?.dividend.components[0] as IScreenerSelect).label ||
                  ''
                }
                value={tabData?.dividend.trend}
                list={
                  (tabData?.dividend.components[0] as IScreenerSelect).list ||
                  []
                }
                onSelect={(value: string) => {
                  stockSetFilterValue(
                    ScreenerStockFilterCardEnum.DIVIDEND,
                    ScreenerFilterNameEnum.TREND,
                    value
                  );
                }}
                containerSx={screenerStyles.longSelectItemComponent}
              />
              {/* Second */}
              <BetweenSliderComponent
                title={
                  (tabData?.dividend.components[1] as IScreenerBetween).label
                }
                min={
                  (tabData?.dividend.components[1] as IScreenerBetween).min || 0
                }
                minValue={tabData?.dividend.minYear as number}
                setMinValue={(value: number) => {
                  onMinBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.DIVIDEND,
                    value,
                    true
                  );
                }}
                max={
                  (tabData?.dividend.components[1] as IScreenerBetween).max || 0
                }
                maxValue={tabData?.dividend.maxYear as number}
                setMaxValue={(value: number) => {
                  onMaxBetweenValueHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.DIVIDEND,
                    value,
                    true
                  );
                }}
                setValues={(values: BetweenValuesType) => {
                  onBetweenValuesHandler(
                    stockSetFilterValue,
                    ScreenerStockFilterCardEnum.DIVIDEND,
                    values,
                    true
                  );
                }}
                containerSx={screenerStyles.paddingRight0}
              />
            </Box>
          </CardComponent>
        </div>
        {/* Market Cap */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CardComponent
            containerHeight={true}
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.MARKET_CAP}
            activeFilters={!!tabData?.marketCap?.active}
            titleLabel={tabData?.marketCap.boxLabel || ''}
          >
            <CheckboxListScreenerComponent
              labels={
                (tabData?.marketCap.components[0] as ScreenerCheckboxListI)
                  .list || []
              }
              active={!!tabData?.marketCap?.active}
              checked={(tabData?.marketCap.bins as boolean[]) || []}
              onClick={(value: boolean[]) => {
                if (value.some((e) => e)) {
                  stockSetFilterValue(
                    ScreenerStockFilterCardEnum.MARKET_CAP,
                    ScreenerFilterNameEnum.BINS,
                    value
                  );
                } else {
                  if (!!tabData?.marketCap?.active) {
                    resetScreenerCardBlockHandler(
                      dispatch,
                      ScreenerTabNameEnum.STOCK,
                      ScreenerStockFilterCardEnum.MARKET_CAP
                    );
                  }
                }
              }}
            />
          </CardComponent>
        </div>
      </div>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: '38%',
        }}
      >
        {/* Dividend Yield */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.DIVIDEND_YIELD}
            data={tabData?.dividendYield}
            setFilterValueHandler={stockSetFilterValue}
          />
        </div>
        {/* Payout Ratio */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.STOCK}
            cardBlock={ScreenerStockFilterCardEnum.PAYOUT_RATIO}
            data={tabData?.payoutRatio}
            setFilterValueHandler={stockSetFilterValue}
          />
        </div>
      </div>
    </>
  );
};

export default StockFiltersTab;
