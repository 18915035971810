import {
  DispatchActionTypes,
  GET_API_LOGS_TREE,
  GET_API_LOGS_TREE_FAIL,
  GET_API_LOGS_TREE_SUCCESS,
  GET_API_LOG_CONTENT,
  GET_API_LOG_CONTENT_FAIL,
  GET_API_LOG_CONTENT_SUCCESS,
  GET_BACKEND_LOGS_LIST,
  GET_BACKEND_LOGS_LIST_FAIL,
  GET_BACKEND_LOGS_LIST_SUCCESS,
  GET_BACKEND_LOG_CONTENT,
  GET_BACKEND_LOG_CONTENT_FAIL,
  GET_BACKEND_LOG_CONTENT_SUCCESS,
  ILogsState,
} from '../../../models/admin/logs.model';

const initialState: ILogsState = {
  loading: false,
  apiTree: [],
  backendList: [],
};

const logsReducer = (
  state: ILogsState = initialState,
  action: DispatchActionTypes
): ILogsState => {
  /* Reducer logic */
  switch (action.type) {
    /* API */
    case GET_API_LOGS_TREE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_API_LOGS_TREE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiTree: action.payload,
      };
    case GET_API_LOGS_TREE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_API_LOG_CONTENT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_API_LOG_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiLogContent: action.payload,
      };
    case GET_API_LOG_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /* BACKEND */
    case GET_BACKEND_LOGS_LIST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_BACKEND_LOGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        backendList: [
          {
            children: [],
            files: action.payload,
            path: '',
            type: '',
          },
        ],
      };
    case GET_BACKEND_LOGS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BACKEND_LOG_CONTENT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_BACKEND_LOG_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        backendLogContent: action.payload,
      };
    case GET_BACKEND_LOG_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default logsReducer;
