import { Box, Palette, useTheme } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import ScreenerResultTableComponent from './screener-result-table.component';

type Props = {
  parentHistory: RouteComponentProps['history'];
};

const getStyles = (palette: Palette) => ({
  dialogContentBg: {
    backgroundColor: palette.background.default,
  },
});

const ScreenerTableDialogComponent = (props: Props) => {
  const theme = useTheme();
  const classes = getStyles(theme.palette);

  return (
    <Box sx={classes.dialogContentBg}>
      <ScreenerResultTableComponent parentHistory={props.parentHistory} />
    </Box>
  );
};

export default ScreenerTableDialogComponent;
