export const isNullUndefined = (value: any) =>
  value === null || value === undefined;

export const isExpired = (datetime: string | Date | number): boolean => {
  if (!datetime) {
    return true;
  }
  const currentTime = new Date().getTime();
  const time = new Date(datetime).getTime();

  return currentTime >= time;
};

export const uniqueArray = (arr: unknown[]) => {
  // To store the unique sub arrays
  const uniques = [];

  // To keep track of the sub arrays
  const itemsFound: any = {};

  for (const val of arr) {
    // convert the sub array to the string
    const stringified = JSON.stringify(val);

    // If it is already added then skip to next element
    if (itemsFound[stringified]) {
      continue;
    }

    // Else add the value to the unique list
    uniques.push(val);

    // Mark it as true so that it can tracked
    itemsFound[stringified] = true;
  }

  // Return the unique list
  return uniques;
};

export const uniqueStringArray = (arr: string[]): string[] =>
  Array.from(new Set(arr));

export const toTitleCase = (value: string): string =>
  value?.replace(
    /\w\S*/g,
    (e: string) => e?.charAt(0)?.toUpperCase() + e?.substr(1)?.toLowerCase()
  );

export const objectMap = (
  obj: any,
  callback: (key: string, value: any, index: number) => any
) =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, callback(k, v, i)])
  );

export const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const numberFormatter = (
  value: number | string,
  digits: number = 1
): string => {
  /* Symbols */
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];

  /* Pattern */
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  /* Make number */
  let num = typeof value === 'string' ? +value : value;
  let negative = false;
  if (num < 0) {
    num = num * -1;
    negative = true;
  }

  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    ((num / si[i].value) * (negative ? -1 : 1))
      .toFixed(digits)
      .replace(rx, '$1') + si[i].symbol
  );
};

export const makeItGibberish = (inputString: string): string => {
  const isAlphanumericOrPunctuation = (char) =>
    /^[a-zA-Z0-9,.!? ]$/.test(char);

  const gibberish = Array.from(inputString).map((char, index) => {
    // Introduce some randomness to each character
    let randomOffset = Math.floor(Math.random() * 5) - 2;

    // Keep certain characters unchanged (e.g., spaces, punctuation)
    if (isAlphanumericOrPunctuation(char)) {
      randomOffset = 0;
    }

    const charCode = char.charCodeAt(0) + randomOffset;

    // Ensure the generated character is alphanumeric or punctuation
    const gibberishChar = String.fromCharCode(charCode);
    return isAlphanumericOrPunctuation(gibberishChar) ? gibberishChar : ' ';
  });

  return gibberish.join('');
};
