import {
  IVisualChart,
  IVisualChartRank,
  IVisualPayload,
} from '../../../models/visuals.model';

export interface IVisualsState {
  /* Company Stocks */
  epsData?: IVisualChart;
  epsDataUpdating?: boolean;
  sharesData?: IVisualChart;
  sharesDataUpdating?: boolean;
  marketCapRankData?: IVisualChartRank;
  marketCapRankDataUpdating?: boolean;
  marketCapRankFullData?: IVisualChartRank;
  marketCapRankFullDataUpdating?: boolean;
  priceData?: IVisualChart;
  priceDataUpdating?: boolean;
  peRatioData?: IVisualChart;
  peRatioDataUpdating?: boolean;
  marketCapData?: IVisualChart;
  marketCapDataUpdating?: boolean;

  /* Company Dividends */
  payoutRatioData?: IVisualChart;
  payoutRatioDataUpdating?: boolean;
  dividendHistoryData?: IVisualChart;
  dividendHistoryDataUpdating?: boolean;
  dividendTTMData?: IVisualChart;
  dividendTTMDataUpdating?: boolean;
  dividendYieldData?: IVisualChart;
  dividendYieldDataUpdating?: boolean;
  dividendYieldDistributionData?: IVisualChart;
  dividendYieldDistributionDataUpdating?: boolean;
}

/***************************************
 *            Company Summary          *
 ***************************************/
/* PRICE_DATA */
export const PRICE_DATA = 'PRICE_DATA';
export interface PriceDataI {
  type: typeof PRICE_DATA;
  payload: IVisualPayload;
}

/* PE_RATIO_DATA */
export const PE_RATIO_DATA = 'PE_RATIO_DATA';
export interface PeRatioDataI {
  type: typeof PE_RATIO_DATA;
  payload: IVisualPayload;
}

/* MARKET_CAP_DATA */
export const MARKET_CAP_DATA = 'MARKET_CAP_DATA';
export interface MarketCapDataI {
  type: typeof MARKET_CAP_DATA;
  payload: IVisualPayload;
}

/* EPS_DATA */
export const EPS_DATA = 'EPS_DATA';
export interface EpsDataI {
  type: typeof EPS_DATA;
  payload: IVisualPayload;
}

/* SHARES_DATA */
export const SHARES_DATA = 'SHARES_DATA';
export interface SharesDataI {
  type: typeof SHARES_DATA;
  payload: IVisualPayload;
}

/* MARKET_CAP_RANK_DATA */
export const MARKET_CAP_RANK_DATA = 'MARKET_CAP_RANK_DATA';
export interface MarketCapRankDataI {
  type: typeof MARKET_CAP_RANK_DATA;
  payload: IVisualPayload;
}

/***************************************
 *           Company Dividends         *
 ***************************************/
/* DIVIDEND_YIELD_DATA */
export const DIVIDEND_YIELD_DATA = 'DIVIDEND_YIELD_DATA';
export interface DividendYieldDataI {
  type: typeof DIVIDEND_YIELD_DATA;
  payload: IVisualPayload;
}

/* PAYOUT_RATIO_DATA */
export const PAYOUT_RATIO_DATA = 'PAYOUT_RATIO_DATA';
export interface PayoutRatioDataI {
  type: typeof PAYOUT_RATIO_DATA;
  payload: IVisualPayload;
}

/* DIVIDEND_HISTORY_DATA */
export const DIVIDEND_HISTORY_DATA = 'DIVIDEND_HISTORY_DATA';
export interface DividendHistoryDataI {
  type: typeof DIVIDEND_HISTORY_DATA;
  payload: IVisualPayload;
}

/* DIVIDEND_YIELD_DISTRIBUTION_DATA */
export const DIVIDEND_YIELD_DISTRIBUTION_DATA =
  'DIVIDEND_YIELD_DISTRIBUTION_DATA';
export interface IDividendYieldDistributionData {
  type: typeof DIVIDEND_YIELD_DISTRIBUTION_DATA;
  payload: IVisualPayload;
}

/* DIVIDEND_TTM_DATA */
export const DIVIDEND_TTM_DATA = 'DIVIDEND_TTM_DATA';
export interface DividendTTMDataI {
  type: typeof DIVIDEND_TTM_DATA;
  payload: IVisualPayload;
}

/* STOCK_ANALYSIS_UPDATE */
export const STOCK_ANALYSIS_UPDATE = 'STOCK_ANALYSIS_UPDATE';
export interface IStockAnalysisUpdateAction {
  type: typeof STOCK_ANALYSIS_UPDATE;
  payload: string[];
}

/* COMPANY_DIVIDENDS_UPDATE_ACTION */
export const COMPANY_DIVIDENDS_UPDATE_ACTION =
  'COMPANY_DIVIDENDS_UPDATE_ACTION';
export interface ICompanyDividendsUpdateAction {
  type: typeof COMPANY_DIVIDENDS_UPDATE_ACTION;
  payload: string[];
}

export const ACTIONS_ARR = [
  DIVIDEND_HISTORY_DATA,
  DIVIDEND_YIELD_DISTRIBUTION_DATA,
  DIVIDEND_TTM_DATA,
  DIVIDEND_YIELD_DATA,
  EPS_DATA,
  MARKET_CAP_DATA,
  MARKET_CAP_RANK_DATA,
  PAYOUT_RATIO_DATA,
  PE_RATIO_DATA,
  PRICE_DATA,
  SHARES_DATA,
];

export type DispatchActionTypes =
  | IStockAnalysisUpdateAction
  /* Company Stocks */
  | PriceDataI
  | PeRatioDataI
  | EpsDataI
  | SharesDataI
  | MarketCapDataI
  | MarketCapRankDataI
  /* Company Dividends */
  | DividendYieldDataI
  | PayoutRatioDataI
  | DividendHistoryDataI
  | DividendTTMDataI
  | IDividendYieldDistributionData;
