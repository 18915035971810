import {
  DELETE_ICON,
  DELETE_ICON_FAIL,
  DispatchActionTypes,
  GET_TOTAL_ICONS,
  GET_TOTAL_ICONS_FAIL,
  GET_TOTAL_ICONS_SUCCESS,
  ICON_DUMP_DB,
  ICON_DUMP_DB_FAIL,
  ICON_DUMP_DB_SUCCESS,
  IIconsState,
  SEARCH_ICONS,
  SEARCH_ICONS_FAIL,
  SEARCH_ICONS_SUCCESS,
  UPSERT_ICON,
  UPSERT_ICON_FAIL,
} from '../../../models/admin/icons.model';

const initialState: IIconsState = {
  loading: false,
  searchedCompanies: [],
};

const iconsReducer = (
  state: IIconsState = initialState,
  action: DispatchActionTypes
): IIconsState => {
  /* Reducer logic */
  switch (action.type) {
    /***************************************
     *                 Dump                *
     ***************************************/
    case ICON_DUMP_DB:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ICON_DUMP_DB_SUCCESS:
      return {
        ...state,
        loading: false,
        iconDump: action.payload,
      };
    case ICON_DUMP_DB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *                Search               *
     ***************************************/
    case SEARCH_ICONS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SEARCH_ICONS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedCompanies: action.payload,
      };
    case SEARCH_ICONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *                Upsert               *
     ***************************************/
    case UPSERT_ICON:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UPSERT_ICON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /***************************************
     *                Delete               *
     ***************************************/
    case DELETE_ICON:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case DELETE_ICON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /*  */
    case GET_TOTAL_ICONS:
      return {
        ...state,
        totalIconsLoading: true,
        error: undefined,
      };
    case GET_TOTAL_ICONS_SUCCESS:
      return {
        ...state,
        totalIconsLoading: false,
        totalIcons: action.payload,
      };
    case GET_TOTAL_ICONS_FAIL:
      return {
        ...state,
        totalIconsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default iconsReducer;
