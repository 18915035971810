import { IconShapeEnum } from '@prometeus/common';
import { Map, Set } from 'immutable';

export interface IIconsState {
  iconsMap: Map<string, ICachedIcon>;
  iconsQueue: string[];
  requestedTickers: Set<string>;

  loading: boolean;
  error?: string;
}

export interface ICachedIcon {
  image: string;
  count: number;
  shape?: IconShapeEnum;
}

export interface IIconObj {
  companyId: string;
  image: string;
  shape?: IconShapeEnum;
}

/* GET_ICONS */
export const GET_ICONS = 'GET_ICONS';
export const GET_ICONS_SUCCESS = 'GET_ICONS_SUCCESS';
export const GET_ICONS_FAIL = 'GET_ICONS_FAIL';

export interface GetIconsI {
  type: typeof GET_ICONS;
  payload: string[];
}

export interface GetIconsFailI {
  type: typeof GET_ICONS_FAIL;
  payload: string;
}

export interface GetIconsSuccessI {
  type: typeof GET_ICONS_SUCCESS;
  payload: IIconObj[];
}

export type GetIconsDispachTypes = GetIconsI | GetIconsFailI | GetIconsSuccessI;

export type DispatchActionTypes = GetIconsDispachTypes;
