import { Theme } from '@mui/material';
import { isFirefox, isIE } from 'react-device-detect';
import { perc2HexOpacity, pxToRem } from '../ui/functions';

export const getAutocompleteStyles = (theme: Theme) => ({
  option: {
    width: '100%',
    // backgroundColor: palette.background.paper,
    color: theme.palette.text.primary,
    minHeight: pxToRem(48),
    '& .MuiButtonBaseRoot': {
      padding: pxToRem(9),
    },
    '& .MuiCheckboxRoot': {
      color: theme.palette.primary.main,
    },
  },
  noOptions: {
    width: '100%',
    color: theme.palette.text.primary,
    // backgroundColor: palette.background.paper,
    padding: `${pxToRem(14)} ${pxToRem(16)}`,
  },
  listbox: {
    padding: '0.5rem 0rem',
    margin: 0,
    width: '100%',
  },
  paper: {
    background: `${theme.palette.background.default}${
      isIE || isFirefox ? '' : perc2HexOpacity(92)
    } !important`,
  },
  expand: {
    marginLeft: 'auto',
    // color: colors.icons,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  countrySectorRoot: {
    '& .MuiOutlinedInput-root': {
      padding: `0rem 1.2rem !important`,
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '1.1rem 0.8rem !important',
    },
  },
});
