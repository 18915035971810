import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../store/reducers/root.reducer';

type Props = {
  children: React.ReactNode;
  path: string;
};

/* Checking token */
export const AuthenticatedRoute = (props: Props) => {
  const { children, path, ...rest } = props;
  const token = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  return (
    <Route
      path={path}
      exact
      {...rest}
      render={({ location }) =>
        token ? (
          (children as any)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const UnauthenticatedRoute = (props: Props) => {
  const { children, path, ...rest } = props;
  const token = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  return (
    <Route
      path={path}
      exact
      {...rest}
      render={({ location }) =>
        token ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          (children as any)
        )
      }
    />
  );
};

/* Checking userInformation */
export const UseredRoute = (props: Props) => {
  const { children, path, ...rest } = props;
  const userInformation = useSelector(
    (state: RootState) => state.user.userInformation
  );

  return (
    <Route
      path={path}
      exact
      {...rest}
      render={({ location }) =>
        !userInformation ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          (children as any)
        )
      }
    />
  );
};

/* Admin Routes */
export const AdminRoute = (props: Props) => {
  const { children, path, ...rest } = props;
  const isAdmin = useSelector(
    (state: RootState) => state.authentication.isAdmin
  );

  return (
    <Route
      path={path}
      exact
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          (children as any)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
