import { Map } from 'immutable';
import {
  handleSelectedCountry,
  handleSelectedIndustry,
  handleSelectedSector,
} from '../../constants/companies.constants';
import {
  COMPANIES_SEARCH_SELECT_COUNTRY_IDS,
  COMPANIES_SEARCH_SELECT_INDUSTRIES,
  COMPANIES_SEARCH_SELECT_SECTORS,
  COMPANIES_SEARCH_SET_SECTOR_DATA,
  COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL,
  COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS,
  DispatchActionTypes,
  FETCH_COMPANY,
  FETCH_COMPANY_FAIL,
  FETCH_COMPANY_SUCCESS,
  FETCH_PEER_LIST_DATA,
  FETCH_PEER_LIST_DATA_FAIL,
  FETCH_PEER_LIST_DATA_SUCCESS,
  GET_MOST_VIEWED_COMPANIES,
  GET_MOST_VIEWED_COMPANIES_FAIL,
  GET_MOST_VIEWED_COMPANIES_SUCCESS,
  ICompaniesState,
  IPeerListData,
  ITickerIdRevenueCap,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_FAIL,
  SEARCH_COMPANIES_SUCCESS,
  SET_COMPANIES_DATA,
} from '../../models/companies.model';

const initialState: ICompaniesState = {
  companies: [],
  loading: false,
  loadingSectorsData: true,
  loadingSearchCompanies: false,
  loadingFreeCompanies: true,
  loadingRevenueCap: false,
  sectors: [],
  selectedIndustries: [],
  selectedCountries: [],
  mostViewedCompanies: [],
  peerListDataMap: Map<string, IPeerListData>([]),
};

const companiesReducer = (
  state: ICompaniesState = initialState,
  action: DispatchActionTypes
): ICompaniesState => {
  switch (action.type) {
    case SEARCH_COMPANIES:
      return {
        ...state,
        loadingSearchCompanies: true,
        lastSearchCompanyKeyword: action.payload,
      };
    case SEARCH_COMPANIES_SUCCESS:
      const { companies, keyword } = action.payload;

      if (keyword !== state.lastSearchCompanyKeyword) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        loadingSearchCompanies: false,
        companies,
      };
    case SET_COMPANIES_DATA:
      return {
        ...state,
        companies: action.payload
      }
    case SEARCH_COMPANIES_FAIL:
      return {
        ...state,
        loadingSearchCompanies: false,
        error: action.payload,
      };
    case FETCH_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCompany: action.payload,
      };
    case FETCH_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /* Pyramid structure logic CountryId > Sector > Industry */
    case COMPANIES_SEARCH_SELECT_COUNTRY_IDS:
      return {
        ...state,
        ...handleSelectedCountry(
          state.selectedCountries,
          action.payload,
          state.sectorData || [],
          state.sectors
        ),
      };
    case COMPANIES_SEARCH_SELECT_SECTORS:
      return {
        ...state,
        ...handleSelectedSector(state.sectors, action.payload),
      };
    case COMPANIES_SEARCH_SELECT_INDUSTRIES:
      return {
        ...state,
        ...handleSelectedIndustry(
          state.sectors,
          action.payload.sector,
          action.payload.industry
        ),
      };
    case COMPANIES_SEARCH_SET_SECTOR_DATA:
      return {
        ...state,
        loadingSectorsData: true,
      };
    case COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingSectorsData: false,
      };
    case COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL:
      return {
        ...state,
        loadingSectorsData: false,
        error: action.payload,
      };

    case FETCH_PEER_LIST_DATA:
      return {
        ...state,
        loadingRevenueCap: true,
      };
    case FETCH_PEER_LIST_DATA_SUCCESS:
      let { peerListDataMap } = state;
      action.payload.forEach((e: ITickerIdRevenueCap) => {
        peerListDataMap = peerListDataMap.set(e.tickerId, e);
      });
      return {
        ...state,
        loadingRevenueCap: false,
        peerListDataMap: peerListDataMap,
      };
    case FETCH_PEER_LIST_DATA_FAIL:
      return {
        ...state,
        loadingRevenueCap: false,
        error: action.payload,
      };

    case GET_MOST_VIEWED_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case GET_MOST_VIEWED_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        mostViewedCompanies: action.payload,
      };
    case GET_MOST_VIEWED_COMPANIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default companiesReducer;
