import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { COMMON_STYLES, DOLLAR_ST } from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../../ui/colors';
import { EQUITY_TITLE_LABELS } from '../utils/visuals.config';

type Props = {};

const EquityTab = (props: Props) => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const equityTotalEquityData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.equityTotalEquityData
  );
  const equityTotalEquityDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.equityTotalEquityDataUpdating
  );
  const shareCapitalAdditionalPaidInData = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.shareCapitalAdditionalPaidInData
  );
  const shareCapitalAdditionalPaidInDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.shareCapitalAdditionalPaidInDataUpdating
  );
  const retainedEarningsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.retainedEarningsData
  );
  const retainedEarningsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.retainedEarningsDataUpdating
  );
  const treasuryStockData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.treasuryStockData
  );
  const treasuryStockDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.treasuryStockDataUpdating
  );

  const [equityTotalEquity, setEquityTotalEquity] = useState<ChartData>();
  const [shareCapitalAdditionalPaidIn, setShareCapitalAdditionalPaidIn] =
    useState<ChartData>();
  const [retainedEarnings, setRetainedEarnings] = useState<ChartData>();
  const [treasuryStock, setTreasuryStock] = useState<ChartData>();

  /* Total Equity */
  const [
    equityTotalEquityHasData,
    equityTotalEquityHasLabels,
    equityTotalEquityError,
  ] = extractChartMetadata(equityTotalEquityData);
  useEffect(() => {
    setEquityTotalEquity({
      datasets: createChartDatasetsConfig(equityTotalEquityData, screenWidth, {
        useDollars,
        isTime: true,
        customLineColor: COMMON_COLORS.equityCharts,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [equityTotalEquityData, useDollars, fiscalYear, screenWidth]);

  /* Share Capital Additional Paid In */
  const [
    shareCapitalAdditionalPaidInHasData,
    shareCapitalAdditionalPaidInHasLabels,
    shareCapitalAdditionalPaidInError,
  ] = extractChartMetadata(shareCapitalAdditionalPaidInData);
  useEffect(() => {
    setShareCapitalAdditionalPaidIn({
      datasets: createChartDatasetsConfig(
        shareCapitalAdditionalPaidInData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          customLineColor: COMMON_COLORS.equityCharts,
          fiscalYear,
          isPortraitMobile,
        }
      ),
    });
  }, [shareCapitalAdditionalPaidInData, useDollars, fiscalYear, screenWidth]);

  /* Retained Earnings */
  const [
    retainedEarningsHasData,
    retainedEarningsHasLabels,
    retainedEarningsError,
  ] = extractChartMetadata(retainedEarningsData);
  useEffect(() => {
    setRetainedEarnings({
      datasets: createChartDatasetsConfig(retainedEarningsData, screenWidth, {
        useDollars,
        isTime: true,
        customLineColor: COMMON_COLORS.equityCharts,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [retainedEarningsData, useDollars, fiscalYear, screenWidth]);

  /* Treasury Stock */
  const [treasuryStockHasData, treasuryStockHasLabels, treasuryStockError] =
    extractChartMetadata(treasuryStockData);
  useEffect(() => {
    setTreasuryStock({
      datasets: createChartDatasetsConfig(treasuryStockData, screenWidth, {
        useDollars,
        isTime: true,
        customLineColor: COMMON_COLORS.equityCharts,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [treasuryStockData, useDollars, fiscalYear, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.EQUITY));
  }, [dispatch]);

  // TODO add responsive row
  return (
    <>
      {equityTotalEquityDataUpdating &&
        shareCapitalAdditionalPaidInDataUpdating &&
        retainedEarningsDataUpdating &&
        treasuryStockDataUpdating && <LoadingComponent transparent={true} />}
      <div style={{ ...COMMON_STYLES.flexDisplay, height: '93.3%' }}>
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}></div>
        <div
          style={{
            flex: '3.5 1 0',
            ...COMMON_STYLES.flexDisplay,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ height: '32%' }}>
            <VisualChartComponent
              containerHeight={true}
              type="line"
              data={equityTotalEquity}
              error={
                !!equityTotalEquityError
                  ? GENERAL_ERROR_MESSAGE
                  : !equityTotalEquityHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={EQUITY_TITLE_LABELS.totalEquity}
              hasData={equityTotalEquityHasData}
              exportData={equityTotalEquityData}
              currencySymbol={
                !!equityTotalEquityData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!equityTotalEquityData?.isPercentage}
              timeTooltipFormat={
                activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
              }
              updating={!!equityTotalEquityDataUpdating}
              onOpenChartDialogClick={onOpenChartDialogClickHandler}
            />
          </div>
          <div
            style={{
              ...COMMON_STYLES.flexDisplay,
              height: '32%',
            }}
          >
            <div style={COMMON_STYLES.flexOneOneZero}>
              <VisualChartComponent
                containerHeight={true}
                type="line"
                data={shareCapitalAdditionalPaidIn}
                error={
                  !!shareCapitalAdditionalPaidInError
                    ? GENERAL_ERROR_MESSAGE
                    : !shareCapitalAdditionalPaidInHasLabels
                    ? GENERAL_NO_DATA_MESSAGE
                    : undefined
                }
                titleLabel={EQUITY_TITLE_LABELS.shareCapitalAdditionalPaidIn}
                hasData={shareCapitalAdditionalPaidInHasData}
                exportData={shareCapitalAdditionalPaidInData}
                currencySymbol={
                  !!shareCapitalAdditionalPaidInData?.isCurrency
                    ? useDollars
                      ? DOLLAR_ST
                      : currencySt
                    : undefined
                }
                isPercentage={!!shareCapitalAdditionalPaidInData?.isPercentage}
                timeTooltipFormat={
                  activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
                }
                updating={!!shareCapitalAdditionalPaidInDataUpdating}
                onOpenChartDialogClick={onOpenChartDialogClickHandler}
              />
            </div>
            <div style={COMMON_STYLES.flexOneOneZero}>
              <VisualChartComponent
                containerHeight={true}
                type="line"
                data={retainedEarnings}
                error={
                  !!retainedEarningsError
                    ? GENERAL_ERROR_MESSAGE
                    : !retainedEarningsHasLabels
                    ? GENERAL_NO_DATA_MESSAGE
                    : undefined
                }
                titleLabel={EQUITY_TITLE_LABELS.retainedEarnings}
                hasData={retainedEarningsHasData}
                exportData={retainedEarningsData}
                currencySymbol={
                  !!retainedEarningsData?.isCurrency
                    ? useDollars
                      ? DOLLAR_ST
                      : currencySt
                    : undefined
                }
                isPercentage={!!retainedEarningsData?.isPercentage}
                timeTooltipFormat={
                  activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
                }
                updating={!!retainedEarningsDataUpdating}
                onOpenChartDialogClick={onOpenChartDialogClickHandler}
              />
            </div>
          </div>
          <div style={{ height: '32%' }}>
            <VisualChartComponent
              containerHeight={true}
              type="line"
              data={treasuryStock}
              error={
                !!treasuryStockError
                  ? GENERAL_ERROR_MESSAGE
                  : !treasuryStockHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={EQUITY_TITLE_LABELS.treasuryStock}
              hasData={treasuryStockHasData}
              exportData={treasuryStockData}
              currencySymbol={
                !!treasuryStockData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!treasuryStockData?.isPercentage}
              timeTooltipFormat={
                activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
              }
              updating={!!treasuryStockDataUpdating}
              onOpenChartDialogClick={onOpenChartDialogClickHandler}
            />
          </div>
        </div>
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}></div>
      </div>
    </>
  );
};

export default EquityTab;
