import { ChartTypeEnum, IVisualConfig } from '../../../models/visuals.model';
import {
  COMPANY_INFO_DATA,
  COMPANY_OVERVIEW_DATA,
  COMPANY_SUMMARY_DATA,
  COMPANY_SUMMARY_PRICE_DATA,
  COMPANY_TOP_PEERS_DATA,
} from './visuals.model';

export const TOP_PEERS_CONFIG: IVisualConfig = {
  id: 'topPeers',
  type: ChartTypeEnum.TOP_PEERS,
  item: '',
  dataAction: COMPANY_TOP_PEERS_DATA,
  stateLabel: 'companyTopPeersData',
  columns: [],
  datasetLabels: [],
};

export const COMPANY_SUMMARY_CONFIG: IVisualConfig[] = [
  {
    id: 'companyOverview',
    type: ChartTypeEnum.RADAR_CHART,
    item: '',
    dataAction: COMPANY_OVERVIEW_DATA,
    stateLabel: 'companyOverviewData',
    isPercentage: true,
    // columns: ['Category', 'Company'],
    columns: [
      'Debt',
      'Dividend',
      'Growth',
      'Profitability',
      'Returns',
      'ticket_score',
    ],
    datasetLabels: [
      { label: 'Debt', fill: true },
      { label: 'Dividend', fill: true },
      { label: 'Growth', fill: true },
      { label: 'Profitability', fill: true },
      { label: 'Returns', fill: true },
      { label: 'ticket_score', fill: true },
    ],
    // datasetLabels: [],
  },
  {
    id: 'companyInfo',
    type: ChartTypeEnum.INFO_CHART,
    item: [
      'Description',
      'Address',
      'Officers',
      'Latitude',
      'Longitude',
      'Website',
      'Exchange',
      'Currency St',
    ],
    dataAction: COMPANY_INFO_DATA,
    stateLabel: 'companyInfoData',
    columns: [],
    datasetLabels: [],
  },
  {
    id: 'summary',
    type: ChartTypeEnum.SUMMARY,
    item: '',
    dataAction: COMPANY_SUMMARY_DATA,
    stateLabel: 'companySummaryData',
    columns: [],
    datasetLabels: [],
  },
  {
    id: 'company-summary-price',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Close adj',
    dataAction: COMPANY_SUMMARY_PRICE_DATA,
    stateLabel: 'priceData',
    isCurrency: true,
    columns: ['Date', 'Close adj'],
    datasetLabels: [{ label: 'Price' }],
  },
  TOP_PEERS_CONFIG,
];

/***************************************
 *             Title Labels            *
 ***************************************/
export const COMPANY_SUMMARY_TITLE_LABELS = {
  companyOverview: 'Company Overview',
};
