import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processStatementsData,
  processVisualData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  ACCOUNTS_RECEIVABLE_QUARTERLY_DATA,
  ACCOUNTS_RECEIVABLE_YEARLY_DATA,
  BALANCE_SHEET_ANALYSIS_UPDATE,
  BS_STATEMENTS_QUARTERLY_DATA,
  BS_STATEMENTS_YEARLY_DATA,
  CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA,
  CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA,
  CURRENT_ASSETS_QUARTERLY_DATA,
  CURRENT_ASSETS_YEARLY_DATA,
  CURRENT_LIABILITIES_QUARTERLY_DATA,
  CURRENT_LIABILITIES_YEARLY_DATA,
  DispatchActionTypes,
  EQUITY_TOTAL_EQUITY_QUARTERLY_DATA,
  EQUITY_TOTAL_EQUITY_YEARLY_DATA,
  INVENTORIES_QUARTERLY_DATA,
  INVENTORIES_YEARLY_DATA,
  IVisualsState,
  LONG_TERM_DEBT_QUARTERLY_DATA,
  LONG_TERM_DEBT_YEARLY_DATA,
  LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA,
  LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA,
  NONCURRENT_ASSETS_QUARTERLY_DATA,
  NONCURRENT_ASSETS_YEARLY_DATA,
  NONCURRENT_LIABILITIES_QUARTERLY_DATA,
  NONCURRENT_LIABILITIES_YEARLY_DATA,
  OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA,
  OTHER_LONG_TERM_ASSETS_YEARLY_DATA,
  OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA,
  OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA,
  OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA,
  OTHER_SHORT_TERM_ASSETS_YEARLY_DATA,
  OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA,
  OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA,
  PAYABLES_ACCRUALS_QUARTERLY_DATA,
  PAYABLES_ACCRUALS_YEARLY_DATA,
  PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA,
  PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA,
  RETAINED_EARNINGS_QUARTERLY_DATA,
  RETAINED_EARNINGS_YEARLY_DATA,
  SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA,
  SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA,
  SHORT_TERM_DEBT_QUARTERLY_DATA,
  SHORT_TERM_DEBT_YEARLY_DATA,
  TOTAL_ASSETS_QUARTERLY_DATA,
  TOTAL_ASSETS_YEARLY_DATA,
  TOTAL_EQUITY_QUARTERLY_DATA,
  TOTAL_EQUITY_YEARLY_DATA,
  TOTAL_LIABILITIES_QUARTERLY_DATA,
  TOTAL_LIABILITIES_YEARLY_DATA,
  TREASURY_STOCK_QUARTERLY_DATA,
  TREASURY_STOCK_YEARLY_DATA,
} from './visuals.model';

const CHART_ACTIONS_SET = new Set([BALANCE_SHEET_ANALYSIS_UPDATE]);

const initialState: IVisualsState = {};

const balanceSheetAnalysisVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case BALANCE_SHEET_ANALYSIS_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;

    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      /***************************************
       *             D-1 Overview            *
       ***************************************/
      case TOTAL_ASSETS_YEARLY_DATA:
        if (hashedData === state.totalAssetsData?.checksum) {
          return { ...state, totalAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalAssetsDataUpdating: false,
        };
      case TOTAL_ASSETS_QUARTERLY_DATA:
        if (hashedData === state.totalAssetsData?.checksum) {
          return { ...state, totalAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalAssetsDataUpdating: false,
        };
      case TOTAL_LIABILITIES_YEARLY_DATA:
        if (hashedData === state.totalLiabilitiesData?.checksum) {
          return { ...state, totalLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalLiabilitiesDataUpdating: false,
        };
      case TOTAL_LIABILITIES_QUARTERLY_DATA:
        if (hashedData === state.totalLiabilitiesData?.checksum) {
          return { ...state, totalLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalLiabilitiesDataUpdating: false,
        };
      case TOTAL_EQUITY_YEARLY_DATA:
        if (hashedData === state.totalEquityData?.checksum) {
          return { ...state, totalEquityDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalEquityData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalEquityDataUpdating: false,
        };
      case TOTAL_EQUITY_QUARTERLY_DATA:
        if (hashedData === state.totalEquityData?.checksum) {
          return { ...state, totalEquityDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          totalEquityData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          totalEquityDataUpdating: false,
        };

      /***************************************
       *  D-2 Current Assets and Liabilities *
       ***************************************/
      case CURRENT_ASSETS_YEARLY_DATA:
        if (hashedData === state.currentAssetsData?.checksum) {
          return { ...state, currentAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          currentAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentAssetsDataUpdating: false,
        };
      case CURRENT_ASSETS_QUARTERLY_DATA:
        if (hashedData === state.currentAssetsData?.checksum) {
          return { ...state, currentAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          currentAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentAssetsDataUpdating: false,
        };
      case CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA:
        if (hashedData === state.cashEqShortTermInvestmentsData?.checksum) {
          return { ...state, cashEqShortTermInvestmentsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashEqShortTermInvestmentsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashEqShortTermInvestmentsDataUpdating: false,
        };
      case CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA:
        if (hashedData === state.cashEqShortTermInvestmentsData?.checksum) {
          return { ...state, cashEqShortTermInvestmentsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashEqShortTermInvestmentsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashEqShortTermInvestmentsDataUpdating: false,
        };
      case ACCOUNTS_RECEIVABLE_YEARLY_DATA:
        if (hashedData === state.accountsReceivableData?.checksum) {
          return { ...state, accountsReceivableDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          accountsReceivableData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          accountsReceivableDataUpdating: false,
        };
      case ACCOUNTS_RECEIVABLE_QUARTERLY_DATA:
        if (hashedData === state.accountsReceivableData?.checksum) {
          return { ...state, accountsReceivableDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          accountsReceivableData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          accountsReceivableDataUpdating: false,
        };
      case INVENTORIES_YEARLY_DATA:
        if (hashedData === state.inventoriesData?.checksum) {
          return { ...state, inventoriesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          inventoriesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          inventoriesDataUpdating: false,
        };
      case INVENTORIES_QUARTERLY_DATA:
        if (hashedData === state.inventoriesData?.checksum) {
          return { ...state, inventoriesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          inventoriesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          inventoriesDataUpdating: false,
        };
      case OTHER_SHORT_TERM_ASSETS_YEARLY_DATA:
        if (hashedData === state.otherShortTermAssetsData?.checksum) {
          return { ...state, otherShortTermAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherShortTermAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherShortTermAssetsDataUpdating: false,
        };
      case OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA:
        if (hashedData === state.otherShortTermAssetsData?.checksum) {
          return { ...state, otherShortTermAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherShortTermAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherShortTermAssetsDataUpdating: false,
        };
      case CURRENT_LIABILITIES_YEARLY_DATA:
        if (hashedData === state.currentLiabilitiesData?.checksum) {
          return { ...state, currentLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          currentLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentLiabilitiesDataUpdating: false,
        };
      case CURRENT_LIABILITIES_QUARTERLY_DATA:
        if (hashedData === state.currentLiabilitiesData?.checksum) {
          return { ...state, currentLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          currentLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          currentLiabilitiesDataUpdating: false,
        };
      case PAYABLES_ACCRUALS_YEARLY_DATA:
        if (hashedData === state.payablesAccrualsData?.checksum) {
          return { ...state, payablesAccrualsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          payablesAccrualsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          payablesAccrualsDataUpdating: false,
        };
      case PAYABLES_ACCRUALS_QUARTERLY_DATA:
        if (hashedData === state.payablesAccrualsData?.checksum) {
          return { ...state, payablesAccrualsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          payablesAccrualsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          payablesAccrualsDataUpdating: false,
        };
      case SHORT_TERM_DEBT_YEARLY_DATA:
        if (hashedData === state.shortTermDebtData?.checksum) {
          return { ...state, shortTermDebtDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          shortTermDebtData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          shortTermDebtDataUpdating: false,
        };
      case SHORT_TERM_DEBT_QUARTERLY_DATA:
        if (hashedData === state.shortTermDebtData?.checksum) {
          return { ...state, shortTermDebtDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          shortTermDebtData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          shortTermDebtDataUpdating: false,
        };
      case OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA:
        if (hashedData === state.otherShortTermLiabilitiesData?.checksum) {
          return { ...state, otherShortTermLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherShortTermLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherShortTermLiabilitiesDataUpdating: false,
        };
      case OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA:
        if (hashedData === state.otherShortTermLiabilitiesData?.checksum) {
          return { ...state, otherShortTermLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherShortTermLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherShortTermLiabilitiesDataUpdating: false,
        };

      /***************************************
       *   D-3 Noncurrent Assets and Liabil  *
       ***************************************/
      case NONCURRENT_ASSETS_YEARLY_DATA:
        if (hashedData === state.nonCurrentAssetsData?.checksum) {
          return { ...state, nonCurrentAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          nonCurrentAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          nonCurrentAssetsDataUpdating: false,
        };
      case NONCURRENT_ASSETS_QUARTERLY_DATA:
        if (hashedData === state.nonCurrentAssetsData?.checksum) {
          return { ...state, nonCurrentAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          nonCurrentAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          nonCurrentAssetsDataUpdating: false,
        };
      case PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA:
        if (hashedData === state.propertyPlantEquipmentData?.checksum) {
          return { ...state, propertyPlantEquipmentDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          propertyPlantEquipmentData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          propertyPlantEquipmentDataUpdating: false,
        };
      case PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA:
        if (hashedData === state.propertyPlantEquipmentData?.checksum) {
          return { ...state, propertyPlantEquipmentDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          propertyPlantEquipmentData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          propertyPlantEquipmentDataUpdating: false,
        };
      case LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA:
        if (hashedData === state.longTermInvestmentsReceivablesData?.checksum) {
          return {
            ...state,
            longTermInvestmentsReceivablesDataUpdating: false,
          };
        }

        /* State Update */
        return {
          ...state,
          longTermInvestmentsReceivablesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          longTermInvestmentsReceivablesDataUpdating: false,
        };
      case LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA:
        if (hashedData === state.longTermInvestmentsReceivablesData?.checksum) {
          return {
            ...state,
            longTermInvestmentsReceivablesDataUpdating: false,
          };
        }

        /* State Update */
        return {
          ...state,
          longTermInvestmentsReceivablesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          longTermInvestmentsReceivablesDataUpdating: false,
        };
      case OTHER_LONG_TERM_ASSETS_YEARLY_DATA:
        if (hashedData === state.otherLongTermAssetsData?.checksum) {
          return { ...state, otherLongTermAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherLongTermAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherLongTermAssetsDataUpdating: false,
        };
      case OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA:
        if (hashedData === state.otherLongTermAssetsData?.checksum) {
          return { ...state, otherLongTermAssetsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherLongTermAssetsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherLongTermAssetsDataUpdating: false,
        };
      case NONCURRENT_LIABILITIES_YEARLY_DATA:
        if (hashedData === state.nonCurrentAssetsData?.checksum) {
          return { ...state, nonCurrentLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          nonCurrentLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          nonCurrentLiabilitiesDataUpdating: false,
        };
      case NONCURRENT_LIABILITIES_QUARTERLY_DATA:
        if (hashedData === state.nonCurrentAssetsData?.checksum) {
          return { ...state, nonCurrentLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          nonCurrentLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          nonCurrentLiabilitiesDataUpdating: false,
        };
      case LONG_TERM_DEBT_YEARLY_DATA:
        if (hashedData === state.longTermDebtData?.checksum) {
          return { ...state, longTermDebtDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          longTermDebtData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          longTermDebtDataUpdating: false,
        };
      case LONG_TERM_DEBT_QUARTERLY_DATA:
        if (hashedData === state.longTermDebtData?.checksum) {
          return { ...state, longTermDebtDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          longTermDebtData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          longTermDebtDataUpdating: false,
        };
      case OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA:
        if (hashedData === state.otherLongTermLiabilitiesData?.checksum) {
          return { ...state, otherLongTermLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherLongTermLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherLongTermLiabilitiesDataUpdating: false,
        };
      case OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA:
        if (hashedData === state.otherLongTermLiabilitiesData?.checksum) {
          return { ...state, otherLongTermLiabilitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          otherLongTermLiabilitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          otherLongTermLiabilitiesDataUpdating: false,
        };

      /***************************************
       *              D-4 Equity             *
       ***************************************/
      case EQUITY_TOTAL_EQUITY_YEARLY_DATA:
        if (hashedData === state.equityTotalEquityData?.checksum) {
          return { ...state, equityTotalEquityDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          equityTotalEquityData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          equityTotalEquityDataUpdating: false,
        };
      case EQUITY_TOTAL_EQUITY_QUARTERLY_DATA:
        if (hashedData === state.equityTotalEquityData?.checksum) {
          return { ...state, equityTotalEquityDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          equityTotalEquityData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          equityTotalEquityDataUpdating: false,
        };
      case SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA:
        if (hashedData === state.shareCapitalAdditionalPaidInData?.checksum) {
          return { ...state, shareCapitalAdditionalPaidInDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          shareCapitalAdditionalPaidInData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          shareCapitalAdditionalPaidInDataUpdating: false,
        };
      case SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA:
        if (hashedData === state.shareCapitalAdditionalPaidInData?.checksum) {
          return { ...state, shareCapitalAdditionalPaidInDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          shareCapitalAdditionalPaidInData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          shareCapitalAdditionalPaidInDataUpdating: false,
        };
      case RETAINED_EARNINGS_YEARLY_DATA:
        if (hashedData === state.retainedEarningsData?.checksum) {
          return { ...state, retainedEarningsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          retainedEarningsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          retainedEarningsDataUpdating: false,
        };
      case RETAINED_EARNINGS_QUARTERLY_DATA:
        if (hashedData === state.retainedEarningsData?.checksum) {
          return { ...state, retainedEarningsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          retainedEarningsData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          retainedEarningsDataUpdating: false,
        };
      case TREASURY_STOCK_YEARLY_DATA:
        if (hashedData === state.treasuryStockData?.checksum) {
          return { ...state, treasuryStockDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          treasuryStockData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          treasuryStockDataUpdating: false,
        };
      case TREASURY_STOCK_QUARTERLY_DATA:
        if (hashedData === state.treasuryStockData?.checksum) {
          return { ...state, treasuryStockDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          treasuryStockData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          treasuryStockDataUpdating: false,
        };
      case BS_STATEMENTS_YEARLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            // tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      case BS_STATEMENTS_QUARTERLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            // tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      default:
        return state;
    }
  }
};

export default balanceSheetAnalysisVisualsReducer;
