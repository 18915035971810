import { Public } from '@mui/icons-material';
import {
  autocompleteClasses,
  Box,
  Palette,
  SxProps,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { isNullUndefined } from '@prometeus/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PREMIUM_FEATURE_TOOLTIP } from '../../../constants/general.constants';
import { ISecIndCty } from '../../../models/screener/structure.model';
import { openActiveFreeTrialDialog } from '../../../store/actions/modals.action';
import { getAutocompleteStyles } from '../../../styles/autocomplete.styles';
import { useSelectStyles } from '../../../styles/select.styles';
import { COMMON_COLORS } from '../../../ui/colors';
import BrandFlame from '../../brand-flame.component';
import ClickAwayComponent, {
  NO_CLICK_AWAY_KEY,
} from '../../click-away.component';
import CustomBadge from '../../custom-badge.component';
import EnhancedTooltip from '../../enhanced-tooltip.component';
import AutocompleteRenderInputComponent from './autocomplete-render-input.component';

const getStyles = (palette: Palette) => ({
  listbox: {
    padding: '0rem !important',
  },
  elementContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottomColor: palette.divider,
    padding: '0.5rem',
  },
  restrictedElement: {
    cursor: 'default !important',
  },
  icon: {
    //  color: colors.icons,
  },
});

type Props = {
  label: string;
  placeholder: string;
  dataList: ISecIndCty[];
  sx?: SxProps;
  textFieldSx?: SxProps;
  onClickCheckHandler: (optionIndex: number) => void;
  onCloseHandler: () => void;
  limit?: number;
};

const CountrySelectorComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { dataList, limit } = props;
  const theme = useTheme();

  const styles = getStyles(theme.palette);
  const selectStyles = useSelectStyles();
  const autocompleteStyles = getAutocompleteStyles(theme);

  const [allIcon, setAllIcon] = useState<boolean>(false);
  const [upFocusEndAdornment, setUpFocusEndAdornment] =
    useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  /* Keep track if any element has been clicked while the menu is open */
  const [clicked, setClicked] = useState<boolean>(false);

  /* Limit */
  const [reachedLimit, setReachedLimit] = useState<boolean>(false);
  useEffect(() => {
    if (limit) {
      const selected: number = dataList.filter(
        (e: ISecIndCty) => e.selected
      ).length;
      setReachedLimit(selected === limit);
    }
  }, [dataList, limit]);

  const getOptions = (): ISecIndCty[] => {
    const filtered = dataList.filter((e: ISecIndCty) => e.shown);
    return filtered.length <= 1
      ? []
      : searchInputValue.length > 0
      ? filtered.slice(1)
      : filtered;
  };

  // 'All' option not-selected icon
  useEffect(() => {
    if (!limit) {
      const notEvery = dataList
        .slice(1)
        .filter((e: ISecIndCty) => e.shown)
        .every((e: ISecIndCty) => !e.selected);

      if (notEvery) {
        setAllIcon(false);
      } else {
        setAllIcon(true);
      }
    }
  }, [dataList, limit]);

  /***************************************
   *               Handlers              *
   ***************************************/
  const onCheckHandler = (option: ISecIndCty) => {
    if (!reachedLimit || option.selected) {
      // Works only if limit not reached yet or, in case, if the option has been already selected
      if (!clicked) {
        setClicked(true);
      }
      props.onClickCheckHandler(option.index);
    }
  };

  const [open, setOpen] = useState<boolean>(false);

  const onOpenHandler = () => {
    setClicked(false);
    setUpFocusEndAdornment(true);
  };

  const { onCloseHandler: propsCloseHandler } = props;
  const onCloseHandler = useCallback(() => {
    setUpFocusEndAdornment(false);
    if (clicked) {
      setClicked(false);
      propsCloseHandler();
    }
  }, [clicked, propsCloseHandler]);

  useEffect(() => {
    if (!open) {
      onCloseHandler();
    }
  }, [open, onCloseHandler]);

  const onInputChangeHandler = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: string
  ) => {
    event?.stopPropagation();
    if (reason === 'input') {
      setSearchInputValue(value);
    }
  };

  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <CustomBadge
      BadgeProps={{
        color: reachedLimit ? 'error' : 'primary',
      }}
      label={
        dataList
          .slice(!!limit ? 0 : 1)
          .filter((e: ISecIndCty) => e.shown && e.selected).length
      }
      hiddenLabel="Countries"
      show={isHover || open}
    >
      <ClickAwayComponent setOpen={setOpen}>
        <Autocomplete
          multiple
          disableListWrap
          disableCloseOnSelect
          open={open}
          // End Adornment
          onOpen={onOpenHandler}
          // Hover
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          // Styling
          componentsProps={{
            paper: {
              sx: {
                ...autocompleteStyles.paper,
                ...selectStyles.menuPaper,
                '& li': {
                  padding: '0 !important',
                  '&.MuiFocused, &[aria-selected="true"]': {
                    backgroundColor: 'transparent',
                  },
                },
              },
            },
          }}
          sx={{
            width: '100%',
            margin: '1rem 0rem',
            ...autocompleteStyles.countrySectorRoot,
            ...props.sx,
            [`& .${autocompleteClasses.noOptions}`]:
              autocompleteStyles.noOptions,
            [`& .${autocompleteClasses.paper}`]: {
              ...autocompleteStyles.paper,
              ...selectStyles.menuPaper,
            },
          }}
          // Listbox
          ListboxProps={{
            id: `${NO_CLICK_AWAY_KEY}-CNT-ul`,
            style: autocompleteStyles.listbox,
          }}
          // Group
          groupBy={(option: ISecIndCty) => option.group || ''}
          // Options
          options={getOptions()}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option: ISecIndCty, value: ISecIndCty) =>
            option.label === value.label
          }
          renderOption={(props, option: ISecIndCty, { selected }) => {
            const noOptionFlag = option.index >= 0;
            const isAll = isNullUndefined(limit) && option.index === 0;
            const isLast = option.index === getOptions().length;
            const disabled = !!option.disabled;
            const isIndeterminate = isAll && !option.selected ? allIcon : false;
            const blueCheckbox = !!option.selected || isIndeterminate;
            const opacity = disabled ? '0.38' : '1';

            const clickHandler = noOptionFlag
              ? (event: any) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onCheckHandler(option);
                }
              : () => {};

            const inner = (
              <Box
                id={`${NO_CLICK_AWAY_KEY}-${option.index}-CNT`}
                key={`${NO_CLICK_AWAY_KEY}-${option.index}-CNT`}
                onClick={clickHandler}
                sx={{
                  ...autocompleteStyles.option,
                  // padding: '0rem !important',
                  borderBottomStyle: !isLast ? 'solid' : 'none',
                  borderBottomWidth: !isLast ? '0.05rem' : '0rem',
                  ...styles.elementContainer,
                  ...(reachedLimit && !option.selected
                    ? styles.restrictedElement
                    : {
                        cursor: 'pointer',
                      }),

                  '& .MuiCheckbox-root': {
                    color: `${
                      blueCheckbox
                        ? theme.palette.primary.main
                        : theme.palette.text.primary
                    } !important`,
                    opacity: opacity,
                    cursor: disabled ? 'default' : 'pointer',
                  },
                  '&:hover': disabled
                    ? {
                        backgroundColor: 'transparent',
                      }
                    : {
                        backgroundColor: theme.palette.primary.main,
                        color: COMMON_COLORS.contrastPrimaryText,

                        '& .MuiCheckbox-root': {
                          color: `${COMMON_COLORS.contrastPrimaryText} !important`,
                        },
                      },
                }}
              >
                {noOptionFlag && (
                  <Checkbox
                    id={`${NO_CLICK_AWAY_KEY}-${option.index}-CNT-Checkbox`}
                    color="primary"
                    indeterminate={isIndeterminate}
                    style={{ marginRight: '0.5rem', marginBottom: '0.25rem' }}
                    checked={option.selected}
                    sx={{
                      ...(reachedLimit && !option.selected
                        ? styles.restrictedElement
                        : {}),
                      color: 'red',
                    }}
                    disabled={disabled}
                  />
                )}
                <span
                  id={`${NO_CLICK_AWAY_KEY}-${option.index}-CNT-span`}
                  style={{ opacity: opacity }}
                >
                  {option.label}
                </span>
                {disabled && <BrandFlame sx={selectStyles.brandFlame} />}
              </Box>
            );

            return disabled ? (
              <EnhancedTooltip
                title={PREMIUM_FEATURE_TOOLTIP}
                onInternalClick={() => {
                  dispatch(openActiveFreeTrialDialog());
                }}
              >
                {inner}
              </EnhancedTooltip>
            ) : (
              inner
            );
          }}
          // Input
          inputValue={searchInputValue}
          onChange={(event: React.ChangeEvent<{}>) => event.stopPropagation()}
          onInputChange={onInputChangeHandler}
          renderInput={AutocompleteRenderInputComponent({
            label: props.label,
            textFieldStyle: {
              fieldset: {
                border: `solid 0.05rem ${theme.palette.divider} !important`,
                borderRadius: '2rem',
              },
              ...props.textFieldSx,
            },
            placeholder: props.placeholder,
            searchInputValue: searchInputValue,
            setSearchInputValue: setSearchInputValue,
            upFocusEndAdornment: upFocusEndAdornment,
            icon: <Public sx={styles.icon} />,
            onClick: () => {
              // Open the autocomplete menu
              setOpen(!open);
            },
            hasFiltersType: 'modal',
          })}
        />
      </ClickAwayComponent>
    </CustomBadge>
  );
};

export default CountrySelectorComponent;
