import { ICompanyFirms, ICompanyMinimal, ISectorData } from '@prometeus/common';
import { Map } from 'immutable';
import { ISectorsPayload } from '../constants/companies.constants';
import { ISecIndCty, ISectorIndustry } from './screener/structure.model';

export enum SectorDataFieldsEnum {
  sector = 'sector',
  industry = 'industry',
  countryId = 'countryId',
  countryName = 'countryName',
}

export interface IScoredCompany {
  companyName: string;
  tickerId: string;
  overallScore: number;
}

export interface IPeerListData {
  revenue: number;
  marketCap: number;
  country: string;
}

export interface ITickerIdRevenueCap extends IPeerListData {
  tickerId: string;
}

/***************************************
 *                 State               *
 ***************************************/

export interface ICompaniesState {
  companies: ICompanyMinimal[];
  lastSearchCompanyKeyword?: string;

  currentCompany?: ICompanyFirms;
  loading: boolean;
  loadingSectorsData: boolean;
  loadingSearchCompanies: boolean;
  error?: string;

  sectorData?: ISectorData[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
  selectedCountries: ISecIndCty[];

  loadingFreeCompanies: boolean;
  mostViewedCompanies: ICompanyMinimal[];

  peerListDataMap: Map<string, IPeerListData>;
  loadingRevenueCap: boolean;
}

/***************************************
 *                 Redux               *
 ***************************************/

/* SEARCH_COMPANIES */
export const SEARCH_COMPANIES = 'SEARCH_COMPANIES';
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
export const SEARCH_COMPANIES_FAIL = 'SEARCH_COMPANIES_FAIL';

export interface IFetchCompanies {
  type: typeof SEARCH_COMPANIES;
  payload: string;
}

export interface IFetchCompaniesFail {
  type: typeof SEARCH_COMPANIES_FAIL;
  payload: string;
}

export interface IFetchCompaniesSuccess {
  type: typeof SEARCH_COMPANIES_SUCCESS;
  payload: { companies: ICompanyMinimal[]; keyword: string };
}

export type FetchCompaniesDispachTypes =
  | IFetchCompanies
  | IFetchCompaniesFail
  | IFetchCompaniesSuccess;

/* SET_COMPANIES_DATA */
export const SET_COMPANIES_DATA = 'SET_COMPANIES_DATA';
export interface ISetCompaniesData {
  type: typeof SET_COMPANIES_DATA;
  payload: ICompanyMinimal[];
}

/* FETCH_COMPANY */
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export interface FetchCompanyI {
  type: typeof FETCH_COMPANY;
}

export interface FetchCompanyFailI {
  type: typeof FETCH_COMPANY_FAIL;
  payload: string;
}

export interface FetchCompanySuccessI {
  type: typeof FETCH_COMPANY_SUCCESS;
  payload: ICompanyFirms;
}

export type FetchCompanyDispachTypes =
  | FetchCompanyI
  | FetchCompanyFailI
  | FetchCompanySuccessI;

/* COMPANIES_SEARCH_SELECT_SECTORS */
export const COMPANIES_SEARCH_SELECT_SECTORS =
  'COMPANIES_SEARCH_SELECT_SECTORS';
export interface ICompaniesSearchSelectSectors {
  type: typeof COMPANIES_SEARCH_SELECT_SECTORS;
  payload: number;
}

/* COMPANIES_SEARCH_SELECT_INDUSTRIES */
export const COMPANIES_SEARCH_SELECT_INDUSTRIES =
  'COMPANIES_SEARCH_SELECT_INDUSTRIES';
export interface ICompaniesSearchSelectIndustries {
  type: typeof COMPANIES_SEARCH_SELECT_INDUSTRIES;
  payload: {
    sector: number;
    industry: number;
  };
}

/* COMPANIES_SEARCH_SELECT_COUNTRY_IDS */
export const COMPANIES_SEARCH_SELECT_COUNTRY_IDS =
  'COMPANIES_SEARCH_SELECT_COUNTRY_IDS';
export interface ICompaniesSearchSelectCountryIds {
  type: typeof COMPANIES_SEARCH_SELECT_COUNTRY_IDS;
  payload: number;
}

/* COMPANIES_SEARCH_SET_SECTOR_DATA */
export const COMPANIES_SEARCH_SET_SECTOR_DATA =
  'COMPANIES_SEARCH_SET_SECTOR_DATA';
export interface ICompaniesSearchSetSectorData {
  type: typeof COMPANIES_SEARCH_SET_SECTOR_DATA;
}

/* COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS */
export const COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS =
  'COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS';
export interface ICompaniesSearchSetSectorDataSuccess {
  type: typeof COMPANIES_SEARCH_SET_SECTOR_DATA_SUCCESS;
  payload: ISectorsPayload;
}

/* COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL */
export const COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL =
  'COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL';
export interface ICompaniesSearchSetSectorDataFail {
  type: typeof COMPANIES_SEARCH_SET_SECTOR_DATA_FAIL;
  payload: string;
}

export type CompaniesSearchSetSectorDataDispatchTypes =
  | ICompaniesSearchSetSectorData
  | ICompaniesSearchSetSectorDataSuccess
  | ICompaniesSearchSetSectorDataFail;

/* FETCH_PEER_LIST_DATA */
export const FETCH_PEER_LIST_DATA = 'FETCH_PEER_LIST_DATA';
export interface IFetchPeerListData {
  type: typeof FETCH_PEER_LIST_DATA;
}

/* FETCH_PEER_LIST_DATA_SUCCESS */
export const FETCH_PEER_LIST_DATA_SUCCESS = 'FETCH_PEER_LIST_DATA_SUCCESS';
export interface IFetchPeerListDataSuccess {
  type: typeof FETCH_PEER_LIST_DATA_SUCCESS;
  payload: ITickerIdRevenueCap[];
}

/* FETCH_PEER_LIST_DATA_FAIL */
export const FETCH_PEER_LIST_DATA_FAIL = 'FETCH_PEER_LIST_DATA_FAIL';
export interface IFetchPeerListDataFail {
  type: typeof FETCH_PEER_LIST_DATA_FAIL;
  payload: string;
}

export type FetchPeerListDataDispatchTypes =
  | IFetchPeerListData
  | IFetchPeerListDataSuccess
  | IFetchPeerListDataFail;

/* GET_MOST_VIEWED_COMPANIES */
export const GET_MOST_VIEWED_COMPANIES = 'GET_MOST_VIEWED_COMPANIES';
export interface IGetMostViewedCompanies {
  type: typeof GET_MOST_VIEWED_COMPANIES;
}

/* GET_MOST_VIEWED_COMPANIES_SUCCESS */
export const GET_MOST_VIEWED_COMPANIES_SUCCESS =
  'GET_MOST_VIEWED_COMPANIES_SUCCESS';
export interface IGetMostViewedCompaniesSuccess {
  type: typeof GET_MOST_VIEWED_COMPANIES_SUCCESS;
  payload: ICompanyMinimal[];
}

/* GET_MOST_VIEWED_COMPANIES_FAIL */
export const GET_MOST_VIEWED_COMPANIES_FAIL = 'GET_MOST_VIEWED_COMPANIES_FAIL';
export interface IGetMostViewedCompaniesFail {
  type: typeof GET_MOST_VIEWED_COMPANIES_FAIL;
  payload: string;
}

export type GetMostViewedCompaniesDispatchTypes =
  | IGetMostViewedCompanies
  | IGetMostViewedCompaniesSuccess
  | IGetMostViewedCompaniesFail;

/* Final Dispatch Type */

export type DispatchActionTypes =
  | FetchCompaniesDispachTypes
  | FetchCompanyDispachTypes
  | ICompaniesSearchSelectCountryIds
  | ICompaniesSearchSelectSectors
  | ICompaniesSearchSelectIndustries
  | CompaniesSearchSetSectorDataDispatchTypes
  | FetchPeerListDataDispatchTypes
  | GetMostViewedCompaniesDispatchTypes
  | ISetCompaniesData;
