import { MD5 } from 'object-hash';
import { Dispatch } from 'redux';
import {
  MAX_FISCAL_YEAR,
  MIN_FISCAL_YEAR,
} from '../../constants/general.constants';
import {
  DWMQFilter,
  DispatchActionTypes,
  FETCH_CHART_DATA,
  FETCH_CHART_DATA_FAIL,
  FETCH_CHART_DATA_SUCCESS,
  IFiltersState,
  PageIdEnum,
  QYFilter,
  RenderTypeEnum,
  SET_DWMQ_FILTER,
  SET_FISCAL_YEAR,
  SET_PAGE,
  SET_PEERS,
  SET_PEER_GROUP,
  SET_QY_FILTER,
  SET_TICKER_ID,
  SET_TOKEN_FILTERS,
  SET_USE_DOLLARS,
  TimeframeEnum,
  TimeframeFilter,
} from '../../models/filters.model';
import { PeerGroupEnum } from '../../models/peer-group.model';
import { ChartTypeEnum, IVisualConfig } from '../../models/visuals.model';
import * as BalanceSheetAnalysisConfig from '../../pages/balance-sheet-analysis/utils/visuals.config';
import { BALANCE_SHEET_ANALYSIS_UPDATE } from '../../pages/balance-sheet-analysis/utils/visuals.model';
import * as CashflowAnalysisConfig from '../../pages/cash-flow-analysis/utils/visuals.config';
import { CASH_FLOW_ANALYSIS_UPDATE } from '../../pages/cash-flow-analysis/utils/visuals.model';
import * as CompanySummaryConfig from '../../pages/company-summary/utils/visuals.config';
import { COMPANY_SUMMARY_UPDATE } from '../../pages/company-summary/utils/visuals.model';
import * as FinancialStrengthConfig from '../../pages/financial-strength/utils/visuals.config';
import { FINANCIAL_STRENGTH_UPDATE } from '../../pages/financial-strength/utils/visuals.model';
import * as IncomeStatementAnalysisConfig from '../../pages/income-statement-analysis/utils/visuals.config';
import { INCOME_STATEMENT_ANALYSIS_UPDATE } from '../../pages/income-statement-analysis/utils/visuals.model';
import * as ManagementEffectivenessConfig from '../../pages/management-effectiveness/utils/visuals.config';
import { MANAGEMENT_EFFECTIVENESS_UPDATE } from '../../pages/management-effectiveness/utils/visuals.model';
import * as StockAnalysisConfig from '../../pages/stock-analysis/utils/visuals.config';
import { STOCK_ANALYSIS_UPDATE } from '../../pages/stock-analysis/utils/visuals.model';
import { fetchChartData } from '../actions/filters.action';

const initialState: IFiltersState = {
  loading: false,

  useDollars: false,
  fiscalYear: [MIN_FISCAL_YEAR, MAX_FISCAL_YEAR], // FIXME: Make years dynamic
  activeQYFilter: TimeframeEnum.YEARLY,
  activeDWMQFilter: TimeframeEnum.MONTHLY,
  peerGroup: PeerGroupEnum.INTERNATIONAL,
  peers: [],
};

const filtersReducer = (
  state: IFiltersState = initialState,
  action: DispatchActionTypes
): IFiltersState => {
  switch (action.type) {
    case SET_USE_DOLLARS:
      return {
        ...state,
        useDollars: action.payload,
      };

    case SET_PAGE:
      if (state.currentPage === action.payload) {
        return state;
      }

      /* UPDATE new page */
      const { config: configSetPage, type: typeSetPage } = makeConfigList(
        action.payload,
        RenderTypeEnum.FILTER_PAGE,
        state.activeQYFilter
      );

      dispatchUpdateCharts(
        action.asyncDispatch,
        configSetPage,
        typeSetPage,
        state.tickerId,
        state.fiscalYear,
        getActiveTimeframeFilter(
          action.payload,
          state.activeDWMQFilter,
          state.activeQYFilter
        ),
        state.peerGroup,
        state.peers
      );

      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_TICKER_ID:
      if (!state.currentPage || state.tickerId === action.payload.tickerId) {
        return state;
      }

      if (!action.payload.skipFetch) {
        const { config: configSetTickerId, type: typeSetTickerId } =
          makeConfigList(
            state.currentPage,
            RenderTypeEnum.FILTER_PAGE,
            state.activeQYFilter
          );

        dispatchUpdateCharts(
          action.asyncDispatch,
          [
            ...configSetTickerId,
            ...(state.peerGroup === PeerGroupEnum.SUGGESTED
              ? [CompanySummaryConfig.TOP_PEERS_CONFIG]
              : []),
          ],
          typeSetTickerId,
          action.payload.tickerId, // TickerId
          state.fiscalYear,
          getActiveTimeframeFilter(
            state.currentPage,
            state.activeDWMQFilter,
            state.activeQYFilter
          ),
          state.peerGroup,
          state.peers
        );
      }

      return {
        ...state,
        tickerId: action.payload.tickerId,
      };

    case SET_PEER_GROUP:
      if (!state.currentPage || state.peerGroup === action.payload) {
        return state;
      }

      if (action.payload === PeerGroupEnum.SUGGESTED) {
        const { config: configPeerGroup, type: typePeerGroup } = makeConfigList(
          state.currentPage,
          RenderTypeEnum.FILTER_PAGE,
          state.activeQYFilter
        );

        dispatchUpdateCharts(
          action.asyncDispatch,
          [...configPeerGroup, CompanySummaryConfig.TOP_PEERS_CONFIG],
          typePeerGroup,
          state.tickerId,
          state.fiscalYear,
          getActiveTimeframeFilter(
            state.currentPage,
            state.activeDWMQFilter,
            state.activeQYFilter
          ),
          action.payload,
          []
        );
      }

      return {
        ...state,
        peerGroup: action.payload,
      };

    case SET_PEERS:
      const statePeers = [...(state.peers || [])];
      const allowFetch = action.payload.allowFetch;

      // Sort peers otherwise they cannot be compared using MD5
      statePeers?.sort();
      action.payload.peers?.sort();

      if (
        !state.currentPage ||
        (!allowFetch &&
          MD5(state.peers || []) === MD5(action.payload.peers || []))
      ) {
        return state;
      }

      const { config: configSetPeers, type: typeSetPeers } = makeConfigList(
        state.currentPage,
        RenderTypeEnum.FILTER_PAGE,
        state.activeQYFilter
      );

      dispatchUpdateCharts(
        action.asyncDispatch,
        configSetPeers,
        typeSetPeers,
        state.tickerId,
        state.fiscalYear,
        getActiveTimeframeFilter(
          state.currentPage,
          state.activeDWMQFilter,
          state.activeQYFilter
        ),
        state.peerGroup,
        action.payload.peers // peers
      );

      return {
        ...state,
        peers: action.payload.peers,
      };

    case SET_FISCAL_YEAR:
      if (!state.currentPage || MD5(state.fiscalYear) === MD5(action.payload)) {
        return state;
      }

      const { config: configSetFiscalYear, type: typeSetFiscalYear } =
        makeConfigList(
          state.currentPage,
          RenderTypeEnum.FILTER_PAGE,
          state.activeQYFilter
        );

      const chartTypesOfInterest = [ChartTypeEnum.DISTR_CHART];
      if (action.payload[1] !== state.fiscalYear[1]) {
        chartTypesOfInterest.push(ChartTypeEnum.RANK_CHART);
      }
      const distrConfig = configSetFiscalYear.filter((e: IVisualConfig) =>
        chartTypesOfInterest.includes(e.type)
      );
      if (distrConfig.length > 0) {
        dispatchUpdateCharts(
          action.asyncDispatch,
          distrConfig,
          typeSetFiscalYear,
          state.tickerId,
          action.payload,
          getActiveTimeframeFilter(
            state.currentPage,
            state.activeDWMQFilter,
            state.activeQYFilter
          ),
          state.peerGroup,
          state.peers
        );
      }
      return {
        ...state,
        fiscalYear: action.payload,
      };

    case SET_QY_FILTER:
      if (!state.currentPage || state.activeQYFilter === action.payload) {
        return state;
      }

      const newQYFilter = action.payload;
      const { config: configQY, type: typeQY } = makeConfigList(
        state.currentPage,
        RenderTypeEnum.TIMEFRAME_FILTER,
        newQYFilter
      );

      dispatchUpdateCharts(
        action.asyncDispatch,
        configQY,
        typeQY,
        state.tickerId,
        state.fiscalYear,
        getActiveTimeframeFilter(
          state.currentPage,
          state.activeDWMQFilter,
          newQYFilter
        ),
        state.peerGroup,
        state.peers
      );

      return {
        ...state,
        activeQYFilter: newQYFilter,
      };

    case SET_DWMQ_FILTER:
      if (!state.currentPage || state.activeDWMQFilter === action.payload) {
        return state;
      }

      const newDWMQFilter = action.payload;

      const { config: configDWMQ, type: typeDWMQ } = makeConfigList(
        state.currentPage,
        RenderTypeEnum.TIMEFRAME_FILTER,
        state.activeQYFilter
      );

      dispatchUpdateCharts(
        action.asyncDispatch,
        configDWMQ,
        typeDWMQ,
        state.tickerId,
        state.fiscalYear,
        getActiveTimeframeFilter(
          state.currentPage,
          newDWMQFilter,
          state.activeQYFilter
        ),
        state.peerGroup,
        state.peers
      );

      return {
        ...state,
        activeDWMQFilter: newDWMQFilter,
      };

    case SET_TOKEN_FILTERS:
      const currentPageSetTokenFilters =
        state.currentPage || PageIdEnum.COMPANY_SUMMARY; // default PageIdEnum could be troublesome
      const { config: configSetTokenFilters, type: typeSetTokenFilters } =
        makeConfigList(
          currentPageSetTokenFilters,
          RenderTypeEnum.FILTER_PAGE,
          state.activeQYFilter
        );

      dispatchUpdateCharts(
        action.asyncDispatch,
        configSetTokenFilters,
        typeSetTokenFilters,
        state.tickerId,
        state.fiscalYear,
        getActiveTimeframeFilter(
          currentPageSetTokenFilters,
          state.activeDWMQFilter,
          state.activeQYFilter
        ),
        state.peerGroup,
        state.peers
      );

      return {
        ...state,
      };

    case FETCH_CHART_DATA:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FETCH_CHART_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default filtersReducer;

const getActiveTimeframeFilter = (
  currentPage: PageIdEnum,
  activeDWMQFilter: DWMQFilter,
  activeQYFilter: QYFilter
): TimeframeFilter =>
  [
    PageIdEnum.COMPANY_SUMMARY,
    PageIdEnum.COMPANY_STOCKS,
    PageIdEnum.COMPANY_DIVIDENDS,
  ].includes(currentPage)
    ? activeDWMQFilter
    : activeQYFilter;

const makeConfigList = (
  currentPage: PageIdEnum,
  renderType: RenderTypeEnum,
  activeQYFilter: QYFilter
): { config: IVisualConfig[]; type: string } => {
  let config: IVisualConfig[] = [];
  let type = '';
  switch (currentPage) {
    case PageIdEnum.COMPANY_SUMMARY:
      config = CompanySummaryConfig.COMPANY_SUMMARY_CONFIG;
      type = COMPANY_SUMMARY_UPDATE;
      break;
    case PageIdEnum.COMPANY_STOCKS:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? StockAnalysisConfig.COMPANY_STOCKS_CONFIG
          : []),
        ...StockAnalysisConfig.COMPANY_STOCK_DWMQ_CONFIG,
      ];
      type = STOCK_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.COMPANY_DIVIDENDS:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? StockAnalysisConfig.COMPANY_DIVIDENDS_CONFIG
          : []),
        ...StockAnalysisConfig.COMPANY_DIVIDENDS_DWMQ_CONFIG,
      ];
      type = STOCK_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.REVEUE_AND_PROFIT:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? IncomeStatementAnalysisConfig.REVENUE_AND_PROFIT_CONFIG
          : []),
        ...(IncomeStatementAnalysisConfig.REVENUE_AND_PROFIT_CONFIG_AGGREGATED[
          activeQYFilter
        ] || []),
      ];
      type = INCOME_STATEMENT_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.PROFIT_MARGIN_AND_GROSS_MARGIN:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? IncomeStatementAnalysisConfig.PROFIT_MARGIN_AND_GROSS_MARGIN_CONFIG
          : []),
        ...(IncomeStatementAnalysisConfig
          .PROFIT_MARGIN_AND_GROSS_MARGIN_CONFIG_AGGREGATED[activeQYFilter] ||
          []),
      ];
      type = INCOME_STATEMENT_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.OPERATING_EXPENSES:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? IncomeStatementAnalysisConfig.OPERATING_EXPENSES_CONFIG
          : []),
        ...(IncomeStatementAnalysisConfig.OPERATING_EXPENSES_CONFIG_AGGREGATED[
          activeQYFilter
        ] || []),
      ];
      type = INCOME_STATEMENT_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.IS_STATEMENT:
      config =
        IncomeStatementAnalysisConfig.IS_STATEMENTS_CONFIG_AGGREGATED[
        activeQYFilter
        ] || [];
      type = INCOME_STATEMENT_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.BS_OVERVIEW:
      config =
        BalanceSheetAnalysisConfig.OVERVIEW_CONFIG_AGGREGATED[activeQYFilter] ||
        [];
      type = BALANCE_SHEET_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.CURRENT_ASSETS_AND_LIABILITIES:
      config =
        BalanceSheetAnalysisConfig.CURRENT_ASSETS_LIABILITIES_CONFIG_AGGREGATED[
        activeQYFilter
        ] || [];
      type = BALANCE_SHEET_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.NONCURRENT_ASSETS_AND_LIABILITIES:
      config =
        BalanceSheetAnalysisConfig
          .NONCURRENT_ASSETS_LIABILITIES_CONFIG_AGGREGATED[activeQYFilter] ||
        [];
      type = BALANCE_SHEET_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.EQUITY:
      config =
        BalanceSheetAnalysisConfig.EQUITY_CONFIG_AGGREGATED[activeQYFilter] ||
        [];
      type = BALANCE_SHEET_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.BS_STATEMENT:
      config =
        BalanceSheetAnalysisConfig.BS_STATEMENTS_CONFIG_AGGREGATED[
        activeQYFilter
        ] || [];
      type = BALANCE_SHEET_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.CASH_FLOW:
      config =
        CashflowAnalysisConfig.CASH_FLOW_CONFIG_AGGREGATED[activeQYFilter] ||
        [];
      type = CASH_FLOW_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.CF_STATEMENT:
      config =
        CashflowAnalysisConfig.CF_STATEMENTS_CONFIG_AGGREGATED[
        activeQYFilter
        ] || [];
      type = CASH_FLOW_ANALYSIS_UPDATE;
      break;
    case PageIdEnum.ROS_ROE:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? ManagementEffectivenessConfig.ROS_AND_ROE_CONFIG
          : []),
        ...(ManagementEffectivenessConfig.ROS_AND_ROE_CONFIG_AGGREGATED[
          activeQYFilter
        ] || []),
      ];
      type = MANAGEMENT_EFFECTIVENESS_UPDATE;
      break;
    case PageIdEnum.ROA_ROIC:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? ManagementEffectivenessConfig.ROA_AND_ROIC_CONFIG
          : []),
        ...(ManagementEffectivenessConfig.ROA_AND_ROIC_CONFIG_AGGREGATED[
          activeQYFilter
        ] || []),
      ];
      type = MANAGEMENT_EFFECTIVENESS_UPDATE;
      break;
    case PageIdEnum.CURRENT_QUICK_RATIO_CAPEX:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? FinancialStrengthConfig.CURRENT_AND_QUICK_RATIO_AND_CAPEX_CONFIG
          : []),
        ...(FinancialStrengthConfig
          .CURRENT_AND_QUICK_RATIO_AND_CAPEX_CONFIG_AGGREGATED[
          activeQYFilter
        ] || []),
      ];
      type = FINANCIAL_STRENGTH_UPDATE;
      break;
    case PageIdEnum.DEBT_TO_EQUITY_DEBT_PAYBACK:
      config = [
        ...(renderType === RenderTypeEnum.FILTER_PAGE
          ? FinancialStrengthConfig.DEBT_TO_EQUITY_AND_DEBT_PAYBACK_CONFIG
          : []),
        ...(FinancialStrengthConfig
          .DEBT_TO_EQUITY_AND_DEBT_PAYBACK_CONFIG_AGGREGATED[activeQYFilter] ||
          []),
      ];
      type = FINANCIAL_STRENGTH_UPDATE;
      break;
    default:
      break;
  }
  return { config, type };
};

const dispatchUpdateCharts = (
  dispatch: Dispatch<any>,
  config: IVisualConfig[],
  updateType: string,
  tickerId: string | undefined,
  years: [number, number],
  timeframe: TimeframeFilter,
  peerGroup: PeerGroupEnum,
  peers: string[]
): void => {
  if (!!tickerId) {
    // Dispatch update
    dispatch({
      type: updateType,
      payload: config.map((e: IVisualConfig) => e.stateLabel),
    });

    // Fetch data
    dispatch(
      fetchChartData(
        config,
        tickerId,
        years,
        timeframe,
        peerGroup,
        peerGroup === PeerGroupEnum.CUSTOM ? peers : []
      )
    );
  }
};
