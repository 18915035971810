import {
  Box,
  Pagination,
  paginationClasses,
  Palette,
  useTheme,
} from '@mui/material';
import { isNullUndefined } from '@prometeus/common';
import { useDispatch } from 'react-redux';
import { ROWS_PER_PAGE_LIST } from '../../constants/general.constants';
import { COMMON_COLORS } from '../../ui/colors';
import { formBorder, pxToRem } from '../../ui/functions';
import CustomSelectComponent from '../select.component';

type PaginationProps = {
  page: number;
  setPage: (value: number) => any;
  rowsPerPage: number;
  setRowsPerPage: (value: number) => any;
  dataLength: number;
  hideChangeRowsPerPage?: boolean;
  paginationItemSize?: 'small' | 'medium' | 'large';
  siblingCount?: number;
  boundaryCount?: number;
  dispatch?: boolean;
  notShowRows?: boolean;
  disabled?: boolean;
};

const getStyles = (palette: Palette) => ({
  paginationRoot: {
    color: palette.text.primary,
  },
  paginationUl: {
    '& button': {
      color: palette.text.primary,
      borderRadius: '0.8rem',
    },
    '& .MuiPaginationItem-root': {
      height: pxToRem(32),
      minWidth: pxToRem(32),
      margin: `0 ${pxToRem(3)}`,
      padding: `0 ${pxToRem(6)}`,

      '& .MuiPaginationItem-icon': {
        margin: `0 ${pxToRem(-8)}`,
      },
    },
    '& .MuiPaginationItem-circulared': {
      borderRadius: '0.8rem',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.text.primary,
    padding: '1rem',
    borderTop: formBorder(palette),
    backgroundColor: palette.background.default,
    borderRadius: '0 0 0.5rem 0.5rem',
  },
  paginationSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  marginRight: { marginRight: '0.8rem' },
  select: {
    backgroundColor: `${COMMON_COLORS.mask} !important`,
    minWidth: '3rem !important',
    height: '3rem !important',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      tranform: `translate(${pxToRem(14)}, ${pxToRem(-6)}) scale(0.75)`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: pxToRem(4),
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: pxToRem(32),
    },
    '& .MuiSelect-outlined': {
      borderRadius: pxToRem(4),
    },
    '& .MuiSelect-select': {
      minWidth: pxToRem(16),
    },
    '& .MuiOutlinedInput-input': {
      padding: `0.85rem ${pxToRem(14)}`,
    },
    '& .MuiSelect-iconOutlined': {
      right: `${pxToRem(7)} !important`,
    },
    '& .MuiSelect-icon': {
      top: `calc(50% - ${pxToRem(12)})`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: `${pxToRem(-5)} !important`,
      padding: `0 ${pxToRem(8)} !important`,
      borderWidth: pxToRem(0),
      display: 'none !important',
    },
  },
});

const TablePaginationComponent = (props: PaginationProps) => {
  const theme = useTheme();

  const paginationStyles = getStyles(theme.palette);
  const dispatch = useDispatch();

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    dataLength,
    paginationItemSize,
    siblingCount,
    boundaryCount,
  } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    if (!!props.dispatch) {
      dispatch(setPage(newPage));
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (value: string) => {
    if (!!props.dispatch) {
      dispatch(setRowsPerPage(parseInt(value as string, 10)));
      dispatch(setPage(0));
    } else {
      setRowsPerPage(parseInt(value as string, 10));
      setPage(0);
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

  return (
    <Box sx={paginationStyles.container}>
      <div style={{ fontSize: '0.9rem' }}>
        {dataLength
          ? `${!props.notShowRows ? 'Showing ' : ''}${
              page * rowsPerPage + 1
            } - ${
              page * rowsPerPage + rowsPerPage - emptyRows
            } out of ${dataLength}`
          : ''}
      </div>
      <Pagination
        sx={{
          ...paginationStyles.paginationRoot,
          [`& .${paginationClasses.ul}`]: paginationStyles.paginationUl,
        }}
        color="primary"
        size={paginationItemSize || 'medium'}
        shape="rounded"
        page={page + 1}
        defaultPage={0}
        count={Math.ceil(dataLength / rowsPerPage)}
        siblingCount={isNullUndefined(siblingCount) ? 1 : siblingCount}
        boundaryCount={isNullUndefined(boundaryCount) ? 1 : boundaryCount}
        onChange={(event: unknown, newPage: number) => {
          handleChangePage(event, newPage - 1);
        }}
        disabled={!!props.disabled}
      />
      {props.hideChangeRowsPerPage ? (
        <></>
      ) : (
        <Box sx={paginationStyles.paginationSelectContainer}>
          {!props.notShowRows && (
            <Box sx={paginationStyles.marginRight}>Show rows</Box>
          )}
          <CustomSelectComponent
            options={ROWS_PER_PAGE_LIST}
            option={String(rowsPerPage)}
            boxLabel=""
            setOption={handleChangeRowsPerPage}
            formControlSx={paginationStyles.select}
            disabled={!!props.disabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default TablePaginationComponent;
