import { Checkbox, Palette, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import createStyles from '@mui/styles/createStyles';
import {
  ICompanyMinimal,
  ICompanyPeerData,
  isNullUndefined,
  ITableColumnData,
} from '@prometeus/common';
import { Map } from 'immutable';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NA_LABEL } from '../../../constants/general.constants';
import { checkCompany } from '../../../store/actions/peer-group.action';
import TableBodyComponent from '../../table/table-body.component';
import TableHeadComponent from '../../table/table-head.component';
import TablePaginationComponent from '../../table/table-pagination.component';
import * as tableUtils from '../../table/table.constants';

type Props = {
  data: ICompanyPeerData[];
  searchValue: string;
  tableColumns: ITableColumnData[];
  peers: Map<string, ICompanyMinimal>;
  currentTicker: string;
  checkAllPeers: () => void;
  order: tableUtils.Order;
  setOrder: (order: tableUtils.Order) => void;
  orderBy: keyof ICompanyPeerData;
  setOrderBy: (orderBy: keyof ICompanyPeerData) => void;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  total?: number;
};

const MAX_HEIGHT_REM = 39;

const getStyles = (palette: Palette) => () =>
  createStyles({
    tableContainer: {
      maxHeight: `${MAX_HEIGHT_REM}rem`,
    },
    paper: {
      marginBottom: '0rem',
    },
    checkbox: {
      color: palette.text.primary,
    },
  });

const PeerGroupSearchTableComponent = (props: Props) => {
  const theme = useTheme();
  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    total,
    searchValue,
    data,
    currentTicker,
    peers,
    checkAllPeers,
  } = props;

  /* Hooks */
  const tableStyles = tableUtils.getTableStyles(theme.palette);
  const styles = getStyles(theme.palette)();
  const dispatch = useDispatch();

  /* Handlers */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof ICompanyPeerData);
  };

  const checkAllHandler = useCallback((): void => {
    checkAllPeers();
  }, [checkAllPeers]);

  useEffect(() => {
    setPage(0);
  }, [searchValue, setPage]);

  const formatCellValue = (
    row: ICompanyPeerData,
    column: ITableColumnData
  ): string => {
    const rowValue = row[column.id as keyof ICompanyPeerData];

    if (tableUtils.isNullUndefinedEmptyStr(rowValue)) {
      return NA_LABEL;
    }

    let value: string = String(rowValue);

    if (!isNullUndefined(column.decimals)) {
      if (column.isPercent) {
        value = String(Number(value) * 100);
        value = Number(value).toFixed(column.decimals);
      } else {
        value = Number(value).toFixed(column.decimals);
      }
    }

    if (column.isPercent || column.isCurrency) {
      value = String(value)
        .replace(/(.)(?=(\d{3})+$)/g, '$1,')
        .replace('-,', '-');
    }

    if (column.isCurrency) {
      value += ' $';
    }
    return value;
  };

  return (
    <Paper sx={{ ...tableStyles.paper, ...styles.paper }}>
      <TableContainer
        sx={
          {
            ...styles.tableContainer,
            ...tableStyles.container,
            ...(!data?.length ? tableStyles.tableContainerOverflowHidden : {}),
          } as any
        }
      >
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeadComponent
            headCells={props.tableColumns}
            sortOrder={order}
            sortBy={orderBy as string}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            /* Checkbox */
            hasCheckboxes="right"
            disabledCheckbox={!data.length}
            uncheckedCheckbox={data.every(
              (e: ICompanyPeerData) => !peers.has(e.tickerId)
            )}
            checkedCheckbox={data.every((e: ICompanyPeerData) =>
              peers.has(e.tickerId)
            )}
            onCheckAll={checkAllHandler}
          />
          <TableBodyComponent
            data={data}
            tableColumns={props.tableColumns}
            emptyRowsMessage="No company to be displayed."
            maxHeight={MAX_HEIGHT_REM}
            formatCellValue={formatCellValue}
            /* Sides */
            rightCell={(company: ICompanyPeerData): React.ReactNode => (
              <Checkbox
                color="primary"
                sx={{ ...styles.checkbox }}
                checked={peers.has(company.tickerId)}
                inputProps={{ 'aria-labelledby': company.tickerId }}
                disabled={currentTicker === company.tickerId}
                onChange={() => {
                  dispatch(checkCompany(company));
                }}
              />
            )}
            /* Pagination */
            page={page}
            rowsPerPage={rowsPerPage}
            /* Sorting */
            order={order}
            orderBy={orderBy as string}
          />
        </Table>
      </TableContainer>
      <TablePaginationComponent
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        dataLength={total || 0}
      />
    </Paper>
  );
};

export default PeerGroupSearchTableComponent;
