import { Close } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { FREE_TRIAL_DURATION_DAYS } from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { CONTACT_EMAIL_ADDRESS } from '../../constants/general.constants';
import { openSignInDialog } from '../../store/actions/modals.action';
import { activateFreeTrial } from '../../store/actions/user.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import BrandFlame from '../brand-flame.component';
import CustomDialogComponent from '../dialog.component';
import TooltipIconButton from '../tooltip-icon-button.component';

const styles = {
  commonButton: {
    borderRadius: '2rem',
  },
  accentText: {
    color: COMMON_COLORS.accent,
  },
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ActivateFreeTrialDialog = (props: Props) => {
  const dispatch = useDispatch();
  const userInformation = useSelector(
    (state: RootState) => state.user.userInformation
  );

  const { open, setOpen } = props;

  const isLoggedIn = !!userInformation;
  const usedFreeTrial = !!userInformation?.usedFreeTrial;

  const closeDialog = () => {
    setOpen(false);
  };

  const activateHandler = () => {
    dispatch(activateFreeTrial());
    closeDialog();
  };

  const accessHandler = () => {
    dispatch(openSignInDialog());
    closeDialog();
  };

  return (
    <CustomDialogComponent
      open={open}
      onClose={closeDialog}
      maxWidth={usedFreeTrial ? 'md' : 'md'}
      sx={{
        '& .MuiDialog-paperFullWidth': {
          width: '60rem',
        },
      }}
      dialogTitle={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="h5"
            sx={{ display: 'flex', alignItems: 'center', fontSize: '2rem' }}
          >
            {isLoggedIn
              ? usedFreeTrial
                ? 'Unlock Premium Features'
                : 'Activate Free Trial'
              : 'Unlock Premium Features'}
            <BrandFlame
              sx={{ transform: 'scale(1.5)', marginLeft: '0.8rem' }}
            />
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            tooltip="Close"
            onClick={closeDialog}
          />
        </Stack>
      }
      dialogContent={
        isLoggedIn ? (
          usedFreeTrial ? (
            <Stack
              spacing={3}
              sx={{ padding: '4rem 3rem' }}
              alignItems="center"
            >
              <Typography variant="h5">
                In order to enjoy the whole potential of <b>PROMETEUS</b>, you
                need to request a{' '}
                <b style={styles.accentText}>Premium licence</b>.
              </Typography>
              <Typography variant="h6">
                <b>PROMETEUS</b> is still under development. If you're
                interested in unlocking all <b>PROMETEUS</b> functionalities in
                preview, please send us an email to{' '}
                <a
                  style={styles.accentText}
                  href={`mailTo:${CONTACT_EMAIL_ADDRESS}`}
                >
                  {CONTACT_EMAIL_ADDRESS}
                </a>{' '}
                explaining why you want to discover more about <b>PROMETEUS</b>.
              </Typography>
            </Stack>
          ) : (
            <Stack
              spacing={3}
              sx={{ padding: '4rem 3rem' }}
              alignItems="center"
            >
              <Typography variant="h5">
                Do you want to unlock the <b>full</b> power of <b>PROMETEUS</b>?
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Activate your{' '}
                <b style={styles.accentText}>
                  {FREE_TRIAL_DURATION_DAYS} days free
                </b>{' '}
                trial and get a taste of it!
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={activateHandler}
                  sx={{
                    ...styles.commonButton,
                    fontSize: '1.3rem',
                    borderRadius: '2rem !important',
                    padding: '0.6rem 2rem !important',
                  }}
                >
                  Activate Free Trial
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    ...styles.commonButton,
                    padding: '0.6rem 1rem',
                    color: 'white',
                    fontSize: '1rem !important',
                    border: '0.05rem solid transparent',
                    borderRadius: '2rem !important',
                    '&:hover': {
                      borderColor: 'white',
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={closeDialog}
                >
                  Not now
                </Button>
              </Stack>
            </Stack>
          )
        ) : (
          <Stack spacing={3} sx={{ padding: '4rem 3rem' }} alignItems="center">
            <Typography variant="h6">
              Do you want to unlock the{' '}
              <b style={{ color: COMMON_COLORS.accent }}>full power</b> of{' '}
              <b>PROMETEUS</b>?
            </Typography>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              To enjoy the whole potential of{' '}
              <b style={{ color: COMMON_COLORS.accent }}>PROMETEUS</b>, you need
              to sign in!
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={accessHandler}
                sx={{
                  ...styles.commonButton,
                  fontSize: '1.1rem',
                }}
              >
                Sign In
              </Button>
            </Stack>
          </Stack>
        )
      }
    />
  );
};

export default ActivateFreeTrialDialog;
