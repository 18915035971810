import { IS_FREE_LICENCE_TICKER } from '@prometeus/common';
import qs from 'qs';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_COMPANY } from '../constants/general.constants';
import { LocalStorageEnum } from '../models/authentication.model';
import { fetchCompanyData } from '../store/actions/companies.action';
import { setTickerId } from '../store/actions/filters.action';
import { RootState } from '../store/reducers/root.reducer';

const handleQueryParams = (
  dispatch: Dispatch<unknown>,
  history: RouteComponentProps['history'],
  locationSearch: string,
  currentCompanyTickerId?: string,
  hasActiveLicence?: boolean | string
): void => {
  let tickerId: string = qs.parse(locationSearch, { ignoreQueryPrefix: true })
    ?.tickerId as string;

  // console.log(tickerId, currentCompanyTickerId);

  if (!hasActiveLicence && !IS_FREE_LICENCE_TICKER(tickerId)) {
    tickerId = DEFAULT_COMPANY.tickerId;
    const params = new URLSearchParams();
    params.append('tickerId', tickerId);
    history.replace({ search: params.toString() });
  }

  // Used to avoid multiple requests and necessary for first request when loading
  if (tickerId === currentCompanyTickerId) {
    dispatch(setTickerId(tickerId));
  }

  if (!tickerId && !currentCompanyTickerId) {
    dispatch(
      fetchCompanyData(
        (localStorage.getItem(LocalStorageEnum.TICKER_ID) as string) ||
        DEFAULT_COMPANY.tickerId
      )
    );
  } else if (currentCompanyTickerId !== tickerId) {
    if (currentCompanyTickerId) {
      const params = new URLSearchParams();
      params.append('tickerId', currentCompanyTickerId);
      history.replace({ search: params.toString() });
    } else if (tickerId) {
      dispatch(fetchCompanyData(tickerId));
    }
  }
};

export const useHandleQueryParams = () => {
  const dispatch = useDispatch();
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  // history.listen((location) => {
  //   console.log(history.action);
  //   if (['PUSH', 'POP'].includes(history.action)) {
  //     console.log(location);
  //     let tickerId: string = qs.parse(locationSearch, {
  //       ignoreQueryPrefix: true,
  //     })?.tickerId as string;
  //     dispatch(setTickerId(tickerId));
  //   }
  // });

  useEffect(() => {
    handleQueryParams(
      dispatch,
      history,
      locationSearch,
      currentCompanyTickerId,
      hasActiveLicence
    );
  }, [
    dispatch,
    history,
    locationSearch,
    currentCompanyTickerId,
    hasActiveLicence,
  ]);
};
