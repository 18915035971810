import {
  Feedback,
  GetApp,
  HelpOutline,
  Image as ImageIcon,
} from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportExcel,
  generateExcel,
} from '../../../constants/charts/visuals.constants';
import { PREMIUM_FEATURE_TOOLTIP } from '../../../constants/general.constants';
import { ExportableDataType } from '../../../models/visuals.model';
import {
  closeChartMenu,
  openActiveFreeTrialDialog,
} from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { useSelectStyles } from '../../../styles/select.styles';
import BrandFlame from '../../brand-flame.component';
import EnhancedTooltip from '../../enhanced-tooltip.component';
import MenuWrapperComponent from './menu-wrapper.component';

type Props = {
  menuOpen: boolean;
  menuOnlyExport?: boolean;
  /* Anchors */
  anchorPosition?: {
    mouseX: null | number;
    mouseY: null | number;
  };
  // Data
  chartImage?: string;
  exportData?: ExportableDataType;
  titleLabel?: string;
  hasInfo?: boolean;
  /* Handlers */
  onFullScreenHandler: () => void;
  onReportIssueHanlder: () => void;
  onInfoHandler: () => void;
};

const MenuComponent = (props: Props) => {
  const dispatch = useDispatch();
  const selectStyles = useSelectStyles();

  const {
    menuOnlyExport,
    /* Anchor */
    anchorPosition,
    menuOpen,
    /* Data */
    chartImage,
    exportData,
    titleLabel,
    hasInfo,
    /* Handlers */
    onFullScreenHandler,
    onReportIssueHanlder,
    onInfoHandler,
  } = props;

  const onMenuCloseHandler = () => {
    dispatch(closeChartMenu());
  };

  const onFullScreenClickHandler = () => {
    onMenuCloseHandler();
    onFullScreenHandler();
  };

  const onDownloadImageHandler = () => {
    if (chartImage) {
      const a = document.createElement('a'); // Create <a>
      a.href = chartImage; // Image Base64 Goes here
      a.download = (titleLabel?.replaceAll(' ', '') || 'chart') + '.png'; // File name Here
      a.click(); // Downloaded file
      onMenuCloseHandler();
    }
  };

  const onExportDataHandler = () => {
    if (exportData) {
      exportExcel(generateExcel(exportData), titleLabel || 'export');
      onMenuCloseHandler();
    }
  };

  const onReportIssueClickHandler = (): void => {
    onReportIssueHanlder();
    onMenuCloseHandler();
  };

  const onInfoClickHandler = (): void => {
    onInfoHandler();
    onMenuCloseHandler();
  };

  const dispatchOpenGetLicenceDialog = () =>
    dispatch(openActiveFreeTrialDialog());

  return (
    <MenuWrapperComponent
      id="card-menu"
      anchorPosition={anchorPosition}
      open={menuOpen}
      onMenuCloseHandler={onMenuCloseHandler}
    >
      {hasInfo && !menuOnlyExport && (
        <MenuItem
          key="Info"
          sx={{ ...selectStyles.menuItemRoot }}
          onClick={onInfoClickHandler}
        >
          <ListItemIcon sx={{ ...selectStyles.listItemIcon, paddingBottom: 0 }}>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText primary="Info" />
        </MenuItem>
      )}

      {!menuOnlyExport && (
        <LicencedMenuItem
          label="Full Screen"
          icon={<ImageIcon />}
          selectStyles={selectStyles}
          disabledCondition={!exportData}
          onClick={onFullScreenClickHandler}
        />
      )}
      <LicencedMenuItem
        label="Export Data"
        icon={<GetApp />}
        selectStyles={selectStyles}
        disabledCondition={!exportData}
        onClick={onExportDataHandler}
      />
      {!menuOnlyExport && (
        <LicencedMenuItem
          label="Download Image"
          icon={<ImageIcon />}
          selectStyles={selectStyles}
          disabledCondition={!chartImage}
          onClick={onDownloadImageHandler}
        />
      )}

      <MenuItem
        key="ReportIssue"
        sx={{ ...selectStyles.menuItemRoot }}
        onClick={onReportIssueClickHandler}
      >
        <ListItemIcon sx={{ ...selectStyles.listItemIcon, paddingBottom: 0 }}>
          <Feedback />
        </ListItemIcon>
        <ListItemText primary="Report Issue" />
      </MenuItem>
    </MenuWrapperComponent>
  );
};

export default MenuComponent;

const LicencedMenuItem = ({
  label,
  icon,
  selectStyles,
  disabledCondition,
  onClick,
}: {
  label: string;
  icon: ReactNode;
  selectStyles: { [label: string]: any };
  disabledCondition?: boolean;
  onClick: () => void;
}) => {
  const dispatch = useDispatch();
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const dispatchOpenGetLicenceDialog = () =>
    dispatch(openActiveFreeTrialDialog());

  return (
    <EnhancedTooltip
      title={!hasActiveLicence ? PREMIUM_FEATURE_TOOLTIP : ''}
      onInternalClick={() => {
        if (!hasActiveLicence) {
          dispatch(openActiveFreeTrialDialog());
        }
      }}
    >
      <MenuItem
        key="DownloadImage"
        sx={{
          ...selectStyles.menuItemRoot,
          ...(!hasActiveLicence ? selectStyles.disabledMenuItem : {}),
        }}
        disabled={disabledCondition || !hasActiveLicence}
        onClick={!hasActiveLicence ? dispatchOpenGetLicenceDialog : onClick}
      >
        <ListItemIcon sx={{ ...selectStyles.listItemIcon, paddingBottom: 0 }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
        {!hasActiveLicence && <BrandFlame sx={selectStyles.brandFlame} />}
      </MenuItem>
    </EnhancedTooltip>
  );
};
