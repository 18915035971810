import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS } from '../utils/visuals.config';

const DebtToEquityDebtPaybackTab = () => {
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const dispatch = useDispatch();
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *              Chars Data             *
   ***************************************/
  const deData = useSelector(
    (state: RootState) => state.visuals.financialStrength.deData
  );
  const deDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.deDataUpdating
  );
  const deDistributionData = useSelector(
    (state: RootState) => state.visuals.financialStrength.deDistributionData
  );
  const deDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.deDistributionDataUpdating
  );
  const debtPaybackData = useSelector(
    (state: RootState) => state.visuals.financialStrength.debtPaybackData
  );
  const debtPaybackDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.debtPaybackDataUpdating
  );
  const debtPaybackDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.debtPaybackDistributionData
  );
  const debtPaybackDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.debtPaybackDistributionDataUpdating
  );

  const [de, setDe] = useState<ChartData>();
  const [deDistribution, setDeDistribution] = useState<ChartData>();
  const [debtPayback, setDebtPayback] = useState<ChartData>();
  const [debtPaybackDistribution, setDebtPaybackDistribution] =
    useState<ChartData>();

  /* Debt To Equity */
  const [deHasData, deHasLabels, deError] = extractChartMetadata(deData);
  useEffect(() => {
    setDe({
      datasets: createChartDatasetsConfig(
        deData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [deData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Debt Payback */
  const [debtPaybackHasData, debtPaybackHasLabels, debtPaybackError] =
    extractChartMetadata(debtPaybackData);
  useEffect(() => {
    setDebtPayback({
      datasets: createChartDatasetsConfig(
        debtPaybackData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [debtPaybackData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Debt To Equity Distribution */
  const [deDistributionHasData, deDistributionHasLabels, deDistributionError] =
    extractChartMetadata(deDistributionData);
  useEffect(() => {
    setDeDistribution({
      labels: deDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        deDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [deDistributionData, screenWidth, currentCompanyTickerId]);

  /* Debt Payback Distribution */
  const [
    debtPaybackDistributionHasData,
    debtPaybackDistributionHasLabels,
    debtPaybackDistributionError,
  ] = extractChartMetadata(debtPaybackDistributionData);
  useEffect(() => {
    setDebtPaybackDistribution({
      labels: debtPaybackDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        debtPaybackDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [debtPaybackDistributionData, screenWidth, currentCompanyTickerId]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.DEBT_TO_EQUITY_DEBT_PAYBACK));
  }, [dispatch]);

  return (
    <>
      {deDataUpdating &&
        deDistributionDataUpdating &&
        debtPaybackDataUpdating &&
        debtPaybackDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={de}
          error={
            !!deError
              ? GENERAL_ERROR_MESSAGE
              : !deHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtToEquity}
          hasData={deHasData}
          exportData={deData}
          showLegend={true}
          currencySymbol={
            !!deData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!deData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!deDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={deDistribution}
          error={
            !!deDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !deDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtToEquityDistribution
          }
          hasData={deDistributionHasData}
          exportData={deDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!deDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!deDistributionData?.isPercentage}
          updating={!!deDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={debtPayback}
          error={
            !!debtPaybackError
              ? GENERAL_ERROR_MESSAGE
              : !debtPaybackHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtPayback}
          hasData={debtPaybackHasData}
          exportData={debtPaybackData}
          showLegend={true}
          currencySymbol={
            !!debtPaybackData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!debtPaybackData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!debtPaybackDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={debtPaybackDistribution}
          error={
            !!debtPaybackDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !debtPaybackDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtPaybackDistribution
          }
          hasData={debtPaybackDistributionHasData}
          exportData={debtPaybackDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!debtPaybackDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!debtPaybackDistributionData?.isPercentage}
          updating={!!debtPaybackDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default DebtToEquityDebtPaybackTab;
