import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const styles = {
  linearProgress: {
    color: 'primary.main',
  },
  typography: {
    color: 'primary.main',
    fontSize: '1.2rem',
  },
  container: {
    width: '25%',
  },
};

const LinearProgressComponent = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box display="flex" alignItems="center" sx={styles.container}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          sx={styles.linearProgress}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" sx={styles.typography}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressComponent;
