import { IDescription } from '@prometeus/common';
import { Map } from 'immutable';

export interface IDescriptionsState {
  descriptionMap: Map<string, IDescription>;
  loading: boolean;
  error?: string;
}

/* GET_DESCRIPTION */
export const GET_DESCRIPTION = 'GET_DESCRIPTION';
export const GET_DESCRIPTION_SUCCESS = 'GET_DESCRIPTION_SUCCESS';
export const GET_DESCRIPTION_FAIL = 'GET_DESCRIPTION_FAIL';

export interface IGetDescription {
  type: typeof GET_DESCRIPTION;
}

export interface IGetDescriptionFail {
  type: typeof GET_DESCRIPTION_FAIL;
  payload: string;
}

export interface IGetDescriptionSuccess {
  type: typeof GET_DESCRIPTION_SUCCESS;
  payload: {
    key: string;
    description: IDescription;
  };
}

export type GetDescriptionDispachTypes =
  | IGetDescription
  | IGetDescriptionFail
  | IGetDescriptionSuccess;

export type DispatchActionTypes = GetDescriptionDispachTypes;
