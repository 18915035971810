import { IPlan } from '@prometeus/common';
import { DumpType } from '../general.model';

export interface IPlansState {
    plans: IPlan[];
    plansDump?: DumpType;

    loading: boolean;
    error?: string;
}

/* GET_PLANS */
export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAIL = 'GET_PLANS_FAIL';

export interface IGetPlans {
    type: typeof GET_PLANS;
}

export interface IGetPlansFail {
    type: typeof GET_PLANS_FAIL;
    payload: string;
}

export interface IGetPlansSuccess {
    type: typeof GET_PLANS_SUCCESS;
    payload: IPlan[];
}

export type GetPlansDispachTypes = IGetPlans | IGetPlansFail | IGetPlansSuccess;

/* GET_PLAN_DETAILS */
export const GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
export const GET_PLAN_DETAILS_SUCCESS = 'GET_PLAN_DETAILS_SUCCESS';
export const GET_PLAN_DETAILS_FAIL = 'GET_PLAN_DETAILS_FAIL';

export interface IGetPlanDetails {
    type: typeof GET_PLAN_DETAILS;
}

export interface IGetPlanDetailsFail {
    type: typeof GET_PLAN_DETAILS_FAIL;
    payload: string;
}

export interface IGetPlanDetailsSuccess {
    type: typeof GET_PLAN_DETAILS_SUCCESS;
    payload: IPlan;
}

export type GetPlanDetailsDispachTypes =
    | IGetPlanDetails
    | IGetPlanDetailsFail
    | IGetPlanDetailsSuccess;

/* PLAN_DUMP_DB */
export const PLAN_DUMP_DB = 'PLAN_DUMP_DB';
export interface IPlanDumpDB {
    type: typeof PLAN_DUMP_DB;
}

/* PLAN_DUMP_DB_SUCCESS */
export const PLAN_DUMP_DB_SUCCESS = 'PLAN_DUMP_DB_SUCCESS';
export interface IPlanDumpDBSuccess {
    type: typeof PLAN_DUMP_DB_SUCCESS;
    payload: DumpType;
}

/* PLAN_DUMP_DB_FAIL */
export const PLAN_DUMP_DB_FAIL = 'PLAN_DUMP_DB_FAIL';
export interface IPlanDumpDBFail {
    type: typeof PLAN_DUMP_DB_FAIL;
    payload: string;
}

export type PlanDumpDBDispatchTypes =
    | IPlanDumpDB
    | IPlanDumpDBSuccess
    | IPlanDumpDBFail;

/* DELETE_PLAN */
export const DELETE_PLAN = 'DELETE_PLAN';
export interface IDeletePlan {
    type: typeof DELETE_PLAN;
}

/* DELETE_PLAN_FAIL */
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL';
export interface IDeletePlanFail {
    type: typeof DELETE_PLAN_FAIL;
    payload: string;
}

export type DeletePlanDispatchTypes =
    | IDeletePlan
    | IGetPlansSuccess
    | IDeletePlanFail;

/* UPSERT_PLAN */
export const UPSERT_PLAN = 'UPSERT_PLAN';
export interface IUpsertPlan {
    type: typeof UPSERT_PLAN;
}

/* UPSERT_PLAN_FAIL */
export const UPSERT_PLAN_FAIL = 'UPSERT_PLAN_FAIL';
export interface IUpsertPlanFail {
    type: typeof UPSERT_PLAN_FAIL;
    payload: string;
}

export type UpsertPlanDispatchTypes =
    | IUpsertPlan
    | IGetPlansSuccess
    | IUpsertPlanFail;

export type DispatchActionTypes =
    | GetPlansDispachTypes
    | PlanDumpDBDispatchTypes
    | DeletePlanDispatchTypes
    | UpsertPlanDispatchTypes
    | GetPlanDetailsDispachTypes;
