import { Map, Set } from 'immutable';

export interface IManagersState {
  managersMap: Map<string, ICachedManager>;
  managersQueue: string[];
  requestedManagers: Set<string>;

  loading: boolean;
  error?: string;
}

export interface ICachedManager {
  image: string;
  count: number;
}

export interface IManagerObj {
  name: string;
  image: string;
}

/* GET_MANAGERS */
export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';
export const GET_MANAGERS_FAIL = 'GET_MANAGERS_FAIL';

export interface GetManagersI {
  type: typeof GET_MANAGERS;
  payload: string[];
}

export interface GetManagersFailI {
  type: typeof GET_MANAGERS_FAIL;
  payload: string;
}

export interface GetManagersSuccessI {
  type: typeof GET_MANAGERS_SUCCESS;
  payload: IManagerObj[];
}

export type GetManagersDispachTypes =
  | GetManagersI
  | GetManagersFailI
  | GetManagersSuccessI;

export type DispatchActionTypes = GetManagersDispachTypes;
