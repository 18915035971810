import { COMMON_COLORS } from '../ui/colors';

/* Scale Loader */
const COMMON_SCALE_LOADER_PROPS = {
  color: COMMON_COLORS.primary,
  loading: true,
};
export const SCALE_LOADER_PROPS = {
  ...COMMON_SCALE_LOADER_PROPS,
  height: '4rem',
  width: '0.4rem',
  margin: '0.15rem',
};

export const SMALL_SCALE_LOADER_PROPS = {
  ...COMMON_SCALE_LOADER_PROPS,
  height: '1.8rem',
  width: '0.2rem',
  margin: '0.1rem',
};
