import { ChartData, ChartOptions, ChartTypeRegistry } from 'chart.js';
import { IAVisualChart } from '../pages/sector-analysis/utils/visuals.model';
import { TimeframeFilter } from './filters.model';
import { PeerGroupEnum } from './peer-group.model';

export enum ChartTypeEnum {
  TIME_CHART = 'timeChart',
  DISTR_CHART = 'distrChart',
  BASIC_CHART = 'basicTimeChart',
  RANK_CHART = 'rankChart',
  RATE_CHART = 'rateChart',
  YOY_CHART = 'yoyChart',
  RADAR_CHART = 'radarChart',
  PIE_CHART = 'pieChart',
  INFO_CHART = 'infoChart',
  SUMMARY = 'summary',
  TOP_PEERS = 'topPeers',
}

export type CreateChartDatasetOptionsType = {
  label?: string;
  [option: string]: any;
};

export interface IFetchChartDataVisual {
  type: ChartTypeEnum;
  item: string | string[];
  ascending?: boolean;
}

interface IAPICommonDataBody {
  minYear: number;
  maxYear: number;
  visuals: IFetchChartDataVisual[];
}

export interface IFetchChartDataBody extends IAPICommonDataBody {
  tickerId: string;
  peerGroup: PeerGroupEnum;
  peers: string[];
  timeFrame: TimeframeFilter;
}

export enum GroupBySectorEnum {
  COUNTRY_ID = 'CountryId',
  SECTOR = 'Sector',
  INDUSTRY = 'Industry',
}

export interface IFetchSectorChartDataBody extends IAPICommonDataBody {
  groupBy: GroupBySectorEnum;
  countries: string[];
  sectors: string[];
  industries: string[];
}

export interface IVisualConfig extends IFetchChartDataVisual {
  id: string;
  dataAction: string;
  stateLabel: string;
  columns: string[];
  datasetLabels: CreateChartDatasetOptionsType[];

  /* Flags */
  isCurrency?: boolean;
  isPercentage?: boolean;
}

export interface IVisualAggregatedConfig {
  daily?: IVisualConfig[];
  weekly?: IVisualConfig[];
  monthly?: IVisualConfig[];
  quarterly?: IVisualConfig[];
  yearly?: IVisualConfig[];
}

export interface IVisualChart {
  /* Labels */
  labels: string[]; // Main (full) label

  /* Data */
  data1: (number | null)[];
  data2?: (number | null)[];
  data3?: (number | null)[];
  data4?: (number | null)[];

  /* Utility data */
  checksum?: string;
  currencyRate?: number[];
  dateLabel?: boolean;

  /* Config data */
  datasetLabels: CreateChartDatasetOptionsType[];
  columns: string[];
  isPercentage?: boolean;
  isCurrency?: boolean;

  /* Metadata */
  hasData: boolean;
  error?: string;
}

export interface IVisualChartRank extends IVisualChart {
  rank: number[];
  selectedTicker: number[];
}

export interface IChartRawData {
  [column: string]: (string | number)[];
}

export interface IRadarRawData {
  Debt: any;
  Dividend: any;
  Growth: any;
  Profitability: any;
  Returns: any;
  ticket_score: number;
}

export interface IVisualPayload {
  data: IChartRawData;
  configData: IVisualConfigData;
}

export interface ITooltipSymbol {
  left: boolean;
  symbol: string;
}

export interface IVisualConfigData {
  columns: string[];
  datasetLabels: CreateChartDatasetOptionsType[];
  isCurrency?: boolean;
  isPercentage?: boolean;
}

export interface IOpenLargeChart {
  titleLabel: string;
  data: ChartData;
  type: keyof ChartTypeRegistry;
  options: ChartOptions;
  info?: boolean;
}

export interface IStatementsData {
  map: StatementsMap;
  checksum?: string;
}
export type StatementsMap = Map<string, (number | string | null)[]>;
export interface IStatementsConfig {
  label: string;
  column: string;
  hierarchy: number;
}

export interface IStatementsExport {
  statements: StatementsMap;
  statementsConfig: IStatementsConfig[];
}

export type ExportableDataType =
  | IVisualChart
  | IAVisualChart
  | IStatementsExport;
