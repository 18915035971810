import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { ISecIndCty } from '../../../models/screener/structure.model';
import {
  GroupBySectorEnum,
  IOpenLargeChart,
} from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { setSectorGroupBy } from '../utils/visuals.actions';
import { CHART_OPTIONS } from '../utils/visuals.config';
import {
  createAChartDatasetsConfig,
  extractChartMetadata,
  getEmptyOptionMessage,
  makeDistributionTitle,
} from '../utils/visuals.constants';
import { ILabelValue } from '../utils/visuals.model';

const CountryAnalysisTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const topCountryData = useSelector(
    (state: RootState) => state.visuals.sector.topCountryData
  );
  const topCountryDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.topCountryDataUpdating
  );
  const topDistributionCountryData = useSelector(
    (state: RootState) => state.visuals.sector.topDistributionCountryData
  );
  const topDistributionCountryDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.sector.topDistributionCountryDataUpdating
  );
  const bottomCountryData = useSelector(
    (state: RootState) => state.visuals.sector.bottomCountryData
  );
  const bottomCountryDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.bottomCountryDataUpdating
  );
  const bottomDistributionCountryData = useSelector(
    (state: RootState) => state.visuals.sector.bottomDistributionCountryData
  );
  const bottomDistributionCountryDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.sector.bottomDistributionCountryDataUpdating
  );
  const topOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );
  const bottomOption = useSelector(
    (state: RootState) => state.visuals.sector.bottomOption
  );
  const countryCountries = useSelector(
    (state: RootState) => state.visuals.sector.countryCountries
  );
  const timeWindow = useSelector(
    (state: RootState) => state.visuals.sector.timeWindow
  );

  const hasCountries = (): boolean =>
    !!countryCountries?.filter((e: ISecIndCty) => e.selected)?.length;

  const [top, setTop] = useState<ChartData>();
  const [topDistribution, setTopDistribution] = useState<ChartData>();
  const [bottom, setBottom] = useState<ChartData>();
  const [bottomDistribution, setBottomDistribution] = useState<ChartData>();

  /* Top */
  const [topHasData, topHasLabels, topError] =
    extractChartMetadata(topCountryData);
  useEffect(() => {
    setTop({
      datasets: createAChartDatasetsConfig(topCountryData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [topCountryData, timeWindow, screenWidth]);

  /* Top Distribution */
  const [
    topDistributionHasData,
    topDistributionHasLabels,
    topDistributionError,
  ] = extractChartMetadata(topDistributionCountryData);
  useEffect(() => {
    setTopDistribution({
      labels: topDistributionCountryData?.labels,
      datasets: createAChartDatasetsConfig(
        topDistributionCountryData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [topDistributionCountryData, screenWidth]);

  /* Bottom */
  const [bottomHasData, bottomHasLabels, bottomError] =
    extractChartMetadata(bottomCountryData);
  useEffect(() => {
    setBottom({
      datasets: createAChartDatasetsConfig(bottomCountryData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [bottomCountryData, timeWindow, screenWidth]);

  /* Bottom Distribution */
  const [
    bottomDistributionHasData,
    bottomDistributionHasLabels,
    bottomDistributionError,
  ] = extractChartMetadata(bottomDistributionCountryData);
  useEffect(() => {
    setBottomDistribution({
      labels: bottomDistributionCountryData?.labels,
      datasets: createAChartDatasetsConfig(
        bottomDistributionCountryData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [bottomDistributionCountryData, screenWidth]);

  /***************************************
   *             Chart Titles            *
   ***************************************/
  const [topTitle, setTopTitle] = useState<string>('');
  const [bottomTitle, setBottomTitle] = useState<string>('');

  /***************************************
   *         Chart Change Listener       *
   ***************************************/
  useEffect(() => {
    if (!!topOption) {
      setTopTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === topOption)?.label ||
          ''
      );
    }
  }, [dispatch, topOption]);

  useEffect(() => {
    if (!!bottomOption) {
      setBottomTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === bottomOption)
          ?.label || ''
      );
    }
  }, [dispatch, bottomOption]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COUNTRY_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.COUNTRY_ID));
  }, [dispatch]);

  return (
    <>
      {topCountryDataUpdating &&
        topDistributionCountryDataUpdating &&
        bottomCountryDataUpdating &&
        bottomDistributionCountryDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={top}
          error={
            !topOption || !hasCountries()
              ? getEmptyOptionMessage('countries', hasCountries(), !!topOption)
              : !!topError
              ? GENERAL_ERROR_MESSAGE
              : !topHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          warningIcon={!topOption || !hasCountries()}
          titleLabel={topTitle}
          hasData={!!topOption && hasCountries() ? topHasData : true}
          isPercentage={topCountryData?.isPercentage}
          exportData={topCountryData}
          showLegend={true}
          updating={!!topCountryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={topDistribution}
          error={
            !topOption || !hasCountries()
              ? getEmptyOptionMessage('countries', hasCountries(), !!topOption)
              : !!topDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !topDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          warningIcon={!topOption || !hasCountries()}
          titleLabel={topTitle ? makeDistributionTitle(topTitle) : ''}
          hasData={
            !!topOption && hasCountries() ? topDistributionHasData : true
          }
          exportData={topDistributionCountryData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          isPercentage={!!topDistributionCountryData?.isPercentage}
          updating={!!topDistributionCountryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={bottom}
          error={
            !bottomOption || !hasCountries()
              ? getEmptyOptionMessage(
                  'countries',
                  hasCountries(),
                  !!bottomOption
                )
              : !!bottomError
              ? GENERAL_ERROR_MESSAGE
              : !bottomHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          warningIcon={!bottomOption || !hasCountries()}
          titleLabel={bottomTitle}
          hasData={!!bottomOption && hasCountries() ? bottomHasData : true}
          isPercentage={bottomCountryData?.isPercentage}
          exportData={bottomCountryData}
          showLegend={true}
          updating={!!bottomCountryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={bottomDistribution}
          error={
            !bottomOption || !hasCountries()
              ? getEmptyOptionMessage(
                  'countries',
                  hasCountries(),
                  !!bottomOption
                )
              : !!bottomDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !bottomDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          warningIcon={!bottomOption || !hasCountries()}
          titleLabel={bottomTitle ? makeDistributionTitle(bottomTitle) : ''}
          hasData={
            !!bottomOption && hasCountries() ? bottomDistributionHasData : true
          }
          exportData={bottomDistributionCountryData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          isPercentage={!!bottomDistributionCountryData?.isPercentage}
          updating={!!bottomDistributionCountryDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default CountryAnalysisTab;
