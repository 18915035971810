import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { ROA_ROIC_TITLE_LABELS } from '../utils/visuals.config';

const RoaRoicTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const roaData = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roaData
  );
  const roaDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roaDataUpdating
  );
  const roaDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roaDistributionData
  );
  const roaDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roaDistributionDataUpdating
  );
  const roicData = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roicData
  );
  const roicDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roicDataUpdating
  );
  const roicDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roicDistributionData
  );
  const roicDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roicDistributionDataUpdating
  );

  const [roa, setRoa] = useState<ChartData>();
  const [roaDistribution, setRoaDistribution] = useState<ChartData>();
  const [roic, setRoic] = useState<ChartData>();
  const [roicDistribution, setRoicDistribution] = useState<ChartData>();

  /* ROA */
  const [roaHasData, roaHasLabels, roaError] = extractChartMetadata(roaData);
  useEffect(() => {
    setRoa({
      datasets: createChartDatasetsConfig(
        roaData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [roaData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* ROIC */
  const [roicHasData, roicHasLabels, roicError] =
    extractChartMetadata(roicData);
  useEffect(() => {
    setRoic({
      datasets: createChartDatasetsConfig(
        roicData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [roicData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* ROA Distribution */
  const [
    roaDistributionHasData,
    roaDistributionHasLabels,
    roaDistributionError,
  ] = extractChartMetadata(roaDistributionData);
  useEffect(() => {
    setRoaDistribution({
      labels: roaDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        roaDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [roaDistributionData, screenWidth, currentCompanyTickerId]);

  /* ROIC Distribution */
  const [
    roicDistributionHasData,
    roicDistributionHasLabels,
    roicDistributionError,
  ] = extractChartMetadata(roicDistributionData);
  useEffect(() => {
    setRoicDistribution({
      labels: roicDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        roicDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [roicDistributionData, screenWidth, currentCompanyTickerId]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.ROA_ROIC));
  }, [dispatch]);

  return (
    <>
      {roaDataUpdating &&
        roaDistributionDataUpdating &&
        roicDataUpdating &&
        roicDistributionDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={roa}
          error={
            !!roaError
              ? GENERAL_ERROR_MESSAGE
              : !roaHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROA_ROIC_TITLE_LABELS.roa}
          hasData={roaHasData}
          exportData={roaData}
          showLegend={true}
          currencySymbol={
            !!roaData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roaData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!roaDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={roaDistribution}
          error={
            !!roaDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !roaDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROA_ROIC_TITLE_LABELS.roaDistribution}
          hasData={roaDistributionHasData}
          exportData={roaDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!roaDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roaDistributionData?.isPercentage}
          updating={!!roaDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={roic}
          error={
            !!roicError
              ? GENERAL_ERROR_MESSAGE
              : !roicHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROA_ROIC_TITLE_LABELS.roic}
          hasData={roicHasData}
          exportData={roicData}
          showLegend={true}
          currencySymbol={
            !!roicData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roicData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!roicDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={roicDistribution}
          error={
            !!roicDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !roicDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={ROA_ROIC_TITLE_LABELS.roicDistribution}
          hasData={roicDistributionHasData}
          exportData={roicDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!roicDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!roicDistributionData?.isPercentage}
          updating={!!roicDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default RoaRoicTab;
