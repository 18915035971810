import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import {
  createChartDatasetConfigAssets,
  createChartDatasetConfigLiabilities,
} from '../../../constants/charts/chart.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../utils/visuals.config';

const NoncurrentAssetAndLiabilitiesTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const nonCurrentAssetsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.nonCurrentAssetsData
  );
  const nonCurrentAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.nonCurrentAssetsDataUpdating
  );
  const propertyPlantEquipmentData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.propertyPlantEquipmentData
  );
  const propertyPlantEquipmentDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.propertyPlantEquipmentDataUpdating
  );
  const longTermInvestmentsReceivablesData = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.longTermInvestmentsReceivablesData
  );
  const longTermInvestmentsReceivablesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.longTermInvestmentsReceivablesDataUpdating
  );
  const otherLongTermAssetsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.otherLongTermAssetsData
  );
  const otherLongTermAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherLongTermAssetsDataUpdating
  );
  const nonCurrentLiabilitiesData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.nonCurrentLiabilitiesData
  );
  const nonCurrentLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.nonCurrentLiabilitiesDataUpdating
  );
  const longTermDebtData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.longTermDebtData
  );
  const longTermDebtDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.longTermDebtDataUpdating
  );
  const otherLongTermLiabilitiesData = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherLongTermLiabilitiesData
  );
  const otherLongTermLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherLongTermLiabilitiesDataUpdating
  );

  const [nonCurrentAssets, setNonCurrentAssets] = useState<ChartData>();
  const [propertyPlantEquipment, setPropertyPlantEquipment] =
    useState<ChartData>();
  const [longTermInvestmentsReceivables, setLongTermInvestmentsReceivables] =
    useState<ChartData>();
  const [otherLongTermAssets, setOtherLongTermAssets] = useState<ChartData>();
  const [nonCurrentLiabilities, setNonCurrentLiabilities] =
    useState<ChartData>();
  const [longTermDebt, setLongTermDebt] = useState<ChartData>();
  const [otherLongTermLiabilities, setOtherLongTermLiabilities] =
    useState<ChartData>();

  /* Noncurrent Assets */
  const [
    nonCurrentAssetsHasData,
    nonCurrentAssetsHasLabels,
    nonCurrentAssetsError,
  ] = extractChartMetadata(nonCurrentAssetsData);
  useEffect(() => {
    setNonCurrentAssets({
      datasets: createChartDatasetConfigAssets(
        nonCurrentAssetsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [nonCurrentAssetsData, useDollars, fiscalYear, screenWidth]);

  /* Property Plant Equipment */
  const [
    propertyPlantEquipmentHasData,
    propertyPlantEquipmentHasLabels,
    propertyPlantEquipmentError,
  ] = extractChartMetadata(propertyPlantEquipmentData);
  useEffect(() => {
    setPropertyPlantEquipment({
      datasets: createChartDatasetConfigAssets(
        propertyPlantEquipmentData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [propertyPlantEquipmentData, useDollars, fiscalYear, screenWidth]);

  /* Long Term Investments Receivable */
  const [
    longTermInvestmentsReceivablesHasData,
    longTermInvestmentsReceivablesHasLabels,
    longTermInvestmentsReceivablesError,
  ] = extractChartMetadata(longTermInvestmentsReceivablesData);

  useEffect(() => {
    setLongTermInvestmentsReceivables({
      datasets: createChartDatasetConfigAssets(
        longTermInvestmentsReceivablesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [longTermInvestmentsReceivablesData, useDollars, fiscalYear, screenWidth]);

  /* Other Long Term Assets */
  const [
    otherLongTermAssetsHasData,
    otherLongTermAssetsHasLabels,
    otherLongTermAssetsError,
  ] = extractChartMetadata(otherLongTermAssetsData);
  useEffect(() => {
    setOtherLongTermAssets({
      datasets: createChartDatasetConfigAssets(
        otherLongTermAssetsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [otherLongTermAssetsData, useDollars, fiscalYear, screenWidth]);

  /* Noncurrent Liabilities */
  const [
    nonCurrentLiabilitiesHasData,
    nonCurrentLiabilitiesHasLabels,
    nonCurrentLiabilitiesError,
  ] = extractChartMetadata(nonCurrentLiabilitiesData);
  useEffect(() => {
    setNonCurrentLiabilities({
      datasets: createChartDatasetConfigLiabilities(
        nonCurrentLiabilitiesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [nonCurrentLiabilitiesData, useDollars, fiscalYear, screenWidth]);

  /* Long Term Debt */
  const [longTermDebtHasData, longTermDebtHasLabels, longTermDebtError] =
    extractChartMetadata(longTermDebtData);
  useEffect(() => {
    setLongTermDebt({
      datasets: createChartDatasetConfigLiabilities(
        longTermDebtData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [longTermDebtData, useDollars, fiscalYear, screenWidth]);

  /* Other Long Term Liabilities */
  const [
    otherLongTermLiabilitiesHasData,
    otherLongTermLiabilitiesHasLabels,
    otherLongTermLiabilitiesError,
  ] = extractChartMetadata(otherLongTermLiabilitiesData);
  useEffect(() => {
    setOtherLongTermLiabilities({
      datasets: createChartDatasetConfigLiabilities(
        otherLongTermLiabilitiesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [otherLongTermLiabilitiesData, useDollars, fiscalYear, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.NONCURRENT_ASSETS_AND_LIABILITIES));
  }, [dispatch]);

  return (
    <>
      {nonCurrentAssetsDataUpdating &&
        nonCurrentLiabilitiesDataUpdating &&
        propertyPlantEquipmentDataUpdating &&
        longTermInvestmentsReceivablesDataUpdating &&
        longTermDebtDataUpdating &&
        otherLongTermLiabilitiesDataUpdating &&
        otherLongTermAssetsDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow sx={{ height: '40%' }}>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={nonCurrentAssets}
          error={
            !!nonCurrentAssetsError
              ? GENERAL_ERROR_MESSAGE
              : !nonCurrentAssetsHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.nonCurrentAssets
          }
          hasData={nonCurrentAssetsHasData}
          exportData={nonCurrentAssetsData}
          currencySymbol={
            !!nonCurrentAssetsData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!nonCurrentAssetsData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!nonCurrentAssetsDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={nonCurrentLiabilities}
          error={
            !!nonCurrentLiabilitiesError
              ? GENERAL_ERROR_MESSAGE
              : !nonCurrentLiabilitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.nonCurrentLiabilities
          }
          hasData={nonCurrentLiabilitiesHasData}
          exportData={nonCurrentLiabilitiesData}
          currencySymbol={
            !!nonCurrentLiabilitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!nonCurrentLiabilitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!nonCurrentLiabilitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <div style={{ height: '50%' }}>
        <ResponsiveRow>
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={propertyPlantEquipment}
            error={
              !!propertyPlantEquipmentError
                ? GENERAL_ERROR_MESSAGE
                : !propertyPlantEquipmentHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.propertyPlantEquipment
            }
            hasData={propertyPlantEquipmentHasData}
            exportData={propertyPlantEquipmentData}
            currencySymbol={
              !!propertyPlantEquipmentData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!propertyPlantEquipmentData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!propertyPlantEquipmentDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={longTermInvestmentsReceivables}
            error={
              !!longTermInvestmentsReceivablesError
                ? GENERAL_ERROR_MESSAGE
                : !longTermInvestmentsReceivablesHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.longTermInvestmentsReceivables
            }
            hasData={longTermInvestmentsReceivablesHasData}
            exportData={longTermInvestmentsReceivablesData}
            currencySymbol={
              !!longTermInvestmentsReceivablesData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!longTermInvestmentsReceivablesData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!longTermInvestmentsReceivablesDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={longTermDebt}
            error={
              !!longTermDebtError
                ? GENERAL_ERROR_MESSAGE
                : !longTermDebtHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.longTermDebt}
            hasData={longTermDebtHasData}
            exportData={longTermDebtData}
            currencySymbol={
              !!longTermDebtData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!longTermDebtData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!longTermDebtDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={otherLongTermLiabilities}
            error={
              !!otherLongTermLiabilitiesError
                ? GENERAL_ERROR_MESSAGE
                : !otherLongTermLiabilitiesHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.otherLongTermLiabilities
            }
            hasData={otherLongTermLiabilitiesHasData}
            exportData={otherLongTermLiabilitiesData}
            currencySymbol={
              !!otherLongTermLiabilitiesData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!otherLongTermLiabilitiesData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!otherLongTermLiabilitiesDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
        </ResponsiveRow>
        <ResponsiveRow noMarginBottom>
          <VisualChartComponent
            containerHeight={true}
            type="line"
            data={otherLongTermAssets}
            error={
              !!otherLongTermAssetsError
                ? GENERAL_ERROR_MESSAGE
                : !otherLongTermAssetsHasLabels
                ? GENERAL_NO_DATA_MESSAGE
                : undefined
            }
            titleLabel={
              NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.otherLongTermAssets
            }
            hasData={otherLongTermAssetsHasData}
            exportData={otherLongTermAssetsData}
            currencySymbol={
              !!otherLongTermAssetsData?.isCurrency
                ? useDollars
                  ? DOLLAR_ST
                  : currencySt
                : undefined
            }
            isPercentage={!!otherLongTermAssetsData?.isPercentage}
            timeTooltipFormat={
              activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
            }
            updating={!!otherLongTermAssetsDataUpdating}
            onOpenChartDialogClick={onOpenChartDialogClickHandler}
            smallChart={true}
          />
          <div style={{ flex: '3 1 0', margin: '0 3rem' }}></div>
        </ResponsiveRow>
      </div>
    </>
  );
};

export default NoncurrentAssetAndLiabilitiesTab;
