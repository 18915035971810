// eslint-disable-next-line no-shadow
export enum FilterEnum {
  ticker = 'ticker',
  tickerSelector = 'tickerSelector',
  timeWindow = 'timeWindow',
  doubleYear = 'doubleYear',
  singleYear = 'singleYear',
  sector = 'sector',
  industry = 'industry',
  country = 'country',
  measure = 'measure',
  mode = 'mode',
  peerGroup = 'peerGroup',
  peers = 'peers',
  measureListTop = 'measureListTop',
  measureListBottom = 'measureListBottom',
}
