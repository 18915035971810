import {
  IGlobalError,
  SET_AXIOS_ERROR,
  SET_GLOBAL_ERROR,
} from '../../models/error-handling.model';

export const setGlobalError = (error: IGlobalError | undefined) => ({
  type: SET_GLOBAL_ERROR,
  payload: error,
});

export const setAxiosError = (error: IGlobalError | undefined) => ({
  type: SET_AXIOS_ERROR,
  payload: error,
});
