import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processStatementsData,
  processVisualData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  CASH_FLOW_ANALYSIS_UPDATE,
  CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA,
  CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA,
  CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA,
  CASH_QUARTERLY_DATA,
  CASH_SOURCES_QUARTERLY_DATA,
  CASH_SOURCES_YEARLY_DATA,
  CASH_YEARLY_DATA,
  CF_STATEMENTS_QUARTERLY_DATA,
  CF_STATEMENTS_YEARLY_DATA,
  DispatchActionTypes,
  IVisualsState,
  NET_CHANGE_IN_CASH_QUARTERLY_DATA,
  NET_CHANGE_IN_CASH_YEARLY_DATA,
} from './visuals.model';

const CHART_ACTIONS_SET = new Set([CASH_FLOW_ANALYSIS_UPDATE]);

const initialState: IVisualsState = {};

const cashflowAnalysisVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case CASH_FLOW_ANALYSIS_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;

    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      case CASH_YEARLY_DATA:
        if (hashedData === state.cashData?.checksum) {
          return { ...state, cashDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashDataUpdating: false,
        };

      case CASH_QUARTERLY_DATA:
        if (hashedData === state.cashData?.checksum) {
          return { ...state, cashDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashDataUpdating: false,
        };

      case NET_CHANGE_IN_CASH_YEARLY_DATA:
        if (hashedData === state.netChangeInCashData?.checksum) {
          return { ...state, netChangeInCashDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          netChangeInCashData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          netChangeInCashDataUpdating: false,
        };

      case NET_CHANGE_IN_CASH_QUARTERLY_DATA:
        if (hashedData === state.netChangeInCashData?.checksum) {
          return { ...state, netChangeInCashDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          netChangeInCashData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          netChangeInCashDataUpdating: false,
        };

      case CASH_SOURCES_YEARLY_DATA:
        if (hashedData === state.cashSourcesData?.checksum) {
          return { ...state, cashSourcesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashSourcesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashSourcesDataUpdating: false,
        };

      case CASH_SOURCES_QUARTERLY_DATA:
        if (hashedData === state.cashSourcesData?.checksum) {
          return { ...state, cashSourcesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashSourcesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashSourcesDataUpdating: false,
        };

      case CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA:
        if (hashedData === state.cashFromOperatingActivitiesData?.checksum) {
          return { ...state, cashFromOperatingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromOperatingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromOperatingActivitiesDataUpdating: false,
        };

      case CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA:
        if (hashedData === state.cashFromOperatingActivitiesData?.checksum) {
          return { ...state, cashFromOperatingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromOperatingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromOperatingActivitiesDataUpdating: false,
        };

      case CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA:
        if (hashedData === state.cashFromInvestingActivitiesData?.checksum) {
          return { ...state, cashFromInvestingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromInvestingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromInvestingActivitiesDataUpdating: false,
        };

      case CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA:
        if (hashedData === state.cashFromInvestingActivitiesData?.checksum) {
          return { ...state, cashFromInvestingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromInvestingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromInvestingActivitiesDataUpdating: false,
        };

      case CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA:
        if (hashedData === state.cashFromFinancingActivitiesData?.checksum) {
          return { ...state, cashFromFinancingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromFinancingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromFinancingActivitiesDataUpdating: false,
        };

      case CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA:
        if (hashedData === state.cashFromFinancingActivitiesData?.checksum) {
          return { ...state, cashFromFinancingActivitiesDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          cashFromFinancingActivitiesData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          cashFromFinancingActivitiesDataUpdating: false,
        };
      case CF_STATEMENTS_YEARLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            // tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      case CF_STATEMENTS_QUARTERLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            // tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      default:
        return state;
    }
  }
};

export default cashflowAnalysisVisualsReducer;
