import { ITokenIsAdmin, IUserInformation, Token } from '@prometeus/common';

export interface IFirstLogin {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IMicrosoftLogin {
  email: string;
  tokenId: string;
}

// eslint-disable-next-line no-shadow
export enum LocalStorageEnum {
  THEME = 'PROMETEUS_THEME',
  TOKEN = 'PROMETEUS_TOKEN',
  TICKER_ID = 'PROMETEUS_TICKER_ID',
}

export interface IAuthenticationState {
  accessToken?: Token;
  error?: string;
  loading?: boolean;
  renewingToken?: boolean; // loading for renew token
  isAdmin: boolean;
  hasActiveLicence?: boolean | string;
  isFirstLogin?: boolean;
  hasRated?: boolean;
}

/* SIGN_IN */
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export interface ISignIn {
  type: typeof SIGN_IN;
}

export interface ISignInFail {
  type: typeof SIGN_IN_FAIL;
  payload: string;
}

export interface ISignInSuccessPayload extends ITokenIsAdmin {
  userInformation: IUserInformation;
  isFirstLogin?: boolean;
  hasRated?: boolean;
}

export interface ISignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: ISignInSuccessPayload;
}

export type SignInDispachTypes = ISignIn | ISignInFail | ISignInSuccess;

/* SIGN_OUT */
export const SIGN_OUT = 'SIGN_OUT';

export interface ISignOut {
  type: typeof SIGN_OUT;
}

/* EMBED_TOKEN */
export const RENEW_TOKEN = 'RENEW_TOKEN';
export const RENEW_TOKEN_FAIL = 'RENEW_TOKEN_FAIL';

export interface RenewTokenI {
  type: typeof RENEW_TOKEN;
}

export interface RenewTokenFailI {
  type: typeof RENEW_TOKEN_FAIL;
  payload: string;
}

export type RenewTokenDispachTypes = RenewTokenI | RenewTokenFailI;

/* SET_EMBED_TOKEN_EXPIRED */
export const SET_EMBED_TOKEN_EXPIRED = 'SET_EMBED_TOKEN_EXPIRED';

export interface SetEmbedTokenExpiredI {
  type: typeof SET_EMBED_TOKEN_EXPIRED;
}

/* SET_IS_FIRST_LOGIN */
export const SET_IS_FIRST_LOGIN = 'SET_IS_FIRST_LOGIN';

export interface ISetIsFirstLogin {
  type: typeof SET_IS_FIRST_LOGIN;
  payload: boolean;
}

/* SET_HAS_RATED */
export const SET_HAS_RATED = 'SET_HAS_RATED';
export interface ISetHasRated {
  type: typeof SET_HAS_RATED;
  payload: boolean;
}

/* SET_HAS_ACTIVE_LICENCE */
export const SET_HAS_ACTIVE_LICENCE = 'SET_HAS_ACTIVE_LICENCE';
export interface ISetHasActiveLicence {
  type: typeof SET_HAS_ACTIVE_LICENCE;
  payload: boolean;
}

/* SET_IS_ADMIN */
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export interface ISetIsAdmin {
  type: typeof SET_IS_ADMIN;
  payload: boolean;
}

/* GET_LOGGED_USER_DATA */
export const GET_LOGGED_USER_DATA = 'GET_LOGGED_USER_DATA';
export const GET_LOGGED_USER_DATA_FAIL = 'GET_LOGGED_USER_DATA_FAIL';
export const GET_LOGGED_USER_DATA_SUCCESS = 'GET_LOGGED_USER_DATA_SUCCESS';

export interface IGetLoggedUserData {
  type: typeof GET_LOGGED_USER_DATA;
}

export interface IGetLoggedUserDataFail {
  type: typeof GET_LOGGED_USER_DATA_FAIL;
  payload: string;
}

export interface IGetLoggedUserDataSuccess {
  type: typeof GET_LOGGED_USER_DATA_SUCCESS;
}

export type GetLoggedUserDataDispachTypes =
  | IGetLoggedUserData
  | IGetLoggedUserDataFail
  | IGetLoggedUserDataSuccess;

/* DispatchActionTypes */
export type DispatchActionTypes =
  | SignInDispachTypes
  | ISignOut
  | RenewTokenDispachTypes
  | SetEmbedTokenExpiredI
  | ISetIsFirstLogin
  | ISetHasRated
  | ISetHasActiveLicence
  | GetLoggedUserDataDispachTypes
  | ISetIsAdmin;
