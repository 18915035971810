import {
  Stack,
  tableCellClasses,
  tableRowClasses,
  useTheme,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ICompanyMinimal, IPlan, ITableColumnData } from '@prometeus/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { NA_LABEL } from '../../constants/general.constants';
import { IScreenerResult } from '../../models/screener/results.model';
import { RouteEnum } from '../../navigation/routes.constants';
import { BLUR } from '../../styles/common.styles';
import IconComponent from '../icon.component';
import * as tableUtils from '../table/table.constants';

type Props = {
  data: (ICompanyMinimal | IScreenerResult | IPlan)[];
  tableColumns: ITableColumnData[];
  emptyRowsMessage?: string;
  formatCellValue?: (row: any, column: any) => string;
  /* Sides */
  leftCell?: (...args: any[]) => React.ReactNode;
  rightCell?: (...args: any[]) => React.ReactNode;
  /* Pagination */
  page: number;
  rowsPerPage: number;
  maxHeight: number;
  hasPaginatedData?: boolean;
  /* Sorting */
  order: tableUtils.Order;
  orderBy: string;
  /* TickerID */
  clickableTickerId?: boolean;
  onTickerIdClick?: (...args: any[]) => void;
  noTickerCell?: boolean;
  /* Other */
  isBlurredRow?: (index: number) => boolean;
  onClickRow?: (value: any) => void;
};

const TableBodyComponent = ({
  data,
  tableColumns,
  emptyRowsMessage,
  formatCellValue: propsFormatCellValue,
  leftCell,
  rightCell,
  page,
  rowsPerPage,
  maxHeight,
  order,
  orderBy,
  clickableTickerId,
  onTickerIdClick,
  noTickerCell,
  isBlurredRow,
  onClickRow,
  hasPaginatedData,
}: Props) => {
  const theme = useTheme();

  /* Hooks */
  const tableStyles = tableUtils.getTableStyles(theme.palette);

  const formatCellValue = (row: any, column: any): string => {
    if (propsFormatCellValue) {
      return propsFormatCellValue(row, column);
    }
    const rowValue = row[column.id];
    return tableUtils.isNullUndefinedEmptyStr(rowValue)
      ? NA_LABEL
      : String(rowValue);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length);

  const getTickerIdCellComponent = (
    row: ICompanyMinimal,
    column: ITableColumnData,
    rowIndex: number
  ) => {
    const component = (
      <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
        <IconComponent
          src={row.companyId}
          rest={{
            className: 'table-tickerId-icon',
            style: {
              width: '2rem',
              height: '2rem',
              marginRight: '0.8rem',
            },
          }}
        />
        <span className={clickableTickerId ? 'table-tickerId-text' : ''}>
          {formatCellValue(row, column)}
        </span>
      </Stack>
    );

    if (clickableTickerId) {
      return (
        <Link
          to={`${RouteEnum.COMPANY_SUMMARY}?tickerId=${row.tickerId}`}
          style={{ all: 'unset' }}
          onClick={(event) => {
            if (onTickerIdClick) {
              onTickerIdClick(event, row.tickerId, rowIndex);
            }
          }}
        >
          {component}
        </Link>
      );
    }

    return component;
  };

  let rowsData = tableUtils.stableSort(data, order, orderBy);
  if (!hasPaginatedData) {
    rowsData = rowsData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }

  return (
    <TableBody>
      {rowsData?.map((row, rowIndex: number) => (
        <TableRow
          hover
          tabIndex={-1}
          key={rowIndex}
          onClick={() => onClickRow && onClickRow(row as any)}
          sx={{
            ...(!!isBlurredRow && isBlurredRow(rowIndex) ? BLUR : {}),
            [`& .${tableRowClasses.hover}`]: tableStyles.tableRow,
            '& *': {
              cursor: !!onClickRow ? 'pointer !important' : 'default',
            },
          }}
        >
          {leftCell && (
            <TableCell
              padding="checkbox"
              sx={{
                ...tableStyles.tableCellRoot,
                [`& .${tableCellClasses.paddingCheckbox}`]:
                  tableStyles.checkbox,
              }}
            >
              {leftCell(row)}
            </TableCell>
          )}
          {tableColumns
            .filter((e: ITableColumnData) => e.label)
            .map((column: ITableColumnData, index: number) => (
              <TableCell
                padding="normal"
                id={`${row[column.id]}-${column.id}`}
                key={`${row[column.id]}-${column.id}`}
                align={
                  // formatCellValue(row, column) === '-'
                  //   ? 'right'
                  //   : column.align
                  column.align
                }
                sx={{ ...tableStyles.tableCellRoot }}
              >
                {!index && !noTickerCell ? (
                  getTickerIdCellComponent(row, column, rowIndex)
                ) : (
                  <span>{formatCellValue(row, column)}</span>
                )}
              </TableCell>
            ))}
          {rightCell && (
            <TableCell
              padding="checkbox"
              sx={{
                ...tableStyles.tableCellRoot,
                [`& .${tableCellClasses.paddingCheckbox}`]:
                  tableStyles.checkbox,
              }}
            >
              {rightCell(row)}
            </TableCell>
          )}
        </TableRow>
      ))}
      {emptyRows > 0 && (
        <TableRow
          sx={{
            ...tableStyles.tableRow,
            height: tableUtils.getEmptyRowsHeight(
              emptyRows,
              maxHeight,
              rowsPerPage
            ),
          }}
          className="empty-row"
        >
          {data?.length ? (
            <TableCell
              padding="normal"
              sx={{
                ...tableStyles.emptyRowsCell,
                ...tableStyles.emptyRowsCellNoMessage,
              }}
              colSpan={tableColumns.length + 1}
            />
          ) : (
            <TableCell
              padding="normal"
              sx={{ ...tableStyles.emptyRowsCell }}
              colSpan={tableColumns.length + 1}
            >
              {emptyRowsMessage}
            </TableCell>
          )}
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableBodyComponent;
