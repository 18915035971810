import { ChartData, ChartDataset } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../../ui/colors';
import { OPERATING_EXPENSES_TITLE_LABELS } from '../utils/visuals.config';

const OperatingExpensesTab = () => {
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const dispatch = useDispatch();
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const operatingIncomeOnGrossProfitData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeOnGrossProfitData
  );
  const operatingIncomeOnGrossProfitDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeOnGrossProfitDataUpdating
  );
  const operationExpensesRDData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.operationExpensesRDData
  );
  const operatingIncomeDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeDistributionDataUpdating
  );
  const operationExpensesSGAData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.operationExpensesSGAData
  );
  const averageOperationExpensesDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement
        .averageOperationExpensesDistributionDataUpdating
  );
  const operationExpensesAmortDeprData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesAmortDeprData
  );
  const operationExpensesRDDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesRDDataUpdating
  );
  const operatingIncomeDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeDistributionData
  );
  const operationExpensesSGADataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesSGADataUpdating
  );
  const averageOperationExpensesDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.averageOperationExpensesDistributionData
  );
  const operationExpensesAmortDeprDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesAmortDeprDataUpdating
  );

  const [operatingIncomeOnGrossProfit, setOperatingIncomeOnGrossProfit] =
    useState<ChartData>();
  const [operationExpensesRD, setOperationExpensesRD] = useState<ChartData>();
  const [operationExpensesSGA, setOperationExpensesSGA] = useState<ChartData>();
  const [operationExpensesAmortDepr, setOperationExpensesAmortDepr] =
    useState<ChartData>();
  const [operatingIncomeDistribution, setOperatingIncomeDistribution] =
    useState<ChartData>();
  const [
    averageOperationExpensesDistribution,
    setAverageOperationExpensesDistribution,
  ] = useState<ChartData>();

  /* Operating Income On Gross Profit */
  const [
    operatingIncomeOnGrossProfitHasData,
    operatingIncomeOnGrossProfitHasLabels,
    operatingIncomeOnGrossProfitError,
  ] = extractChartMetadata(operatingIncomeOnGrossProfitData);
  useEffect(() => {
    setOperatingIncomeOnGrossProfit({
      datasets: createChartDatasetsConfig(
        operatingIncomeOnGrossProfitData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    operatingIncomeOnGrossProfitData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Operating Income Distribution */
  const [
    operatingIncomeDistributionHasData,
    operatingIncomeDistributionHasLabels,
    operatingIncomeDistributionError,
  ] = extractChartMetadata(operatingIncomeDistributionData);
  useEffect(() => {
    setOperatingIncomeDistribution({
      labels: operatingIncomeDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        operatingIncomeDistributionData,
        screenWidth,
        {
          useDollars,
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    operatingIncomeDistributionData,
    useDollars,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Operation Expenses R&D */
  const [
    operationExpensesRDHasData,
    operationExpensesRDHasLabels,
    operationExpensesRDError,
  ] = extractChartMetadata(operationExpensesRDData);
  useEffect(() => {
    setOperationExpensesRD({
      datasets: createChartDatasetsConfig(
        operationExpensesRDData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    operationExpensesRDData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Operation Expenses SG&A */
  const [
    operationExpensesSGAHasData,
    operationExpensesSGAHasLabels,
    operationExpensesSGAError,
  ] = extractChartMetadata(operationExpensesSGAData);
  useEffect(() => {
    setOperationExpensesSGA({
      datasets: createChartDatasetsConfig(
        operationExpensesSGAData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    operationExpensesSGAData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Operation Expenses Amort Depr */
  const [
    operationExpensesAmortDeprHasData,
    operationExpensesAmortDeprHasLabels,
    operationExpensesAmortDeprError,
  ] = extractChartMetadata(operationExpensesAmortDeprData);
  useEffect(() => {
    setOperationExpensesAmortDepr({
      datasets: createChartDatasetsConfig(
        operationExpensesAmortDeprData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    operationExpensesAmortDeprData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Average Operation Expenses Distribution */
  const [
    averageOperationExpensesDistributionHasData,
    averageOperationExpensesDistributionHasLabels,
    averageOperationExpensesDistributionError,
  ] = extractChartMetadata(averageOperationExpensesDistributionData);
  useEffect(() => {
    const datasets = createChartDatasetsConfig(
      averageOperationExpensesDistributionData,
      screenWidth,
      {
        useDollars,
        isPie: true,
        fiscalYear,
      }
    );

    setAverageOperationExpensesDistribution({
      labels: averageOperationExpensesDistributionData?.datasetLabels.map(
        (e) => e.label
      ),
      datasets: [
        {
          data: datasets.map((e: ChartDataset) => e.data as unknown as number),
          backgroundColor: datasets.map(
            (_, i: number) =>
              COMMON_COLORS[`chartColor${i + 1}` as keyof typeof COMMON_COLORS]
          ),
          borderColor: datasets.map(
            (_, i: number) =>
              COMMON_COLORS[`chartColor${i + 1}` as keyof typeof COMMON_COLORS]
          ),
          borderWidth: 1,
        },
      ],
    });
  }, [
    averageOperationExpensesDistributionData,
    useDollars,
    fiscalYear,
    screenWidth,
  ]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.OPERATING_EXPENSES));
  }, [dispatch]);

  return (
    <>
      {operatingIncomeOnGrossProfitDataUpdating &&
        operatingIncomeDistributionDataUpdating &&
        averageOperationExpensesDistributionDataUpdating &&
        operationExpensesRDDataUpdating &&
        operationExpensesSGADataUpdating &&
        operationExpensesAmortDeprDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={operatingIncomeOnGrossProfit}
          error={
            !!operatingIncomeOnGrossProfitError
              ? GENERAL_ERROR_MESSAGE
              : !operatingIncomeOnGrossProfitHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            OPERATING_EXPENSES_TITLE_LABELS.operatingIncomeOnGrossProfit
          }
          hasData={operatingIncomeOnGrossProfitHasData}
          exportData={operatingIncomeOnGrossProfitData}
          showLegend={true}
          currencySymbol={
            !!operatingIncomeOnGrossProfitData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!operatingIncomeOnGrossProfitData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!operatingIncomeOnGrossProfitDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={operatingIncomeDistribution}
          error={
            !!operatingIncomeDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !operatingIncomeDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            OPERATING_EXPENSES_TITLE_LABELS.operatingIncomeDistribution
          }
          hasData={operatingIncomeDistributionHasData}
          exportData={operatingIncomeDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!operatingIncomeDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!operatingIncomeDistributionData?.isPercentage}
          updating={!!operatingIncomeDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="pie"
          data={averageOperationExpensesDistribution}
          error={
            !!averageOperationExpensesDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !averageOperationExpensesDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            OPERATING_EXPENSES_TITLE_LABELS.averageOperationExpensesDistribution
          }
          hasData={averageOperationExpensesDistributionHasData}
          exportData={averageOperationExpensesDistributionData}
          showLegend={true}
          options={{
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              y: {
                display: false,
              },
            },
          }}
          currencySymbol={
            !!averageOperationExpensesDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={
            !!averageOperationExpensesDistributionData?.isPercentage
          }
          updating={!!averageOperationExpensesDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={operationExpensesRD}
          error={
            !!operationExpensesRDError
              ? GENERAL_ERROR_MESSAGE
              : !operationExpensesRDHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={OPERATING_EXPENSES_TITLE_LABELS.operationExpensesRD}
          hasData={operationExpensesRDHasData}
          exportData={operationExpensesRDData}
          showLegend={true}
          currencySymbol={
            !!operationExpensesRDData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!operationExpensesRDData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!operationExpensesRDDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={operationExpensesSGA}
          error={
            !!operationExpensesSGAError
              ? GENERAL_ERROR_MESSAGE
              : !operationExpensesSGAHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={OPERATING_EXPENSES_TITLE_LABELS.operationExpensesSGA}
          hasData={operationExpensesSGAHasData}
          exportData={operationExpensesSGAData}
          showLegend={true}
          currencySymbol={
            !!operationExpensesSGAData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!operationExpensesSGAData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!operationExpensesSGADataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={operationExpensesAmortDepr}
          error={
            !!operationExpensesAmortDeprError
              ? GENERAL_ERROR_MESSAGE
              : !operationExpensesAmortDeprHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            OPERATING_EXPENSES_TITLE_LABELS.operationExpensesAmortDepr
          }
          hasData={operationExpensesAmortDeprHasData}
          exportData={operationExpensesAmortDeprData}
          showLegend={true}
          currencySymbol={
            !!operationExpensesAmortDeprData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!operationExpensesAmortDeprData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!operationExpensesAmortDeprDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default OperatingExpensesTab;
