import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

export const actionErrorInterceptor = (
  dispatch: Dispatch<any>,
  type: string,
  err: any
) => {
  const response: AxiosResponse = err.response;

  console.log('actionErrorInterceptor:', err);
  // if (!!response?.status && [401, 402, 403].includes(response.status)) {
  //   dispatch(signOut());
  // }

  dispatch({
    type: type,
    payload: response?.data?.message || err.message,
  });
};
