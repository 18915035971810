import {
  Badge,
  badgeClasses,
  BadgeProps,
  Palette,
  useTheme,
} from '@mui/material';
import React from 'react';
import { pxToRem } from '../ui/functions';

const BADGE_SIZE_PX = 25;
const BADGE_POSITIONING_VERTICAL = '1.2rem';
const BADGE_POSITIONING_HORIZONTAL = '1.5rem';

const getStyles = (palette: Palette) => ({
  anchorOriginBottomRightRectangular: {
    bottom: BADGE_POSITIONING_VERTICAL,
    right: BADGE_POSITIONING_HORIZONTAL,
  },
  anchorOriginTopRightRectangular: {
    top: BADGE_POSITIONING_VERTICAL,
    right: BADGE_POSITIONING_HORIZONTAL,
  },
  badge: {
    border: `0.05rem solid ${palette.divider}`,
    height: pxToRem(BADGE_SIZE_PX),
    padding: `0 ${pxToRem(6)}`,
    minWidth: pxToRem(BADGE_SIZE_PX),
    borderRadius: pxToRem(BADGE_SIZE_PX / 2),
    fontSize: '0.9rem',
    transition: 'none',

    '& .badge-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& .badge-inner': {
        width: '0rem',
        overflow: 'hidden',
        display: 'inline-block',
        // transition: 'width 0.5s ease-in-out',
        '&.show': {
          width: '100%',
          marginRight: '0.3rem',
        },
      },
    },
  },
  root: {
    width: '100%',
    '&.hover .MuiBadge-badge': {
      right: '3rem !important',
      transition: 'right 0.3s ease-out',
    },
  },
});

type Props = {
  BadgeProps?: BadgeProps;
  label: string | number;
  hiddenLabel: string;
  show?: boolean;
  children: React.ReactChild;
};

const CustomBadge = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <Badge
      overlap="rectangular"
      showZero={true}
      color="primary"
      className={!!props.show ? 'hover' : ''}
      badgeContent={
        <span className="badge-content">
          <span className={`badge-inner${!!props.show ? ' show' : ''}`}>
            {props.hiddenLabel}:
          </span>
          {props.label}
        </span>
      }
      sx={{
        ...styles.root,
        [`& .${badgeClasses.badge}`]: styles.badge,
        [`& .${badgeClasses.anchorOriginBottomRightRectangular}`]:
          styles.anchorOriginBottomRightRectangular,
        [`& .${badgeClasses.anchorOriginTopRightRectangular}`]:
          styles.anchorOriginTopRightRectangular,
      }}
      {...props.BadgeProps}
      children={props.children}
    />
  );
};

export default CustomBadge;
