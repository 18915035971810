import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
import RoaRoicTab from './tabs/roa-roic.tab';
import RosRoeTab from './tabs/ros-roe.tab';

export enum ManagementEffectivenessRouteEnum {
  ROS_AND_ROE = 'ros-and-roe',
  ROA_AND_ROIC = 'roa-and-roic',
}

const tabs: ITab[] = [
  {
    label: 'ROS & ROE',
    route: ManagementEffectivenessRouteEnum.ROS_AND_ROE,
    component: RosRoeTab,
  },
  {
    label: 'ROA & ROIC',
    route: ManagementEffectivenessRouteEnum.ROA_AND_ROIC,
    component: RoaRoicTab,
  },
];

const ManagementEffectivenessPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.MANAGEMENT_EFFECTIVENESS}
      tabs={tabs}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default ManagementEffectivenessPage;
