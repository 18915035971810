import { Palette } from '@mui/material';

export const getFormStyles = (palette: Palette) => ({
  rowForm: {
    display: 'flex',
    marginTop: '0.5rem',
    color: palette.text.primary,
  },
  innerRowForm: {
    marginRight: '1rem',
    '&:last-child': {
      marginRight: 0,
    },
  },
  rowFormShort: {
    flex: '1 1 0',
  },
  rowFormLong: {
    flex: '3 1 0',
  },
  rowFormLongLong: {
    flex: '5 1 0',
  },
});
