import {
  DEV_SIGNIN_AUTH_URL,
  DUMMY_AUTH_URL,
  GET_LOGGED_USER_DATA_URL,
  GOOGLE_AUTH_URL,
  ILoggedUserData,
  ITokenIsAdmin,
  MICROSOFT_AUTH_URL,
  RENEW_TOKEN_AUTH_URL,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { ISignInSuccessPayload } from '../models/authentication.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const devSignInService = (
  devType: 'user' | 'admin'
): Promise<AxiosResponse<ISignInSuccessPayload>> =>
  axiosInstance.post(DEV_SIGNIN_AUTH_URL, { devType });

export const getDummyTokenService = (
  fingerprint: number
): Promise<AxiosResponse<ISignInSuccessPayload>> =>
  axiosInstance.post(DUMMY_AUTH_URL, { fingerprint });

export const googleSignInService = (
  tokenId: string,
  redirectUri: string
): Promise<AxiosResponse<ISignInSuccessPayload>> =>
  axiosInstance.post(GOOGLE_AUTH_URL, {
    tokenId,
    redirectUri,
  });

export const microsoftSignInService = (
  tokenId: string
): Promise<AxiosResponse<ISignInSuccessPayload>> =>
  axiosInstance.post(MICROSOFT_AUTH_URL, {
    tokenId,
  });

export const renewTokenService = (): Promise<AxiosResponse<ITokenIsAdmin>> =>
  axiosInstance.get(RENEW_TOKEN_AUTH_URL);

export const getLoggedUserDataService = (): Promise<
  AxiosResponse<ILoggedUserData>
> => axiosInstance.get(GET_LOGGED_USER_DATA_URL);
