import {
  ACTIVATE_FREE_TRIAL,
  ACTIVATE_FREE_TRIAL_FAIL,
  ACTIVATE_FREE_TRIAL_SUCCESS,
  DispatchActionTypes,
  FETCH_LICENCES_HISTORY,
  FETCH_LICENCES_HISTORY_FAIL,
  FETCH_LICENCES_HISTORY_SUCCESS,
  IUserState,
  RESET_USER_INFORMATION,
  SET_USER_INFORMATION,
  SUBMIT_USER_REGISTRATION_INFORMATION,
  SUBMIT_USER_REGISTRATION_INFORMATION_FAIL,
} from '../../models/user.model';

export const initialStructureState: IUserState = {
  loading: true,
};

const userReducer = (
  state: IUserState = initialStructureState,
  action: DispatchActionTypes
): IUserState => {
  switch (action.type) {
    case SUBMIT_USER_REGISTRATION_INFORMATION:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SUBMIT_USER_REGISTRATION_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_USER_INFORMATION:
      return {
        ...state,
        userInformation: { ...state.userInformation, ...action.payload },
      };

    case RESET_USER_INFORMATION:
      return {
        ...state,
        userInformation: undefined,
      };

    case FETCH_LICENCES_HISTORY:
      return { ...state, loading: true, error: undefined };
    case FETCH_LICENCES_HISTORY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FETCH_LICENCES_HISTORY_SUCCESS:
      return {
        ...state,
        licencesHistory: action.payload,
      };

    case ACTIVATE_FREE_TRIAL:
      return { ...state, loading: true, error: undefined };
    case ACTIVATE_FREE_TRIAL_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ACTIVATE_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        userInformation: !!state.userInformation
          ? {
            ...state.userInformation,
            usedFreeTrial: true,
          }
          : undefined,
      };
    default:
      return state;
  }
};

export default userReducer;
