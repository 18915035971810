import { IUserData, UserRoleUnion } from '@prometeus/common';
import { DumpType } from '../general.model';

export interface IMappedUserData {
  // General data
  userId: string;
  email: string;
  role: UserRoleUnion;
  notDeletable?: boolean;
  licenceExpirationDate?: number;
  logins: number;
  firstLogin?: number;
  lastLogin?: number;

  // User information
  organization?: string;
  name?: string;
}

export interface IUsersState {
  users: IMappedUserData[];
  filterValue?: string;
  userDetail?: IUserData;
  loading: boolean;
  error?: string;

  userDump?: DumpType;
}

/* FETCH_USERS */
export const FETCH_USERS = 'FETCH_USERS';
export interface IFetchUsers {
  type: typeof FETCH_USERS;
}

/* FETCH_USERS_SUCCESS */
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export interface IFetchUsersSuccess {
  type: typeof FETCH_USERS_SUCCESS;
  payload: IUserData[];
}

/* FETCH_USERS_FAIL */
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export interface IFetchUsersFail {
  type: typeof FETCH_USERS_FAIL;
  payload: string;
}

export type FetchUsersDispatchTypes =
  | IFetchUsers
  | IFetchUsersSuccess
  | IFetchUsersFail;

/* FETCH_USER */
export const FETCH_USER = 'FETCH_USER';
export interface IFetchUser {
  type: typeof FETCH_USER;
}

/* FETCH_USER_SUCCESS */
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export interface IFetchUserSuccess {
  type: typeof FETCH_USER_SUCCESS;
  payload: IUserData;
}

/* FETCH_USER_FAIL */
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export interface IFetchUserFail {
  type: typeof FETCH_USER_FAIL;
  payload: string;
}

export type FetchUserDispatchTypes =
  | IFetchUser
  | IFetchUserSuccess
  | IFetchUserFail;

/* FILTER_USERS */
export const FILTER_USERS = 'FILTER_USERS';
export interface IFilterUsers {
  type: typeof FILTER_USERS;
  payload: string;
}

/* CREATE_USER */
export const CREATE_USER = 'CREATE_USER';
export interface ICreateUser {
  type: typeof CREATE_USER;
}

/* CREATE_USER_SUCCESS */
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export interface ICreateUserSuccess {
  type: typeof CREATE_USER_SUCCESS;
  payload: IUserData;
}

/* CREATE_USER_FAIL */
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export interface ICreateUserFail {
  type: typeof CREATE_USER_FAIL;
  payload: string;
}

export type CreateUserDispatchTypes =
  | ICreateUser
  | ICreateUserSuccess
  | ICreateUserFail;

/* DELETE_USER */
export const DELETE_USER = 'DELETE_USER';
export interface IDeleteUser {
  type: typeof DELETE_USER;
}

/* DELETE_USER_SUCCESS */
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export interface IDeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
  payload: IUserData[];
}

/* DELETE_USER_FAIL */
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export interface IDeleteUserFail {
  type: typeof DELETE_USER_FAIL;
  payload: string;
}

export type DeleteUserDispatchTypes =
  | IDeleteUser
  | IDeleteUserSuccess
  | IDeleteUserFail;

/* UPDATE_USER */
export const UPDATE_USER = 'UPDATE_USER';
export interface IUpdateUser {
  type: typeof UPDATE_USER;
}

/* UPDATE_USER_SUCCESS */
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export interface IUpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
  payload: IUserData;
}

/* UPDATE_USER_FAIL */
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export interface IUpdateUserFail {
  type: typeof UPDATE_USER_FAIL;
  payload: string;
}

export type UpdateUserDispatchTypes =
  | IUpdateUser
  | IUpdateUserSuccess
  | IUpdateUserFail;

/* CLEAN_UP_USER_DETAIL */
export const CLEAN_UP_USER_DETAIL = 'CLEAN_UP_USER_DETAIL';
export interface ICleanUpUserDetail {
  type: typeof CLEAN_UP_USER_DETAIL;
}

/* RESET_USER_ERROR */
export const RESET_USER_ERROR = 'RESET_USER_ERROR';
export interface IResetUserError {
  type: typeof RESET_USER_ERROR;
}

/* USER_DUMP_DB */
export const USER_DUMP_DB = 'USER_DUMP_DB';
export interface IUserDumpDB {
  type: typeof USER_DUMP_DB;
}

/* USER_DUMP_DB_SUCCESS */
export const USER_DUMP_DB_SUCCESS = 'USER_DUMP_DB_SUCCESS';
export interface IUserDumpDBSuccess {
  type: typeof USER_DUMP_DB_SUCCESS;
  payload: DumpType;
}

/* USER_DUMP_DB_FAIL */
export const USER_DUMP_DB_FAIL = 'USER_DUMP_DB_FAIL';
export interface IUserDumpDBFail {
  type: typeof USER_DUMP_DB_FAIL;
  payload: string;
}

export type UserDumpDBDispatchTypes =
  | IUserDumpDB
  | IUserDumpDBSuccess
  | IUserDumpDBFail;

/* REVOKE_USER_LICENCE */
export const REVOKE_USER_LICENCE = 'REVOKE_USER_LICENCE';
export interface IRevokeUserLicence {
  type: typeof REVOKE_USER_LICENCE;
}

/* REVOKE_USER_LICENCE_SUCCESS */
export const REVOKE_USER_LICENCE_SUCCESS = 'REVOKE_USER_LICENCE_SUCCESS';
export interface IRevokeUserLicenceSuccess {
  type: typeof REVOKE_USER_LICENCE_SUCCESS;
  payload: IUserData;
}

/* REVOKE_USER_LICENCE_FAIL */
export const REVOKE_USER_LICENCE_FAIL = 'REVOKE_USER_LICENCE_FAIL';
export interface IRevokeUserLicenceFail {
  type: typeof REVOKE_USER_LICENCE_FAIL;
  payload: string;
}

export type RevokeUserLicenceDispatchTypes =
  | IRevokeUserLicence
  | IRevokeUserLicenceSuccess
  | IRevokeUserLicenceFail;

export type DispatchActionTypes =
  | FetchUsersDispatchTypes
  | IFilterUsers
  | FetchUserDispatchTypes
  | CreateUserDispatchTypes
  | DeleteUserDispatchTypes
  | UpdateUserDispatchTypes
  | ICleanUpUserDetail
  | IResetUserError
  | UserDumpDBDispatchTypes
  | RevokeUserLicenceDispatchTypes;
