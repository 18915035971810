import { ISectorData } from '@prometeus/common';
import { SectorDataFieldsEnum } from '../models/companies.model';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../models/screener/structure.model';

const handleSelectedCountryInner = (
  dataList: ISecIndCty[],
  index: number
): ISecIndCty[] => {
  // Initialisation
  const isAll = index <= 0;

  // Main logic
  if (isAll) {
    const notEvery =
      index < 0 ? true : dataList.every((e: ISecIndCty) => !e.selected);

    if (notEvery) {
      return dataList.map((e: ISecIndCty) => ({ ...e, selected: true }));
    } else {
      return dataList.map((e: ISecIndCty) => ({ ...e, selected: false }));
    }
  } else {
    // const newDataList = dataList;
    dataList[index] = {
      ...dataList[index],
      selected: !dataList[index].selected,
    };

    const every = dataList.slice(1).every((e: ISecIndCty) => e.selected);
    const selected = every ? true : false;

    dataList[0] = {
      ...dataList[0],
      selected: selected,
    };

    return [...dataList];
  }
};

export const handleSelectedCountry = (
  selectedCountries: ISecIndCty[],
  index: number,
  sectorData: ISectorData[],
  sectors: ISectorIndustry[]
): {
  selectedCountries: ISecIndCty[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
} => {
  const newCountryIds: ISecIndCty[] = handleSelectedCountryInner(
    selectedCountries,
    index
  );

  const selected: string[] = newCountryIds
    .filter((e: ISecIndCty) => e.selected)
    .map((e: ISecIndCty) => e.label);

  const lower: string[] = sectorData
    ? sectorData
        .filter((e: ISectorData) =>
          selected.includes(
            `${e[SectorDataFieldsEnum.countryId]} - ${
              e[SectorDataFieldsEnum.countryName]
            }`
          )
        )
        .map((e: ISectorData) => e[SectorDataFieldsEnum.sector])
    : [];
  const lowerSet = new Set(lower);

  const updatedSectorsWCountries: ISectorIndustry[] = sectors.map(
    (e: ISectorIndustry) => ({
      ...e,
      shown: e.index === 0 ? true : lowerSet.has(e.label),
    })
  );

  const selectedIndustries = updatedSectorsWCountries
    .filter((e: ISectorIndustry) => e.selected)
    .reduce(
      (prev: string[], current: ISectorIndustry) => [
        ...prev,
        ...current.industries
          .filter((ind: ISecIndCty) => ind.selected)
          .map((ind: ISecIndCty) => ind.label),
      ],
      []
    );

  return {
    selectedCountries: newCountryIds,
    sectors: updatedSectorsWCountries,
    selectedIndustries: selectedIndustries,
  };
};

const handleSelectedSectorInner = (
  dataList: ISectorIndustry[],
  index: number
): ISectorIndustry[] => {
  // Initialisation
  const isAll = index <= 0;

  // Main logic
  if (isAll) {
    const notEvery =
      index < 0 ? true : dataList.every((e: ISectorIndustry) => !e.selected);
    dataList[0] = {
      ...dataList[0],
      indeterminate: false,
    };
    return dataList.map((e: ISectorIndustry) => ({
      ...e,
      selected: notEvery,
      industries: e.industries.map((industry: ISecIndCty) => ({
        ...industry,
        selected: notEvery,
      })),
    }));
  } else {
    // Sector
    let industries = dataList[index].industries;
    const everyIndustry = industries.every((e: ISecIndCty) => e.selected);
    const notEveryIndustry = industries.every((e: ISecIndCty) => !e.selected);
    const indeterminateSector =
      everyIndustry || notEveryIndustry ? false : true;

    const selectedSector = !dataList[index].selected;

    industries = industries.map((e: ISecIndCty) => ({
      ...e,
      selected: selectedSector,
    }));

    dataList[index] = {
      ...dataList[index],
      industries: industries,
      selected: selectedSector,
      indeterminate: selectedSector && indeterminateSector ? true : false,
    };

    // All Option
    const every = dataList.slice(1).every((e: ISectorIndustry) => e.selected);
    const notEvery = dataList.every((e: ISectorIndustry) => !e.selected);
    const selected = every ? true : false;
    const indeterminate = every || notEvery ? false : true;

    dataList[0] = {
      ...dataList[0],
      selected: selected,
      indeterminate: indeterminate,
    };

    return [...dataList];
  }
};

export const handleSelectedSector = (
  sectors: ISectorIndustry[],
  index: number
): {
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
} => {
  const newSectors: ISectorIndustry[] = handleSelectedSectorInner(
    sectors,
    index
  );

  const selectedIndustries = newSectors
    .filter((e: ISectorIndustry) => e.selected)
    .reduce(
      (prev: string[], current: ISectorIndustry) => [
        ...prev,
        ...current.industries
          .filter((ind: ISecIndCty) => ind.selected)
          .map((ind: ISecIndCty) => ind.label),
      ],
      []
    );

  return {
    sectors: newSectors,
    selectedIndustries: selectedIndustries,
  };
};

const handleSelectedIndustryInner = (
  dataList: ISectorIndustry[],
  sectorIndex: number,
  industryIndex: number
): ISectorIndustry[] => {
  // Initialisation
  const indIndex = +`${industryIndex}`.split('0000')[1];
  const sector = dataList[sectorIndex];
  const industries = sector.industries;
  const industry = industries[indIndex];

  industries[indIndex] = {
    ...industry,
    selected: !industry.selected,
  };

  const everyIndustry = industries.every((e: ISecIndCty) => e.selected);
  const notEveryIndustry = industries.every((e: ISecIndCty) => !e.selected);
  const oldSelected = sector.selected;

  const selectedSector = notEveryIndustry ? false : true;
  const indeterminateSector = everyIndustry || notEveryIndustry ? false : true;

  dataList[sectorIndex] = {
    ...sector,
    industries: industries,
    selected: selectedSector,
    indeterminate: indeterminateSector,
  };

  if (oldSelected !== selectedSector) {
    // All Option
    const every = dataList.slice(1).every((e: ISectorIndustry) => e.selected);
    const notEvery = dataList.every((e: ISectorIndustry) => !e.selected);
    const selected = every ? true : false;
    const indeterminate = every || notEvery ? false : true;
    dataList[0] = {
      ...dataList[0],
      selected: selected,
      indeterminate: indeterminate,
    };
  }

  return [...dataList];
};

export const handleSelectedIndustry = (
  sectors: ISectorIndustry[],
  sectorIndex: number,
  industryIndex: number
): { sectors: ISectorIndustry[]; selectedIndustries: string[] } => {
  const newIndustries: ISectorIndustry[] = handleSelectedIndustryInner(
    sectors,
    sectorIndex,
    industryIndex
  );

  const selectedIndustries = newIndustries
    .filter((e: ISectorIndustry) => e.selected)
    .reduce(
      (prev: string[], current: ISectorIndustry) => [
        ...prev,
        ...current.industries
          .filter((ind: ISecIndCty) => ind.selected)
          .map((ind: ISecIndCty) => ind.label),
      ],
      []
    );

  return {
    sectors: newIndustries,
    selectedIndustries: selectedIndustries,
  };
};

export interface ISectorsPayload {
  sectorData: ISectorData[];
  selectedCountries: ISecIndCty[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
}

export const handleGetSectorIndustryCountryData = (
  data: ISectorData[]
): {
  selectedCountries: ISecIndCty[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
} => {
  const sectors: string[] = [];
  const industries: string[] = [];
  const countries: string[] = [];
  const selectedIndustries: string[] = [];

  if (!!data?.length) {
    data?.forEach((e: ISectorData) => {
      sectors.push(e.sector);
      industries.push(`${e.sector}§${e.industry}`);
      countries.push(`${e.countryId} - ${e.countryName}`);
      selectedIndustries.push(e.industry);
    });
  }

  // Build sector-industry array
  const selectedSectors: ISectorIndustry[] = [];
  selectedSectors.push({
    label: 'All Sectors',
    shown: true,
    selected: true,
    indeterminate: false,
    index: 0,
    industries: [],
  });
  let oldSector = '';
  let sectorIndex = 0;
  let industriesOfSector: string[] = [];
  industries
    .sort((a: string, b: string): number => {
      const splitA = a.split('§');
      const splitB = b.split('§');

      if (splitA[0] === splitB[0]) {
        return splitA[1].localeCompare(splitB[1]);
      } else {
        return splitA[0].localeCompare(splitB[0]);
      }
    })
    .forEach((sectorIndustry: string) => {
      const splitted: string[] = sectorIndustry.split('§');
      const sector = splitted[0];
      const industry = splitted[1];
      if (sector !== oldSector) {
        if (sectorIndex >= 1) {
          selectedSectors.push({
            label: oldSector,
            index: sectorIndex,
            selected: true,
            shown: true,
            indeterminate: false,
            industries: [...new Set(industriesOfSector)]
              .sort()
              .map((e: string, index: number) => ({
                label: e,
                selected: true,
                shown: true,
                index: +`${sectorIndex}0000${index}`,
              })),
          });
        }
        oldSector = sector;
        sectorIndex += 1;
        industriesOfSector = [];
      }
      industriesOfSector.push(industry);
    });
  // last element did not pushed otherwise
  selectedSectors.push({
    label: oldSector,
    index: sectorIndex,
    selected: true,
    shown: true,
    indeterminate: false,
    industries: [...new Set(industriesOfSector)]
      .sort()
      .map((e: string, index: number) => ({
        label: e,
        selected: true,
        shown: true,
        index: +`${sectorIndex}0000${index}`,
      })),
  });

  return {
    selectedCountries: ['All Countries']
      .concat([...new Set(countries)].sort())
      .map((e: string, index: number) => ({
        label: e,
        selected: true,
        shown: true,
        index: index,
      })),
    sectors: selectedSectors,
    selectedIndustries: [...new Set(selectedIndustries)],
  };
};
