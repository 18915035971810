import { IUILicence, IUserInformation } from '@prometeus/common';

export interface ILicencesHistory {
  activeLicence: IUILicence;
  licencesHistory: IUILicence[];
}

export interface IUserState {
  loading: boolean;
  userInformation?: IUserInformation;
  error?: string;
  licencesHistory?: ILicencesHistory;
}

/* SUBMIT_USER_REGISTRATION_INFORMATION */
export const SUBMIT_USER_REGISTRATION_INFORMATION =
  'SUBMIT_USER_REGISTRATION_INFORMATION';
export interface ISubmitUserRegistrationInformation {
  type: typeof SUBMIT_USER_REGISTRATION_INFORMATION;
}

/* SUBMIT_USER_REGISTRATION_INFORMATION_FAIL */
export const SUBMIT_USER_REGISTRATION_INFORMATION_FAIL =
  'SUBMIT_USER_REGISTRATION_INFORMATION_FAIL';
export interface ISubmitUserRegistrationInformationFail {
  type: typeof SUBMIT_USER_REGISTRATION_INFORMATION_FAIL;
  payload: string;
}

/* SET_USER_INFORMATION */
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export interface ISetUserInformation {
  type: typeof SET_USER_INFORMATION;
  payload: IUserInformation;
}

export type SubmitUserRegistrationInformationDispatchTypes =
  | ISubmitUserRegistrationInformation
  | ISubmitUserRegistrationInformationFail
  | ISetUserInformation;

/* UPDATE_USER_REGISTRATION_INFORMATION */
export const UPDATE_USER_REGISTRATION_INFORMATION =
  'UPDATE_USER_REGISTRATION_INFORMATION';
export interface IUpdateUserRegistrationInformation {
  type: typeof UPDATE_USER_REGISTRATION_INFORMATION;
}

/* UPDATE_USER_REGISTRATION_INFORMATION_FAIL */
export const UPDATE_USER_REGISTRATION_INFORMATION_FAIL =
  'UPDATE_USER_REGISTRATION_INFORMATION_FAIL';
export interface IUpdateUserRegistrationInformationFail {
  type: typeof UPDATE_USER_REGISTRATION_INFORMATION_FAIL;
  payload: string;
}

export type UpdateUserRegistrationInformationDispatchTypes =
  | IUpdateUserRegistrationInformation
  | IUpdateUserRegistrationInformationFail
  | ISetUserInformation;

/* RESET_USER_INFORMATION */
export const RESET_USER_INFORMATION = 'RESET_USER_INFORMATION';
export interface IResetUserInformation {
  type: typeof RESET_USER_INFORMATION;
}

/* FETCH_LICENCES_HISTORY */
export const FETCH_LICENCES_HISTORY = 'FETCH_LICENCES_HISTORY';
export interface IFetchLicencesHistory {
  type: typeof FETCH_LICENCES_HISTORY;
}

/* FETCH_LICENCES_HISTORY_SUCCESS */
export const FETCH_LICENCES_HISTORY_SUCCESS = 'FETCH_LICENCES_HISTORY_SUCCESS';
export interface IFetchLicencesHistorySuccess {
  type: typeof FETCH_LICENCES_HISTORY_SUCCESS;
  payload: ILicencesHistory;
}

/* FETCH_LICENCES_HISTORY_FAIL */
export const FETCH_LICENCES_HISTORY_FAIL = 'FETCH_LICENCES_HISTORY_FAIL';
export interface IFetchLicencesHistoryFail {
  type: typeof FETCH_LICENCES_HISTORY_FAIL;
  payload: string;
}

export type FetchLicencesHistoryDispatchTypes =
  | IFetchLicencesHistory
  | IFetchLicencesHistorySuccess
  | IFetchLicencesHistoryFail;

/* ACTIVATE_FREE_TRIAL */
export const ACTIVATE_FREE_TRIAL = 'ACTIVATE_FREE_TRIAL';
export interface IActivateFreeTrial {
  type: typeof ACTIVATE_FREE_TRIAL;
}

/* ACTIVATE_FREE_TRIAL_SUCCESS */
export const ACTIVATE_FREE_TRIAL_SUCCESS = 'ACTIVATE_FREE_TRIAL_SUCCESS';
export interface IActivateFreeTrialSuccess {
  type: typeof ACTIVATE_FREE_TRIAL_SUCCESS;
}

/* ACTIVATE_FREE_TRIAL_FAIL */
export const ACTIVATE_FREE_TRIAL_FAIL = 'ACTIVATE_FREE_TRIAL_FAIL';
export interface IActivateFreeTrialFail {
  type: typeof ACTIVATE_FREE_TRIAL_FAIL;
  payload: string;
}

export type ActivateFreeTrialDispatchTypes =
  | IActivateFreeTrial
  | IActivateFreeTrialSuccess
  | IActivateFreeTrialFail;

export type DispatchActionTypes =
  | SubmitUserRegistrationInformationDispatchTypes
  | IResetUserInformation
  | UpdateUserRegistrationInformationDispatchTypes
  | FetchLicencesHistoryDispatchTypes
  | ActivateFreeTrialDispatchTypes;
