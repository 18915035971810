import { ICompanyMinimal } from '@prometeus/common';
import { Dispatch } from 'redux';
import {
  DWMQFilter,
  FETCH_CHART_DATA,
  FETCH_CHART_DATA_FAIL,
  FETCH_CHART_DATA_SUCCESS,
  PageIdEnum,
  QYFilter,
  SET_DWMQ_FILTER,
  SET_FISCAL_YEAR,
  SET_PAGE,
  SET_PEERS,
  SET_PEER_GROUP,
  SET_QY_FILTER,
  SET_TICKER_ID,
  SET_USE_DOLLARS,
  TimeframeEnum,
  TimeframeFilter,
} from '../../models/filters.model';
import {
  PeerGroupEnum,
  SET_PEERS_PEER_GROUP,
} from '../../models/peer-group.model';
import {
  ChartTypeEnum,
  IFetchChartDataBody,
  IFetchChartDataVisual,
  IVisualConfig,
} from '../../models/visuals.model';
import { TOP_PEERS_CONFIG } from '../../pages/company-summary/utils/visuals.config';
import { fetchChartDataService } from '../../services/filters.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';

export const setTickerId = (tickerId: string, skipFetch?: boolean) => ({
  type: SET_TICKER_ID,
  payload: { tickerId, skipFetch },
});

export const setPeerGroup = (peerGroup: PeerGroupEnum) => ({
  type: SET_PEER_GROUP,
  payload: peerGroup,
});

export const setPeers = (peers: string[]) => ({
  type: SET_PEERS,
  payload: { peers },
});

export const setFiscalYear = (fiscalYear: [number, number]) => ({
  type: SET_FISCAL_YEAR,
  payload: fiscalYear,
});

export const setQYFilter = (filter: QYFilter) => ({
  type: SET_QY_FILTER,
  payload: filter,
});

export const setDWMQFilter = (filter: DWMQFilter) => ({
  type: SET_DWMQ_FILTER,
  payload: filter,
});

export const setUseDollars = (mode: boolean) => ({
  type: SET_USE_DOLLARS,
  payload: mode,
});

const makeFetchChartDataBody = (
  config: IVisualConfig[],
  tickerId: string,
  years: [number, number],
  timeFrame: TimeframeFilter,
  peerGroup: PeerGroupEnum,
  peers: string[]
): IFetchChartDataBody => {
  // Dedupe config
  const visuals: IFetchChartDataVisual[] = [];

  let hasTopPeers = false;
  config.forEach((e: IVisualConfig) => {
    const { type } = e;
    const isTopPeers = type === ChartTypeEnum.TOP_PEERS;

    if (!isTopPeers || !hasTopPeers) {
      visuals.push({
        type,
        item: e.item,
        ascending: e.stateLabel !== 'statementsData',
      } as any);
    }

    if (isTopPeers) {
      hasTopPeers = true;
    }
  });

  return {
    tickerId,
    peerGroup,
    peers,
    timeFrame,
    minYear: years[0],
    maxYear: years[1],
    visuals,
  };
};

interface ITopPeers {
  'Company Name': string;
  CompanyId: string;
}

export const fetchChartData = (
  config: IVisualConfig[],
  tickerId: string,
  years: [number, number],
  timeframe: TimeframeFilter,
  peerGroup: PeerGroupEnum,
  peers: string[]
) => {
  return (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(dispatch, FETCH_CHART_DATA_FAIL, async () => {
      // Loading
      dispatch({ type: FETCH_CHART_DATA });

      const isRadar = !!config?.filter(
        (e: IVisualConfig) => e.type === ChartTypeEnum.RADAR_CHART
      )?.length;

      const response = await fetchChartDataService(
        makeFetchChartDataBody(
          config,
          tickerId?.toUpperCase(),
          years,
          isRadar ? TimeframeEnum.MONTHLY : timeframe,
          isRadar ? PeerGroupEnum.SUGGESTED : peerGroup,
          peers
        )
      );

      let chartData: string[] = [];
      if (typeof response.data === 'string') {
        chartData = JSON.parse(
          (response.data as string).replace(':NaN', ':"NaN"')
        )?.data;
      } else {
        chartData = response?.data?.data;
      }

      if (
        config.findIndex((e: IVisualConfig) => e.id === TOP_PEERS_CONFIG.id) >=
        0 &&
        peerGroup === PeerGroupEnum.SUGGESTED
      ) {
        const topPeers: ITopPeers[] =
          ([...(chartData || [])].pop() as unknown as ITopPeers[]) || [];

        dispatch({
          type: SET_PEERS_PEER_GROUP,
          payload: topPeers.map((e: ITopPeers) => ({
            tickerId: e.CompanyId,
            companyName: e['Company Name'],
          })) as ICompanyMinimal[],
        });
      }

      chartData?.forEach((tsv: string, index: number) => {
        const conf = config[index];

        dispatch({
          type: conf.dataAction,
          payload: {
            data: tsv,
            configData: {
              columns: conf.columns,
              datasetLabels: conf.datasetLabels,
              isCurrency: conf?.isCurrency,
              isPercentage: conf?.isPercentage,
            },
          },
        });
      });
      dispatch({ type: FETCH_CHART_DATA_SUCCESS });
    });
  };
};

export const setCurrentPage = (page: PageIdEnum) => ({
  type: SET_PAGE,
  payload: page,
});
