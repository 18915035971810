import { MD5 } from 'object-hash';
import { Dispatch } from 'redux';
import { makeUpdatingVisuals } from '../../../constants/charts/visuals.constants';
import {
  handleSelectedCountry,
  handleSelectedIndustry,
  handleSelectedSector,
} from '../../../constants/companies.constants';
import {
  MAX_FISCAL_YEAR,
  MIN_FISCAL_YEAR,
} from '../../../constants/general.constants';
import { RenderTypeEnum } from '../../../models/filters.model';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import {
  GroupBySectorEnum,
  IChartRawData,
  IVisualConfig,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import { processAVisualData } from '../utils/visuals.constants';
import { fetchSectorChartData } from './visuals.actions';
import * as SectorAnalysisConfig from './visuals.config';
import {
  ACTIONS_ARR,
  APPLY_CTY_SEC_IND_FILTERS,
  BOTTOM_COUNTRY_DATA,
  BOTTOM_DISTRIBUTION_COUNTRY_DATA,
  BOTTOM_DISTRIBUTION_INDUSTRY_DATA,
  BOTTOM_DISTRIBUTION_SECTOR_DATA,
  BOTTOM_INDUSTRY_DATA,
  BOTTOM_SECTOR_DATA,
  DispatchActionTypes,
  FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL,
  FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS,
  FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL,
  FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS,
  FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL,
  FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS,
  FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL,
  FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS,
  IVisualsState,
  SECTOR_ANALYSIS_UPDATE,
  SELECT_COUNTRY_COUNTRY_IDS,
  SELECT_COUNTRY_INDUSTRIES,
  SELECT_COUNTRY_SECTORS,
  SELECT_INDUSTRY_COUNTRY_IDS,
  SELECT_INDUSTRY_INDUSTRIES,
  SELECT_INDUSTRY_SECTORS,
  SELECT_SECTOR_COUNTRY_IDS,
  SELECT_SECTOR_INDUSTRIES,
  SELECT_SECTOR_SECTORS,
  SET_BOTTOM_CHART_OPTION,
  SET_SECTOR_GROUP_BY,
  SET_TIME_WINDOW,
  SET_TOP_CHART_OPTION,
  TOP_COUNTRY_DATA,
  TOP_DISTRIBUTION_COUNTRY_DATA,
  TOP_DISTRIBUTION_INDUSTRY_DATA,
  TOP_DISTRIBUTION_SECTOR_DATA,
  TOP_INDUSTRY_DATA,
  TOP_SECTOR_DATA,
} from './visuals.model';

const initialState: IVisualsState = {
  groupBy: GroupBySectorEnum.COUNTRY_ID,
  timeWindow: [MIN_FISCAL_YEAR, MAX_FISCAL_YEAR], // FIXME: Make years dynamic

  /* Countries */
  countryCountries: [],
  countrySectors: [],
  countrySelectedIndustries: [],
  /* Sectors */
  sectorCountries: [],
  sectorSectors: [],
  sectorSelectedIndustries: [],
  /* Industries */
  industryCountries: [],
  industrySectors: [],
  industrySelectedIndustries: [],
};

const CHART_ACTIONS_SET = new Set(ACTIONS_ARR);

const sectorAnalysisVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;
    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      /***************************************
       *                Country              *
       ***************************************/
      case TOP_COUNTRY_DATA:
        if (
          hashedData === state.topCountryData?.checksum ||
          !state.topOption ||
          !state.countryCountries?.filter((e: ISecIndCty) => e.selected)?.length
        ) {
          return { ...state, topCountryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topCountryData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.topOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          topCountryDataUpdating: false,
        };

      case TOP_DISTRIBUTION_COUNTRY_DATA:
        if (
          hashedData === state.topDistributionCountryData?.checksum ||
          !state.topOption ||
          !state.countryCountries?.filter((e: ISecIndCty) => e.selected)?.length
        ) {
          return { ...state, topDistributionCountryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topDistributionCountryData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          topDistributionCountryDataUpdating: false,
        };

      case BOTTOM_COUNTRY_DATA:
        if (
          hashedData === state.bottomCountryData?.checksum ||
          !state.bottomOption ||
          !state.countryCountries?.filter((e: ISecIndCty) => e.selected)?.length
        ) {
          return { ...state, bottomCountryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomCountryData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.bottomOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          bottomCountryDataUpdating: false,
        };

      case BOTTOM_DISTRIBUTION_COUNTRY_DATA:
        if (
          hashedData === state.bottomDistributionCountryData?.checksum ||
          !state.bottomOption ||
          !state.countryCountries?.filter((e: ISecIndCty) => e.selected)?.length
        ) {
          return { ...state, bottomDistributionCountryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomDistributionCountryData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          bottomDistributionCountryDataUpdating: false,
        };
      /***************************************
       *                Sector               *
       ***************************************/
      case TOP_SECTOR_DATA:
        if (
          hashedData === state.topSectorData?.checksum ||
          !state.topOption ||
          !state.sectorSectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, topSectorDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topSectorData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.topOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          topSectorDataUpdating: false,
        };

      case TOP_DISTRIBUTION_SECTOR_DATA:
        if (
          hashedData === state.topDistributionSectorData?.checksum ||
          !state.topOption ||
          !state.sectorSectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, topDistributionSectorDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topDistributionSectorData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          topDistributionSectorDataUpdating: false,
        };

      case BOTTOM_SECTOR_DATA:
        if (
          hashedData === state.bottomSectorData?.checksum ||
          !state.bottomOption ||
          !state.sectorSectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, bottomSectorDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomSectorData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.bottomOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          bottomSectorDataUpdating: false,
        };

      case BOTTOM_DISTRIBUTION_SECTOR_DATA:
        if (
          hashedData === state.bottomDistributionSectorData?.checksum ||
          !state.bottomOption ||
          !state.sectorSectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, bottomDistributionSectorDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomDistributionSectorData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          bottomDistributionSectorDataUpdating: false,
        };

      /***************************************
       *               Industry              *
       ***************************************/
      case TOP_INDUSTRY_DATA:
        if (
          hashedData === state.topIndustryData?.checksum ||
          !state.topOption ||
          !state.industrySectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, topIndustryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topIndustryData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.topOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          topIndustryDataUpdating: false,
        };

      case TOP_DISTRIBUTION_INDUSTRY_DATA:
        if (
          hashedData === state.topDistributionIndustryData?.checksum ||
          !state.topOption ||
          !state.industrySectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, topDistributionIndustryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          topDistributionIndustryData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          topDistributionIndustryDataUpdating: false,
        };

      case BOTTOM_INDUSTRY_DATA:
        if (
          hashedData === state.bottomIndustryData?.checksum ||
          !state.bottomOption ||
          !state.industrySectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, bottomIndustryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomIndustryData: {
            ...processAVisualData(
              data,
              {
                ...configData,
                isPercentage:
                  SectorAnalysisConfig.CHART_OPTIONS_IS_PERCENT.includes(
                    state.bottomOption
                  ),
              },
              true
            ),
            checksum: hashedData,
          },
          bottomIndustryDataUpdating: false,
        };

      case BOTTOM_DISTRIBUTION_INDUSTRY_DATA:
        if (
          hashedData === state.bottomDistributionIndustryData?.checksum ||
          !state.bottomOption ||
          !state.industrySectors?.filter((e: ISectorIndustry) => e.selected)
            ?.length
        ) {
          return { ...state, bottomDistributionIndustryDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          bottomDistributionIndustryData: {
            ...processAVisualData(data, configData),
            checksum: hashedData,
          },
          bottomDistributionIndustryDataUpdating: false,
        };

      default:
        return state;
    }
  } else {
    switch (action.type) {
      /***************************************
       *                 FETCH               *
       ***************************************/
      case FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS:
        const { sectorData, selectedCountries, sectors, selectedIndustries } =
          action.payload;
        const countries = selectedCountries.map((e: ISecIndCty) => ({
          ...e,
          selected: false,
        }));
        const mappedSectors = sectors.map((e: ISectorIndustry) => ({
          ...e,
          selected: false,
          industries: e.industries.map((ind: ISecIndCty) => ({
            ...ind,
            selected: false,
          })),
        }));
        return {
          ...state,
          sectorData,
          countryCountries: [...countries],
          countrySectors: [...sectors],
          countrySelectedIndustries: [...selectedIndustries],
          sectorCountries: [...selectedCountries],
          sectorSectors: [...mappedSectors],
          sectorSelectedIndustries: [],
          industryCountries: [...selectedCountries],
          industrySectors: [...mappedSectors],
          industrySelectedIndustries: [],
        };
      case FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL:
        return state;
      case FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS:
        return {
          ...state,
          countryIndustryCompaniesNumber: action.payload,
        };
      case FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL:
        return {
          ...state,
          countryIndustryCompaniesNumber: undefined,
        };
      case FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS:
        return {
          ...state,
          sectorIndustryCompaniesNumber: action.payload,
        };
      case FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL:
        return {
          ...state,
          sectorIndustryCompaniesNumber: undefined,
        };
      case FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS:
        return {
          ...state,
          industryIndustryCompaniesNumber: action.payload,
        };
      case FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL:
        return {
          ...state,
          countryIndustryCompaniesNumber: undefined,
        };

      /***************************************************************************************************
       *                                              Filters                                            *
       ***************************************************************************************************/
      case SET_SECTOR_GROUP_BY:
        return {
          ...state,
          groupBy: action.payload,
        };
      case SET_TIME_WINDOW:
        if (MD5(state.timeWindow) === MD5(action.payload)) {
          return state;
        }

        const setTimeWindowConfig = makeConfigList(
          state.groupBy,
          RenderTypeEnum.FILTER_PAGE,
          state.topOption,
          state.bottomOption
        );

        const setTimeWindowConSecInd = getConSecInd(state.groupBy, state);

        dispatchUpdateCharts(
          action.asyncDispatch,
          setTimeWindowConfig,
          state.groupBy,
          action.payload,
          setTimeWindowConSecInd.selectedCountries,
          setTimeWindowConSecInd.sectors,
          setTimeWindowConSecInd.selectedIndustries
        );

        return {
          ...state,
          timeWindow: action.payload,
        };

      case APPLY_CTY_SEC_IND_FILTERS:
        const applyCtySecIndFiltersConfig = makeConfigList(
          state.groupBy,
          RenderTypeEnum.FILTER_PAGE,
          state.topOption,
          state.bottomOption
        );

        dispatchUpdateCharts(
          action.asyncDispatch,
          applyCtySecIndFiltersConfig,
          state.groupBy,
          state.timeWindow,
          action.payload.country,
          action.payload.sector,
          action.payload.industry
        );
        return state;

      /* OPTIONS */
      case SET_TOP_CHART_OPTION:
        if (state.topOption === action.payload) {
          return state;
        }

        const setTopOptionConfig = makeConfigList(
          state.groupBy,
          RenderTypeEnum.TOP_FILTER,
          action.payload,
          state.bottomOption
        );

        const setTopOptionConSecInd = getConSecInd(state.groupBy, state);

        dispatchUpdateCharts(
          action.asyncDispatch,
          setTopOptionConfig,
          state.groupBy,
          state.timeWindow,
          setTopOptionConSecInd.selectedCountries,
          setTopOptionConSecInd.sectors,
          setTopOptionConSecInd.selectedIndustries
        );

        return { ...state, topOption: action.payload };

      case SET_BOTTOM_CHART_OPTION:
        if (state.bottomOption === action.payload) {
          return state;
        }

        const setBottomOptionConfig = makeConfigList(
          state.groupBy,
          RenderTypeEnum.BOTTOM_FILTER,
          state.topOption,
          action.payload
        );

        const setBottomOptionConSecInd = getConSecInd(state.groupBy, state);

        dispatchUpdateCharts(
          action.asyncDispatch,
          setBottomOptionConfig,
          state.groupBy,
          state.timeWindow,
          setBottomOptionConSecInd.selectedCountries,
          setBottomOptionConSecInd.sectors,
          setBottomOptionConSecInd.selectedIndustries
        );
        return { ...state, bottomOption: action.payload };

      /* UPDATE */
      case SECTOR_ANALYSIS_UPDATE:
        return { ...state, ...makeUpdatingVisuals(state, action.payload) };

      /***************************************
       *                SELECT               *
       ***************************************/
      /* COUNTRY */
      case SELECT_COUNTRY_COUNTRY_IDS:
        const countryCountries = handleSelectedCountry(
          state.countryCountries,
          action.payload,
          state.sectorData || [],
          state.countrySectors
        );

        return {
          ...state,
          countryCountries: countryCountries.selectedCountries,
          countrySectors: countryCountries.sectors,
          countrySelectedIndustries: countryCountries.selectedIndustries,
        };
      case SELECT_COUNTRY_SECTORS:
        const countrySectors = handleSelectedSector(
          state.countrySectors,
          action.payload
        );

        return {
          ...state,
          countrySectors: countrySectors.sectors,
          countrySelectedIndustries: countrySectors.selectedIndustries,
        };
      case SELECT_COUNTRY_INDUSTRIES:
        const countryIndustries = handleSelectedIndustry(
          state.countrySectors,
          action.payload.sector,
          action.payload.industry
        );

        return {
          ...state,
          countrySectors: countryIndustries.sectors,
          countrySelectedIndustries: countryIndustries.selectedIndustries,
        };
      /* SECTOR */
      case SELECT_SECTOR_COUNTRY_IDS:
        const sectorCountries = handleSelectedCountry(
          state.sectorCountries,
          action.payload,
          state.sectorData || [],
          state.sectorSectors
        );

        return {
          ...state,
          sectorCountries: sectorCountries.selectedCountries,
          sectorSectors: sectorCountries.sectors,
          sectorSelectedIndustries: sectorCountries.selectedIndustries,
        };

      case SELECT_SECTOR_SECTORS:
        const sectorSectors = handleSelectedSector(
          state.sectorSectors,
          action.payload
        );

        return {
          ...state,
          sectorSectors: sectorSectors.sectors,
          sectorSelectedIndustries: sectorSectors.selectedIndustries,
        };
      case SELECT_SECTOR_INDUSTRIES:
        const sectorIndustries = handleSelectedIndustry(
          state.sectorSectors,
          action.payload.sector,
          action.payload.industry
        );

        return {
          ...state,
          sectorSectors: sectorIndustries.sectors,
          sectorSelectedIndustries: sectorIndustries.selectedIndustries,
        };
      /* INDUSTRY */
      case SELECT_INDUSTRY_COUNTRY_IDS:
        const industryCountries = handleSelectedCountry(
          state.industryCountries,
          action.payload,
          state.sectorData || [],
          state.industrySectors
        );

        return {
          ...state,
          industryCountries: industryCountries.selectedCountries,
          industrySectors: industryCountries.sectors,
          industrySelectedIndustries: industryCountries.selectedIndustries,
        };

      case SELECT_INDUSTRY_SECTORS:
        const industrySectors = handleSelectedSector(
          state.industrySectors,
          action.payload
        );

        return {
          ...state,
          industrySectors: industrySectors.sectors,
          industrySelectedIndustries: industrySectors.selectedIndustries,
        };
      case SELECT_INDUSTRY_INDUSTRIES:
        const industryIndustries = handleSelectedIndustry(
          state.industrySectors,
          action.payload.sector,
          action.payload.industry
        );

        return {
          ...state,
          industrySectors: industryIndustries.sectors,
          industrySelectedIndustries: industryIndustries.selectedIndustries,
        };

      default:
        return state;
    }
  }
};

export default sectorAnalysisVisualsReducer;

const makeConfigList = (
  groupBy: GroupBySectorEnum,
  renderType: RenderTypeEnum,
  topOption?: string,
  bottomOption?: string
): IVisualConfig[] => {
  let topConfig: IVisualConfig[] = [];
  let bottomConfig: IVisualConfig[] = [];
  switch (groupBy) {
    case GroupBySectorEnum.COUNTRY_ID:
      switch (renderType) {
        case RenderTypeEnum.TOP_FILTER:
          topConfig = SectorAnalysisConfig.COUNTRY_ANALYSIS_TOP_CONFIG;
          break;
        case RenderTypeEnum.BOTTOM_FILTER:
          bottomConfig = SectorAnalysisConfig.COUNTRY_ANALYSIS_BOTTOM_CONFIG;
          break;
        case RenderTypeEnum.FILTER_PAGE:
          topConfig = SectorAnalysisConfig.COUNTRY_ANALYSIS_TOP_CONFIG;
          bottomConfig = SectorAnalysisConfig.COUNTRY_ANALYSIS_BOTTOM_CONFIG;
          break;
      }
      break;
    case GroupBySectorEnum.SECTOR:
      switch (renderType) {
        case RenderTypeEnum.TOP_FILTER:
          topConfig = SectorAnalysisConfig.SECTOR_ANALYSIS_TOP_CONFIG;
          break;
        case RenderTypeEnum.BOTTOM_FILTER:
          bottomConfig = SectorAnalysisConfig.SECTOR_ANALYSIS_BOTTOM_CONFIG;
          break;
        case RenderTypeEnum.FILTER_PAGE:
          topConfig = SectorAnalysisConfig.SECTOR_ANALYSIS_TOP_CONFIG;
          bottomConfig = SectorAnalysisConfig.SECTOR_ANALYSIS_BOTTOM_CONFIG;
          break;
      }
      break;
    case GroupBySectorEnum.INDUSTRY:
      switch (renderType) {
        case RenderTypeEnum.TOP_FILTER:
          topConfig = SectorAnalysisConfig.INDUSTRY_ANALYSIS_TOP_CONFIG;
          break;
        case RenderTypeEnum.BOTTOM_FILTER:
          bottomConfig = SectorAnalysisConfig.INDUSTRY_ANALYSIS_BOTTOM_CONFIG;
          break;
        case RenderTypeEnum.FILTER_PAGE:
          topConfig = SectorAnalysisConfig.INDUSTRY_ANALYSIS_TOP_CONFIG;
          bottomConfig = SectorAnalysisConfig.INDUSTRY_ANALYSIS_BOTTOM_CONFIG;
          break;
      }
      break;
  }
  return [
    ...(!!topOption
      ? topConfig.map((e: IVisualConfig) => ({ ...e, item: topOption }))
      : []),
    ...(!!bottomOption
      ? bottomConfig.map((e: IVisualConfig) => ({ ...e, item: bottomOption }))
      : []),
  ];
};

const getConSecInd = (
  groupBy: GroupBySectorEnum,
  state: IVisualsState
): {
  selectedCountries: ISecIndCty[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
} => {
  switch (groupBy) {
    case GroupBySectorEnum.COUNTRY_ID:
      return {
        selectedCountries: state.countryCountries,
        sectors: state.countrySectors,
        selectedIndustries: state.countrySelectedIndustries,
      };
    case GroupBySectorEnum.SECTOR:
      return {
        selectedCountries: state.sectorCountries,
        sectors: state.sectorSectors,
        selectedIndustries: state.sectorSelectedIndustries,
      };
    case GroupBySectorEnum.INDUSTRY:
      return {
        selectedCountries: state.industryCountries,
        sectors: state.industrySectors,
        selectedIndustries: state.industrySelectedIndustries,
      };
  }
};

const dispatchUpdateCharts = (
  dispatch: Dispatch<any>,
  config: IVisualConfig[],
  groupBy: GroupBySectorEnum,
  years: [number, number],
  countries: ISecIndCty[],
  sectors: ISectorIndustry[],
  industries: string[]
): void => {
  /* Check if there are enough countries/sectors/industries */
  let pass = false;
  switch (groupBy) {
    case GroupBySectorEnum.COUNTRY_ID:
      const filteredCountries = countries.filter(
        (e: ISecIndCty) => !!e.selected
      );
      pass = !!filteredCountries.length && filteredCountries.length < 4;
      break;
    case GroupBySectorEnum.SECTOR:
      const filteredSectors = sectors.filter(
        (e: ISectorIndustry) => !!e.selected
      );
      pass = !!filteredSectors.length && filteredSectors.length < 4;
      break;
    case GroupBySectorEnum.INDUSTRY:
      pass = !!industries.length && industries.length < 4;
      break;
  }

  if (pass && !!config.length) {
    // Dispatch update
    dispatch({
      type: SECTOR_ANALYSIS_UPDATE,
      payload: config.map((e: IVisualConfig) => e.stateLabel),
    });

    // Fetch data
    dispatch(
      fetchSectorChartData(
        config,
        groupBy,
        years,
        countries
          .filter((e: ISecIndCty) => e.selected)
          .map((e: ISecIndCty) => e.label.split(' -')[0]),
        sectors
          .filter((e: ISectorIndustry) => e.selected)
          .map((e: ISectorIndustry) => e.label),
        industries
      )
    );
  }
};
