import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import {
  createChartDatasetsConfig,
  createRankingChartDatasetConfig,
} from '../../../constants/charts/chart.constants';
import {
  getRankChartOptions,
  tuneOptions,
} from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
  MAX_TICK_ROTATION,
  MIN_TICK_ROTATION,
  RANK_AMOUNT_THRESHOLD,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import {
  IOpenLargeChart,
  IVisualChartRank,
} from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMPANY_STOCKS_TITLE_LABELS } from '../utils/visuals.config';

const CompanyStocksTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeDWMQFilter = useSelector(
    (state: RootState) => state.filters.activeDWMQFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt =
    useSelector(
      (state: RootState) => state.companies.currentCompany?.currencySt
    ) || '';
  const currencyP =
    useSelector(
      (state: RootState) => state.companies.currentCompany?.currencyP
    ) || '';

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const priceData = useSelector(
    (state: RootState) => state.visuals.stock.priceData
  );
  const priceDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.priceDataUpdating
  );
  const epsData = useSelector(
    (state: RootState) => state.visuals.stock.epsData
  );
  const epsDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.epsDataUpdating
  );
  const sharesData = useSelector(
    (state: RootState) => state.visuals.stock.sharesData
  );
  const sharesDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.sharesDataUpdating
  );
  const peRatioData = useSelector(
    (state: RootState) => state.visuals.stock.peRatioData
  );
  const peRatioDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.peRatioDataUpdating
  );
  const marketCapRankData = useSelector(
    (state: RootState) => state.visuals.stock.marketCapRankData
  );
  const marketCapRankDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.marketCapRankDataUpdating
  );
  const marketCapData = useSelector(
    (state: RootState) => state.visuals.stock.marketCapData
  );
  const marketCapDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.marketCapDataUpdating
  );
  const marketCapRankFullData = useSelector(
    (state: RootState) => state.visuals.stock.marketCapRankFullData
  );

  const [price, setPrice] = useState<ChartData>();
  const [eps, setEps] = useState<ChartData>();
  const [shares, setShares] = useState<ChartData>();
  const [peRatio, setPeRatio] = useState<ChartData>();
  const [marketCap, setMarketCap] = useState<ChartData>();
  const [marketCapRank, setMarketCapRank] = useState<ChartData>();
  const [marketCapRankFull, setMarketCapRankFull] = useState<ChartData>();

  /* Price Chart */
  const [priceHasData, priceHasLabels, priceError] =
    extractChartMetadata(priceData);
  useEffect(() => {
    setPrice({
      datasets: createChartDatasetsConfig(priceData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [priceData, useDollars, fiscalYear, screenWidth]);

  /* Shares Chart */
  const [sharesHasData, sharesHasLabels, sharesError] =
    extractChartMetadata(sharesData);
  useEffect(() => {
    setShares({
      datasets: createChartDatasetsConfig(sharesData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [sharesData, useDollars, fiscalYear, screenWidth]);

  /* Market Cap Chart */
  const [marketCapHasData, marketCapHasLabels, marketCapError] =
    extractChartMetadata(marketCapData);
  useEffect(() => {
    setMarketCap({
      datasets: createChartDatasetsConfig(marketCapData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [marketCapData, useDollars, fiscalYear, screenWidth]);

  /* PE Ratio Chart */
  const [peRatioHasData, peRatioHasLabels, peRatioError] =
    extractChartMetadata(peRatioData);
  useEffect(() => {
    setPeRatio({
      datasets: createChartDatasetsConfig(
        peRatioData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    peRatioData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* EPS Chart */
  const [epsHasData, epsHasLabels, epsError] = extractChartMetadata(epsData);
  useEffect(() => {
    setEps({
      datasets: createChartDatasetsConfig(epsData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
      }),
    });
  }, [epsData, useDollars, fiscalYear, screenWidth]);

  /* Market Cap Rank */
  const [marketCapRankHasData, marketCapRankHasLabels, marketCapRankError] =
    extractChartMetadata(marketCapRankData);
  useEffect(() => {
    const labels = marketCapRankData?.labels;
    const ranks = marketCapRankData?.rank;

    if (labels && ranks) {
      setMarketCapRank({
        labels: labels.map((label: string, index: number): string[] => [
          label,
          `${ranks[index]}º`,
        ]),
        datasets: createRankingChartDatasetConfig(
          marketCapRankData,
          screenWidth
        ),
      });
    }
  }, [marketCapRankData, screenWidth]);

  // Market Cap Rank Full
  useEffect(() => {
    let data: IVisualChartRank | undefined = marketCapRankFullData;

    // Build selected ticker if not present
    if (
      (!data?.selectedTicker || data?.selectedTicker?.length === 0) &&
      data?.labels &&
      data.rank
    ) {
      const ticker: string =
        marketCapRankData?.labels.find(
          (e: string, i: number) => marketCapRankData?.selectedTicker[i] > 0
        ) || '';
      const selectedTicker: number[] =
        data?.labels?.map((e: string) => (e === ticker ? 1 : 0)) || [];
      data = !!data
        ? {
            ...data,
            selectedTicker: selectedTicker,
          }
        : undefined;
      setMarketCapRankFull({
        labels: data?.labels.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            arr.length < RANK_AMOUNT_THRESHOLD ? `${data?.rank[index]}º` : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(data, screenWidth),
      });
    } else {
      const labels = marketCapRankFullData?.labels;
      const ranks = marketCapRankFullData?.rank;

      setMarketCapRankFull({
        labels: labels?.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            ranks && arr.length < RANK_AMOUNT_THRESHOLD
              ? `${ranks[index]}º`
              : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(
          marketCapRankFullData,
          screenWidth
        ),
      });
    }
  }, [marketCapRankFullData, marketCapRankData, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };
  const onOpenLargeClickMarketCapRankHandler = (data: IOpenLargeChart) => {
    dispatch(
      openLargeChartDialog({
        ...data,
        data: marketCapRankFull as ChartData,
        options: tuneOptions({
          screenWidth,
          options: getRankChartOptions(
            marketCapRankFullData &&
              marketCapRankFullData?.data1?.length > RANK_AMOUNT_THRESHOLD
              ? MAX_TICK_ROTATION
              : MIN_TICK_ROTATION,
            screenWidth
          ),
          showLegend: false,
          xType: 'category',
          tooltipSymbol:
            !!marketCapRankFullData?.isCurrency ||
            !!marketCapRankFullData?.isPercentage
              ? {
                  left: !!marketCapRankFullData?.isCurrency,
                  symbol: !!currencySt ? DOLLAR_ST : '%',
                }
              : undefined,
        }),
      })
    );
  };

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COMPANY_STOCKS));
  }, [dispatch]);

  return (
    <>
      {priceDataUpdating &&
        sharesDataUpdating &&
        epsDataUpdating &&
        peRatioDataUpdating &&
        marketCapDataUpdating &&
        marketCapRankDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={price}
          error={
            !!priceError
              ? GENERAL_ERROR_MESSAGE
              : !priceHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.price}
          hasData={priceHasData}
          exportData={priceData}
          timeUnit={activeDWMQFilter === 'daily' ? 'month' : 'year'}
          currencySymbol={
            !!priceData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencyP
              : undefined
          }
          isPercentage={!!priceData?.isPercentage}
          updating={!!priceDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={shares}
          error={
            !!sharesError
              ? GENERAL_ERROR_MESSAGE
              : !sharesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.shares}
          hasData={sharesHasData}
          exportData={sharesData}
          currencySymbol={
            !!sharesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!sharesData?.isPercentage}
          updating={!!sharesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.eps}
          data={eps}
          error={
            !!epsError
              ? GENERAL_ERROR_MESSAGE
              : !epsHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={epsHasData}
          exportData={epsData}
          showLegend={true}
          currencySymbol={
            !!epsData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!epsData?.isPercentage}
          updating={!!epsDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.peRatio}
          data={peRatio}
          error={
            !!peRatioError
              ? GENERAL_ERROR_MESSAGE
              : !peRatioHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={peRatioHasData}
          showLegend={true}
          exportData={peRatioData}
          timeUnit={activeDWMQFilter === 'daily' ? 'month' : 'year'}
          currencySymbol={
            !!peRatioData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!peRatioData?.isPercentage}
          updating={!!peRatioDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={marketCap}
          error={
            !!marketCapError
              ? GENERAL_ERROR_MESSAGE
              : !marketCapHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.marketCap}
          hasData={marketCapHasData}
          exportData={marketCapData}
          timeUnit={activeDWMQFilter === 'daily' ? 'month' : 'year'}
          currencySymbol={
            !!marketCapData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencyP
              : undefined
          }
          isPercentage={!!marketCapData?.isPercentage}
          updating={!!marketCapDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          titleLabel={COMPANY_STOCKS_TITLE_LABELS.marketCapRank}
          data={marketCapRank}
          error={
            !!marketCapRankError
              ? GENERAL_ERROR_MESSAGE
              : !marketCapRankHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={marketCapRankHasData}
          exportData={marketCapRankFullData}
          xAxisType="category"
          options={getRankChartOptions(0, screenWidth)}
          currencySymbol={
            !!marketCapRankData?.isCurrency ? DOLLAR_ST : undefined
          }
          isPercentage={!!marketCapRankData?.isPercentage}
          updating={!!marketCapRankDataUpdating}
          onOpenChartDialogClick={onOpenLargeClickMarketCapRankHandler}
        />
      </ResponsiveRow>
      <ScrollFillerComponent />
    </>
  );
};

export default CompanyStocksTab;
