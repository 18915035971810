import ScaleLoader from 'react-spinners/ScaleLoader';
import { COMMON_STYLES } from '../../constants/general.constants';
import { COMMON_COLORS } from '../../ui/colors';

export const SCALE_LOADER_CARD_PROPS = {
  color: COMMON_COLORS.primary,
  loading: true,
  height: '2rem',
  width: '0.2rem',
  margin: '0.12rem',
};

type Props = {
  scaleLoaderCardProps?: {
    height: string;
    width?: string;
    margin?: string;
  };
};

const getScaleLoaderCardProps = (scaleLoaderProps: any) =>
  !!scaleLoaderProps
    ? {
        ...SCALE_LOADER_CARD_PROPS,
        ...scaleLoaderProps,
      }
    : SCALE_LOADER_CARD_PROPS;

const ChartLoadingComponent = (props: Props) => {
  return (
    <div style={COMMON_STYLES.centered}>
      <ScaleLoader {...getScaleLoaderCardProps(props.scaleLoaderCardProps)} />
    </div>
  );
};

export default ChartLoadingComponent;
