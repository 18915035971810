import { SxProps, Typography, TypographyVariant } from '@mui/material';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import WaveSkeleton from '../../wave-skeleton.component';

type Props = {
  title: string;
  variant?: TypographyVariant;
  sx?: SxProps;
  skeleton?: boolean;
};

const FilterTitleComponent = (props: Props) => {
  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <Typography
      gutterBottom
      variant={props.variant || 'h6'}
      sx={{
        textAlign: 'left' as 'left',
        // marginTop: '1rem',
        color: 'text.primary',
        ...(isPortraitMobile
          ? { marginBottom: '1.5rem', fontSize: '2.2rem' }
          : {}),
        ...props.sx,
      }}
    >
      {!!props.skeleton ? <WaveSkeleton /> : props.title}
    </Typography>
  );
};

export default FilterTitleComponent;
