import { alpha } from '@mui/material';

export type ColorsType = typeof DARK_THEME_COLORS;

export const COMMON_COLORS = {
  // primary: '#2d88ff' /* Facebook blue */,
  // primary: '#006AFF' /* Messenger blue */,
  // primary: '#0041cb', /* Dark messanger blue */
  primary: '#1565c0',
  contrastPrimaryText: '#ffffff',
  // primary: '#1b4cd9', // Elrond (blue)
  accent: '#ffcb56', // Yellow
  // ternary: '#ffcb56', // Yellow
  lightBlue: '#008ffb',

  backdrop: '#18191aB3',
  menuBackdrop: '#18191a80',
  error: '#ff0000',
  darkError: '#ff0000aa',
  // mask: `#FFFFFF${perc2HexOpacity(8)}`,
  mask: alpha('#FFFFFF', 0.08),

  /* Charts */
  chartColor1: '#16c784', // Green
  chartColor2: '#ffcb56', // Yellow
  chartColor3: '#008ffb', // Light blue
  // chartColor4: '#ff00e6', // Purple
  chartColor4: '#a24bfb', // Violet
  /* Other charts */
  chartGrid: '#444444',
  chartLabel: '#888888',

  assetCharts: '#008ffb',
  liabilityCharts: '#ffcb56',
  equityCharts: '#16c784',

  disabledButton: '#535353',
};

export const DARK_THEME_COLORS = {
  icons: 'lightgrey',
  textPrimary: '#d2e2eb',
  textSecondary: '#b6b6b6',
  bgPrimary: '#242526',
  bgSecondary: '#191b1f',
  // bgSecondary: '#17171a',
  divider: '#494949',
  ...COMMON_COLORS,
};

export const LIGHT_THEME_COLORS = {
  icons: 'grey',
  textPrimary: '#050f19',
  textSecondary: '#5b616e',
  bgPrimary: '#ffffff',
  bgSecondary: '#f6f9fc',
  divider: '#d3d3d3',
  ...COMMON_COLORS,
};

export interface ILabelColor {
  label: string;
  color: string;
}

export const PRIMARIES: ILabelColor[] = [
  {
    label: 'red',
    color: '#e91e63',
  },
  {
    label: 'orange',
    color: '#ffa500',
  },
  {
    label: 'yellow',
    color: '#ffd740',
  },
  {
    label: 'green',
    color: '#11cb5f',
  },
  {
    label: 'blue',
    // color: '#3f51b5',
    color: '#2d88ff',
  },
  {
    label: 'indaco',
    color: '#673ab7',
  },
  {
    label: 'violet',
    color: '#9c27b0',
  },
];
