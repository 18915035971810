import {
  DispatchActionTypes,
  GET_COMPANIES_COUNT,
  GET_COMPANIES_COUNT_FAIL,
  GET_COMPANIES_COUNT_SUCCESS,
  ICompaniesState,
} from '../../../models/admin/companies.model';

const initialState: ICompaniesState = {
  loading: false,
  companiesCount: 0,
  marketCapRevenueCount: 0,
};

const companiesReducer = (
  state: ICompaniesState = initialState,
  action: DispatchActionTypes
): ICompaniesState => {
  /* Reducer logic */
  switch (action.type) {
    case GET_COMPANIES_COUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_COMPANIES_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_COMPANIES_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companiesReducer;
