import { IScoredCompany } from '../../../models/companies.model';
import {
  IRadarRawData,
  IVisualChart,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';

export interface ICompanyInfo {
  description: string;
  address: string;
  lat: number;
  long: number;
  website: string;
  exchange: string;
  officers: [string, string][];
  currency: string;
  noMapData?: boolean;
}

export interface ISummaryValue {
  key: string;
  value: string;
}

export interface IVisualsState {
  /* Companies Summary */
  companyOverviewData?: IVisualChart;
  companyOverviewFullData?: IRadarRawData;
  tickerScore?: number;
  companyOverviewDataUpdating?: boolean;
  companyInfoData?: ICompanyInfo;
  companyInfoDataUpdating?: boolean;
  companyInfoCoordinates?: { lat: number; long: number };
  companySummaryDataUpdating?: boolean;
  companySummaryData?: ISummaryValue[];
  companyTopPeersDataUpdating?: boolean;
  companyTopPeersData?: IScoredCompany[];
  priceData?: IVisualChart;
  priceDataUpdating?: boolean;

  coordinatesLoading: boolean;
  coordinatesError?: string;
}

/***************************************
 *            Company Summary          *
 ***************************************/
/* COMPANY_OVERVIEW_DATA */
export const COMPANY_OVERVIEW_DATA = 'COMPANY_OVERVIEW_DATA';
export interface CompanyOverviewDataI {
  type: typeof COMPANY_OVERVIEW_DATA;
  payload: { data: IRadarRawData; configData: IVisualConfigData };
}

/* COMPANY_SUMMARY_PRICE_DATA */
export const COMPANY_SUMMARY_PRICE_DATA = 'COMPANY_SUMMARY_PRICE_DATA';
export interface ICompanySummaryPriceData {
  type: typeof COMPANY_SUMMARY_PRICE_DATA;
  payload: IVisualPayload;
}

/* COMPANY_INFO_DATA*/
export const COMPANY_INFO_DATA = 'COMPANY_INFO_DATA';
export interface CompanyInfoDataI {
  type: typeof COMPANY_INFO_DATA;
  payload: {
    data: ICompanyInfo;
    configData: IVisualConfigData;
  };
}

/* COMPANY_SUMMARY_DATA*/
export const COMPANY_SUMMARY_DATA = 'COMPANY_SUMMARY_DATA';
export interface CompanySummaryDataI {
  type: typeof COMPANY_SUMMARY_DATA;
  payload: {
    data: { [label: string]: number };
    configData: IVisualConfigData;
  };
}

/* COMPANY_TOP_PEERS_DATA */
export const COMPANY_TOP_PEERS_DATA = 'COMPANY_TOP_PEERS_DATA';
export interface ICompanyTopPeersData {
  type: typeof COMPANY_TOP_PEERS_DATA;
  payload: {
    data: { [label: string]: number }[];
    configData: IVisualConfigData;
  };
}

/* COMPANY_SUMMARY_UPDATE */
export const COMPANY_SUMMARY_UPDATE = 'COMPANY_SUMMARY_UPDATE';
export interface ICompanySummaryUpdate {
  type: typeof COMPANY_SUMMARY_UPDATE;
  payload: string[];
}

/* RESET_COMPANY_SUMMARY */
export const RESET_COMPANY_SUMMARY = 'RESET_COMPANY_SUMMARY';
export interface IResetCompanySummary {
  type: typeof RESET_COMPANY_SUMMARY;
}

/* COMPANY_DIVIDENDS_UPDATE_ACTION */
export const COMPANY_DIVIDENDS_UPDATE_ACTION =
  'COMPANY_DIVIDENDS_UPDATE_ACTION';
export interface ICompanyDividendsUpdateAction {
  type: typeof COMPANY_DIVIDENDS_UPDATE_ACTION;
  payload: string[];
}

export const ACTIONS_ARR = [COMPANY_OVERVIEW_DATA];

export type DispatchActionTypes =
  | ICompanySummaryUpdate
  | IResetCompanySummary
  /* Company Summary */
  | CompanyOverviewDataI
  | CompanyInfoDataI
  | CompanySummaryDataI
  | ICompanyTopPeersData
  | ICompanySummaryPriceData;
