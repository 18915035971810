import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { ICompanyMinimal, ITableColumnData } from '@prometeus/common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeAllCompanies,
  removeCompany,
} from '../../../store/actions/peer-group.action';
import { RootState } from '../../../store/reducers/root.reducer';
import TableBodyComponent from '../../table/table-body.component';
import TableHeadComponent from '../../table/table-head.component';
import TablePaginationComponent from '../../table/table-pagination.component';
import * as tableUtils from '../../table/table.constants';
import TooltipIconButton from '../../tooltip-icon-button.component';

type Props = {
  data: ICompanyMinimal[];
  tableColumns: ITableColumnData[];
};

const MAX_HEIGHT_REM = 39;

const styles = {
  tableContainer: {
    maxHeight: `${MAX_HEIGHT_REM}rem`,
  },
  paper: {
    marginBottom: '0rem',
  },
  listItemIconRoot: {
    padding: '0.5rem !important',
  },
  iconButtonRoot: {
    color: 'white !important',
    margin: '0rem 0.05rem 0rem 0.0rem',
  },
};

const PeerGroupPeersTableComponent = (props: Props) => {
  const theme = useTheme();

  /* Hooks */
  const tableStyles = tableUtils.getTableStyles(theme.palette);
  const dispatch = useDispatch();
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /* Orderings */
  const [order, setOrder] = useState<tableUtils.Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ICompanyMinimal>('tickerId');

  /* Pages */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  /* Handlers */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof ICompanyMinimal);
  };

  const { data } = props;
  useEffect(() => {
    if (page && data.length <= page * rowsPerPage) {
      setPage(page - 1);
    }
  }, [data, page, rowsPerPage]);

  return (
    <Paper sx={{ ...tableStyles.paper, ...styles.paper }}>
      <TableContainer
        sx={{
          ...styles.tableContainer,
          ...tableStyles.container,
          ...(!data?.length ? tableStyles.tableContainerOverflowHidden : {}),
        }}
      >
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeadComponent
            headCells={props.tableColumns}
            sortOrder={order}
            sortBy={orderBy as string}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            rightCell={
              <TooltipIconButton
                icon={<Close />}
                tooltip="Remove All"
                onClick={() => {
                  dispatch(removeAllCompanies(currentCompanyTickerId || ''));
                }}
                iconButtonStyle={{
                  ...styles.iconButtonRoot,
                  ...styles.listItemIconRoot,
                }}
              />
            }
          />
          <TableBodyComponent
            data={data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
            tableColumns={props.tableColumns}
            emptyRowsMessage="No peer selected."
            maxHeight={MAX_HEIGHT_REM}
            /* Sides */
            rightCell={(company: ICompanyMinimal): React.ReactNode => (
              <>
                {!currentCompanyTickerId ||
                currentCompanyTickerId !== company.tickerId ? (
                  <TooltipIconButton
                    icon={<Close />}
                    tooltip="Remove"
                    iconButtonStyle={{
                      ...styles.iconButtonRoot,
                      ...styles.listItemIconRoot,
                    }}
                    onClick={() => {
                      dispatch(removeCompany(company.tickerId));
                    }}
                  />
                ) : (
                  <div style={{ padding: '1.5rem' }}></div>
                )}
              </>
            )}
            /* Pagination */
            page={page}
            rowsPerPage={rowsPerPage}
            /* Sorting */
            order={order}
            orderBy={orderBy as string}
          />
        </Table>
      </TableContainer>
      <TablePaginationComponent
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        dataLength={data.length}
        siblingCount={0}
        notShowRows={true}
      />
    </Paper>
  );
};

export default PeerGroupPeersTableComponent;
