import {
  IScreenerFilterFields,
  ISectorData,
  SECTORS_URL,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import {
  SCREENER_RESULTS_URL,
  SCREENER_URL,
} from '../constants/api-urls.constants';
import { PeerGroupEnum } from '../models/peer-group.model';
import {
  IScreenerResultUnmapped,
  SortOrderType,
} from '../models/screener/results.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const sendScreenerFiltersService = (body: {
  filters: IScreenerFilterFields[];
  countries: string[];
  sectors: string[];
  industries: string[];
  peerGroup: PeerGroupEnum;
}): Promise<AxiosResponse<{ data: string[] }>> =>
  axiosInstance.post<{ data: string[] }>(SCREENER_URL, body);

export const fetchSectorsDataService = (): Promise<
  AxiosResponse<ISectorData[]>
> => axiosInstance.get(SECTORS_URL);

export const showScreenerResultsService = (
  tickers: string[],
  page: number,
  resultsPerPage: number,
  columns: string[],
  sortBy: string,
  sortOrder: SortOrderType
): Promise<AxiosResponse<{ data: IScreenerResultUnmapped[] }>> =>
  axiosInstance.post<{ data: IScreenerResultUnmapped[] }>(
    SCREENER_RESULTS_URL,
    {
      tickers,
      page: page + 1,
      resultsPerPage,
      columns,
      sortBy,
      sortOrder: `${sortOrder}ending`,
    }
  );
