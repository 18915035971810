import { ChartTextSizeEnum } from '../../models/structure.model';
import { CreateChartDatasetOptionsType } from '../../models/visuals.model';
import { DEFAULT_WIDTH } from '../general.constants';

export const MIN_TICK_ROTATION = 0;
export const MAX_TICK_ROTATION = 50;
export const RANK_AMOUNT_THRESHOLD = 20;

export const EMPTY_DATASET_LABELS: CreateChartDatasetOptionsType[] = [{}];

export const GENERAL_ERROR_MESSAGE = 'An Error Has Occured!';
export const GENERAL_NO_DATA_MESSAGE = 'No Data Available.';

export const getSizeRem = (size: number, screenWidth: number): number =>
  (size / DEFAULT_WIDTH) * (screenWidth || DEFAULT_WIDTH);

export const getTextSize = (size: ChartTextSizeEnum | undefined) => {
  switch (size) {
    case ChartTextSizeEnum.SMALL:
      return 9;
    case ChartTextSizeEnum.MEDIUM:
      return 14;
    case ChartTextSizeEnum.LARGE:
      return 15;
    default:
      return 10;
  }
};
