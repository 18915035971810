import { Map, Set } from 'immutable';
import { ICONS_MAX_SIZE } from '../../constants/general.constants';
import {
  DispatchActionTypes,
  GET_MANAGERS,
  GET_MANAGERS_FAIL,
  GET_MANAGERS_SUCCESS,
  ICachedManager,
  IManagerObj,
  IManagersState,
} from '../../models/icons-managers.model';

const initialState: IManagersState = {
  managersMap: Map<string, ICachedManager>([]),
  requestedManagers: Set<string>([]),
  managersQueue: [],
  loading: false,
};

const managersReducer = (
  state: IManagersState = initialState,
  action: DispatchActionTypes
): IManagersState => {
  switch (action.type) {
    case GET_MANAGERS:
      return {
        ...state,
        loading: true,
        requestedManagers: Set([...state.requestedManagers, ...action.payload]),
      };
    case GET_MANAGERS_SUCCESS:
      let { managersMap, managersQueue } = state;
      action.payload?.forEach((e: IManagerObj) => {
        const { name, image } = e;

        const old = managersMap.get(name);
        managersMap = managersMap.set(name, {
          image,
          count: (old?.count || 0) + 1,
        });
        managersQueue = [...managersQueue, name];

        while (managersMap.size > ICONS_MAX_SIZE && !!managersQueue.length) {
          const name = managersQueue.pop() as string;
          const { count } = managersMap.get(name) || { count: 1 };
          if (count - 1 <= 0) {
            managersMap = managersMap.delete(name);
          }
        }
      });
      return {
        ...state,
        managersMap,
        managersQueue,
      };

    case GET_MANAGERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default managersReducer;
