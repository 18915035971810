import { useTheme } from '@mui/material';
import { pxToRem } from '../ui/functions';
import { useMediaQueryPortraitMobile } from './responsive-design.hook';

export const useGlobalStyles = () => {
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const palette = theme.palette;

  return {
    body: {
      height: '100%',
      backgroundColor: palette.background.paper,
      '& .MuiButtonBase-root': {
        outline: 'none',
      },

      /* Autocomplete */
      '& .MuiAutocomplete-paper': {
        backgroundColor: `${palette.background.default} !important`,
        color: `${palette.text.primary} !important`,
      },
      '& .MuiAutocomplete-option': {
        padding: '0px !important',
      },
      '& .MuiAutocomplete-groupLabel': {
        backgroundColor: `${palette.background.paper} !important`,
        color: `${palette.text.primary} !important`,
      },
      '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
        color: `${palette.text.primary} !important`,
      },
      /* Drawer Backdrop */
      '& .MuiBackdrop-root': {
        opacity: '1 !important',
        visibility: 'visible !important', // !important
      },
      /* Pagination */
      '& .MuiPaginationItem-root': {
        color: `${palette.text.primary} !important`,
      },
      /* Text Fields Outline */
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',

        /* Pivels to Rem */
        top: pxToRem(-5),
        padding: `0 ${pxToRem(8)}`,
        '& legend': {
          maxWidth: pxToRem(1000),
          height: pxToRem(11),
          '& span': {
            paddingLeft: pxToRem(5),
            paddingRight: pxToRem(5),
          },
        },
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `none !important`,
      },

      /***************************************
       *             Pixels To Rem           *
       ***************************************/
      /* Toolbar */
      '& .MuiToolbar-regular': {
        minHeight: pxToRem(64),
        // minHeight: `${APP_BAR_HEIGHT_REM}rem`,
      },
      '& .MuiToolbar-gutters': {
        paddingRight: pxToRem(24),
      },

      /* Icons */
      '& .MuiIconButton-root': {
        padding: pxToRem(12),
      },

      /* Search Bar */
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: pxToRem(14),
      },
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: pxToRem(14),
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: pxToRem(8),
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: pxToRem(8),
      },
      '& .MuiOutlinedInput-input': {
        padding: `1.2rem ${pxToRem(14)}`,
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: `translate(${pxToRem(14)}, ${pxToRem(-6)}) scale(0.75)`,
      },

      /* Tabs */
      '& .MuiTabs-root': {
        minHeight: pxToRem(48),
        top: pxToRem(0.001),
      },
      '& .MuiTab-root': {
        minWidth: pxToRem(160),
        minHeight: pxToRem(48),
      },
      '& .MuiTabs-indicator': {
        height: pxToRem(2),
      },

      /* List */
      '& .MuiList-padding': {
        paddingTop: pxToRem(8),
        paddingBottom: pxToRem(8),
        '& .MuiListItem-gutters': {
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(8),
        },
      },

      '& .MuiListItemAvatar-root': {
        minWidth: pxToRem(56),
      },
      '& .MuiAvatar-root': {
        width: pxToRem(40),
        height: pxToRem(40),
      },
      '& .MuiListItemText-multiline': {
        marginTop: pxToRem(6),
        marginBottom: pxToRem(6),
      },
      '& .MuiList-root': {
        '& .MuiDivider-inset': {
          marginLeft: pxToRem(72),
        },
        '& .MuiDivider-root': {
          height: pxToRem(1),
        },
      },
      '& .MuiListItemText-root': {
        marginTop: pxToRem(4),
        marginBottom: pxToRem(4),
      },

      /* Card */
      '& .MuiCardContent-root:last-child': {
        paddingBottom: pxToRem(24),
      },
      '& .MuiCard-root:not(.admin)': {
        backgroundImage: 'none !important',
      },

      /* Switch */
      '& .MuiSwitch-root': {
        width: pxToRem(58),
        height: pxToRem(38),
        padding: pxToRem(12),
        transform: `scale(${isPortraitMobile ? 2.2 : 1})`,

        '& span.MuiSwitch-switchBase': {
          padding: `${pxToRem(9)}`,
        },
        '& .MuiSwitch-thumb': {
          width: pxToRem(20),
          height: pxToRem(20),
        },
        '& .MuiSwitch-track': {
          borderRadius: pxToRem(7),
          // backgroundColor: palette.divider,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: `translateX(${pxToRem(20)})`,
        },
      },

      /* Button Group */
      '& .MuiButtonGroup-root': {
        // transform: `scale(${isPortraitMobile ? 1.3 : 1})`,
        borderRadius: pxToRem(4),
        '& .MuiButton-outlinedSizeLarge': {
          padding: `${pxToRem(7)} ${pxToRem(21)}`,
        },
        '& .MuiButton-outlined': {
          borderWidth: pxToRem(1),
        },
        '& .MuiButton-root': {
          borderRadius: pxToRem(4),
        },

        ...(isPortraitMobile
          ? {
            '& button': {
              fontSize: '1.5rem !important',
            },
          }
          : {}),
      },
      '& .MuiButtonGroup-grouped': {
        minWidth: pxToRem(40),
      },

      /* Liner Progress */
      '& .MuiLinearProgress-root': {
        height: pxToRem(4),
      },

      /* Select */
      '& .MuiSelect-iconOutlined': {
        right: pxToRem(7),
      },
      '& .MuiSelect-icon': {
        top: `calc(50% - ${pxToRem(12)})`,
      },
      '& .MuiInputLabel-outlined': {
        transform: `translate(${pxToRem(14)}, ${pxToRem(20)}) scale(1)`,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: pxToRem(4),
        '& .MuiSelect-outlined': {
          borderRadius: '2rem',
        },
        '& .MuiSelect-select': {
          minWidth: pxToRem(16),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: pxToRem(1),
        },
      },
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingRight: pxToRem(32),
      },

      /* Slider */
      '& .MuiSlider-root': {
        height: isPortraitMobile ? '0.5rem' : pxToRem(2),
        '& .MuiSlider-rail': {},
        '& .MuiSlider-track': {
          borderWidth: '0',
        },
        '& .MuiSlider-thumb': {
          width: isPortraitMobile ? '2.5rem' : '1rem',
          height: isPortraitMobile ? '2.5rem' : '1rem',
          '&::after': {
            width: '1.8rem',
            height: '1.8rem',
          },
        },
        '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {},
      },
      '& .MuiSlider-marked': {
        '& .MuiSlider-mark': {},
        '& .MuiSlider-markLabel': {},
      },

      /* Button */
      '& .MuiButton-root': {
        padding: `${pxToRem(6)} ${pxToRem(16)}`,
        minWidth: pxToRem(64),
        borderRadius: pxToRem(4),
      },
    },
  };
};
