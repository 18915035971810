import {
  IStatementsData,
  IVisualChart,
  IVisualPayload,
} from '../../../models/visuals.model';

export interface IVisualsState {
  /* Overview */
  totalAssetsData?: IVisualChart;
  totalAssetsDataUpdating?: boolean;
  totalLiabilitiesData?: IVisualChart;
  totalLiabilitiesDataUpdating?: boolean;
  totalEquityData?: IVisualChart;
  totalEquityDataUpdating?: boolean;

  /* Current Asset Liabilities */
  currentAssetsData?: IVisualChart;
  currentAssetsDataUpdating?: boolean;
  cashEqShortTermInvestmentsData?: IVisualChart;
  cashEqShortTermInvestmentsDataUpdating?: boolean;
  accountsReceivableData?: IVisualChart;
  accountsReceivableDataUpdating?: boolean;
  inventoriesData?: IVisualChart;
  inventoriesDataUpdating?: boolean;
  otherShortTermAssetsData?: IVisualChart;
  otherShortTermAssetsDataUpdating?: boolean;
  currentLiabilitiesData?: IVisualChart;
  currentLiabilitiesDataUpdating?: boolean;
  payablesAccrualsData?: IVisualChart;
  payablesAccrualsDataUpdating?: boolean;
  shortTermDebtData?: IVisualChart;
  shortTermDebtDataUpdating?: boolean;
  otherShortTermLiabilitiesData?: IVisualChart;
  otherShortTermLiabilitiesDataUpdating?: boolean;

  /* Noncurrent Asset Liabilities */
  nonCurrentAssetsData?: IVisualChart;
  nonCurrentAssetsDataUpdating?: boolean;
  propertyPlantEquipmentData?: IVisualChart;
  propertyPlantEquipmentDataUpdating?: boolean;
  longTermInvestmentsReceivablesData?: IVisualChart;
  longTermInvestmentsReceivablesDataUpdating?: boolean;
  otherLongTermAssetsData?: IVisualChart;
  otherLongTermAssetsDataUpdating?: boolean;
  nonCurrentLiabilitiesData?: IVisualChart;
  nonCurrentLiabilitiesDataUpdating?: boolean;
  longTermDebtData?: IVisualChart;
  longTermDebtDataUpdating?: boolean;
  otherLongTermLiabilitiesData?: IVisualChart;
  otherLongTermLiabilitiesDataUpdating?: boolean;

  /* Equity */
  equityTotalEquityData?: IVisualChart;
  equityTotalEquityDataUpdating?: boolean;
  shareCapitalAdditionalPaidInData?: IVisualChart;
  shareCapitalAdditionalPaidInDataUpdating?: boolean;
  retainedEarningsData?: IVisualChart;
  retainedEarningsDataUpdating?: boolean;
  treasuryStockData?: IVisualChart;
  treasuryStockDataUpdating?: boolean;

  /* Statements */
  statementsData?: IStatementsData;
  statementsDataUpdating?: boolean;
}

/* BALANCE_SHEET_ANALYSIS_UPDATE */
export const BALANCE_SHEET_ANALYSIS_UPDATE = 'BALANCE_SHEET_ANALYSIS_UPDATE';
export interface IBalanceSheetAnalysisUpdateAction {
  type: typeof BALANCE_SHEET_ANALYSIS_UPDATE;
  payload: string[];
}

/***************************************
 *               Overview              *
 ***************************************/

/* TOTAL_ASSETS_YEARLY_DATA */
export const TOTAL_ASSETS_YEARLY_DATA = 'TOTAL_ASSETS_YEARLY_DATA';
export interface TotalAssetsYearlyDataI {
  type: typeof TOTAL_ASSETS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* TOTAL_ASSETS_QUARTERLY_DATA */
export const TOTAL_ASSETS_QUARTERLY_DATA = 'TOTAL_ASSETS_QUARTERLY_DATA';
export interface TotalAssetsQuarterlyDataI {
  type: typeof TOTAL_ASSETS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* TOTAL_LIABILITIES_YEARLY_DATA */
export const TOTAL_LIABILITIES_YEARLY_DATA = 'TOTAL_LIABILITIES_YEARLY_DATA';
export interface TotalLiabilitiesYearlyDataI {
  type: typeof TOTAL_LIABILITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* TOTAL_LIABILITIES_QUARTERLY_DATA */
export const TOTAL_LIABILITIES_QUARTERLY_DATA =
  'TOTAL_LIABILITIES_QUARTERLY_DATA';
export interface TotalLiabilitiesQuarterlyDataI {
  type: typeof TOTAL_LIABILITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* TOTAL_EQUITY_YEARLY_DATA */
export const TOTAL_EQUITY_YEARLY_DATA = 'TOTAL_EQUITY_YEARLY_DATA';
export interface TotalEquityYearlyDataI {
  type: typeof TOTAL_EQUITY_YEARLY_DATA;
  payload: IVisualPayload;
}

/* TOTAL_EQUITY_QUARTERLY_DATA */
export const TOTAL_EQUITY_QUARTERLY_DATA = 'TOTAL_EQUITY_QUARTERLY_DATA';
export interface TotalEquityQuarterlyDataI {
  type: typeof TOTAL_EQUITY_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *      Current Assets Liabilities     *
 ***************************************/
/* CURRENT_ASSETS_YEARLY_DATA */
export const CURRENT_ASSETS_YEARLY_DATA = 'CURRENT_ASSETS_YEARLY_DATA';
export interface CurrentAssetsYearlyDataI {
  type: typeof CURRENT_ASSETS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CURRENT_ASSETS_QUARTERLY_DATA */
export const CURRENT_ASSETS_QUARTERLY_DATA = 'CURRENT_ASSETS_QUARTERLY_DATA';
export interface CurrentAssetsQuarterlyDataI {
  type: typeof CURRENT_ASSETS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA */
export const CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA =
  'CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA';
export interface CashEqShortTermInvestmentsYearlyDataI {
  type: typeof CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA */
export const CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA =
  'CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA';
export interface CashEqShortTermInvestmentsQuarterlyDataI {
  type: typeof CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* ACCOUNTS_RECEIVABLE_YEARLY_DATA */
export const ACCOUNTS_RECEIVABLE_YEARLY_DATA =
  'ACCOUNTS_RECEIVABLE_YEARLY_DATA';
export interface AccountsReceivableYearlyDataI {
  type: typeof ACCOUNTS_RECEIVABLE_YEARLY_DATA;
  payload: IVisualPayload;
}

/* ACCOUNTS_RECEIVABLE_QUARTERLY_DATA */
export const ACCOUNTS_RECEIVABLE_QUARTERLY_DATA =
  'ACCOUNTS_RECEIVABLE_QUARTERLY_DATA';
export interface AccountsReceivableQuarterlyDataI {
  type: typeof ACCOUNTS_RECEIVABLE_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* INVENTORIES_YEARLY_DATA */
export const INVENTORIES_YEARLY_DATA = 'INVENTORIES_YEARLY_DATA';
export interface InventoriesYearlyDataI {
  type: typeof INVENTORIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* INVENTORIES_QUARTERLY_DATA */
export const INVENTORIES_QUARTERLY_DATA = 'INVENTORIES_QUARTERLY_DATA';
export interface InventoriesQuarterlyDataI {
  type: typeof INVENTORIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_SHORT_TERM_ASSETS_YEARLY_DATA */
export const OTHER_SHORT_TERM_ASSETS_YEARLY_DATA =
  'OTHER_SHORT_TERM_ASSETS_YEARLY_DATA';
export interface OtherShortTermAssetsYearlyDataI {
  type: typeof OTHER_SHORT_TERM_ASSETS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA */
export const OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA =
  'OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA';
export interface OtherShortTermAssetsQuarterlyDataI {
  type: typeof OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CURRENT_LIABILITIES_YEARLY_DATA */
export const CURRENT_LIABILITIES_YEARLY_DATA =
  'CURRENT_LIABILITIES_YEARLY_DATA';
export interface CurrentLiabilitiesYearlyDataI {
  type: typeof CURRENT_LIABILITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CURRENT_LIABILITIES_QUARTERLY_DATA */
export const CURRENT_LIABILITIES_QUARTERLY_DATA =
  'CURRENT_LIABILITIES_QUARTERLY_DATA';
export interface CurrentLiabilitiesQuarterlyDataI {
  type: typeof CURRENT_LIABILITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* PAYABLES_ACCRUALS_YEARLY_DATA */
export const PAYABLES_ACCRUALS_YEARLY_DATA = 'PAYABLES_ACCRUALS_YEARLY_DATA';
export interface PayableAccrualsYearlyDataI {
  type: typeof PAYABLES_ACCRUALS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* PAYABLES_ACCRUALS_QUARTERLY_DATA */
export const PAYABLES_ACCRUALS_QUARTERLY_DATA =
  'PAYABLES_ACCRUALS_QUARTERLY_DATA';
export interface PayableAccrualsQuarterlyDataI {
  type: typeof PAYABLES_ACCRUALS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* SHORT_TERM_DEBT_YEARLY_DATA */
export const SHORT_TERM_DEBT_YEARLY_DATA = 'SHORT_TERM_DEBT_YEARLY_DATA';
export interface ShortTermDebtYearlyDataI {
  type: typeof SHORT_TERM_DEBT_YEARLY_DATA;
  payload: IVisualPayload;
}

/* SHORT_TERM_DEBT_QUARTERLY_DATA */
export const SHORT_TERM_DEBT_QUARTERLY_DATA = 'SHORT_TERM_DEBT_QUARTERLY_DATA';
export interface ShortTermDebtQuarterlyDataI {
  type: typeof SHORT_TERM_DEBT_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA */
export const OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA =
  'OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA';
export interface OtherShortTermLiabilitiesYearlyDataI {
  type: typeof OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA */
export const OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA =
  'OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA';
export interface OtherShortTermLiabilitiesQuarterlyDataI {
  type: typeof OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *     Noncurrent Assets Liabilities   *
 ***************************************/
/* NONCURRENT_ASSETS_YEARLY_DATA */
export const NONCURRENT_ASSETS_YEARLY_DATA = 'NONCURRENT_ASSETS_YEARLY_DATA';
export interface NoncurrentAssetsYearlyDataI {
  type: typeof NONCURRENT_ASSETS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* NONCURRENT_ASSETS_QUARTERLY_DATA */
export const NONCURRENT_ASSETS_QUARTERLY_DATA =
  'NONCURRENT_ASSETS_QUARTERLY_DATA';
export interface NoncurrentAssetsQuarterlyDataI {
  type: typeof NONCURRENT_ASSETS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA */
export const PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA =
  'PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA';
export interface PropertyPlanEquipmentYearlyDataI {
  type: typeof PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA;
  payload: IVisualPayload;
}

/* PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA */
export const PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA =
  'PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA';
export interface PropertyPlanEquipmentQuarterlyDataI {
  type: typeof PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA */
export const LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA =
  'LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA';
export interface LongTermInvestmentsReceivableYearlyDataI {
  type: typeof LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA;
  payload: IVisualPayload;
}

/* LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA */
export const LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA =
  'LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA';
export interface LongTermInvestmentsReceivableQuarterlyDataI {
  type: typeof LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_LONG_TERM_ASSETS_YEARLY_DATA */
export const OTHER_LONG_TERM_ASSETS_YEARLY_DATA =
  'OTHER_LONG_TERM_ASSETS_YEARLY_DATA';
export interface OtherLongTermAssetsYearlyDataI {
  type: typeof OTHER_LONG_TERM_ASSETS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA */
export const OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA =
  'OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA';
export interface OtherLongTermAssetsQuarterlyDataI {
  type: typeof OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* NONCURRENT_LIABILITIES_YEARLY_DATA */
export const NONCURRENT_LIABILITIES_YEARLY_DATA =
  'NONCURRENT_LIABILITIES_YEARLY_DATA';
export interface NoncurrentLiabilitiesYearlyDataI {
  type: typeof NONCURRENT_LIABILITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* NONCURRENT_LIABILITIES_QUARTERLY_DATA */
export const NONCURRENT_LIABILITIES_QUARTERLY_DATA =
  'NONCURRENT_LIABILITIES_QUARTERLY_DATA';
export interface NoncurrentLiabilitiesQuarterlyDataI {
  type: typeof NONCURRENT_LIABILITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* LONG_TERM_DEBT_YEARLY_DATA */
export const LONG_TERM_DEBT_YEARLY_DATA = 'LONG_TERM_DEBT_YEARLY_DATA';
export interface LongTermDebtYearlyDataI {
  type: typeof LONG_TERM_DEBT_YEARLY_DATA;
  payload: IVisualPayload;
}

/* LONG_TERM_DEBT_QUARTERLY_DATA */
export const LONG_TERM_DEBT_QUARTERLY_DATA = 'LONG_TERM_DEBT_QUARTERLY_DATA';
export interface LongTermDebtQuarterlyDataI {
  type: typeof LONG_TERM_DEBT_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA */
export const OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA =
  'OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA';
export interface OtherLongTermLiabilitiesYearlyDataI {
  type: typeof OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA */
export const OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA =
  'OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA';
export interface OtherLongTermLiabilitiesQuarterlyDataI {
  type: typeof OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *                Equity               *
 ***************************************/
/* EQUITY_TOTAL_EQUITY_YEARLY_DATA */
export const EQUITY_TOTAL_EQUITY_YEARLY_DATA =
  'EQUITY_TOTAL_EQUITY_YEARLY_DATA';
export interface EquityTotalEquityYearlyDataI {
  type: typeof EQUITY_TOTAL_EQUITY_YEARLY_DATA;
  payload: IVisualPayload;
}

/* EQUITY_TOTAL_EQUITY_QUARTERLY_DATA */
export const EQUITY_TOTAL_EQUITY_QUARTERLY_DATA =
  'EQUITY_TOTAL_EQUITY_QUARTERLY_DATA';
export interface EquityTotalEquityQuarterlyDataI {
  type: typeof EQUITY_TOTAL_EQUITY_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA */
export const SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA =
  'SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA';
export interface ShareCapitalAdditionalPaidInYearlyDataI {
  type: typeof SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA;
  payload: IVisualPayload;
}

/* SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA */
export const SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA =
  'SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA';
export interface ShareCapitalAdditionalPaidInQuarterlyDataI {
  type: typeof SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* RETAINED_EARNINGS_YEARLY_DATA */
export const RETAINED_EARNINGS_YEARLY_DATA = 'RETAINED_EARNINGS_YEARLY_DATA';
export interface RetainedEarningsYearlyDataI {
  type: typeof RETAINED_EARNINGS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* RETAINED_EARNINGS_QUARTERLY_DATA */
export const RETAINED_EARNINGS_QUARTERLY_DATA =
  'RETAINED_EARNINGS_QUARTERLY_DATA';
export interface RetainedEarningsQuarterlyDataI {
  type: typeof RETAINED_EARNINGS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* TREASURY_STOCK_YEARLY_DATA */
export const TREASURY_STOCK_YEARLY_DATA = 'TREASURY_STOCK_YEARLY_DATA';
export interface TreasuryStockYearlyDataI {
  type: typeof TREASURY_STOCK_YEARLY_DATA;
  payload: IVisualPayload;
}

/* TREASURY_STOCK_QUARTERLY_DATA */
export const TREASURY_STOCK_QUARTERLY_DATA = 'TREASURY_STOCK_QUARTERLY_DATA';
export interface TreasuryStockQuarterlyDataI {
  type: typeof TREASURY_STOCK_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *              Statements             *
 ***************************************/
/* BS_STATEMENTS_YEARLY_DATA */
export const BS_STATEMENTS_YEARLY_DATA = 'BS_STATEMENTS_YEARLY_DATA';
export interface BSStatementsYearlyDataI {
  type: typeof BS_STATEMENTS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* BS_STATEMENTS_QUARTERLY_DATA */
export const BS_STATEMENTS_QUARTERLY_DATA = 'BS_STATEMENTS_QUARTERLY_DATA';
export interface BSStatementsQuarterlyDataI {
  type: typeof BS_STATEMENTS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

export type DispatchActionTypes =
  | IBalanceSheetAnalysisUpdateAction
  | TotalAssetsYearlyDataI
  | TotalAssetsQuarterlyDataI
  | TotalLiabilitiesYearlyDataI
  | TotalLiabilitiesQuarterlyDataI
  | TotalEquityYearlyDataI
  | TotalEquityQuarterlyDataI
  | CurrentAssetsYearlyDataI
  | CurrentAssetsQuarterlyDataI
  | CashEqShortTermInvestmentsYearlyDataI
  | CashEqShortTermInvestmentsQuarterlyDataI
  | AccountsReceivableYearlyDataI
  | AccountsReceivableQuarterlyDataI
  | InventoriesYearlyDataI
  | InventoriesQuarterlyDataI
  | OtherShortTermAssetsYearlyDataI
  | OtherShortTermAssetsQuarterlyDataI
  | CurrentLiabilitiesYearlyDataI
  | CurrentLiabilitiesQuarterlyDataI
  | PayableAccrualsYearlyDataI
  | PayableAccrualsQuarterlyDataI
  | ShortTermDebtYearlyDataI
  | ShortTermDebtQuarterlyDataI
  | OtherShortTermLiabilitiesYearlyDataI
  | OtherShortTermLiabilitiesQuarterlyDataI
  | NoncurrentAssetsYearlyDataI
  | NoncurrentAssetsQuarterlyDataI
  | PropertyPlanEquipmentYearlyDataI
  | PropertyPlanEquipmentQuarterlyDataI
  | LongTermInvestmentsReceivableYearlyDataI
  | LongTermInvestmentsReceivableQuarterlyDataI
  | OtherLongTermAssetsYearlyDataI
  | OtherLongTermAssetsQuarterlyDataI
  | NoncurrentLiabilitiesYearlyDataI
  | NoncurrentLiabilitiesQuarterlyDataI
  | LongTermDebtYearlyDataI
  | LongTermDebtQuarterlyDataI
  | OtherLongTermLiabilitiesYearlyDataI
  | OtherLongTermLiabilitiesQuarterlyDataI
  | EquityTotalEquityYearlyDataI
  | EquityTotalEquityQuarterlyDataI
  | ShareCapitalAdditionalPaidInYearlyDataI
  | ShareCapitalAdditionalPaidInQuarterlyDataI
  | RetainedEarningsYearlyDataI
  | RetainedEarningsQuarterlyDataI
  | TreasuryStockYearlyDataI
  | TreasuryStockQuarterlyDataI
  /* Statements */
  | BSStatementsYearlyDataI
  | BSStatementsQuarterlyDataI;
