import {
  IUserInformation,
  IUserRegistrationInformation,
  Token,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
  ACTIVATE_FREE_TRIAL,
  ACTIVATE_FREE_TRIAL_FAIL,
  ACTIVATE_FREE_TRIAL_SUCCESS,
  FETCH_LICENCES_HISTORY,
  FETCH_LICENCES_HISTORY_FAIL,
  FETCH_LICENCES_HISTORY_SUCCESS,
  FetchLicencesHistoryDispatchTypes,
  ISetUserInformation,
  RESET_USER_INFORMATION,
  SET_USER_INFORMATION,
  SUBMIT_USER_REGISTRATION_INFORMATION,
  SUBMIT_USER_REGISTRATION_INFORMATION_FAIL,
  UPDATE_USER_REGISTRATION_INFORMATION,
  UPDATE_USER_REGISTRATION_INFORMATION_FAIL,
} from '../../models/user.model';
import {
  activateFreeTrialService,
  fetchLicencesHistoryService,
  submitRegistrationInformationService,
  updateRegistrationInformationService,
} from '../../services/user.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';
import { actionErrorInterceptor } from '../../utils/error-interceptor';
import { updateAccessToken } from './authentication.action';
import { toggleAlert } from './modals.action';

export const submitRegistrationInformation =
  (registrationInformation: IUserRegistrationInformation) =>
    async (dispatch: Dispatch<any>) => {
      try {
        dispatch({
          type: SUBMIT_USER_REGISTRATION_INFORMATION,
        });

        const response = await submitRegistrationInformationService(
          registrationInformation
        );

        commonUserInformationAction(dispatch, response.data);
      } catch (err: any) {
        actionErrorInterceptor(
          dispatch,
          SUBMIT_USER_REGISTRATION_INFORMATION_FAIL,
          err
        );
      }
    };

export const updateUserInformation =
  (updatedInformation: IUserInformation) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: UPDATE_USER_REGISTRATION_INFORMATION,
      });

      const response = await updateRegistrationInformationService(
        updatedInformation
      );

      commonUserInformationAction(dispatch, response.data);
    } catch (err: any) {
      actionErrorInterceptor(
        dispatch,
        UPDATE_USER_REGISTRATION_INFORMATION_FAIL,
        err
      );
    }
  };

export const commonUserInformationAction = (
  dispatch: Dispatch,
  data: IUserInformation
) => {
  dispatch(setUserInformation(data));
  dispatch(toggleAlert('Information successfully updated!'));
};

export const setUserInformation = (
  userInformation: IUserInformation
): ISetUserInformation => {
  return {
    type: SET_USER_INFORMATION,
    payload: userInformation,
  };
};

export const resetUserInformation = () => ({
  type: RESET_USER_INFORMATION,
});

export const fetchLicencesHistory = () => {
  return (dispatch: Dispatch<FetchLicencesHistoryDispatchTypes>) => {
    actionTryCatchWrapper(dispatch, FETCH_LICENCES_HISTORY_FAIL, async () => {
      dispatch({ type: FETCH_LICENCES_HISTORY });

      const response = await fetchLicencesHistoryService();

      dispatch({
        type: FETCH_LICENCES_HISTORY_SUCCESS,
        payload: response?.data || undefined,
      });
    });
  };
};

export const activateFreeTrial = () => {
  return (dispatch: Dispatch) => {
    actionTryCatchWrapper(dispatch, ACTIVATE_FREE_TRIAL_FAIL, async () => {
      dispatch({ type: ACTIVATE_FREE_TRIAL });

      const response: AxiosResponse<Token> = await activateFreeTrialService();

      dispatch({
        type: ACTIVATE_FREE_TRIAL_SUCCESS,
      });

      // Update access token
      updateAccessToken(dispatch, response.data, false, true);

      // Open alert
      dispatch(toggleAlert('Free trial successfully activated!'));
    });
  };
};
