import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import {
  createChartDatasetConfigAssets,
  createChartDatasetConfigLiabilities,
  createChartDatasetsConfig,
} from '../../../constants/charts/chart.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../../ui/colors';
import { BS_OVERVIEW_TITLE_LABELS } from '../utils/visuals.config';

const OverviewTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const totalAssetsData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalAssetsData
  );
  const totalAssetsDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalAssetsDataUpdating
  );
  const totalLiabilitiesData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalLiabilitiesData
  );
  const totalLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.totalLiabilitiesDataUpdating
  );
  const totalEquityData = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalEquityData
  );
  const totalEquityDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalEquityDataUpdating
  );

  const [totalAssets, setTotalAssets] = useState<ChartData>();
  const [totalLiabilities, setTotalLiabilities] = useState<ChartData>();
  const [totalEquity, setTotalEquity] = useState<ChartData>();

  /* Total Assets */
  const [totalAssetsHasData, totalAssetsHasLabels, totalAssetsError] =
    extractChartMetadata(totalAssetsData);
  useEffect(() => {
    setTotalAssets({
      datasets: createChartDatasetConfigAssets(
        totalAssetsData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [totalAssetsData, useDollars, fiscalYear, screenWidth]);

  /* Total Liabilities */
  const [
    totalLiabilitiesHasData,
    totalLiabilitiesHasLabels,
    totalLiabilitiesError,
  ] = extractChartMetadata(totalLiabilitiesData);
  useEffect(() => {
    setTotalLiabilities({
      datasets: createChartDatasetConfigLiabilities(
        totalLiabilitiesData,
        screenWidth,
        useDollars,
        fiscalYear
      ),
    });
  }, [totalLiabilitiesData, useDollars, fiscalYear, screenWidth]);

  /* Total Equity */
  const [totalEquityHasData, totalEquityHasLabels, totalEquityError] =
    extractChartMetadata(totalEquityData);
  useEffect(() => {
    setTotalEquity({
      datasets: createChartDatasetsConfig(totalEquityData, screenWidth, {
        useDollars,
        isTime: true,
        customLineColor: COMMON_COLORS.equityCharts,
        fiscalYear,
      }),
    });
  }, [totalEquityData, useDollars, fiscalYear, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.BS_OVERVIEW));
  }, [dispatch]);

  return (
    <>
      {totalAssetsDataUpdating &&
        totalLiabilitiesDataUpdating &&
        totalEquityDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={totalAssets}
          error={
            !!totalAssetsError
              ? GENERAL_ERROR_MESSAGE
              : !totalAssetsHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={BS_OVERVIEW_TITLE_LABELS.totalAssets}
          hasData={totalAssetsHasData}
          exportData={totalAssetsData}
          currencySymbol={
            !!totalAssetsData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!totalAssetsData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!totalAssetsDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={totalLiabilities}
          error={
            !!totalLiabilitiesError
              ? GENERAL_ERROR_MESSAGE
              : !totalLiabilitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={BS_OVERVIEW_TITLE_LABELS.totalLiabilities}
          hasData={totalLiabilitiesHasData}
          exportData={totalLiabilitiesData}
          currencySymbol={
            !!totalLiabilitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!totalLiabilitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!totalLiabilitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <div style={{ margin: '0 1rem' }}></div>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={totalEquity}
          error={
            !!totalEquityError
              ? GENERAL_ERROR_MESSAGE
              : !totalEquityHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={BS_OVERVIEW_TITLE_LABELS.totalEquity}
          hasData={totalEquityHasData}
          exportData={totalEquityData}
          currencySymbol={
            !!totalEquityData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!totalEquityData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!totalEquityDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default OverviewTab;
