import {
  DispatchActionTypes,
  GET_MAP_OVERVIEW_DATA,
  GET_MAP_OVERVIEW_DATA_FAIL,
  GET_MAP_OVERVIEW_DATA_SUCCESS,
  IVisualsState,
} from './visuals.model';

const initialState: IVisualsState = {
  loading: false,
};

const marketCoverageVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  switch (action.type) {
    case GET_MAP_OVERVIEW_DATA:
      /* State update */
      return {
        ...state,
        loading: true,
      };

    case GET_MAP_OVERVIEW_DATA_SUCCESS:
      /* State update */
      return {
        ...state,
        loading: false,
        mapData: action.payload,
      };

    case GET_MAP_OVERVIEW_DATA_FAIL:
      /* State update */
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default marketCoverageVisualsReducer;
