import { isNullUndefined, numberFormatter } from '@prometeus/common';
import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processVisualData,
} from '../../../constants/charts/visuals.constants';
import {
  IRadarRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import { BROAD_LABELS } from './contants';
import {
  COMPANY_INFO_DATA,
  COMPANY_OVERVIEW_DATA,
  COMPANY_SUMMARY_DATA,
  COMPANY_SUMMARY_PRICE_DATA,
  COMPANY_SUMMARY_UPDATE,
  COMPANY_TOP_PEERS_DATA,
  DispatchActionTypes,
  ICompanyInfo,
  ISummaryValue,
  IVisualsState,
  RESET_COMPANY_SUMMARY,
} from './visuals.model';

const formatSummaryValue = (key: string, value: number) => {
  if (isNullUndefined(value)) {
    return '-';
  }

  switch (key) {
    case 'Market Cap':
    case 'Revenue':
      return numberFormatter(value) + ' USD';
    case 'P/E':
      return String((+String(value))?.toFixed(2)) + 'x';
    case 'Current Ratio':
    case 'Quick Ratio':
    case 'Debt Payback Period':
      return String((+String(value))?.toFixed(2));
    case 'Last Year':
      return String(value);
    default:
      return `${(value * 100).toFixed(1)} %`;
  }
};

const initialState: IVisualsState = {
  coordinatesLoading: false,
};

const CHART_ACTIONS_SET = new Set([
  COMPANY_SUMMARY_UPDATE,
  RESET_COMPANY_SUMMARY,
  COMPANY_INFO_DATA,
  COMPANY_SUMMARY_DATA,
  COMPANY_TOP_PEERS_DATA,
]);

const companySummaryVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  try {
    if (CHART_ACTIONS_SET.has(action.type)) {
      switch (action.type) {
        case COMPANY_SUMMARY_UPDATE:
          return {
            ...state,
            ...makeUpdatingVisuals(state, action.payload),
          };
        case RESET_COMPANY_SUMMARY:
          return {
            ...initialState,
            ...makeUpdatingVisuals(state, [
              'companyOverviewData',
              'companyInfoData',
              'companySummaryData',
              'companyTopPeersData',
            ]),
          };
        case COMPANY_INFO_DATA:
          const anyPayload = action.payload.data as any;
          const mappedPayload: ICompanyInfo = {
            description: anyPayload['Description'],
            address: anyPayload['Address'],
            lat: anyPayload['Latitude'],
            long: anyPayload['Longitude'],
            website: anyPayload['Website'],
            exchange: anyPayload['Exchange'],
            officers: anyPayload['Officers'],
            currency: anyPayload['Currency St'],
          };
          if (
            JSON.stringify(mappedPayload) ===
            JSON.stringify(state.companyInfoData || '')
          ) {
            return {
              ...state,
              companyInfoDataUpdating: false,
            };
          }

          return {
            ...state,
            companyInfoData: {
              ...mappedPayload,
              noMapData:
                (!!mappedPayload.lat ||
                  !!mappedPayload.long ||
                  !!mappedPayload.address) &&
                (!mappedPayload.lat || !mappedPayload.long),
            },
            companyInfoDataUpdating: false,
          };
        case COMPANY_SUMMARY_DATA:
          const _data = action.payload.data;
          let dataArr: ISummaryValue[] = [];
          const dataArrStart: ISummaryValue[] = [];

          Object.keys(_data).forEach((e: string) => {
            const obj = { key: e, value: formatSummaryValue(e, _data[e]) };
            if (['Last Year', 'Market Cap', 'Revenue'].includes(e)) {
              dataArrStart.push(obj);
            } else {
              dataArr.push(obj);
            }
          });

          dataArr = [...dataArrStart, ...dataArr];

          if (
            JSON.stringify(dataArr) ===
            JSON.stringify(state.companySummaryData || '')
          ) {
            return {
              ...state,
              companySummaryDataUpdating: false,
            };
          }

          return {
            ...state,
            companySummaryData: dataArr,
            companySummaryDataUpdating: false,
          };

        case COMPANY_TOP_PEERS_DATA:
          const _topPeers: any[] = action.payload.data;
          const topPeers = _topPeers.map((e) => ({
            companyName: e['Company Name'] as string,
            tickerId: e['CompanyId'] as string,
            overallScore: e['overall_score'] as number,
          }));
          if (
            JSON.stringify(topPeers) ===
            JSON.stringify(state.companyInfoData || '')
          ) {
            return {
              ...state,
              companyTopPeersDataUpdating: false,
            };
          }

          return {
            ...state,
            companyTopPeersData: topPeers,
            companyTopPeersDataUpdating: false,
          };

        default:
          return state;
      }
    } else {
      switch (action.type) {
        case COMPANY_OVERVIEW_DATA:
          const radarActionPayload = (
            action as {
              type: string;
              payload: { data: IRadarRawData; configData: IVisualConfigData };
            }
          ).payload;
          const radarData: IRadarRawData = radarActionPayload?.data;
          const radarHashedData: string = MD5(radarData || '');
          if (radarHashedData === state.companyOverviewData?.checksum) {
            return { ...state, companyOverviewDataUpdating: false };
          }

          const commonData = {
            datasetLabels: radarActionPayload.configData.datasetLabels,
            columns: radarActionPayload.configData.columns,
            hasData: true,
          };

          /* State update */
          return {
            ...state,
            companyOverviewData: {
              ...commonData,
              labels: BROAD_LABELS,
              data1: BROAD_LABELS.map((e: string) =>
                !!radarData
                  ? (((radarData as any)[e] as any)?.score || 0) * 100
                  : 0
              ),
            },
            companyOverviewFullData: radarData,
            tickerScore: radarData.ticket_score,
            companyOverviewDataUpdating: false,
          };
        case COMPANY_SUMMARY_PRICE_DATA:
          const actionPayload: IVisualPayload = (
            action as { type: string; payload: IVisualPayload }
          ).payload;

          const configData: IVisualConfigData = actionPayload?.configData;
          const data = actionPayload?.data;
          const hashedData: string = MD5(data || '');

          if (hashedData === state.priceData?.checksum) {
            return { ...state, priceDataUpdating: false };
          }

          return {
            ...state,
            priceData: {
              ...processVisualData(data, configData, true),
              checksum: hashedData,
            },
            priceDataUpdating: false,
          };

        default:
          return state;
      }
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default companySummaryVisualsReducer;
