import { alpha, Button, SxProps, Theme } from '@mui/material';
import React from 'react';
import { COMMON_COLORS } from '../../ui/colors';

type LoginButtonProps = {
  text: string;
  logo: React.ElementType;
  onClick: () => void;
  sx: SxProps<Theme>;
};

const LoginButton = (props: LoginButtonProps) => {
  const Logo = props.logo as any;

  return (
    <Button
      onClick={props.onClick}
      sx={{
        borderRadius: '3rem !important',
        color: 'black',
        height: '3rem',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '20rem',
        marginBottom: '1rem',
        whiteSpace: 'nowrap',

        '& img, svg': {
          width: '1.5rem',
          height: '1.5rem',
          marginRight: '1.5rem',
          marginLeft: '0.6rem',
          marginBottom: '0.2rem',
        },

        '& span': {
          fontSize: '1rem',
        },

        border: 'none',
        padding: '0.5rem',
        '&:hover': {
          backgroundColor: alpha(COMMON_COLORS.primary, 0.75),
          color: 'white',
        },
        ...(props.sx as any).loginButton,
      }}
    >
      <Logo />
      <span className="text">{props.text}</span>
    </Button>
  );
};

export default LoginButton;
