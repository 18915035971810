import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Palette,
  Radio,
  RadioGroup,
  SxProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../ui/functions';

const getStyles = (palette: Palette) => ({
  formLabel: {
    color: palette.text.primary,
  },
  formControl: {
    padding: '0.8rem 0rem !important',
    '& legend': {
      padding: '0.3rem 0rem',
    },
  },
  formControlSmall: {
    padding: '0.4rem 0.8rem 0.4rem 0rem !important',
    marginBottom: '0.5rem',
  },
  formControlLabel: {
    marginLeft: pxToRem(-11),
    marginRight: pxToRem(0),
    '& .MuiRadio-root': {
      padding: '0.3rem',
      paddingLeft: '0.55rem',
    },
    '& .MuiTypography-root': {
      fontSize: '1.1rem',
    },
  },
  splitFormControlLabel: {
    '&:first-child': {
      marginRight: '1rem',
    },
  },
  radio: {
    color: palette.text.primary,
    marginBottom: '0.3rem',
    marginRight: '0.1rem',
  },
  lineContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      flex: '1 1 0',
    },
  },
  fontSize: {
    fontSize: '1.1rem',
  },
  smallFormControlLabel: {
    wordSpacing: '-0.1rem',
    fontSize: '1rem',
  },
});

export const CustomFormControlLabel = (props: {
  value: string;
  label: string;
  onSelect: (value: string) => void;
  split?: boolean;
  isSmall?: boolean;
}) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <FormControlLabel
      id={`form-control-label-${props.value}`}
      key={props.value}
      value={props.value}
      control={<Radio color="primary" sx={{ ...styles.radio }} />}
      label={props.label}
      sx={{
        ...styles.formControlLabel,
        ...(!!props.split ? styles.splitFormControlLabel : {}),
        // `& .${formControlClasses.label}`: {
        ...styles.fontSize,
        ...(!!props.isSmall ? styles.smallFormControlLabel : {}),
        // }
      }}
      onClick={() => {
        props.onSelect(props.value);
      }}
    />
  );
};

type Props = {
  label: string;
  list: { label: string; value: string }[];
  value?: string | null;
  onSelect: (value: string) => void;
  split?: boolean;
  splitStep?: number;
  containerSx?: SxProps;
  isSmall?: boolean;
};

const SelectItemComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const splitStep = props.splitStep || 2;

  return (
    <FormControl
      component="fieldset"
      sx={{
        ...styles.formControl,
        ...props.containerSx,
        ...(!!props.isSmall ? styles.formControlSmall : {}),
      }}
    >
      <FormLabel component="legend" sx={{ ...styles.formLabel }}>
        {props.label}
      </FormLabel>
      <RadioGroup
        name={props.label}
        value={props.value}
        onChange={(
          event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>
        ) => {
          props.onSelect(event.target.value as string);
        }}
      >
        {!!props.split
          ? Array(props.list.length / splitStep)
              .fill(0)
              .map((_, _index: number) => {
                const index = _index * splitStep;
                return (
                  <Box
                    sx={styles.lineContainer}
                    key={`list-item-couple-${index}`}
                  >
                    {Array(splitStep)
                      .fill(null)
                      .map((_, i: number) => (
                        <CustomFormControlLabel
                          key={`${props.list[index + i].label}-${index}`}
                          label={props.list[index + i].label}
                          value={props.list[index + i].value}
                          onSelect={props.onSelect}
                          split={i !== splitStep - 1}
                          isSmall={!!props.isSmall}
                        />
                      ))}
                  </Box>
                );
              })
          : props.list.map((e: { label: string; value: string }) => (
              <CustomFormControlLabel
                label={e.label}
                value={e.value}
                onSelect={props.onSelect}
                key={e.label}
                isSmall={!!props.isSmall}
              />
            ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectItemComponent;
