import { HelpOutline, MoreVert, Refresh } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Palette,
  Typography,
  useTheme,
} from '@mui/material';
import { ScreenerFilterCardType } from '@prometeus/common';
import React, { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { DRAWER_Z_INDEX } from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../models/screener/structure.model';
import { ExportableDataType } from '../models/visuals.model';
import { openChartMenu } from '../store/actions/modals.action';
import { resetScreenerFilterBlockValue } from '../store/actions/screener/structure.action';
import { COMMON_COLORS } from '../ui/colors';
import { pxToRem } from '../ui/functions';
import TooltipIconButton from './tooltip-icon-button.component';

type Props = {
  children: React.ReactNode;
  titleLabel?: string;
  hideTitleLabel?: boolean;

  /* Screener */
  activeFilters?: boolean; // Used for both screener and other filters
  tabName?: ScreenerTabNameEnum;
  cardBlock?: ScreenerFilterCardType;
  onResetClick?: () => void;

  /* More & Info */
  hasMoreInfo?: boolean;
  onInfoClickHandler?: () => void;
  onOpenLargeClick?: () => void;
  chartImage?: string;
  exportData?: ExportableDataType;
  menuOnlyExport?: boolean;

  /* Error */
  error?: string;

  /* Loading */
  showLoading?: boolean;

  /* Custom Component Top Right */
  customTopRightComponent?: React.ReactNode;

  /* Style */
  containerHeight?: boolean;
  containerWidth?: boolean;
  smallCard?: boolean;
  noCardStyle?: boolean;
};

const SCALE_LOADER_CARD_PROPS = {
  color: COMMON_COLORS.primary,
  loading: true,
  height: '1.5rem',
  width: '0.18rem',
  margin: '0.1rem',
};

export const resetScreenerCardBlockHandler = (
  dispatch: Dispatch<unknown>,
  tabName: ScreenerTabNameEnum,
  cardBlock: ScreenerFilterCardType
) => {
  dispatch(resetScreenerFilterBlockValue(tabName, cardBlock));
};

export const getCardStyles = (palette: Palette, isPaymentRoute?: boolean) => ({
  cardRoot: {
    // backgroundColor: palette.background.paper,
    backgroundColor: palette.background.default,
    backgroundImage: 'none',
    // backgroundColor: `#FFFFFF${perc2HexOpacity(4)}`,
    color: palette.text.primary,
    margin: '0.8rem',
    marginBottom: isPaymentRoute ? '1rem' : '2rem',
    borderRadius: '0.5rem',
    // borderTop: `solid 0.25rem ${primary}`,
    border: `${palette.divider} solid 0.1rem`,
    boxShadow: `0px ${pxToRem(2)} ${pxToRem(1)} ${pxToRem(
      -1
    )} rgba(0,0,0,0.2),0px ${pxToRem(1)} ${pxToRem(
      1
    )} 0px rgba(0,0,0,0.14),0px ${pxToRem(1)} ${pxToRem(
      3
    )} 0px rgba(0,0,0,0.12)`,
  },
  cardContentRoot: {
    padding: '1rem',
    paddingTop: '0.5rem',
  },
  backdrop: {
    zIndex: DRAWER_Z_INDEX + 1,
    color: COMMON_COLORS.contrastPrimaryText,
  },
  reducedMarginIcon: {
    marginRight: '-0.6rem',
  },
  typographyAlign: {
    textAlign: 'left' as 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  containerHeight: {
    height: '100% !important',
    '& .MuiCardContent-root': {
      height: '90% !important',
    },
  },
  containerWidth: {
    width: '100% !important',
  },
  iconButtonRoot: {
    marginBottom: 0,
    padding: '0.5rem !important',
  },
  iconFontSizePortraitMobile: {
    '& .MuiSvgIcon-root': {
      fontSize: '2.3rem !important',
    },
  },
  nullifyCard: {
    width: '100%',
    height: '100%',
    '& .MuiPaper-root.MuiCard-root': {
      border: 'none !important',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      margin: '0 !important',

      '& .MuiCardContent-root': {
        height: '96% !important',
      },
    },
  },
  noCardStyle: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    boxShadow: 'none !important',

    '& .MuiCardContent-root': {
      padding: '0 !important',
      height: '96% !important',
    },
  },
});

const CardComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const styles = getCardStyles(theme.palette, isPortraitMobile);

  const defaultResetClickHandler = () => {
    resetScreenerCardBlockHandler(
      dispatch,
      props.tabName as ScreenerTabNameEnum,
      props.cardBlock as ScreenerFilterCardType
    );
  };

  /***************************************
   *                 Menu                *
   ***************************************/
  const onOpenMenu = (x: number, y: number, hasInfo: boolean) => {
    dispatch(
      openChartMenu({
        open: true,
        hasInfo,
        anchorPosition: {
          mouseX: x - 2,
          mouseY: y - 4,
        },
        chartImage: props.chartImage,
        exportData: props.exportData,
        titleLabel: props.titleLabel,
        onFullScreenHandler: props.onOpenLargeClick,
        onInfoHandler: props.onInfoClickHandler,
        menuOnlyExport: props.menuOnlyExport,
      })
    );
  };

  const onMoreVertClickHandler = ($event: React.MouseEvent<HTMLElement>) => {
    $event.preventDefault();
    onOpenMenu($event.clientX, $event.clientY, false);
  };

  const onContextMenuClickHandler = (
    $event: React.MouseEvent<HTMLDivElement>
  ): void => {
    if (props.hasMoreInfo) {
      $event.preventDefault();
      onOpenMenu($event.clientX, $event.clientY, true);
    }
  };

  return (
    <Card
      sx={{
        ...styles.cardRoot,
        // backgroundColor: 'transparent !important',
        ...(!!props.containerHeight ? styles.containerHeight : {}),
        ...(!!props.containerWidth ? styles.containerWidth : {}),
        ...(!!props.noCardStyle ? styles.noCardStyle : {}),
      }}
      onContextMenu={!!props.error ? () => {} : onContextMenuClickHandler}
    >
      <CardContent sx={styles.cardContentRoot}>
        {!!props.titleLabel && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: props.smallCard ? '2.8rem' : '3.8rem',
            }}
          >
            {!!props.hideTitleLabel ? (
              <div></div>
            ) : (
              <Typography
                gutterBottom
                variant={
                  !!props.smallCard
                    ? 'subtitle1'
                    : isPortraitMobile
                    ? 'h5'
                    : 'h6'
                }
                sx={styles.typographyAlign}
              >
                {props.titleLabel}
              </Typography>
            )}

            {/***************************************
             *                 ICONS               *
             ***************************************/}
            {/* If active --> Screener Filter */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '-0.5rem',
              }}
            >
              {!!props.showLoading && (
                <ScaleLoader {...SCALE_LOADER_CARD_PROPS} />
              )}
              {props.activeFilters && (
                <TooltipIconButton
                  icon={<Refresh />}
                  tooltip="Reset"
                  onClick={props.onResetClick || defaultResetClickHandler}
                  iconButtonStyle={{
                    ...(isPortraitMobile
                      ? styles.iconFontSizePortraitMobile
                      : {}),
                    ...(!!props.smallCard ? styles.iconButtonRoot : {}),
                  }}
                />
              )}
              {props.hasMoreInfo && (
                <div style={{ display: 'flex' }}>
                  {!props.menuOnlyExport && (
                    <TooltipIconButton
                      icon={<HelpOutline />}
                      tooltip="Info"
                      onClick={props.onInfoClickHandler}
                      iconButtonStyle={{
                        ...(isPortraitMobile
                          ? styles.iconFontSizePortraitMobile
                          : {}),
                        ...(!!props.smallCard ? styles.iconButtonRoot : {}),
                      }}
                      IconButtonProps={{
                        disabled: !!props.error,
                      }}
                    />
                  )}
                  <TooltipIconButton
                    icon={<MoreVert />}
                    tooltip="More"
                    onClick={onMoreVertClickHandler}
                    iconButtonStyle={{
                      ...(isPortraitMobile
                        ? styles.iconFontSizePortraitMobile
                        : {}),
                      ...(!!props.smallCard ? styles.iconButtonRoot : {}),
                    }}
                    IconButtonProps={{
                      disabled: !!props.error,
                    }}
                  />
                </div>
              )}
              {props.customTopRightComponent && (
                <div>{props.customTopRightComponent}</div>
              )}
            </div>
          </div>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
};

export default CardComponent;
