import mapChartData from '../assets/countries.json';
import { MAX_FISCAL_YEAR } from './general.constants';

export const GENDER_OPTIONS = [
    'Female',
    'Male',
    'Non binary',
    'Prefer not to say',
    'Other',
];
export const LOCATIONS = mapChartData.objects.countries.geometries
    .map((e) => e.properties.name)
    .sort();
export const WORKING_SECTORS = ['Engineering', 'Finance', 'Institutions'];
export const WORKING_ROLES = ['Team Member', 'Team Leader', 'Manager'];
export const PROMETEUS_GOALS = ['Invest', 'Study', 'Other'];
export const YEAR_OPTIONS = Array(MAX_FISCAL_YEAR - 1900 - 9)
    .fill(1)
    .map((_, index: number) => String(MAX_FISCAL_YEAR - 15 - index));