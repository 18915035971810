import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerManagementEffectivenessFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import {
  CommonBlockComponent,
  COMMON_SCREENER_ROW_HEIGHT,
} from '../utils/constants';

const ManagementEffectivenessTab = () => {
  const dispatch = useDispatch();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.managementEffectiveness
      : null
  );

  const managementEffectivenessSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(
        ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS,
        block,
        filter,
        value
      )
    );
  };

  return (
    <>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* ROS */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
            cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROS}
            data={tabData?.ROS}
            setFilterValueHandler={managementEffectivenessSetFilterValue}
            isLarge={true}
          />
        </div>
        {/* ROA */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
            cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROA}
            data={tabData?.ROA}
            setFilterValueHandler={managementEffectivenessSetFilterValue}
            isLarge={true}
          />
        </div>
      </div>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* ROE */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
            cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROE}
            data={tabData?.ROE}
            setFilterValueHandler={managementEffectivenessSetFilterValue}
            isLarge={true}
          />
        </div>
        {/* ROIC */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <CommonBlockComponent
            tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
            cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROIC}
            data={tabData?.ROIC}
            setFilterValueHandler={managementEffectivenessSetFilterValue}
            isLarge={true}
          />
        </div>
      </div>
    </>
  );
};

export default ManagementEffectivenessTab;
