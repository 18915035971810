import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Divider,
  InputAdornment,
  menuClasses,
  Palette,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { IUserInformation, VERIFY_OAUTH_URL } from '@prometeus/common';
import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GENDER_OPTIONS,
  LOCATIONS,
  PROMETEUS_GOALS,
  WORKING_ROLES,
  WORKING_SECTORS,
  YEAR_OPTIONS,
} from '../constants/form-options.constants';
import { IMicrosoftLogin } from '../models/authentication.model';
import { setAxiosError } from '../store/actions/error-handling.action';
import { RootState } from '../store/reducers/root.reducer';
import { COMMON_COLORS } from '../ui/colors';
import { boxShadow } from '../ui/functions';
import { authHeader } from '../utils/auth-header';
import { axiosInstance } from '../utils/axios-interceptor';
import { getRedirectUri } from '../utils/common';
import CustomDialogComponent from './dialog.component';
import GoogleLoginButton from './login-button/GoogleLoginButton';
import MicrosoftLoginButton from './login-button/MicrosoftLoginButton';
import SelectTextField from './select-text-field.component';
import TextField, { COMMON_TEXT_FIELD_SX } from './text-field.component';
import TooltipIconButton from './tooltip-icon-button.component';

const getStyles = (palette: Palette) => ({
  textFieldFieldset: COMMON_TEXT_FIELD_SX(palette),
  container: {
    height: '100%',
  },
  button: {
    flex: '1 1 0',
    borderRadius: '2rem !important',
    fontSize: '1.2rem',
    paddingTop: '0.6rem !important',
    paddingBottom: '0.6rem !important',
  },
  buttonFiller: { flex: '1.2 1 0' },
  skipButton: {
    color: 'white',
    border: '0.05rem solid transparent',
    '&:hover': {
      borderColor: 'white',
      backgroundColor: 'transparent',
    },
  },
  selectBox: {
    flex: '1 1 0',
    '& label': {
      '&.MuiInputLabel-shrink': {
        color: 'white !important',
      },
      '&.MuiInputLabel-outlined': {
        transform: 'translate(1rem, 1.3rem) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(1rem, -0.55rem) scale(0.9)',
        },
      },
    },
  },
  select: {
    '& .MuiSelect-select': {
      padding: '1.3rem',
      paddingRight: '2.5rem',
    },
    '& fieldset': {
      border: 'none !important',
    },
  },
  selecFormControl: {
    border: `solid 0.1rem ${COMMON_COLORS.chartGrid} !important`,
  },
  loginButton: {
    backgroundColor: palette.mode === 'dark' ? '#f6f5f4' : 'transparent',
    boxShadow: palette.mode === 'light' ? boxShadow(palette) : 'none',
  },
  divider: { margin: '2.5rem 2.5rem 0rem 2.5rem !important' },
});

const HORIZONTAL_SPACING = 2;
const MAX_NAME_LENGTH = 100;
const LONG_SELECT_MENU_HEIGHT = '20rem';

type Props = {
  registration?: boolean;
  userProfile?: boolean;
  onSkip?: () => void;
  onSubmit: (userInformation: IUserInformation) => void;
};

const UserInformationForm = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);
  const dispatch = useDispatch();

  const { registration, userProfile } = props;
  const userInformation = useSelector(
    (state: RootState) => state.user.userInformation
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [openUpdateEmail, setOpenUpdateEmail] = useState<boolean>(false);

  // Use signature for a double-check
  const [email, setEmail] = useState<string>(userInformation?.email || '');
  const [signature, setSignature] = useState<string>();
  const [firstName, setFirstName] = useState<string>(
    userInformation?.firstName || ''
  );
  const [lastName, setLastName] = useState<string>(
    userInformation?.lastName || ''
  );
  const [year, setYear] = useState<string>(userInformation?.year || '');
  const [gender, setGender] = useState<string>(userInformation?.gender || '');
  const [workingSector, setWorkingSector] = useState<string>(
    userInformation?.workingSector || ''
  );
  const [workingRole, setWorkingRole] = useState<string>(
    userInformation?.workingRole || ''
  );
  const [location, setLocation] = useState<string>(
    userInformation?.location || ''
  );
  const [prometeusGoal, setPrometeusGoal] = useState<string>(
    userInformation?.prometeusGoal || ''
  );

  const submitHandler = () => {
    props.onSubmit({
      email,
      signature,
      firstName,
      lastName,
      year,
      gender,
      workingSector,
      workingRole,
      location,
      prometeusGoal,
    });
  };

  const commonSelectTextFieldProps = {
    editable: true,
    textFieldSx: styles.textFieldFieldset,
    selectSx: styles.select,
    selectBoxSx: styles.selectBox,
    selectFormControlSx: styles.selecFormControl,
  };

  const setValueWrapper =
    (setValue: (value: string) => void) => (value: string) => {
      if (!hasChanges) {
        setHasChanges(true);
      }
      setValue(value);
    };

  const redirectUri = getRedirectUri();

  const validateTokenId = (tokenId: string, oauthProvider: string) => {
    axiosInstance
      .post(
        `${VERIFY_OAUTH_URL}${oauthProvider}`,
        { tokenId, redirectUri },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        const { email: resEmail, signature } = res?.data;
        if (!!resEmail) {
          setValueWrapper(setEmail)(resEmail);
          setSignature(signature);
        }
        setOpenUpdateEmail(false);
      })
      .catch((err) => {
        dispatch(setAxiosError(err));
      });
  };

  /* Update Google email sign-in */
  const onGoogleSuccess = (codeResponse: any) => {
    if (!!codeResponse?.code) {
      validateTokenId(codeResponse.code, 'gmail');
    }
  };

  const onGoogleError = (res: any) => {
    console.debug('Login failed: res:', res);
  };

  const signInGoogle = useGoogleLogin({
    onSuccess: onGoogleSuccess,
    onError: onGoogleError,
    flow: 'auth-code',
    redirect_uri: redirectUri,
  });

  return (
    <>
      <CustomDialogComponent
        open={openUpdateEmail}
        onClose={() => {
          setOpenUpdateEmail(false);
        }}
        maxWidth="sm"
        dialogTitle="Update Email Address"
        dialogContent={
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ padding: '2rem' }}
          >
            <Typography variant="h6" noWrap>
              To update your email address, please verify you can access it
            </Typography>
            <GoogleLoginButton
              signIn={signInGoogle}
              sx={{ loginButton: styles.loginButton }}
              label="Continue with Google"
            />
            <MicrosoftLoginButton
              onSuccess={(data: IMicrosoftLogin) => {
                validateTokenId(data.tokenId, 'microsoft');
              }}
              clientId={process.env?.REACT_APP_MICROSOFT_CLIENT_ID || ''}
              sx={{ loginButton: styles.loginButton }}
              label="Continue with Microsoft"
            />
          </Stack>
        }
      />

      {/* Pre-filled data */}
      {!!userProfile && <Typography variant="h4">Basic Info</Typography>}

      <Stack direction="row" spacing={HORIZONTAL_SPACING}>
        <TextField
          variant="outlined"
          label="Email"
          required
          value={email}
          sx={styles.textFieldFieldset}
          disabled={!!registration}
          {...(!!userProfile
            ? {
                InputProps: {
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <TooltipIconButton
                        icon={<OpenInNew />}
                        tooltip="Update Email Address"
                        onClick={() => {
                          setOpenUpdateEmail(true);
                        }}
                        iconButtonStyle={{ transform: 'scale(0.8)' }}
                      />
                    </InputAdornment>
                  ),
                },
              }
            : {})}
        />
        <TextField
          variant="outlined"
          label="First Name"
          value={firstName}
          sx={{ ...styles.textFieldFieldset }}
          onChange={(event) => {
            setValueWrapper(setFirstName)(event.target.value);
          }}
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
        />
        <TextField
          variant="outlined"
          label="Last Name"
          value={lastName}
          sx={{ ...styles.textFieldFieldset }}
          onChange={(event) => {
            setValueWrapper(setLastName)(event.target.value);
          }}
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
        />
      </Stack>

      <Box sx={styles.divider}>
        <Divider />
      </Box>

      {!!userProfile && <Typography variant="h4">More About You</Typography>}
      {/* Questions */}
      <Stack direction="row" spacing={HORIZONTAL_SPACING}>
        <SelectTextField
          label="What year were you born?"
          value={year}
          setValue={setValueWrapper(setYear)}
          options={YEAR_OPTIONS}
          {...commonSelectTextFieldProps}
          MenuProps={{
            sx: {
              [`& .${menuClasses.list}`]: { height: LONG_SELECT_MENU_HEIGHT },
            },
          }}
        />
        <SelectTextField
          label="How do you identify?"
          value={gender}
          setValue={setValueWrapper(setGender)}
          options={GENDER_OPTIONS}
          {...commonSelectTextFieldProps}
        />
      </Stack>
      <Stack direction="row" spacing={HORIZONTAL_SPACING}>
        <SelectTextField
          label="What kind of work do you do?"
          value={workingSector}
          setValue={setValueWrapper(setWorkingSector)}
          options={WORKING_SECTORS}
          {...commonSelectTextFieldProps}
        />
        <SelectTextField
          label="What is your role?"
          value={workingRole}
          setValue={setValueWrapper(setWorkingRole)}
          options={WORKING_ROLES}
          {...commonSelectTextFieldProps}
        />
      </Stack>
      <Stack direction="row" spacing={HORIZONTAL_SPACING}>
        <SelectTextField
          label="Where are you based?"
          value={location}
          setValue={setValueWrapper(setLocation)}
          options={LOCATIONS}
          {...commonSelectTextFieldProps}
          MenuProps={{
            sx: {
              [`& .${menuClasses.list}`]: { height: LONG_SELECT_MENU_HEIGHT },
            },
          }}
        />
        <SelectTextField
          label="How do you plan to use Prometeus?"
          value={prometeusGoal}
          setValue={setValueWrapper(setPrometeusGoal)}
          options={PROMETEUS_GOALS}
          {...commonSelectTextFieldProps}
        />
      </Stack>

      {/* Licences */}
      {false && !!userProfile && (
        <>
          <Box sx={styles.divider}>
            <Divider />
          </Box>

          <Stack direction="row" spacing={HORIZONTAL_SPACING}>
            <TextField
              variant="outlined"
              label="Licence Type"
              value={'Free Trial'}
              sx={{ ...styles.textFieldFieldset }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              variant="outlined"
              label="Licence Expiration Date"
              value={'30/02/2024'}
              sx={{ ...styles.textFieldFieldset }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Stack>
        </>
      )}

      {/* Buttons */}
      <Stack direction="row" spacing={4}>
        <div style={styles.buttonFiller}></div>
        {!!registration ? (
          <Stack spacing={2} style={{ flex: '1 1 0' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ ...styles.button }}
              onClick={submitHandler}
            >
              Submit
            </Button>
            <Button
              variant="text"
              color="primary"
              sx={{ ...styles.button, ...styles.skipButton }}
              onClick={props.onSkip}
            >
              Skip
            </Button>
          </Stack>
        ) : (
          <>
            {hasChanges && (
              <Button
                variant="contained"
                color="primary"
                sx={{ ...styles.button, flex: '0.7 1 0' }}
                onClick={submitHandler}
              >
                Confirm Changes
              </Button>
            )}
          </>
        )}

        <div style={styles.buttonFiller}></div>
      </Stack>
    </>
  );
};

export default UserInformationForm;
