import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Palette,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FEEDBACK_MAX_CHARACTERS } from '@prometeus/common';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { IFiltersState } from '../../../models/filters.model';
import {
  reportIssueService,
  verifyRecaptchaService,
} from '../../../services/other.service';
import { toggleAlert } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { maskBorderShadow, pxToRem } from '../../../ui/functions';
import ChartLoadingComponent from '../../charts/chart-loading.component';
import CustomDialogComponent from '../../dialog.component';
import RecaptchaComponent from '../../recaptcha.component';
import TextField from '../../text-field.component';
import TooltipIconButton from '../../tooltip-icon-button.component';

type Props = {
  open: boolean;
  onCloseHandler: () => void;
  chartTitleLabel: string;
  chartImage: string;
};

const getStyles = (palette: Palette) => ({
  listItemIconRoot: {
    margin: '0rem 0.05rem 0rem 0rem',
    '& svg': {
      fontSize: '1.8rem',
    },
  },
  textAreaRoot: {
    width: '100%',
    marginBottom: '1rem',
    borderRadius: '0.8rem !important',
    '& .MuiOutlinedInput-root': {
      border: maskBorderShadow(palette),
      borderRadius: '0.8rem !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: `${pxToRem(18.5)} ${pxToRem(14)}`,
    },
    '& .MuiFormHelperText-root': {
      color: palette.text.secondary,
      marginLeft: 'auto',
      marginRight: pxToRem(14),
      marginTop: pxToRem(3),
    },
  },
  paper: {
    backgroundColor: palette.background.paper,
  },
  button: {
    borderRadius: '2rem',
  },
});

const ReportIssueDialogComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = getStyles(theme.palette);

  const { open } = props;

  const onCloseDialogHandler = () => {
    props.onCloseHandler();
  };

  const [issueMessage, setIssueMessage] = useState<string>('');
  const filters: IFiltersState = useSelector(
    (state: RootState) => state.filters
  );

  const onFeedbackMessageChange = (event: any) => {
    setIssueMessage(event.target.value);
  };

  const onReportIssueHandler = async () => {
    setLoading(true);
    try {
      // Check if ref is not null
      if (recaptchaRef?.current) {
        // recaptcha
        const token = await recaptchaRef.current.executeAsync();

        if (token) {
          const verifiedTokenResponse = (await verifyRecaptchaService(token))
            ?.data?.token;

          if (verifiedTokenResponse !== token) {
            throw new Error('Failed reCAPTCHA verification');
          }
        }

        recaptchaRef.current.reset();
      }

      const response = await reportIssueService(
        issueMessage,
        props.chartTitleLabel,
        props.chartImage,
        filters
      );
      if (!!response) {
        dispatch(toggleAlert('Thank you very much for your feedback!'));
      }
    } catch (err) {
      dispatch(
        toggleAlert('An error has occured! Please, try again later.', true)
      );
    }
    props.onCloseHandler();
  };

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (open) {
      setIssueMessage('');
      setLoading(false);
    }
  }, [open]);

  // reCAPTCHA
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loadedRecaptcha, setLoadedRecaptcha] = useState<boolean>(false);

  return (
    <CustomDialogComponent
      open={open}
      onClose={onCloseDialogHandler}
      maxWidth="md"
      sx={{
        paper: styles.paper,
      }}
      dialogTitle={
        <>
          <div>Report Issue - {props.chartTitleLabel}</div>
          <Stack direction="row" alignItems="center">
            <TooltipIconButton
              icon={<Close />}
              tooltip="Close"
              onClick={props.onCloseHandler}
              iconButtonStyle={styles.listItemIconRoot}
            />
          </Stack>
        </>
      }
      dialogContent={
        <>
          <RecaptchaComponent
            ref={recaptchaRef}
            onLoad={() => {
              setLoadedRecaptcha(true);
            }}
          />
          {!loadedRecaptcha || loading ? (
            <ChartLoadingComponent />
          ) : (
            <>
              {props.chartImage && (
                <Stack alignItems="center">
                  <img
                    src={props.chartImage}
                    alt="reported chart"
                    style={{
                      width: '70%',
                    }}
                  />
                </Stack>
              )}
              <Box sx={{ margin: '1rem' }}>
                <Typography variant="h6" style={{ margin: '1rem 0rem' }}>
                  Please, describe in detail the issue you want to report:
                </Typography>
                <div style={{ textAlign: 'center' }}>
                  <TextField
                    multiline={true}
                    rows={4}
                    variant={'outlined'}
                    sx={styles.textAreaRoot}
                    onChange={onFeedbackMessageChange}
                    value={issueMessage}
                    placeholder={'Leave your feedback here'}
                    error={issueMessage.length > FEEDBACK_MAX_CHARACTERS}
                    helperText={`${issueMessage.length}/${FEEDBACK_MAX_CHARACTERS}`}
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{ marginBottom: '1rem' }}
                  >
                    <Button
                      color="inherit"
                      onClick={onCloseDialogHandler}
                      sx={styles.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onReportIssueHandler}
                      disabled={
                        !issueMessage ||
                        issueMessage.length > FEEDBACK_MAX_CHARACTERS
                      }
                      sx={styles.button}
                    >
                      Report Issue
                    </Button>
                  </Stack>
                </div>
              </Box>
            </>
          )}
        </>
      }
    />
  );
};

export default ReportIssueDialogComponent;
