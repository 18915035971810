import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISectorIndustry } from '../../../models/screener/structure.model';
import {
  applyCtySecIndFilters,
  selectSectorCountries,
  selectSectorIndustries,
  selectSectorSectors,
} from '../../../pages/sector-analysis/utils/visuals.actions';
import { A_PAGE_LIMIT } from '../../../pages/sector-analysis/utils/visuals.constants';
import { RootState } from '../../../store/reducers/root.reducer';
import { AFiltersStyles } from '../aggregated-filters/a-filters.component';
import CountrySelectorComponent from '../filter-components/country-selector.component';
import SectorIndustrySelectorComponent from '../filter-components/sector-industry-selector.component';

const SectorTabFiltersComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  /* Get data from state manager */
  const sectorCountries = useSelector(
    (state: RootState) => state.visuals.sector.sectorCountries
  );
  const sectorSectors = useSelector(
    (state: RootState) => state.visuals.sector.sectorSectors
  );
  const sectorSelectedIndustries = useSelector(
    (state: RootState) => state.visuals.sector.sectorSelectedIndustries
  );

  const sectorCountryIdsClickHandler = useCallback(
    (optionIndex: number) => {
      dispatch(selectSectorCountries(optionIndex));
    },
    [dispatch]
  );

  const onCloseHandlerCallback = useCallback(() => {
    dispatch(
      applyCtySecIndFilters(
        sectorCountries,
        sectorSectors,
        sectorSelectedIndustries
      )
    );
  }, [dispatch, sectorCountries, sectorSectors, sectorSelectedIndustries]);

  const sectorDataList = sectorSectors.slice(1);

  return (
    <>
      <CountrySelectorComponent
        label="Countries"
        placeholder={'Select countries'}
        dataList={sectorCountries}
        onClickCheckHandler={sectorCountryIdsClickHandler}
        onCloseHandler={onCloseHandlerCallback}
        sx={AFiltersStyles.customSelect}
      />
      <SectorIndustrySelectorComponent
        label="Sectors & Industries"
        placeholder={`Select up to ${A_PAGE_LIMIT} sectors`}
        dataList={sectorDataList}
        limit={A_PAGE_LIMIT}
        sectorAction={selectSectorSectors}
        industryAction={selectSectorIndustries}
        selectedIndustries={sectorSelectedIndustries?.length || 0}
        onCloseHandler={onCloseHandlerCallback}
        filterTabSecInd="sector"
        sx={AFiltersStyles.customSelect}
        textFieldSx={
          !sectorDataList
            .slice(!!A_PAGE_LIMIT ? 0 : 1)
            .filter((e: ISectorIndustry) => e.shown && e.selected).length
            ? {
                '& fieldset': {
                  border: `solid 0.05rem ${theme.palette.divider} !important`,
                  borderRadius: '2rem',
                  ...AFiltersStyles.missingHighlightBorder,
                },
              }
            : {}
        }
      />
    </>
  );
};

export default SectorTabFiltersComponent;
