import {
  Box,
  menuClasses,
  Palette,
  Select,
  selectClasses,
  SxProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import { iconStyles } from '../styles/icons.styles';
import { formBorder, pxToRem } from '../ui/functions';
import TooltipIconButton from './tooltip-icon-button.component';

type Props = {
  tooltip: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
  sx?: SxProps;
  iconStyles?: SxProps;
};

const getStyles = (palette: Palette) => ({
  iconButtonRoot: {
    color: palette.text.primary,
    minWidth: 0,
    padding: pxToRem(12),
    '& svg': {
      fontSize: '1.8rem',
    },
  },
  menuPaper: {
    backgroundColor: palette.background.default,
    border: formBorder(palette),
    borderRadius: '1rem',
    backgroundImage: 'none !important',
  },
  selectRoot: {
    minWidth: '0 !important',
    paddingRight: '0 !important',
  },
});

const SelectIconComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <Box
      sx={{
        ...iconStyles.customSelectIcon,
        ...props.iconStyles,
        [`& .${selectClasses.standard}`]: {
          paddingRight: '0 !imporant',
          minWidth: '0 !imporant',
        },
        '& .MuiSelect-select': {
          paddingRight: '0 !imporant',
          minWidth: '0 !imporant',
        },
      }}
    >
      <Select
        value={[]}
        open={props.open}
        multiple
        variant="standard"
        sx={{
          [`& .${selectClasses.select}`]: {
            ...styles.selectRoot,
          },
        }}
        MenuProps={{
          sx: {
            [`& .${menuClasses.paper}`]: {
              ...styles.menuPaper,
              ...props.sx,
            },
          },
        }}
        IconComponent={() => (
          <TooltipIconButton
            icon={props.icon}
            tooltip={props.tooltip}
            onClick={() => {
              props.setOpen(true);
            }}
            iconButtonStyle={styles.iconButtonRoot}
          />
        )}
      >
        {props.children}
      </Select>
    </Box>
  );
};

export default SelectIconComponent;
