import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS } from '../utils/visuals.config';

const ProfitMarginAndGrossMarginTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *              Chars Data             *
   ***************************************/
  const profitMarginData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.profitMarginData
  );
  const profitMarginDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.profitMarginDataUpdating
  );
  const grossMarginDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.grossMarginDistributionData
  );
  const profitMarginDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitMarginDistributionDataUpdating
  );
  const profitMarginDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitMarginDistributionData
  );
  const grossMarginDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.grossMarginDataUpdating
  );
  const grossMarginData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.grossMarginData
  );
  const grossMarginDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.grossMarginDistributionDataUpdating
  );

  const [profitMargin, setProfitMargin] = useState<ChartData>();
  const [grossMarginDistribution, setGrossMarginDistribution] =
    useState<ChartData>();
  const [profitMarginDistribution, setProfitMarginDistribution] =
    useState<ChartData>();
  const [grossMargin, setGrossMargin] = useState<ChartData>();

  /* Profit Margin Chart */
  const [profitMarginHasData, profitMarginHasLabels, profitMarginError] =
    extractChartMetadata(profitMarginData);
  useEffect(() => {
    setProfitMargin({
      datasets: createChartDatasetsConfig(
        profitMarginData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [profitMarginData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Gross Margin Chart */
  const [grossMarginHasData, grossMarginHasLabels, grossMarginError] =
    extractChartMetadata(grossMarginData);
  useEffect(() => {
    setGrossMargin({
      datasets: createChartDatasetsConfig(
        grossMarginData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [grossMarginData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Profit Margin Distribution Chart */
  const [
    profitMarginDistributionHasData,
    profitMarginDistributionHasLabels,
    profitMarginDistributionError,
  ] = extractChartMetadata(profitMarginDistributionData);
  useEffect(() => {
    setProfitMarginDistribution({
      labels: profitMarginDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        profitMarginDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [profitMarginDistributionData, screenWidth, currentCompanyTickerId]);

  /* Gross Margin Distribution Chart */
  const [
    grossMarginDistributionHasData,
    grossMarginDistributionHasLabels,
    grossMarginDistributionError,
  ] = extractChartMetadata(grossMarginDistributionData);
  useEffect(() => {
    setGrossMarginDistribution({
      labels: grossMarginDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        grossMarginDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [grossMarginDistributionData, screenWidth, currentCompanyTickerId]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.PROFIT_MARGIN_AND_GROSS_MARGIN));
  }, [dispatch]);

  return (
    <>
      {profitMarginDataUpdating &&
        profitMarginDistributionDataUpdating &&
        grossMarginDataUpdating &&
        grossMarginDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={profitMargin}
          error={
            !!profitMarginError
              ? GENERAL_ERROR_MESSAGE
              : !profitMarginHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.profitMargin}
          hasData={profitMarginHasData}
          exportData={profitMarginData}
          showLegend={true}
          currencySymbol={
            !!profitMarginData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!profitMarginData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!profitMarginDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          titleLabel={
            PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.profitMarginDistribution
          }
          data={profitMarginDistribution}
          error={
            !!profitMarginDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !profitMarginDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={profitMarginDistributionHasData}
          exportData={profitMarginDistributionData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          xAxisType="category"
          currencySymbol={
            !!profitMarginDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!profitMarginDistributionData?.isPercentage}
          updating={!!profitMarginDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={grossMargin}
          error={
            !!grossMarginError
              ? GENERAL_ERROR_MESSAGE
              : !grossMarginHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.grossMargin}
          hasData={grossMarginHasData}
          exportData={grossMarginData}
          showLegend={true}
          currencySymbol={
            !!grossMarginData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!grossMarginData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!grossMarginDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          titleLabel={
            PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.grossMarginDistribution
          }
          data={grossMarginDistribution}
          error={
            !!grossMarginDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !grossMarginDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          hasData={grossMarginDistributionHasData}
          exportData={grossMarginDistributionData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          xAxisType="category"
          currencySymbol={
            !!grossMarginDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!grossMarginDistributionData?.isPercentage}
          updating={!!grossMarginDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default ProfitMarginAndGrossMarginTab;
