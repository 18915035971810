import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PREMIUM_FEATURE_TOOLTIP } from '../../../constants/general.constants';
import { INavMenuLinkItem } from '../../../models/modals.model';
import {
  closeNavMenu,
  openActiveFreeTrialDialog,
} from '../../../store/actions/modals.action';
import { useSelectStyles } from '../../../styles/select.styles';
import BrandFlame from '../../brand-flame.component';
import EnhancedTooltip from '../../enhanced-tooltip.component';
import MenuWrapperComponent from './menu-wrapper.component';

type Props = {
  menuOpen: boolean;
  /* Anchors */
  anchorPosition?: {
    mouseX: null | number;
    mouseY: null | number;
  };
  list?: INavMenuLinkItem[];
};

const NavMenuComponent = (props: Props) => {
  const dispatch = useDispatch();

  const selectStyles = useSelectStyles();

  const { menuOpen, anchorPosition, list } = props;

  const onMenuCloseHandler = () => {
    dispatch(closeNavMenu('away'));
  };

  return (
    <MenuWrapperComponent
      id="nav-menu"
      anchorPosition={anchorPosition}
      open={menuOpen}
      onMenuCloseHandler={onMenuCloseHandler}
    >
      {list?.map((e: INavMenuLinkItem) => {
        const key = e.label;
        const disabled = !!e.disabled;

        const inner = (
          <MenuItem
            key={key}
            sx={{
              ...selectStyles.menuItemRoot,
              ...(disabled ? selectStyles.disabledMenuItem : {}),
            }}
            onClick={() => {
              dispatch(closeNavMenu('click'));
            }}
            disabled={disabled}
          >
            <span className="menu-item-label">{e.label}</span>
            {disabled && <BrandFlame sx={selectStyles.brandFlame} />}
          </MenuItem>
        );

        return disabled ? (
          <EnhancedTooltip
            key={key}
            title={PREMIUM_FEATURE_TOOLTIP}
            onInternalClick={() => {
              dispatch(openActiveFreeTrialDialog());
            }}
          >
            {inner}
          </EnhancedTooltip>
        ) : (
          <Link to={e.path} style={{ all: 'unset' }}>
            {inner}
          </Link>
        );
      })}
    </MenuWrapperComponent>
  );
};

export default NavMenuComponent;
