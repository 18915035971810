import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import {
  IS_FREE_LICENCE_TICKER,
  ITableColumnData,
  ScreenerItemEnum,
} from '@prometeus/common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { resetCompanyData } from '../../../store/actions/companies.action';
import {
  closeTableDialog,
  openActiveFreeTrialDialog,
} from '../../../store/actions/modals.action';
import {
  setScreenerResultsPage,
  setScreenerResultsPerPage,
  setScreenerSortBy,
  setScreenerSortOrder,
  showScreenerResults,
} from '../../../store/actions/screener/results.action';
import { RootState } from '../../../store/reducers/root.reducer';
import LoadingComponent from '../../loading.component';
import TableBodyComponent from '../../table/table-body.component';
import TableHeadComponent from '../../table/table-head.component';
import TablePaginationComponent from '../../table/table-pagination.component';
import * as tableUtils from '../../table/table.constants';

type Props = {
  parentHistory: RouteComponentProps['history'];
};

const MAX_HEIGHT_REM = 45;

const styles = {
  tableContainer: {
    maxHeight: `${MAX_HEIGHT_REM}rem`,
  },
};

const ScreenerResultTableComponent = (props: Props) => {
  const theme = useTheme();

  /* Hooks */
  const dispatch = useDispatch();
  const tableStyles = tableUtils.getTableStyles(theme.palette);
  const filteredTickers = useSelector(
    (state: RootState) => state.screener.structure.filteredTickers
  );

  const results = useSelector(
    (state: RootState) => state.screener.results.results
  );
  const loading = useSelector(
    (state: RootState) => state.screener.results.loading
  );
  const page = useSelector((state: RootState) => state.screener.results.page);
  const resultsPerPage = useSelector(
    (state: RootState) => state.screener.results.resultsPerPage
  );
  const sortBy = useSelector(
    (state: RootState) => state.screener.results.sortBy
  );
  const sortOrder = useSelector(
    (state: RootState) => state.screener.results.sortOrder
  );
  const tableColumns = useSelector(
    (state: RootState) => state.screener.results.tableColumns
  );
  const checksum = useSelector(
    (state: RootState) => state.screener.results.checksum
  );
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  useEffect(() => {
    dispatch(
      showScreenerResults(
        filteredTickers,
        page,
        resultsPerPage,
        tableColumns.map((e: ITableColumnData) => e.item),
        sortBy,
        sortOrder,
        checksum
      )
    );
  }, [
    dispatch,
    filteredTickers,
    page,
    resultsPerPage,
    sortBy,
    sortOrder,
    tableColumns,
    checksum,
  ]);

  /* Handlers */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    dispatch(setScreenerSortOrder(isAsc ? 'desc' : 'asc'));
    dispatch(setScreenerSortBy(property as ScreenerItemEnum));
  };

  const goToCompany = (event: React.MouseEvent<HTMLElement>): void => {
    if (!event.ctrlKey && !event.metaKey) {
      dispatch(resetCompanyData());
      dispatch(closeTableDialog());
    }
  };

  return (
    <Paper sx={tableStyles.paper}>
      {!!loading ? (
        <LoadingComponent transparent={true} />
      ) : (
        <>
          <TableContainer
            sx={{
              ...styles.tableContainer,
              ...tableStyles.container,
              ...(!results?.length
                ? tableStyles.tableContainerOverflowHidden
                : {}),
            }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadComponent
                headCells={tableColumns}
                sortOrder={sortOrder}
                sortBy={sortBy}
                onRequestSort={handleRequestSort}
                rowCount={results.length}
                headFilterProperty="item"
              />
              <TableBodyComponent
                data={results}
                tableColumns={tableColumns}
                emptyRowsMessage="No company"
                formatCellValue={tableUtils.formatCellValue}
                maxHeight={MAX_HEIGHT_REM}
                /* Pagination */
                page={page}
                rowsPerPage={resultsPerPage}
                hasPaginatedData
                /* Sorting */
                order={sortOrder}
                orderBy={sortBy}
                /* TickerId */
                clickableTickerId={true}
                onTickerIdClick={(event, tickerId: string, rowIndex: number) =>
                  !hasActiveLicence &&
                  (rowIndex > 4 || !IS_FREE_LICENCE_TICKER(tickerId))
                    ? dispatch(openActiveFreeTrialDialog())
                    : goToCompany(event)
                }
                isBlurredRow={(index: number) => !hasActiveLicence && index > 4}
              />
            </Table>
          </TableContainer>
          <TablePaginationComponent
            page={page}
            setPage={setScreenerResultsPage}
            rowsPerPage={resultsPerPage}
            setRowsPerPage={setScreenerResultsPerPage}
            dataLength={filteredTickers.length}
            dispatch={true}
            disabled={!hasActiveLicence}
          />
        </>
      )}
    </Paper>
  );
};

export default ScreenerResultTableComponent;
