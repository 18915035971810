import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import {
  createChartDatasetsConfig,
  createRankingChartDatasetConfig,
} from '../../../constants/charts/chart.constants';
import {
  BAR_CHART_PLUGINS,
  getRankChartOptions,
  tuneOptions,
} from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
  MAX_TICK_ROTATION,
  MIN_TICK_ROTATION,
  RANK_AMOUNT_THRESHOLD,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { COMMON_STYLES, DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import {
  IOpenLargeChart,
  IVisualChartRank,
} from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { REVENUE_AND_PROFIT_TITLE_LABELS } from '../utils/visuals.config';

const RevenueAndProfitTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const revenueVsIncomeData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueVsIncomeData
  );
  const revenueVsIncomeDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.revenueVsIncomeDataUpdating
  );
  const revenueGrowthYoyData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueGrowthYoyData
  );
  const revenueGrowthYoyDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.revenueGrowthYoyDataUpdating
  );
  const revenueRankData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueRankData
  );
  const revenueRankDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueRankDataUpdating
  );
  const profitabilityRankData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.profitabilityRankData
  );
  const profitabilityRankDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitabilityRankDataUpdating
  );
  const annualRevenueGrowthRateData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.annualRevenueGrowthRateData
  );
  const annualRevenueGrowthRateDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.annualRevenueGrowthRateDataUpdating
  );
  const revenueRankFullData = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueRankFullData
  );
  const profitabilityRankFullData = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitabilityRankFullData
  );

  const [revenueVsIncome, setRevenueVsIncome] = useState<ChartData>();
  const [revenueGrowthYoy, setRevenueGrowthYoy] = useState<ChartData>();
  const [revenueRank, setRevenueRank] = useState<ChartData>();
  const [revenueRankFull, setRevenueRankFull] = useState<ChartData>();
  const [profitabilityRank, setProfitabilityRank] = useState<ChartData>();
  const [profitabilityRankFull, setProfitabilityRankFull] =
    useState<ChartData>();
  const [annualRevenueGrowthRate, setAnnualRevenueGrowthRate] =
    useState<ChartData>();

  /* Revenue VS Income Chart */
  const [
    revenueVsIncomeHasData,
    revenueVsIncomeHasLabels,
    revenueVsIncomeError,
  ] = extractChartMetadata(revenueVsIncomeData);
  useEffect(() => {
    setRevenueVsIncome({
      datasets: createChartDatasetsConfig(revenueVsIncomeData, screenWidth, {
        useDollars,
        isTime: true,
        isBar: true,
        fiscalYear,
      }),
    });
  }, [revenueVsIncomeData, useDollars, fiscalYear, screenWidth]);

  /* Revenue Growth YoY Chart */
  const [
    revenueGrowthYoyHasData,
    revenueGrowthYoyHasLabels,
    revenueGrowthYoyError,
  ] = extractChartMetadata(revenueGrowthYoyData);
  useEffect(() => {
    setRevenueGrowthYoy({
      datasets: createChartDatasetsConfig(
        revenueGrowthYoyData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    revenueGrowthYoyData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /* Profitability Rank Chart */
  const [
    profitabilityRankHasData,
    profitabilityRankHasLabels,
    profitabilityRankError,
  ] = extractChartMetadata(profitabilityRankData);
  useEffect(() => {
    const labels = profitabilityRankData?.labels;
    const ranks = profitabilityRankData?.rank;

    if (labels && ranks) {
      setProfitabilityRank({
        labels: labels.map((label: string, index: number): string[] => [
          label,
          `${ranks[index]}º`,
        ]),
        datasets: createRankingChartDatasetConfig(
          profitabilityRankData,
          screenWidth
        ),
      });
    }
  }, [profitabilityRankData, screenWidth]);

  // Profitability Rank Full Chart
  useEffect(() => {
    let data: IVisualChartRank | undefined = profitabilityRankFullData;

    // Build selected ticker if not present
    if (
      (!data?.selectedTicker || data?.selectedTicker?.length === 0) &&
      data?.labels &&
      data.rank
    ) {
      const ticker: string =
        profitabilityRankData?.labels.find(
          (e: string, i: number) => profitabilityRankData?.selectedTicker[i] > 0
        ) || '';
      const selectedTicker: number[] =
        data?.labels?.map((e: string) => (e === ticker ? 1 : 0)) || [];
      data = !!data
        ? {
            ...data,
            selectedTicker: selectedTicker,
          }
        : undefined;
      setProfitabilityRankFull({
        labels: data?.labels.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            arr.length < RANK_AMOUNT_THRESHOLD ? `${data?.rank[index]}º` : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(data, screenWidth),
      });
    } else {
      const labels = profitabilityRankFullData?.labels;
      const ranks = profitabilityRankFullData?.rank;

      setProfitabilityRankFull({
        labels: labels?.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            ranks && arr.length < RANK_AMOUNT_THRESHOLD
              ? `${ranks[index]}º`
              : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(
          profitabilityRankFullData,
          screenWidth
        ),
      });
    }
  }, [
    profitabilityRankData,
    profitabilityRankFullData,
    useDollars,
    screenWidth,
  ]);

  /* Revenue Rank Chart */
  const [revenueRankHasData, revenueRankHasLabels, revenueRankError] =
    extractChartMetadata(revenueRankData);
  useEffect(() => {
    const labels = revenueRankData?.labels;
    const ranks = revenueRankData?.rank;

    if (labels && ranks) {
      setRevenueRank({
        labels: labels.map((label: string, index: number): string[] => [
          label,
          `${ranks[index]}º`,
        ]),
        datasets: createRankingChartDatasetConfig(revenueRankData, screenWidth),
      });
    }
  }, [revenueRankData, screenWidth]);

  // Revenue Rank Full Chart
  useEffect(() => {
    let data: IVisualChartRank | undefined = revenueRankFullData;

    // Build selected ticker if not present
    if (
      (!data?.selectedTicker || data?.selectedTicker?.length === 0) &&
      data?.labels &&
      data.rank
    ) {
      const ticker: string =
        revenueRankData?.labels.find(
          (e: string, i: number) => revenueRankData?.selectedTicker[i] > 0
        ) || '';
      const selectedTicker: number[] =
        data?.labels?.map((e: string) => (e === ticker ? 1 : 0)) || [];
      data = !!data
        ? {
            ...data,
            selectedTicker: selectedTicker,
          }
        : undefined;
      setRevenueRankFull({
        labels: data?.labels.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            arr.length < RANK_AMOUNT_THRESHOLD ? `${data?.rank[index]}º` : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(data, screenWidth),
      });
    } else {
      const labels = revenueRankFullData?.labels;
      const ranks = revenueRankFullData?.rank;

      setRevenueRankFull({
        labels: labels?.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            ranks && arr.length < RANK_AMOUNT_THRESHOLD
              ? `${ranks[index]}º`
              : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(
          revenueRankFullData,
          screenWidth
        ),
      });
    }
  }, [revenueRankData, revenueRankFullData, screenWidth]);

  /* Annual Revenue Growth Rate Chart */
  const [
    annualRevenueGrowthRateHasData,
    annualRevenueGrowthRateHasLabels,
    annualRevenueGrowthRateError,
  ] = extractChartMetadata(annualRevenueGrowthRateData);
  useEffect(() => {
    setAnnualRevenueGrowthRate({
      labels: annualRevenueGrowthRateData?.labels,
      datasets: createChartDatasetsConfig(
        annualRevenueGrowthRateData,
        screenWidth,
        {
          useDollars,
          isBar: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [
    annualRevenueGrowthRateData,
    useDollars,
    screenWidth,
    currentCompanyTickerId,
  ]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  const onOpenLargeClickRevenueRankFullHandler = (data: IOpenLargeChart) => {
    const rankChartOptions = getRankChartOptions(
      revenueRankFullData &&
        revenueRankFullData?.data1?.length > RANK_AMOUNT_THRESHOLD
        ? MAX_TICK_ROTATION
        : MIN_TICK_ROTATION,
      screenWidth
    );
    dispatch(
      openLargeChartDialog({
        ...data,
        data: revenueRankFull as ChartData,
        options: tuneOptions({
          screenWidth,
          options: rankChartOptions,
          showLegend: false,
          xType: 'category',
          tooltipSymbol: !!revenueRankFullData?.isCurrency
            ? {
                left: !!revenueRankFullData?.isCurrency,
                symbol: (useDollars ? DOLLAR_ST : currencySt) || '%',
              }
            : !!revenueRankFullData?.isPercentage
            ? {
                left: false,
                symbol: '%',
              }
            : undefined,
        }),
      })
    );
  };

  const onOpenLargeClickProfitabilityRankFullHandler = (
    data: IOpenLargeChart
  ) => {
    const rankChartOptions = getRankChartOptions(
      profitabilityRankFullData &&
        profitabilityRankFullData?.data1?.length > RANK_AMOUNT_THRESHOLD
        ? MAX_TICK_ROTATION
        : MIN_TICK_ROTATION,
      screenWidth
    );
    dispatch(
      openLargeChartDialog({
        ...data,
        data: profitabilityRankFull as ChartData,
        options: tuneOptions({
          screenWidth,
          options: {
            ...rankChartOptions,
            scales: {
              ...rankChartOptions.scales,
              y: {
                // type: 'logarithmic',
                max: 300,
                min: -300,
              },
            },
          },
          showLegend: false,
          xType: 'category',
          tooltipSymbol: !!profitabilityRankFullData?.isCurrency
            ? {
                left: !!profitabilityRankFullData?.isCurrency,
                symbol: (useDollars ? DOLLAR_ST : currencySt) || '%',
              }
            : !!profitabilityRankFullData?.isPercentage
            ? {
                left: false,
                symbol: '%',
              }
            : undefined,
        }),
      })
    );
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.REVEUE_AND_PROFIT));
  }, [dispatch]);

  // TODO add responsive row
  return (
    <>
      {revenueVsIncomeDataUpdating &&
        revenueGrowthYoyDataUpdating &&
        revenueRankDataUpdating &&
        profitabilityRankDataUpdating &&
        annualRevenueGrowthRateDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <div style={{ ...COMMON_STYLES.flexDisplay, height: '100%' }}>
        <div
          style={{
            ...COMMON_STYLES.flexDisplay,
            flexDirection: 'column',
            ...COMMON_STYLES.flexTwoOneZero,
          }}
        >
          <div
            style={{
              height: '52%',
              marginBottom: '3%',
            }}
          >
            <VisualChartComponent
              containerHeight={true}
              type="bar"
              data={revenueVsIncome}
              error={
                !!revenueVsIncomeError
                  ? GENERAL_ERROR_MESSAGE
                  : !revenueVsIncomeHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.revenueVsIncome}
              hasData={revenueVsIncomeHasData}
              exportData={revenueVsIncomeData}
              showLegend={true}
              options={BAR_CHART_PLUGINS}
              currencySymbol={
                !!revenueVsIncomeData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!revenueVsIncomeData?.isPercentage}
              timeTooltipFormat={
                activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
              }
              updating={!!revenueVsIncomeDataUpdating}
              onOpenChartDialogClick={onOpenChartDialogClickHandler}
            />
          </div>
          <div
            style={{
              height: '38.3%',
            }}
          >
            <VisualChartComponent
              containerHeight={true}
              type="line"
              data={revenueGrowthYoy}
              error={
                !!revenueGrowthYoyError
                  ? GENERAL_ERROR_MESSAGE
                  : !revenueGrowthYoyHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.revenueGrowthYoy}
              hasData={revenueGrowthYoyHasData}
              exportData={revenueGrowthYoyData}
              showLegend={true}
              currencySymbol={
                !!revenueGrowthYoyData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!revenueGrowthYoyData?.isPercentage}
              timeTooltipFormat={
                activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
              }
              updating={!!revenueGrowthYoyDataUpdating}
              onOpenChartDialogClick={onOpenChartDialogClickHandler}
            />
          </div>
        </div>
        <div
          style={{
            ...COMMON_STYLES.flexDisplay,
            flexDirection: 'column',
            // justifyContent: 'space-between',
            height: '93.3%',
            ...COMMON_STYLES.flexOneOneZero,
          }}
        >
          <div
            style={{
              height: '31.5%',
              marginBottom: '5%',
            }}
          >
            <VisualChartComponent
              containerHeight={true}
              type="bar"
              data={revenueRank}
              error={
                !!revenueRankError
                  ? GENERAL_ERROR_MESSAGE
                  : !revenueRankHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.revenueRank}
              hasData={revenueRankHasData}
              exportData={revenueRankFullData}
              options={getRankChartOptions(0, screenWidth)}
              xAxisType="category"
              currencySymbol={
                !!revenueRankData?.isCurrency ? DOLLAR_ST : undefined
              }
              isPercentage={!!revenueRankData?.isPercentage}
              updating={!!revenueRankDataUpdating}
              onOpenChartDialogClick={onOpenLargeClickRevenueRankFullHandler}
              smallChart={true}
            />
          </div>
          <div
            style={{
              height: '31.5%',
              marginBottom: '5%',
            }}
          >
            <VisualChartComponent
              containerHeight={true}
              type="bar"
              data={profitabilityRank}
              error={
                !!profitabilityRankError
                  ? GENERAL_ERROR_MESSAGE
                  : !profitabilityRankHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.profitabilityRank}
              hasData={profitabilityRankHasData}
              exportData={profitabilityRankFullData}
              options={getRankChartOptions(0, screenWidth)}
              xAxisType="category"
              currencySymbol={
                !!profitabilityRankData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!profitabilityRankData?.isPercentage}
              updating={!!profitabilityRankDataUpdating}
              onOpenChartDialogClick={
                onOpenLargeClickProfitabilityRankFullHandler
              }
              smallChart={true}
            />
          </div>
          <div
            style={{
              height: '31.5%',
            }}
          >
            <VisualChartComponent
              containerHeight={true}
              type="bar"
              titleLabel={
                REVENUE_AND_PROFIT_TITLE_LABELS.annualRevenueGrowthRate
              }
              data={annualRevenueGrowthRate}
              error={
                !!annualRevenueGrowthRateError
                  ? GENERAL_ERROR_MESSAGE
                  : !annualRevenueGrowthRateHasLabels
                  ? GENERAL_NO_DATA_MESSAGE
                  : undefined
              }
              hasData={annualRevenueGrowthRateHasData}
              exportData={annualRevenueGrowthRateData}
              showLegend={true}
              options={BAR_CHART_PLUGINS}
              xAxisType="category"
              currencySymbol={
                !!annualRevenueGrowthRateData?.isCurrency
                  ? useDollars
                    ? DOLLAR_ST
                    : currencySt
                  : undefined
              }
              isPercentage={!!annualRevenueGrowthRateData?.isPercentage}
              updating={!!annualRevenueGrowthRateDataUpdating}
              onOpenChartDialogClick={onOpenChartDialogClickHandler}
              smallChart={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueAndProfitTab;
