import { combineReducers } from 'redux';
import balanceSheetAnalysisVisualsReducer from '../../pages/balance-sheet-analysis/utils/visuals.reducer';
import cashflowAnalysisVisualsReducer from '../../pages/cash-flow-analysis/utils/visuals.reducer';
import companySummaryVisualsReducer from '../../pages/company-summary/utils/visuals.reducer';
import financialStrengthVisualsReducer from '../../pages/financial-strength/utils/visuals.reducer';
import incomeStatementAnalysisVisualsReducer from '../../pages/income-statement-analysis/utils/visuals.reducer';
import managementEffectivenessVisualsReducer from '../../pages/management-effectiveness/utils/visuals.reducer';
import marketCoverageVisualsReducer from '../../pages/market-coverage/utils/visuals.reducer';
import sectorAnalysisVisualsReducer from '../../pages/sector-analysis/utils/visuals.reducer';
import stockAnalysisVisualsReducer from '../../pages/stock-analysis/utils/visuals.reducer';
import adminCompaniesReducer from './admin/companies.reducer';
import adminManagersReducer from './admin/icons-managers.reducer';
import adminIconsReducer from './admin/icons.reducer';
import logsReducer from './admin/logs.reducer';
import plansReducer from './admin/plans.reducer';
import usersReducer from './admin/users.reducer';
import authenticationReducer from './authentication.reducer';
import companiesReducer from './companies.reducer';
import descriptionsReducer from './descriptions.reducer';
import errorHandlingReducer from './error-handling.reducer';
import filtersReducer from './filters.reducer';
import managersReducer from './icons-managers.reducer';
import iconsReducer from './icons.reducer';
import modalsReducer from './modals.reducer';
import peerGroupReducer from './peer-group.reducer';
import screenerResultsReducer from './screener/results.reducer';
import screenerStructureReducer from './screener/structure.reducer';
import structureReducer from './structure.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  admin: combineReducers({
    companies: adminCompaniesReducer,
    icons: adminIconsReducer,
    logs: logsReducer,
    managers: adminManagersReducer,
    users: usersReducer,
    plans: plansReducer
  }),
  authentication: authenticationReducer,
  companies: companiesReducer,
  descriptions: descriptionsReducer,
  errorHandling: errorHandlingReducer,
  filters: filtersReducer,
  icons: iconsReducer,
  managers: managersReducer,
  modals: modalsReducer,
  peerGroup: peerGroupReducer,
  screener: combineReducers({
    structure: screenerStructureReducer,
    results: screenerResultsReducer,
  }),
  structure: structureReducer,
  user: userReducer,
  visuals: combineReducers({
    companySummary: companySummaryVisualsReducer,
    stock: stockAnalysisVisualsReducer,
    incomeStatement: incomeStatementAnalysisVisualsReducer,
    balanceSheet: balanceSheetAnalysisVisualsReducer,
    cashflow: cashflowAnalysisVisualsReducer,
    managementEffectiveness: managementEffectivenessVisualsReducer,
    financialStrength: financialStrengthVisualsReducer,
    sector: sectorAnalysisVisualsReducer,
    marketCoverage: marketCoverageVisualsReducer,
  }),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
