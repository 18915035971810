import { ITableColumnData, ScreenerItemEnum } from '@prometeus/common';
import { Dispatch } from 'redux';
export interface IScreenerResultUnmapped {
  CompanyId: string;
  TickerId: string;
  'CapEx To Net Income'?: number;
  'Close adj'?: number;
  'Company Name'?: string;
  'Country Name'?: string;
  'Current Ratio'?: number;
  'D/E adj'?: number;
  'Debt Payback Period'?: number;
  Dividend?: number;
  'Dividend TTM'?: number;
  'Dividend Yield'?: number;
  'Gross Margin'?: number;
  Industry?: string;
  'Last Year'?: number;
  'Market Cap'?: number;
  'Net Income'?: number;
  'Op. Inc. %'?: number;
  'P/E'?: number;
  'Payout Ratio'?: number;
  'Profit Margin'?: number;
  'Quick Ratio'?: number;
  'ROA adj'?: number;
  'ROE adj'?: number;
  ROIC?: number;
  ROS?: number;
  Revenue?: number;
  Sector?: string;
  'Total Assets'?: number;
  'Total Equity'?: number;
  'Total Liabilities'?: number;
}

export interface IScreenerResult {
  tickerId: string;
  companyId: string;
  companyName?: string;
  icon?: string;
  countryName?: string;
  industry?: string;
  sector?: string;
  // Screener
  dividendTTM?: number;
  dividendYield?: number;
  closeadj?: number;
  pE?: number;
  marketCap?: number;
  dividend?: number;
  revenue?: number;
  netIncome?: number;
  profitMargin?: number;
  grossMargin?: number;
  opInc?: number;
  rOS?: number;
  lastYear?: number;
  totalAssets?: number;
  totalLiabilities?: number;
  totalEquity?: number;
  currentRatio?: number;
  quickRatio?: number;
  dEadj?: number;
  debtPaybackPeriod?: number;
  rOAadj?: number;
  rOEadj?: number;
  rOIC?: number;
  payoutRatio?: number;
  capExToNetIncome?: number;
}

export const mapScreenerResults = (
  data: IScreenerResultUnmapped
): IScreenerResult => ({
  tickerId: data['TickerId'],
  companyId: data['CompanyId'],
  companyName: data['Company Name'] || undefined,
  countryName: data['Country Name'] || undefined,
  industry: data['Industry'] || undefined,
  sector: data['Sector'] || undefined,
  // Screener
  dividendTTM: data['Dividend TTM'] || undefined,
  dividendYield: data['Dividend Yield'] || undefined,
  closeadj: data['Close adj'] || undefined,
  pE: data['P/E'] || undefined,
  marketCap: data['Market Cap'] || undefined,
  dividend: data['Dividend'] || undefined,
  revenue: data['Revenue'] || undefined,
  netIncome: data['Net Income'] || undefined,
  profitMargin: data['Profit Margin'] || undefined,
  grossMargin: data['Gross Margin'] || undefined,
  opInc: data['Op. Inc. %'] || undefined,
  rOS: data['ROS'] || undefined,
  lastYear: data['Last Year'] || undefined,
  totalAssets: data['Total Assets'] || undefined,
  totalLiabilities: data['Total Liabilities'] || undefined,
  totalEquity: data['Total Equity'] || undefined,
  currentRatio: data['Current Ratio'] || undefined,
  quickRatio: data['Quick Ratio'] || undefined,
  dEadj: data['D/E adj'] || undefined,
  debtPaybackPeriod: data['Debt Payback Period'] || undefined,
  rOAadj: data['ROA adj'] || undefined,
  rOEadj: data['ROE adj'] || undefined,
  rOIC: data['ROIC'] || undefined,
  payoutRatio: data['Payout Ratio'] || undefined,
  capExToNetIncome: data['CapEx To Net Income'] || undefined,
});

export type SortOrderType = 'asc' | 'desc';

export interface IScreenerResultsState {
  /* Pagination */
  page: number;
  resultsPerPage: number;
  sortBy: ScreenerItemEnum;
  sortOrder: SortOrderType;
  tableColumns: ITableColumnData[];

  checksum?: string;

  /* Results */
  results: IScreenerResult[];

  // Metadata
  loading: boolean;
  error?: string;
}

/* SET_SCREENER_TABLE_COLUMNS */
export const SET_SCREENER_TABLE_COLUMNS = 'SET_SCREENER_TABLE_COLUMNS';
export interface ISetScreenerTableColumns {
  type: typeof SET_SCREENER_TABLE_COLUMNS;
  payload: ITableColumnData[];
}

/* SET_SCREENER_RESULTS_PAGE */
export const SET_SCREENER_RESULTS_PAGE = 'SET_SCREENER_RESULTS_PAGE';
export interface ISetScreenerResultsPage {
  type: typeof SET_SCREENER_RESULTS_PAGE;
  payload: number;
}

/* SET_SCREENER_RESULTS_PER_PAGE */
export const SET_SCREENER_RESULTS_PER_PAGE = 'SET_SCREENER_RESULTS_PER_PAGE';
export interface ISetScreenerResultsPerPage {
  type: typeof SET_SCREENER_RESULTS_PER_PAGE;
  payload: number;
}

/* SET_CHECKSUM */
export const SET_CHECKSUM = 'SET_CHECKSUM';
export interface ISetChecksum {
  type: typeof SET_CHECKSUM;
  payload: string;
}

/* SET_SCREENER_SORT_BY */
export const SET_SCREENER_SORT_BY = 'SET_SCREENER_SORT_BY';
export interface ISetScreenerSortBy {
  type: typeof SET_SCREENER_SORT_BY;
  payload: ScreenerItemEnum;
}

/* SET_SCREENER_SORT_ORDER */
export const SET_SCREENER_SORT_ORDER = 'SET_SCREENER_SORT_ORDER';
export interface ISetScreenerSortOrder {
  type: typeof SET_SCREENER_SORT_ORDER;
  payload: SortOrderType;
}

/* SHOW_SCREENER_RESULTS */
export const SHOW_SCREENER_RESULTS = 'SHOW_SCREENER_RESULTS';
export interface IShowScreenerResults {
  type: typeof SHOW_SCREENER_RESULTS;
}

export const SHOW_SCREENER_RESULTS_SUCCESS = 'SHOW_SCREENER_RESULTS_SUCCESS';
export interface IShowScreenerResultsSuccess {
  type: typeof SHOW_SCREENER_RESULTS_SUCCESS;
  payload: {
    data: IScreenerResult[];
    checksum: string;
  };
}

export const SHOW_SCREENER_RESULTS_FAIL = 'SHOW_SCREENER_RESULTS_FAIL';
export interface IShowScreenerResultsFail {
  type: typeof SHOW_SCREENER_RESULTS_FAIL;
  payload: string;
}

export type ShowScreenerResultsDispatchType =
  | IShowScreenerResults
  | IShowScreenerResultsSuccess
  | IShowScreenerResultsFail;

/* FETCH_PRELIMINARY_RESULTS */
export const FETCH_PRELIMINARY_RESULTS = 'FETCH_PRELIMINARY_RESULTS';
export interface IFetchPreliminaryResults {
  type: typeof FETCH_PRELIMINARY_RESULTS;
  payload: string[];
  asyncDispatch: Dispatch<any>;
}

/* DispatchActionTypes */
export type DispatchActionTypes =
  | ShowScreenerResultsDispatchType
  | ISetScreenerResultsPage
  | ISetScreenerResultsPerPage
  | ISetScreenerSortOrder
  | ISetScreenerSortBy
  | ISetScreenerTableColumns
  | ISetChecksum
  | IFetchPreliminaryResults;
