import {
  DEFAULT_SCREENER_FILTER_VALUE,
  IScreenerFilter,
  IScreenerTab,
  objectMap,
  ScreenerFilterCardEnum,
  ScreenerFilterCardType,
} from '@prometeus/common';
import { Set as ImmutableSet } from 'immutable';
import { MD5 } from 'object-hash';
import {
  handleSelectedCountry,
  handleSelectedIndustry,
  handleSelectedSector,
} from '../../../constants/companies.constants';
import { SCREENER_COLUMNS } from '../../../constants/config/screener/screener-columns';
import SCREENER_STRUCTURE_CONFIG from '../../../constants/config/screener/screener-structure';
import { PeerGroupEnum } from '../../../models/peer-group.model';
import {
  APPLY_FILTERS,
  DispatchActionTypes,
  FETCH_INDUSTRY_COMPANIES,
  FETCH_INDUSTRY_COMPANIES_FAIL,
  FETCH_INDUSTRY_COMPANIES_SUCCESS,
  FETCH_SECTORS_DATA,
  FETCH_SECTORS_DATA_FAIL,
  FETCH_SECTORS_DATA_SUCCESS,
  FREEPREMIUM_SCREENER_COUNTRIES,
  IScreenerStructureState,
  ISecIndCty,
  RESET_SCREENER_FILTER_BLOCK_VALUE,
  RESET_SCREENER_TAB_FILTERS,
  ScreenerTabNameEnum,
  SELECT_COUNTRY_IDS,
  SELECT_INDUSTRIES,
  SELECT_SECTORS,
  SEND_SCREENER_FILTERS,
  SEND_SCREENER_FILTERS_FAIL,
  SEND_SCREENER_FILTERS_SUCCESS,
  SET_SCREENER_FILTER_VALUE,
  SET_SCREENER_PEER_GROUP,
  SETUP_NOT_ACTIVE_LICENCE_COUNTRIES,
} from '../../../models/screener/structure.model';
import { sendScreenerFilters } from '../../actions/screener/structure.action';

const BETWEEN_YEARS_BLOCKS: Set<ScreenerFilterCardType> = new Set([
  ScreenerFilterCardEnum.PRICE_EARNINGS,
  ScreenerFilterCardEnum.DIVIDEND,
  ScreenerFilterCardEnum.DIVIDEND_YIELD,
  ScreenerFilterCardEnum.PAYOUT_RATIO,
  ScreenerFilterCardEnum.PROFIT_MARGIN,
  ScreenerFilterCardEnum.GROSS_MARGIN,
  ScreenerFilterCardEnum.OP_INC,
  ScreenerFilterCardEnum.ROS,
  ScreenerFilterCardEnum.ROE,
  ScreenerFilterCardEnum.ROA,
  ScreenerFilterCardEnum.ROIC,
  ScreenerFilterCardEnum.CURRENT_RATIO,
  ScreenerFilterCardEnum.QUICK_RATIO,
  ScreenerFilterCardEnum.DEBT_TO_EQUITY,
  ScreenerFilterCardEnum.DEBT_PAYBACK,
]);

const initialState: IScreenerStructureState = {
  screenerColumnSetup: SCREENER_COLUMNS,
  screenerStructure: SCREENER_STRUCTURE_CONFIG,
  activeFilters: ImmutableSet<string>([]),
  filteredTickers: [],
  sectors: [],
  selectedIndustries: [],
  selectedCountries: [],
  peerGroup: PeerGroupEnum.REGIONAL,
  // loadings
  industryCompaniesLoading: false,
  fetchSectorsDataLoading: false,
  sendScreenerFilterLoading: false,
  showScreenerResultLoading: false,
};

const screenerStructureReducer = (
  state: IScreenerStructureState = initialState,
  action: DispatchActionTypes
): IScreenerStructureState => {
  switch (action.type) {
    case SET_SCREENER_FILTER_VALUE:
      const newValue: any = action.payload.value;

      if (
        !!state?.screenerStructure &&
        state?.screenerStructure[action.payload.tab]
      ) {
        return {
          ...state,
          screenerStructure: {
            ...state.screenerStructure,
            [action.payload.tab]: {
              ...state.screenerStructure[action.payload.tab],
              [action.payload.cardBlock]: {
                ...state.screenerStructure[action.payload.tab][
                action.payload.cardBlock
                ],
                [action.payload.filter]: newValue,
                active: true,
              },
            },
          },
        };
      }

      return {
        ...state,
      };

    /***************************************
     *                 Reset               *
     ***************************************/
    case RESET_SCREENER_FILTER_BLOCK_VALUE:
      if (
        !!state?.screenerStructure &&
        state?.screenerStructure[action.payload.tab]
      ) {
        return {
          ...state,
          screenerStructure: {
            ...state.screenerStructure,
            [action.payload.tab]: {
              ...state.screenerStructure[action.payload.tab],
              [action.payload.cardBlock]: {
                ...state.screenerStructure[action.payload.tab][
                action.payload.cardBlock
                ],
                active: false,
                ...DEFAULT_SCREENER_FILTER_VALUE(
                  BETWEEN_YEARS_BLOCKS.has(action.payload.cardBlock),
                  action.payload.cardBlock === ScreenerFilterCardEnum.PRICE,
                  action.payload.cardBlock ===
                  ScreenerFilterCardEnum.MARKET_CAP,
                  (
                    [
                      ScreenerFilterCardEnum.PRICE_EARNINGS,
                      ScreenerFilterCardEnum.DIVIDEND_YIELD,
                    ] as ScreenerFilterCardType[]
                  ).includes(action.payload.cardBlock)
                ),
              },
            },
          },
        };
      }

      return {
        ...state,
      };

    case RESET_SCREENER_TAB_FILTERS: {
      if (state.screenerStructure) {
        const screenerStructure = { ...state.screenerStructure };
        let tabNames: ScreenerTabNameEnum[] = [];
        if (action.payload === -1) {
          // Reset all
          tabNames = [
            ScreenerTabNameEnum.STOCK,
            ScreenerTabNameEnum.INCOME_STATEMENT,
            ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS,
            ScreenerTabNameEnum.FINANCIAL_STRENGTH,
          ];
        } else {
          if (![0, 1, 2, 3].includes(action.payload)) {
            return {
              ...state,
            };
          }
          const tabName =
            action.payload === 0
              ? ScreenerTabNameEnum.STOCK
              : action.payload === 1
                ? ScreenerTabNameEnum.INCOME_STATEMENT
                : action.payload === 2
                  ? ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS
                  : ScreenerTabNameEnum.FINANCIAL_STRENGTH;

          tabNames = [tabName];
        }

        tabNames.forEach((tabName: ScreenerTabNameEnum) => {
          screenerStructure[tabName] = objectMap(
            screenerStructure[tabName],
            (key: string, value: IScreenerFilter) => ({
              ...value,
              ...DEFAULT_SCREENER_FILTER_VALUE(
                value.minYear !== null, // hasTimeRange
                key === ScreenerFilterCardEnum.PRICE,
                key === ScreenerFilterCardEnum.MARKET_CAP,
                (
                  [
                    ScreenerFilterCardEnum.PRICE_EARNINGS,
                    ScreenerFilterCardEnum.DIVIDEND_YIELD,
                  ] as string[]
                ).includes(key)
              ),
              active: false,
            })
          );
        });

        return {
          ...state,
          screenerStructure,
        };
      }
      return {
        ...state,
      };
    }

    /***************************************
     *        Send and apply filters       *
     ***************************************/
    case APPLY_FILTERS:
      let newActiveFilters = ImmutableSet<string>([]);

      if (state.screenerStructure) {
        const checksum = MD5([
          state.selectedCountries,
          state.selectedIndustries,
          state.sectors,
          state.peerGroup,
          state.screenerStructure,
        ]);

        if (state.screenerStructureChecksum !== checksum) {
          action.asyncDispatch(
            sendScreenerFilters(
              state.selectedCountries,
              state.sectors,
              state.selectedIndustries,
              state.peerGroup,
              state.screenerStructure
            )
          );

          const mapped: string[] = [];
          Object.values(state.screenerStructure).forEach(
            (value: IScreenerTab) => {
              Object.values(value).forEach((e: IScreenerFilter) => {
                if (!!e.active) {
                  mapped.push(e.item);
                }
              });
            }
          );

          newActiveFilters = ImmutableSet<string>(mapped);
        }
        return {
          ...state,
          activeFilters: newActiveFilters,
          screenerStructureChecksum: checksum,
        };
      }
      return state;

    case SEND_SCREENER_FILTERS:
      return {
        ...state,
        sendScreenerFilterLoading: true,
      };

    case SEND_SCREENER_FILTERS_SUCCESS:
      return {
        ...state,
        sendScreenerFilterLoading: false,
        filteredTickers: action.payload,
      };

    case SEND_SCREENER_FILTERS_FAIL:
      return {
        ...state,
        sendScreenerFilterLoading: false,
        error: action.payload,
      };

    /***************************************
     *           Sector & Industry         *
     ***************************************/
    case FETCH_SECTORS_DATA:
      return {
        ...state,
        fetchSectorsDataLoading: true,
      };
    case FETCH_SECTORS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetchSectorsDataLoading: false,
      };
    case FETCH_SECTORS_DATA_FAIL:
      return {
        ...state,
        fetchSectorsDataLoading: false,
        error: action.payload,
      };
    /* Pyramid structure logic CountryId > Sector > Industry */
    case SELECT_COUNTRY_IDS:
      return {
        ...state,
        ...handleSelectedCountry(
          state.selectedCountries,
          action.payload,
          state.sectorData || [],
          state.sectors
        ),
      };
    case SELECT_SECTORS:
      return {
        ...state,
        ...handleSelectedSector(state.sectors, action.payload),
      };
    case SELECT_INDUSTRIES:
      return {
        ...state,
        ...handleSelectedIndustry(
          state.sectors,
          action.payload.sector,
          action.payload.industry
        ),
      };

    /* FETCH INDUSTRY COMPANIES */
    case FETCH_INDUSTRY_COMPANIES:
      return {
        ...state,
        industryCompaniesLoading: true,
      };
    case FETCH_INDUSTRY_COMPANIES_SUCCESS:
      return {
        ...state,
        industryCompaniesLoading: false,
        industryCompaniesNumber: action.payload,
      };
    case FETCH_INDUSTRY_COMPANIES_FAIL:
      return {
        ...state,
        industryCompaniesLoading: false,
        error: action.payload,
      };

    /* SETUP_NOT_ACTIVE_LICENCE_COUNTRIES */
    case SETUP_NOT_ACTIVE_LICENCE_COUNTRIES:
      return {
        ...state,
        selectedCountries: state.selectedCountries
          .map((e: ISecIndCty) => ({
            ...e,
            disabled:
              !FREEPREMIUM_SCREENER_COUNTRIES.includes(e.label) &&
              e.label !== 'All Countries',
            selected: FREEPREMIUM_SCREENER_COUNTRIES.includes(e.label),
          }))
          .sort((a: ISecIndCty, b: ISecIndCty) => {
            if (a.label === 'All Countries') {
              return -1;
            } else if (b.label === 'All Countries') {
              return 1;
            } else if (
              FREEPREMIUM_SCREENER_COUNTRIES.includes(a.label) &&
              !FREEPREMIUM_SCREENER_COUNTRIES.includes(b.label)
            ) {
              return -1;
            } else if (
              !FREEPREMIUM_SCREENER_COUNTRIES.includes(a.label) &&
              FREEPREMIUM_SCREENER_COUNTRIES.includes(b.label)
            ) {
              return 1;
            } else {
              return a.label.localeCompare(b.label);
            }
          }),
      };

    /***************************************
     *              Peer Group             *
     ***************************************/
    case SET_SCREENER_PEER_GROUP:
      return {
        ...state,
        peerGroup: action.payload,
      };

    default:
      return state;
  }
};

export default screenerStructureReducer;
