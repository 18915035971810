import {
  ITableColumnData,
  ScreenerFinancialStrengthFilterCardEnum,
  ScreenerIncomeStatementFilterCardEnum,
  ScreenerItemEnum,
  ScreenerLabelEnum,
  ScreenerManagementEffectivenessFilterCardEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';

export const SCREENER_COLUMNS: ITableColumnData[] = [
  {
    id: 'tickerId',
    filterBlock: 'tickerId',
    label: ScreenerLabelEnum.TICKER_ID,
    item: ScreenerItemEnum.TICKER_ID,
    isDefault: true,
    notRemovable: true,
    align: 'left',
  },
  {
    id: 'companyName',
    filterBlock: 'companyName',
    label: ScreenerLabelEnum.COMPANY_NAME,
    item: ScreenerItemEnum.COMPANY_NAME,
    isDefault: true,
    align: 'left',
    minWidth: 12,
  },
  {
    id: 'sector',
    filterBlock: 'sector',
    label: ScreenerLabelEnum.SECTOR,
    item: ScreenerItemEnum.SECTOR,
    isDefault: true,
    align: 'left',
    minWidth: 15,
  },
  {
    id: 'industry',
    filterBlock: 'industry',
    label: ScreenerLabelEnum.INDUSTRY,
    item: ScreenerItemEnum.INDUSTRY,
    isDefault: true,
    align: 'left',
    minWidth: 15,
  },
  {
    id: 'countryName',
    filterBlock: 'countryName',
    label: ScreenerLabelEnum.COUNTRY,
    item: ScreenerItemEnum.COUNTRY,
    align: 'left',
  },
  {
    id: 'dividendTTM',
    filterBlock: ScreenerStockFilterCardEnum.DIVIDEND,
    label: ScreenerLabelEnum.DIVIDEND_TTM,
    item: ScreenerItemEnum.DIVIDEND_TTM,
    isCurrency: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'dividendYield',
    filterBlock: ScreenerStockFilterCardEnum.DIVIDEND_YIELD,
    label: ScreenerLabelEnum.DIV_YIELD,
    item: ScreenerItemEnum.DIV_YIELD,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'closeadj',
    filterBlock: ScreenerStockFilterCardEnum.PRICE,
    label: ScreenerLabelEnum.PRICE,
    item: ScreenerItemEnum.PRICE,
    isCurrency: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'pE',
    filterBlock: ScreenerStockFilterCardEnum.PRICE_EARNINGS,
    label: ScreenerLabelEnum.PE,
    item: ScreenerItemEnum.PE,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'marketCap',
    filterBlock: ScreenerStockFilterCardEnum.MARKET_CAP,
    label: ScreenerLabelEnum.MARKET_CAP,
    item: ScreenerItemEnum.MARKET_CAP,
    isCurrency: true,
    decimals: 0,
    align: 'right',
  },
  {
    id: 'dividend',
    filterBlock: ScreenerStockFilterCardEnum.DIVIDEND,
    label: ScreenerLabelEnum.DIVIDEND,
    item: ScreenerItemEnum.DIVIDEND,
    isCurrency: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'revenue',
    filterBlock: 'revenue',
    label: ScreenerLabelEnum.REVENUE,
    item: ScreenerItemEnum.REVENUE,
    isCurrency: true,
    decimals: 0,
    align: 'right',
    minWidth: 10,
  },
  {
    id: 'netIncome',
    filterBlock: 'netIncome',
    label: ScreenerLabelEnum.NET_INCOME,
    item: ScreenerItemEnum.NET_INCOME,
    isCurrency: true,
    decimals: 0,
    align: 'right',
  },
  {
    id: 'totalAssets',
    filterBlock: 'totalAssets',
    label: ScreenerLabelEnum.ASSETS,
    item: ScreenerItemEnum.ASSETS,
    isCurrency: true,
    decimals: 0,
    align: 'right',
    minWidth: 11,
  },
  {
    id: 'totalLiabilities',
    filterBlock: 'totalLiabilities',
    label: ScreenerLabelEnum.LIABILITIES,
    item: ScreenerItemEnum.LIABILITIES,
    isCurrency: true,
    decimals: 0,
    align: 'right',
  },
  {
    id: 'totalEquity',
    filterBlock: 'totalEquity',
    label: ScreenerLabelEnum.EQUITY,
    item: ScreenerItemEnum.EQUITY,
    isCurrency: true,
    decimals: 0,
    align: 'right',
  },
  {
    id: 'lastYear',
    filterBlock: 'lastYear',
    label: ScreenerLabelEnum.YEAR,
    item: ScreenerItemEnum.YEAR,
    decimals: 0,
    align: 'right',
    isDefault: true,
  },
  {
    id: 'profitMargin',
    filterBlock: ScreenerIncomeStatementFilterCardEnum.PROFIT_MARGIN,
    label: ScreenerLabelEnum.PROFIT_MARGIN,
    item: ScreenerItemEnum.PROFIT_MARGIN,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'grossMargin',
    filterBlock: ScreenerIncomeStatementFilterCardEnum.GROSS_MARGIN,
    label: ScreenerLabelEnum.GROSS_MARGIN,
    item: ScreenerItemEnum.GROSS_MARGIN,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'opInc',
    filterBlock: ScreenerIncomeStatementFilterCardEnum.OP_INC,
    label: ScreenerLabelEnum.OP_INC,
    item: ScreenerItemEnum.OP_INC,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'rOS',
    filterBlock: ScreenerManagementEffectivenessFilterCardEnum.ROS,
    label: ScreenerLabelEnum.ROS,
    item: ScreenerItemEnum.ROS,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'rOEadj',
    filterBlock: ScreenerManagementEffectivenessFilterCardEnum.ROE,
    label: ScreenerLabelEnum.ROE,
    item: ScreenerItemEnum.ROE,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'rOAadj',
    filterBlock: ScreenerManagementEffectivenessFilterCardEnum.ROA,
    label: ScreenerLabelEnum.ROA,
    item: ScreenerItemEnum.ROA,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'rOIC',
    filterBlock: ScreenerManagementEffectivenessFilterCardEnum.ROIC,
    label: ScreenerLabelEnum.ROIC,
    item: ScreenerItemEnum.ROIC,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'currentRatio',
    filterBlock: ScreenerFinancialStrengthFilterCardEnum.CURRENT_RATIO,
    label: ScreenerLabelEnum.CURRENT_RATIO,
    item: ScreenerItemEnum.CURRENT_RATIO,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'quickRatio',
    filterBlock: ScreenerFinancialStrengthFilterCardEnum.QUICK_RATIO,
    label: ScreenerLabelEnum.QUICK_RATIO,
    item: ScreenerItemEnum.QUICK_RATIO,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'dEadj',
    filterBlock: 'de',
    label: ScreenerLabelEnum.DE,
    item: ScreenerItemEnum.DE,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'debtPaybackPeriod',
    filterBlock: ScreenerFinancialStrengthFilterCardEnum.DEBT_PAYBACK,
    label: ScreenerLabelEnum.DEBT_PAYBACK,
    item: ScreenerItemEnum.DEBT_PAYBACK,
    decimals: 0,
    align: 'right',
  },
  {
    id: 'payoutRatio',
    filterBlock: ScreenerStockFilterCardEnum.PAYOUT_RATIO,
    label: ScreenerLabelEnum.PAYOUT,
    item: ScreenerItemEnum.PAYOUT,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
  {
    id: 'capExToNetIncome',
    filterBlock: 'capExToNetIncome',
    label: ScreenerLabelEnum.CAPEX,
    item: ScreenerItemEnum.CAPEX,
    isPercent: true,
    decimals: 2,
    align: 'right',
  },
];
