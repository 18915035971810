import { IconShapeEnum, JSON_POST_LIMIT_MB } from '@prometeus/common';
import { AxiosResponse } from 'axios';
// import queryString from 'query-string';
import { DumpType } from '../models/general.model';

export const isNumeric = (str: string) => {
  return !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

export const hashifyString = (s: string): number => {
  if (!(typeof s === 'string' || (s as any) instanceof String)) {
    return -1;
  }
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    // tslint:disable-next-line: no-bitwise
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }

  return h;
};

export const setLocalStorage = (
  value: any,
  label: string,
  stringify?: boolean
) => {
  try {
    localStorage.setItem(
      label,
      !!stringify ? JSON.stringify(value || {}) : value
    );
  } catch (err: any) {
    console.error(err.message);
  }
};

export const isInRange = (value: number, max: number, min: number): boolean =>
  value >= min && value <= max;

export const booleanArrToDecimal = (arr: boolean[]) =>
  arr.reduce((r: number, v: boolean) => r * 2 + (v ? 1 : 0), 0);

export const fisherYatesShuffle = <T>(array: T[]): T[] => {
  let currentIndex: number = array.length;
  let randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

/* Admin */
export const exportDump = (dump: DumpType, key: string): void => {
  const fileName = `prometeus-${key}-dump`;
  const json = JSON.stringify(dump);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

// Function used to upload a JSON dump to the database; is splits the JSON file in chunks of maximum 5MB and send one chunk after the other to the backend
export const makeHandleLoad =
  (
    service: (chunk: any, flag?: boolean) => Promise<AxiosResponse>,
    setProgress: (progress: number) => void,
    setOpenDialog: (open: boolean) => void,
    hasIncrement?: boolean
  ) =>
    (loadedFile: any, flag: boolean) => {
      setProgress(1);
      const jsonObject = JSON.parse(loadedFile) as any[];

      const unregularChunks: any[][] = [[]];
      let current: number = 0;
      let index: number = 0;
      const MAX_OBJECT_SIZE = (JSON_POST_LIMIT_MB - 0.3) * 1_000_000;
      jsonObject.forEach((object: any) => {
        // const objectSize: number = sizeof(object);
        const stringSize = JSON.stringify(object).length;
        const currentSize = current + stringSize;
        if (currentSize < MAX_OBJECT_SIZE) {
          current = currentSize;
          unregularChunks[index] = [...unregularChunks[index], object];
        } else {
          current = stringSize;
          index += 1;
          unregularChunks.push([object]);
        }
      });

      try {
        let thenIndex = 0;

        // "Recursive" function --> allows to send a request after the other, waiting for each response
        const thenHandler = () => {
          thenIndex += 1;
          setProgress(Math.floor((thenIndex / unregularChunks.length) * 100));

          if (thenIndex < unregularChunks.length) {
            service(unregularChunks[thenIndex], hasIncrement ? flag : false)
              .then(thenHandler)
              .catch((err) => {
                throw new Error(err as any);
              });
          } else {
            setProgress(0);
            setOpenDialog(false);
          }
        };

        // `flag` is needed to be used only in the first request --> because if false, it would remove all the data sent by previous request in the current load
        service(unregularChunks[0], hasIncrement ? flag : !flag).then(
          thenHandler
        );
      } catch (err) {
        console.error(err);
        setProgress(0);
        setOpenDialog(false);
      }
    };

export const chunkArray = (arr: any[], chunkSize: number): any[][] => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }

  return chunks;
};

export const getIconShapeStyle = (shape?: IconShapeEnum) => {
  switch (shape) {
    case IconShapeEnum.ROUNDED:
      return { borderRadius: '50%' };
    case IconShapeEnum.SQUARED:
      return { borderRadius: '15%' };
    case IconShapeEnum.ORIGINAL:
    default:
      return {};
  }
};
