import {
  ILoggedUserData,
  ITokenIsAdmin,
  Token,
  isExpired,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { ClientJS } from 'clientjs';
import { Dispatch } from 'redux';
import { setLocalStorage } from '../../constants/utils.constants';
import {
  GET_LOGGED_USER_DATA,
  GET_LOGGED_USER_DATA_FAIL,
  GET_LOGGED_USER_DATA_SUCCESS,
  ISignInSuccess,
  ISignInSuccessPayload,
  LocalStorageEnum,
  RENEW_TOKEN,
  RENEW_TOKEN_FAIL,
  RenewTokenDispachTypes,
  SET_HAS_ACTIVE_LICENCE,
  SET_HAS_RATED,
  SET_IS_ADMIN,
  SET_IS_FIRST_LOGIN,
  SIGN_IN,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SignInDispachTypes,
} from '../../models/authentication.model';
import { SET_PEER_GROUP, SET_TOKEN_FILTERS } from '../../models/filters.model';
import { PeerGroupEnum } from '../../models/peer-group.model';
import {
  devSignInService,
  getDummyTokenService,
  getLoggedUserDataService,
  googleSignInService,
  microsoftSignInService,
  renewTokenService,
} from '../../services/authentication.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';
import { setPeerGroup } from './filters.action';
import { setUserInformation } from './user.action';

export const updateAccessToken = (
  dispatch: Dispatch,
  accessToken: Token,
  isAdmin: boolean,
  hasActiveLicence: boolean | string
) => {
  setLocalStorage({ accessToken }, LocalStorageEnum.TOKEN, true);

  dispatch({
    type: SIGN_IN_SUCCESS,
    payload: {
      accessToken,
      isAdmin,
      hasActiveLicence,
    },
  });

  if (hasActiveLicence) {
    dispatch({
      type: SET_PEER_GROUP,
      payload: PeerGroupEnum.SUGGESTED,
    });
  }
};

const signInSuccess = (
  dispatch: Dispatch<any>,
  payload: ISignInSuccessPayload,
  renew?: boolean
) => {
  const {
    accessToken,
    isAdmin,
    hasActiveLicence,
    userInformation,
    isFirstLogin,
    hasRated,
  } = payload;

  // Store token in local storage and update it in state
  updateAccessToken(dispatch, accessToken, isAdmin, !!hasActiveLicence);

  // Update user information
  if (!!userInformation) {
    dispatch(setUserInformation(userInformation));
  }

  // If first login, show the registration dialog
  if (!!isFirstLogin) {
    dispatch(setIsFirstLogin(true));
  }

  // Dispatch hasRated
  dispatch(setHasRated(!!hasRated));

  // If it's a sign-in (no renew token), reload the filters
  if (!renew) {
    dispatch({
      type: SET_TOKEN_FILTERS,
      asyncDispatch: dispatch as any,
    });
  }
};

export const devSignIn =
  (devType: 'user' | 'admin') =>
    async (dispatch: Dispatch<SignInDispachTypes>) => {
      actionTryCatchWrapper(dispatch, SIGN_IN_FAIL, async () => {
        dispatch({
          type: SIGN_IN,
        });

        const response: AxiosResponse<ISignInSuccessPayload> =
          await devSignInService(devType);

        // Handle token data
        if (!!response?.data?.accessToken) {
          // const tokenData: ITokenIsAdmin = { ...response?.data.accessToken };
          signInSuccess(dispatch, response?.data);
        } else {
          throw new Error('Login Failed');
        }
      });
    };

export const googleSignIn =
  (tokenId: string, redirectUri: string) =>
    async (dispatch: Dispatch<SignInDispachTypes>) => {
      actionTryCatchWrapper(dispatch, SIGN_IN_FAIL, async () => {
        dispatch({
          type: SIGN_IN,
        });

        const response: AxiosResponse<ISignInSuccessPayload> =
          await googleSignInService(tokenId, redirectUri);

        // Handle token data
        if (!!response?.data?.accessToken) {
          // const tokenData: ITokenIsAdmin = { ...response?.data };
          signInSuccess(dispatch, response?.data);
        } else {
          throw new Error('Login Failed');
        }
      });
    };

export const microsoftSignIn =
  (tokenId: string) => async (dispatch: Dispatch<SignInDispachTypes>) => {
    actionTryCatchWrapper(dispatch, SIGN_IN_FAIL, async () => {
      dispatch({
        type: SIGN_IN,
      });

      const response: AxiosResponse<ISignInSuccessPayload> =
        await microsoftSignInService(tokenId);

      // Handle token data
      if (!!response?.data?.accessToken) {
        // const tokenData: ITokenIsAdmin = { ...response?.data };
        signInSuccess(dispatch, response?.data);
      } else {
        throw new Error('Login Failed');
      }
    });
  };

export const signOut = () => {
  localStorage.removeItem(LocalStorageEnum.TOKEN);
  return { type: SIGN_OUT };
};

export const renewToken =
  () => (dispatch: Dispatch<RenewTokenDispachTypes | ISignInSuccess>) => {
    actionTryCatchWrapper(dispatch, RENEW_TOKEN_FAIL, async () => {
      dispatch({
        type: RENEW_TOKEN,
      });

      const response: AxiosResponse<ITokenIsAdmin> = await renewTokenService();

      // Handle token data
      const tokenData: ITokenIsAdmin = { ...response?.data };

      signInSuccess(dispatch, tokenData as ISignInSuccessPayload, true);
    });
  };

export const getDummyToken =
  () => async (dispatch: Dispatch<SignInDispachTypes>) => {
    actionTryCatchWrapper(dispatch, SIGN_IN_FAIL, async () => {
      const tokens: ITokenIsAdmin = JSON.parse(
        localStorage.getItem(LocalStorageEnum.TOKEN) as string
      );

      // Request a dummy token only if there's no token or it expired
      if (
        !tokens?.accessToken ||
        isExpired(tokens?.accessToken?.expirationDate)
      ) {
        dispatch({
          type: SIGN_IN,
        });

        const clientJs = new ClientJS();
        const fingerprint = clientJs.getFingerprint();

        const response: AxiosResponse<ISignInSuccessPayload> =
          await getDummyTokenService(fingerprint);

        // Handle token data
        if (!!response?.data?.accessToken) {
          const tokenData: ISignInSuccessPayload = { ...response?.data };
          signInSuccess(dispatch, tokenData);
        } else {
          throw new Error('Login Failed');
        }
      }
    });
  };

export const setIsFirstLogin = (payload: boolean) => ({
  type: SET_IS_FIRST_LOGIN,
  payload: payload,
});

/*  */
export const setHasRated = (value: boolean) => {
  return {
    type: SET_HAS_RATED,
    payload: value,
  };
};

/*  */
export const setHasActiveLicence = (value: boolean) => {
  return {
    type: SET_HAS_ACTIVE_LICENCE,
    payload: value,
  };
};

export const getLoggedUserData = () => (dispatch: Dispatch<any>) => {
  actionTryCatchWrapper(dispatch, GET_LOGGED_USER_DATA_FAIL, async () => {
    dispatch({
      type: GET_LOGGED_USER_DATA,
    });

    const response: AxiosResponse<ILoggedUserData> =
      await getLoggedUserDataService();

    dispatch({
      type: GET_LOGGED_USER_DATA_SUCCESS,
    });

    // Handle token data
    const loggedUserData: ILoggedUserData = { ...response?.data };
    const { hasActiveLicence, hasRated, isAdmin, userInformation } =
      loggedUserData;

    if (hasActiveLicence) {
      dispatch(setPeerGroup(PeerGroupEnum.SUGGESTED));
    }

    dispatch(setHasActiveLicence(!!hasActiveLicence));
    dispatch(setHasRated(!!hasRated));
    if (!!userInformation) {
      dispatch(setUserInformation(userInformation));
    }
    dispatch({
      type: SET_IS_ADMIN,
      payload: !!isAdmin,
    });
  });
};
