import {
  Box,
  Palette,
  Slider,
  sliderClasses,
  SxProps,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material';
import { isNullUndefined } from '@prometeus/common';
import { useEffect, useState } from 'react';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { pxToRem } from '../../../ui/functions';
import IncrementalComponent from './incremental.component';

export type BetweenValuesType = [number | null, number | null];

type Props = {
  title?: string;
  min: number;
  minValue: number;
  setMinValue: (value: number) => void;
  max: number;
  maxValue: number;
  setMaxValue: (value: number) => void;
  setValues: (values: BetweenValuesType) => void;
  typographyVariant?: TypographyVariant;
  step?: number;
  containerSx?: SxProps;
  isPercent?: boolean;
  isSmall?: boolean;
  largeInputWidth?: boolean;
  disabled?: boolean;
};

export const styles = {
  slider: {
    margin: '0rem 2rem',
  },
  valueTooltip: {
    fontSize: '0.8rem !important',
    margin: `0.5rem 0`,
    padding: `${pxToRem(4)} ${pxToRem(8)}`,
    maxWidth: pxToRem(300),
    borderRadius: pxToRem(4),
  },
  popper: {
    zIndex: 0,
    '& .MuiTooltip-tooltip': {
      fontSize: '0.8rem !important',
    },
  },
};

export const getStyles = (palette: Palette) => ({
  rowForm: {
    display: 'flex',
    // marginTop: '0.5rem',
    justifyContent: 'space-between',
    color: palette.text.primary,
    '& > *': {
      flex: '1 1 0',
    },
  },
  container: {
    padding: '0rem 2rem 2rem',
  },
  typography: {
    marginBottom: '0.5rem',
    marginTop: '0.1rem',
  },
  smallTypography: {
    marginBottom: '0rem',
  },
  sliderRoot: {
    width: '95%',
    marginLeft: '2.5%',
    padding: '0.9rem 0rem !important',
  },
  disabled: {
    opacity: 0.5,
    '& *': {
      cursor: 'default !important',
    },
  },
});

const BetweenSliderComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const { minValue, maxValue, min, max, isSmall, title, largeInputWidth } =
    props;
  // const inputWidth = !!title ? (!!isSmall ? '2.6rem' : '3rem') : '5.5rem';
  const inputWidth = !!largeInputWidth ? '5.5rem' : '3rem';

  const [internalValue, setInternalValue] = useState<(null | string)[]>([
    null,
    null,
  ]);

  const setValue = (value: string | null): number | null =>
    !!value ? +(value as string) : null;

  // On start
  useEffect(() => {
    setInternalValue([
      isNullUndefined(minValue) ? null : `${minValue}`,
      isNullUndefined(maxValue) ? null : `${maxValue}`,
    ]);
  }, [minValue, maxValue]);

  const onChangeMinValue = (value: string) => {
    if (!props.disabled) {
      const newValue = [
        `${setValue(value.replace(/[^0-9]/g, ''))}`,
        internalValue[1],
      ];
      setInternalValue(newValue);
      props.setValues(
        newValue.map((e: string | null) =>
          e === null ? null : +e
        ) as BetweenValuesType
      );
    }
  };

  const onChangeMaxValue = (value: string) => {
    if (!props.disabled) {
      const newValue = [
        internalValue[0],
        `${setValue(value.replace(/[^0-9]/g, ''))}`,
      ];
      setInternalValue(newValue);
      props.setValues(
        newValue.map((e: string | null) =>
          e === null ? null : +e
        ) as BetweenValuesType
      );
    }
  };

  const onChangeSliderHandler = (event: Event, _value: number | number[]) => {
    if (!props.disabled) {
      const value = _value as number[];
      if (!!value && value.length > 1) {
        const minValue =
          internalValue[0] === null && value[0] === props.min
            ? null
            : `${value[0]}`;
        const maxValue =
          internalValue[1] === null && value[1] === props.max
            ? null
            : `${value[1]}`;

        setInternalValue([minValue, maxValue]);
      }
    }
  };

  const onChangeCommittedHandler = () => {
    if (!props.disabled) {
      props.setValues([setValue(internalValue[0]), setValue(internalValue[1])]);
    }
  };

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <Box
      sx={{
        ...(props.containerSx || styles.container),
        ...(!!props.disabled ? styles.disabled : {}),
      }}
    >
      <Typography
        variant={props.typographyVariant || 'subtitle1'}
        align="left"
        sx={{
          ...styles.typography,
          ...styles.smallTypography,
          ...(isPortraitMobile
            ? {
                marginBottom: '2rem',
                fontSize: '2.5rem',
              }
            : {}),
        }}
      >
        {title}
      </Typography>
      <Slider
        sx={{
          ...styles.sliderRoot,
          [`& .${sliderClasses.valueLabel}`]: {
            fontSize: isPortraitMobile ? '2rem' : '0.8rem',
          },
        }}
        onChange={onChangeSliderHandler}
        onChangeCommitted={onChangeCommittedHandler}
        step={props.step || 1}
        valueLabelDisplay="auto"
        // marks
        min={props.min}
        max={props.max}
        value={[
          isNullUndefined(internalValue[0])
            ? props.min
            : +(internalValue[0] as string),
          isNullUndefined(internalValue[1])
            ? props.max
            : +(internalValue[1] as string),
        ]}
      />
      <Box sx={styles.rowForm}>
        <IncrementalComponent
          boxLabel={!!props.title ? '' : 'Min'}
          min={min}
          max={
            isNullUndefined(internalValue[1])
              ? max
              : +(internalValue[1] as string)
          }
          step={props.step || 1}
          mask="min"
          value={internalValue[0]}
          setValue={onChangeMinValue}
          onBlurChange={(value: string) => {
            props.setMinValue(+value);
          }}
          inputWidth={inputWidth}
          isSmall={!!isSmall}
          isPercent={props.isPercent}
        />
        <IncrementalComponent
          boxLabel={!!props.title ? '' : 'Max'}
          min={
            isNullUndefined(internalValue[0])
              ? min
              : +(internalValue[0] as string)
          }
          max={max}
          step={props.step || 1}
          value={internalValue[1]}
          mask="max"
          setValue={onChangeMaxValue}
          onBlurChange={(value: string) => {
            props.setMaxValue(+value);
          }}
          inputWidth={inputWidth}
          isSmall={!!isSmall}
          rightEnd={true}
          isPercent={props.isPercent}
        />
      </Box>
    </Box>
  );
};

export default BetweenSliderComponent;
