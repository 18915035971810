import { Business } from '@mui/icons-material';
import { Chip, chipClasses, Palette, useTheme } from '@mui/material';
import { maskBorderShadow, pxToRem } from '../ui/functions';

type Props = {
  value: number | string;
};

const getStyles = (palette: Palette) => ({
  companyChip: {
    flex: '1 1 0',
    height: '2.5rem',
    fontSize: '1.1rem',
    borderRadius: '2rem',
    color: palette.text.primary,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1.6rem',
    // marginBottom: '1rem',
    ...maskBorderShadow(palette),
  },
  companyChipLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '0.8rem',
    // marginTop: '0.3rem',
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
    '& div:last-child': {
      fontSize: '1.4rem',
    },
  },
  companyChipIcon: {
    //  color: colors.icons,
    marginLeft: pxToRem(5),
    marginRight: pxToRem(-6),
    marginBottom: '0.2rem',
    transform: 'scale(1.4)',
  },
});

const CompaniesChipComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);
  return (
    <Chip
      sx={{
        ...styles.companyChip,
        [`& .${chipClasses.label}`]: styles.companyChipLabel,
        [`& .${chipClasses.icon}`]: styles.companyChipIcon,
      }}
      icon={<Business />}
      label={
        <>
          <div>Companies</div>
          <div>{props.value}</div>
        </>
      }
    />
  );
};

export default CompaniesChipComponent;
