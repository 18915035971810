import {
  IconButton,
  IconButtonProps,
  SxProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import React from 'react';
import {
  TOOLTIP_ENTER_DELAY,
  TOOLTIP_FONT_SIZE,
} from '../constants/general.constants';
import { COMMON_COLORS } from '../ui/colors';
import { pxToRem } from '../ui/functions';

export type TooltipIconButtonProps = {
  icon: React.ReactNode;
  tooltip: string;
  onClick?: (event?: any) => void;
  IconButtonProps?: IconButtonProps & { href?: string; component?: string };
  iconButtonStyle?: SxProps;
  TooltipProps?: TooltipProps;
  tooltipStyle?: SxProps;
  disableRipple?: boolean;
  disabled?: boolean;
};

const TooltipIconButton = (props: TooltipIconButtonProps) => {
  const iconButton = (
    <IconButton
      color="inherit"
      edge="end"
      disableRipple={!!props.disableRipple}
      sx={{
        ...styles.iconButton,
        ...props.iconButtonStyle,
      }}
      onClick={props.onClick}
      {...props.IconButtonProps}
    >
      {props.icon}
    </IconButton>
  );
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            ...styles.tooltip,
            ...props.tooltipStyle,
          },
        },
      }}
      title={props.tooltip}
      enterDelay={TOOLTIP_ENTER_DELAY}
      {...props.TooltipProps}
    >
      {!!props.IconButtonProps?.disabled ? (
        <span>{iconButton}</span>
      ) : (
        iconButton
      )}
    </Tooltip>
  );
};

export default TooltipIconButton;

const styles = {
  iconButton: {
    margin: '0rem 0.05rem 0rem 0rem',
    minWidth: 0,
    fontSize: '1rem',
    padding: pxToRem(12),

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
    '& svg': {
      fontSize: '1.8rem',
    },

    '&.Mui-disabled': {
      color: `${COMMON_COLORS.disabledButton} !important`,
    },
  },
  tooltip: {
    fontSize: TOOLTIP_FONT_SIZE,
    padding: `${pxToRem(4)} ${pxToRem(8)}`,
    margin: `${pxToRem(24)} ${pxToRem(0)}`,
    borderRadius: pxToRem(4),
    maxWidth: pxToRem(300),
  },
};
