export const JSON_POST_LIMIT_MB = 15;
export const PYTHON_ANYWHERE_URL = 'https://prometeus.eu.pythonanywhere.com/'; //'http://127.0.0.1:5005/';
export const MOMENT_FORMAT = 'DD/MM/yyyy';
export const MOMENT_FORMAT_TIMESTAMP = `${MOMENT_FORMAT} HH:mm:ss`;
export const WINSTON_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS (Z)'; // 2022-01-25 03:23:10.350 PM

export const FREE_LICENCE_TICKERS = [
  // 'AAPL.US', // Apple
  // 'MSFT.US', // Microsoft
  // 'RACE.MI', // Ferrari
  // '7203.TSE', // Toyota Motor Corportation
  // '005940.KO', // Samsung,
  // 'OR.PA', // L'Oreal
  // 'GMG.AU', // GOODMAN GROUP
];
export const IS_FREE_LICENCE_TICKER = (tickerId: string): boolean =>
  !FREE_LICENCE_TICKERS.length || FREE_LICENCE_TICKERS.includes(tickerId);

export const FREE_TRIAL_DURATION_DAYS = 30;

export const FEEDBACK_MAX_CHARACTERS = 2000;
