import { AttachMoney, Group, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Divider,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Palette,
  Switch,
  alpha,
  listItemClasses,
  useTheme,
} from '@mui/material';
import {
  ICompanyMinimal,
  isNullUndefined,
  toTitleCase,
} from '@prometeus/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import {
  MAX_FISCAL_YEAR,
  MIN_FISCAL_YEAR,
  TOOLTIP_FONT_SIZE,
} from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import {
  DWMQFilter,
  PageIdEnum,
  QYFilter,
  TimeframeEnum,
} from '../../../models/filters.model';
import { IKeyValue } from '../../../models/other.model';
import { PeerGroupEnum } from '../../../models/peer-group.model';
import {
  setDWMQFilter,
  setFiscalYear,
  setPeerGroup,
  setPeers,
  setQYFilter,
  setUseDollars,
} from '../../../store/actions/filters.action';
import { getIcons } from '../../../store/actions/icons.action';
import { openTableDialog } from '../../../store/actions/modals.action';
import {
  getCompaniesInternational,
  getCompaniesRegional,
} from '../../../store/actions/peer-group.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { getFormStyles } from '../../../styles/form.styles';
import { iconStyles } from '../../../styles/icons.styles';
import { COMMON_COLORS } from '../../../ui/colors';
import { maskBorderShadow, pxToRem } from '../../../ui/functions';
import CustomSelectComponent from '../../select.component';
import TextField from '../../text-field.component';
import TooltipIconButton from '../../tooltip-icon-button.component';
import ButtonGroupComponent from '../filter-components/button-group.component';
import FilterTitleComponent from '../filter-components/filter-title.component';
import PeerMenuItemComponent from '../filter-components/peer-menu-item.component';
import FiltersCardComponent from '../filters-card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../screener/between.component';

const DWMQY: IKeyValue<string>[] = [
  { key: 'Day', value: TimeframeEnum.DAILY },
  { key: 'Week', value: TimeframeEnum.WEEKLY },
  { key: 'Month', value: TimeframeEnum.MONTHLY },
  { key: 'Quarter', value: TimeframeEnum.QUARTERLY },
  { key: 'Year', value: TimeframeEnum.YEARLY },
];

const B_PAGES = new Set([
  PageIdEnum.COMPANY_SUMMARY,
  PageIdEnum.COMPANY_STOCKS,
  PageIdEnum.COMPANY_DIVIDENDS,
]);

const PEER_GROUP_OPTIONS: string[] = [
  toTitleCase(PeerGroupEnum.REGIONAL),
  toTitleCase(PeerGroupEnum.INTERNATIONAL),
  toTitleCase(PeerGroupEnum.SUGGESTED),
  toTitleCase(PeerGroupEnum.CUSTOM),
];

export const useAggFiltersStyles = () => {
  const theme = useTheme();
  const palette = theme.palette;
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return {
    tooltip: {
      fontSize: TOOLTIP_FONT_SIZE,
    },
    iconButtonRoot: {
      fontSize: '1rem',
      borderRadius: '50%',
      padding: '0.5rem',
      color: palette.text.primary,
      backgroundColor: palette.background.paper,
      '&:hover': {
        backgroundColor: `${palette.text.primary}B3`,
      },
    },
    /* Select */
    labelRoot: {
      color: palette.text.primary,
    },
    icon: {
      // color: colors.icons,
      transform: `scale(${isPortraitMobile ? 1.8 : 1})`,
    },
    typographyStyle: {
      textAlign: 'left' as 'left',
      marginTop: '1rem',
    },

    filterMargin: {
      marginBottom: '1.2rem',
    },
    divider: {
      backgroundColor: palette.divider,
    },
    listItemIconRoot: {
      // color: colors.icons,
      marginTop: '0.1rem',
      minWidth: pxToRem(40),
      '& svg': {
        fontSize: '1.8rem',
      },
    },
    fiscalYear: {
      padding: '0rem',
    },
  };
};

const getStyles = (palette: Palette) => ({
  divider: {
    marginTop: '1.5rem',
    marginBottom: '1.2rem',
    height: pxToRem(1),
  },
  fiscalYearTypography: {
    marginTop: '0.5rem',
  },
  marginBottom2: {
    marginBottom: '2rem',
  },
  flexSpaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  usdSwitch: {
    marginLeft: '1rem',
    marginTop: '0.2rem',
  },
  littleForm: {
    marginLeft: '1rem',
    flex: '1 1 0',

    '& .MuiOutlinedInput-input': {
      padding: '1.2rem 0',
    },
  },
  maskBorderShadow: {
    ...maskBorderShadow(palette),
  },
  endAdornmentIconButton: {
    color: palette.text.primary,
    padding: '0.5rem !important',
    transform: 'scale(0.8)',
  },
  endAdornmentIconButtonTooltip: {
    transform: 'scale(0.8)',
  },
  dollarItemIconRoot: {
    minWidth: 0,
    '& svg': {
      fontSize: '1.5rem',
    },
  },
  activeIconButton: {
    color: COMMON_COLORS.contrastPrimaryText,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.7),
    },
  },

  /* List Item */
  listItemText: {
    marginBottom: pxToRem(4),
    marginTop: 0,
    '& .MuiTypography-root': {
      marginTop: '0.5rem',
    },
  },
  secondaryAction: {
    paddingRight: pxToRem(48),
  },
  listItemSecondaryAction: {
    right: pxToRem(16),
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    // marginTop: '0.5rem',
    listStyle: 'none',
    '& *': {
      listStyle: 'none',
    },
    '& .MuiTypography-root': {
      marginTop: '0 !important',
    },
  },
  mobileListItemIconRoot: {
    transform: 'scale(2)',
  },

  /* Peers */
  peersContainer: {
    overflow: 'auto',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0rem',
    minHeight: '10rem',
    height: '100%',
  },
  peerGroupLabel: {
    margin: '1rem 0rem 0rem',
  },
});

const SideFiltersComponent = () => {
  const dispatch = useDispatch();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const currentCompany = useSelector(
    (state: RootState) => state.companies.currentCompany
  );
  const theme = useTheme();
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const activeDWMQFilter = useSelector(
    (state: RootState) => state.filters.activeDWMQFilter
  );
  const currentPage = useSelector(
    (state: RootState) => state.filters.currentPage
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const peerGroup = useSelector((state: RootState) => state.filters.peerGroup);

  const [isBPage, setIsBPage] = useState<boolean>(false);
  useEffect(() => {
    const value = !!currentPage && B_PAGES.has(currentPage);
    setIsBPage(value);
  }, [currentPage]);

  const styles = useAggFiltersStyles();
  const thisStyles = getStyles(theme.palette);
  const formStyles = getFormStyles(theme.palette);

  const isCurrentTimeframeFilter = (value: string): boolean =>
    (isBPage ? activeDWMQFilter : activeQYFilter) === value;

  const onClickSetDWMQYHandler = (filter: string) => {
    if (!isCurrentTimeframeFilter(filter)) {
      dispatch(
        isBPage
          ? setDWMQFilter(filter as DWMQFilter)
          : setQYFilter(filter as QYFilter)
      );
    }
  };

  /* Peer group */
  const peersLoading = useSelector(
    (state: RootState) => state.peerGroup.loading
  );
  const peers = useSelector((state: RootState) => state.peerGroup.peers);

  useEffect(() => {
    if (!!peers?.size) {
      dispatch(
        getIcons([...peers.values()].map((e: ICompanyMinimal) => e.tickerId))
      );
    }
  }, [dispatch, peers]);

  useEffect(() => {
    if (currentCompany) {
      switch (peerGroup) {
        case PeerGroupEnum.REGIONAL:
          dispatch(
            getCompaniesRegional(
              currentCompany.countryId,
              currentCompany.industry
            )
          );
          break;
        case PeerGroupEnum.INTERNATIONAL:
          dispatch(getCompaniesInternational(currentCompany.industry));
          break;
      }
    }
  }, [dispatch, peerGroup, currentCompany]);

  const filterPeers = (peer: ICompanyMinimal) =>
    ![currentCompany?.tickerId, currentCompany?.companyId].includes(
      peer.tickerId
    );

  const getPeersValue = () => {
    const peersValue = [...peers.values()].filter(filterPeers).length;
    return isNullUndefined(peersValue) ? '-' : peersValue < 0 ? 0 : peersValue;
  };

  const onClickPeerGroupMenuItemHandler = (_value: string) => {
    const value = _value.toLowerCase() as PeerGroupEnum;
    dispatch(setPeerGroup(value));
    if (value === PeerGroupEnum.CUSTOM) {
      dispatch(setPeers(Array.from(peers?.keys())));
    }
  };

  const onClickSetDialogOpenHandler = () => {
    dispatch(openTableDialog('peers'));
  };

  /* Other */
  const onClickDollarsHandler = () => {
    dispatch(setUseDollars(!useDollars));
  };

  /***************************************
   *    Fiscal Year Component Handlers   *
   ***************************************/
  const [internalFiscalYear, setInternalFiscalYear] = useState<
    [number, number]
  >([0, 0]);

  useEffect(() => {
    setInternalFiscalYear(fiscalYear);
  }, [fiscalYear]);

  const onChangeSlideTWHandler = (event: any, newRange: any) => {
    if (Array.isArray(newRange)) {
      if (
        `${newRange[0]}`.length === 4 &&
        `${newRange[1]}`.length === 4 &&
        newRange[1] >= newRange[0]
      ) {
        setInternalFiscalYear(newRange as [number, number]);
        dispatch(setFiscalYear(newRange as [number, number]));
      }
    }
  };

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Divider */
  const dividerComponent = (
    <Divider
      sx={{
        ...styles.divider,
        ...thisStyles.divider,
        ...(isPortraitMobile
          ? {
              marginTop: '3rem',
              marginBottom: '3rem',
            }
          : {}),
      }}
    />
  );

  return (
    <FiltersCardComponent>
      {/***************************************
       *        Convert currency in USD      *
       ***************************************/}
      <ListItem
        component="div"
        disableGutters
        sx={{
          ...thisStyles.listItemRoot,
          [`& .${listItemClasses.secondaryAction}`]: thisStyles.secondaryAction,
          listStyle: 'none !important',
        }}
      >
        <ListItemIcon
          sx={{
            ...iconStyles.listItemIconRoot,
            ...styles.listItemIconRoot,
            ...(isPortraitMobile ? thisStyles.mobileListItemIconRoot : {}),
          }}
        >
          <AttachMoney />
        </ListItemIcon>
        <ListItemText
          primary="Convert currency in USD"
          primaryTypographyProps={{
            variant: 'h6',
            sx: {
              ...styles.typographyStyle,
              ...(isPortraitMobile ? { fontSize: '2rem' } : {}),
            },
          }}
          sx={thisStyles.listItemText}
        />
        <ListItemSecondaryAction sx={thisStyles.listItemSecondaryAction}>
          <Switch
            sx={{ ...thisStyles.usdSwitch }}
            checked={useDollars}
            onChange={onClickDollarsHandler}
            color="primary"
            name="convertUSD"
          />
        </ListItemSecondaryAction>
      </ListItem>

      {dividerComponent}

      {/***************************************
       *            Data Frequency           *
       ***************************************/}
      <Box sx={styles.filterMargin}>
        <FilterTitleComponent title="Data Frequency" />
        <ButtonGroupComponent
          data={DWMQY.filter((_, index: number) =>
            isBPage ? index < 4 : index > 2
          )}
          onClick={onClickSetDWMQYHandler}
          isButtonSelected={isCurrentTimeframeFilter}
          isDisabled={(key: string) =>
            hasActiveLicence || (isBPage && key === 'Month') || key === 'Year'
              ? false
              : true
          }
        />
      </Box>

      {dividerComponent}

      {/***************************************
       *              Peer Group             *
       ***************************************/}
      <Box sx={{ ...styles.filterMargin, ...thisStyles.marginBottom2 }}>
        <FilterTitleComponent
          title="Peer Group"
          sx={thisStyles.peerGroupLabel}
        />
        <Box sx={{ ...formStyles.rowForm, ...thisStyles.flexSpaceAround }}>
          <CustomSelectComponent
            boxLabel="Group"
            option={toTitleCase(peerGroup)}
            options={PEER_GROUP_OPTIONS}
            setOption={onClickPeerGroupMenuItemHandler}
            boxSx={{ marginTop: '0.5rem', flex: '1.8 1 0' }}
            isDisabled={(option: string) =>
              hasActiveLicence
                ? false
                : [
                    toTitleCase(PeerGroupEnum.SUGGESTED),
                    toTitleCase(PeerGroupEnum.CUSTOM),
                  ].includes(option)
            }
          />
          <TextField
            sx={{
              ...formStyles.rowForm,
              ...formStyles.innerRowForm,
              ...thisStyles.littleForm,
              fieldset: {
                border: `solid 0.05rem ${theme.palette.divider} !important`,
              },
              ...(isPortraitMobile
                ? {
                    '& .MuiFormLabel-root': {
                      fontSize: '2rem',
                      marginTop: '-0.4rem',
                    },
                    '& .MuiInputBase-root': {
                      height: '6rem',
                      fontSize: '1.8rem',
                      '& input': {
                        marginLeft: '0.9rem',
                      },
                    },
                  }
                : {}),
            }}
            id="outlined-basic"
            label="# Peers"
            variant="outlined"
            value={getPeersValue()}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {!peersLoading && peerGroup === PeerGroupEnum.CUSTOM && (
                    <TooltipIconButton
                      icon={<OpenInNew />}
                      tooltip="Manage Peers"
                      onClick={onClickSetDialogOpenHandler}
                      iconButtonStyle={thisStyles.endAdornmentIconButton}
                      IconButtonProps={{
                        component: 'span',
                      }}
                      tooltipStyle={thisStyles.endAdornmentIconButtonTooltip}
                    />
                  )}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <Group sx={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      {/* List */}
      <Box
        sx={{
          ...styles.filterMargin,
          flex: '1 1 0',
        }}
      >
        <Box
          sx={{
            ...thisStyles.peersContainer,
            ...thisStyles.maskBorderShadow,
          }}
        >
          <Virtuoso
            data={[...peers.values()].filter(filterPeers).slice(0, 10)}
            itemContent={(index, peer: ICompanyMinimal) => (
              <PeerMenuItemComponent key={peer.tickerId} peer={peer} />
            )}
          />
        </Box>
      </Box>

      {dividerComponent}

      {/***************************************
       *              Fiscal Year            *
       ***************************************/}
      <BetweenSliderComponent
        containerSx={styles.fiscalYear}
        typographyVariant={isPortraitMobile ? 'h3' : 'h6'}
        title="Fiscal Year"
        // step={}
        min={MIN_FISCAL_YEAR}
        minValue={internalFiscalYear[0]}
        setMinValue={(value: number) => {
          onChangeSlideTWHandler(null, [value, internalFiscalYear[1]]);
        }}
        max={MAX_FISCAL_YEAR}
        maxValue={internalFiscalYear[1]}
        setMaxValue={(value: number) => {
          onChangeSlideTWHandler(null, [internalFiscalYear[0], value]);
        }}
        setValues={(values: BetweenValuesType) => {
          onChangeSlideTWHandler(null, values);
        }}
        disabled={
          (isBPage ? activeDWMQFilter : activeQYFilter) === TimeframeEnum.DAILY
        }
      />
    </FiltersCardComponent>
  );
};

export default SideFiltersComponent;
