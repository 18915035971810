import { ExpandMore } from '@mui/icons-material';
import { Box, Checkbox, IconButton, Theme, useTheme } from '@mui/material';
import { FixMeLater } from '../../../constants/general.constants';
import { ISecIndCty } from '../../../models/screener/structure.model';
import { COMMON_COLORS } from '../../../ui/colors';
import { NO_CLICK_AWAY_KEY } from '../../click-away.component';

const getStyles = (theme: Theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginRight: '1rem',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  accordionContainer: {
    width: '100%',
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: theme.palette.background.default,
    paddingBottom: '0.7rem',
  },
  accordionContainerBorder: {
    borderBottomWidth: '0.05rem',
    borderBottomColor: theme.palette.divider,
  },
  marginBottomCheckbox: {
    marginBottom: '0.25rem',
  },
  accordionHeader: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    width: '100%',
    maxWidth: '100%',
    transition: 'all 0.8s ease 0s',
    cursor: 'pointer',
    paddingTop: '0.2rem',
    position: 'sticky' as 'sticky',
    top: '0rem',
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
  },
  accordionContentRow: {
    // borderTop: '0.05rem solid lightgray',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0.2rem 0.1rem 0.2rem 1rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: `${COMMON_COLORS.contrastPrimaryText} !important`,
      '& .Mui-checked': {
        color: `${COMMON_COLORS.contrastPrimaryText} !important`,
      },
      '& .MuiIconButton-root': {
        color: `${COMMON_COLORS.contrastPrimaryText} !important`,
      },
    },
  },
  marginHorizontalPointFive: {
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  alignItemsCenter: { display: 'flex', alignItems: 'center' },
  noWrapEllipsis: {
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accordionCollapsableContent: {
    backgroundColor: theme.palette.background.default,
    transition: '0.5s ease 0s',
    overflow: 'hidden',
    width: '100%',
    maxHeight: '0rem',
    opacity: 0.3,
  },
  accordionCollapsableContentExpanded: {
    maxHeight: '100rem',
  },
  accordionCollapsableContentChecked: {
    opacity: 1,
  },
  restrictedElement: {
    cursor: 'default !important',
  },
});

type Props = {
  isAll: boolean;
  isLast: boolean;
  checked: boolean;
  label: string;
  index: number;
  indeterminate: boolean;
  contentList: FixMeLater[];
  expanded: boolean;
  expandedHandler: () => void;
  sectorAction: () => void;
  industryAction: (industry: ISecIndCty) => void;
  reachedLimit?: boolean;
};

const AccordionComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const onClickCheckboxHandler = (e: any) => {
    e?.stopPropagation();
    props?.sectorAction();
  };

  const onClickExpandMoreHandler = (e: any) => {
    e?.stopPropagation();
    props?.expandedHandler();
  };

  const onClickListItemHandler = (industry: ISecIndCty) => () => {
    props.industryAction(industry);
  };

  return (
    <Box
      sx={{
        ...styles.accordionContainer,
        ...(!props.isLast ? styles.accordionContainerBorder : {}),
        ...(!!props.reachedLimit ? styles.restrictedElement : {}),
      }}
      id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-container`}
    >
      <Box
        component="span"
        sx={styles.accordionHeader}
        onClick={() => {
          if (props.isAll) {
            props.sectorAction();
          } else {
            props.expandedHandler();
          }
        }}
        id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-header`}
      >
        <Checkbox
          id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-header-checkbox`}
          color="primary"
          indeterminate={props.indeterminate}
          sx={{
            ...styles.marginHorizontalPointFive,
            ...styles.marginBottomCheckbox,
            ...(!!props.reachedLimit ? styles.restrictedElement : {}),
          }}
          checked={props.checked}
          onClick={onClickCheckboxHandler}
        />
        <Box
          component="span"
          id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-header-label`}
          className="header-label"
          sx={styles.noWrapEllipsis}
        >
          {props.label}
        </Box>
        {!props.isAll && (
          <IconButton
            id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-header-icon-button`}
            sx={{
              ...styles.expand,
              ...(props.expanded ? styles.expandOpen : {}),
              padding: '0.5rem',
            }}
            disableFocusRipple
            onClick={onClickExpandMoreHandler}
            aria-expanded={props.expanded}
            aria-label="show more"
            size="large"
          >
            <ExpandMore
              id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-header-icon-button-expand-more`}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          ...styles.accordionCollapsableContent,
          ...(props.checked ? styles.accordionCollapsableContentChecked : {}),
          ...(props.expanded ? styles.accordionCollapsableContentExpanded : {}),
          ...(!!props.reachedLimit ? styles.restrictedElement : {}),
        }}
        id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-div`}
      >
        {props.contentList
          .filter((e: ISecIndCty) => e.shown)
          .map((e: ISecIndCty) => (
            <Box
              id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-item-${e.index}`}
              key={`${e.label}-${e.index}`}
              sx={{
                ...styles.accordionContentRow,
                ...(!!props.reachedLimit ? styles.restrictedElement : {}),
              }}
              onClick={onClickListItemHandler(e)}
            >
              <Checkbox
                id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-checkbox-${e.index}`}
                color="primary"
                style={{
                  marginLeft: '1rem',
                }}
                sx={{
                  ...styles.marginHorizontalPointFive,
                  ...styles.marginBottomCheckbox,
                  ...(!!props.reachedLimit ? styles.restrictedElement : {}),
                }}
                checked={e.selected}
              />
              <Box
                component="span"
                id={`${NO_CLICK_AWAY_KEY}-${props.index}-SEC-IND-label-${e.index}`}
                sx={styles.noWrapEllipsis}
              >
                {e.label}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default AccordionComponent;
