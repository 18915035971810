export interface ILogTreeItem {
  type: string;
  path: string;
  files: string[];
  children: ILogTreeItem[];
}

export interface ILogsState {
  apiTree: ILogTreeItem[];
  apiLogContent?: string;
  backendList: ILogTreeItem[];
  backendLogContent?: string[];
  loading: boolean;
  error?: string;
}

/* GET_API_LOGS_TREE */
export const GET_API_LOGS_TREE = 'GET_API_LOGS_TREE';
export interface IGetAPILogsTree {
  type: typeof GET_API_LOGS_TREE;
}

/* GET_API_LOGS_TREE_SUCCESS */
export const GET_API_LOGS_TREE_SUCCESS = 'GET_API_LOGS_TREE_SUCCESS';
export interface IGetAPILogsTreeSuccess {
  type: typeof GET_API_LOGS_TREE_SUCCESS;
  payload: ILogTreeItem[];
}

/* GET_API_LOGS_TREE_FAIL */
export const GET_API_LOGS_TREE_FAIL = 'GET_API_LOGS_TREE_FAIL';
export interface IGetAPILogsTreeFail {
  type: typeof GET_API_LOGS_TREE_FAIL;
  payload: string;
}

export type GetAPILogsTreeDispatchTypes =
  | IGetAPILogsTree
  | IGetAPILogsTreeSuccess
  | IGetAPILogsTreeFail;

/* GET_API_LOG_CONTENT */
export const GET_API_LOG_CONTENT = 'GET_API_LOG_CONTENT';
export interface IGetAPILogContent {
  type: typeof GET_API_LOG_CONTENT;
}

/* GET_API_LOG_CONTENT_SUCCESS */
export const GET_API_LOG_CONTENT_SUCCESS = 'GET_API_LOG_CONTENT_SUCCESS';
export interface IGetAPILogContentSuccess {
  type: typeof GET_API_LOG_CONTENT_SUCCESS;
  payload: string;
}

/* GET_API_LOG_CONTENT_FAIL */
export const GET_API_LOG_CONTENT_FAIL = 'GET_API_LOG_CONTENT_FAIL';
export interface IGetAPILogContentFail {
  type: typeof GET_API_LOG_CONTENT_FAIL;
  payload: string;
}

export type GetAPILogContentDispatchTypes =
  | IGetAPILogContent
  | IGetAPILogContentSuccess
  | IGetAPILogContentFail;

/* GET_BACKEND_LOGS_LIST */
export const GET_BACKEND_LOGS_LIST = 'GET_BACKEND_LOGS_LIST';
export interface IGetBackendLogsList {
  type: typeof GET_BACKEND_LOGS_LIST;
}

/* GET_BACKEND_LOGS_LIST_SUCCESS */
export const GET_BACKEND_LOGS_LIST_SUCCESS = 'GET_BACKEND_LOGS_LIST_SUCCESS';
export interface IGetBackendLogsListSuccess {
  type: typeof GET_BACKEND_LOGS_LIST_SUCCESS;
  payload: string[];
}

/* GET_BACKEND_LOGS_LIST_FAIL */
export const GET_BACKEND_LOGS_LIST_FAIL = 'GET_BACKEND_LOGS_LIST_FAIL';
export interface IGetBackendLogsListFail {
  type: typeof GET_BACKEND_LOGS_LIST_FAIL;
  payload: string;
}

export type GetBackendLogsListDispatchTypes =
  | IGetBackendLogsList
  | IGetBackendLogsListSuccess
  | IGetBackendLogsListFail;

/* GET_BACKEND_LOG_CONTENT */
export const GET_BACKEND_LOG_CONTENT = 'GET_BACKEND_LOG_CONTENT';
export interface IGetBackendLogContent {
  type: typeof GET_BACKEND_LOG_CONTENT;
}

/* GET_BACKEND_LOG_CONTENT_SUCCESS */
export const GET_BACKEND_LOG_CONTENT_SUCCESS =
  'GET_BACKEND_LOG_CONTENT_SUCCESS';
export interface IGetBackendLogContentSuccess {
  type: typeof GET_BACKEND_LOG_CONTENT_SUCCESS;
  payload: string[];
}

/* GET_BACKEND_LOG_CONTENT_FAIL */
export const GET_BACKEND_LOG_CONTENT_FAIL = 'GET_BACKEND_LOG_CONTENT_FAIL';
export interface IGetBackendLogContentFail {
  type: typeof GET_BACKEND_LOG_CONTENT_FAIL;
  payload: string;
}

export type GetBackendLogContentDispatchTypes =
  | IGetBackendLogContent
  | IGetBackendLogContentSuccess
  | IGetBackendLogContentFail;

export type DispatchActionTypes =
  | GetAPILogsTreeDispatchTypes
  | GetAPILogContentDispatchTypes
  | GetBackendLogsListDispatchTypes
  | GetBackendLogContentDispatchTypes;
