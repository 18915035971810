import { SkeletonProps } from '@mui/lab';
import { Skeleton, skeletonClasses } from '@mui/material';
import { COMMON_COLORS } from '../ui/colors';

const styles = {
  waveSkeletonRoot: {
    backgroundColor: COMMON_COLORS.mask,
  },
  waveBackground: {
    '&::after': {
      background:
        'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.04), transparent)',
    },
  },
};

const WaveSkeleton = (props: SkeletonProps) => {
  return (
    <Skeleton
      animation="wave"
      sx={{
        ...styles.waveSkeletonRoot,
        ...(props.variant !== 'circular' ? { borderRadius: '0.5rem' } : {}),
        [`& .${skeletonClasses.wave}`]: styles.waveBackground,
      }}
      {...props}
    />
  );
};

export default WaveSkeleton;
