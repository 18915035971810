import { EMPTY_DATASET_LABELS } from '../../../constants/charts/utils.constants';
import {
  ChartTypeEnum,
  IStatementsConfig,
  IVisualAggregatedConfig,
  IVisualConfig,
} from '../../../models/visuals.model';
import {
  CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA,
  CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA,
  CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA,
  CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA,
  CASH_QUARTERLY_DATA,
  CASH_SOURCES_QUARTERLY_DATA,
  CASH_SOURCES_YEARLY_DATA,
  CASH_YEARLY_DATA,
  CF_STATEMENTS_QUARTERLY_DATA,
  CF_STATEMENTS_YEARLY_DATA,
  NET_CHANGE_IN_CASH_QUARTERLY_DATA,
  NET_CHANGE_IN_CASH_YEARLY_DATA,
} from './visuals.model';

const CASH_FLOW_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'cashYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Cash & Equivalents',
    dataAction: CASH_YEARLY_DATA,
    stateLabel: 'cashData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Cash & Equivalents',
      'Report Date',
      'Currency Rate',
    ],
    datasetLabels: [{ filled: true }],
  },
  {
    id: 'netChangeInCashYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Net Change in Cash',
    dataAction: NET_CHANGE_IN_CASH_YEARLY_DATA,
    stateLabel: 'netChangeInCashData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Net Change in Cash',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'cashSourcesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Net Cash from Operating Activities',
      'Net Cash from Investing Activities',
      'Net Cash from Financing Activities',
    ],
    dataAction: CASH_SOURCES_YEARLY_DATA,
    stateLabel: 'cashSourcesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Net Cash from Operating Activities',
      'Net Cash from Investing Activities',
      'Net Cash from Financing Activities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: [
      { label: 'Net Cash from Operating Activities' },
      { label: 'Net Cash from Investing Activities' },
      { label: 'Net Cash from Financing Activities' },
    ],
  },
  {
    id: 'cashFromOperatingActivitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Net Income (First Line)',
      'Depreciation & Amortization',
      'Non-Cash Items',
      'Change in Operating Working Capital',
    ],
    dataAction: CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA,
    stateLabel: 'cashFromOperatingActivitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Net Income (First Line)',
      'Depreciation & Amortization',
      'Non-Cash Items',
      'Change in Operating Working Capital',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: [
      { label: 'Net Income/Starting Line' },
      { label: 'Depreciation & Amortization' },
      { label: 'Non-Cash Items' },
      { label: 'Change in Working Capital' },
    ],
  },
  {
    id: 'cashFromInvestingActivitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: ['Capital Expenditures', 'Other Investing Activities'],
    dataAction: CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA,
    stateLabel: 'cashFromInvestingActivitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Capital Expenditures',
      'Other Investing Activities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: [
      { label: 'CapEx' },
      { label: 'Other Investing Activities' },
    ],
  },
  {
    id: 'cashFromFinancingActivitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Dividends Paid',
      'Cash from (Repayment of) Debt',
      'Cash from (Repurchase of) Equity',
      'Other Financing Activities',
    ],
    dataAction: CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA,
    stateLabel: 'cashFromFinancingActivitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Dividends Paid',
      'Cash from (Repayment of) Debt',
      'Cash from (Repurchase of) Equity',
      'Other Financing Activities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: [
      { label: 'Dividends Paid' },
      { label: 'Cash from (Repayment of) Debt' },
      { label: 'Cash from (Repurchase of) Equity' },
      { label: 'Other Financing Activities' },
    ],
  },
];

const CASH_FLOW_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'cashQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Cash & Equivalents',
    dataAction: CASH_QUARTERLY_DATA,
    stateLabel: 'cashData',
    isCurrency: true,
    columns: [
      'Date',
      'Cash & Equivalents',
      'Fiscal Year',
      'Fiscal Period',
      'Report Date',
      'Currency Rate',
    ],
    datasetLabels: [{ filled: true }],
  },
  {
    id: 'netChangeInCashQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Net Change in Cash',
    dataAction: NET_CHANGE_IN_CASH_QUARTERLY_DATA,
    stateLabel: 'netChangeInCashData',
    isCurrency: true,
    columns: [
      'Date',
      'Net Change in Cash',
      'Fiscal Year',
      'Fiscal Period',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'cashSourcesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Net Cash from Operating Activities',
      'Net Cash from Investing Activities',
      'Net Cash from Financing Activities',
    ],
    dataAction: CASH_SOURCES_QUARTERLY_DATA,
    stateLabel: 'cashSourcesData',
    isCurrency: true,
    columns: [
      'Date',
      'Net Cash from Operating Activities',
      'Net Cash from Investing Activities',
      'Net Cash from Financing Activities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: [
      { label: 'Net Cash from Operating Activities' },
      { label: 'Net Cash from Investing Activities' },
      { label: 'Net Cash from Financing Activities' },
    ],
  },
  {
    id: 'cashFromOperatingActivitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Net Income (First Line)',
      'Depreciation & Amortization',
      'Non-Cash Items',
      'Change in Operating Working Capital',
    ],
    dataAction: CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA,
    stateLabel: 'cashFromOperatingActivitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Net Income (First Line)',
      'Depreciation & Amortization',
      'Non-Cash Items',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
      'Change in Operating Working Capital',
    ],
    datasetLabels: [
      { label: 'Net Income/Starting Line' },
      { label: 'Depreciation & Amortization' },
      { label: 'Non-Cash Items' },
      { label: 'Change in Working Capital' },
    ],
  },
  {
    id: 'cashFromInvestingActivitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: ['Capital Expenditures', 'Other Investing Activities'],
    dataAction: CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA,
    stateLabel: 'cashFromInvestingActivitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Capital Expenditures',
      'Other Investing Activities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: [
      { label: 'CapEx' },
      { label: 'Other Investing Activities' },
    ],
  },
  {
    id: 'cashFromFinancingActivitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: [
      'Dividends Paid',
      'Cash from (Repayment of) Debt',
      'Cash from (Repurchase of) Equity',
      'Other Financing Activities',
    ],
    dataAction: CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA,
    stateLabel: 'cashFromFinancingActivitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Dividends Paid',
      'Cash from (Repayment of) Debt',
      'Cash from (Repurchase of) Equity',
      'Other Financing Activities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: [
      { label: 'Dividends Paid' },
      { label: 'Cash from (Repayment of) Debt' },
      { label: 'Cash from (Repurchase of) Equity' },
      { label: 'Other Financing Activities' },
    ],
  },
];

export const CASH_FLOW_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: CASH_FLOW_YEARLY_CONFIG,
  quarterly: CASH_FLOW_QUARTERLY_CONFIG,
};

export const CF_STATEMENTS_CONFIG: IStatementsConfig[] = [
  {
    label: 'Net Income',
    column: 'Net Income (First Line)',
    hierarchy: 2,
  },
  {
    label: 'Depreciation & Amortization',
    column: 'Depreciation & Amortization',
    hierarchy: 2,
  },
  {
    label: 'Non-Cash Items',
    column: 'Non-Cash Items',
    hierarchy: 2,
  },
  {
    label: 'Change in Operating Working Capital',
    column: 'Change in Operating Working Capital',
    hierarchy: 2,
  },
  {
    label: 'Change in Accounts Receivable',
    column: 'Change in Accounts Receivable',
    hierarchy: 3,
  },
  {
    label: 'Change in Inventories',
    column: 'Change in Inventories',
    hierarchy: 3,
  },
  {
    label: 'Change in Accounts Payable',
    column: 'Change in Accounts Payable',
    hierarchy: 3,
  },
  {
    label: 'Other changes in Operating Working Capital',
    column: 'Other changes in Operating Working Capital',
    hierarchy: 3,
  },
  {
    label: 'Net Cash from Operating Activities',
    column: 'Net Cash from Operating Activities',
    hierarchy: 1,
  },
  {
    label: 'Capital Expenditures',
    column: 'Capital Expenditures',
    hierarchy: 2,
  },
  {
    label: 'Other Investing Activities',
    column: 'Other Investing Activities',
    hierarchy: 2,
  },
  {
    label: 'Net Cash from Investing Activities',
    column: 'Net Cash from Investing Activities',
    hierarchy: 1,
  },
  {
    label: 'Dividends Paid',
    column: 'Dividends Paid',
    hierarchy: 2,
  },
  {
    label: 'Cash from (Repayment of) Debt',
    column: 'Cash from (Repayment of) Debt',
    hierarchy: 2,
  },
  {
    label: 'Cash from (Repurchase of) Equity',
    column: 'Cash from (Repurchase of) Equity',
    hierarchy: 2,
  },
  {
    label: 'Other Financing Activities',
    column: 'Other Financing Activities',
    hierarchy: 2,
  },
  {
    label: 'Net Cash from Financing Activities',
    column: 'Net Cash from Financing Activities',
    hierarchy: 1,
  },
  {
    label: 'Effect of Foreign Exchange Rates',
    column: 'Effect of Foreign Exchange Rates',
    hierarchy: 2,
  },
  {
    label: 'Change in Cash & Equivalents',
    column: 'Change in Cash & Equivalents',
    hierarchy: 2,
  },
  {
    label: 'Initial Cash Flow',
    column: 'Initial Cash Flow',
    hierarchy: 2,
  },
  {
    label: 'Final Cash Flow',
    column: 'Final Cash Flow',
    hierarchy: 1,
  },
];

const cf_statements_items = CF_STATEMENTS_CONFIG.map(
  (e: IStatementsConfig) => e.column
);

export const CF_STATEMENTS_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: [
    {
      id: 'csStatements',
      type: ChartTypeEnum.BASIC_CHART,
      item: cf_statements_items,
      dataAction: CF_STATEMENTS_YEARLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
  quarterly: [
    {
      id: 'csStatementsQuarterly',
      type: ChartTypeEnum.BASIC_CHART,
      item: cf_statements_items,
      dataAction: CF_STATEMENTS_QUARTERLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
};

/***************************************
 *             Title Labels            *
 ***************************************/
export const CASH_FLOW_TITLE_LABELS = {
  cash: 'Cash',
  netChangeInCash: 'Net Change In Cash',
  cashSources: 'Cash Sources',
  cashFromOperatingActivities: 'Cash From Operating Activities',
  cashFromInvestingActivities: 'Cash From Investing Activities',
  cashFromFinancingActivities: 'Cash From Financing Activities',
};
