import {
  CLOSE_ACTIVE_FREE_TRIAL_DIALOG,
  CLOSE_CHART_MENU,
  CLOSE_LARGE_CHART_DIALOG,
  CLOSE_NAV_MENU,
  CLOSE_RATING_DIALOG,
  CLOSE_REPORT_ISSUE_DIALOG,
  CLOSE_SIGN_IN_DIALOG,
  CLOSE_TABLE_DIALOG,
  CLOSE_USER_SETTINGS_MENU,
  CloseTriggerUnion,
  IAnchorPosition,
  IChartMenuData,
  INavMenuLinkItem,
  OPEN_ACTIVE_FREE_TRIAL_DIALOG,
  OPEN_CHART_MENU,
  OPEN_LARGE_CHART_DIALOG,
  OPEN_NAV_MENU,
  OPEN_RATING_DIALOG,
  OPEN_REPORT_ISSUE_DIALOG,
  OPEN_SIGN_IN_DIALOG,
  OPEN_TABLE_DIALOG,
  OPEN_USER_SETTINGS_MENU,
  TOGGLE_ALERT,
  TableDialogUnion,
} from '../../models/modals.model';
import { IOpenLargeChart } from '../../models/visuals.model';

// Report issue
export const openReportIssueDialog = (
  titleLabel: string,
  chartImage: string
) => ({
  type: OPEN_REPORT_ISSUE_DIALOG,
  payload: {
    titleLabel,
    chartImage,
  },
});

export const closeReportIssueDialog = () => ({
  type: CLOSE_REPORT_ISSUE_DIALOG,
});

// Large chart dialog
export const openLargeChartDialog = (data: IOpenLargeChart) => {
  return ({
    type: OPEN_LARGE_CHART_DIALOG,
    payload: data,
  })
};

export const closeLargeChartDialog = () => ({
  type: CLOSE_LARGE_CHART_DIALOG,
});

// Table dialog
export const openTableDialog = (type: TableDialogUnion) => ({
  type: OPEN_TABLE_DIALOG,
  payload: {
    type,
  },
});

export const closeTableDialog = () => ({
  type: CLOSE_TABLE_DIALOG,
});

// Chart menu
export const openChartMenu = (data: IChartMenuData) => ({
  type: OPEN_CHART_MENU,
  payload: data,
});

export const closeChartMenu = () => ({
  type: CLOSE_CHART_MENU,
});

// Nav menu
export const openNavMenu = (
  linkList: INavMenuLinkItem[],
  anchorPosition: IAnchorPosition
) => ({
  type: OPEN_NAV_MENU,
  payload: {
    linkList,
    anchorPosition,
  },
});

export const closeNavMenu = (trigger: CloseTriggerUnion) => ({
  type: CLOSE_NAV_MENU,
  payload: trigger,
});

// Active free trial dialog
export const openActiveFreeTrialDialog = () => ({
  type: OPEN_ACTIVE_FREE_TRIAL_DIALOG,
});

export const closeActiveFreeTrialDialog = () => ({
  type: CLOSE_ACTIVE_FREE_TRIAL_DIALOG,
});

// User settings menu
export const openUserSettingsMenu = (anchorPosition: IAnchorPosition) => ({
  type: OPEN_USER_SETTINGS_MENU,
  payload: {
    anchorPosition,
  },
});

export const closeUserSettingsMenu = () => ({
  type: CLOSE_USER_SETTINGS_MENU,
});

// Alert
export const toggleAlert = (message?: string, error?: boolean) => ({
  type: TOGGLE_ALERT,
  payload: !!message ? { message, error: !!error } : undefined,
});

// Rating dialog
export const openRatingDialog = () => ({
  type: OPEN_RATING_DIALOG,
});

export const closeRatingDialog = () => ({ type: CLOSE_RATING_DIALOG });

// SignIn dialog
export const openSignInDialog = () => ({
  type: OPEN_SIGN_IN_DIALOG,
});

export const closeSignInDialog = () => ({ type: CLOSE_SIGN_IN_DIALOG });
