import { Box, MenuItem, menuItemClasses } from '@mui/material';
import { ICompanyMinimal, IS_FREE_LICENCE_TICKER } from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteEnum } from '../../../navigation/routes.constants';
import { fetchCompanyData } from '../../../store/actions/companies.action';
import { setTickerId } from '../../../store/actions/filters.action';
import { openActiveFreeTrialDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { BLUR } from '../../../styles/common.styles';
import { useSelectStyles } from '../../../styles/select.styles';
import IconComponent from '../../icon.component';

type Props = {
  peer: ICompanyMinimal;
};

const styles = {
  menuItemRoot: {
    display: 'flex',
    padding: '0.3rem',
    minHeight: '3.5rem !important',
    paddingLeft: '1rem',
    backgroundColor: 'transparent',
  },
  tickerIdContainer: {
    flex: '2 1 0',
    width: '5rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: '0.5rem',
  },
  companyNameContainer: {
    flex: '5 1 0',
    maxWidth: '14rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const PeerMenuItemComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { peer } = props;

  const selectStyles = useSelectStyles();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const isNotFreeTicker =
    !hasActiveLicence && !IS_FREE_LICENCE_TICKER(props.peer.tickerId);

  const showBlurred = !hasActiveLicence;

  const component = (
    <MenuItem
      sx={{
        ...selectStyles.menuItemRoot,
        ...styles.menuItemRoot,
        ...(showBlurred ? BLUR : {}),
        [`& .${menuItemClasses.selected}`]: selectStyles.menuItemSelected,
      }}
      onClick={(event) => {
        if (isNotFreeTicker) {
          dispatch(openActiveFreeTrialDialog());
        } else {
          if (!(event.ctrlKey || event.metaKey)) {
            event.stopPropagation();
            event.preventDefault();
            dispatch(setTickerId(peer.tickerId));
            dispatch(fetchCompanyData(peer.tickerId));
          }
        }
      }}
    >
      <IconComponent
        src={peer?.tickerId}
        rest={{
          style: {
            width: '2rem',
            height: '2rem',
            marginRight: '1rem',
          },
        }}
      />
      <Box sx={styles.tickerIdContainer}>{peer.tickerId}</Box>
      <Box sx={styles.companyNameContainer}>{peer.companyName}</Box>
    </MenuItem>
  );

  if (isNotFreeTicker || showBlurred) {
    return component;
  }

  return (
    <Link
      to={`${RouteEnum.COMPANY_SUMMARY}?tickerId=${peer.tickerId}`}
      style={{ all: 'unset' }}
    >
      {component}
    </Link>
  );
};

export default PeerMenuItemComponent;
