import {
  FEEDBACK_URL,
  RESIZE_IMAGE_URL,
  SEND_RATING_URL,
  VERIFY_RECAPTCHA_URL,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { STANDARD_ICON_SIZE } from '../constants/general.constants';
import { IFiltersState } from '../models/filters.model';
import { axiosInstance } from '../utils/axios-interceptor';

// const snapshotJson = JSON.stringify(filters);
// const snapshot = LZString.compress(snapshotJson);

export const reportIssueService = (
  message: string,
  chartTitleLabel: string,
  chartImage: string,
  filters: IFiltersState
) =>
  axiosInstance.post(FEEDBACK_URL, {
    message,
    chartTitleLabel,
    chartImage,
    filters: {
      tickerId: filters.tickerId,
      fiscalYear: filters.fiscalYear,
      activeQYFilter: filters.activeQYFilter,
      activeDWMQFilter: filters.activeDWMQFilter,
      peerGroup: filters.peerGroup,
      useDollars: filters.useDollars,
      peers: filters.peers,
    },
  });

export const sendRatingService = (message: string, rating: number) =>
  axiosInstance.put(SEND_RATING_URL, {
    message,
    rating,
  });

export const verifyRecaptchaService = (token: string) =>
  axiosInstance.post(VERIFY_RECAPTCHA_URL, { token });

export const resizeImageService = (
  base64Image: string,
  width?: number,
  height?: number
): Promise<AxiosResponse<string>> =>
  axiosInstance.post(RESIZE_IMAGE_URL, {
    b64_icon: base64Image,
    width: width || STANDARD_ICON_SIZE,
    height: height || STANDARD_ICON_SIZE,
  });
