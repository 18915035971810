import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableDialogUnion } from '../../models/modals.model';
import {
  closeActiveFreeTrialDialog,
  closeLargeChartDialog,
  closeReportIssueDialog,
  closeTableDialog,
  openReportIssueDialog,
} from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import ChartDialogComponent from '../charts/chart-dialog.component';
import RegistrationDialog from '../registration-dialog.component';
import SignInDialogComponent from '../sign-in-dialog.component';
import ActivateFreeTrialDialog from './activate-free-trial-dialog.component';
import AlertComponent from './alert.component';
import ErrorSnackbarComponent from './error-snackbar.component';
import MenuComponent from './menu/menu.component';
import NavMenuComponent from './menu/nav-menu.component';
import ReportIssueDialogComponent from './menu/report-issue-dialog.component';
import UserSettingsMenu from './menu/user-settings-menu.component';
import RatingDialogComponent from './rating-dialog.component';
import ScreenerPeersDialogComponent from './screener-peers-dialog.component';

const ModalsComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reportIssueDialogData = useSelector(
    (state: RootState) => state.modals.reportIssueDialogData
  );
  const largeChartDialogData = useSelector(
    (state: RootState) => state.modals.largeChartDialogData
  );
  const tableDialogData = useSelector(
    (state: RootState) => state.modals.tableDialogData
  );
  const menuData = useSelector((state: RootState) => state.modals.menuData);
  const navMenuData = useSelector(
    (state: RootState) => state.modals.navMenuData
  );
  const activeFreeTrialOpen = useSelector(
    (state: RootState) => state.modals.activeFreeTrialOpen
  );
  const userSettingsMenuData = useSelector(
    (state: RootState) => state.modals.userSettingsMenuData
  );

  /* Send Feedback */
  const onCloseReportIssueHandler = () => {
    dispatch(closeReportIssueDialog());
  };

  /* Get licence */
  const onCloseActiveFreeTrialHandler = () => {
    dispatch(closeActiveFreeTrialDialog());
  };

  /* Large Chart */
  const onChartDialogCloseHandler = () => {
    dispatch(closeLargeChartDialog());
  };

  /* Table */
  const onTableDialogCloseHandler = () => {
    dispatch(closeTableDialog());
  };

  return (
    <>
      {/* Send Feedback */}
      <ReportIssueDialogComponent
        chartTitleLabel={reportIssueDialogData?.titleLabel || ''}
        chartImage={reportIssueDialogData?.chartImage || ''}
        open={!!reportIssueDialogData}
        onCloseHandler={onCloseReportIssueHandler}
      />

      {/* Activate Free Trial */}
      <ActivateFreeTrialDialog
        open={!!activeFreeTrialOpen}
        setOpen={onCloseActiveFreeTrialHandler}
      />

      {/* Large Chart */}
      {largeChartDialogData && (
        <ChartDialogComponent
          title={largeChartDialogData.titleLabel}
          open={!!largeChartDialogData}
          data={largeChartDialogData}
          onCloseHandler={onChartDialogCloseHandler}
        />
      )}

      {/* Screener Table / Peer Group */}
      <ScreenerPeersDialogComponent
        type={tableDialogData?.type as TableDialogUnion}
        open={!!tableDialogData}
        onCloseHandler={onTableDialogCloseHandler}
        parentHistory={history}
      />

      {/* Menu */}
      <MenuComponent
        menuOpen={!!menuData?.open}
        menuOnlyExport={!!menuData?.menuOnlyExport}
        /* Anchor */
        anchorPosition={menuData?.anchorPosition}
        /* Data */
        chartImage={menuData?.chartImage}
        exportData={menuData?.exportData}
        titleLabel={menuData?.titleLabel}
        hasInfo={menuData?.hasInfo}
        /* Handlers */
        onFullScreenHandler={() => {
          if (menuData?.onFullScreenHandler) {
            menuData.onFullScreenHandler();
          }
        }}
        onReportIssueHanlder={() => {
          dispatch(
            openReportIssueDialog(
              menuData?.titleLabel as string,
              menuData?.chartImage || ''
            )
          );
        }}
        onInfoHandler={() => {
          if (menuData?.onInfoHandler) {
            menuData.onInfoHandler();
          }
        }}
      />

      {/* Nav Menu */}
      <NavMenuComponent
        menuOpen={!!navMenuData?.open}
        list={navMenuData?.linkList}
        anchorPosition={navMenuData?.anchorPosition}
      />

      {/* User Settings Menu */}
      <UserSettingsMenu
        menuOpen={!!userSettingsMenuData?.open}
        anchorPosition={userSettingsMenuData?.anchorPosition}
      />

      {/* Snackbar */}
      <ErrorSnackbarComponent />

      {/* Registration */}
      <RegistrationDialog />

      {/* Alert */}
      <AlertComponent />

      {/* Rating */}
      <RatingDialogComponent />

      {/* Access Dialog */}
      <SignInDialogComponent />
    </>
  );
};

export default ModalsComponent;
