import { COMPANY_LABEL_PLACEHOLDER } from '../../../constants/charts/chart.constants';
import {
  ChartTypeEnum,
  IVisualAggregatedConfig,
  IVisualConfig,
} from '../../../models/visuals.model';
import {
  CAPEX_DISTRIBUTION,
  CAPEX_QUARTERLY,
  CAPEX_YEARLY,
  CURRENT_RATIO_DISTRIBUTION,
  CURRENT_RATIO_QUARTERLY,
  CURRENT_RATIO_YEARLY,
  DEBT_PAYBACK_DISTRIBUTION,
  DEBT_PAYBACK_QUARTERLY,
  DEBT_PAYBACK_YEARLY,
  DEBT_TO_EQUITY_DISTRIBUTION,
  DEBT_TO_EQUITY_QUARTERLY,
  DEBT_TO_EQUITY_YEARLY,
  QUICK_RATIO_DISTRIBUTION,
  QUICK_RATIO_QUARTERLY,
  QUICK_RATIO_YEARLY,
} from './visuals.model';

/***************************************
 *  Current and Quick Ration and Capex *
 ***************************************/
const CURRENT_AND_QUICK_RATIO_AND_CAPEX_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'currentRatioYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Current Ratio',
    dataAction: CURRENT_RATIO_YEARLY,
    stateLabel: 'currentRatioData',
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'quickRatioYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Quick Ratio',
    dataAction: QUICK_RATIO_YEARLY,
    stateLabel: 'quickRatioData',
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'capexYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'CapEx To Net Income',
    dataAction: CAPEX_YEARLY,
    stateLabel: 'capexData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const CURRENT_AND_QUICK_RATIO_AND_CAPEX_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'currentRatioQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Current Ratio',
    dataAction: CURRENT_RATIO_QUARTERLY,
    stateLabel: 'currentRatioData',
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'quickRatioQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Quick Ratio',
    dataAction: QUICK_RATIO_QUARTERLY,
    stateLabel: 'quickRatioData',
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'capexQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'CapEx To Net Income',
    dataAction: CAPEX_QUARTERLY,
    stateLabel: 'capexData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const CURRENT_AND_QUICK_RATIO_AND_CAPEX_CONFIG: IVisualConfig[] = [
  {
    id: 'currentRatioDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Current Ratio',
    dataAction: CURRENT_RATIO_DISTRIBUTION,
    stateLabel: 'currentRatioDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
  {
    id: 'quicktRatioDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Quick Ratio',
    dataAction: QUICK_RATIO_DISTRIBUTION,
    stateLabel: 'quickRatioDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
  {
    id: 'capexDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'CapEx To Net Income',
    dataAction: CAPEX_DISTRIBUTION,
    stateLabel: 'capexDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
];

export const CURRENT_AND_QUICK_RATIO_AND_CAPEX_CONFIG_AGGREGATED: IVisualAggregatedConfig =
  {
    yearly: CURRENT_AND_QUICK_RATIO_AND_CAPEX_YEARLY_CONFIG,
    quarterly: CURRENT_AND_QUICK_RATIO_AND_CAPEX_QUARTERLY_CONFIG,
  };

/***************************************
 *    Debt To Equity and Debt Payback  *
 ***************************************/
const DE_AND_DEBT_PAYBACK_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'deYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'D/E adj',
    dataAction: DEBT_TO_EQUITY_YEARLY,
    stateLabel: 'deData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'debtPaybackYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Debt Payback Period',
    dataAction: DEBT_PAYBACK_YEARLY,
    stateLabel: 'debtPaybackData',
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const DE_AND_DEBT_PAYBACK_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'deQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'D/E adj',
    dataAction: DEBT_TO_EQUITY_QUARTERLY,
    stateLabel: 'deData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },

  {
    id: 'debtPaybackQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'Debt Payback Period',
    dataAction: DEBT_PAYBACK_QUARTERLY,
    stateLabel: 'debtPaybackData',
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const DEBT_TO_EQUITY_AND_DEBT_PAYBACK_CONFIG: IVisualConfig[] = [
  {
    id: 'deDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'D/E adj',
    dataAction: DEBT_TO_EQUITY_DISTRIBUTION,
    stateLabel: 'deDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
  {
    id: 'debtPaybackDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'Debt Payback Period',
    dataAction: DEBT_PAYBACK_DISTRIBUTION,
    stateLabel: 'debtPaybackDistributionData',
    isPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
];

export const DEBT_TO_EQUITY_AND_DEBT_PAYBACK_CONFIG_AGGREGATED: IVisualAggregatedConfig =
  {
    quarterly: DE_AND_DEBT_PAYBACK_QUARTERLY_CONFIG,
    yearly: DE_AND_DEBT_PAYBACK_YEARLY_CONFIG,
  };

/***************************************
 *             Title Labels            *
 ***************************************/
export const CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS = {
  currentRatio: 'Current Ratio',
  quickRatio: 'Quick Ratio',
  capex: 'Capex',
  currentRatioDistribution: 'Current Ratio Distribution',
  quickRatioDistribution: 'Quick Ratio Distribution',
  capexDistribution: 'Capex Distribution',
};

export const DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS = {
  debtToEquity: 'Debt To Equity',
  debtPayback: 'Debt Payback Period',
  debtToEquityDistribution: 'Debt To Equity Distribution',
  debtPaybackDistribution: 'Debt Payback Distribution',
};
