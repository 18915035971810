import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Palette,
  Rating,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FEEDBACK_MAX_CHARACTERS } from '@prometeus/common';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendRatingService,
  verifyRecaptchaService,
} from '../../services/other.service';
import { setHasRated } from '../../store/actions/authentication.action';
import {
  closeRatingDialog,
  toggleAlert,
} from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import { maskBorderShadow, pxToRem } from '../../ui/functions';
import ChartLoadingComponent from '../charts/chart-loading.component';
import CustomDialogComponent from '../dialog.component';
import RecaptchaComponent from '../recaptcha.component';
import TextField from '../text-field.component';
import TooltipIconButton from '../tooltip-icon-button.component';

const getStyles = (palette: Palette) => ({
  textAreaRoot: {
    width: '100%',
    marginBottom: '1rem',
    marginTop: '1.5rem',
    borderRadius: '0.8rem !important',
    '& .MuiOutlinedInput-root': {
      border: maskBorderShadow(palette),
      borderRadius: '0.8rem !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: `${pxToRem(18.5)} ${pxToRem(14)}`,
    },
    '& .MuiFormHelperText-root': {
      color: palette.text.secondary,
      marginLeft: 'auto',
      marginRight: pxToRem(14),
      marginTop: pxToRem(3),
    },
  },
  paper: {
    backgroundColor: palette.background.paper,
  },
  button: {
    borderRadius: '2rem',
  },
});

const RatingDialogComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const open = useSelector((state: RootState) => state.modals.openRatingDialog);

  const closeDialog = () => {
    setRating(0);
    setFeedback('');
    dispatch(closeRatingDialog());
  };

  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>('');

  const onFeedbackChange = (event: any) => {
    setFeedback(event.target.value);
  };

  const sendRatingHandler = async () => {
    try {
      // Check if ref is not null
      if (recaptchaRef?.current) {
        // recaptcha
        const token = await recaptchaRef.current.executeAsync();

        if (token) {
          const verifiedTokenResponse = (await verifyRecaptchaService(token))
            ?.data?.token;

          if (verifiedTokenResponse !== token) {
            throw new Error('Failed reCAPTCHA verification');
          }
        }

        recaptchaRef.current.reset();
      }

      const response = await sendRatingService(feedback, rating);
      if (!!response) {
        dispatch(toggleAlert('Thank you very much for your feedback!'));
        dispatch(setHasRated(true));
      }
    } catch (err) {
      dispatch(
        toggleAlert('An error has occured! Please, try again later.', true)
      );
    }
    closeDialog();
  };

  // reCAPTCHA
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loadedRecaptcha, setLoadedRecaptcha] = useState<boolean>(false);

  return (
    <CustomDialogComponent
      open={!!open}
      onClose={closeDialog}
      maxWidth="sm"
      dialogTitle={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5">
            What do you think about <b>PROMETEUS</b>?
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            tooltip="Close"
            onClick={closeDialog}
          />
        </Stack>
      }
      dialogContent={
        <>
          <RecaptchaComponent
            ref={recaptchaRef}
            onLoad={() => {
              setLoadedRecaptcha(true);
            }}
          />
          {!loadedRecaptcha ? (
            <ChartLoadingComponent />
          ) : (
            <Box sx={{ margin: '1rem' }}>
              <Typography variant="h6" style={{ margin: '1rem 0rem' }}>
                Let us know your thoughts!
              </Typography>
              <Box style={{ textAlign: 'center' }}>
                <Rating
                  value={rating}
                  onChange={(_: any, newRating: number | null) => {
                    setRating(newRating || 0);
                  }}
                  size="large"
                />
                <TextField
                  multiline={true}
                  rows={4}
                  variant={'outlined'}
                  sx={styles.textAreaRoot}
                  onChange={onFeedbackChange}
                  value={feedback}
                  placeholder={'Leave your feedback here'}
                  error={feedback.length > FEEDBACK_MAX_CHARACTERS}
                  helperText={`${feedback.length}/${FEEDBACK_MAX_CHARACTERS}`}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{ marginBottom: '1rem' }}
                >
                  <Button
                    color="inherit"
                    onClick={closeDialog}
                    sx={styles.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendRatingHandler}
                    disabled={
                      !feedback ||
                      feedback.length > FEEDBACK_MAX_CHARACTERS ||
                      !rating
                    }
                    sx={styles.button}
                  >
                    Send
                  </Button>
                </Stack>
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};

export default RatingDialogComponent;
