import {
  Box,
  Divider,
  Palette,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNullUndefined } from '@prometeus/common';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/root.reducer';
import WaveSkeleton from './wave-skeleton.component';

const getStyles = (palette: Palette) => ({
  titleRoot: {
    color: palette.text.primary,
  },
  firmsTitleRoot: {
    marginLeft: '0.8rem',
  },
  divider: {
    margin: '0.5rem 1.5rem',
    // backgroundColor: palette.text.primary,
    backgroundColor: palette.divider,
    width: '0.1rem',
  },
});

type IndicatorProps = {
  indicator: string;
  indicatorValue: string;
  firms?: number;
  rank?: number;
  styles: { [label: string]: any };
};

const IndicatorComponent = (props: IndicatorProps) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ marginRight: '0.5rem', ...props.styles.titleRoot }}
        >
          {props.indicator}
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ ...props.styles.titleRoot, ...props.styles.firmsTitleRoot }}
        >
          {/* {isNullUndefined(props.firms) ? '' : `${props.rank} / ${props.firms}`} */}
          {isNullUndefined(props.rank) ? '' : props.rank} /{' '}
          <b>{isNullUndefined(props.firms) ? '' : props.firms}</b>
        </Typography>
      </Stack>
      <Typography
        variant="h6"
        component="div"
        sx={{ ...props.styles.titleRoot }}
      >
        {props.indicatorValue}
      </Typography>
    </Box>
  );
};

const IndicatorSkeleton = () => (
  <WaveSkeleton variant="rectangular" height="3rem" width="8rem" />
);

const SectorIndustryCountryHeaderComponent = (props: {
  skeleton?: boolean;
  companySummary?: boolean;
}) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);
  const currentCompany = useSelector(
    (state: RootState) => state.companies.currentCompany
  );

  if (!!currentCompany) {
    return (
      <Stack
        direction="row"
        // sx={{ marginBottom: props.companySummary ? 0 : '-1.5rem' }}
      >
        {!!props.skeleton ? (
          <IndicatorSkeleton />
        ) : (
          <IndicatorComponent
            styles={styles}
            indicator="Country"
            indicatorValue={
              currentCompany.countryName || currentCompany.countryId
            }
            firms={currentCompany.countryFirms}
            rank={currentCompany.countryRank}
          />
        )}
        <Divider orientation="vertical" flexItem sx={{ ...styles.divider }} />
        {!!props.skeleton ? (
          <IndicatorSkeleton />
        ) : (
          <IndicatorComponent
            styles={styles}
            indicator="Sector"
            indicatorValue={currentCompany.sector}
            firms={currentCompany.sectorFirms}
            rank={currentCompany.sectorRank}
          />
        )}
        <Divider orientation="vertical" flexItem sx={{ ...styles.divider }} />
        {!!props.skeleton ? (
          <IndicatorSkeleton />
        ) : (
          <IndicatorComponent
            styles={styles}
            indicator="Industry"
            indicatorValue={currentCompany.industry}
            firms={currentCompany.industryFirms}
            rank={currentCompany.industryRank}
          />
        )}
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default SectorIndustryCountryHeaderComponent;
