import { Map, Set } from 'immutable';
import { ICONS_MAX_SIZE } from '../../constants/general.constants';
import {
  DispatchActionTypes,
  GET_ICONS,
  GET_ICONS_FAIL,
  GET_ICONS_SUCCESS,
  ICachedIcon,
  IIconObj,
  IIconsState,
} from '../../models/icons.model';

const initialState: IIconsState = {
  iconsMap: Map<string, ICachedIcon>([]),
  requestedTickers: Set<string>([]),
  iconsQueue: [],
  loading: false,
};

const iconsReducer = (
  state: IIconsState = initialState,
  action: DispatchActionTypes
): IIconsState => {
  switch (action.type) {
    case GET_ICONS:
      return {
        ...state,
        loading: true,
        requestedTickers: Set([...state.requestedTickers, ...action.payload]),
      };
    case GET_ICONS_SUCCESS:
      let { iconsMap, iconsQueue } = state;

      action.payload?.forEach((e: IIconObj) => {
        const { companyId, image, shape } = e;
        const old = iconsMap.get(companyId);
        iconsMap = iconsMap.set(companyId, {
          image,
          count: (old?.count || 0) + 1,
          shape,
        });
        iconsQueue = [...iconsQueue, companyId];

        while (iconsMap.size > ICONS_MAX_SIZE && !!iconsQueue.length) {
          const ticker = iconsQueue.pop() as string;
          const { count } = iconsMap.get(ticker) || { count: 1 };
          if (count - 1 <= 0) {
            iconsMap = iconsMap.delete(ticker);
          }
        }
      });
      return {
        ...state,
        iconsMap,
        iconsQueue,
      };

    case GET_ICONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default iconsReducer;
