import { Stack, SxProps } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import React from 'react';
import { COMMON_STYLES } from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
  direction?: ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  noMarginBottom?: boolean;
};

const ResponsiveRow = (props: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  return (
    <Stack
      direction={(isPortraitMobile ? 'column' : 'row') || props.direction}
      sx={{
        '& > div': {
          flex: '1 1 0',
        },
        height: isPortraitMobile ? '65%' : '45%',
        ...(!!props.noMarginBottom
          ? {}
          : isPortraitMobile
          ? { marginBottom: 0 }
          : COMMON_STYLES.cardRowBottomMargin),
        ...props.sx,
      }}
    >
      {props.children}
    </Stack>
  );
};

export default ResponsiveRow;
