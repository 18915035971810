import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
import CurrentQuickRatioCapexTab from './tabs/current-quick-ratio-capex.tab';
import DebtToEquityDebtPaybackTab from './tabs/debt-to-equity-debt-payback.tab';

export enum FinancialStrengthRouteEnum {
  CURRENT_AND_QUICK_RATIO = 'current-and-quick-ratio',
  DEBT_TO_EQUITY_AND_DEBT_PAYBACK = 'debt-to-equity-and-debt-payback',
}

const tabs: ITab[] = [
  {
    label: 'Current & Quick Ratio, CapEx Impact',
    route: FinancialStrengthRouteEnum.CURRENT_AND_QUICK_RATIO,
    component: CurrentQuickRatioCapexTab,
  },
  {
    label: 'Debt To Equity & Debt Payback',
    route: FinancialStrengthRouteEnum.DEBT_TO_EQUITY_AND_DEBT_PAYBACK,
    component: DebtToEquityDebtPaybackTab,
  },
];

const FinancialStrengthPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.FINANCIAL_STRENGTH}
      tabs={tabs}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default FinancialStrengthPage;
