import { lazy } from 'react';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';

const UserInformationTab = lazy(() => import('./tabs/user-information.tab'));
const PreferencesTab = lazy(() => import('./tabs/preferences.tab'));
const LicencesTab = lazy(() => import('./tabs/licences.tab'));

export enum UserRouteEnum {
  USER_INFORMATION = 'user-information',
  LICENCES = 'licences',
  PREFERENCES = 'preferences',
}

export const USER_TAB: ITab[] = [
  {
    label: 'User Information',
    route: UserRouteEnum.USER_INFORMATION,
    component: UserInformationTab,
  },
  {
    label: 'Licences',
    route: UserRouteEnum.LICENCES,
    component: LicencesTab,
  },
  {
    label: 'Preferences',
    route: UserRouteEnum.PREFERENCES,
    component: PreferencesTab,
  },
];
const UserPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.USER}
      tabs={USER_TAB}
      hideCompanyHeader
    />
  );
};

export default UserPage;
