import { IVisualChart, IVisualPayload } from '../../../models/visuals.model';

export interface IVisualsState {
  /* ROS & ROE */
  rosData?: IVisualChart;
  rosDataUpdating?: boolean;
  rosDistributionData?: IVisualChart;
  rosDistributionDataUpdating?: boolean;
  roeData?: IVisualChart;
  roeDataUpdating?: boolean;
  roeDistributionData?: IVisualChart;
  roeDistributionDataUpdating?: boolean;
  /* ROA & ROIC */
  roaData?: IVisualChart;
  roaDataUpdating?: boolean;
  roaDistributionData?: IVisualChart;
  roaDistributionDataUpdating?: boolean;
  roicData?: IVisualChart;
  roicDataUpdating?: boolean;
  roicDistributionData?: IVisualChart;
  roicDistributionDataUpdating?: boolean;
}

/* MANAGEMENT_EFFECTIVENESS_UPDATE */
export const MANAGEMENT_EFFECTIVENESS_UPDATE =
  'MANAGEMENT_EFFECTIVENESS_UPDATE';
export interface IManagementEffectivenessUpdate {
  type: typeof MANAGEMENT_EFFECTIVENESS_UPDATE;
  payload: string[];
}

/***************************************
 *               ROS & ROE             *
 ***************************************/
/* ROS_YEARLY */
export const ROS_YEARLY = 'ROS_YEARLY';
export interface RosYearlyI {
  type: typeof ROS_YEARLY;
  payload: IVisualPayload;
}

/* ROS_QUARTERLY */
export const ROS_QUARTERLY = 'ROS_QUARTERLY';
export interface RosQuarterlyI {
  type: typeof ROS_QUARTERLY;
  payload: IVisualPayload;
}

/* ROE_YEARLY */
export const ROE_YEARLY = 'ROE_YEARLY';
export interface RoeYearlyI {
  type: typeof ROE_YEARLY;
  payload: IVisualPayload;
}

/* ROE_QUARTERLY */
export const ROE_QUARTERLY = 'ROE_QUARTERLY';
export interface RoeQuarterlyI {
  type: typeof ROE_QUARTERLY;
  payload: IVisualPayload;
}

/* ROS_DISTRIBUTION */
export const ROS_DISTRIBUTION = 'ROS_DISTRIBUTION';
export interface RosDistributionI {
  type: typeof ROS_DISTRIBUTION;
  payload: IVisualPayload;
}

/* ROE_DISTRIBUTION */
export const ROE_DISTRIBUTION = 'ROE_DISTRIBUTION';
export interface RoeDistributionI {
  type: typeof ROE_DISTRIBUTION;
  payload: IVisualPayload;
}

/***************************************
 *              ROA & ROIC             *
 ***************************************/
/* ROA_YEARLY */
export const ROA_YEARLY = 'ROA_YEARLY';
export interface RoaYearlyI {
  type: typeof ROA_YEARLY;
  payload: IVisualPayload;
}

/* ROA_QUARTERLY */
export const ROA_QUARTERLY = 'ROA_QUARTERLY';
export interface RoaQuarterlyI {
  type: typeof ROA_QUARTERLY;
  payload: IVisualPayload;
}

/* ROIC_YEARLY */
export const ROIC_YEARLY = 'ROIC_YEARLY';
export interface RoicYearlyI {
  type: typeof ROIC_YEARLY;
  payload: IVisualPayload;
}

/* ROIC_QUARTERLY */
export const ROIC_QUARTERLY = 'ROIC_QUARTERLY';
export interface RoicQuarterlyI {
  type: typeof ROIC_QUARTERLY;
  payload: IVisualPayload;
}

/* ROA_DISTRIBUTION */
export const ROA_DISTRIBUTION = 'ROA_DISTRIBUTION';
export interface RoaDistributionI {
  type: typeof ROA_DISTRIBUTION;
  payload: IVisualPayload;
}

/* ROIC_DISTRIBUTION */
export const ROIC_DISTRIBUTION = 'ROIC_DISTRIBUTION';
export interface RoicDistributionI {
  type: typeof ROIC_DISTRIBUTION;
  payload: IVisualPayload;
}

export type DispatchActionTypes =
  | IManagementEffectivenessUpdate
  | RosYearlyI
  | RosQuarterlyI
  | RoeYearlyI
  | RoeQuarterlyI
  | RosDistributionI
  | RoeDistributionI
  | RoaYearlyI
  | RoaQuarterlyI
  | RoicYearlyI
  | RoicQuarterlyI
  | RoaDistributionI
  | RoicDistributionI;
