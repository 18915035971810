import { ExportableDataType, IOpenLargeChart } from './visuals.model';

export type TableDialogUnion = 'screener' | 'peers';

export interface IAnchorPosition {
  mouseX: null | number;
  mouseY: null | number;
}

export interface IChartMenuData {
  open: boolean;
  anchorPosition?: IAnchorPosition;
  chartImage?: string;
  exportData?: ExportableDataType;
  titleLabel?: string;
  hasInfo?: boolean;
  onFullScreenHandler?: any;
  onInfoHandler?: any;
  menuOnlyExport?: boolean;
}

export interface INavMenuLinkItem {
  label: string;
  path: string;
  disabled?: boolean;
}

export type CloseTriggerUnion = 'click' | 'away';
export interface IMenuData {
  open: boolean;
  anchorPosition?: IAnchorPosition;
  linkList?: INavMenuLinkItem[];
  closeTrigger?: CloseTriggerUnion;
}

export interface IAlertData {
  message: string;
  error?: boolean;
}

export interface IModalsState {
  reportIssueDialogData?: {
    titleLabel: string;
    chartImage: string;
  };

  largeChartDialogData?: IOpenLargeChart;

  tableDialogData?: {
    type: TableDialogUnion;
  };

  menuData?: IChartMenuData;
  navMenuData?: IMenuData;

  userSettingsMenuData?: IMenuData;

  activeFreeTrialOpen?: boolean;

  showAlert?: IAlertData;

  openRatingDialog?: boolean;

  openSignInDialog?: boolean;
}

/* OPEN_REPORT_ISSUE_DIALOG */
export const OPEN_REPORT_ISSUE_DIALOG = 'OPEN_REPORT_ISSUE_DIALOG';
export interface IOpenReportIssueDialog {
  type: typeof OPEN_REPORT_ISSUE_DIALOG;
  payload: {
    titleLabel: string;
    chartImage: string;
  };
}

/* CLOSE_REPORT_ISSUE_DIALOG */
export const CLOSE_REPORT_ISSUE_DIALOG = 'CLOSE_REPORT_ISSUE_DIALOG';
export interface ICloseReportIssueDialog {
  type: typeof CLOSE_REPORT_ISSUE_DIALOG;
}

/* OPEN_LARGE_CHART_DIALOG */
export const OPEN_LARGE_CHART_DIALOG = 'OPEN_LARGE_CHART_DIALOG';
export interface IOpenLargeChartDialog {
  type: typeof OPEN_LARGE_CHART_DIALOG;
  payload: IOpenLargeChart;
}

/* CLOSE_LARGE_CHART_DIALOG */
export const CLOSE_LARGE_CHART_DIALOG = 'CLOSE_LARGE_CHART_DIALOG';
export interface ICloseLargeChartDialog {
  type: typeof CLOSE_LARGE_CHART_DIALOG;
}

/* OPEN_TABLE_DIALOG */
export const OPEN_TABLE_DIALOG = 'OPEN_TABLE_DIALOG';
export interface IOpenTableDialog {
  type: typeof OPEN_TABLE_DIALOG;
  payload: {
    type: TableDialogUnion;
  };
}

/* CLOSE_TABLE_DIALOG */
export const CLOSE_TABLE_DIALOG = 'CLOSE_TABLE_DIALOG';
export interface ICloseTableDialog {
  type: typeof CLOSE_TABLE_DIALOG;
}

/* OPEN_CHART_MENU */
export const OPEN_CHART_MENU = 'OPEN_CHART_MENU';
export interface IOpenChartMenu {
  type: typeof OPEN_CHART_MENU;
  payload: IChartMenuData;
}

/* CLOSE_CHART_MENU */
export const CLOSE_CHART_MENU = 'CLOSE_CHART_MENU';
export interface ICloseChartMenu {
  type: typeof CLOSE_CHART_MENU;
}

/* OPEN_NAV_MENU */
export const OPEN_NAV_MENU = 'OPEN_NAV_MENU';
export interface IOpenNavMenu {
  type: typeof OPEN_NAV_MENU;
  payload: {
    linkList: INavMenuLinkItem[];
    anchorPosition: IAnchorPosition;
  };
}

/* CLOSE_NAV_MENU */
export const CLOSE_NAV_MENU = 'CLOSE_NAV_MENU';
export interface ICloseNavMenu {
  type: typeof CLOSE_NAV_MENU;
  payload: CloseTriggerUnion;
}

/* OPEN_ACTIVE_FREE_TRIAL_DIALOG */
export const OPEN_ACTIVE_FREE_TRIAL_DIALOG = 'OPEN_ACTIVE_FREE_TRIAL_DIALOG';
export interface IOpenActiveFreeTrialDialog {
  type: typeof OPEN_ACTIVE_FREE_TRIAL_DIALOG;
}

/* CLOSE_ACTIVE_FREE_TRIAL_DIALOG */
export const CLOSE_ACTIVE_FREE_TRIAL_DIALOG = 'CLOSE_ACTIVE_FREE_TRIAL_DIALOG';
export interface ICloseActiveFreeTrialDialog {
  type: typeof CLOSE_ACTIVE_FREE_TRIAL_DIALOG;
}

/* OPEN_USER_SETTINGS_MENU */
export const OPEN_USER_SETTINGS_MENU = 'OPEN_USER_SETTINGS_MENU';
export interface IOpenUserSettingsMenu {
  type: typeof OPEN_USER_SETTINGS_MENU;
  payload: {
    anchorPosition: IAnchorPosition;
  };
}

/* CLOSE_USER_SETTINGS_MENU */
export const CLOSE_USER_SETTINGS_MENU = 'CLOSE_USER_SETTINGS_MENU';
export interface ICloseUserSettingsMenu {
  type: typeof CLOSE_USER_SETTINGS_MENU;
}

/* TOGGLE_ALERT */
export const TOGGLE_ALERT = 'TOGGLE_ALERT';
export interface IToggleAlert {
  type: typeof TOGGLE_ALERT;
  payload?: IAlertData;
}

/* OPEN_RATING_DIALOG */
export const OPEN_RATING_DIALOG = 'OPEN_RATING_DIALOG';
export interface IOpenRatingDialog {
  type: typeof OPEN_RATING_DIALOG;
}

/* CLOSE_RATING_DIALOG */
export const CLOSE_RATING_DIALOG = 'CLOSE_RATING_DIALOG';
export interface ICloseRatingDialog {
  type: typeof CLOSE_RATING_DIALOG;
}

/* OPEN_SIGN_IN_DIALOG */
export const OPEN_SIGN_IN_DIALOG = 'OPEN_SIGN_IN_DIALOG';
export interface IOpenSignInDialog {
  type: typeof OPEN_SIGN_IN_DIALOG;
}

/* CLOSE_SIGN_IN_DIALOG */
export const CLOSE_SIGN_IN_DIALOG = 'CLOSE_SIGN_IN_DIALOG';
export interface ICloseSignInDialog {
  type: typeof CLOSE_SIGN_IN_DIALOG;
}

export type DispatchActionTypes =
  | IOpenReportIssueDialog
  | ICloseReportIssueDialog
  | IOpenLargeChartDialog
  | ICloseLargeChartDialog
  | IOpenTableDialog
  | ICloseTableDialog
  | IOpenChartMenu
  | ICloseChartMenu
  | IOpenNavMenu
  | ICloseNavMenu
  | IToggleAlert
  | IOpenActiveFreeTrialDialog
  | ICloseActiveFreeTrialDialog
  | IOpenUserSettingsMenu
  | ICloseUserSettingsMenu
  | IOpenRatingDialog
  | ICloseRatingDialog
  | IOpenSignInDialog
  | ICloseSignInDialog;
