import {
  Box,
  Palette,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { numberFormatter } from '@prometeus/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StatementsCommonRowEnum } from '../../constants/charts/visuals.constants';
import { DOLLAR_ST, NA_LABEL } from '../../constants/general.constants';
import { TimeframeEnum } from '../../models/filters.model';
import { IStatementsConfig, StatementsMap } from '../../models/visuals.model';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import { formBorder } from '../../ui/functions';
import CardLoadingComponent from '../card-loading.component';
import CardNoDataComponent from '../card-no-data.component';
import CardComponent, { getCardStyles } from '../card.component';
import { getTableHeadStyles } from '../table/table-head.component';
import {
  getTableStyles,
  isNullUndefinedEmptyStr,
} from '../table/table.constants';

const TAB = 1;

type strnumnull = string | number | null;

const getStyles = (palette: Palette) => ({
  /* Statements */
  statementMinWidth: {
    minWidth: '20rem',
    fontSize: '1rem',
    paddingLeft: `${TAB}rem`,
    paddingRight: '0.5rem',
  },
  bold: {
    fontWeight: 'bold !important',
  },
  bolder: {
    fontWeight: 900,
  },
  valueLargerFont: {
    fontSize: '0.92rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  smallFont: {
    fontSize: '0.8rem',
  },
  singleTab: {
    paddingLeft: `${TAB * 2}rem !important`,
  },
  doubleTab: {
    paddingLeft: `${TAB * 3}rem !important`,
  },
  tripleTab: {
    paddingLeft: `${TAB * 4}rem !important`,
  },
  fiscalYear: {
    color: COMMON_COLORS.contrastPrimaryText,
    fontSize: '1.2rem',
  },
  quarterlyFiscalYear: {
    fontSize: '1.1rem',
  },
  container: {
    position: 'relative',
    height: '99%',
    overflow: 'hidden',
    borderRadius: '0.5rem',
  },
  paper: {
    overflow: 'auto',
    height: '100%',
    // ...maskBorderShadow(palette),
    border: '#494949 solid 0.1rem',
    backgroundColor: palette.background.paper,
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
  },
  maskedBg: {
    '&hover': {
      backgroundColor:
        palette.mode === 'dark' ? '#353637 !important' : '#f6f9fc !important',
    },
  },
  bgPrimary: {
    backgroundColor: palette.background.default,
  },
  bgSecondary: {
    backgroundColor: palette.background.paper,
  },
  primaryBg: {
    backgroundColor: palette.primary.main,
  },
  inheritBg: {
    backgroundColor: 'inherit',
  },
  borderRight: {
    borderRight: formBorder(palette),
  },
  accentColor: {
    color: COMMON_COLORS.contrastPrimaryText,
  },
  fontSize09: {
    fontSize: '0.9rem',
  },
  fontSize12: {
    fontSize: '1.2rem',
  },
  borderTop: {
    borderTop: formBorder(palette),
    borderTopStyle: 'double',
    borderTopWidth: '0.3rem',
  },

  /* Table */
  tableContainer: {
    overflowX: 'initial',
    backgroundColor: palette.background.paper,
  },
  tableRow: {
    '&:hover .MuiTableCell-root': {
      backgroundColor: `#414242 !important`,
      // backgroundColor: `${palette.text.secondary}${perc2HexOpacity(
      //   20
      // )} !important`,
    },
  },
  zIndex1: {
    zIndex: '1 !important',
  },
  zIndex2: {
    zIndex: '2 !important',
  },
  dataPaddingRight: {
    paddingRight: '2rem !important',
  },
});

interface Props {
  titleLabel?: string;
  hasData: boolean;
  updating?: boolean;
  error?: string;
  warningIcon?: boolean;

  /* Statements */
  statements: StatementsMap;
  statementsConfig: IStatementsConfig[];
  /* Card */
  cardStyle?: CSSProperties;
  containerHeight?: boolean;
  containerWidth?: boolean;
  /* Icons */
  noInfoIcon?: boolean;
}

const StatementsTableComponent = (props: Props) => {
  const {
    titleLabel,
    hasData,
    updating,
    error,
    warningIcon,
    /* Statements Config */
    statements: _statements,
    statementsConfig,
    /* Card */
    containerHeight,
    containerWidth,
    /* Card Icons */
    noInfoIcon,
  } = props;

  const [statements, setStatements] = useState<StatementsMap>(_statements);

  const theme = useTheme();
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currentCompanyCurrencySt = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt
  );

  const headStyles = getTableHeadStyles(theme.palette);
  const tableStyles = getTableStyles(theme.palette);
  const styles = getStyles(theme.palette);
  const cardStyles = getCardStyles(theme.palette);

  const formatValue = (
    value: strnumnull,
    index: number,
    currencyRates?: strnumnull[]
  ) => {
    if (isNullUndefinedEmptyStr(value)) {
      return NA_LABEL;
    }

    let formatted = numberFormatter(value as string | number);

    if (useDollars) {
      if (
        !!currencyRates &&
        currencyRates?.length >= index &&
        !!currencyRates[index]
      ) {
        formatted = numberFormatter(
          (value as number) / (currencyRates[index] as number)
        );
      }
    }

    return formatted;
  };

  const getCurrencyString = (): string => {
    return `( in ${useDollars ? DOLLAR_ST : currentCompanyCurrencySt} )`;
  };

  useEffect(() => {
    /* Get allowed indeces in FiscalYear range */
    const allowedIndexes = new Set<number>();
    _statements
      ?.get(StatementsCommonRowEnum.FISCAL_YEAR)
      ?.forEach((year, index: number) => {
        if (Number(year) >= fiscalYear[0] && Number(year) <= fiscalYear[1]) {
          allowedIndexes.add(index);
        }
      });

    /* New statements map */
    const newStatementsMap = new Map<string, (number | string | null)[]>();

    /* Fiscal Year */
    newStatementsMap.set(
      StatementsCommonRowEnum.FISCAL_YEAR,
      _statements
        ?.get(StatementsCommonRowEnum.FISCAL_YEAR)
        ?.filter(
          (year) =>
            Number(year) >= fiscalYear[0] && Number(year) <= fiscalYear[1]
        ) as any
    );

    /* Common Rows */
    [
      StatementsCommonRowEnum.FISCAL_PERIOD,
      StatementsCommonRowEnum.CURRENCY_RATE,
      StatementsCommonRowEnum.CURRENCY,
      StatementsCommonRowEnum.REPORT_DATE,
    ].forEach((config: StatementsCommonRowEnum) => {
      newStatementsMap.set(
        config,
        _statements
          ?.get(config)
          ?.filter((_, index) => allowedIndexes.has(index)) as any
      );
    });

    /* Update rows */
    statementsConfig.forEach((config: IStatementsConfig) => {
      newStatementsMap.set(
        config.column,
        _statements
          ?.get(config.column)
          ?.filter((_, index: number) => allowedIndexes.has(index)) as any
      );
    });

    /* Update statements */
    setStatements(newStatementsMap);
  }, [fiscalYear, _statements, statementsConfig]);

  return (
    <Box sx={cardStyles.nullifyCard}>
      <CardComponent
        titleLabel={`${titleLabel} ${getCurrencyString()}`}
        hideTitleLabel={false}
        hasMoreInfo={!noInfoIcon}
        showLoading={hasData && updating}
        exportData={{ statements, statementsConfig }}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        menuOnlyExport={true}
      >
        {hasData ? (
          <Box sx={styles.container}>
            <>
              {error ? (
                <CardNoDataComponent
                  message={error}
                  warningIcon={warningIcon}
                />
              ) : (
                <Paper sx={styles.paper}>
                  <TableContainer sx={styles.tableContainer}>
                    <Table
                      stickyHeader
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        {/* Fiscal Year */}
                        <TableRow sx={headStyles.tableRow}>
                          <TableCell
                            sx={{
                              ...headStyles.tableHeadCell,
                              ...styles.stickyCell,
                              ...styles.bold,
                              ...styles.borderRight,
                              ...styles.accentColor,
                              ...styles.fontSize12,
                              ...headStyles.stickyTable,
                              ...styles.zIndex2,
                            }}
                            key="Filler Fiscal Year"
                            padding="normal"
                          >
                            Fiscal Year
                            <Box sx={styles.fontSize09}>Report Date</Box>
                          </TableCell>
                          {statements
                            ?.get(StatementsCommonRowEnum.FISCAL_YEAR)
                            ?.map((value, index: number) => (
                              <TableCell
                                sx={{
                                  ...headStyles.tableHeadCell,
                                  ...styles.bold,
                                  ...styles.fiscalYear,
                                  ...styles.primaryBg,
                                  ...styles.zIndex1,
                                  ...(activeQYFilter ===
                                    TimeframeEnum.QUARTERLY &&
                                  !!statements.get(
                                    StatementsCommonRowEnum.FISCAL_PERIOD
                                  )
                                    ? styles.quarterlyFiscalYear
                                    : {}),
                                  [`& .${tableCellClasses.stickyHeader}`]: {
                                    ...headStyles.stickyTable,
                                  },
                                }}
                                key={`FiscalYear-${value}-${index}`}
                                align="center"
                                padding="normal"
                              >
                                {activeQYFilter === TimeframeEnum.QUARTERLY &&
                                !!statements.get(
                                  StatementsCommonRowEnum.FISCAL_PERIOD
                                )
                                  ? `${
                                      (
                                        statements.get(
                                          StatementsCommonRowEnum.FISCAL_PERIOD
                                        ) as number[]
                                      )[index]
                                    } ${value}`
                                  : value}
                                <Box
                                  sx={{
                                    ...styles.fontSize09,
                                    ...styles.italic,
                                  }}
                                >
                                  {!!statements.get(
                                    StatementsCommonRowEnum.REPORT_DATE
                                  ) &&
                                    (
                                      statements.get(
                                        StatementsCommonRowEnum.REPORT_DATE
                                      ) as number[]
                                    )[index]}
                                </Box>
                              </TableCell>
                            ))}
                        </TableRow>
                        {/* Report Date */}
                      </TableHead>
                      <TableBody>
                        {statementsConfig?.map(
                          (row: IStatementsConfig, index: number) => {
                            return (
                              <TableRow
                                tabIndex={-1}
                                key={`${row.label}-${index}`}
                                sx={{
                                  ...tableStyles.tableRow,
                                  ...styles.tableRow,
                                  ...styles.maskedBg,
                                  ...styles.bgPrimary,
                                }}
                              >
                                <TableCell
                                  padding="normal"
                                  align="left"
                                  sx={{
                                    ...tableStyles.tableCellRoot,
                                    ...styles.statementMinWidth,
                                    ...styles.stickyCell,
                                    ...styles.borderRight,
                                    ...styles.inheritBg,
                                    ...(row.hierarchy === 1 ? styles.bold : {}),
                                    ...(row.hierarchy === 1 && !!index
                                      ? styles.borderTop
                                      : {}),
                                    ...([2, 5].includes(row.hierarchy)
                                      ? styles.singleTab
                                      : {}),
                                    ...([3, 4].includes(row.hierarchy)
                                      ? styles.italic
                                      : {}),
                                    ...(row.hierarchy === 3
                                      ? styles.doubleTab
                                      : {}),
                                    ...(row.hierarchy === 4
                                      ? styles.smallFont
                                      : {}),
                                    ...(row.hierarchy === 4
                                      ? styles.tripleTab
                                      : {}),
                                  }}
                                >
                                  {row.label}
                                </TableCell>
                                {statements
                                  .get(row.column)
                                  ?.map((e, i: number) => (
                                    <TableCell
                                      padding="normal"
                                      sx={{
                                        ...tableStyles.tableCellRoot,
                                        ...styles.dataPaddingRight,
                                        ...styles.inheritBg,
                                        ...(row.hierarchy === 1
                                          ? styles.bold
                                          : {}),
                                        ...(row.hierarchy === 1
                                          ? styles.valueLargerFont
                                          : {}),
                                        ...(row.hierarchy === 1 && !!index
                                          ? styles.borderTop
                                          : {}),
                                      }}
                                      align="right"
                                      key={`${row.label}-${e}-${i}`}
                                    >
                                      {formatValue(
                                        e,
                                        i,
                                        statements?.get(
                                          StatementsCommonRowEnum.CURRENCY_RATE
                                        )
                                      )}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </>
          </Box>
        ) : (
          <CardLoadingComponent />
        )}
      </CardComponent>
    </Box>
  );
};

export default StatementsTableComponent;
