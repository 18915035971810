import {
  ACTIVATE_FREE_TRIAL_URL,
  FETCH_LICENCES_HISTORY_URL,
  IUserData,
  IUserInformation,
  IUserRegistrationInformation,
  REVOKE_USER_LICENCE_URL,
  SUBMIT_USER_REGISTRATION_INFORMATION_URL,
  Token,
  UPDATE_USER_REGISTRATION_INFORMATION_URL,
  USERS_ADMIN_URL,
  USER_ADMIN_URL,
  USER_DUMP_URL,
  USER_LOAD_DUMP_URL,
} from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { DumpType } from '../models/general.model';
import { ILicencesHistory } from '../models/user.model';
import { axiosInstance } from '../utils/axios-interceptor';

export const submitRegistrationInformationService = (
  information: IUserRegistrationInformation
): Promise<AxiosResponse<IUserInformation>> =>
  axiosInstance.patch(SUBMIT_USER_REGISTRATION_INFORMATION_URL, information);

export const updateRegistrationInformationService = (
  information: IUserInformation
): Promise<AxiosResponse<IUserInformation>> =>
  axiosInstance.patch(UPDATE_USER_REGISTRATION_INFORMATION_URL, information);

export const fetchLicencesHistoryService = (): Promise<
  AxiosResponse<ILicencesHistory>
> => axiosInstance.get(FETCH_LICENCES_HISTORY_URL);

export const activateFreeTrialService = (): Promise<AxiosResponse<Token>> =>
  axiosInstance.patch(ACTIVATE_FREE_TRIAL_URL);

/***************************************
 *                 Admin               *
 ***************************************/
export const fetchUsersService = (): Promise<AxiosResponse<IUserData[]>> =>
  axiosInstance.get(USERS_ADMIN_URL);

export const fetchUserService = (
  userId: string
): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.get(`${USER_ADMIN_URL}/${userId}`);

export const createUserService = (
  userData: IUserData
): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.put(USER_ADMIN_URL, userData);

export const updateUserService = (
  userData: IUserData
): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.patch(`${USER_ADMIN_URL}/${userData.userId}`, userData);

export const deleteUserService = (
  userId: string
): Promise<AxiosResponse<IUserData[]>> =>
  axiosInstance.delete(`${USER_ADMIN_URL}/${userId}`);

export const userDumpDBService = (
  mandatoryFields: string[]
): Promise<AxiosResponse<DumpType>> =>
  axiosInstance.post(USER_DUMP_URL, { mandatoryFields });

export const userLoadDumpDBService = (
  dump: any[],
  deleteExisting?: boolean
): Promise<AxiosResponse> =>
  axiosInstance.post(USER_LOAD_DUMP_URL, { dump, deleteExisting });

export const revokeUserLicenceService = (
  userId: string
): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.post(REVOKE_USER_LICENCE_URL, {
    userId,
  });
