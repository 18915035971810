import { createTheme, PaletteMode, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'chartjs-adapter-moment';
import React, { ReactElement } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './App.scss';
import Store from './store/store';
import { ColorModeContext } from './ui/ColorModeContext';
import { getDesignTokens } from './ui/theme';

const Providers = ({ children }: { children: ReactElement }) => {
  /* Theme */
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const colorTheme = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light'
        );
      },
      colorTheme: mode,
    }),
    [mode]
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <GoogleOAuthProvider
      clientId={process.env?.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ReduxProvider store={Store}>
          <ColorModeContext.Provider value={colorTheme}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
          </ColorModeContext.Provider>
        </ReduxProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
};

export default Providers;
