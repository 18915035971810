import {
  IStatementsData,
  IVisualChart,
  IVisualPayload,
} from '../../../models/visuals.model';

export interface IVisualsState {
  cashData?: IVisualChart;
  cashDataUpdating?: boolean;
  netChangeInCashData?: IVisualChart;
  netChangeInCashDataUpdating?: boolean;
  cashSourcesData?: IVisualChart;
  cashSourcesDataUpdating?: boolean;
  cashFromOperatingActivitiesData?: IVisualChart;
  cashFromOperatingActivitiesDataUpdating?: boolean;
  cashFromInvestingActivitiesData?: IVisualChart;
  cashFromInvestingActivitiesDataUpdating?: boolean;
  cashFromFinancingActivitiesData?: IVisualChart;
  cashFromFinancingActivitiesDataUpdating?: boolean;

  /* Statements */
  statementsData?: IStatementsData;
  statementsDataUpdating?: boolean;
}

/* CASH_FLOW_ANALYSIS_UPDATE */
export const CASH_FLOW_ANALYSIS_UPDATE = 'CASH_FLOW_ANALYSIS_UPDATE';
export interface ICashFlowAnalysisUpdate {
  type: typeof CASH_FLOW_ANALYSIS_UPDATE;
  payload: string[];
}

/* CASH_YEARLY_DATA */
export const CASH_YEARLY_DATA = 'CASH_YEARLY_DATA';
export interface CashYearlyDataI {
  type: typeof CASH_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_QUARTERLY_DATA */
export const CASH_QUARTERLY_DATA = 'CASH_QUARTERLY_DATA';
export interface CashQuarterlyDataI {
  type: typeof CASH_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* NET_CHANGE_IN_CASH_YEARLY_DATA */
export const NET_CHANGE_IN_CASH_YEARLY_DATA = 'NET_CHANGE_IN_CASH_YEARLY_DATA';
export interface NetChangeInCashYearlyDataI {
  type: typeof NET_CHANGE_IN_CASH_YEARLY_DATA;
  payload: IVisualPayload;
}

/* NET_CHANGE_IN_CASH_QUARTERLY_DATA */
export const NET_CHANGE_IN_CASH_QUARTERLY_DATA =
  'NET_CHANGE_IN_CASH_QUARTERLY_DATA';
export interface NetChangeInCashQuarterlyDataI {
  type: typeof NET_CHANGE_IN_CASH_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CASH_SOURCES_YEARLY_DATA */
export const CASH_SOURCES_YEARLY_DATA = 'CASH_SOURCES_YEARLY_DATA';
export interface CashSourcesYearlyDataI {
  type: typeof CASH_SOURCES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_SOURCES_QUARTERLY_DATA */
export const CASH_SOURCES_QUARTERLY_DATA = 'CASH_SOURCES_QUARTERLY_DATA';
export interface CashSourcesQuarterlyDataI {
  type: typeof CASH_SOURCES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA */
export const CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA =
  'CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA';
export interface CashFromOperatingActivitiesYearlyDataI {
  type: typeof CASH_FROM_OPERATING_ACTIVITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA */
export const CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA =
  'CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA';
export interface CashFromOperatingActivitiesQuarterlyDataI {
  type: typeof CASH_FROM_OPERATING_ACTIVITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA */
export const CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA =
  'CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA';
export interface CashFromInvestingActivitiesYearlyDataI {
  type: typeof CASH_FROM_INVESTING_ACTIVITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA */
export const CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA =
  'CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA';
export interface CashFromInvestingActivitiesQuarterlyDataI {
  type: typeof CASH_FROM_INVESTING_ACTIVITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA */
export const CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA =
  'CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA';
export interface CashFromFinancingActivitiesYearlyDataI {
  type: typeof CASH_FROM_FINANCING_ACTIVITIES_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA */
export const CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA =
  'CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA';
export interface CashFromFinancingActivitiesQuarterlyDataI {
  type: typeof CASH_FROM_FINANCING_ACTIVITIES_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *              Statements             *
 ***************************************/
/* CF_STATEMENTS_YEARLY_DATA */
export const CF_STATEMENTS_YEARLY_DATA = 'CF_STATEMENTS_YEARLY_DATA';
export interface CSStatementsYearlyDataI {
  type: typeof CF_STATEMENTS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* CF_STATEMENTS_QUARTERLY_DATA */
export const CF_STATEMENTS_QUARTERLY_DATA = 'CF_STATEMENTS_QUARTERLY_DATA';
export interface CSStatementsQuarterlyDataI {
  type: typeof CF_STATEMENTS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

export type DispatchActionTypes =
  | ICashFlowAnalysisUpdate
  | CashYearlyDataI
  | CashQuarterlyDataI
  | NetChangeInCashYearlyDataI
  | NetChangeInCashQuarterlyDataI
  | CashSourcesYearlyDataI
  | CashSourcesQuarterlyDataI
  | CashFromOperatingActivitiesYearlyDataI
  | CashFromOperatingActivitiesQuarterlyDataI
  | CashFromInvestingActivitiesYearlyDataI
  | CashFromInvestingActivitiesQuarterlyDataI
  | CashFromFinancingActivitiesYearlyDataI
  | CashFromFinancingActivitiesQuarterlyDataI
  | CSStatementsYearlyDataI
  | CSStatementsQuarterlyDataI;
