import AFiltersComponent from '../../components/filters/aggregated-filters/a-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
import CountryAnalysisTab from './tabs/country-analysis.tab';
import IndustryTab from './tabs/industry-analysis.tab';
import SectorTab from './tabs/sector-analysis.tab';

export enum SectorAnalysisRouteEnum {
  COUNTRY = 'country',
  SECTOR = 'sector',
  INDUSTRY = 'industry',
}

const tabs: ITab[] = [
  {
    label: 'Country Analysis',
    route: SectorAnalysisRouteEnum.COUNTRY,
    component: CountryAnalysisTab,
  },
  {
    label: 'Sector Analysis',
    route: SectorAnalysisRouteEnum.SECTOR,
    component: SectorTab,
  },
  {
    label: 'Industry Analysis',
    route: SectorAnalysisRouteEnum.INDUSTRY,
    component: IndustryTab,
  },
];

const SectorAnalysisPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.SECTOR_ANALYSIS}
      tabs={tabs}
      filtersComponent={AFiltersComponent}
      hideCompanyHeader={true}
    />
  );
};

export default SectorAnalysisPage;
