import { Box, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScreenerFiltersComponent from '../../components/filters/aggregated-filters/screener-filters.component';
import LoadingComponent from '../../components/loading.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
import { applyFilters } from '../../store/actions/screener/structure.action';
import { RootState } from '../../store/reducers/root.reducer';
import { getTabStyles } from '../../styles/tab.styles';

import FinancialStrengthTab from './tabs/financial-strength.tab';
import IncomeStatementTab from './tabs/income-statement.tab';
import ManagementEffectivenessTab from './tabs/management-effectivenes.tab';
import StockFiltersTab from './tabs/stock.tab';

export enum ScreenerRouteEnum {
  STOCK = 'stock',
  INCOME_STATEMENT = 'income-statement',
  MANAGEMENT_EFFECTIVENESS = 'management-effectiveness',
  FINANCIAL_STRENGTH = 'financial-strength',
}

export const SCREENER_ANALYSIS_TAB: ITab[] = [
  {
    label: 'Stock',
    route: ScreenerRouteEnum.STOCK,
    component: StockFiltersTab,
  },
  {
    label: 'Income Statement',
    route: ScreenerRouteEnum.INCOME_STATEMENT,
    component: IncomeStatementTab,
  },
  {
    label: 'Management Effectiveness',
    route: ScreenerRouteEnum.MANAGEMENT_EFFECTIVENESS,
    component: ManagementEffectivenessTab,
  },
  {
    label: 'Financial Strenght',
    route: ScreenerRouteEnum.FINANCIAL_STRENGTH,
    component: FinancialStrengthTab,
  },
];

const ScreenerPage = () => {
  const theme = useTheme();
  const classes = getTabStyles(theme.palette);

  const history = useHistory();

  const dispatch = useDispatch();
  const screenerStructure = useSelector(
    (state: RootState) => state.screener.structure.screenerStructure
  );
  const industryCompaniesLoading = useSelector(
    (state: RootState) => state.screener.structure.industryCompaniesLoading
  );
  const fetchSectorsDataLoading = useSelector(
    (state: RootState) => state.screener.structure.fetchSectorsDataLoading
  );
  const sendScreenerFilterLoading = useSelector(
    (state: RootState) => state.screener.structure.sendScreenerFilterLoading
  );
  const showScreenerResultLoading = useSelector(
    (state: RootState) => state.screener.structure.showScreenerResultLoading
  );

  useEffect(() => {
    dispatch(applyFilters());
  }, [dispatch]);

  return (
    <Box sx={classes.root}>
      {fetchSectorsDataLoading && <LoadingComponent />}
      {(industryCompaniesLoading ||
        sendScreenerFilterLoading ||
        showScreenerResultLoading) && <LoadingComponent transparent={true} />}
      {!!screenerStructure && (
        <PageSkeletonComponent
          pageRoute={RouteEnum.SCREENER}
          tabs={SCREENER_ANALYSIS_TAB}
          hideCompanyHeader={true}
          filtersComponent={ScreenerFiltersComponent}
          history={history}
        />
      )}
    </Box>
  );
};

export default ScreenerPage;
