import {
  ICompanyMinimal,
  ICompanyPeerData,
  ISectorData,
} from '@prometeus/common';
import { Map } from 'immutable';
import { ISectorsPayload } from '../constants/companies.constants';
import { ISecIndCty, ISectorIndustry } from './screener/structure.model';

export enum PeerGroupEnum {
  REGIONAL = 'regional',
  INTERNATIONAL = 'international',
  SUGGESTED = 'suggested',
  CUSTOM = 'custom',
}

export interface IPeerGroupState {
  peerGroupType: PeerGroupEnum;
  peers: Map<string, ICompanyMinimal>;
  keepPeers: Map<string, ICompanyMinimal>;
  peerGroupSearchResult: ICompanyPeerData[];
  peerGroupSearchResultTotal?: number;
  /* Filters */
  sectorData?: ISectorData[];
  selectedCountries: ISecIndCty[];
  sectors: ISectorIndustry[];
  selectedIndustries: string[];
  /* API */
  loading: boolean;
  checkAllLoading: boolean;
  error?: string;
}

export interface IReturnedCompanies {
  companies: number;
}

export interface ISearchPeerCompaniesData {
  countries: string[];
  industries: string[];
  keyword: string;
}

/* SET_PEERS_PEER_GROUP */
export const SET_PEERS_PEER_GROUP = 'SET_PEERS_PEER_GROUP';
export interface ISetPeersPeerGroup {
  type: typeof SET_PEERS_PEER_GROUP;
  payload: ICompanyMinimal[];
}

/* SET_PEER_GROUP_TYPE */
export const SET_PEER_GROUP_TYPE = 'SET_PEER_GROUP_TYPE';
export interface ISetPeerGroupType {
  type: typeof SET_PEER_GROUP_TYPE;
  payload: PeerGroupEnum;
}

/* CHECK_COMPANY */
export const CHECK_COMPANY = 'CHECK_COMPANY';
export interface ICheckCompany {
  type: typeof CHECK_COMPANY;
  payload: ICompanyMinimal;
}

/* GET_TOTAL_PEERS */
export const GET_TOTAL_PEERS = 'GET_TOTAL_PEERS';
export interface IGetTotalPeers {
  type: typeof GET_TOTAL_PEERS;
}

/* CHECK_ALL_COMPANIES */
export const CHECK_ALL_COMPANIES = 'CHECK_ALL_COMPANIES';
export interface ICheckAllCompanies {
  type: typeof CHECK_ALL_COMPANIES;
  payload: {
    currentTickerId: string;
    peers: ICompanyMinimal[];
  };
}

/* GET_TOTAL_PEERS_FAIL */
export const GET_TOTAL_PEERS_FAIL = 'GET_TOTAL_PEERS_FAIL';
export interface IGetTotalPeersFail {
  type: typeof GET_TOTAL_PEERS_FAIL;
  payload: string;
}

export type GetTotalPeerCompaniesDispachTypes =
  | IGetTotalPeers
  | ICheckAllCompanies
  | IGetTotalPeersFail;

/* REMOVE_COMPANY */
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export interface IRemoveCompany {
  type: typeof REMOVE_COMPANY;
  payload: string;
}

/* REMOVE_ALL_COMPANIES */
export const REMOVE_ALL_COMPANIES = 'REMOVE_ALL_COMPANIES';
export interface IRemoveAllCompanies {
  type: typeof REMOVE_ALL_COMPANIES;
  payload: string;
}

/* SEARCH_PEER_COMPANIES */
export const SEARCH_PEER_COMPANIES = 'SEARCH_PEER_COMPANIES';
export const SEARCH_PEER_COMPANIES_SUCCESS = 'SEARCH_PEER_COMPANIES_SUCCESS';
export const SEARCH_PEER_COMPANIES_FAIL = 'SEARCH_PEER_COMPANIES_FAIL';

export interface ISearchPeerCompanies {
  type: typeof SEARCH_PEER_COMPANIES;
}

export interface ISearchPeerCompaniesFail {
  type: typeof SEARCH_PEER_COMPANIES_FAIL;
  payload: string;
}

export interface ISearchPeerCompaniesResult {
  companies: ICompanyPeerData[];
  total: number;
}

export interface ISearchPeerCompaniesSuccess {
  type: typeof SEARCH_PEER_COMPANIES_SUCCESS;
  payload: ISearchPeerCompaniesResult;
}

export type SearchPeerCompaniesDispachTypes =
  | ISearchPeerCompanies
  | ISearchPeerCompaniesFail
  | ISearchPeerCompaniesSuccess;

/* GET_COMPANIES_REGIONAL */
export const GET_COMPANIES_REGIONAL = 'GET_COMPANIES_REGIONAL';
export const GET_COMPANIES_REGIONAL_SUCCESS = 'GET_COMPANIES_REGIONAL_SUCCESS';
export const GET_COMPANIES_REGIONAL_FAIL = 'GET_COMPANIES_REGIONAL_FAIL';

export interface IGetCompaniesRegional {
  type: typeof GET_COMPANIES_REGIONAL;
}

export interface IGetCompaniesRegionalFail {
  type: typeof GET_COMPANIES_REGIONAL_FAIL;
  payload: string;
}

export type GetCompaniesRegionalDispachTypes =
  | IGetCompaniesRegional
  | IGetCompaniesRegionalFail
  | ISetPeersPeerGroup;

/* GET_COMPANIES_INTERNATIONAL */
export const GET_COMPANIES_INTERNATIONAL = 'GET_COMPANIES_INTERNATIONAL';
export const GET_COMPANIES_INTERNATIONAL_SUCCESS =
  'GET_COMPANIES_INTERNATIONAL_SUCCESS';
export const GET_COMPANIES_INTERNATIONAL_FAIL =
  'GET_COMPANIES_INTERNATIONAL_FAIL';

export interface IGetCompaniesInternational {
  type: typeof GET_COMPANIES_INTERNATIONAL;
}

export interface IGetCompaniesInternationalFail {
  type: typeof GET_COMPANIES_INTERNATIONAL_FAIL;
  payload: string;
}

export type GetCompaniesInternationalDispachTypes =
  | IGetCompaniesInternational
  | IGetCompaniesInternationalFail
  | ISetPeersPeerGroup;

/* FETCH_PEER_GROUP_SECTORS_DATA */
export const FETCH_PEER_GROUP_SECTORS_DATA = 'FETCH_PEER_GROUP_SECTORS_DATA';
export const FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS =
  'FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS';
export const FETCH_PEER_GROUP_SECTORS_DATA_FAIL =
  'FETCH_PEER_GROUP_SECTORS_DATA_FAIL';

export interface FetchPeerGroupSectorsDataI {
  type: typeof FETCH_PEER_GROUP_SECTORS_DATA;
}

export interface FetchPeerGroupSectorsDataFailI {
  type: typeof FETCH_PEER_GROUP_SECTORS_DATA_FAIL;
  payload: string;
}

export interface FetchPeerGroupSectorsDataSuccessI {
  type: typeof FETCH_PEER_GROUP_SECTORS_DATA_SUCCESS;
  payload: ISectorsPayload;
}

export type FetchPeerGroupSectorsDataDispachTypes =
  | FetchPeerGroupSectorsDataI
  | FetchPeerGroupSectorsDataFailI
  | FetchPeerGroupSectorsDataSuccessI;

/* INITIALIZE_SECTOR_DATA */
export const INITIALIZE_SECTOR_DATA = 'INITIALIZE_SECTOR_DATA';
export interface IInitializeSectorData {
  type: typeof INITIALIZE_SECTOR_DATA;
}

/* SELECT_SECTORS_PEER_GROUP */
export const SELECT_SECTORS_PEER_GROUP = 'SELECT_SECTORS_PEER_GROUP';
export interface ISelectSectorsPeerGroup {
  type: typeof SELECT_SECTORS_PEER_GROUP;
  payload: number;
}

/* SELECT_INDUSTRIES_PEER_GROUP */
export const SELECT_INDUSTRIES_PEER_GROUP = 'SELECT_INDUSTRIES_PEER_GROUP';
export interface ISelectIndustriesPeerGroup {
  type: typeof SELECT_INDUSTRIES_PEER_GROUP;
  payload: {
    sector: number;
    industry: number;
  };
}

/* SELECT_COUNTRY_IDS_PEER_GROUP */
export const SELECT_COUNTRY_IDS_PEER_GROUP = 'SELECT_COUNTRY_IDS_PEER_GROUP';
export interface ISelectCountryIdsPeerGroup {
  type: typeof SELECT_COUNTRY_IDS_PEER_GROUP;
  payload: number;
}

/* RESET_PEER_GROUP_SEARCH_RESULT */
export const RESET_PEER_GROUP_SEARCH_RESULT = 'RESET_PEER_GROUP_SEARCH_RESULT';
export interface IResetPeerGroupSearchResult {
  type: typeof RESET_PEER_GROUP_SEARCH_RESULT;
  payload: number;
}

/* RESET_PEERS_CHANGES */
export const RESET_PEERS_CHANGES = 'RESET_PEERS_CHANGES';
export interface IResetPeerChanges {
  type: typeof RESET_PEERS_CHANGES;
}

/* KEEP_PEERS */
export const KEEP_PEERS = 'KEEP_PEERS';
export interface IKeepPeers {
  type: typeof KEEP_PEERS;
}

/* DispatchActionTypes */
export type DispatchActionTypes =
  | ISetPeerGroupType
  | ICheckCompany
  | GetTotalPeerCompaniesDispachTypes
  | IRemoveCompany
  | SearchPeerCompaniesDispachTypes
  | FetchPeerGroupSectorsDataDispachTypes
  | IInitializeSectorData
  | ISelectSectorsPeerGroup
  | ISelectIndustriesPeerGroup
  | ISelectCountryIdsPeerGroup
  | IResetPeerGroupSearchResult
  | IRemoveAllCompanies
  | GetCompaniesRegionalDispachTypes
  | GetCompaniesInternationalDispachTypes
  | IResetPeerChanges
  | IKeepPeers
  | ISetPeersPeerGroup;
