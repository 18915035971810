import { ICompanyMinimal, ICompanyPeerData } from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import * as tableUtils from '../../components/table/table.constants';
import { SET_PEERS } from '../../models/filters.model';
import {
  CHECK_ALL_COMPANIES,
  CHECK_COMPANY,
  GET_COMPANIES_INTERNATIONAL,
  GET_COMPANIES_INTERNATIONAL_FAIL,
  GET_COMPANIES_REGIONAL,
  GET_COMPANIES_REGIONAL_FAIL,
  GET_TOTAL_PEERS,
  GET_TOTAL_PEERS_FAIL,
  GetTotalPeerCompaniesDispachTypes,
  INITIALIZE_SECTOR_DATA,
  ISearchPeerCompaniesResult,
  ISelectCountryIdsPeerGroup,
  ISelectIndustriesPeerGroup,
  ISelectSectorsPeerGroup,
  KEEP_PEERS,
  PeerGroupEnum,
  REMOVE_ALL_COMPANIES,
  REMOVE_COMPANY,
  RESET_PEERS_CHANGES,
  RESET_PEER_GROUP_SEARCH_RESULT,
  SEARCH_PEER_COMPANIES,
  SEARCH_PEER_COMPANIES_FAIL,
  SEARCH_PEER_COMPANIES_SUCCESS,
  SELECT_COUNTRY_IDS_PEER_GROUP,
  SELECT_INDUSTRIES_PEER_GROUP,
  SELECT_SECTORS_PEER_GROUP,
  SET_PEERS_PEER_GROUP,
  SET_PEER_GROUP_TYPE,
  SearchPeerCompaniesDispachTypes,
} from '../../models/peer-group.model';
import {
  getAllPeersService,
  getCompaniesInternationalService,
  getCompaniesRegionalService,
  searchPeerCompaniesService,
} from '../../services/peer-group.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';

export const setPeerGroupType = (type: PeerGroupEnum) => ({
  type: SET_PEER_GROUP_TYPE,
  payload: type,
});

export const checkCompany = (company: ICompanyMinimal) => ({
  type: CHECK_COMPANY,
  payload: company,
});

export const checkAllCompanies =
  (
    currentTickerId: string,
    keyword: string,
    countries: string[],
    industries: string[]
  ) =>
  (dispatch: Dispatch<GetTotalPeerCompaniesDispachTypes>) => {
    actionTryCatchWrapper(dispatch, GET_TOTAL_PEERS_FAIL, async () => {
      dispatch({
        type: GET_TOTAL_PEERS,
      });

      const response: AxiosResponse<ICompanyMinimal[]> =
        await getAllPeersService(keyword, countries, industries);

      const peers = response?.data;
      dispatch({
        type: CHECK_ALL_COMPANIES,
        payload: {
          currentTickerId,
          peers,
        },
      });
    });
  };

export const removeCompany = (tickerId: string) => ({
  type: REMOVE_COMPANY,
  payload: tickerId,
});

export const removeAllCompanies = (currentTickerId: string) => ({
  type: REMOVE_ALL_COMPANIES,
  payload: currentTickerId,
});

export const searchPeerCompanies =
  (
    keyword: string,
    countries: string[],
    industries: string[],
    order: tableUtils.Order,
    orderBy: keyof ICompanyPeerData,
    page: number,
    rowsPerPage: number
  ) =>
  (dispatch: Dispatch<SearchPeerCompaniesDispachTypes>) => {
    actionTryCatchWrapper(dispatch, SEARCH_PEER_COMPANIES_FAIL, async () => {
      dispatch({
        type: SEARCH_PEER_COMPANIES,
      });

      const response: AxiosResponse<ISearchPeerCompaniesResult> =
        await searchPeerCompaniesService(
          keyword,
          countries,
          industries,
          order,
          orderBy,
          page,
          rowsPerPage
        );

      const { companies, total } = response?.data;
      dispatch({
        type: SEARCH_PEER_COMPANIES_SUCCESS,
        payload: {
          companies,
          total,
        },
      });
    });
  };

export const getCompaniesRegional =
  (countryId: string, industry: string) => (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(dispatch, GET_COMPANIES_REGIONAL_FAIL, async () => {
      dispatch({
        type: GET_COMPANIES_REGIONAL,
      });

      const response: AxiosResponse<ICompanyMinimal[]> =
        await getCompaniesRegionalService(countryId, industry);

      dispatch({
        type: SET_PEERS_PEER_GROUP,
        payload: response?.data,
      });
      dispatch({
        type: SET_PEERS,
        payload: {
          peers: response?.data.map((e: ICompanyMinimal) => e.tickerId),
          allowFetch: true,
        },
      });
    });
  };

export const getCompaniesInternational =
  (industry: string) => (dispatch: Dispatch<any>) => {
    actionTryCatchWrapper(
      dispatch,
      GET_COMPANIES_INTERNATIONAL_FAIL,
      async () => {
        dispatch({
          type: GET_COMPANIES_INTERNATIONAL,
        });

        const response: AxiosResponse<ICompanyMinimal[]> =
          await getCompaniesInternationalService(industry);

        dispatch({
          type: SET_PEERS_PEER_GROUP,
          payload: response?.data,
        });
        dispatch({
          type: SET_PEERS,
          payload: {
            peers: response?.data.map((e: ICompanyMinimal) => e.tickerId),
          },
        });
      }
    );
  };

export const initializeSectorData = () => ({
  type: INITIALIZE_SECTOR_DATA,
});

export const peerGroupSelectSectors = (
  optionIndex: number
): ISelectSectorsPeerGroup => ({
  type: SELECT_SECTORS_PEER_GROUP,
  payload: optionIndex,
});

export const peerGroupSelectIndustries = (
  sectorIndex: number,
  industryIndex: number
): ISelectIndustriesPeerGroup => ({
  type: SELECT_INDUSTRIES_PEER_GROUP,
  payload: {
    sector: sectorIndex,
    industry: industryIndex,
  },
});

export const peerGroupSelectCountries = (
  optionIndex: number
): ISelectCountryIdsPeerGroup => ({
  type: SELECT_COUNTRY_IDS_PEER_GROUP,
  payload: optionIndex,
});

export const resetSearchResult = () => ({
  type: RESET_PEER_GROUP_SEARCH_RESULT,
});

export const keepPeers = () => ({
  type: KEEP_PEERS,
});

export const resetPeersChanges = () => ({
  type: RESET_PEERS_CHANGES,
});
