import { Check, Close } from '@mui/icons-material';
import { Palette, alpha, useTheme } from '@mui/material';
import { ITableColumnData } from '@prometeus/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TableDialogUnion } from '../../models/modals.model';
import { setPeers } from '../../store/actions/filters.action';
import {
  keepPeers,
  resetPeersChanges,
} from '../../store/actions/peer-group.action';
import { setScreenerTableColumns } from '../../store/actions/screener/results.action';
import { RootState } from '../../store/reducers/root.reducer';
import { pxToRem } from '../../ui/functions';
import CustomDialogComponent from '../dialog.component';
import TooltipIconButton from '../tooltip-icon-button.component';
import PeerGroupDialogComponent from './peer-group/peer-group-dialog.component';
import ScreenerSelectTableColumnsComponent from './screener/screener-select-table-columns.component';
import ScreenerTableDialogComponent from './screener/screener-table-dialog.components';

type Props = {
  open: boolean;
  onCloseHandler: () => void;
  type: TableDialogUnion;
  parentHistory: RouteComponentProps['history'];
};

const getStyles = (palette: Palette) => ({
  confirmButton: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.7),
    },
    marginLeft: '0.5rem !important',
  },
  icons: {
    //  color: colors.icons,
  },
  pointer: {
    cursor: 'pointer',
  },
  screenerPaper: {
    margin: pxToRem(32),
    borderWidth: '0.3rem',
    backgroundColor: `${palette.background.paper} !important`,
    minHeight: '5rem',
  },
});

const ScreenerPeersDialogComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  const { type } = props;
  const dispatch = useDispatch();

  /* Selected Columns */
  const screenerColumnSetup = useSelector(
    (state: RootState) => state.screener.structure.screenerColumnSetup
  );
  const activeFilters = useSelector(
    (state: RootState) => state.screener.structure.activeFilters
  );
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const [selectedColumns, setSelectedColumns] = useState<boolean[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (type === 'screener') {
      setSelectedColumns(
        screenerColumnSetup.map(
          (e: ITableColumnData) => !!e.isDefault || activeFilters.has(e.item)
        )
      );
    }
  }, [type, screenerColumnSetup, activeFilters]);

  useEffect(() => {
    if (!openFilter) {
      dispatch(
        setScreenerTableColumns(
          screenerColumnSetup.filter(
            (_: ITableColumnData, index: number) => selectedColumns[index]
          )
        )
      );
    }
  }, [dispatch, screenerColumnSetup, selectedColumns, openFilter]);

  const peers = useSelector((state: RootState) => state.peerGroup.peers);

  // Keep track of peers when dialog opens --> Used when changes resetted
  useEffect(() => {
    if (type === 'peers') {
      dispatch(keepPeers());
    }
  }, [dispatch, type]);

  const confirmHandler = () => {
    dispatch(setPeers(Array.from(peers.keys())));
    props.onCloseHandler();
  };

  const closeHandler = () => {
    dispatch(resetPeersChanges());
    props.onCloseHandler();
  };

  return (
    <CustomDialogComponent
      open={props.open}
      onClose={props.onCloseHandler}
      maxWidth="xl"
      sx={
        type === 'screener'
          ? {
              paper: styles.screenerPaper,
            }
          : {}
      }
      dialogTitle={
        <>
          <div>{type === 'peers' ? 'Custom Peers' : 'Screened Companies'}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            {type === 'screener' && !!hasActiveLicence && (
              <ScreenerSelectTableColumnsComponent
                open={openFilter}
                setOpen={setOpenFilter}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
              />
            )}
            <div style={{ display: 'flex' }}>
              <TooltipIconButton
                icon={<Close />}
                tooltip="Close"
                onClick={closeHandler}
              />
              {type === 'peers' && (
                <TooltipIconButton
                  icon={<Check />}
                  tooltip="Confirm"
                  onClick={confirmHandler}
                  iconButtonStyle={styles.confirmButton}
                />
              )}
            </div>
          </div>
        </>
      }
      dialogContent={
        <>
          {type === 'peers' ? (
            <PeerGroupDialogComponent onCloseHandler={props.onCloseHandler} />
          ) : (
            <ScreenerTableDialogComponent parentHistory={props.parentHistory} />
          )}
        </>
      }
    />
  );
};

export default ScreenerPeersDialogComponent;
