import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { ISectorIndustry } from '../../../models/screener/structure.model';
import {
  GroupBySectorEnum,
  IOpenLargeChart,
} from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { setSectorGroupBy } from '../utils/visuals.actions';
import { CHART_OPTIONS } from '../utils/visuals.config';
import {
  createAChartDatasetsConfig,
  extractChartMetadata,
  getEmptyOptionMessage,
  makeDistributionTitle,
} from '../utils/visuals.constants';
import { ILabelValue } from '../utils/visuals.model';

const SectorAnalysisTab = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );

  /***************************************
   *             Charts Data             *
   ***************************************/
  const topSectorData = useSelector(
    (state: RootState) => state.visuals.sector.topSectorData
  );
  const topSectorDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.topSectorDataUpdating
  );
  const topDistributionSectorData = useSelector(
    (state: RootState) => state.visuals.sector.topDistributionSectorData
  );
  const topDistributionSectorDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.topDistributionSectorDataUpdating
  );
  const bottomSectorData = useSelector(
    (state: RootState) => state.visuals.sector.bottomSectorData
  );
  const bottomSectorDataUpdating = useSelector(
    (state: RootState) => state.visuals.sector.bottomSectorDataUpdating
  );
  const bottomDistributionSectorData = useSelector(
    (state: RootState) => state.visuals.sector.bottomDistributionSectorData
  );
  const bottomDistributionSectorDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.sector.bottomDistributionSectorDataUpdating
  );
  const topOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );
  const bottomOption = useSelector(
    (state: RootState) => state.visuals.sector.bottomOption
  );
  const sectorSectors = useSelector(
    (state: RootState) => state.visuals.sector.sectorSectors
  );
  const timeWindow = useSelector(
    (state: RootState) => state.visuals.sector.timeWindow
  );

  const [top, setTop] = useState<ChartData>();
  const [topDistribution, setTopDistribution] = useState<ChartData>();
  const [bottom, setBottom] = useState<ChartData>();
  const [bottomDistribution, setBottomDistribution] = useState<ChartData>();

  /* Top */
  const [topHasData, topHasLabels, topError] =
    extractChartMetadata(topSectorData);
  useEffect(() => {
    setTop({
      datasets: createAChartDatasetsConfig(topSectorData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [topSectorData, timeWindow, screenWidth]);

  /* Top Distribution */
  const [
    topDistributionHasData,
    topDistributionHasLabels,
    topDistributionError,
  ] = extractChartMetadata(topDistributionSectorData);
  useEffect(() => {
    setTopDistribution({
      labels: topDistributionSectorData?.labels,
      datasets: createAChartDatasetsConfig(
        topDistributionSectorData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [topDistributionSectorData, screenWidth]);

  /* Bottom */
  const [bottomHasData, bottomHasLabels, bottomError] =
    extractChartMetadata(bottomSectorData);
  useEffect(() => {
    setBottom({
      datasets: createAChartDatasetsConfig(bottomSectorData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [bottomSectorData, timeWindow, screenWidth]);

  /* Bottom Distribution */
  const [
    bottomDistributionHasData,
    bottomDistributionHasLabels,
    bottomDistributionError,
  ] = extractChartMetadata(bottomDistributionSectorData);
  useEffect(() => {
    setBottomDistribution({
      labels: bottomDistributionSectorData?.labels,
      datasets: createAChartDatasetsConfig(
        bottomDistributionSectorData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [bottomDistributionSectorData, screenWidth]);

  /***************************************
   *             Chart Titles            *
   ***************************************/
  const [topTitle, setTopTitle] = useState<string>('');
  const [bottomTitle, setBottomTitle] = useState<string>('');

  /***************************************
   *         Chart Change Listener       *
   ***************************************/
  useEffect(() => {
    if (!!topOption) {
      setTopTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === topOption)?.label ||
          ''
      );
    }
  }, [dispatch, topOption]);

  useEffect(() => {
    if (!!bottomOption) {
      setBottomTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === bottomOption)
          ?.label || ''
      );
    }
  }, [dispatch, bottomOption]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *             Error Message           *
   ***************************************/

  const hasSectors = (): boolean =>
    !!sectorSectors?.filter((e: ISectorIndustry) => e.selected)?.length;

  const makeErrorMessage = (
    option: boolean,
    hasLabels: boolean,
    error?: string
  ): string | undefined =>
    !option || !hasSectors()
      ? getEmptyOptionMessage('sectors', hasSectors(), !!option)
      : !!error
      ? GENERAL_ERROR_MESSAGE
      : !hasLabels
      ? GENERAL_NO_DATA_MESSAGE
      : undefined;

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.SECTOR_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.SECTOR));
  }, [dispatch]);

  return (
    <>
      {topSectorDataUpdating &&
        topDistributionSectorDataUpdating &&
        bottomSectorDataUpdating &&
        bottomDistributionSectorDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={top}
          error={makeErrorMessage(!!topOption, topHasLabels, topError)}
          warningIcon={!topOption || !hasSectors()}
          titleLabel={topTitle}
          hasData={!!topOption && hasSectors() ? topHasData : true}
          isPercentage={topSectorData?.isPercentage}
          exportData={topSectorData}
          showLegend={true}
          updating={!!topSectorDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={topDistribution}
          error={makeErrorMessage(
            !!topOption,
            topDistributionHasLabels,
            topDistributionError
          )}
          warningIcon={!topOption || !hasSectors()}
          titleLabel={topTitle ? makeDistributionTitle(topTitle) : ''}
          hasData={!!topOption && hasSectors() ? topDistributionHasData : true}
          exportData={topDistributionSectorData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          isPercentage={!!topDistributionSectorData?.isPercentage}
          updating={!!topDistributionSectorDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={bottom}
          error={makeErrorMessage(!!bottomOption, bottomHasLabels, bottomError)}
          warningIcon={!bottomOption || !hasSectors()}
          titleLabel={bottomTitle}
          hasData={!!bottomOption && hasSectors() ? bottomHasData : true}
          isPercentage={bottomSectorData?.isPercentage}
          exportData={bottomSectorData}
          showLegend={true}
          updating={!!bottomSectorDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={bottomDistribution}
          error={makeErrorMessage(
            !!bottomOption,
            bottomDistributionHasLabels,
            bottomDistributionError
          )}
          warningIcon={!bottomOption || !hasSectors()}
          titleLabel={bottomTitle ? makeDistributionTitle(bottomTitle) : ''}
          hasData={
            !!bottomOption && hasSectors() ? bottomDistributionHasData : true
          }
          exportData={bottomDistributionSectorData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          isPercentage={!!bottomDistributionSectorData?.isPercentage}
          updating={!!bottomDistributionSectorDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
    </>
  );
};

export default SectorAnalysisTab;
