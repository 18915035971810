import { IDescription } from '@prometeus/common';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
  GetDescriptionDispachTypes,
  GET_DESCRIPTION,
  GET_DESCRIPTION_FAIL,
  GET_DESCRIPTION_SUCCESS,
} from '../../models/descriptions.model';
import { getDescriptionService } from '../../services/description.service';
import { actionTryCatchWrapper } from '../../utils/action-try-catch-wrapper';

export const getDescription =
  (descriptionKey: string) =>
  (dispatch: Dispatch<GetDescriptionDispachTypes>) => {
    actionTryCatchWrapper(dispatch, GET_DESCRIPTION_FAIL, async () => {
      dispatch({
        type: GET_DESCRIPTION,
      });

      const response: AxiosResponse<{
        description: IDescription;
      }> = await getDescriptionService(descriptionKey);

      dispatch({
        type: GET_DESCRIPTION_SUCCESS,
        payload: { ...response?.data, key: descriptionKey },
      });
    });
  };
