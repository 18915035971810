export const screenerStyles = {
  longSelectItemComponent: {
    flex: '1.2 1 0 !important',
  },
  sameFlex: { flex: '1 1 0' },
  sliderSelectItemBox: {
    marginTop: '-1.8rem',
    flex: '0.9 1 0 !important',
  },
  flexHorizontal: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '1 1 0',
      '&:first-child': {
        marginRight: '3.5rem',
      },
    },
  },
  flexHorizontalSmall: {
    '& > *': {
      '&:first-child': {
        marginRight: '2rem',
      },
    },
  },
  flexVertical: {
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      marginBottom: '2rem',
    },
  },
  payoutRatio: {
    '& div:first-child': {
      paddingLeft: '0rem !important',
    },
  },
  marginTop2: {
    marginTop: '2rem',
  },
  firstChildMarginBottom: {
    '& div:first-child': {
      marginBottom: '1rem',
    },
  },
  marginTopNegd5: {
    marginTop: '-0.5rem',
  },
  marginTopNeg1d5: {
    marginTop: '-1.5rem',
  },
  noMarginRight: {
    marginRight: '0rem !important',
  },
  centered: {
    display: 'flex',
    // justifyContent: 'center',
    padding: '1rem',
    '& > *': {
      flex: '1 1 0',
    },
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  marginBottom1d5: {
    marginBottom: '1.5rem',
  },
  marginBottom2: {
    marginBottom: '2rem',
  },
  verticalHalfPadding: {
    paddingTop: '0.5rem',
    paddingMargin: '0.5rem',
  },
  padding2: {
    padding: '2rem',
  },
  paddingTop2: {
    paddingTop: '2rem',
  },
  noPaddingBottom: {
    paddingBottom: '0rem !important',
  },
  noPaddingTop: {
    paddingTop: '0rem !important',
  },
  sliderBoxContainer: {
    margin: '0rem 0rem 0rem 1rem',
    '&:first-child': {
      marginLeft: '0rem !important',
    },
  },
  doubleSliderBoxContainer: {
    margin: '0rem 0rem 0rem 3rem',
    '&:first-child': {
      marginLeft: '0rem !important',
    },
  },
  verticalSliderBoxContainer: {
    // margin: '1rem 0rem 0rem',
    // '&:first-child': {
    //   marginTop: '0rem !important',
    // },
    margin: '0rem 0rem 1rem 0rem',
  },
  paddingRight0: {
    paddingRight: '0rem',
  },
  paddingLeft6: {
    paddingLeft: '6rem',
  },
  payoutRatioContainer: {
    flex: '1 1 0',
    '& .MuiCard-root .MuiCardContent-root div:last-child': {
      paddingTop: '0rem !important',
      // '& div:last-child': { marginBottom: '-0.5rem !important' },
    },
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  groupedSliderBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  betweenContainer: {
    paddingRight: '0rem',
  },
  smallBetweenContainer: {
    paddingRight: '0.5rem',
    '& .MuiSlider-root': {
      width: '90%',
    },
    '& .MuiTypography-root': {
      marginBottom: '0.3rem',
    },
  },
};
