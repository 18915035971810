import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerIncomeStatementFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import {
  COMMON_SCREENER_ROW_HEIGHT,
  GrowthBlockComponent,
  VerticalCommonBlockComponent,
} from '../utils/constants';

const IncomeStatementTab = () => {
  const dispatch = useDispatch();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.incomeStatement
      : null
  );

  const incomeStatementSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(ScreenerTabNameEnum.INCOME_STATEMENT, block, filter, value)
    );
  };

  return (
    <>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
          ...COMMON_STYLES.cardRowBottomMargin,
        }}
      >
        {/* Profit Margin */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <VerticalCommonBlockComponent
            tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
            cardBlock={ScreenerIncomeStatementFilterCardEnum.PROFIT_MARGIN}
            data={tabData?.profitMargin}
            setFilterValueHandler={incomeStatementSetFilterValue}
          />
        </div>
        {/* Gross Margin */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <VerticalCommonBlockComponent
            tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
            cardBlock={ScreenerIncomeStatementFilterCardEnum.GROSS_MARGIN}
            data={tabData?.grossMargin}
            setFilterValueHandler={incomeStatementSetFilterValue}
          />
        </div>
        {/* Operation Income */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <VerticalCommonBlockComponent
            tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
            cardBlock={ScreenerIncomeStatementFilterCardEnum.OP_INC}
            data={tabData?.opInc}
            setFilterValueHandler={incomeStatementSetFilterValue}
          />
        </div>
      </div>
      <div
        style={{
          ...COMMON_STYLES.flexDisplay,
          height: COMMON_SCREENER_ROW_HEIGHT,
        }}
      >
        {/* Rev Growth */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <GrowthBlockComponent
            tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
            cardBlock={ScreenerIncomeStatementFilterCardEnum.REV_GROWTH}
            data={tabData?.revGrowth}
            setFilterValueHandler={incomeStatementSetFilterValue}
          />
        </div>
        {/* Prof Growth */}
        <div style={{ ...COMMON_STYLES.flexOneOneZero }}>
          <GrowthBlockComponent
            tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
            cardBlock={ScreenerIncomeStatementFilterCardEnum.PROF_GROWTH}
            data={tabData?.profGrowth}
            setFilterValueHandler={incomeStatementSetFilterValue}
          />
        </div>
      </div>
    </>
  );
};

export default IncomeStatementTab;
