import FilterList from '@mui/icons-material/FilterList';
import { ITableColumnData } from '@prometeus/common';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/root.reducer';
import ClickAwayComponent from '../../click-away.component';
import { CustomListItem } from '../../custom-list-item.component';
import SelectIconComponent from '../../select-icon.component';

type Props = {
  selectedColumns: boolean[];
  setSelectedColumns: (selected: boolean[]) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
};

const styles = {
  column: {
    flex: '1 1 0',
    '&:last-child': {
      marginRight: '2rem',
    },
  },
};

const ScreenerSelectTableColumnsComponent = (props: Props) => {
  const screenerColumnSetup = useSelector(
    (state: RootState) => state.screener.structure.screenerColumnSetup
  );

  const arrayColumnSplit = (arr: unknown[], size: number): any[] => {
    const arrayOfArrays = [];
    for (let i = 0; i < arr.length; i += size) {
      arrayOfArrays.push(arr.slice(i, i + size));
    }
    return arrayOfArrays;
  };

  /* Select All */
  const [selectAllValue, setSelectAllValue] = useState<boolean>(false);

  const onSelectAllHandler = () => {
    if (selectAllValue) {
      props.setSelectedColumns(
        [...screenerColumnSetup].map(
          (e: ITableColumnData): boolean => !!e.notRemovable
        )
      );
    } else {
      props.setSelectedColumns(Array(screenerColumnSetup.length).fill(true));
    }
    setSelectAllValue(!selectAllValue);
  };

  return (
    <SelectIconComponent
      tooltip="Filter Columns"
      open={props.open}
      setOpen={props.setOpen}
      icon={<FilterList />}
    >
      <ClickAwayComponent setOpen={props.setOpen}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ flex: '1 1 0' }}>
            <CustomListItem
              label="Select All"
              indeterminate={!selectAllValue}
              checked={selectAllValue}
              onClick={onSelectAllHandler}
            />
          </div>
          <div style={{ flex: '2 1 0' }}></div>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          {arrayColumnSplit(screenerColumnSetup, 10).map(
            (column: ITableColumnData[], colIndex: number) => (
              <div
                key={`screener-select-table-column-${colIndex}`}
                style={styles.column}
              >
                {column.map((e: ITableColumnData, index: number) => {
                  const correctIndex = index + colIndex * 10;
                  return (
                    <div
                      style={{ padding: '0.5rem 0rem' }}
                      key={`${e.label}-${correctIndex}`}
                    >
                      <CustomListItem
                        label={e.label?.split(' (')[0]}
                        checked={props.selectedColumns[correctIndex]}
                        onClick={() => {
                          if (!e.notRemovable) {
                            const tmp = [...props.selectedColumns];
                            tmp[correctIndex] = !tmp[correctIndex];
                            props.setSelectedColumns([...tmp]);
                            setSelectAllValue(tmp.every((v: boolean) => v));
                          }
                        }}
                        disabled={e.notRemovable}
                      />
                    </div>
                  );
                })}
              </div>
            )
          )}
        </div>
      </ClickAwayComponent>
    </SelectIconComponent>
  );
};

export default ScreenerSelectTableColumnsComponent;
