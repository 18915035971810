import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { COMMON_STYLES, DOLLAR_ST } from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { CASH_FLOW_TITLE_LABELS } from '../utils/visuals.config';

const CashflowTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  /***************************************
   *              Charts Data            *
   ***************************************/
  const cashData = useSelector(
    (state: RootState) => state.visuals.cashflow.cashData
  );
  const cashDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.cashDataUpdating
  );
  const cashFromFinancingActivitiesData = useSelector(
    (state: RootState) => state.visuals.cashflow.cashFromFinancingActivitiesData
  );
  const cashFromOperatingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromOperatingActivitiesDataUpdating
  );
  const cashFromInvestingActivitiesData = useSelector(
    (state: RootState) => state.visuals.cashflow.cashFromInvestingActivitiesData
  );
  const netChangeInCashDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.netChangeInCashDataUpdating
  );
  const cashFromOperatingActivitiesData = useSelector(
    (state: RootState) => state.visuals.cashflow.cashFromOperatingActivitiesData
  );
  const cashFromInvestingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromInvestingActivitiesDataUpdating
  );
  const cashSourcesData = useSelector(
    (state: RootState) => state.visuals.cashflow.cashSourcesData
  );
  const cashSourcesDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.cashSourcesDataUpdating
  );
  const netChangeInCashData = useSelector(
    (state: RootState) => state.visuals.cashflow.netChangeInCashData
  );
  const cashFromFinancingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromFinancingActivitiesDataUpdating
  );

  const [cash, setCash] = useState<ChartData>();
  const [cashFromFinancingActivities, setCashFromFinancingActivities] =
    useState<ChartData>();
  const [cashFromInvestingActivities, setCashFromInvestingActivities] =
    useState<ChartData>();
  const [cashFromOperatingActivities, setCashFromOperatingActivities] =
    useState<ChartData>();
  const [cashSources, setCashSources] = useState<ChartData>();
  const [netChangeInCash, setNetChangeInCash] = useState<ChartData>();

  /* Cash */
  const [cashHasData, cashHasLabels, cashError] =
    extractChartMetadata(cashData);
  useEffect(() => {
    setCash({
      datasets: createChartDatasetsConfig(cashData, screenWidth, {
        useDollars,
        isTime: true,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [cashData, useDollars, fiscalYear, screenWidth]);

  /* Net Change In Cash */
  const [
    netChangeInCashHasData,
    netChangeInCashHasLabels,
    netChangeInCashError,
  ] = extractChartMetadata(netChangeInCashData);
  useEffect(() => {
    setNetChangeInCash({
      datasets: createChartDatasetsConfig(netChangeInCashData, screenWidth, {
        useDollars,
        isTime: true,
        isBar: true,
        highlightNegatives: true,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [netChangeInCashData, useDollars, fiscalYear, screenWidth]);

  /* Cash Sources */
  const [cashSourcesHasData, cashSourcesHasLabels, cashSourcesError] =
    extractChartMetadata(cashSourcesData);
  useEffect(() => {
    setCashSources({
      datasets: createChartDatasetsConfig(cashSourcesData, screenWidth, {
        useDollars,
        isTime: true,
        isBar: true,
        fiscalYear,
        isPortraitMobile,
      }),
    });
  }, [cashSourcesData, useDollars, fiscalYear, screenWidth]);

  /* Cash From Operating Activities */
  const [
    cashFromOperatingActivitiesHasData,
    cashFromOperatingActivitiesHasLabels,
    cashFromOperatingActivitiesError,
  ] = extractChartMetadata(cashFromOperatingActivitiesData);
  useEffect(() => {
    setCashFromOperatingActivities({
      datasets: createChartDatasetsConfig(
        cashFromOperatingActivitiesData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          isBar: true,
          fiscalYear,
          isPortraitMobile,
        }
      ),
    });
  }, [cashFromOperatingActivitiesData, useDollars, fiscalYear, screenWidth]);

  /* Cash From Investing Activities */
  const [
    cashFromInvestingActivitiesHasData,
    cashFromInvestingActivitiesHasLabels,
    cashFromInvestingActivitiesError,
  ] = extractChartMetadata(cashFromInvestingActivitiesData);
  useEffect(() => {
    setCashFromInvestingActivities({
      datasets: createChartDatasetsConfig(
        cashFromInvestingActivitiesData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          isBar: true,
          fiscalYear,
          isPortraitMobile,
        }
      ),
    });
  }, [cashFromInvestingActivitiesData, useDollars, fiscalYear, screenWidth]);

  /* Cash From Financing Activities */
  const [
    cashFromFinancingActivitiesHasData,
    cashFromFinancingActivitiesHasLabels,
    cashFromFinancingActivitiesError,
  ] = extractChartMetadata(cashFromFinancingActivitiesData);
  useEffect(() => {
    setCashFromFinancingActivities({
      datasets: createChartDatasetsConfig(
        cashFromFinancingActivitiesData,
        screenWidth,
        {
          useDollars,
          isTime: true,
          isBar: true,
          fiscalYear,
          isPortraitMobile,
        }
      ),
    });
  }, [cashFromFinancingActivitiesData, useDollars, fiscalYear, screenWidth]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CASH_FLOW));
  }, [dispatch]);

  return (
    <>
      {cashDataUpdating &&
        cashFromOperatingActivitiesDataUpdating &&
        netChangeInCashDataUpdating &&
        cashFromInvestingActivitiesDataUpdating &&
        cashSourcesDataUpdating &&
        cashFromFinancingActivitiesDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={cash}
          error={
            !!cashError
              ? GENERAL_ERROR_MESSAGE
              : !cashHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.cash}
          hasData={cashHasData}
          exportData={cashData}
          currencySymbol={
            !!cashData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!cashData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!cashDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={cashFromOperatingActivities}
          error={
            !!cashFromOperatingActivitiesError
              ? GENERAL_ERROR_MESSAGE
              : !cashFromOperatingActivitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.cashFromOperatingActivities}
          hasData={cashFromOperatingActivitiesHasData}
          exportData={cashFromOperatingActivitiesData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!cashFromOperatingActivitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!cashFromOperatingActivitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!cashFromOperatingActivitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={netChangeInCash}
          error={
            !!netChangeInCashError
              ? GENERAL_ERROR_MESSAGE
              : !netChangeInCashHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.netChangeInCash}
          hasData={netChangeInCashHasData}
          exportData={netChangeInCashData}
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!netChangeInCashData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!netChangeInCashData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!netChangeInCashDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={cashFromInvestingActivities}
          error={
            !!cashFromInvestingActivitiesError
              ? GENERAL_ERROR_MESSAGE
              : !cashFromInvestingActivitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.cashFromInvestingActivities}
          hasData={cashFromInvestingActivitiesHasData}
          exportData={cashFromInvestingActivitiesData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!cashFromInvestingActivitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!cashFromInvestingActivitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!cashFromInvestingActivitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow sx={{ ...COMMON_STYLES.cardRowLastBottomMargin }}>
        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={cashSources}
          error={
            !!cashSourcesError
              ? GENERAL_ERROR_MESSAGE
              : !cashSourcesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.cashSources}
          hasData={cashSourcesHasData}
          exportData={cashSourcesData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!cashSourcesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!cashSourcesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!cashSourcesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={cashFromFinancingActivities}
          error={
            !!cashFromFinancingActivitiesError
              ? GENERAL_ERROR_MESSAGE
              : !cashFromFinancingActivitiesHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CASH_FLOW_TITLE_LABELS.cashFromFinancingActivities}
          hasData={cashFromFinancingActivitiesHasData}
          exportData={cashFromFinancingActivitiesData}
          showLegend={true}
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!cashFromFinancingActivitiesData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!cashFromFinancingActivitiesData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!cashFromFinancingActivitiesDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ScrollFillerComponent />
    </>
  );
};

export default CashflowTab;
