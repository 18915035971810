import { Palette } from '@mui/material';
import {
  ITableColumnData,
  MOMENT_FORMAT,
  isNullUndefined,
} from '@prometeus/common';
import moment from 'moment';
import { NA_LABEL } from '../../constants/general.constants';
import { IScreenerResult } from '../../models/screener/results.model';
import { formBorder, pxToRem } from '../../ui/functions';

export const ROW_HEIGHT_REM = 4.36; // 61px
export const HEAD_ROW_HEIGHT_REM = 4.36; // 61px

export const formatCellValue = (
  row: IScreenerResult,
  column: ITableColumnData
): string => {
  const rowValue = row[column.id as keyof IScreenerResult];

  if (isNullUndefinedEmptyStr(rowValue)) {
    return NA_LABEL;
  }

  let value: string = String(rowValue);

  if (!isNullUndefined(column.decimals)) {
    if (column.isPercent) {
      value = String(Number(value) * 100);
      value = Number(value).toFixed(column.decimals);
    } else {
      value = Number(value).toFixed(column.decimals);
    }
  }

  if (column.isPercent || column.isCurrency) {
    value = String(value)
      .replace(/(.)(?=(\d{3})+$)/g, '$1,')
      .replace('-,', '-');
  }

  if (column.isPercent) {
    value += ' %';
  }

  if (column.isCurrency) {
    value += ' $';
  }

  if (column.id === 'pE') {
    value += 'x';
  }

  if (column.isDate) {
    value = !!value ? moment(+value).format(MOMENT_FORMAT) : 'N.A.';
  }

  return value;
};

export const isNullUndefinedEmptyStr = (value: unknown): boolean => {
  return isNullUndefined(value) || value === '';
};

const descendingComparator = (a: any, b: any, orderBy: keyof any) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export type Order = 'asc' | 'desc';

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (
  array: any[],
  order: Order,
  orderBy: string
): any[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    if (
      isNullUndefinedEmptyStr(a[0][orderBy]) &&
      !isNullUndefinedEmptyStr(b[0][orderBy])
    ) {
      return 1;
    } else if (
      isNullUndefinedEmptyStr(b[0][orderBy]) &&
      !isNullUndefinedEmptyStr(a[0][orderBy])
    ) {
      return -1;
    }

    const orderResult = getComparator(order, orderBy)(a[0], b[0]);
    if (orderResult !== 0) {
      return orderResult;
    }

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getTableStyles = (palette: Palette) => ({
  root: {
    width: '100%',
  },
  container: {
    // backgroundColor: colors.mask,
    borderRadius: '0.5rem 0.5rem 0 0',
    // '-ms-overflow-style': 'none' /* IE, Edge */,
    msOverflowStyle: 'none',
    // 'scrollbar-width': 'none !important' /* Firefox */,
    scrollbarWidth: 'none !important',
    '&::-webkit-scrollbar': {
      display: 'none' /* Safari and Chrome */,
    },
  },
  paper: {
    width: '100%',
    marginBottom: '1rem',
    backgroundColor: palette.background.paper,
    borderRadius: '0.5rem',
    border: formBorder(palette),
  },
  tableContainerOverflowHidden: {
    overflow: 'hidden',
  },
  tableRow: {
    height: `${ROW_HEIGHT_REM}rem`,
    backgroundColor: palette.background.default,
    cursor: 'default',
    '&:last-child .MuiTableCell-root': {
      borderBottom: '0px !important',
    },
  },
  tableCellRoot: {
    color: `${palette.text.primary} !important`,
    padding: pxToRem(16),
    borderBottom: formBorder(palette),
    '& .table-tickerId-text, .table-tickerId-icon': {
      cursor: 'pointer !important',
    },
    '&:hover': {
      '& .table-tickerId-text': {
        textDecoration: 'underline',
      },
    },
  },
  emptyRowsCell: {
    color: `${palette.text.primary} !important`,
    padding: pxToRem(16),
    borderBottom: formBorder(palette),
    verticalAlign: 'top',
  },
  emptyRowsCellNoMessage: {
    padding: 0,
  },
  checkbox: {
    '&:last-child': {
      paddingRight: pxToRem(4),
    },
    width: pxToRem(48),
  },
});

export const getEmptyRowsHeight = (
  emptyRows: number,
  maxHeight: number,
  rowsPerPage: number
): string => {
  const reducedMaxHeight = maxHeight - HEAD_ROW_HEIGHT_REM;
  const fullRowsHeight = ROW_HEIGHT_REM * (rowsPerPage - emptyRows);
  let emptyHeight = ROW_HEIGHT_REM * emptyRows;
  if (fullRowsHeight >= reducedMaxHeight) {
    emptyHeight = 0;
  } else {
    emptyHeight = reducedMaxHeight - fullRowsHeight;
  }
  return `${emptyHeight}rem`;
};
