import { SvgIcon, SxProps, Tooltip } from '@mui/material';
import { ReactComponent as BrandFlameGradientSVG } from '../assets/brand-flame-gradient.svg';
import { ReactComponent as BrandFlameSVG } from '../assets/brand-flame.svg';

type Props = {
  sx?: SxProps;
  gradient?: boolean;
  tooltip?: string;
};

const BrandFlame = (props: Props) => {
  const svgComponent = (
    <SvgIcon
      sx={{
        transform: 'scale(1.35)',
        ...props.sx,
      }}
    >
      {props.gradient ? <BrandFlameGradientSVG /> : <BrandFlameSVG />}
    </SvgIcon>
  );
  return !!props.tooltip ? (
    <Tooltip title={props.tooltip}>
      <span>{svgComponent}</span>
    </Tooltip>
  ) : (
    svgComponent
  );
};

export default BrandFlame;
