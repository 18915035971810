export type UserRoleUnion = 'admin' | 'user' | 'api' | 'dummy';
export type UserLicenceUnion =
  | 'period'
  | 'trigger'
  | 'free-trial'
  | 'basic'
  | 'gold';
export type LicenceSubscriptionDurationUnion = 'day' | 'month' | 'year';
export enum ExpirationStatusEnum {
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  ADMIN_GRANTED_NEW = 'admin_granted_new',
  USER_SUSPENDED = 'user_suspended',
  RENEWED = 'renewed',
  FAILED_PAYMENT = 'failed_payment',
  NEW_SUBSCRIPTION = 'new_subscription',
}

export interface ILicence {
  type: UserLicenceUnion;
  startDate?: string;
  expirationDate?: string;
  days?: number;
  expirationStatus?: ExpirationStatusEnum;
  receivedPayment?: boolean;
  failedPaymentCounter?: number;
  subscriptionId?: string;
  planId?: string;
}

export interface IUserData {
  // General data
  userId: string;
  email: string;
  role: UserRoleUnion;
  notDeletable?: boolean;

  // Licence
  activeLicence?: ILicence;
  licencesHistory: ILicence[];
  usedFreeTrial?: boolean;
  subscriptionId?: string;
  planId?: string;

  // User information
  firstName?: string;
  lastName?: string;
  year?: string;
  gender?: string;
  workingSector?: string;
  workingRole?: string;
  location?: string;
  prometeusGoal?: string;

  // Stats
  logins: number;
  firstLogin?: string;
  lastLogin?: string;
  oauthProvider?: string;

  // Other
  hasRated?: boolean;
}

export interface IUILicence {
  type: string;
  startDate: string;
  expirationDate: string;
}

export interface IUserRegistrationInformation {
  firstName?: string;
  lastName?: string;
  year?: string;
  gender?: string;
  workingSector?: string;
  workingRole?: string;
  location?: string;
  prometeusGoal?: string;
}

export interface IUserInformation extends IUserRegistrationInformation {
  email: string;
  signature?: string;
  usedFreeTrial?: boolean;
  hasRated?: boolean;
}

export interface ILoggedUserData {
  isAdmin: boolean;
  hasActiveLicence: boolean | string;
  hasRated: boolean;
  userInformation: IUserInformation;
}
