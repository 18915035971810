import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAX_FISCAL_YEAR,
  MIN_FISCAL_YEAR,
} from '../../../constants/general.constants';
import {
  setBottomChartOption,
  setTimeWindow,
  setTopChartOption,
} from '../../../pages/sector-analysis/utils/visuals.actions';
import {
  CHART_OPTIONS,
  FREE_LICENCE_SECTOR_ANALYSIS_METRICS,
} from '../../../pages/sector-analysis/utils/visuals.config';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../../ui/colors';
import { pxToRem } from '../../../ui/functions';
import CustomSelectComponent from '../../select.component';
import CountryTabFiltersComponent from '../a-filters/country-tab-filters.component';
import IndustryTabFiltersComponent from '../a-filters/industry-tab-filters.component';
import SectorTabFiltersComponent from '../a-filters/sector-tab-filters.component';
import FilterTitleComponent from '../filter-components/filter-title.component';
import FiltersCardComponent from '../filters-card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../screener/between.component';
import { useAggFiltersStyles } from './side-filters.component';

type Props = {
  tab: 'country' | 'industry' | 'sector';
};

const AFiltersComponent = (props: Props) => {
  const dispatch = useDispatch();

  const aggStyles = useAggFiltersStyles();

  /***************************************
   *              Chart Types            *
   ***************************************/
  const topOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );
  const bottomOption = useSelector(
    (state: RootState) => state.visuals.sector.bottomOption
  );
  const timeWindow = useSelector(
    (state: RootState) => state.visuals.sector.timeWindow
  );

  /***************************************
   *              Time Window            *
   ***************************************/
  const [internalTimeWindow, setInternalTimeWindow] = useState<
    [number, number]
  >([0, 0]);
  useEffect(() => {
    setInternalTimeWindow(timeWindow);
  }, [timeWindow]);

  const onChangeSlideTWHandler = (event: any, newRange: any) => {
    if (Array.isArray(newRange)) {
      if (
        `${newRange[0]}`.length === 4 &&
        `${newRange[1]}`.length === 4 &&
        newRange[1] >= newRange[0]
      ) {
        setInternalTimeWindow(newRange as [number, number]);
        dispatch(setTimeWindow(newRange as [number, number]));
      }
    }
  };

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const handleIsDisable = (option: string): boolean =>
    !hasActiveLicence && !FREE_LICENCE_SECTOR_ANALYSIS_METRICS.includes(option);

  return (
    <FiltersCardComponent>
      <Box sx={aggStyles.filterMargin}>
        <FilterTitleComponent title="Chart Type" />
        <CustomSelectComponent
          options={CHART_OPTIONS}
          option={{ label: topOption || '', value: topOption || '' }}
          boxLabel="Top Chart Type"
          setOption={(option: string) => {
            dispatch(setTopChartOption(option));
          }}
          boxSx={AFiltersStyles.customSelect}
          inputLabelSx={AFiltersStyles.inputLabelSelect}
          isLabelValue={true}
          isDisabled={handleIsDisable}
          formControlSx={
            !topOption ? AFiltersStyles.missingHighlightBorder : {}
          }
        />
        <CustomSelectComponent
          options={CHART_OPTIONS}
          option={{ label: bottomOption || '', value: bottomOption || '' }}
          boxLabel="Bottom Chart Type"
          setOption={(option: string) => {
            dispatch(setBottomChartOption(option));
          }}
          boxSx={AFiltersStyles.customSelect}
          inputLabelSx={AFiltersStyles.inputLabelSelect}
          isLabelValue={true}
          isDisabled={handleIsDisable}
          formControlSx={
            !bottomOption ? AFiltersStyles.missingHighlightBorder : {}
          }
        />
      </Box>
      <Divider sx={{ ...aggStyles.divider, ...AFiltersStyles.divider }} />
      <Box sx={aggStyles.filterMargin}>
        <FilterTitleComponent title="Countries, Sectors & Industries" />
        {props.tab === 'country' && <CountryTabFiltersComponent />}
        {props.tab === 'sector' && <SectorTabFiltersComponent />}
        {props.tab === 'industry' && <IndustryTabFiltersComponent />}
      </Box>
      <Divider sx={{ ...aggStyles.divider, ...AFiltersStyles.divider }} />
      <Box sx={aggStyles.filterMargin}>
        <BetweenSliderComponent
          // containerClassName={aggStyles.fiscalYear}
          typographyVariant="h6"
          title="Fiscal Year"
          // step={}
          min={MIN_FISCAL_YEAR}
          minValue={internalTimeWindow[0]}
          setMinValue={(value: number) => {
            onChangeSlideTWHandler(null, [value, internalTimeWindow[1]]);
          }}
          max={MAX_FISCAL_YEAR}
          maxValue={internalTimeWindow[1]}
          setMaxValue={(value: number) => {
            onChangeSlideTWHandler(null, [internalTimeWindow[0], value]);
          }}
          setValues={(values: BetweenValuesType) => {
            onChangeSlideTWHandler(null, values);
          }}
        />
      </Box>
    </FiltersCardComponent>
  );
};

export default AFiltersComponent;

export const AFiltersStyles = {
  customSelect: { width: '100%', marginBottom: '1rem', marginTop: '1rem' },
  inputLabelSelect: {
    transform: 'translate(1rem, 1.1rem) scale(1) !important',
    '&.MuiInputLabel-shrink': {
      transform:
        'translate(1rem, -0.42857142857142855rem) scale(0.75) !important',
    },
  },
  divider: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
    height: pxToRem(1),
  },
  missingHighlightBorder: {
    borderColor: `${COMMON_COLORS.accent} !important`,
    borderWidth: '0.15rem !important',
  },
};
