import { IVisualChart, IVisualPayload } from '../../../models/visuals.model';

export interface IVisualsState {
  /* Current And Quick Ratio And Capex */
  currentRatioData?: IVisualChart;
  currentRatioDataUpdating?: boolean;
  currentRatioDistributionData?: IVisualChart;
  currentRatioDistributionDataUpdating?: boolean;
  quickRatioData?: IVisualChart;
  quickRatioDataUpdating?: boolean;
  quickRatioDistributionData?: IVisualChart;
  quickRatioDistributionDataUpdating?: boolean;
  capexData?: IVisualChart;
  capexDataUpdating?: boolean;
  capexDistributionData?: IVisualChart;
  capexDistributionDataUpdating?: boolean;
  /* Debt To Equity And Debt Payback */
  deData?: IVisualChart;
  deDataUpdating?: boolean;
  deDistributionData?: IVisualChart;
  deDistributionDataUpdating?: boolean;
  debtPaybackData?: IVisualChart;
  debtPaybackDataUpdating?: boolean;
  debtPaybackDistributionData?: IVisualChart;
  debtPaybackDistributionDataUpdating?: boolean;
}

/* FINANCIAL_STRENGTH_UPDATE */
export const FINANCIAL_STRENGTH_UPDATE = 'FINANCIAL_STRENGTH_UPDATE';
export interface IFinancialStrengthUpdate {
  type: typeof FINANCIAL_STRENGTH_UPDATE;
  payload: string[];
}

/***************************************
 *   Current And Quick Ratio And Capex *
 ***************************************/
/* CURRENT_RATIO_YEARLY */
export const CURRENT_RATIO_YEARLY = 'CURRENT_RATIO_YEARLY';
export interface CurrentRatioYearlyI {
  type: typeof CURRENT_RATIO_YEARLY;
  payload: IVisualPayload;
}

/* CURRENT_RATIO_QUARTERLY */
export const CURRENT_RATIO_QUARTERLY = 'CURRENT_RATIO_QUARTERLY';
export interface CurrentRatioQuarterlyI {
  type: typeof CURRENT_RATIO_QUARTERLY;
  payload: IVisualPayload;
}

/* CURRENT_RATIO_DISTRIBUTION */
export const CURRENT_RATIO_DISTRIBUTION = 'CURRENT_RATIO_DISTRIBUTION';
export interface CurrentRatioDistributionI {
  type: typeof CURRENT_RATIO_DISTRIBUTION;
  payload: IVisualPayload;
}

/* QUICK_RATIO_YEARLY */
export const QUICK_RATIO_YEARLY = 'QUICK_RATIO_YEARLY';
export interface QuickRatioYearlyI {
  type: typeof QUICK_RATIO_YEARLY;
  payload: IVisualPayload;
}

/* QUICK_RATIO_QUARTERLY */
export const QUICK_RATIO_QUARTERLY = 'QUICK_RATIO_QUARTERLY';
export interface QuickRatioQuarterlyI {
  type: typeof QUICK_RATIO_QUARTERLY;
  payload: IVisualPayload;
}

/* QUICK_RATIO_DISTRIBUTION */
export const QUICK_RATIO_DISTRIBUTION = 'QUICK_RATIO_DISTRIBUTION';
export interface QuickRatioDistributionI {
  type: typeof QUICK_RATIO_DISTRIBUTION;
  payload: IVisualPayload;
}

/* CAPEX_YEARLY */
export const CAPEX_YEARLY = 'CAPEX_YEARLY';
export interface CapexYearlyI {
  type: typeof CAPEX_YEARLY;
  payload: IVisualPayload;
}

/* CAPEX_QUARTERLY */
export const CAPEX_QUARTERLY = 'CAPEX_QUARTERLY';
export interface CapexQuarterlyI {
  type: typeof CAPEX_QUARTERLY;
  payload: IVisualPayload;
}

/* CAPEX_DISTRIBUTION */
export const CAPEX_DISTRIBUTION = 'CAPEX_DISTRIBUTION';
export interface CapexDistributionI {
  type: typeof CAPEX_DISTRIBUTION;
  payload: IVisualPayload;
}

/***************************************
 *    Debt To Equity And Debt Payback  *
 ***************************************/
/* DEBT_TO_EQUITY_YEARLY */
export const DEBT_TO_EQUITY_YEARLY = 'DEBT_TO_EQUITY_YEARLY';
export interface DebtToEquityYearlyI {
  type: typeof DEBT_TO_EQUITY_YEARLY;
  payload: IVisualPayload;
}

/* DEBT_TO_EQUITY_QUARTERLY */
export const DEBT_TO_EQUITY_QUARTERLY = 'DEBT_TO_EQUITY_QUARTERLY';
export interface DebtToEquityQuarterlyI {
  type: typeof DEBT_TO_EQUITY_QUARTERLY;
  payload: IVisualPayload;
}

/* DEBT_TO_EQUITY_DISTRIBUTION */
export const DEBT_TO_EQUITY_DISTRIBUTION = 'DEBT_TO_EQUITY_DISTRIBUTION';
export interface DebtToEquityDistributionI {
  type: typeof DEBT_TO_EQUITY_DISTRIBUTION;
  payload: IVisualPayload;
}

/* DEBT_PAYBACK_YEARLY */
export const DEBT_PAYBACK_YEARLY = 'DEBT_PAYBACK_YEARLY';
export interface DebtPaybackYearlyI {
  type: typeof DEBT_PAYBACK_YEARLY;
  payload: IVisualPayload;
}

/* DEBT_PAYBACK_QUARTERLY */
export const DEBT_PAYBACK_QUARTERLY = 'DEBT_PAYBACK_QUARTERLY';
export interface DebtPaybackQuarterlyI {
  type: typeof DEBT_PAYBACK_QUARTERLY;
  payload: IVisualPayload;
}

/* DEBT_PAYBACK_DISTRIBUTION */
export const DEBT_PAYBACK_DISTRIBUTION = 'DEBT_PAYBACK_DISTRIBUTION';
export interface DebtPaybackDistributionI {
  type: typeof DEBT_PAYBACK_DISTRIBUTION;
  payload: IVisualPayload;
}

export type DispatchActionTypes =
  | IFinancialStrengthUpdate
  | CurrentRatioYearlyI
  | CurrentRatioQuarterlyI
  | CurrentRatioDistributionI
  | QuickRatioYearlyI
  | QuickRatioQuarterlyI
  | QuickRatioDistributionI
  | CapexYearlyI
  | CapexQuarterlyI
  | CapexDistributionI
  | DebtToEquityYearlyI
  | DebtToEquityQuarterlyI
  | DebtToEquityDistributionI
  | DebtPaybackYearlyI
  | DebtPaybackQuarterlyI
  | DebtPaybackDistributionI;
