import {
  TextField as MuiTextField,
  Palette,
  SxProps,
  TextFieldProps,
  Theme,
  inputClasses,
  outlinedInputClasses,
  useTheme,
} from '@mui/material';
import { COMMON_COLORS } from '../ui/colors';
import { pxToRem } from '../ui/functions';

export const COMMON_TEXT_FIELD_SX = (palette: Palette) => ({
  flex: '1 1 0',
  '& .MuiInputBase-root': {
    border: `solid 0.1rem ${COMMON_COLORS.chartGrid} !important`,
    borderRadius: '2rem',
  },
  '& label': {
    // fontSize: '1.2rem',
    fontWeight: '700 !important',
    '&.Mui-disabled': {
      color: `${palette.text.secondary}`,
    },
    '&.MuiInputLabel-shrink': {
      // color: 'white !important',
      color: palette.text.primary,
    },
    '&.MuiInputLabel-outlined': {
      transform: 'translate(1.5rem, 1.2rem) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(1.5rem, -0.65rem) scale(0.9)',
      },
    },
  },
  '& .Mui-disabled': {
    color: `${palette.text.secondary} !important`,
    WebkitTextFillColor: `${palette.text.secondary} !important`,
  },
  '& input': {
    padding: '1.2rem !important',
    paddingLeft: '1.5rem !important',
  },
});

type CssTextFieldProps = TextFieldProps & {
  sx?: SxProps<Theme>;
};

const TextField = (props: CssTextFieldProps) => {
  const theme = useTheme();

  return (
    <MuiTextField
      {...props}
      InputProps={{
        ...props?.InputProps,
        sx: {
          backgroundColor: theme.palette.background.default,
          borderRadius: '2rem !important',
          '& fieldset': {
            border: 'none',
            // ? `solid 0.2rem ${theme.palette.primary.main} !important`
            // : 'none',
            // border: !!isHeaderField
            //   ? `solid 0.2rem ${primary} !important`
            //   : formBorder(palette),
            // boxShadow: theme === 'light' ? boxShadow(theme) : 'none',
          },
          '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
          },
          [`& .${outlinedInputClasses.focused} fieldset`]: {
            borderColor: theme.palette.primary.main,
          },
          ...props?.InputProps?.sx,
        },
      }}
      InputLabelProps={{
        ...props?.InputLabelProps,
        sx: {
          color: theme.palette.text.primary,
          ...props?.InputLabelProps?.sx,
        },
      }}
      sx={{
        '& .MuiInputBase-input': {
          color: theme.palette.text.primary,
          [`& .${inputClasses.underline}`]: {
            borderBottomWidth: pxToRem(1),
            borderColor: theme.palette.text.primary,

            '&::before': {
              borderBottomColor: theme.palette.text.primary,
            },

            '&::after': {
              borderBottomWidth: pxToRem(2),
            },

            '&:hover': {
              '&::before': {
                borderBottom: `${pxToRem(2)} solid ${
                  theme.palette.primary.main
                }`,
              },
            },
          },
          [`& .${inputClasses.error}`]: {
            border: `${pxToRem(1)} solid ${theme.palette.error.main}`,
          },
        },
        ...props?.sx,
      }}
    />
  );
};

export default TextField;
