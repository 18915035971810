import { Close } from '@mui/icons-material';
import {
  Button,
  Palette,
  Stack,
  SvgIcon,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import isDev from '../constants/is-dev.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { IMicrosoftLogin } from '../models/authentication.model';
import { RouteEnum } from '../navigation/routes.constants';
import {
  devSignIn,
  googleSignIn,
  microsoftSignIn,
} from '../store/actions/authentication.action';
import {
  closeSignInDialog,
  openSignInDialog,
} from '../store/actions/modals.action';
import { RootState } from '../store/reducers/root.reducer';
import { COMMON_COLORS } from '../ui/colors';
import { boxShadow } from '../ui/functions';
import { getRedirectUri } from '../utils/common';
import CustomDialogComponent from './dialog.component';
import GoogleLoginButton from './login-button/GoogleLoginButton';
import MicrosoftLoginButton from './login-button/MicrosoftLoginButton';
import TooltipIconButton from './tooltip-icon-button.component';

const getLoginButtonStyles = (palette: Palette) => ({
  loginButton: {
    backgroundColor: palette.mode === 'dark' ? '#f6f5f4' : 'transparent',
    boxShadow: palette.mode === 'light' ? boxShadow(palette) : 'none',
  },
  centered: {
    padding: '2rem 1rem',
    paddingTop: 0,
  },
  button: {
    borderRadius: '2rem !important',
  },
  title: {
    color: palette.text.primary,
    fontSize: '2.5rem',
    letterSpacing: '0.05rem',
  },
  version: {
    color: palette.text.primary,
    display: 'flex',
    alignItems: 'flex-end',
  },
  logo: {
    width: '7rem',
    height: '7rem',
  },
  termsAndConditions: {
    marginTop: '2rem !important',
    '& a': {
      color: `${COMMON_COLORS.lightBlue} !important`,
    },
  },
});

const SignInDialogComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const loginButtonStyles = getLoginButtonStyles(theme.palette);
  const redirectUri = getRedirectUri();

  const closeDialog = () => {
    dispatch(closeSignInDialog());
  };

  const open = useSelector((state: RootState) => state.modals.openSignInDialog);

  const onDevSignIn = (devType: 'admin' | 'user') => {
    dispatch(devSignIn(devType));
    closeDialog();
  };

  const onGoogleSuccess = (codeResponse: any) => {
    if (!!codeResponse?.code) {
      dispatch(googleSignIn(codeResponse.code, redirectUri));
      closeDialog();
    }
  };

  const onGoogleError = (res: any) => {
    console.debug('Login failed: res:', res);
  };

  const signInGoogle = useGoogleLogin({
    onSuccess: onGoogleSuccess,
    onError: onGoogleError,
    flow: 'auth-code',
    redirect_uri: redirectUri,
  });

  const microsoftSuccessHandler = (data: IMicrosoftLogin) => {
    if (!!data) {
      dispatch(microsoftSignIn(data.tokenId));
      closeDialog();
    }
  };

  // Path
  const { pathname } = useLocation();
  const isPaymentRoute = pathname.split('/')[1] === RouteEnum.PAYMENT.slice(1);

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <CustomDialogComponent
      open={!!open}
      onClose={closeDialog}
      maxWidth="sm"
      dialogTitle={
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <TooltipIconButton
            icon={<Close />}
            tooltip="Close"
            onClick={closeDialog}
          />
        </Stack>
      }
      dialogContent={
        <Stack
          sx={{ ...loginButtonStyles.centered }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {/* Header */}
          <Stack
            sx={{ marginBottom: '1rem' }}
            alignItems="center"
            direction="row"
          >
            <SvgIcon sx={loginButtonStyles.logo}>
              <Logo />
            </SvgIcon>
            <Typography
              variant="h4"
              noWrap
              sx={{ ...loginButtonStyles.title, marginTop: '1rem' }}
            >
              PROMETEUS
            </Typography>
          </Stack>

          {/* Message */}
          {isPaymentRoute && (
            <Typography
              variant="h5"
              noWrap
              classes={{ ...loginButtonStyles.title }}
            >
              To continue, you first need to sign in!
            </Typography>
          )}
          <Typography
            variant="h5"
            noWrap
            classes={{ ...loginButtonStyles.title }}
          >
            Access using Your favourite provider!
          </Typography>

          {/* Buttons */}
          <Stack
            sx={{ height: '100%', ...loginButtonStyles.centered }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <GoogleLoginButton
              signIn={signInGoogle}
              sx={loginButtonStyles}
              label="Continue with Google"
            />
            <MicrosoftLoginButton
              onSuccess={microsoftSuccessHandler}
              clientId={process.env?.REACT_APP_MICROSOFT_CLIENT_ID || ''}
              sx={loginButtonStyles}
              label="Continue with Microsoft"
            />

            <Typography sx={loginButtonStyles.termsAndConditions}>
              By signing in, you agree to our{' '}
              <a
                target="_blank"
                href="https://www.prometeus.finance/terms-and-conditions"
                rel="noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                href="https://www.prometeus.finance/privacy-policy"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Typography>
            {isDev() && (
              <Stack direction="row" spacing={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onDevSignIn('admin')}
                  sx={{
                    ...loginButtonStyles.button,
                  }}
                >
                  Dev Admin
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onDevSignIn('user')}
                  sx={{
                    ...loginButtonStyles.button,
                  }}
                >
                  Dev User
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      }
      sx={
        isPortraitMobile
          ? {
              transform: 'scale(1.5)',
            }
          : {}
      }
    />
  );
};

const BORDER_BUTTON_STYLE = (theme: Theme) => ({
  color: COMMON_COLORS.contrastPrimaryText,
  backgroundColor: theme.palette.primary.main,
  borderColor: COMMON_COLORS.contrastPrimaryText,

  '& svg *': {
    fill: `${COMMON_COLORS.contrastPrimaryText} !important`,
  },
});

const BUTTON_STYLE = (theme: Theme) => ({
  fontSize: '1.05rem',
  borderRadius: '3rem !important',
  border: `0.1rem solid transparent`,
  fontWeight: 600,
  backgroundColor: COMMON_COLORS.contrastPrimaryText,
  '&:hover': {
    color: COMMON_COLORS.contrastPrimaryText,
    backgroundColor: theme.palette.primary.main,
    borderColor: COMMON_COLORS.contrastPrimaryText,
  },
});

type ButtonProps = {
  sx?: SxProps;
};

export const LogInButtonComponent = ({ sx }: ButtonProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Button
      variant="text"
      sx={{
        ...BUTTON_STYLE(theme),
        ...BORDER_BUTTON_STYLE(theme),
        '&:hover': {
          opacity: '0.6',
        },
        ...sx,
      }}
      onClick={() => {
        dispatch(openSignInDialog());
      }}
    >
      Log In
    </Button>
  );
};

export const SignUpButtonComponent = ({ sx }: ButtonProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Button
      variant="text"
      sx={{
        ...BUTTON_STYLE(theme),
        '&:hover': {
          ...BORDER_BUTTON_STYLE(theme),
          opacity: 0.6,
        },
        ...sx,
      }}
      onClick={() => {
        dispatch(openSignInDialog());
      }}
    >
      Sign Up
    </Button>
  );
};

export default SignInDialogComponent;
