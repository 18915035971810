import { Palette } from '@mui/material';
import {
  APP_BAR_HEIGHT_REM,
  COMPANY_HEADER_HEIGHT_REM,
  TAB_BAR_HEIGHT_REM,
} from '../constants/general.constants';
import { COMMON_COLORS } from '../ui/colors';
import { formBorder } from '../ui/functions';

export const getTabStyles = (palette: Palette) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  skeletonContainer: {
    // boxShadow: boxShadow(theme),
    borderBottom: formBorder(palette),
  },
  containerCompanyHeader: {
    display: 'flex',
  },
  tabsRoot: {
    // backgroundColor: palette.background.paper,
    backgroundColor: 'transparent',
    height: `${TAB_BAR_HEIGHT_REM}rem`,
    position: 'sticky',
    paddingLeft: '0.5rem',
    top: 0.001,
    '& a': {
      padding: '0.3rem 1rem',
      color: palette.text.primary,
      fontSize: '1rem',
      maxWidth: 'max-content',
    },
    '& a.Mui-selected': {
      color: COMMON_COLORS.lightBlue,
      // color: palette.primary.main,
    },
    '& a:hover': {
      color: COMMON_COLORS.lightBlue,
      // color: palette.primary.main,
    },
  },
  indicator: {
    // display: 'none',
  },
  tabView: {
    margin: '0.5rem 1rem 1rem',
  },
  tabViewCompanyHeader: {
    height: `calc(97.5vh - ${APP_BAR_HEIGHT_REM + TAB_BAR_HEIGHT_REM + COMPANY_HEADER_HEIGHT_REM
      }rem)`,
  },
  tabViewNoCompanyHeader: {
    height: `calc(97.5vh - ${APP_BAR_HEIGHT_REM + TAB_BAR_HEIGHT_REM}rem)`,
  },
  spinner: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    flex: '1 1 0',
    position: 'sticky' as 'sticky',
    top: 0,
  },
  filterColumn: {
    height: '99.4%',
  },
  filterColumnHome: {
    height: '93.4%',
  },
  macroColumn: {
    flex: '3 1 0',
    minWidth: 0,
  },
  displayFlex: {
    display: 'flex',
  },
  typographyAlign: {
    textAlign: 'left',
  },
  overflowY: {
    overflowY: 'auto',
  },
  tabsPageMacrocolumn: {
    // '-ms-overflow-style': 'none', // IE, Edge
    msOverflowStyle: 'none',
    scrollbarWidth: 'none', // Firefox

    '&::-webkit-scrollbar': {
      // Chrome, Safari, Opera
      display: 'none',
    },
  },
});

export const a11yTabProps = (id: string, index: any) => {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  };
};
