import {
  IVisualChart,
  IVisualChartRank,
  IVisualPayload,
} from '../../../models/visuals.model';

export interface IVisualsState {
  /* Revenue & Profit */
  revenueVsIncomeData?: IVisualChart;
  revenueVsIncomeDataUpdating?: boolean;
  revenueGrowthYoyData?: IVisualChart;
  revenueGrowthYoyDataUpdating?: boolean;
  revenueRankData?: IVisualChartRank;
  revenueRankDataUpdating?: boolean;
  revenueRankFullData?: IVisualChartRank;
  profitabilityRankData?: IVisualChartRank;
  profitabilityRankDataUpdating?: boolean;
  profitabilityRankFullData?: IVisualChartRank;
  annualRevenueGrowthRateData?: IVisualChart;
  annualRevenueGrowthRateDataUpdating?: boolean;

  /* Profit Margin and Gross Margin */
  profitMarginData?: IVisualChart;
  profitMarginDataUpdating?: boolean;
  profitMarginDistributionData?: IVisualChart;
  profitMarginDistributionDataUpdating?: boolean;
  grossMarginData?: IVisualChart;
  grossMarginDataUpdating?: boolean;
  grossMarginDistributionData?: IVisualChart;
  grossMarginDistributionDataUpdating?: boolean;

  /* Operating Expenses */
  operatingIncomeOnGrossProfitData?: IVisualChart;
  operatingIncomeOnGrossProfitDataUpdating?: boolean;
  operationExpensesRDData?: IVisualChart;
  operationExpensesRDDataUpdating?: boolean;
  operationExpensesSGAData?: IVisualChart;
  operationExpensesSGADataUpdating?: boolean;
  operationExpensesAmortDeprData?: IVisualChart;
  operationExpensesAmortDeprDataUpdating?: boolean;
  operatingIncomeDistributionData?: IVisualChart;
  operatingIncomeDistributionDataUpdating?: boolean;
  averageOperationExpensesDistributionData?: IVisualChart;
  averageOperationExpensesDistributionDataUpdating?: boolean;

  /* Statements */
  statementsData?: any;
  statementsDataUpdating?: boolean;
}

/* INCOME_STATEMENT_ANALYSIS_UPDATE */
export const INCOME_STATEMENT_ANALYSIS_UPDATE =
  'INCOME_STATEMENT_ANALYSIS_UPDATE';
export interface IIncomeStatementAnalysisUpdate {
  type: typeof INCOME_STATEMENT_ANALYSIS_UPDATE;
  payload: string[];
}

/***************************************
 *           Revenue & Profit          *
 ***************************************/
/* REVENUE_VS_INCOME_QUARTERLY_DATA */
export const REVENUE_VS_INCOME_QUARTERLY_DATA =
  'REVENUE_VS_INCOME_QUARTERLY_DATA';
export interface RevenueVsIncomeQuarterlyDataI {
  type: typeof REVENUE_VS_INCOME_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* REVENUE_VS_INCOME_YEARLY_DATA */
export const REVENUE_VS_INCOME_YEARLY_DATA = 'REVENUE_VS_INCOME_YEARLY_DATA';
export interface RevenueVsIncomeYearlyDataI {
  type: typeof REVENUE_VS_INCOME_YEARLY_DATA;
  payload: IVisualPayload;
}

/* REVENUE_GROWTH_YOY_QUARTERLY_DATA */
export const REVENUE_GROWTH_YOY_QUARTERLY_DATA =
  'REVENUE_GROWTH_YOY_QUARTERLY_DATA';
export interface RevenueGrowthYoyQuarterlyDataI {
  type: typeof REVENUE_GROWTH_YOY_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* REVENUE_GROWTH_YOY_YEARLY_DATA */
export const REVENUE_GROWTH_YOY_YEARLY_DATA = 'REVENUE_GROWTH_YOY_YEARLY_DATA';
export interface RevenueGrowthYoyYearlyDataI {
  type: typeof REVENUE_GROWTH_YOY_YEARLY_DATA;
  payload: IVisualPayload;
}

/* REVENUE_RANK_DATA */
export const REVENUE_RANK_DATA = 'REVENUE_RANK_DATA';
export interface RevenueRankDataI {
  type: typeof REVENUE_RANK_DATA;
  payload: IVisualPayload;
}

/* PROFITABILITY_RANK_DATA */
export const PROFITABILITY_RANK_DATA = 'PROFITABILITY_RANK_DATA';
export interface ProfitabilityRankDataI {
  type: typeof PROFITABILITY_RANK_DATA;
  payload: IVisualPayload;
}

/* ANNUAL_REVENUE_GROWTH_RATE_DATA */
export const ANNUAL_REVENUE_GROWTH_RATE_DATA =
  'ANNUAL_REVENUE_GROWTH_RATE_DATA';
export interface AnnualRevenueGrowthRateDataI {
  type: typeof ANNUAL_REVENUE_GROWTH_RATE_DATA;
  payload: IVisualPayload;
}

/* REVENUE_RANK_FULL_DATA */
export const REVENUE_RANK_FULL_DATA = 'REVENUE_RANK_FULL_DATA';
export interface RevenueRankFullDataI {
  type: typeof REVENUE_RANK_FULL_DATA;
  payload: IVisualPayload;
}

/* PROFITABILITY_RANK_FULL_DATA */
export const PROFITABILITY_RANK_FULL_DATA = 'PROFITABILITY_RANK_FULL_DATA';
export interface ProfitabilityRankFullDataI {
  type: typeof PROFITABILITY_RANK_FULL_DATA;
  payload: IVisualPayload;
}

/***************************************
 *    Profit Margin And Gross Margin   *
 ***************************************/
/* PROFIT_MARGIN_YEARLY_DATA */
export const PROFIT_MARGIN_YEARLY_DATA = 'PROFIT_MARGIN_YEARLY_DATA';
export interface ProfitMarginYearlyDataI {
  type: typeof PROFIT_MARGIN_YEARLY_DATA;
  payload: IVisualPayload;
}

/* PROFIT_MARGIN_QUARTERLY_DATA */
export const PROFIT_MARGIN_QUARTERLY_DATA = 'PROFIT_MARGIN_QUARTERLY_DATA';
export interface ProfitMarginQuarterlyDataI {
  type: typeof PROFIT_MARGIN_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* PROFIT_MARGIN_DISTRIBUTION_DATA */
export const PROFIT_MARGIN_DISTRIBUTION_DATA =
  'PROFIT_MARGIN_DISTRIBUTION_DATA';
export interface ProfitMarginDistributionDataI {
  type: typeof PROFIT_MARGIN_DISTRIBUTION_DATA;
  payload: IVisualPayload;
}

/* GROSS_MARGIN_YEARLY_DATA */
export const GROSS_MARGIN_YEARLY_DATA = 'GROSS_MARGIN_YEARLY_DATA';
export interface GrossMarginYearlyDataI {
  type: typeof GROSS_MARGIN_YEARLY_DATA;
  payload: IVisualPayload;
}

/* GROSS_MARGIN_QUARTERLY_DATA */
export const GROSS_MARGIN_QUARTERLY_DATA = 'GROSS_MARGIN_QUARTERLY_DATA';
export interface GrossMarginQuarterlyDataI {
  type: typeof GROSS_MARGIN_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* GROSS_MARGIN_DISTRIBUTION_DATA */
export const GROSS_MARGIN_DISTRIBUTION_DATA = 'GROSS_MARGIN_DISTRIBUTION_DATA';
export interface GrossMarginDistributionDataI {
  type: typeof GROSS_MARGIN_DISTRIBUTION_DATA;
  payload: IVisualPayload;
}

/***************************************
 *          Operating Expenses         *
 ***************************************/
/* OPERATING_INCOME_DISTRIBUTION_DATA */
export const OPERATING_INCOME_DISTRIBUTION_DATA =
  'OPERATING_INCOME_DISTRIBUTION_DATA';
export interface OperatingIncomeDistributionDataI {
  type: typeof OPERATING_INCOME_DISTRIBUTION_DATA;
  payload: IVisualPayload;
}

/* AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA */
export const AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA =
  'AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA';
export interface AverageOperationExpensesDistributionDataI {
  type: typeof AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA;
  payload: IVisualPayload;
}

/* OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA */
export const OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA =
  'OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA';
export interface OperatingIncomeOnGrossProfitQuarterlyDataI {
  type: typeof OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA */
export const OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA =
  'OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA';
export interface OperatingIncomeOnGrossProfitYearlyDataI {
  type: typeof OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_RD_QUARTERLY_DATA */
export const OPERATION_EXPENSES_RD_QUARTERLY_DATA =
  'OPERATION_EXPENSES_RD_QUARTERLY_DATA';
export interface OperationExpensesRDQuarterlyDataI {
  type: typeof OPERATION_EXPENSES_RD_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_RD_YEARLY_DATA */
export const OPERATION_EXPENSES_RD_YEARLY_DATA =
  'OPERATION_EXPENSES_RD_YEARLY_DATA';
export interface OperationExpensesRDYearlyDataI {
  type: typeof OPERATION_EXPENSES_RD_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_SGA_QUARTERLY_DATA */
export const OPERATION_EXPENSES_SGA_QUARTERLY_DATA =
  'OPERATION_EXPENSES_SGA_QUARTERLY_DATA';
export interface OperationExpensesSGAQuarterlyDataI {
  type: typeof OPERATION_EXPENSES_SGA_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_SGA_YEARLY_DATA */
export const OPERATION_EXPENSES_SGA_YEARLY_DATA =
  'OPERATION_EXPENSES_SGA_YEARLY_DATA';
export interface OperationExpensesSGAYearlyDataI {
  type: typeof OPERATION_EXPENSES_SGA_YEARLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA */
export const OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA =
  'OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA';
export interface OpeartionExpensesAmortDeprQuarterlyDataI {
  type: typeof OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA;
  payload: IVisualPayload;
}

/* OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA */
export const OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA =
  'OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA';
export interface OpeartionExpensesAmortDeprYearlyDataI {
  type: typeof OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *              Statements             *
 ***************************************/
/* IS_STATEMENTS_YEARLY_DATA */
export const IS_STATEMENTS_YEARLY_DATA = 'IS_STATEMENTS_YEARLY_DATA';
export interface ISStatementsYearlyDataI {
  type: typeof IS_STATEMENTS_YEARLY_DATA;
  payload: IVisualPayload;
}

/* IS_STATEMENTS_QUARTERLY_DATA */
export const IS_STATEMENTS_QUARTERLY_DATA = 'IS_STATEMENTS_QUARTERLY_DATA';
export interface ISStatementsQuarterlyDataI {
  type: typeof IS_STATEMENTS_QUARTERLY_DATA;
  payload: IVisualPayload;
}

export type DispatchActionTypes =
  | IIncomeStatementAnalysisUpdate
  /* Revenue & Profit */
  | RevenueVsIncomeQuarterlyDataI
  | RevenueGrowthYoyQuarterlyDataI
  | RevenueVsIncomeYearlyDataI
  | RevenueGrowthYoyYearlyDataI
  | RevenueRankDataI
  | ProfitabilityRankDataI
  | AnnualRevenueGrowthRateDataI
  | RevenueRankFullDataI
  | ProfitabilityRankFullDataI
  /* Profit Margin and Gross Margin */
  | ProfitMarginYearlyDataI
  | ProfitMarginQuarterlyDataI
  | ProfitMarginDistributionDataI
  | GrossMarginYearlyDataI
  | GrossMarginQuarterlyDataI
  | GrossMarginDistributionDataI
  /* Operating Expenses */
  | OperatingIncomeDistributionDataI
  | AverageOperationExpensesDistributionDataI
  | OperatingIncomeOnGrossProfitQuarterlyDataI
  | OperatingIncomeOnGrossProfitYearlyDataI
  | OperationExpensesRDQuarterlyDataI
  | OperationExpensesRDYearlyDataI
  | OperationExpensesSGAQuarterlyDataI
  | OperationExpensesSGAYearlyDataI
  | OpeartionExpensesAmortDeprQuarterlyDataI
  | OpeartionExpensesAmortDeprYearlyDataI
  /* Statements */
  | ISStatementsYearlyDataI
  | ISStatementsQuarterlyDataI;
