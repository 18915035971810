import {
  DispatchActionTypes,
  GET_LOGGED_USER_DATA,
  GET_LOGGED_USER_DATA_FAIL,
  GET_LOGGED_USER_DATA_SUCCESS,
  IAuthenticationState,
  RENEW_TOKEN,
  RENEW_TOKEN_FAIL,
  SET_HAS_ACTIVE_LICENCE,
  SET_HAS_RATED,
  SET_IS_ADMIN,
  SET_IS_FIRST_LOGIN,
  SIGN_IN,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
} from '../../models/authentication.model';

const initialState: IAuthenticationState = {
  isAdmin: false,
  renewingToken: false,
};

const authenticationReducer = (
  state: IAuthenticationState = initialState,
  action: DispatchActionTypes
): IAuthenticationState => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isAdmin: !!action.payload.isAdmin,
        hasActiveLicence: action.payload.hasActiveLicence,
        loading: false,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        accessToken: undefined,
        loading: false,
        error: action.payload,
      };
    case SET_HAS_ACTIVE_LICENCE:
      return {
        ...state,
        hasActiveLicence: action.payload,
      };
    case SIGN_OUT:
      return initialState;
    case RENEW_TOKEN:
      return {
        ...state,
        renewingToken: true,
        error: undefined,
      };
    case RENEW_TOKEN_FAIL: {
      return {
        ...state,
        renewingToken: false,
        error: action.payload,
      };
    }
    case SET_IS_FIRST_LOGIN: {
      return {
        ...state,
        isFirstLogin: action.payload,
      };
    }
    case SET_HAS_RATED:
      return {
        ...state,
        hasRated: action.payload,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case GET_LOGGED_USER_DATA:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GET_LOGGED_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_LOGGED_USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
