import { MD5 } from 'object-hash';
import {
  makeUpdatingVisuals,
  processStatementsData,
  processVisualData,
  processVisualRankData,
} from '../../../constants/charts/visuals.constants';
import {
  IChartRawData,
  IVisualConfigData,
  IVisualPayload,
} from '../../../models/visuals.model';
import {
  ANNUAL_REVENUE_GROWTH_RATE_DATA,
  AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA,
  DispatchActionTypes,
  GROSS_MARGIN_DISTRIBUTION_DATA,
  GROSS_MARGIN_QUARTERLY_DATA,
  GROSS_MARGIN_YEARLY_DATA,
  INCOME_STATEMENT_ANALYSIS_UPDATE,
  IS_STATEMENTS_QUARTERLY_DATA,
  IS_STATEMENTS_YEARLY_DATA,
  IVisualsState,
  OPERATING_INCOME_DISTRIBUTION_DATA,
  OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA,
  OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA,
  OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA,
  OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA,
  OPERATION_EXPENSES_RD_QUARTERLY_DATA,
  OPERATION_EXPENSES_RD_YEARLY_DATA,
  OPERATION_EXPENSES_SGA_QUARTERLY_DATA,
  OPERATION_EXPENSES_SGA_YEARLY_DATA,
  PROFITABILITY_RANK_DATA,
  PROFIT_MARGIN_DISTRIBUTION_DATA,
  PROFIT_MARGIN_QUARTERLY_DATA,
  PROFIT_MARGIN_YEARLY_DATA,
  REVENUE_GROWTH_YOY_QUARTERLY_DATA,
  REVENUE_GROWTH_YOY_YEARLY_DATA,
  REVENUE_RANK_DATA,
  REVENUE_VS_INCOME_QUARTERLY_DATA,
  REVENUE_VS_INCOME_YEARLY_DATA,
} from './visuals.model';

const CHART_ACTIONS_SET = new Set([INCOME_STATEMENT_ANALYSIS_UPDATE]);

const initialState: IVisualsState = {};

const incomeStatementAnalysisVisualsReducer = (
  state: IVisualsState = initialState,
  action: DispatchActionTypes
): IVisualsState => {
  if (CHART_ACTIONS_SET.has(action.type)) {
    switch (action.type) {
      case INCOME_STATEMENT_ANALYSIS_UPDATE:
        return {
          ...state,
          ...makeUpdatingVisuals(state, action.payload),
        };
      default:
        return state;
    }
  } else {
    const actionPayload: IVisualPayload = action.payload as IVisualPayload;

    const configData: IVisualConfigData = actionPayload?.configData;
    const data: IChartRawData = actionPayload?.data;
    const hashedData: string = MD5(data || '');

    switch (action.type) {
      /***************************************
       *        C-1 - Revenue & Profit       *
       ***************************************/
      case REVENUE_VS_INCOME_YEARLY_DATA:
        if (hashedData === state.revenueVsIncomeData?.checksum) {
          return { ...state, revenueVsIncomeDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          revenueVsIncomeData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          revenueVsIncomeDataUpdating: false,
        };
      case REVENUE_VS_INCOME_QUARTERLY_DATA:
        if (hashedData === state.revenueVsIncomeData?.checksum) {
          return { ...state, revenueVsIncomeDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          revenueVsIncomeData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          revenueVsIncomeDataUpdating: false,
        };
      case REVENUE_GROWTH_YOY_YEARLY_DATA:
        if (hashedData === state.revenueGrowthYoyData?.checksum) {
          return { ...state, revenueGrowthYoyDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          revenueGrowthYoyData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          revenueGrowthYoyDataUpdating: false,
        };
      case REVENUE_GROWTH_YOY_QUARTERLY_DATA:
        if (hashedData === state.revenueGrowthYoyData?.checksum) {
          return { ...state, revenueGrowthYoyDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          revenueGrowthYoyData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          revenueGrowthYoyDataUpdating: false,
        };
      case REVENUE_RANK_DATA:
        if (hashedData === state.revenueRankData?.checksum) {
          return { ...state, revenueRankDataUpdating: false };
        }

        const { full: revenueFull, reduced: revenueReduced } =
          processVisualRankData(data, configData);

        /* State update */
        return {
          ...state,
          revenueRankData: {
            ...revenueReduced,
            checksum: hashedData,
          },
          revenueRankFullData: {
            ...revenueFull,
            checksum: hashedData,
          },
          revenueRankDataUpdating: false,
        };
      case PROFITABILITY_RANK_DATA:
        if (hashedData === state.profitabilityRankData?.checksum) {
          return { ...state, profitabilityRankDataUpdating: false };
        }

        const { full: profitabilityFull, reduced: profitabilityReduced } =
          processVisualRankData(data, configData);

        /* State update */
        return {
          ...state,
          profitabilityRankData: {
            ...profitabilityReduced,
            checksum: hashedData,
          },
          profitabilityRankFullData: {
            ...profitabilityFull,
            checksum: hashedData,
          },
          profitabilityRankDataUpdating: false,
        };
      case ANNUAL_REVENUE_GROWTH_RATE_DATA:
        if (hashedData === state.annualRevenueGrowthRateData?.checksum) {
          return { ...state, annualRevenueGrowthRateDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          annualRevenueGrowthRateData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          annualRevenueGrowthRateDataUpdating: false,
        };

      /***************************************
       *  C-2 Profit Margin and Gross Margin *
       ***************************************/
      case PROFIT_MARGIN_YEARLY_DATA:
        if (hashedData === state.profitMarginData?.checksum) {
          return { ...state, profitMarginDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          profitMarginData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          profitMarginDataUpdating: false,
        };
      case PROFIT_MARGIN_QUARTERLY_DATA:
        if (hashedData === state.profitMarginData?.checksum) {
          return { ...state, profitMarginDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          profitMarginData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          profitMarginDataUpdating: false,
        };
      case PROFIT_MARGIN_DISTRIBUTION_DATA:
        if (hashedData === state.profitMarginDistributionData?.checksum) {
          return { ...state, profitMarginDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          profitMarginDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          profitMarginDistributionDataUpdating: false,
        };
      case GROSS_MARGIN_YEARLY_DATA:
        if (hashedData === state.grossMarginData?.checksum) {
          return { ...state, grossMarginDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          grossMarginData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          grossMarginDataUpdating: false,
        };
      case GROSS_MARGIN_QUARTERLY_DATA:
        if (hashedData === state.grossMarginData?.checksum) {
          return { ...state, grossMarginDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          grossMarginData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          grossMarginDataUpdating: false,
        };
      case GROSS_MARGIN_DISTRIBUTION_DATA:
        if (hashedData === state.grossMarginDistributionData?.checksum) {
          return { ...state, grossMarginDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          grossMarginDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          grossMarginDistributionDataUpdating: false,
        };

      /***************************************
       *       C-3 - Operating Expenses      *
       ***************************************/
      case OPERATING_INCOME_DISTRIBUTION_DATA:
        if (hashedData === state.operatingIncomeDistributionData?.checksum) {
          return { ...state, operatingIncomeDistributionDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operatingIncomeDistributionData: {
            ...processVisualData(data, configData),
            checksum: hashedData,
          },
          operatingIncomeDistributionDataUpdating: false,
        };
      case AVERAGE_OPERATION_EXPENSES_DISTRIBUTION_DATA:
        if (
          hashedData ===
          state.averageOperationExpensesDistributionData?.checksum
        ) {
          return {
            ...state,
            averageOperationExpensesDistributionDataUpdating: false,
          };
        }

        /* State update */
        return {
          ...state,
          averageOperationExpensesDistributionData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          averageOperationExpensesDistributionDataUpdating: false,
        };
      case OPERATING_INCOME_ON_GROSS_PROFIT_QUARTERLY_DATA:
        if (hashedData === state.operatingIncomeOnGrossProfitData?.checksum) {
          return { ...state, operatingIncomeOnGrossProfitDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operatingIncomeOnGrossProfitData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operatingIncomeOnGrossProfitDataUpdating: false,
        };
      case OPERATING_INCOME_ON_GROSS_PROFIT_YEARLY_DATA:
        if (hashedData === state.operatingIncomeOnGrossProfitData?.checksum) {
          return { ...state, operatingIncomeOnGrossProfitDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operatingIncomeOnGrossProfitData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operatingIncomeOnGrossProfitDataUpdating: false,
        };
      case OPERATION_EXPENSES_RD_QUARTERLY_DATA:
        if (hashedData === state.operationExpensesRDData?.checksum) {
          return { ...state, operationExpensesRDDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesRDData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesRDDataUpdating: false,
        };
      case OPERATION_EXPENSES_RD_YEARLY_DATA:
        if (hashedData === state.operationExpensesRDData?.checksum) {
          return { ...state, operationExpensesRDDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesRDData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesRDDataUpdating: false,
        };
      case OPERATION_EXPENSES_SGA_QUARTERLY_DATA:
        if (hashedData === state.operationExpensesSGAData?.checksum) {
          return { ...state, operationExpensesSGADataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesSGAData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesSGADataUpdating: false,
        };
      case OPERATION_EXPENSES_SGA_YEARLY_DATA:
        if (hashedData === state.operationExpensesSGAData?.checksum) {
          return { ...state, operationExpensesSGADataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesSGAData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesSGADataUpdating: false,
        };
      case OPERATION_EXPENSES_AMORT_DEPR_QUARTERLY_DATA:
        if (hashedData === state.operationExpensesAmortDeprData?.checksum) {
          return { ...state, operationExpensesAmortDeprDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesAmortDeprData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesAmortDeprDataUpdating: false,
        };
      case OPERATION_EXPENSES_AMORT_DEPR_YEARLY_DATA:
        if (hashedData === state.operationExpensesAmortDeprData?.checksum) {
          return { ...state, operationExpensesAmortDeprDataUpdating: false };
        }

        /* State update */
        return {
          ...state,
          operationExpensesAmortDeprData: {
            ...processVisualData(data, configData, true),
            checksum: hashedData,
          },
          operationExpensesAmortDeprDataUpdating: false,
        };
      case IS_STATEMENTS_YEARLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      case IS_STATEMENTS_QUARTERLY_DATA:
        if (hashedData === state.statementsData?.checksum) {
          return { ...state, statementsDataUpdating: false };
        }

        /* State Update */
        return {
          ...state,
          statementsData: {
            map: processStatementsData(data),
            // tsvData: data,
            checksum: hashedData,
          },
          statementsDataUpdating: false,
        };
      default:
        return state;
    }
  }
};

export default incomeStatementAnalysisVisualsReducer;
