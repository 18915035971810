import { IStructureMacrosection } from '@prometeus/common';
export interface ISectionsMap {
  [sectionId: string]: {
    timeframeFilterQY?: boolean;
    timeframeFilterDWMQ?: boolean;
    changeCurrency?: boolean;
    filters: string[];
  };
}

export enum ChartTextSizeEnum {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

/***************************************
 *                 State               *
 ***************************************/
export interface IStructureState {
  structure: IStructureMacrosection[];
  sections?: ISectionsMap;
  loading: boolean;
  error?: string;
  displayName?: string;
  screenWidth: number;
  screenHeight: number;
}

/***************************************************************************************************
 *                                               Redux                                             *
 ***************************************************************************************************/

/* SET_SCREEN_SIZE */
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export interface ISetScreenWidth {
  type: typeof SET_SCREEN_SIZE;
  payload: [number, number];
}

/* SET_DISPLAY_NAME */
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';

export interface SetDisplayNameI {
  type: typeof SET_DISPLAY_NAME;
  payload: string;
}

/* SET_STRUCTURE_ERROR */
export const SET_STRUCTURE_ERROR = 'SET_STRUCTURE_ERROR';
export interface ISetStructureError {
  type: typeof SET_STRUCTURE_ERROR;
  payload?: string;
}

/* DispatchActionTypes */
export type DispatchActionTypes =
  | SetDisplayNameI
  | ISetStructureError
  | ISetScreenWidth;
