export interface IGlobalError {
  message: string;
}

export interface IErrorHandlingState {
  error?: IGlobalError;
  axiosError?: IGlobalError;
}

/***************************************
 *                 Redux               *
 ***************************************/
/* SET_GLOBAL_ERROR */
export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';
export interface ISetGlobalError {
  type: typeof SET_GLOBAL_ERROR;
  payload: IGlobalError;
}

/* SET_AXIOS_ERROR */
export const SET_AXIOS_ERROR = 'SET_AXIOS_ERROR';
export interface ISetAxiosError {
  type: typeof SET_AXIOS_ERROR;
  payload: IGlobalError;
}

export type DispatchActionTypes = ISetGlobalError | ISetAxiosError;
