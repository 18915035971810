import { Dispatch } from 'redux';
import { PeerGroupEnum } from './peer-group.model';

/***************************************************************************************************
 *                                               Types                                             *
 ***************************************************************************************************/

export enum TimeframeEnum {
  YEARLY = 'yearly',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
}

export type QYFilter = TimeframeEnum.YEARLY | TimeframeEnum.QUARTERLY;

export type DWMQFilter =
  | TimeframeEnum.DAILY
  | TimeframeEnum.WEEKLY
  | TimeframeEnum.MONTHLY
  | TimeframeEnum.QUARTERLY;

export type TimeframeFilter = DWMQFilter | QYFilter;

export enum RenderTypeEnum {
  FILTER_PAGE,
  TIMEFRAME_FILTER,
  TOP_FILTER,
  BOTTOM_FILTER,
}

export enum PageIdEnum {
  COUNTRY_ANALYSIS = 'A-2',
  SECTOR_ANALYSIS = 'A-3',
  INDUSTRY_ANALYSIS = 'A-4',
  COMPANY_SUMMARY = 'B-0',
  COMPANY_STOCKS = 'B-1',
  COMPANY_DIVIDENDS = 'B-2',
  REVEUE_AND_PROFIT = 'C-1',
  PROFIT_MARGIN_AND_GROSS_MARGIN = 'C-2',
  OPERATING_EXPENSES = 'C-3',
  IS_STATEMENT = 'C-4',
  BS_OVERVIEW = 'D-1',
  CURRENT_ASSETS_AND_LIABILITIES = 'D-2',
  NONCURRENT_ASSETS_AND_LIABILITIES = 'D-3',
  EQUITY = 'D-4',
  BS_STATEMENT = 'D-5',
  CASH_FLOW = 'E-1',
  CF_STATEMENT = 'E-2',
  ROS_ROE = 'F-1',
  ROA_ROIC = 'F-2',
  CURRENT_QUICK_RATIO_CAPEX = 'G-1',
  DEBT_TO_EQUITY_DEBT_PAYBACK = 'G-2',
}

/***************************************************************************************************
 *                                               State                                             *
 ***************************************************************************************************/

export interface IFiltersState {
  currentPage?: PageIdEnum;

  /* Filters */
  tickerId?: string;
  fiscalYear: [number, number];
  activeQYFilter: QYFilter;
  activeDWMQFilter: DWMQFilter;
  peerGroup: PeerGroupEnum;
  useDollars: boolean;
  peers: string[];

  /* Status */
  loading: boolean;
  error?: string;
}

/***************************************************************************************************
 *                                               Redux                                             *
 ***************************************************************************************************/
/* FETCH_CHART_DATA */
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA';

export interface FetchChartDataI {
  type: typeof FETCH_CHART_DATA;
}

export const FETCH_CHART_DATA_SUCCESS = 'FETCH_CHART_DATA_SUCCESS';

export interface FetchChartDataSuccessI {
  type: typeof FETCH_CHART_DATA_SUCCESS;
}

export const FETCH_CHART_DATA_FAIL = 'FETCH_CHART_DATA_FAIL';

export interface FetchChartDataFailI {
  type: typeof FETCH_CHART_DATA_FAIL;
  payload: string; // error
}

export type FetchChartDataDispatchType =
  | FetchChartDataI
  | FetchChartDataSuccessI
  | FetchChartDataFailI;

/* SET_PAGE */
export const SET_PAGE = 'SET_PAGE';

export interface SetPageI {
  type: typeof SET_PAGE;
  payload: PageIdEnum;
  asyncDispatch: Dispatch;
}

/* SET_QY_FILTER */
export const SET_QY_FILTER = 'SET_QY_FILTER';
export interface SetQYFilterI {
  type: typeof SET_QY_FILTER;
  payload: QYFilter;
  asyncDispatch: Dispatch;
}

/* SET_DWMQ_FILTER */
export const SET_DWMQ_FILTER = 'SET_DWMQ_FILTER';
export interface SetDWMQFilterI {
  type: typeof SET_DWMQ_FILTER;
  payload: DWMQFilter;
  asyncDispatch: Dispatch;
}

/* SET_TICKER_ID */
export const SET_TICKER_ID = 'SET_TICKER_ID';
export interface ISetTickerId {
  type: typeof SET_TICKER_ID;
  payload: {
    tickerId: string;
    skipFetch?: boolean;
  };
  asyncDispatch: Dispatch;
}

/* SET_FISCAL_YEAR */
export const SET_FISCAL_YEAR = 'SET_FISCAL_YEAR';
export interface ISetFiscalYear {
  type: typeof SET_FISCAL_YEAR;
  payload: [number, number];
  asyncDispatch: Dispatch;
}

/* SET_PEER_GROUP */
export const SET_PEER_GROUP = 'SET_PEER_GROUP';
export interface ISetPeerGroup {
  type: typeof SET_PEER_GROUP;
  payload: PeerGroupEnum;
  asyncDispatch: Dispatch;
}

/* SET_PEERS */
export const SET_PEERS = 'SET_PEERS';
export interface ISetPeers {
  type: typeof SET_PEERS;
  payload: { peers: string[]; allowFetch?: boolean };
  asyncDispatch: Dispatch;
}

/* SET_USE_DOLLARS */
export const SET_USE_DOLLARS = 'SET_USE_DOLLARS';
export interface SetUseDollarsI {
  type: typeof SET_USE_DOLLARS;
  payload: boolean;
}

/* SET_TIME_WINDOW */
export const SET_TIME_WINDOW = 'SET_TIME_WINDOW';
export interface ISetTimeWindow {
  type: typeof SET_TIME_WINDOW;
  payload: [number, number];
  asyncDispatch: Dispatch;
}

/* SET_TOKEN_FILTERS */
export const SET_TOKEN_FILTERS = 'SET_TOKEN_FILTERS';
export interface ISetTokenFilters {
  type: typeof SET_TOKEN_FILTERS;
  asyncDispatch: Dispatch;
}

/* DispatchActionTypes */
export type DispatchActionTypes =
  | SetQYFilterI
  | SetDWMQFilterI
  | SetUseDollarsI
  | ISetFiscalYear
  | ISetTickerId
  | ISetPeers
  | ISetPeerGroup
  | SetPageI
  | FetchChartDataDispatchType
  | ISetTimeWindow
  | ISetTokenFilters;
