import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { createChartDatasetsConfig } from '../../../constants/charts/chart.constants';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IOpenLargeChart } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { openLargeChartDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS } from '../utils/visuals.config';

const CurrentQuickRatioCapexTab = () => {
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const dispatch = useDispatch();
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );

  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /***************************************
   *              Chars Data             *
   ***************************************/
  const currentRatioData = useSelector(
    (state: RootState) => state.visuals.financialStrength.currentRatioData
  );
  const currentRatioDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.currentRatioDataUpdating
  );
  const currentRatioDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.currentRatioDistributionData
  );
  const currentRatioDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.currentRatioDistributionDataUpdating
  );
  const quickRatioData = useSelector(
    (state: RootState) => state.visuals.financialStrength.quickRatioData
  );
  const quickRatioDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.quickRatioDataUpdating
  );
  const quickRatioDistributionData = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.quickRatioDistributionData
  );
  const quickRatioDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.quickRatioDistributionDataUpdating
  );
  const capexData = useSelector(
    (state: RootState) => state.visuals.financialStrength.capexData
  );
  const capexDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.capexDataUpdating
  );
  const capexDistributionData = useSelector(
    (state: RootState) => state.visuals.financialStrength.capexDistributionData
  );
  const capexDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.capexDistributionDataUpdating
  );

  const [currentRatio, setCurrentRatio] = useState<ChartData>();
  const [currentRatioDistribution, setCurrentRatioDistribution] =
    useState<ChartData>();
  const [quickRatio, setQuickRatio] = useState<ChartData>();
  const [quickRatioDistribution, setQuickRatioDistribution] =
    useState<ChartData>();
  const [capex, setCapex] = useState<ChartData>();
  const [capexDistribution, setCapexDistribution] = useState<ChartData>();

  /* Current Ratio */
  const [currentRatioHasData, currentRatioHasLabels, currentRatioError] =
    extractChartMetadata(currentRatioData);
  useEffect(() => {
    setCurrentRatio({
      datasets: createChartDatasetsConfig(
        currentRatioData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [currentRatioData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Quick Ratio */
  const [quickRatioHasData, quickRatioHasLabels, quickRatioError] =
    extractChartMetadata(quickRatioData);
  useEffect(() => {
    setQuickRatio({
      datasets: createChartDatasetsConfig(
        quickRatioData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [quickRatioData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Capex */
  const [capexHasData, capexHasLabels, capexError] =
    extractChartMetadata(capexData);
  useEffect(() => {
    setCapex({
      datasets: createChartDatasetsConfig(
        capexData,
        screenWidth,
        {
          isTime: true,
          fiscalYear,
        },
        currentCompanyTickerId
      ),
    });
  }, [capexData, fiscalYear, screenWidth, currentCompanyTickerId]);

  /* Current Ratio Distribution */
  const [
    currentRatioDistributionHasData,
    currentRatioDistributionHasLabels,
    currentRatioDistributionError,
  ] = extractChartMetadata(currentRatioDistributionData);
  useEffect(() => {
    setCurrentRatioDistribution({
      labels: currentRatioDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        currentRatioDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [currentRatioDistributionData, screenWidth, currentCompanyTickerId]);

  /* Quick Ratio Distribution */
  const [
    quickRatioDistributionHasData,
    quickRatioDistributionHasLabels,
    quickRatioDistributionError,
  ] = extractChartMetadata(quickRatioDistributionData);
  useEffect(() => {
    setQuickRatioDistribution({
      labels: quickRatioDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        quickRatioDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [quickRatioDistributionData, screenWidth, currentCompanyTickerId]);

  /* Capex Distribution */
  const [
    capexDistributionHasData,
    capexDistributionHasLabels,
    capexDistributionError,
  ] = extractChartMetadata(capexDistributionData);
  useEffect(() => {
    setCapexDistribution({
      labels: capexDistributionData?.labels,
      datasets: createChartDatasetsConfig(
        capexDistributionData,
        screenWidth,
        {
          isDistr: true,
        },
        currentCompanyTickerId
      ),
    });
  }, [capexDistributionData, screenWidth, currentCompanyTickerId]);

  /***************************************
   *              Large Chart            *
   ***************************************/
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  /***************************************
   *          Change Report Page         *
   ***************************************/
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CURRENT_QUICK_RATIO_CAPEX));
  }, [dispatch]);

  return (
    <>
      {currentRatioDataUpdating &&
        currentRatioDistributionDataUpdating &&
        quickRatioDataUpdating &&
        quickRatioDistributionDataUpdating &&
        capexDataUpdating &&
        capexDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={currentRatio}
          error={
            !!currentRatioError
              ? GENERAL_ERROR_MESSAGE
              : !currentRatioHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.currentRatio}
          hasData={currentRatioHasData}
          exportData={currentRatioData}
          showLegend={true}
          currencySymbol={
            !!currentRatioData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!currentRatioData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!currentRatioDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={currentRatioDistribution}
          error={
            !!currentRatioDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !currentRatioDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.currentRatioDistribution
          }
          hasData={currentRatioDistributionHasData}
          exportData={currentRatioDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!currentRatioDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!currentRatioDistributionData?.isPercentage}
          updating={!!currentRatioDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={quickRatio}
          error={
            !!quickRatioError
              ? GENERAL_ERROR_MESSAGE
              : !quickRatioHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.quickRatio}
          hasData={quickRatioHasData}
          exportData={quickRatioData}
          showLegend={true}
          currencySymbol={
            !!quickRatioData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!quickRatioData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!quickRatioDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={quickRatioDistribution}
          error={
            !!quickRatioDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !quickRatioDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={
            CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.quickRatioDistribution
          }
          hasData={quickRatioDistributionHasData}
          exportData={quickRatioDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!quickRatioDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!quickRatioDistributionData?.isPercentage}
          updating={!!quickRatioDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom>
        <VisualChartComponent
          containerHeight={true}
          type="line"
          data={capex}
          error={
            !!capexError
              ? GENERAL_ERROR_MESSAGE
              : !capexHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.capex}
          hasData={capexHasData}
          exportData={capexData}
          showLegend={true}
          currencySymbol={
            !!capexData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!capexData?.isPercentage}
          timeTooltipFormat={
            activeQYFilter === 'yearly' ? 'YYYY' : 'DD MMM YYYY'
          }
          updating={!!capexDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />

        <VisualChartComponent
          containerHeight={true}
          type="bar"
          data={capexDistribution}
          error={
            !!capexDistributionError
              ? GENERAL_ERROR_MESSAGE
              : !capexDistributionHasLabels
              ? GENERAL_NO_DATA_MESSAGE
              : undefined
          }
          titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.capexDistribution}
          hasData={capexDistributionHasData}
          exportData={capexDistributionData}
          showLegend={true}
          xAxisType="category"
          options={BAR_CHART_PLUGINS}
          currencySymbol={
            !!capexDistributionData?.isCurrency
              ? useDollars
                ? DOLLAR_ST
                : currencySt
              : undefined
          }
          isPercentage={!!capexDistributionData?.isPercentage}
          updating={!!capexDistributionDataUpdating}
          onOpenChartDialogClick={onOpenChartDialogClickHandler}
        />
      </ResponsiveRow>
      <ScrollFillerComponent />
    </>
  );
};

export default CurrentQuickRatioCapexTab;
